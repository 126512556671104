import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__search(authToken, perspectiveId, params) {
  const {
    searchContext,
    searchQuery,
    recordId,
    typeId,
    categoryId,
    connectionId,
    nodeTagId,
    limit,
    offset,
  } = params;

  let url = `/search?context=${searchContext}`;

  if (recordId) {
    url += `&recordId=${recordId}`;
  }
  if (typeId) {
    url += `&typeId=${typeId}`;
  }

  if (categoryId) {
    url += `&categoryId=${categoryId}`;
  }
  if (connectionId) {
    url += `&connectionId=${connectionId}`;
  }

  if (nodeTagId) {
    url += `&nodeTagId=${nodeTagId}`;
  }

  if (searchQuery) {
    url += `&searchQuery=${searchQuery}`;
  }

  if (limit) {
    url += `&limit=${limit}`;
  }

  if (offset) {
    url += `&offset=${offset}`;
  }

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
