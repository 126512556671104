import {
  NODE_FRONTEND_PERMISSION_LABELS,
} from './PERMISSION_FLAGS';

function nodeFrontendPermissionLabel(permission) {
  if (!permission) {
    throw new Error('Cannot provide Node frontend permission label! Permission is undefined!');
  }
  return NODE_FRONTEND_PERMISSION_LABELS[permission];
}

export default nodeFrontendPermissionLabel;
