import React from 'react';
import {Route, Switch, generatePath} from 'react-router-dom';

import PeopleContext from './context';
import genPeopleBreadcrumbsFromPathname from './genPeopleBreadcrumbsFromPathname';

import DrilldownTracker from 'components/DrilldownTracker';
import DrilldownTrackerSegment from 'components/DrilldownTrackerSegment';
import breadcrumbComponentsFromBreadcrumbs from 'components/Breadcrumbs/breadcrumbComponentsFromBreadcrumbs';
import PersonTabs from 'components/PersonTabs';
import PersonTab from 'components/PersonTab';

import PersonNodesByRecordListRoute from './PersonNodesByRecordListRoute';

import genNodeSpawnedRecords from 'services/Nodes/genNodeSpawnedRecords';

const PersonRoute = ({
  match,
  history,
}) => {

  const {
    authToken,
    currentPerspectiveId,
    selectedPersonId,
    dispatchUserInterfaceAction,
    selectedPersonRecordId,
    pushAndClearFilter,
  } = React.useContext(PeopleContext);

  const currentPathname = window.location.pathname;

  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  const [breadcrumbStatus, setBreadcrumbsStatus] = React.useState('loading');
  const [fatalError, setFatalError] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [hoveredTab, setHoveredTab] = React.useState(null);
  const [spawnedPersonRecords, setSpawnedPersonRecords] = React.useState([]);

  const [isInitialized, setIsInitialized] = React.useState(false);

  React.useEffect(() => {
    const init = async() => {
      const {
        params: {
          personId,
          recordId,
        },
      } = match;

      if (!selectedPersonId) {

        return dispatchUserInterfaceAction({
          type: 'SET_SELECTED_PERSON_ID',
          payload: personId,
        });
      }

      const result = await genNodeSpawnedRecords(authToken, currentPerspectiveId, selectedPersonId, ['recordType']);
      const defaultSelectedRecordId = result.filter(record => record.recordType.name === 'Personal Information').map(record => record.id)[0];
      setSpawnedPersonRecords(result);

      if (!recordId) {
        dispatchUserInterfaceAction({
          type: 'SET_SELECTED_PERSON_RECORD_ID',
          payload: defaultSelectedRecordId,
        });

        pushAndClearFilter(`${match.url}/${defaultSelectedRecordId}`);
      } else {
        dispatchUserInterfaceAction({
          type: 'SET_SELECTED_PERSON_RECORD_ID',
          payload: recordId,
        });
        const recordIdTabIndex = result.findIndex((record) => record.id === parseInt(recordId));
        setSelectedTab(recordIdTabIndex);
      }

      setIsInitialized(true);
    };

    if (!isInitialized) {
      init();
    }
  }, [authToken, currentPerspectiveId, dispatchUserInterfaceAction, isInitialized, match, pushAndClearFilter, selectedPersonId, selectedPersonRecordId]);

  React.useEffect(() => {
    const constructBreadcrumbs = async() => {
      setBreadcrumbsStatus('loading');
      try {
        const breadcrumbs = await genPeopleBreadcrumbsFromPathname(currentPathname, authToken, currentPerspectiveId);
        setBreadcrumbs(breadcrumbs);
      } catch(error) {
        setFatalError(error);
      }
      setBreadcrumbsStatus('ready');
    };

    constructBreadcrumbs();
  }, [authToken, currentPathname, currentPerspectiveId]);

  const handleSelectTab = (params) => {
    const {
      tabIndex,
      recordId,
    } = params;

    setSelectedTab(tabIndex);
    dispatchUserInterfaceAction({
      type: 'SET_SELECTED_PERSON_RECORD_ID',
      payload: recordId,
    });
    pushAndClearFilter(generatePath(match.path, {personId: selectedPersonId, recordId: recordId}));
  };

  const handleShowRecord = () => {
    dispatchUserInterfaceAction({
      type: 'SET_EXPANDED_RECORD_ID',
      payload: selectedPersonRecordId,
    });
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_RECORD_PANEL',
      payload: true,
    });
  };

  const handleShareRecord = () => {
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_SHARE_RECORD_WIZARD',
      payload: true,
    });
    dispatchUserInterfaceAction({
      type: 'SET_SHARED_RECORD_ID',
      payload: selectedPersonRecordId,
    });
  };

  if (fatalError) {
    throw fatalError;
  }

  if (!isInitialized) {
    return (
      <div>Loading ...</div>
    );
  }

  return (
    <>
      <DrilldownTracker>
        <DrilldownTrackerSegment
          weight="1"
        >
          {breadcrumbComponentsFromBreadcrumbs(breadcrumbs, breadcrumbStatus, history)}
        </DrilldownTrackerSegment>
      </DrilldownTracker>

      <PersonTabs
        selectedTab={selectedTab}
        handleShowRecord={handleShowRecord}
        handleShareRecord={handleShareRecord}
      >
        {
          spawnedPersonRecords.map((record, index) => {
            const {
              recordType: {
                name: recordTypeName,
              },
              id,
            } = record;

            return (
              <PersonTab
                key={index}
                onMouseEnter={() => {
                  setHoveredTab(index);
                }}
                onMouseLeave={() => {
                  setHoveredTab(null);
                }}
                tabIndex={index}
                selectedTab={selectedTab}
                hoveredTab={hoveredTab}
                label={recordTypeName}
                handleSelectTab={handleSelectTab}
                recordId={id}
              />
            );
          })
        }
      </PersonTabs>

      <Switch>
        <Route
          path={`${match.path}`}
          component={PersonNodesByRecordListRoute}
        />
      </Switch>

    </>
  );
};

export default PersonRoute;
