export default function connectionToken(
  value,
  errorMessage = 'invalid connection code',
) {

  const allowedCharacters = new RegExp(/^[A-Za-z0-9]+$/);
  if (!allowedCharacters.test(value)) {
    return errorMessage;
  }

  if (value.length < 48 || value.length > 128) {
    return errorMessage;
  }

  return '';
}
