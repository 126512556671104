import React from 'react';
import MUIBackdrop from '@material-ui/core/Backdrop';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    zIndex: 102,
  },
});

const ContextualPanelShield = ({
  children,
  onClick,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <MUIBackdrop
      onClick={onClick}
      {...otherProps}
      classes={{
        root: classes.root,
      }}>
      {children}
    </MUIBackdrop>
  );
};

export default ContextualPanelShield;
