import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import InternalNodesByRecordListRoute from './InternalNodesByRecordListRoute';
import InternalLocationsListRoute from './InternalLocationsListRoute';

const InternalRecordRoute = ({
  match,
}) => (
  <Switch>
    <Route
      path={`${match.path}/nodes`}
      component={InternalNodesByRecordListRoute}
    />
    <Route
      path={`${match.path}/by-location`}
      component={InternalLocationsListRoute}
    />
    <Redirect
      to={`${match.path}/nodes`}
    />
  </Switch>
);

export default InternalRecordRoute;
