import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const AddNodeLinkDefinitionDialog = ({
  open,
  onClose,
  controlsDisabled,
  label,
  directQualifier,
  reverseQualifier,
  onSetLabel,
  onSetDirectQualifier,
  onSetReverseQualifier,
  onSaveNodeLinkDefinition,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Add Node Link Definition
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h6">
          Label
        </Typography>
        <TextField
          autoFocus
          fullWidth
          disabled={controlsDisabled}
          margin="normal"
          value={label}
          onChange={event => {
            if (event.target.value.length > 80) {
              return;
            }
            onSetLabel(event.target.value);
          }}
        />
        <br/><br/>

        <Typography
          variant="h6">
          Direct qualifier
        </Typography>
        <TextField
          fullWidth
          disabled={controlsDisabled}
          margin="normal"
          value={directQualifier}
          onChange={event => {
            if (event.target.value.length > 80) {
              return;
            }
            onSetDirectQualifier(event.target.value);
          }}
        />
        <br/><br/>

        <Typography
          variant="h6">
          Reverse qualifier
        </Typography>
        <TextField
          fullWidth
          disabled={controlsDisabled}
          margin="normal"
          value={reverseQualifier}
          onChange={event => {
            if (event.target.value.length > 80) {
              return;
            }
            onSetReverseQualifier(event.target.value);
          }}
        />
        <br/><br/>

      </DialogContent>
      <DialogActions>
        <Button
          disabled={controlsDisabled}
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled ||
            label === '' ||
            directQualifier === '' ||
            reverseQualifier === ''
          }
          variant="contained"
          color="primary"
          onClick={onSaveNodeLinkDefinition}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNodeLinkDefinitionDialog;
