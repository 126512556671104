import React from 'react';
import styled from '@emotion/styled';
import ProgressBar from 'ui-library/components/ProgressBar';
import {
  wizardProgressHeight,
} from 'constants/layout';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
  position: fixed;
  width: 100%;
  height: ${wizardProgressHeight}px;
`;

const WizardProgress = ({
  isVisible,
  ...otherProps
}) => {
  return (
    <Fade
      in={isVisible}
      timeout={200}>
      <Root>
        <ProgressBar
          {...otherProps}
          height={wizardProgressHeight}
          railColor="grey-light"
        />
      </Root>
    </Fade>
  );
};

export default WizardProgress;
