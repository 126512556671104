import isNumeric from 'validator/lib/isNumeric';

export default function fieldIsPhoneNumber(
  params,
  errorMessage = 'enter a year between 1800 and 2099!',
) {
  const {
    value,
  } = params;

  if (value === '' || (isNumeric(value, {no_symbols: true}) && +value >= 1800 && +value <= 2099)) {
    return '';
  }
  return errorMessage;
}
