import React from 'react';
import PropTypes from 'prop-types';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';

import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

const RemoveCardDialog = ({
  isVisible,
  isProcessing,
  handleClose,
  handleSubmit,
}) => {

  return (
    <ModalCard
      open={isVisible}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <ModalCardHeader
        color="primary"
      >
        <ModalCardHeaderIcon
          color="error"
        >
          <CreditCardRoundedIcon
            fontSize="inherit"
          />
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle
        >
          Remove Credit Card
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        <Typography
          variant="body"
        >
          Are you sure you weant to remove your Credit Card?
        </Typography>
        <br/>
        <Typography
          variant="body"
        >
          Your membership will not be able to renew.
        </Typography>
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="modal-remove-card-cancel-button"
          variant="text"
          onClick={handleClose}
          disabled={isProcessing}
        >
          Cancel
        </Button>
        <Button
          data-test-id="modal-remove-card-submit-button"
          variant="custom"
          textTransform="uppercase"
          customBgColor="error"
          customHoverBgColor="error-dark"
          customTextColor="white"
          customHoverTextColor="white"
          customActiveBgColor="error-darker"
          customActiveTextColor="white"
          disabled={isProcessing}
          onClick={handleSubmit}
          startIcon={isProcessing ? <CircularProgress size={24} color="white"/> : <DeleteRoundedIcon/>}
        >
          Remove Card
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

RemoveCardDialog.propTypes = {
  isVisible: PropTypes.bool,
  isProcessing: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default RemoveCardDialog;
