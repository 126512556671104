import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {POST} from '../verbs';

export default async function POST__nodes_ID_tags(authToken, perspectiveId, params) {
  const {
    nodeId,
    tagName,
  } = params;

  return await POST(
    `/nodes/${nodeId}/tags`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      tagName,
    },
  );
}
