import React from 'react';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';
import PersonDetailsStep from './steps/PersonDetailsStep';
import SaveConfirmationStep from './steps/SaveConfirmationStep';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genPersonTypeService from 'services/Persons/genPersonType';
import savePersonService from 'services/Persons/savePerson';

const AddPersonWizard = ({
  onClose,
  onSwitchWizard,
}) => {
  const [personFields, setPersonFields] = React.useState(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [savedPersonName, setSavedPersonName] = React.useState('');

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  React.useEffect(() => {
    async function init() {
      const personType = await genPersonTypeService(authToken, currentPerspectiveId);
      const personFields = {};
      Object.keys(personType.fieldDefinitions).forEach(id => {
        personFields[id] = {
          ...personType.fieldDefinitions[id],
          value: '',
          errorMessage: '',
        };
      });
      setPersonFields(personFields);
    }
    init();
  }, [authToken, currentPerspectiveId]);

  const [step, setStep] = React.useState(1);
  const TOTAL_STEPS = 2;

  function closeWizard() {
    if (!window.confirm('Are you sure you want to quit the wizard? Your person will not be saved if you quit now.')) {
      return;
    }
    onClose();
  }

  async function savePerson() {
    setIsSaving(true);
    const personValues = {};
    Object.keys(personFields).forEach(id => {
      if (personFields[id].value && personFields[id].value !== 'N/A') {
        personValues[id] = personFields[id].value;
      }
    });
    const savedPerson = await savePersonService(authToken, currentPerspectiveId, {fields: personValues});
    setSavedPersonName(savedPerson.name);
    setIsSaving(false);
    setStep(2);
  }

  return (
    <Wizard onClose={closeWizard}>
      <WizardProgress
        isVisible={true}
        progress={step}
        total={TOTAL_STEPS}
      />
      <WizardNavigation>
        <WizardBackButton
          isVisible={step === 1}
          onClick={() => {
            step > 1 && setStep(step - 1);
            step === 1 && onSwitchWizard();
          }}
        />
        <WizardCloseButton
          isVisible={step === 1}
          onClick={closeWizard}
        />
      </WizardNavigation>

      <PersonDetailsStep
        active={step === 1}
        personFields={personFields}
        setPersonFields={setPersonFields}
        isSaving={isSaving}
        savePerson={savePerson}
      />

      <SaveConfirmationStep
        active={step === 2}
        savedPersonName={savedPersonName}
        closeWizard={onClose}
      />
    </Wizard>
  );
};

export default AddPersonWizard;
