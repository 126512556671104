import React from 'react';
import styled from '@emotion/styled';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
`;

const WizardStepContent = ({
  children,
}) => {
  return (
    <Fade
      in
      timeout={750}>
      <Root>
        {children}
      </Root>
    </Fade>
  );
};

export default WizardStepContent;
