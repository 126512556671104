import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import theme from 'ui-library/theme';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  list: {
    display: theme.nodeAttachmentList.list.display,
    flexDirection: theme.nodeAttachmentList.list.flexDirection,
    width: theme.nodeAttachmentList.list.width,
    paddingBottom: theme.nodeAttachmentList.list.paddingBottom,
  },
  header: {
    display: theme.nodeAttachmentList.header.display,
    flexDirection: theme.nodeAttachmentList.header.flexDirection,
    justifyContent: theme.nodeAttachmentList.header.justifyContent,
    borderBottom: theme.nodeAttachmentList.header.borderBottom,
  },
  name: {
    display: theme.nodeAttachmentList.name.display,
    padding: theme.nodeAttachmentList.name.padding,
    justifyContent: theme.nodeAttachmentList.name.justifyContent,
    userSelect: theme.nodeAttachmentList.name.userSelect,
    color: props => props.criteria === 'name' ? theme.nodeAttachmentList.name.selectedColor : theme.nodeAttachmentList.name.defaultColor,
  },
  size: {
    display: theme.nodeAttachmentList.size.display,
    padding: theme.nodeAttachmentList.size.padding,
    justifyContent: theme.nodeAttachmentList.size.justifyContent,
    userSelect: theme.nodeAttachmentList.size.userSelect,
    color: props => props.criteria === 'size' ? theme.nodeAttachmentList.name.selectedColor : theme.nodeAttachmentList.name.defaultColor,
  },
  icon: {
    display: theme.nodeAttachmentList.icon.display,
    width: theme.nodeAttachmentList.icon.width,
    height: theme.nodeAttachmentList.icon.height,
  },
});

const NodeAttachmentList = ({
  attachmentsSortDirection, // ascending || descending
  attachmentsSortCriteria, // name || size
  handleNameSort,
  handleSizeSort,
  children,
}) => {

  const criteria = attachmentsSortCriteria ? attachmentsSortCriteria : 'name';

  const classes = useStyles({
    criteria,
  });

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <div className={classes.name}>
          <div className={classes.icon}>
            {
              attachmentsSortCriteria === 'name' ?
                attachmentsSortDirection === 'descending' ?
                  <KeyboardArrowDownRoundedIcon />
                  :
                  <KeyboardArrowUpRoundedIcon />
                :
                null
            }
          </div>
          <Typography
            variant="x-small"
            fontWeight="bold"
            style={{
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            onClick={handleNameSort}
          >
            FILENAME
          </Typography>
        </div>
        <div className={classes.size}>
          <div className={classes.icon}>
            {
              attachmentsSortCriteria === 'size' ?
                attachmentsSortDirection === 'descending' ?
                  <KeyboardArrowDownRoundedIcon />
                  :
                  <KeyboardArrowUpRoundedIcon />
                :
                null
            }
          </div>
          <Typography
            variant="x-small"
            fontWeight="bold"
            style={{
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            onClick={handleSizeSort}
          >
            SIZE
          </Typography>
        </div>
      </div>
      <div className={classes.body}>
        {
          children
        }
      </div>
    </div>
  );
};

NodeAttachmentList.propTypes = {
  children: PropTypes.node,
  attachmentsSortDirection: PropTypes.oneOf(['ascending', 'descending']),
  attachmentsSortCriteria: PropTypes.oneOf(['name', 'size']),
  handleNameSort: PropTypes.func,
  handleSizeSort: PropTypes.func,
};

export default NodeAttachmentList;
