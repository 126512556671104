import React from 'react';
import styled from '@emotion/styled';
import Fade from '@material-ui/core/Fade';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CenterpieceCheckmark from 'components/chrome/CenterpieceCheckmark';
import Link from 'ui-library/components/Link';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 32px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
`;

const SaveConfirmationScreen = ({
  history,
  navigateToStep,
  closeWizardWithPrepend,
}) => {
  return (
    <Fade in timeout={400}>
      <Root>
        <CenterpieceCheckmark/>
        <Typography
          variant="h2"
          fontWeight="bold"
          color="secondary"
        >
          Your Item was added successfully!
        </Typography>
        <Typography
          variant="lead"
          fontWeight="normal"
          color="black"
        >
            You can view and edit your items in the&nbsp;
          <Link
            data-test-id="library-list-link"
            value="/library"
            label="Library"
            variant="lead"
            fontWeight="normal"
            color="primary"
          />
            .
        </Typography>
        <Actions>
          <Button
            data-test-id="add-item-wizard-finish-button"
            variant="primary"
            onClick={() => {
              closeWizardWithPrepend();
            }}>
            Finish
          </Button>
        </Actions>
      </Root>
    </Fade>
  );
};

export default SaveConfirmationScreen;
