import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const AddItemDialog = ({
  open,
  controlsDisabled,
  typeId,
  types,
  onClose,
  onSetItem,
  onSaveItem,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Add a type to the eHR Hub
      </DialogTitle>
      <DialogContent>
        {
          !types.length ?
            <>
              <Typography
                variant="body2"
              >
                All the types associated with the Electronic Health Record Type have been added to the eHub.
              </Typography>
            </>
            :
            <>
              <Typography
                variant="body2"
              >
                Select a type to be added to the eHR Hub. The type can be repositioned in order to set it's display index.
              </Typography>
              <br/>
              <Typography
                variant="h6">
                Type
              </Typography>
              <Select
                fullWidth
                disabled={controlsDisabled}
                value={typeId}
                onChange={event => {
                  onSetItem(event.target.value);
                }}
              >
                {
                  types.map((type) => {
                    const {
                      id,
                      name,
                    } = type;
                    return (
                      <MenuItem
                        key={id}
                        value={id}
                      >
                        {name}
                      </MenuItem>
                    );
                  })
                }
              </Select>
            </>
        }
      </DialogContent>
      <DialogActions>
        <Button
          disabled={controlsDisabled}
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled ||
            typeId === ''
          }
          variant="contained"
          color="primary"
          onClick={onSaveItem}>
          add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemDialog;
