import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  row: {
    display: theme.nodeLocationAssignmentViewMode.row.display,
    padding: theme.nodeLocationAssignmentViewMode.row.padding,
    flex: theme.nodeLocationAssignmentViewMode.row.flex,
    transition: theme.nodeLocationAssignmentViewMode.row.transition,
    minWidth: theme.nodeLocationAssignmentViewMode.row.minWidth,
    userSelect: theme.nodeLocationAssignmentViewMode.row.userSelect,
  },
  label: {
    display: theme.nodeLocationAssignmentViewMode.label.display,
    minWidth: theme.nodeLocationAssignmentViewMode.label.minWidth,
  },
  recordValue: {
    overflow: theme.nodeLocationAssignmentViewMode.value.overflow,
    whiteSpace: theme.nodeLocationAssignmentViewMode.value.whiteSpace,
    textOverflow: theme.nodeLocationAssignmentViewMode.value.textOverflow,
  },
});

const NodeLocationAssignment = ({
  assignedTo,
  locationPath,
}) => {

  const classes = useStyles();

  return (
    <>
      <div className={classes.row}>
        <div className={classes.label}>
          <Typography
            variant="body"
            fontWeight="bold"
          >
          Assigned To:
          </Typography>
        </div>
        <div className={classes.recordValue}>
          <Typography
            variant="body"
          >
            {assignedTo}
          </Typography>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          <Typography
            variant="body"
            fontWeight="bold"
          >
          Location Path:
          </Typography>
        </div>
        <div>
          <Typography
            variant="body"
          >
            {locationPath}
          </Typography>
        </div>
      </div>
    </>
  );
};

NodeLocationAssignment.propTypes = {
  assignedTo: PropTypes.string,
  locationPath: PropTypes.string,
};

export default NodeLocationAssignment;
