import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Typography from 'ui-library/components/Typography';
import {
  primary,
} from 'ui-library/color-palette';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const WizardCloseButton = ({
  isVisible,
  onClick,
  ...otherProps
}) => {
  return (
    <Fade
      in={isVisible}
      timeout={200}>
      <Root
        onClick={() => {
          typeof onClick === 'function' && onClick();
        }}
        {...otherProps}
      >
        <Typography
          variant="small"
          fontWeight="bold"
          color="primary">
          CLOSE
        </Typography>
        &nbsp;&nbsp;
        <span
          style={{
            color: primary,
            position: 'relative',
            top: '0.1em',
          }}>
          <CloseIcon/>
        </span>
      </Root>
    </Fade>
  );
};

WizardCloseButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WizardCloseButton;
