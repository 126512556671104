function selectUsers(DM) {
  const {
    usersById,
    userIds,
  } = DM;

  return (
    userIds
      .map(userId => usersById[userId])
  );
}

export default selectUsers;
