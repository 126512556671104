import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PUT} from '../verbs';

export default async function PUT__nodes_ID_fields(params) {
  const {
    authToken,
    currentPerspectiveId,
    id,
    fields,
  } = params;

  return await PUT(
    `/nodes/${id}/fields`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, currentPerspectiveId),
    {
      fields,
    },
  );
}
