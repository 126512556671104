import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ContextualPanel from 'components/chrome/ContextualPanel';
import ContextualPanelNavigation from 'components/chrome/ContextualPanelNavigation';
import ContextualPanelBackButton from 'components/chrome/ContextualPanelBackButton';
import ContextualPanelCloseButton from 'components/chrome/ContextualPanelCloseButton';
import ContextualPanelHeader from 'components/chrome/ContextualPanelHeader';
import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import ContextualPanelActions from 'components/chrome/ContextualPanelActions';
import ContextualPanelTabs from 'components/chrome/ContextualPanelTabs';
import ContextualPanelTab from 'components/chrome/ContextualPanelTab';

import PersonDetails from 'components/PersonDetails';
import NodeAttachments from 'components/NodeAttachments';
import NodeLinks from 'components/NodeLinks';
import NodeTags from 'components/NodeTags';
import NodeLocationEdit from 'components/NodeLocationEdit';
import NodePermissionsTab from 'components/NodePermissionsTab';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';

import Grid from '@material-ui/core/Grid';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import EditableTitle from 'ui-library/components/EditableTitle';
import NodeLinkCandidatesList from 'ui-library/components/NodeLinkCandidatesList';
import NodeLinkInfoCard from 'ui-library/components/NodeLinkInfoCard';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import Field from 'ui-library/components/Field';
import Message from 'ui-library/components/Message';

import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';
import genTypeByTypeId from 'services/Types/genTypeByTypeId';
import updateNodeName from 'services/Nodes/updateNodeName';
import replaceNodeFieldValue from 'services/Nodes/replaceNodeFieldValue';
import deleteNodeFieldValue from 'services/Nodes/deleteNodeFieldValue';
import replaceNodeFields from 'services/Nodes/replaceNodeFields';

import UserInterfaceContext from 'contexts/UserInterface';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import parseNodePermissions from 'utils/permissions/parseNodePermissions';
import parseFieldValue from 'utils/parseFieldValue';

import {
  fieldIsEnum,
  fieldIsPersonName,
  name as nameValidator,
} from 'utils/validator';

import {
  initNodeState,
  nodeReducer,
} from 'reducers/NewNodePanel';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const SecondButtonContainer = styled.div`
  margin-left: 8px;
`;

const FIELD_VALIDATION = {
  'enum': fieldIsEnum,
  'name': fieldIsPersonName,
};

const PersonPanel = () => {
  const {
    expandedPersonId,
    expandedPersonLoadingState,
    expandedPersonIsCurrentUser,
    showPersonPanel,
    dispatchUserInterfaceAction,
    nodePanelDisplayMode,
    selectedNodePanelTab,
  } = React.useContext(UserInterfaceContext);

  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const [nodeState, dispatchNodeAction] = React.useReducer(
    nodeReducer,
    null,
    initNodeState,
  );

  const {
    node: {
      id: nodeId,
      name: nodeName,
    },
    previousNodeName,
    isNodeSaving,
    isNodeNameSaving,
    isNodeNameEditable,
    fieldsByFieldDefinitionId,
    fieldDefinitionIds,
    fieldDefinitionsById,
    attachmentsById,
    attachmentIds,
    uploadsById,
    uploadIds,

    isLinkInfoOpen,
    isLinkInfoLoading,
    linkInfo,

    nodePermissions: {
      frontendPermissions:{
        edit: nodeEditPermissions,
        delete: nodeDeletePermissions,
      },
    },
  } = nodeState;

  const [hoveredTab, setHoveredTab] = React.useState(null);

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [isPanelMenuOpen, setIsPanelMenuOpen] = React.useState(false);
  const [panelMenuAnchorEl, setPanelMenuAnchorEl] = React.useState(null);

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        setAuthToken(null);
        setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw(fatalError);
  }

  const loadNode = React.useCallback(async(postEditReload) => {
    try {
      if (!postEditReload) {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_PERSON_LOADING_STATE',
          payload: 'loading',
        });
      }
      const response = await genNodeByNodeId({
        authToken,
        perspectiveId: currentPerspectiveId,
        nodeId: expandedPersonId,
      });
      const {
        name,
        permissions,
      } = response;

      dispatchNodeAction({
        type: 'SET_NODE',
        payload: {
          ...response,
        },
      });
      dispatchNodeAction({
        type: 'SET_PREVIOUS_NODE_NAME',
        payload: name,
      });
      dispatchNodeAction({
        type: 'SET_NODE_PERMISSIONS',
        payload: parseNodePermissions(permissions),
      });
      if (!postEditReload) {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_PERSON_LOADING_STATE',
          payload: 'loaded',
        });
      }
    } catch (error) {
      setError(error);
    }
  }, [authToken, currentPerspectiveId, dispatchUserInterfaceAction, expandedPersonId]);

  React.useEffect(() => {
    if (expandedPersonId !== null && expandedPersonLoadingState === 'not loaded') {
      loadNode();
    }
  }, [expandedPersonId, expandedPersonLoadingState, loadNode]);

  const nodeNameValidationErrorMessage = nameValidator(nodeName);
  const fieldsHaveErrors = fieldDefinitionIds.map(fieldDefinitionId => {
    const {
      errorMessage,
    } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

    return errorMessage === '';
  }).includes(false);


  const saveNodeName = async() => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: true,
    });
    try {
      await updateNodeName(authToken, currentPerspectiveId, expandedPersonId, nodeName);
      dispatchNodeAction({
        type: 'SET_PREVIOUS_NODE_NAME',
        payload: nodeName,
      });
      dispatchNodeAction({
        type: 'SET_IS_NODE_NAME_EDITABLE',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_PERSON_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: expandedPersonId,
      });
    } catch(error) {
      setError(error);
    };
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: false,
    });
  };

  const revertNodeName = () => {
    dispatchNodeAction({
      type: 'SET_NODE_NAME',
      payload: previousNodeName,
    });
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_EDITABLE',
      payload: false,
    });
  };

  const saveNodeField = async({
    value,
    previousValue,
    id,
    fieldDefinitionId,
    type,
    errorMessage,
  }) => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: true,
    });
    dispatchNodeAction({
      type: 'SET_FIELD_IS_EDITABLE',
      payload: {
        fieldDefinitionId,
        isEditable: false,
      },
    });

    try {
      if (![null, previousValue].includes(value) && !Boolean(errorMessage)) {
        dispatchNodeAction({
          type: 'SET_FIELD_IS_SAVING',
          payload: {
            fieldDefinitionId,
            isSaving: true,
          },
        });
        if (previousValue !== value && ['', 'N/A'].includes(value)) {
          await deleteNodeFieldValue({authToken, currentPerspectiveId, id: expandedPersonId, fieldDefinitionId});
          await deleteEmptyNodeFields();
        } else {
          await replaceNodeFieldValue({authToken, currentPerspectiveId, id: expandedPersonId, fieldDefinitionId, value: parseFieldValue({type, value, setError})});
        }
        dispatchNodeAction({
          type: 'SET_FIELD_PREVIOUS_VALUE',
          payload: {
            fieldDefinitionId,
            value: value,
          },
        });
        dispatchNodeAction({
          type: 'SET_FIELD_IS_SAVING',
          payload: {
            fieldDefinitionId,
            isSaving: false,
          },
        });
        dispatchNodeAction({
          type: 'SET_FIELD_IS_FOCUSED',
          payload: {
            fieldDefinitionId,
            isFocused: false,
          },
        });
      } else {
        dispatchNodeAction({
          type: 'SET_FIELD_IS_FOCUSED',
          payload: {
            fieldDefinitionId,
            isFocused: false,
          },
        });
      }
    } catch(error) {
      setError(error);
    }
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: false,
    });
  };

  const deleteEmptyNodeFields = async() => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: true,
    });
    const emptyFieldsById = fieldDefinitionIds.filter(fielDefinitionId => {
      const {
        value,
        previousValue,
      } = fieldsByFieldDefinitionId[fielDefinitionId];

      return [null, '', 'N/A'].includes(value) && ![null, '', 'N/A'].includes(previousValue);
    });

    try {
      await Promise.all(emptyFieldsById.map(async(fieldDefinitionId) => {
        await deleteNodeFieldValue({authToken, currentPerspectiveId, id: expandedPersonId, fieldDefinitionId});

        dispatchNodeAction({
          type: 'SET_FIELD_VALUE',
          payload: {
            fieldDefinitionId,
            value: '',
          },
        });

        dispatchNodeAction({
          type: 'SET_FIELD_PREVIOUS_VALUE',
          payload: {
            fieldDefinitionId,
            value: '',
          },
        });

        dispatchNodeAction({
          type: 'SET_FIELD_INLINE_EDIT_MODE',
          payload: {
            fieldDefinitionId,
            inlineEditMode: false,
          },
        });
      }));
      dispatchNodeAction({
        type: 'SET_IS_NODE_SAVING',
        payload: false,
      });
    } catch(error) {
      setError(error);
    }

    loadNode(true);

    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: false,
    });

    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: false,
    });

    dispatchUserInterfaceAction({
      type: 'SET_NODE_PANEL_DISPLAY_MODE',
      payload: 'default',
    });
    dispatchUserInterfaceAction({
      type: 'SET_NODE_PANEL_SELECTED_TAB',
      payload: 0,
    });
  };

  const revertAllFields = () => {
    dispatchNodeAction({
      type: 'REVERT_FIELDS',
      payload: {
        revertIds: fieldDefinitionIds,
      },
    });
    dispatchNodeAction({
      type: 'SET_NODE_NAME',
      payload: previousNodeName,
    });
  };

  const revertErroneousNodeFields = () => {
    const erroneusFieldIds = fieldDefinitionIds.filter(fielDefinitionId => {
      const {
        errorMessage,
      } = fieldsByFieldDefinitionId[fielDefinitionId];

      return Boolean(errorMessage);
    });

    dispatchNodeAction({
      type: 'REVERT_FIELDS',
      payload: {
        revertIds: erroneusFieldIds,
      },
    });

    if (Boolean(nodeNameValidationErrorMessage)) {
      dispatchNodeAction({
        type: 'SET_NODE_NAME',
        payload: previousNodeName,
      });
    }
  };

  const saveAllFields = async() => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: true,
    });
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: true,
    });
    const fields = {};
    Object.keys(fieldsByFieldDefinitionId).forEach(fieldDefinitionId => {
      const {
        type,
        value,
      } = fieldsByFieldDefinitionId[fieldDefinitionId];

      if (![null, '', 'N/A'].includes(value)) {
        fields[fieldDefinitionId] = parseFieldValue({type, value, setError});
      }
    });

    try {
      await replaceNodeFields({
        authToken,
        currentPerspectiveId,
        id: nodeId,
        fields,
      });
      await updateNodeName(authToken, currentPerspectiveId, nodeId, nodeName);

      setShowSuccessMessage(true);
    } catch(error) {
      setError(error);
    }

    loadNode(true);

    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: false,
    });

    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: false,
    });

    dispatchUserInterfaceAction({
      type: 'SET_NODE_PANEL_DISPLAY_MODE',
      payload: 'default',
    });

    dispatchUserInterfaceAction({
      type: 'SET_PERSON_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
      payload: expandedPersonId,
    });

    dispatchUserInterfaceAction({
      type: 'SET_NODE_PANEL_SELECTED_TAB',
      payload: 0,
    });
  };

  const uploadsInProgress = () => {
    const activeUploads = uploadIds.filter((id) => uploadsById[id].request);
    return Boolean(activeUploads.length);
  };

  const downloadsInProgress = () => {
    const activeDownloads = attachmentIds.filter((id) => attachmentsById[id].downloadRequest);
    return Boolean(activeDownloads.length);
  };

  const cancelAllUploads = () => {
    uploadIds.forEach((id) => {
      const {
        request,
      } = uploadsById[id];
      if (request) {
        request.abort();
        dispatchNodeAction({
          type: 'REMOVE_UPLOAD',
          payload: {
            id,
          },
        });
      }
    });
  };

  const cancelAllDownloads = () => {
    attachmentIds.forEach((id) => {
      const {
        downloadRequest,
      } = attachmentsById[id];
      if (downloadRequest) {
        downloadRequest.abort();
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_DOWNLOAD_REQUEST',
          payload: {
            id,
            downloadRequest: null,
          },
        });
      }
    });
  };

  const handleCloseLinkInfo = () => {
    dispatchNodeAction({
      type: 'SET_IS_LINK_INFO_OPEN',
      payload: false,
    });
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_PERSON_PANEL',
      payload: true,
    });
  };

  const handleLinkInfo = async(linkedNodeId) => {

    try {
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_PERSON_PANEL',
        payload: false,
      });
      dispatchNodeAction({
        type: 'SET_IS_LINK_INFO_LOADING',
        payload: true,
      });
      dispatchNodeAction({
        type: 'SET_IS_LINK_INFO_OPEN',
        payload: true,
      });
      const linkInfoNode = await genNodeByNodeId({authToken, perspectiveId: currentPerspectiveId, nodeId: linkedNodeId});
      const {
        name,
        typeName,
        fields,
        typeId: linkInfoNodeTypeId,
      } = linkInfoNode;

      const linkInfoNodeType = await genTypeByTypeId({authToken, perspectiveId: currentPerspectiveId, typeId: linkInfoNodeTypeId});
      const {
        fieldDefinitions,
      } = linkInfoNodeType;

      const linkInfo = {
        name,
        fields: [{
          label: 'Type',
          value: typeName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
          type: 'text',
        }],
      };

      if (fields) {
        Object.keys(fields).forEach(key => {
          linkInfo.fields.push({
            label: fieldDefinitions[key].label,
            value: fields[key],
            type: fieldDefinitions[key].type,
          });
        });
      }

      dispatchNodeAction({
        type: 'SET_LINK_INFO',
        payload: linkInfo,
      });
      dispatchNodeAction({
        type: 'SET_IS_LINK_INFO_LOADING',
        value: false,
      });

    } catch(error) {
      setError(error);
    }
  };

  const handleClosePanelMenu = () => {
    setIsPanelMenuOpen(false);
    setPanelMenuAnchorEl(null);
  };

  const closePanel = (closeForDelete) => {
    if (expandedPersonId !== null) {
      // CONFIRMATION DIALOG IF UPLOADS IN PROGRESS
      let cancelUploads = false;
      if (uploadsInProgress()) {
        if (window.confirm('There are uploads in progress. Are you sure you want to continue?')) {
          cancelUploads = true;
        }
      } else {
        cancelUploads = true;
      }

      // CONFIRMATION DIALOG IF DOWNLOADS IN PROGRESS
      let cancelDownloads = false;
      if (downloadsInProgress()) {
        if (window.confirm('There are downloads in progress. Are you sure you want to continue?')) {
          cancelDownloads = true;
        }
      } else {
        cancelDownloads = true;
      }

      if (cancelUploads && cancelDownloads) {
        dispatchUserInterfaceAction({
          type: 'SET_SHOW_PERSON_PANEL',
          payload: false,
        });

        // CANCEL ALL IN PROGRESS UPLOADS
        cancelAllUploads();

        // CANCEL ALL IN PROGRESS DOWNLOADS
        cancelAllDownloads();

        // RESET SELECTED & HOVERED TAB
        setHoveredTab(null);
        dispatchUserInterfaceAction({
          type: 'SET_NODE_PANEL_SELECTED_TAB',
          payload: 0,
        });

        // RESET PANEL DISPLAY MODE
        dispatchUserInterfaceAction({
          type: 'SET_NODE_PANEL_DISPLAY_MODE',
          payload: 'default',
        });

        // DELETE INLINE EDITED FIELDS LEFT EMPTY (N/A)
        deleteEmptyNodeFields();

        // RESET LOCAL MENU STATE
        setIsPanelMenuOpen(false);
        setPanelMenuAnchorEl(null);

        // RESET EDITABLE TITLE
        dispatchNodeAction({
          type: 'SET_IS_NODE_NAME_EDITABLE',
          payload: false,
        });

        // CLOSE SUCCESS MESSAGE
        setShowSuccessMessage(false);

        // RESET USER INTERFACE STATE
        if (!closeForDelete) {
          dispatchUserInterfaceAction({
            type: 'SET_EXPANDED_PERSON_ID',
            payload: null,
          });
          dispatchUserInterfaceAction({
            type: 'SET_EXPANDED_PERSON_LOADING_STATE',
            payload: 'not loaded',
          });
        }
        dispatchUserInterfaceAction({
          type: 'SET_RELOAD_ITEM_PERMISSION_GRANTS',
          payload: false,
        });
        dispatchUserInterfaceAction({
          type: 'SET_EXTERNAL_RECORD_CONNECTION_ID',
          payload: null,
        });
      }
    }
  };

  return (
    <ContextualPanel
      expanded={!!expandedPersonId && showPersonPanel}
      handleEscKey={() => {
        if (nodePanelDisplayMode !== 'editItem') {
          closePanel();
        }
      }}
      onMouseDown={(ev) => {
        if (nodeEditPermissions) {
          if (isNodeNameEditable && !Boolean(nodeNameValidationErrorMessage)) {
            if (nodeName !== previousNodeName) {
              saveNodeName();
            } else {
              dispatchNodeAction({
                type: 'SET_IS_NODE_NAME_EDITABLE',
                payload: false,
              });
            }
          }

          Object.keys(fieldsByFieldDefinitionId).forEach(fieldDefinitionId => {
            const {
              value,
              previousValue,
              isFocused,
              type,
              errorMessage,
            } = fieldsByFieldDefinitionId[fieldDefinitionId];

            if (isFocused === true && !Boolean(errorMessage)) {
              saveNodeField({value, previousValue, id: expandedPersonId, type, fieldDefinitionId});
            }
          });
        }
      }}
    >
      <ContextualPanelNavigation
        expanded={!!expandedPersonId && showPersonPanel}
      >
        <ContextualPanelBackButton
          isVisible={false}
          onClick={() => {}}
        />
        <ContextualPanelCloseButton
          data-test-id="contextual-panel-close-button"
          isVisible={true}
          onClick={() => {
            closePanel();
          }}
        />
      </ContextualPanelNavigation>

      {
        expandedPersonLoadingState !== 'loaded' ?
          <div>loading ...</div>
          :
          <>
            <ContextualPanelHeader
              panelDisplayMode={nodePanelDisplayMode}
            >
              <EditableTitle
                data-test-id="person-panel-nickname-input"
                disabled={false}
                isSaving={isNodeNameSaving}
                autoFocus={true}
                value={nodeName}
                editable={nodeEditPermissions && isNodeNameEditable}
                panelDisplayMode={nodePanelDisplayMode}
                setEditable={() => {
                  dispatchNodeAction({
                    type: 'SET_IS_NODE_NAME_EDITABLE',
                    payload: true,
                  });
                }}
                error={Boolean(nodeNameValidationErrorMessage)}
                helperText={nodeNameValidationErrorMessage}
                onKeyPress={(ev) => {
                  if (ev.key === 'Enter') {
                    if (nodeNameValidationErrorMessage) {
                      return;
                    }
                    if (nodeName !== previousNodeName) {
                      dispatchNodeAction({
                        type: 'SET_NODE_NAME',
                        payload: ev.target.value,
                      });
                      saveNodeName();
                    } else {
                      dispatchNodeAction({
                        type: 'SET_IS_NODE_NAME_EDITABLE',
                        payload: false,
                      });
                    }
                  }
                }}
                onChange={(ev) => {
                  dispatchNodeAction({
                    type: 'SET_NODE_NAME',
                    payload: ev.target.value,
                  });
                }}
                onMouseDown={(ev) => {
                  ev.stopPropagation();
                }}
                saveName={saveNodeName}
                revertName={revertNodeName}
              />
            </ContextualPanelHeader>

            {/* PANEL DISPLAY MODE: 'editItem' */}
            {
              nodePanelDisplayMode === 'editItem' &&
              <>
                <ContextualPanelContent
                  panelDisplayMode={nodePanelDisplayMode}
                >
                  <PaddedContainer>
                    <Grid
                      container
                      spacing={3}>
                      {
                        fieldDefinitionIds.map(fieldDefinitionId => {

                          const {
                            value,
                            isSaving,
                            errorMessage,
                          } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

                          const {
                            type,
                            label,
                            description,
                            enumValues,
                          } = fieldDefinitionsById[fieldDefinitionId];

                          const options = type === 'enum' ? ['N/A', ...enumValues] : enumValues;

                          return (
                            <Grid
                              key={fieldDefinitionId}
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              style={{
                                cursor: 'pointer',
                                height: '110px',
                                flexGrow: 1,
                              }}
                            >
                              <Field
                                inlineEdit={false}
                                autoFocus={false}
                                type={type}
                                label={label}
                                helperText={Boolean(errorMessage) ? errorMessage : description}
                                error={Boolean(errorMessage)}
                                isEditable={true}
                                value={value}
                                options={options}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                }}
                                onKeyPress={(ev) => {
                                  if (ev.key === 'Enter') {
                                    dispatchNodeAction({
                                      type: 'SET_FIELD_VALUE',
                                      payload: {
                                        fieldDefinitionId,
                                        value: ev.target.value,
                                      },
                                    });
                                  }
                                }}
                                onChange={(ev) => {
                                  dispatchNodeAction({
                                    type: 'SET_FIELD_VALUE',
                                    payload: {
                                      fieldDefinitionId,
                                      value: ev.target.value,
                                    },
                                  });
                                  dispatchNodeAction({
                                    type: 'SET_FIELD_ERROR_MESSAGE',
                                    payload: {
                                      fieldDefinitionId,
                                      errorMessage: FIELD_VALIDATION[type]({value: ev.target.value, options: options}),
                                    },
                                  });
                                }}
                                onMouseDown={(ev) =>{
                                  ev.stopPropagation();
                                }}
                                disabled={false}
                                isSaving={isSaving}
                                required={false}
                              />
                            </Grid>
                          );
                        })
                      }
                    </Grid>
                  </PaddedContainer>
                </ContextualPanelContent>
                <ContextualPanelActions expanded={showPersonPanel}>
                  <Button
                    data-test-id="save-all-fields-button"
                    disabled={expandedPersonLoadingState !== 'loaded' || fieldsHaveErrors || Boolean(nodeNameValidationErrorMessage) || isNodeSaving}
                    onClick={() => {
                      saveAllFields();
                    }}
                  >
                    {isNodeSaving ? 'SAVING...' : 'SAVE'}
                  </Button>
                  <SecondButtonContainer>
                    <Button
                      data-test-id="cancel-button"
                      variant="text"
                      disabled={expandedPersonLoadingState !== 'loaded' || isNodeSaving}
                      onClick={() => {
                        revertAllFields();
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                          payload: 'default',
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  </SecondButtonContainer>
                </ContextualPanelActions>
              </>
            }

            {/* PANEL DISPLAY MODE: 'editLocation' */}
            {
              nodePanelDisplayMode === 'editLocation' &&
              <NodeLocationEdit
                panelDisplayMode={nodePanelDisplayMode}
                nodeState={nodeState}
                dispatchNodeAction={dispatchNodeAction}
                setError={setError}
              />
            }

            {/* PANEL DISPLAY MODE: 'addNewLink' */}
            {
              nodePanelDisplayMode === 'addNewLink' &&
              <>
                <ContextualPanelContent
                  panelDisplayMode={nodePanelDisplayMode}
                >
                  <PaddedContainer>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          cursor: 'pointer',
                          flexGrow: 1,
                          height: '100%',
                        }}
                      >
                        <NodeLinkCandidatesList
                          nodeState={nodeState}
                          dispatchNodeAction={dispatchNodeAction}
                          setError={setError}
                          handleLinkInfo={handleLinkInfo}
                        />
                      </Grid>
                    </Grid>
                  </PaddedContainer>
                </ContextualPanelContent>
                <ContextualPanelActions expanded={showPersonPanel}>
                  <Button
                    data-test-id="close-candidates-list-button"
                    disabled={false}
                    onClick={() => {
                      dispatchUserInterfaceAction({
                        type: 'SET_NODE_PANEL_DISPLAY_MODE',
                        payload: 'default',
                      });
                    }}
                  >
                    Done
                  </Button>
                </ContextualPanelActions>
              </>
            }

            {/* PANEL DISPLAY MODE: 'default' */}
            {
              nodePanelDisplayMode === 'default' &&
              <>
                <ContextualPanelContent
                  panelDisplayMode={nodePanelDisplayMode}
                >
                  <ContextualPanelTabs selectedTab={selectedNodePanelTab}>
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(0);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={0}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={(value) => {
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_SELECTED_TAB',
                          payload: value,
                        });
                      }}
                      label="Details"
                      icon={<InfoRoundedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(1);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={1}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={(value) => {
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_SELECTED_TAB',
                          payload: value,
                        });
                      }}
                      label="Attachments"
                      icon={<AttachFileRoundedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(2);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={2}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={(value) => {
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_SELECTED_TAB',
                          payload: value,
                        });
                      }}
                      label="Links"
                      icon={<LinkRoundedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(3);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={3}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={(value) => {
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_SELECTED_TAB',
                          payload: value,
                        });
                      }}
                      label="Tags"
                      icon={<LabelOutlinedIcon />}
                    />
                  </ContextualPanelTabs>
                  {
                    selectedNodePanelTab === 0 &&
                    <PersonDetails
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                      saveNodeField={saveNodeField}
                    />
                  }
                  {
                    selectedNodePanelTab === 1 &&
                    <NodeAttachments
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                    />
                  }
                  {
                    selectedNodePanelTab === 2 &&
                    <NodeLinks
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                      handleLinkInfo={handleLinkInfo}
                    />
                  }
                  {
                    selectedNodePanelTab === 3 &&
                    <NodeTags
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                    />
                  }
                  {
                    selectedNodePanelTab === 4 &&
                    <NodePermissionsTab
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      dispatchUserInterfaceAction={dispatchUserInterfaceAction}
                      setError={setError}
                    />
                  }
                </ContextualPanelContent>

                <ContextualPanelActions expanded={showPersonPanel}>
                  <Button
                    data-test-id="full-edit-button"
                    disabled={expandedPersonLoadingState !== 'loaded' || isNodeSaving || isNodeNameSaving}
                    onClick={async() => {
                      try {
                        await deleteEmptyNodeFields();
                        revertErroneousNodeFields();
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                          payload: 'editItem',
                        });
                      } catch(error) {
                        setError(error);
                      }
                    }}
                  >
                    EDIT
                  </Button>

                  {
                    !expandedPersonIsCurrentUser &&
                    <>
                      <SecondButtonContainer>
                        <Button
                          variant="icon-text"
                          onClick={(ev) => {
                            setPanelMenuAnchorEl(ev.currentTarget);
                            setIsPanelMenuOpen(true);
                          }}
                        >
                          <MoreHorizIcon />
                        </Button>
                      </SecondButtonContainer>

                      <Menu
                        open={isPanelMenuOpen}
                        onClose={handleClosePanelMenu}
                        anchorEl={panelMenuAnchorEl}
                        disablePortal={true}
                      >
                        <MenuItem
                          data-test-id="person-panel-context-menu-delete-button"
                          disabled={!nodeDeletePermissions || uploadsInProgress() || downloadsInProgress()}
                          onClick={() => {
                            closePanel(true);
                            dispatchUserInterfaceAction({
                              type: 'SET_DELETE_PERSON_ID',
                              payload: nodeId,
                            });
                            dispatchUserInterfaceAction({
                              type: 'SET_SHOW_DELETE_PERSON_DIALOG',
                              payload: true,
                            });
                          }}
                          color="error"
                        >
                          <Typography
                            variant="small"
                          >
                          Delete Person
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </>
                  }
                </ContextualPanelActions>
              </>
            }

            <NodeLinkInfoCard
              open={isLinkInfoOpen}
              loading={isLinkInfoLoading}
              handleClose={handleCloseLinkInfo}
              linkInfo={linkInfo}
            />

            <Message
              variant="success"
              open={showSuccessMessage}
              onClose={() => {
                setShowSuccessMessage(false);
              }}
              messageTitle="Item saved successfully"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              width={532}
            />
          </>
      }
    </ContextualPanel>
  );
};

PersonPanel.propTypes = {
  expandedPersonId: PropTypes.number,
  showPersonPanel: PropTypes.bool,
  dispatchUserInterfaceAction: PropTypes.func,
};

export default PersonPanel;
