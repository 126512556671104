import React from 'react';
import styled from '@emotion/styled';
import {
  wizardProgressHeight,
  wizardNavigationHeight,
  wizardStepHeadHeight,
} from 'constants/layout';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding-top: ${wizardProgressHeight + wizardNavigationHeight}px;
  padding-bottom: ${wizardProgressHeight + wizardNavigationHeight + wizardStepHeadHeight}px;  
`;

const WizardStep = ({
  children,
}) => {
  return (
    <Root>
      {children}
    </Root>
  );
};

export default WizardStep;
