import POST__backup from 'network-layer/endpoints/POST__backup';

function responseAdapter(response) {
  return response;
}

export default async function genNewBackup(authToken) {
  const response = await POST__backup(authToken);

  return responseAdapter(response);
}
