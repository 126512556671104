import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../../verbs';

export default async function PATCH__admin_types_fieldDefinitions_FIELDDEFINITIONID(authToken, perspectiveId, params) {
  const {
    fieldDefinitionId,
    ...patchParams
  } = params;

  return await PATCH(
    `/admin/types/field-definitions/${fieldDefinitionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...patchParams,
    },
  );
}
