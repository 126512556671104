import POST__nodeLinks from 'network-layer/endpoints/POST__nodeLinks';

function responseAdapter(response) {
  return response;
}

export default async function saveNodeLink(authToken, perspectiveId, params) {
  const response = await POST__nodeLinks(authToken, perspectiveId, params);

  return responseAdapter(response);
}
