import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const EditFieldWatcherDialog = ({
  open,
  onClose,
  onSave,
  controlsDisabled,
  saveDisabled,
  fieldWatcherTimeDelta,
  onSetFieldWatcherTimeDelta,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Edit Field Watcher
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="dense"
          disabled={controlsDisabled}
          value={fieldWatcherTimeDelta}
          onChange={event => {
            const value = event.target.value;
            onSetFieldWatcherTimeDelta(value);
          }}
          placeholder="time delta"
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={controlsDisabled}
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            saveDisabled ||
            controlsDisabled ||
            fieldWatcherTimeDelta === ''
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFieldWatcherDialog;
