import React from 'react';
import styled from '@emotion/styled';
import {
  wizardProgressHeight,
  wizardNavigationHeight,
  navigationWidth,
  navigationClearance,
  appBarHeight,
  gutter,
} from 'constants/layout';
import {
  greyLighter,
} from 'ui-library/color-palette';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  top: ${wizardProgressHeight + wizardNavigationHeight}px;
  width: 100%;
  padding: ${appBarHeight + gutter - wizardProgressHeight - wizardNavigationHeight}px ${navigationWidth + 2 * navigationClearance + gutter * 2}px 0 ${navigationWidth + 2 * navigationClearance + gutter * 2}px;
  background-color: ${greyLighter};
`;

const WizardStepHead = ({
  isVisible,
  children,
}) => {
  return (
    <Fade
      in={isVisible}
      timeout={{
        enter: 0,
        exit: 200,
      }}>
      <Root>
        {children}
      </Root>
    </Fade>
  );
};

WizardStepHead.defaultProps = {
  isVisible: true,
};

export default WizardStepHead;
