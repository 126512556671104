import React from 'react';
import styled from '@emotion/styled';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
`;

const PageContent = ({
  children,
}) => {
  return (
    <Fade
      in
      timeout={700}>
      <Root>
        {children}
      </Root>
    </Fade>
  );
};

export default PageContent;
