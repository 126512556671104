function selectNotificationPipelines(DM) {
  const {
    notificationPipelinesById,
    notificationPipelineIds,
  } = DM;

  return (
    notificationPipelineIds
      .map(notificationPipelineId => notificationPipelinesById[notificationPipelineId])
  );
}

export default selectNotificationPipelines;
