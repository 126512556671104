import React from 'react';
import styled from '@emotion/styled';
import Fade from '@material-ui/core/Fade';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CenterpieceCheckmark from 'components/chrome/CenterpieceCheckmark';
import Link from 'ui-library/components/Link';
import MailRoundedIcon from '@material-ui/icons/MailRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 32px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
`;


const SaveConfirmationScreen = ({
  connectionState,
  navigateToStep,
  closeWizardWithPrepend,
  currentPerspectiveName,
}) => {

  const {
    connectionType,
    connectionToken,
  } = connectionState;

  return (
    <Fade in timeout={400}>
      <Root>
        <CenterpieceCheckmark/>
        <Typography
          variant="h2"
          fontWeight="bold"
          color="secondary"
        >
          Your Connection was added successfully!
        </Typography>
        {
          connectionType === 'outgoing' ?
            <>
              <Typography
                variant="lead"
                fontWeight="normal"
                color="black"
              >
                Your &nbsp;
                <Typography
                  variant="lead"
                  fontWeight="bold"
                  color="black"
                >
                  Connection Code &nbsp;
                </Typography>
                is:
              </Typography>
              <span
                style={{
                  wordBreak: 'break-all',
                }}
              >
                <Typography
                  variant="h2"
                  fontWeight="bold"
                >
                  {connectionToken}
                </Typography>
              </span>
              <Typography
                variant="lead"
                fontWeight="normal"
                color="black"
              >
                In order to share data through this connection, you must send the Connection Code to the other party.
              </Typography>
              <Actions>
                <Button
                  variant="primary"
                  startIcon={<MailRoundedIcon />}
                  onClick={() => {
                    const href =
                      'mailto:?subject=A Jules Connection Code has been shared with you!' +
                      '&body=' +
                      `${currentPerspectiveName} is inviting you to collaborate using Jules!` +
                      '%0D%0A%0D%0A' +
                      'Below is your unique Connection Code. Use this code to accept this collaboration request from your Jules web or mobile app.' +
                      '%0D%0A%0D%0A' +
                      `${connectionToken}` +
                      '%0D%0A%0D%0A' +
                      'Step-by-step instructions to accept Connection Code:' +
                      '%0D%0A%0D%0A' +
                      '1. Log in to your Jules account. If you do not have a Jules account, click on any of the links below to get started today!' +
                      '%0D%0A' +
                      '  Log in to Jules Web: https://jules.app/sign-in' +
                      '%0D%0A' +
                      '  Login to Jules Mobile (iOS): https://apps.apple.com/us/app/jules-mobile/id1443574567' +
                      '%0D%0A' +
                      '  Log in to Jules Mobile (Android): https://play.google.com/store/apps/details?id=app.jules.mobile%26hl=en_US%26gl=US' +
                      '%0D%0A' +
                      '2. Go to "Add" Page from Navigation Bar' +
                      '%0D%0A' +
                      '3. Select "Connection"' +
                      '%0D%0A' +
                      '4. Select "Incoming"' +
                      '%0D%0A' +
                      '5. Copy %26 Paste your Connection Code from above and Name your new Connection' +
                      '%0D%0A' +
                      '6. Continue and you will see your Connection has been successfully established!' +
                      '%0D%0A%0D%0A' +
                      'Accepting this Connection does not share any of your Jules information with this Connection except your account name. Once you have established this Connection, you will be able to access and manage ' + currentPerspectiveName + '\'s information in accordance to the permissions you have been provided by ' + currentPerspectiveName + '. Through this Connection, you may also share access to your information with ' + currentPerspectiveName + '. In order to share your information, you must be logged in to Jules Web.' +
                      '%0D%0A%0D%0A';

                    window.location.href = href;
                  }}
                >
                  send code by email
                </Button>
                <Button
                  variant="primary"
                  startIcon={<FileCopyRoundedIcon />}
                  onClick={() => {
                    navigator.clipboard.writeText(connectionToken);
                  }}
                >
                  copy code to clipboard
                </Button>
                <Button
                  data-test-id="add-connection-wizard-finish-button"
                  variant="outlined"
                  onClick={() => {
                    closeWizardWithPrepend();
                  }}
                >
                  Finish
                </Button>
              </Actions>
            </>
            :
            <Actions>
              <Button
                data-test-id="add-connection-wizard-finish-button"
                variant="primary"
                onClick={() => {
                  closeWizardWithPrepend();
                }}
              >
                Finish
              </Button>
            </Actions>
        }
        <Typography
          variant="lead"
          fontWeight="normal"
          color="black"
        >
          You can manage all of your Connections from the &nbsp;
          <Link
            data-test-id="connections-page-link"
            value="/connections"
            label="Connections Page"
            variant="lead"
            fontWeight="normal"
            color="primary"
          />
          &nbsp;.
        </Typography>
      </Root>
    </Fade>
  );
};

export default SaveConfirmationScreen;
