import React from 'react';
import styled from '@emotion/styled';
import Logo from 'images/logo.png';

const LogoContainer = styled.div`
  height: 125px;
  width: 179px;
`;

const UnauthenticatedHeader = () => {
  return (
    <LogoContainer>
      <img alt="Jules" src={Logo} />
    </LogoContainer>
  );
};

export default UnauthenticatedHeader;
