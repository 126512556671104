import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PUT} from '../verbs';

export default async function PUT__payments_paymentMethod(authToken, perspectiveId, params) {
  const {
    stripePayload,
  } = params;

  const url = '/payments/payment-method';

  return await PUT(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      stripePayload,
    },
  );
}
