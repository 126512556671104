import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__locations_ID_children(authToken, perspectiveId, params) {
  const {
    locationId,
    connectionId,
  } = params;

  return await GET(
    `/locations/${locationId}/children${connectionId !== undefined ? `?connectionId=${connectionId}` : ''}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
