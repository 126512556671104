import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import styled from '@emotion/styled';

const DragHandleRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const DragHandle = () => {
  return (
    <DragHandleRoot>
      <DragHandleIcon/>
    </DragHandleRoot>
  );
};

function computeIndexPatches(fieldDefinitions, dragResult) {
  const {
    source: {
      index: dragSourceIndex,
    },
    destination: {
      index: dragDestinationIndex,
    },
  } = dragResult;

  const affectedFieldDefinitions = fieldDefinitions
    .slice(
      dragSourceIndex < dragDestinationIndex ? dragSourceIndex + 1 : dragDestinationIndex,
      dragSourceIndex < dragDestinationIndex ? dragDestinationIndex + 1 : dragSourceIndex,
    );

  const affectedFieldDefinitionsPatches = affectedFieldDefinitions.map(fieldDefinition => {
    const {
      id,
      index,
      label,
    } = fieldDefinition;
    return {
      id,
      label,
      oldIndex: index,
      newIndex: dragSourceIndex < dragDestinationIndex ? index - 1 : index + 1,
    };
  });

  return [
    {
      id: fieldDefinitions[dragSourceIndex].id,
      label: fieldDefinitions[dragSourceIndex].label,
      oldIndex: fieldDefinitions[dragSourceIndex].index,
      newIndex: dragSourceIndex < dragDestinationIndex ? affectedFieldDefinitionsPatches[affectedFieldDefinitionsPatches.length - 1].oldIndex : affectedFieldDefinitionsPatches[0].oldIndex,
    },
    ...affectedFieldDefinitionsPatches,
  ];
}

const FieldDefinitionsList = ({
  fieldDefinitions,
  onAddFieldDefinition,
  onRemoveFieldDefinition,
  onEditFieldDefinition,
  onReorderFieldDefinitions,
  controlsDisabled,
}) => {

  return (
    <>
      <DragDropContext
        onDragEnd={result => {
          const {
            source: {
              index: dragSourceIndex,
            },
            destination: {
              index: dragDestinationIndex,
            },
          } = result;

          if (dragSourceIndex === dragDestinationIndex) {
            return;
          }

          onReorderFieldDefinitions(computeIndexPatches(fieldDefinitions, result));
        }}>
        <Droppable
          droppableId="droppable">
          {(provided, snapshot) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {
                  fieldDefinitions
                    .sort((fd1, fd2) => {
                      const {
                        index: index1,
                      } = fd1;
                      const {
                        index: index2,
                      } = fd2;
                      if (index1 < index2) {
                        return -1;
                      }
                      if (index1 > index2) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((fieldDefinition, index) => {
                      const {
                        id,
                        label,
                        type,
                        description,
                        enumValues,
                      } = fieldDefinition;
                      return (
                        <Draggable
                          key={id}
                          draggableId={`${id}`}
                          index={index}>
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}>
                                <NestedBox>
                                  <ContentLine>
                                    <DragHandle/>
                                    &nbsp;&nbsp;
                                    <ContentLineText>
                                      <Typography
                                        variant="body1">
                                        {label}
                                      </Typography>
                                      <Typography
                                        variant="body2">
                                        <strong>({type}{type === 'enum' ? `: ${enumValues.join(', ')}` : ''})</strong>
                                      </Typography>
                                      <Typography
                                        variant="body2">
                                        <em>{description}</em>
                                      </Typography>
                                    </ContentLineText>
                                    <Tooltip
                                      placement="top"
                                      title={`edit "${label}"`}>
                                      <span>
                                        <IconButton
                                          disabled={controlsDisabled}
                                          onClick={() => {
                                            onEditFieldDefinition(id);
                                          }}>
                                          <EditIcon/>
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                    <Tooltip
                                      placement="top"
                                      title={`remove "${label}"`}>
                                      <span>
                                        <IconButton
                                          disabled={controlsDisabled}
                                          onClick={() => {
                                            onRemoveFieldDefinition(id);
                                          }}>
                                          <RemoveIcon/>
                                        </IconButton>
                                      </span>
                                    </Tooltip>
                                  </ContentLine>
                                </NestedBox>
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })
                }
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <br/>

      <Tooltip
        placement="top"
        title="add a Field Definition">
        <span>
          <IconButton
            disabled={false}
            onClick={() => {
              onAddFieldDefinition();
            }}>
            <AddIcon/>
          </IconButton>
        </span>
      </Tooltip>
      <br/><br/>
    </>
  );
};

function areFieldDefinitionsTheSame(previousProps, nextProps) {
  const {
    fieldDefinitions: previousFieldDefinitions,
  } = previousProps;
  const {
    fieldDefinitions: nextFieldDefinitions,
  } = nextProps;

  if (previousFieldDefinitions.length !== nextFieldDefinitions.length) {
    return false;
  }

  for (let i = 0; i < previousFieldDefinitions.length; i++) {
    const previousFieldDefinition = previousFieldDefinitions[i];
    const nextFieldDefinition = nextFieldDefinitions[i];

    const {
      label: previousFieldDefinitionLabel,
      description: previousFieldDefinitionDescription,
      type: previousFieldDefinitionType,
      index: previousFieldDefinitionIndex,
      enumValues: previousFieldDefinitionEnumValues,
    } = previousFieldDefinition;
    const {
      label: nextFieldDefinitionLabel,
      description: nextFieldDefinitionDescription,
      type: nextFieldDefinitionType,
      index: nextFieldDefinitionIndex,
      enumValues: nextFieldDefinitionEnumValues,
    } = nextFieldDefinition;

    if (
      previousFieldDefinitionLabel !== nextFieldDefinitionLabel ||
      previousFieldDefinitionDescription !== nextFieldDefinitionDescription ||
      previousFieldDefinitionType !== nextFieldDefinitionType ||
      previousFieldDefinitionIndex !== nextFieldDefinitionIndex ||
      previousFieldDefinitionEnumValues !== nextFieldDefinitionEnumValues
    ) {
      return false;
    }
  }

  return true;
}

export default React.memo(FieldDefinitionsList, areFieldDefinitionsTheSame);
