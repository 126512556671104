import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {POST} from '../verbs';

export default async function POST_subscriptions_currentSubscription_renew(authToken, perspectiveId) {
  const url = '/subscriptions/current-subscription/renew';

  return await POST(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {},
  );
}
