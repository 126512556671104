import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import Typography from 'ui-library/components/Typography';
import SearchIcon from '@material-ui/icons/SearchRounded';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import MUIRadio from '@material-ui/core/Radio';
import Button from 'ui-library/components/Button';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';

const useStyles = makeStyles({
  root: {
    display: theme.filterCriterion.root.display,
    margin: theme.filterCriterion.root.margin,
    cursor: theme.filterCriterion.root.cursor,
  },
  valueContainer: {
    display: theme.filterCriterion.valueContainer.display,
  },
  value: {
    whiteSpace: theme.filterCriterion.value.whiteSpace,
    overflow: theme.filterCriterion.value.overflow,
    textOverflow: theme.filterCriterion.value.textOverflow,
  },
  openValue: {
    whiteSpace: theme.filterCriterion.value.whiteSpace,
    overflow: theme.filterCriterion.value.overflow,
    textOverflow: theme.filterCriterion.value.textOverflow,
    width: theme.filterCriterion.value.width,
  },
  menu: {
    minHeight: theme.filterDrillDown.minHeight,
    maxHeight: theme.filterDrillDown.maxHeight,
    overflow: theme.filterDrillDown.overflow,
  },
  menuItem: {
    display: theme.filterDrillDown.display,
  },
  searchInput: {
    color: theme.filterDrillDown.searchInput.input.color,
    fontSize: theme.filterDrillDown.searchInput.input.fontSize,
    borderBottom: theme.filterDrillDown.searchInput.input.borderBottom,
    height: theme.filterDrillDown.searchInput.input.height,
    minWidth: theme.filterDrillDown.searchInput.input.minWidth,
    marginTop: theme.filterDrillDown.searchInput.input.marginTop,
  },
  searchInputFocused: {
    // adornment
    borderBottom: theme.filterDrillDown.searchInput.borderBottom,
    '& .MuiSvgIcon-root': {
      color: theme.filterDrillDown.searchInput.activeColor,
    },
  },
  searchIcon: {
    color: theme.filterDrillDown.searchIcon.color,
    width: theme.filterDrillDown.searchIcon.width,
    height: theme.filterDrillDown.searchIcon.height,
    marginLeft: theme.filterDrillDown.searchIcon.marginLeft,
  },
  backContainer: {
    minWidth: theme.filterDrillDown.minWidth,
  },
  radio: {
    color: theme.filterDrillDown.radioButton.color,
    '&:hover': {
      backgroundColor: theme.filterDrillDown.radioButton.backgroundColor,
    },
    '&$checked': {
      color: theme.filterDrillDown.radioButton['checked'].color,
      '&:hover': {
        backgroundColor: theme.filterDrillDown.radioButton.backgroundColor,
      },
    },
  },
  checked: {},
  optionContainer: {
    display: theme.filterDrillDown.optionContainer.display,
    flexDirection: theme.filterDrillDown.optionContainer.flexDirection,
    flexGrow: theme.filterDrillDown.optionContainer.flexGrow,
    justifyContent: theme.filterDrillDown.optionContainer.justifyContent,
    alignItems: theme.filterDrillDown.optionContainer.alignItems,
  },
  noResultsContainer: {
    minWidth: theme.filterDrillDown.noResultsContainer.minWidth,
    height: theme.filterDrillDown.noResultsContainer.height,
    display: theme.filterDrillDown.noResultsContainer.display,
    alignItems: theme.filterDrillDown.noResultsContainer.alignItems,
    justifyContent: theme.filterDrillDown.noResultsContainer.justifyContent,
  },
  loadingContainer: {
    display: theme.filterDrillDown.loadingContainer.display,
    height: theme.filterDrillDown.loadingContainer.height,
    alignItems: theme.filterDrillDown.loadingContainer.alignItems,
    justifyContent: theme.filterDrillDown.loadingContainer.justifyContent,
    '& .centerpiece-spinner > div': {
      width: theme.filterDrillDown.loadingContainer.spinner.width,
      height: theme.filterDrillDown.loadingContainer.spinner.height,
    },
  },
});

const FilterDrillDown = ({
  index: mainIndex,
  filterPillValue,
  updateFilterPillValue,
  options: rootOptions,
  onLoadTree,
  removeFilterPillCriterion,
}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState('');
  const [filteredOptions, setFilteredOptions] = React.useState(rootOptions);

  const [activeTree, setActiveTree] = React.useState([]);
  const [levelOptions, setLevelOptions] = React.useState([]);

  const [activeRootId, setActiveRootId] = React.useState(null);
  const [isRootLevel, setIsRootLevel] = React.useState(true);
  const [currentLevelParentId, setCurrentLevelParentId] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const [displayValue, setDisplayValue] = React.useState('');

  const selectedValue = filterPillValue[0] ? filterPillValue[0] : null;
  const selectedValueId = selectedValue ? selectedValue.id : null;

  const MenuRefrence = React.createRef();
  const classes = useStyles();

  async function handleLoadTree(rootNodeId) {
    try {
      setIsLoading(true);
      const tree = await onLoadTree(rootNodeId);
      setActiveTree(tree);
      setIsLoading(false);
    } catch(err) {
      throw err;
    }
  }

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
    if (filterPillValue.length === 0) {
      removeFilterPillCriterion(mainIndex);
    }
  };

  React.useEffect(() => {
    if (MenuRefrence.current) {
      setAnchorEl(MenuRefrence.current);
    }
    if (filterPillValue.length === 0) {
      setIsOpen(true);
    }
  }, [MenuRefrence, filterPillValue]);

  React.useEffect(() => {
    const getSelectedValueDisplayValue = async(node) => {
      if (!node) {
        setDisplayValue('');
        return;
      }
      const {
        id,
        parentId: currentParentId,
        rootId,
      } = node;
      if (!currentParentId) {
        const name = rootOptions.find(option => option.id === id).name;
        setDisplayValue(name);
        return;
      }

      try {
        const tree = await onLoadTree(rootId);
        const name = tree.find(option => option.id === id).name;
        const locationPath = [name];
        let currentParent = tree.find(node => node.id === currentParentId);
        while (currentParent !== undefined) {
          const {
            name: parentName,
            parentId,
          } = currentParent;
          locationPath.unshift(parentName);
          currentParent = tree.find(node => node.id === parentId);
        }
        locationPath.unshift(rootOptions.find(node => node.id === rootId).name);
        setDisplayValue(locationPath.join(' / '));
      } catch(err) {
        throw err;
      }
    };
    getSelectedValueDisplayValue(selectedValue);
  }, [onLoadTree, rootOptions, selectedValue]);

  // when criteria for this filter pill is edited, we need to reset tree
  React.useEffect(() => {
    if (selectedValue === null) {
      setActiveTree([]);
      setCurrentLevelParentId(null);
    }
  }, [selectedValue]);

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!currentLevelParentId) {
      setIsRootLevel(true);
      setActiveRootId(null);
      setLevelOptions(searchQuery ? filteredOptions : rootOptions);
    }
    if (currentLevelParentId) {
      setLevelOptions(activeTree.filter(
        location => location.parentId === currentLevelParentId,
      ));
    }
  }, [activeTree, currentLevelParentId, filteredOptions, isLoading, rootOptions, searchQuery]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        handleClose();
      }
    };
    document.body.addEventListener('scroll', handleScroll);
    return () => document.body.removeEventListener('scroll', handleScroll);
  });

  if (isOpen) {
    return (
      <>
        <div ref={MenuRefrence} className={classes.root} onBlur={handleClose}>
          <div className={classes.valueContainer}>
            <div className={classes.openValue}>
              <Typography
                variant="x-small"
                fontWeight="bold"
              >
                {displayValue}
              </Typography>
            </div>
          </div>
        </div>

        {
          anchorEl &&
          <Menu
            open={isOpen}
            classes={{
              root: classes.menu,
            }}
            onClose={handleClose}
            anchorEl={anchorEl}>
            {
              isRootLevel ?
                <Input
                  data-test-id="search-root-level-input"
                  classes={{
                    root: classes.searchInput,
                    focused: classes.searchInputFocused,
                  }}
                  type="text"
                  fullWidth
                  disableUnderline
                  value={searchQuery}
                  placeholder="Search"
                  onChange={(event) => {
                    setSearchQuery(event.target.value);
                    setFilteredOptions(rootOptions.filter(option => {
                      const {name} = option;
                      return name.toLowerCase().includes(event.target.value.toLowerCase());
                    }));
                  }}
                  startAdornment={
                    <SearchIcon className={classes.searchIcon}/>
                  }
                />
                :
                <div className={classes.backContainer}>
                  <Button
                    data-test-id="tree-level-back-button"
                    variant="text"
                    startIcon={<ArrowBackIcon />}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      const currentLevelParent = activeTree.find(node => node.id === currentLevelParentId);
                      setCurrentLevelParentId(currentLevelParent ? currentLevelParent.parentId : null);
                    }}>
                    Back
                  </Button>
                  <Divider />
                </div>
            }
            {
              isLoading ? <div className={classes.loadingContainer}><CenterpieceSpinner/></div> :
                levelOptions.length > 0 ? levelOptions.map((option, index) => {
                  const {
                    id: currentId,
                    name,
                  } = option;
                  const isLeaf = isRootLevel ? false : activeTree.find(node => node.parentId === currentId) ? false : true;
                  return (
                    <MenuItem
                      key={index}
                      disableGutters
                      classes={{
                        root: classes.menuItem,
                      }}
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}>
                      <MUIRadio
                        data-test-id="select-option-radio-button"
                        disableRipple
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                        checkedIcon={<CheckCircleIcon/>}
                        checked={selectedValueId === currentId}
                        onChange={(ev) => {
                          ev.stopPropagation();
                          updateFilterPillValue(mainIndex, [{...option, rootId: activeRootId}]);
                          setSearchQuery('');
                          setIsOpen(false);
                        }}
                      />
                      <div
                        className={classes.optionContainer}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          if (isLeaf) {
                            return;
                          }
                          if (isRootLevel) {
                            handleLoadTree(currentId);
                            setActiveRootId(currentId);
                            setIsRootLevel(false);
                            setSearchQuery('');
                          }
                          setCurrentLevelParentId(currentId);
                        }}>
                        <Typography variant="small">
                          {name}
                        </Typography>
                        {
                          !isLeaf &&
                          <Button
                            data-test-id="tree-next-level-button"
                            variant="custom"
                            textTransform="none"
                            customBgColor="transparent"
                            customTextColor="primary"
                            customHoverBgColor="transparent"
                            customHoverTextColor="primary"
                            customActiveBgColor="transparent"
                            customActiveTextColor="primary"
                          >
                            <ChevronRightIcon />
                          </Button>
                        }
                      </div>
                    </MenuItem>
                  );
                }) :
                  <div className={classes.noResultsContainer}>
                    <Typography variant="small">
                      No available options
                    </Typography>
                  </div>
            }
          </Menu>
        }
      </>
    );
  }

  return (
    <div
      ref={MenuRefrence}
      className={classes.root}
      onClick={(ev) => {
        ev.stopPropagation();
        if (selectedValue) {
          const {
            rootId,
            parentId,
          } = selectedValue;
          if (rootId !== activeRootId) {
            rootId && handleLoadTree(rootId);
            setActiveRootId(rootId);
          }
          if (parentId) {
            setIsRootLevel(false);
          }
          setCurrentLevelParentId(parentId);
        }
        setIsOpen(true);
      }}>
      <div className={classes.valueContainer}>
        <div className={classes.value}>
          <Typography
            variant="x-small"
            fontWeight="bold"
          >
            {displayValue}
          </Typography>
        </div>
      </div>
    </div>
  );
};

FilterDrillDown.propTypes = {
  index: PropTypes.number,
  filterPillValue: PropTypes.array,
  updateFilterPillValue: PropTypes.func,
  options: PropTypes.array,
  onLoadTree: PropTypes.func,
  removeFilterPillCriterion: PropTypes.func,
};

export default FilterDrillDown;
