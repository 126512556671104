import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import {COLOR_MONIKERS} from 'ui-library/color-palette';

const useStyles = makeStyles({
  progressBarRail: {
    width: theme.nodeAttachmentProgressBar.rail.width,
    height: theme.nodeAttachmentProgressBar.rail.height,
    margin: theme.nodeAttachmentProgressBar.rail.margin,
    borderRadius: theme.nodeAttachmentProgressBar.rail.borderRadius,
    backgroundColor: props => props.hasError ? theme.nodeAttachmentProgressBar.rail.errorBackgroundColor : theme.palette[props.railBarColor],
  },
  progressBarProgress: {
    height: theme.nodeAttachmentProgressBar.progress.height,
    borderRadius: theme.nodeAttachmentProgressBar.progress.borderRadius,
    transition: theme.nodeAttachmentProgressBar.progress.transition,
    backgroundColor: props => props.hasError ? theme.nodeAttachmentProgressBar.progress.errorBackgroundColor : theme.palette[props.progressBarColor],
    width: props => props.progress,
  },
});

const ProgressBar = ({
  progress,
  progressColor,
  railColor,
  hasError,
}) => {


  const progressBarColor = progressColor ? progressColor : 'primary';
  const railBarColor = railColor ? railColor : 'grey-semi';

  const classes = useStyles({
    hasError,
    progress,
    progressBarColor,
    railBarColor,
  });

  return (
    <div className={classes.progressBarRail}>
      <div className={classes.progressBarProgress} />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.string,
  progressColor: PropTypes.oneOf(COLOR_MONIKERS),
  railColor: PropTypes.oneOf(COLOR_MONIKERS),
  hasError: PropTypes.bool,
};

export default ProgressBar;
