import React from 'react';
import styled from '@emotion/styled';
import {
  contextualPanelNavigationHeight,
  contextualPanelHeaderHeight,
  gutter,
} from 'constants/layout';
import {
  greyLightest,
  greyLighter,
} from 'ui-library/color-palette';

const Root = styled.div`
  margin-top: ${contextualPanelNavigationHeight}px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: ${props => props.height || `${contextualPanelHeaderHeight}px`};
  padding: 0 ${gutter}px;
  background-color: ${props => props.backgroundColor};
  transition: right .3s ease;
`;

const ContextualPanelHeader = ({
  panelDisplayMode,
  children,
  height,
}) => {

  const backgroundColor = panelDisplayMode === 'default' ? greyLightest : greyLighter;

  return (
    <Root
      backgroundColor={backgroundColor}
      height={height}
    >
      {children}
    </Root>
  );
};

export default ContextualPanelHeader;
