import React from 'react';
import PageContainer from 'components/chrome/PageContainer';
import PageTitle from 'components/chrome/PageTitle';
import PageDescription from 'components/chrome/PageDescription';
import PageContent from 'components/chrome/PageContent';
import {Route, Switch, Redirect} from 'react-router-dom';
import LibraryContext from './context';
import LibraryFilterInput from 'components/LibraryFilterInput';
import InternalRecordRoute from './InternalRecordRoute';
import InternalRecordsListRoute from './InternalRecordsListRoute';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';
import DrilldownTracker from 'components/DrilldownTracker';
import DrilldownTrackerSegment from 'components/DrilldownTrackerSegment';
import genLibraryBreadcrumbsFromPathname from './genLibraryBreadcrumbsFromPathname';
import breadcrumbComponentsFromBreadcrumbs from 'components/Breadcrumbs/breadcrumbComponentsFromBreadcrumbs';
import genLibraryLocationBreadcrumbsFromLocation from './genLibraryLocationBreadcrumbsFromLocation';

const Library = ({
  match,
  history,
  location,
}) => {
  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } = React.useContext(UserContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = React.useContext(PerspectiveContext);
  const {
    nodeIdModifiedDestructivelyForLists,
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const [filter, setFilter] = React.useState('');
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  const [breadcrumbStatus, setBreadcrumbsStatus] = React.useState('loading');
  const [fatalError, setFatalError] = React.useState(null);

  const currentPathname = location.pathname;
  React.useEffect(() => {
    async function constructBreadcrumbs() {
      setBreadcrumbsStatus('loading');
      try {
        let breadcrumbs = await genLibraryBreadcrumbsFromPathname(currentPathname, authToken, currentPerspectiveId);

        if (currentPathname.indexOf('/by-location?locations=')) {
          const locationBreadcrumbs = await genLibraryLocationBreadcrumbsFromLocation(location, authToken, currentPerspectiveId);
          breadcrumbs = breadcrumbs.concat(locationBreadcrumbs);
        }

        setBreadcrumbs(breadcrumbs);
      } catch(error) {
        setFatalError(error);
      }
      setBreadcrumbsStatus('ready');
    }
    constructBreadcrumbs();
  }, [authToken, currentPathname, currentPerspectiveId, location]);

  if (fatalError) {
    throw fatalError;
  }

  return (
    <PageContainer>
      <PageTitle>
        Library
      </PageTitle>
      <PageDescription>
        Browse items by record.
      </PageDescription>
      <PageContent>
        <LibraryFilterInput
          type="text"
          value={filter}
          onChange={event => setFilter(event.target.value)}
          placeholder="Type to filter..."
        />

        <DrilldownTracker>
          <DrilldownTrackerSegment
            weight="1"
          >
            {breadcrumbComponentsFromBreadcrumbs(breadcrumbs, breadcrumbStatus, history)}
          </DrilldownTrackerSegment>
        </DrilldownTracker>

        <LibraryContext.Provider
          value={{
            authToken,
            setAuthToken,
            setAuthenticationStatus,
            currentPerspectiveId,
            nodeIdModifiedDestructivelyForLists,
            dispatchUserInterfaceAction,
            filter,
            pushAndClearFilter: (...args) => {
              setFilter('');
              history.push(...args);
            },
          }}>
          <Switch>
            <Route
              exact path={`${match.path}/records`}
              component={InternalRecordsListRoute}
            />
            <Route
              path={`${match.path}/records/:recordId`}
              component={InternalRecordRoute}
            />
            <Redirect
              from={match.path}
              to={`${match.path}/records`}
            />
          </Switch>
        </LibraryContext.Provider>
      </PageContent>
    </PageContainer>
  );
};

export default Library;
