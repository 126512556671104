import React from 'react';
import Typography from 'ui-library/components/Typography';
import BannerMessage from 'ui-library/components/BannerMessage';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import styled from '@emotion/styled';

import SubscriptionContext from 'contexts/Subscription';

import ApplicationBar from 'components/ApplicationBar';
import LogOutModal from 'components/LogOutModal';

const MessageRow = styled.div`
  display: flex;
  flex-grow: 1;
`;

const SuspendedSubscriptionCompany = () => {

  const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);

  const subscription = React.useContext(SubscriptionContext);
  const {
    currentSubscriptionName,
    currentSubscriptionDescription,

  } = subscription;


  return (
    <>
      <ApplicationBar
        setIsLogoutModalOpen={setIsLogoutModalOpen}
        enablePerspectiveSwitcher={false}
        enableAccountPanel={false}
        enableNotifications={false}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '65px 50px 50px 50px',
      }}>
        <div style={{
          display: 'flex',
          height: 80,
        }}>
          <Typography
            variant="h3"
            fontWeight="bold"
          >
            Welcome to Jules!
          </Typography>
        </div>

        <div style={{
          display: 'flex',
          marginBottom: 50,
        }}>
          <BannerMessage
            icon={
              <InfoRoundedIcon
                fontSize="large"
              />
            }
            title="This membership has expired."
            type="error"
          >
            <MessageRow>
              <Typography
                variant="body"
              >
                Contact your marketing representative to restore access to your Jules account.
              </Typography>
            </MessageRow>
          </BannerMessage>
        </div>

        <div style={{
          display: 'flex',
          height: 80,
          alignItems: 'center',
          paddingLeft: 20,
        }}>
          <Typography
            variant="h5"
            fontWeight="bold"
          >
            Subscription type
          </Typography>
        </div>
        <div style={{
          display: 'flex',
          height: 30,
          alignItems: 'center',
          paddingLeft: 20,
        }}>
          <Typography
            variant="small"
            fontWeight="bold"
          >
            {currentSubscriptionName.toUpperCase()}
          </Typography>
        </div>
        <div style={{
          display: 'flex',
          height: 30,
          alignItems: 'center',
          paddingLeft: 20,
        }}>
          <Typography
            variant="caption"
          >
            {currentSubscriptionDescription}
          </Typography>
        </div>
      </div>
      <LogOutModal
        isOpen={isLogoutModalOpen}
        handleClose={() => {
          setIsLogoutModalOpen(false);
        }}
      />
    </>
  );
};

export default SuspendedSubscriptionCompany;
