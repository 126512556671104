const notificationsById = notifications => {
  const notificationsById = {};

  for (let notification of notifications) {
    notificationsById[notification.id] = notification;
  }

  return notificationsById;
};

const notificationIds = notifications => notifications.map(notification => notification.id);

const notificationTypesById = notificationTypes => {
  const notificationTypesById = {};

  for (let notificationType of notificationTypes) {
    notificationTypesById[notificationType.id] = notificationType;
  }

  return notificationTypesById;
};

const notificationTypeIds = notificationTypes => notificationTypes.map(notificationType => notificationType.id);

/* -------------------------------------------------------------------------- */


const initNotificationsState = () => {
  return {
    notificationsById: {},
    notificationIds: [],
    notificationTypesById: {},
    notificationTypeIds: [],
    hasMore: false,
    pushStatus: 'disabled',
  };
};

const notificationsReducer = (state, {type: actionType, payload}) => {
  switch(actionType) {
    case 'HYDRATE_NOTIFICATIONS':
      return {
        ...state,
        notificationsById: notificationsById(payload),
        notificationIds: notificationIds(payload),
        hasMore: payload.length > 0,
      };
    case 'HYDRATE_ADDITIONAL_NOTIFICATIONS': {
      return {
        ...state,
        hasMore: payload.length > 0,
        notificationsById: {...state.notificationsById, ...notificationsById(payload)},
        notificationIds: [...state.notificationIds, ...notificationIds(payload)],
      };
    }
    case 'TOGGLE_NOTIFICATION_READ_STATUS': {
      const {
        id,
      } = payload;
      const {
        notificationsById,
        notificationsById: {
          [id]: data,
        },
      } = state;

      return {
        ...state,
        notificationsById: {
          ...notificationsById,
          [id]: {
            ...data,
            readStatus: notificationsById[id].readStatus === 'read' ? 'unread' : 'read',
          },
        },
      };
    }
    case 'DELETE_NOTIFICATION': {
      const {
        id,
      } = payload;

      const {
        notificationIds,
        notificationsById,
      } = state;

      const index = notificationIds.indexOf(id);
      delete notificationsById[id];

      return {
        ...state,
        notificationsById: {
          ...notificationsById,
        },
        notificationIds: [
          ...notificationIds.slice(0, index),
          ...notificationIds.slice(index + 1),
        ],
      };
    }
    case 'DELETE_ALL_NOTIFICATIONS': {
      return {
        ...state,
        notificationsById: {},
        notificationIds: [],
        hasMore: false,
      };
    }
    case 'MARK_ALL_AS_READ': {
      const {
        notificationIds,
      } = state;

      let readNotifications = {};
      for (let id of notificationIds) {
        const {
          notificationsById: {
            [id]: data,
          },
        } = state;
        readNotifications[id] = data;
        readNotifications[id].readStatus = 'read';
      }
      return {
        ...state,
        notificationsById: readNotifications,
      };
    }
    case 'HYDRATE_NOTIFICATION_TYPES':
      return {
        ...state,
        notificationTypesById: notificationTypesById(payload),
        notificationTypeIds: notificationTypeIds(payload),
      };
    case 'TOGGLE_NOTIFICATION_TYPE': {
      const {
        notificationTypeId,
        enabledStatus,
      } = payload;

      const {
        notificationTypesById,
        notificationTypesById: {
          [notificationTypeId]: data,
        },
      } = state;
      return {
        ...state,
        notificationTypesById: {
          ...notificationTypesById,
          [notificationTypeId]: {
            ...data,
            enabledStatus: enabledStatus,
          },
        },
      };
    }
    case 'HYDRATE_PUSH_STATUS': {
      return {
        ...state,
        pushStatus: payload,
      };
    }
    default:
      throw new Error(`Unknown notification action type "${actionType}"!`);
  }
};

export {
  initNotificationsState,
  notificationsReducer,
};
