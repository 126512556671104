import selectVirtualLocationTreeNodeHints from './selectVirtualLocationTreeNodeHints';

function selectVirtualLocationTreeNodeHintById(
  configurationState,
  virtualLocationTreeNodeHintId,
) {
  return (
    selectVirtualLocationTreeNodeHints(configurationState)
      .find(virtualLocationTreeNodeHint => {
        const {
          id,
        } = virtualLocationTreeNodeHint;
        return virtualLocationTreeNodeHintId === id;
      })
  );
}

export default selectVirtualLocationTreeNodeHintById;
