import React from 'react';
import styled from '@emotion/styled';
import Divider from '@material-ui/core/Divider';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';

import {
  white,
  greySemi,
} from 'ui-library/color-palette';

const Root = styled.div`
  padding: 10px 25px;
  margin: 8px 0;
  border: 1px solid ${greySemi};
  border-radius: 4px;
  background-color: ${white};
  display: flex;
  flex-direction: column;
`;

const DividerContainer = styled.div`
  padding: 10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const PermissionGrant = ({
  title,
  subTitle,
  menuOptions = [],
  children,
}) => {

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [permissionsMenuAnchorEl, setPermissionsMenuAnchorEl] = React.useState(null);

  return (
    <Root>
      <HeaderContainer>
        <TitleContainer>
          <Typography
            variant="small"
            fontWeight="bold"
            fontFamily="primary"
            color="black">
            {title}
          </Typography>
          <Typography
            variant="caption"
            fontWeight="normal"
            fontFamily="primary"
            color="grey">
            {subTitle}
          </Typography>
        </TitleContainer>
        {
          menuOptions.length > 0 &&
          <ButtonContainer>
            <Button
              data-test-id="permissions-context-menu-button"
              variant="icon-text"
              onClick={(ev) => {
                setPermissionsMenuAnchorEl(ev.currentTarget);
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <MoreHorizIcon />
            </Button>
            <Menu
              open={isMenuOpen}
              onClose={() => {
                setIsMenuOpen(false);
                setPermissionsMenuAnchorEl(null);
              }}
              anchorEl={permissionsMenuAnchorEl}
              disablePortal={true}
            >
              {
                menuOptions.map((option, index) => {
                  const {
                    label,
                    onClick,
                  } = option;
                  return (
                    <MenuItem
                      key={index}
                      data-test-id="permissions-context-menu-item-button"
                      onClick={() => {
                        onClick();
                        setIsMenuOpen(false);
                      }}
                    >
                      <Typography variant="small">
                        {label}
                      </Typography>
                    </MenuItem>
                  );
                })
              }
            </Menu>
          </ButtonContainer>
        }
      </HeaderContainer>
      <DividerContainer>
        <Divider/>
      </DividerContainer>
      {children}
    </Root>
  );
};

export default PermissionGrant;
