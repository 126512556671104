import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import styled from '@emotion/styled';

const DragHandleRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

const DragHandle = () => {
  return (
    <DragHandleRoot>
      <DragHandleIcon/>
    </DragHandleRoot>
  );
};

function computeIndexPatches(items, dragResult) {
  const {
    source: {
      index: dragSourceIndex,
    },
    destination: {
      index: dragDestinationIndex,
    },
  } = dragResult;

  const affectedItems = items
    .slice(
      dragSourceIndex < dragDestinationIndex ? dragSourceIndex + 1 : dragDestinationIndex,
      dragSourceIndex < dragDestinationIndex ? dragDestinationIndex + 1 : dragSourceIndex,
    );

  const affectedItemsPatches = affectedItems.map(item => {
    const {
      typeId: id,
      displayIndex: index,
      typeName: name,
    } = item;
    return {
      id,
      name,
      oldIndex: index,
      newIndex: dragSourceIndex < dragDestinationIndex ? index - 1 : index + 1,
    };
  });

  return [
    {
      id: items[dragSourceIndex].typeId,
      name: items[dragSourceIndex].typeName,
      oldIndex: items[dragSourceIndex].displayIndex,
      newIndex: dragSourceIndex < dragDestinationIndex ? affectedItemsPatches[affectedItemsPatches.length - 1].oldIndex : affectedItemsPatches[0].oldIndex,
    },
    ...affectedItemsPatches,
  ];
}

const ItemsList = ({
  items,
  onAddItem,
  onRemoveItem,
  onReorderItem,
}) => {
  return (
    <>
      {
        !items.length ?
          <Typography
            variant="body2">
            <em>The eHR Hub is empty. Please use the button below to add a type.</em>
          </Typography>
          :
          <DragDropContext
            onDragEnd={result => {
              const {
                source: {
                  index: dragSourceIndex,
                },
                destination: {
                  index: dragDestinationIndex,
                },
              } = result;

              if (dragSourceIndex === dragDestinationIndex) {
                return;
              }

              onReorderItem(computeIndexPatches(items, result));
            }}>
            <Droppable
              droppableId="droppable">
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {
                      items
                        .sort((fd1, fd2) => {
                          const {
                            displayIndex: index1,
                          } = fd1;
                          const {
                            displayIndex: index2,
                          } = fd2;
                          if (index1 < index2) {
                            return -1;
                          }
                          if (index1 > index2) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((item, index) => {
                          const {
                            typeId,
                            typeName,
                            description,
                          } = item;
                          return (
                            <Draggable
                              key={typeId}
                              draggableId={`${typeId}`}
                              index={index}>
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}>
                                    <NestedBox>
                                      <ContentLine>
                                        <DragHandle/>
                                        &nbsp;&nbsp;
                                        <ContentLineText>
                                          <Typography
                                            variant="body1">
                                            {typeName}
                                          </Typography>
                                          <Typography
                                            variant="body2">
                                            <em>{description}</em>
                                          </Typography>
                                        </ContentLineText>
                                        <Tooltip
                                          placement="top"
                                          title={`remove "${typeName}" fromeHR Hub `}>
                                          <span>
                                            <IconButton
                                              // disabled={controlsDisabled}
                                              onClick={() => {
                                                onRemoveItem(typeId);
                                              }}>
                                              <RemoveIcon/>
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                      </ContentLine>
                                    </NestedBox>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })
                    }
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
      }
      <br/>

      <Tooltip
        placement="top"
        title="add a type to the eHR Hub">
        <span>
          <IconButton
            disabled={false}
            onClick={() => {
              onAddItem();
            }}>
            <AddIcon/>
          </IconButton>
        </span>
      </Tooltip>
      <br/><br/>
    </>
  );
};

function areItemsTheSame(previousProps, nextProps) {
  const {
    items: previousItems,
  } = previousProps;
  const {
    items: nextItems,
  } = nextProps;

  if (previousItems.length !== nextItems.length) {
    return false;
  }

  for (let i = 0; i < previousItems.length; i++) {
    const previousFieldDefinition = previousItems[i];
    const nextFieldDefinition = nextItems[i];

    const {
      name: previousItemName,
      id: previousItemId,
      isEnabled: previousItemIsEnabled,
      displayIndex: previousItemIndex,
    } = previousFieldDefinition;
    const {
      name: nextItemName,
      id: nextItemId,
      isEnabled: nextItemIsEnabled,
      displayIndex: nextItemIndex,
    } = nextFieldDefinition;

    if (
      previousItemName !== nextItemName ||
      previousItemId !== nextItemId ||
      previousItemIsEnabled !== nextItemIsEnabled ||
      previousItemIndex !== nextItemIndex
    ) {
      return false;
    }
  }

  return true;
}

export default React.memo(ItemsList, areItemsTheSame);
