import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import Typography from 'ui-library/components/Typography';
import FilterCriterion from 'ui-library/components/FilterCriterion';
import FilterOperator from 'ui-library/components/FilterOperator';
import FilterCloseButton from 'ui-library/components/FilterCloseButton';
import FilterValue from 'ui-library/components/FilterValue';

const useStyles = makeStyles({
  root: {
    display: theme.filterPill.root.display,
  },
  container: {
    backgroundColor: theme.filterPill.container.backgroundColor,
    display: theme.filterPill.container.display,
    paddingLeft: theme.filterPill.container.paddingLeft,
    height: theme.filterPill.container.height,
    borderRadius: theme.filterPill.container.borderRadius,
    justifyContent: theme.filterPill.container.justifyContent,
    margin: theme.filterPill.container.margin,
  },
  loading: {
    display: theme.filterPill.loading.display,
    margin: theme.filterPill.loading.margin,
  },
});

const FilterPill = ({
  index,
  criteria,
  isLoading,
  filterPillCriterion,
  filterPillValue,
  removeFilterPillCriterion,
  updateFilterPillValue,
  updateFilterPillCriterion,
  onLoadTree,
  onLoadOptions,
}) => {

  const classes = useStyles();

  const [isCriterionOpen, setIsCriterionOpen] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  const {
    label: criterionLabel,
    operator: criterionOperator,
  } = filterPillCriterion;

  const selectCriterion = (newCriterion) => {
    const {
      label: newCriterionLabel,
    } = newCriterion;

    if (criterionLabel !== newCriterionLabel) {
      updateFilterPillCriterion(index, newCriterion);
      updateFilterPillValue(index, []);
      setEditMode(true);
      setIsCriterionOpen(false);
    }
  };

  React.useEffect(() => {
    if (filterPillValue.length === 0) {
      setEditMode(true);
    }
  }, [filterPillValue.length]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (isCriterionOpen) {
        setIsCriterionOpen(false);
      }
      if (editMode) {
        setEditMode(false);
        if (filterPillValue.length === 0) {
          removeFilterPillCriterion(index);
        }
      }
    };
    document.body.addEventListener('scroll', handleScroll);
    return () => document.body.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>

        <FilterCriterion
          criteria={criteria}
          selectCriterion={selectCriterion}
          isCriterionOpen={isCriterionOpen}
          setIsCriterionOpen={setIsCriterionOpen}
          criterionLabel={criterionLabel}
        >
          <Typography
            variant="x-small"
            fontWeight="bold"
          >
            {criterionLabel}
          </Typography>
        </FilterCriterion>

        <FilterOperator>
          <Typography variant="x-small" fontWeight="normal" color="tertiary-dark">
            {criterionOperator}
          </Typography>
        </FilterOperator>

        {
          isLoading ?
            <div className={classes.loading}>
              <Typography
                variant="x-small"
                fontStyle="italic"
              >
               Loading...
              </Typography>
            </div> :
            <FilterValue
              index={index}
              filterPillCriterion={filterPillCriterion}
              removeFilterPillCriterion={removeFilterPillCriterion}
              editMode={editMode}
              setEditMode={setEditMode}
              filterPillValue={filterPillValue}
              updateFilterPillValue={updateFilterPillValue}
              onLoadTree={onLoadTree}
            />
        }
        <FilterCloseButton
          onClick={() => {
            removeFilterPillCriterion(index);
          }}
        />
      </div>
    </div>
  );
};

FilterPill.propTypes = {
  index: PropTypes.number,
  criteria: PropTypes.array,
  isLoading: PropTypes.bool,
  filterPillCriterion: PropTypes.object,
  filterPillValue: PropTypes.array,
  removeFilterPillCriterion: PropTypes.func,
  updateFilterPillValue: PropTypes.func,
  updateFilterPillCriterion: PropTypes.func,
  onLoadTree: PropTypes.func,
  onLoadOptions: PropTypes.func,
};

export default FilterPill;
