import React from 'react';
import PropTypes from 'prop-types';
import {CardElement, useElements} from '@stripe/react-stripe-js';
import {
  grey,
  greySemi,
  greyLighter,
  error,
} from 'ui-library/color-palette';
import styled from '@emotion/styled';

const CardContainer = styled.div`
  border: 1px solid ${greyLighter};
  border-radius: 4px;
  padding: 12px 10px;
  min-width: 300px;
`;

const StripeForm = ({
  setErrorMessage,
}) => {

  const elements = useElements();

  React.useEffect(() => {
    if (elements) {
      const cardElement = elements.getElement(CardElement);

      cardElement.on('change', (event) => {
        if (event.error) {
          setErrorMessage(event.error.message);
        } else {
          setErrorMessage('');
        }
      });
    }
  }, [elements, setErrorMessage]);

  return (
    <CardContainer>
      <CardElement
        options={{
          iconStyle: 'solid',
          style: {
            base: {
              iconColor: greySemi,
              color: grey,
              fontWeight: 500,
              fontSize: '16px',
              fontSmoothing: 'antialiased',
              ':-webkit-autofill': {color: '#fce883'},
              '::placeholder': {color: grey},
            },
            invalid: {
              iconColor: error,
              color: error,
            },
          },
        }}
      />
    </CardContainer>
  );
};

StripeForm.propTypes = {
  setErrorMessage: PropTypes.func,
};

export default StripeForm;
