import React from 'react';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import styled from '@emotion/styled';
import Fade from '@material-ui/core/Fade';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CenterpieceCheckmark from 'components/chrome/CenterpieceCheckmark';
import Link from 'ui-library/components/Link';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 32px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
`;

const SaveConfirmationScreen = ({
  active,
  closeWizard,
  savedPersonName,
}) => {
  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
          Add Person
          </WizardBreadcrumb>
        </WizardStepTitle>
      </WizardStepHead>

      <WizardContentColumn>
        <Fade in timeout={400}>
          <Root>
            <CenterpieceCheckmark/>
            <Typography
              variant="h2"
              fontWeight="bold"
              color="secondary"
            >
              {savedPersonName} was added successfully!
            </Typography>
            <Typography
              variant="lead"
              fontWeight="normal"
              color="black"
            >
              You can view and edit their details in the&nbsp;
              <Link
                data-test-id="people-list-link"
                value="/people"
                label="People Section"
                variant="lead"
                fontWeight="normal"
                color="primary"
              />
              .
            </Typography>
            <Actions>
              <Button
                data-test-id="add-person-wizard-finish-button"
                variant="primary"
                onClick={closeWizard}>
                Finish
              </Button>
            </Actions>
          </Root>
        </Fade>
      </WizardContentColumn>
    </WizardStep>

  );
};

export default SaveConfirmationScreen;
