import genConnectionByConnectionId from 'services/Connections/genConnectionByConnectionId';
import genRecordByRecordId from 'services/Records/genRecordByRecordId';

function genConnectionBreadcrumbsFromPathname(pathname, authToken, perspectiveId) {
  const path = pathname.split('/').filter(segment => segment);
  return new Promise((resolve, reject) => {
    const breadcrumbs = [
      {
        path: '/connections',
        label: 'Connections',
      },
    ];

    const dataSegments = [
      extractConnectionId(path),
      extractRecordId(path),
    ];

    const promises = [];

    for (let i = 0; i < dataSegments.length; i++) {
      if (dataSegments[i] === null) {
        break;
      }
      switch(i) {
        case 0: {
          promises.push(new Promise(async(resolve, reject) => {
            try {
              const connection = await genConnectionByConnectionId(authToken, perspectiveId, dataSegments[i]);
              resolve(connection);
            } catch (e) {
              reject(e);
            }
          }));
          break;
        }
        case 1: {
          promises.push(new Promise(async(resolve, reject) => {
            try {
              const record = await genRecordByRecordId(authToken, perspectiveId, dataSegments[i]);
              resolve(record);
            } catch (e) {
              reject(e);
            }
          }));
          break;
        }
        default:
          break;
      }
    }

    Promise.all(promises).then(([connection, record]) => {
      if (!connection) {
        return resolve(breadcrumbs);
      }

      breadcrumbs.push({
        id: connection.id,
        label: connection.name,
        path: `/connections/${connection.id}`,
        skipWhenUnstacking: true,
      });

      const recordsLocality = extractRecordsLocality(path);
      if (!recordsLocality) {
        return resolve(breadcrumbs);
      }

      breadcrumbs.push({
        label: (() => {
          switch(recordsLocality) {
            case 'inbound-records': {
              return 'External records';
            }
            case 'outbound-records': {
              return 'Shared records';
            }
            default:
              return reject(`Unknown records locality "${recordsLocality}"!`);
          }
        })(),
        path: `/connections/${connection.id}/${recordsLocality}`,
      });

      if (!record) {
        return resolve(breadcrumbs);
      }

      breadcrumbs.push({
        id: record.id,
        label: record.name,
        path: `/connections/${connection.id}/${recordsLocality}/${record.id}`,
      });

      resolve(breadcrumbs);
    }).catch(e => reject(e));
  });
}

function extractConnectionId(path) {
  if (path[1]) {
    return +path[1];
  }
  return null;
}

function extractRecordsLocality(path) {
  if (path[2]) {
    return path[2];
  }
  return null;
}

function extractRecordId(path) {
  if (path[3]) {
    return +path[3];
  }
  return null;
}

export default genConnectionBreadcrumbsFromPathname;
