import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from '@emotion/styled';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

import PersonIcon from '@material-ui/icons/PersonRounded';
import HelpIcon from '@material-ui/icons/HelpOutline';

import AppBarContainer from 'components/chrome/AppBarContainer';
import AppBar from 'components/chrome/AppBar';
import AppBarActions from 'components/chrome/AppBarActions';

import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import NotificationsCount from 'ui-library/components/NotificationsCount';

import PerspectiveSwitcher from 'components/chrome/PerspectiveSwitcher';

import UserInterfaceContext from 'contexts/UserInterface';

import Typography from 'ui-library/components/Typography';

const useStyles = makeStyles({
  icon: {
    color: theme.palette['primary-darker'],
  },
});

const MenuContainer = styled.div`
  cursor: pointer;
  padding-left: 16px;
`;

const AppplicationBar = ({
  setIsPanelOpen = () => {},
  setIsLogoutModalOpen,
  enablePerspectiveSwitcher = true,
  enableAccountPanel = true,
  enableNotifications = true,
}) => {
  const classes = useStyles();

  const {
    notificationsUnreadCount,
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isMenuOpen, setMenuIsOpen] = React.useState(false);
  const [anchorElHelp, setAnchorElHelp] = React.useState(null);
  const [isHelpMenuOpen, setHelpMenuIsOpen] = React.useState(false);


  const handleClose = () => {
    setMenuIsOpen(false);
    setAnchorEl(null);
  };

  const handleCloseHelpMenu = () => {
    setHelpMenuIsOpen(false);
    setAnchorElHelp(null);
  };

  return (
    <>
      <AppBarContainer>
        <AppBar>
          {
            enablePerspectiveSwitcher &&
            <PerspectiveSwitcher/>
          }
          <AppBarActions>
            {
              enableNotifications &&
              <MenuContainer>
                <NotificationsCount
                  data-test-id="notifications-button"
                  onClick={() => {
                    dispatchUserInterfaceAction({
                      type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                      payload: true,
                    });
                  }}
                  count={notificationsUnreadCount}
                />
              </MenuContainer>
            }

            <MenuContainer>
              <HelpIcon
                className={classes.icon}
                data-test-id="help-menu-homeowner-button"
                onClick={(ev) => {
                  setAnchorElHelp(ev.currentTarget);
                  setHelpMenuIsOpen(true);
                }}
              />
              <Menu
                open={isHelpMenuOpen}
                onClose={handleCloseHelpMenu}
                anchorEl={anchorElHelp}
              >
                <a
                  href="https://static.jules.app/faq.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{textDecoration: 'none'}}
                  data-test-id="faq-link">
                  <MenuItem
                    onClick={handleCloseHelpMenu}
                    centeredContent
                    showDivider={true}>
                    <Typography variant="small">FAQ</Typography>
                  </MenuItem>
                </a>
                <a
                  href="https://julesapp.com/tutorials"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{textDecoration: 'none'}}
                  data-test-id="tutorials-link">
                  <MenuItem
                    onClick={handleCloseHelpMenu}
                    centeredContent
                    showDivider={true}>
                    <Typography variant="small">Tutorials</Typography>
                  </MenuItem>
                </a>
                <a
                  href="https://static.jules.app/privacy_policy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{textDecoration: 'none'}}
                  data-test-id="privacy-policy-link">
                  <MenuItem
                    onClick={handleCloseHelpMenu}
                    centeredContent
                    showDivider={true}>
                    <Typography variant="small">Privacy Policy</Typography>
                  </MenuItem>
                </a>
                <a
                  href="https://static.jules.app/terms_of_use.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{textDecoration: 'none'}}
                  data-test-id="terms-and-cond-link">
                  <MenuItem
                    centeredContent
                    onClick={handleCloseHelpMenu}>
                    <Typography variant="small">Terms & Conditions</Typography>
                  </MenuItem>
                </a>
              </Menu>
            </MenuContainer>
            <MenuContainer>
              <PersonIcon
                className={classes.icon}
                data-test-id="user-options-business-button"
                onClick={(ev) => {
                  setAnchorEl(ev.currentTarget);
                  setMenuIsOpen(true);
                }}
              />
              <Menu
                open={isMenuOpen}
                onClose={handleClose}
                anchorEl={anchorEl}
              >
                {
                  enableAccountPanel &&
                  <MenuItem
                    data-test-id="my-account-option-business"
                    onClick={() => {
                      setIsPanelOpen(true);
                      handleClose();
                    }}
                    showDivider={true}
                  >
                    <Typography variant="small">My Account</Typography>
                  </MenuItem>
                }
                <NavLink
                  to="/conversation-vault"
                  style={{textDecoration: 'none'}}
                  data-test-id="conversation-vault-link">
                  <MenuItem
                    centeredContent
                    showDivider={true}
                    onClick={handleClose}>
                    <Typography variant="small">Conversation Vault</Typography>
                  </MenuItem>
                </NavLink>
                <MenuItem
                  data-test-id="logout-option-business"
                  onClick={() => {
                    setIsLogoutModalOpen(true);
                    handleClose();
                  }}>
                  <Typography variant="small" color="error">Log Out</Typography>
                </MenuItem>
              </Menu>
            </MenuContainer>
          </AppBarActions>
        </AppBar>
      </AppBarContainer>
    </>
  );
};

export default AppplicationBar;
