import React from 'react';
import styled from '@emotion/styled';
import PersonList from 'components/PersonList';
import CurrentUserPerson from 'components/CurrentUserPerson';
import SelectAllItems from 'components/SelectAllItems';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import Message from 'ui-library/components/Message';
import BatchActionMenu from 'ui-library/components/BatchActionMenu';
import BatchActionButton from 'ui-library/components/BatchActionButton';
import BatchDeleteWarningDialog from 'components/BatchDeleteWarningDialog';
import BatchDeletePersonDialog from 'components/BatchDeletePersonDialog';

import PeopleContext from './context';

import genPersons from 'services/Persons/genPersons';
import deletePerson from 'services/Persons/deletePerson';

const PersonListContainer = styled.div`
`;

const CurrentUserContainer = styled.div`
  padding-bottom: 8px;
`;

const PersonListRoute = ({
  match,
}) => {
  const [status, setStatus] = React.useState('fetching data');
  const [fatalError, setFatalError] = React.useState(null);
  const [currentUserPerson, setCurrentUserPerson] = React.useState(null);
  const [persons, setPersons] = React.useState([]);
  const [selectedPersonIds, setSelectedPersonIds] = React.useState([]);

  const [showBatchDeleteWarningDialog, setShowBatchDeleteWarningDialog] = React.useState(false);
  const [showBatchDeleteDialog, setShowBatchDeleteDialog] = React.useState(false);
  const [batchDeleteSuccess, setBatchDeleteSuccess] = React.useState(false);

  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
    currentPerspectiveId,
    dispatchUserInterfaceAction,
    filter,
    personIdModifiedDestructivelyForLists,
    pushAndClearFilter,
  } = React.useContext(PeopleContext);

  React.useEffect(() => {
    const initialize = async() => {
      try {
        setStatus('fetching data');
        const allPersons = await genPersons(authToken, currentPerspectiveId);
        const currentUserIndex = allPersons.findIndex(person => person.isCurrentUser);
        setCurrentUserPerson(allPersons[currentUserIndex]);
        setPersons(allPersons);
        setStatus('ready');
        dispatchUserInterfaceAction({
          type: 'SET_PERSON_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
          payload: null,
        });
      } catch (error) {
        const {
          name,
        } = error;
        switch (name) {
          case 'AuthorizationError':
            setAuthToken(null);
            setAuthenticationStatus('not-authenticated');
            localStorage.removeItem('authToken');
            break;
          default:
            setFatalError(error);
        }
      }
    };

    initialize();
  }, [authToken, currentPerspectiveId, setAuthToken, setAuthenticationStatus, personIdModifiedDestructivelyForLists, dispatchUserInterfaceAction]);

  if (fatalError) {
    throw fatalError;
  }

  if (status !== 'ready'){
    return <ListSkeleton/>;
  }

  const batchDeletePersons = () => {
    return new Promise((resolve, reject) => {
      const promises = selectedPersonIds.map(selectedPersonId => {
        return new Promise(async(resolve, reject) => {
          try {
            const result = await deletePerson({
              authToken,
              currentPerspectiveId,
              nodeId: selectedPersonId,
            });
            resolve(result);
          } catch(error) {
            const {
              body: {
                code,
              },
            } = error;
            switch(code) {
              case 3001:
                resolve(null);
                break;
              case 1014:
                resolve({selectedPersonId, code});
                break;
              default:
                reject(error);
            }
          }
        });
      });

      Promise.all(promises)
        .then(deleted => {
          const withErrors = deleted.filter(result => result !== null);
          resolve(withErrors);
          setSelectedPersonIds([]);
          if (withErrors.length === 0) {
            setBatchDeleteSuccess(true);
          }
        })
        .catch(error => {
          setFatalError(error);
        });
    });
  };

  const renderedPersons = persons.filter(person => {
    if (!filter) {
      return true;
    }
    const {
      name,
    } = person;
    return (
      name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  });

  const selectablePersons = renderedPersons.filter(person => !person.isCurrentUser);

  return (
    <>
      <SelectAllItems
        disabled={selectablePersons.length === 0}
        checked={selectablePersons.length > 0 && Boolean(selectedPersonIds.length === selectablePersons.length)}
        indeterminate={Boolean(selectedPersonIds.length && (selectedPersonIds.length !== selectablePersons.length))}
        onChange={() => {
          if (selectedPersonIds.length === selectablePersons.length) {
            setSelectedPersonIds([]);
          } else {
            setSelectedPersonIds([
              ...selectablePersons.map(person => person.id),
            ]);
          }
        }}
      />
      <div>
        <BatchActionMenu>
          <BatchActionButton
            data-test-id="batch-delete-button"
            variant="delete"
            disabled={selectablePersons.length === 0}
            onClick={() => {
              if (selectedPersonIds.length > 0) {
                setShowBatchDeleteDialog(true);
              } else {
                setShowBatchDeleteWarningDialog(true);
              }
            }}
          />
        </BatchActionMenu>
        {
          renderedPersons.find(person => person.isCurrentUser) &&
          <CurrentUserContainer>
            <CurrentUserPerson
              person={currentUserPerson}
              onPersonAction={(personId, action) => {
                switch (action) {
                  case 'expand': {
                    dispatchUserInterfaceAction({
                      type: 'SET_EXPANDED_PERSON_ID',
                      payload: personId,
                    });
                    dispatchUserInterfaceAction({
                      type: 'SET_EXPANDED_PERSON_IS_CURRENT_USER',
                      payload: true,
                    });
                    dispatchUserInterfaceAction({
                      type: 'SET_SHOW_PERSON_PANEL',
                      payload: true,
                    });
                    break;
                  }
                  case 'navigate': {
                    dispatchUserInterfaceAction({
                      type: 'SET_SELECTED_PERSON_ID',
                      payload: personId,
                    });
                    pushAndClearFilter(`${match.url}/${personId}/records`);
                    break;
                  }
                  default:
                    break;
                }
              }}
            />
          </CurrentUserContainer>
        }
      </div>
      <PersonListContainer>
        <PersonList
          persons={selectablePersons}
          selectedPersonIds={selectedPersonIds}
          onPersonAction={(personId, action) => {
            switch(action) {
              case 'expand': {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_PERSON_ID',
                  payload: personId,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_PERSON_IS_CURRENT_USER',
                  payload: false,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_PERSON_PANEL',
                  payload: true,
                });
                break;
              }
              case 'select': {
                setSelectedPersonIds([
                  ...selectedPersonIds,
                  personId,
                ]);
                break;
              }
              case 'deselect': {
                const index = selectedPersonIds.indexOf(personId);
                setSelectedPersonIds([
                  ...selectedPersonIds.slice(0, index),
                  ...selectedPersonIds.slice(index + 1),
                ]);
                break;
              }
              case 'delete': {
                setSelectedPersonIds([
                  personId,
                ]);
                setShowBatchDeleteDialog(true);
                break;
              }
              case 'navigate': {
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_PERSON_ID',
                  payload: personId,
                });
                pushAndClearFilter(`${match.url}/${personId}/records`);
                break;
              }
              default:
                break;
            }
          }}
        />
        <BatchDeleteWarningDialog
          isOpen={showBatchDeleteWarningDialog}
          onClose={() => setShowBatchDeleteWarningDialog(false)}
          type="person"
        />
        <BatchDeletePersonDialog
          isOpen={showBatchDeleteDialog}
          onCancel={() => {
            setShowBatchDeleteDialog(false);
            setSelectedPersonIds([]);
          }}
          onClose={() => {
            setShowBatchDeleteDialog(false);
            dispatchUserInterfaceAction({
              type: 'SET_PERSON_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
              payload: true,
            });
          }}
          persons={selectablePersons.filter((person) => {
            return selectedPersonIds.indexOf(person.id) !== -1;
          })}
          deletePersons={batchDeletePersons}
        />
        <Message
          variant="success"
          open={batchDeleteSuccess}
          onClose={() => setBatchDeleteSuccess(false)}
          messageTitle="Person(s) Deleted"
          messageBody={
            <Typography variant="body">
            All selected persons have been deleted successfully.
            </Typography>
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          width={532}
        />
      </PersonListContainer>
    </>
  );
};

export default PersonListRoute;
