import PATCH__users_firstName from 'network-layer/endpoints/PATCH__users_firstName';

function responseAdapter(response) {
  return response;
}

export default async function updatePhoneNumber(params) {
  const {
    authToken,
    firstName,
  } = params;
  const response = await PATCH__users_firstName(authToken, {value: firstName});

  return responseAdapter(response);
}
