import GET__hub_record_ID from 'network-layer/endpoints/GET__hub_record_ID';

function responseAdapter(response) {
  return response;
}

export default async function genEHRHubByRecordId(authToken, perspectiveId, recordId) {
  const response = await GET__hub_record_ID(authToken, perspectiveId, recordId);

  return responseAdapter(response);
}
