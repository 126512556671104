const prettyFileSize = (bytes) => {
  if (typeof bytes !== 'number') {
    throw new Error('prettyFileSize expects a number!');
  }

  let prettySize = bytes;

  if (bytes < 1024) {
    return `${Math.floor(prettySize)} B`;
  }

  prettySize = prettySize / 1024;

  if (bytes < 1024 * 1024) {
    return `${Math.floor(prettySize * 10) / 10} KB`;
  }

  prettySize = prettySize / 1024;

  if (bytes < 1024 * 1024 * 1024) {
    return `${Math.floor(prettySize * 10) / 10} MB`;
  }

  prettySize = prettySize / 1024;

  if (bytes < 1024 * 1024 * 1024 * 1024) {
    return `${Math.floor(prettySize * 10) / 10} GB`;
  }

  return '> 1 PB';
};

export default prettyFileSize;
