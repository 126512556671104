import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import MUITextField from '@material-ui/core/TextField';
import Typography from 'ui-library/components/Typography';

const useStyles = makeStyles({
  root: {
    '& input': {
      height: theme.filteredTextField.height,
      fontSize: theme.filteredTextField.fontSize,
      padding: theme.filteredTextField.padding,
    },

    // boxShadow on input hover
    '& .MuiInputBase-root:hover': {
      boxShadow: theme.filteredTextField.boxShadow,
    },

    // border
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
      '&:after': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
    },
  },
  container: {
    display: theme.filteredTextField.container.display,
    margin: theme.filteredTextField.container.margin,
    cursor: theme.filteredTextField.container.cursor,
  },
  valueContainer: {
    display: theme.filterCriterion.valueContainer.display,
  },
  value: {
    whiteSpace: theme.filterCriterion.value.whiteSpace,
    overflow: theme.filterCriterion.value.overflow,
    textOverflow: theme.filterCriterion.value.textOverflow,
  },
});

const FilterTextField = ({
  index,
  filterPillValue,
  updateFilterPillValue,
  editMode,
  setEditMode,
}) => {

  const classes = useStyles();
  const InputRefrence = React.createRef();

  React.useEffect(() => {
    if (editMode === true) {
      InputRefrence.current.focus();
    }
  }, [editMode, InputRefrence]);

  if (editMode) {
    return (
      <div className={classes.container}>
        <MUITextField
          className={classes.root}
          variant="standard"
          size="small"
          fullWidth={true}
          inputRef={InputRefrence}
          InputLabelProps={{
            shrink: true,
          }}
          value={filterPillValue}
          onBlur={() => {
            if (filterPillValue.length > 0) {
              setEditMode(false);
            }
          }}
          onChange={(ev) => {
            const {
              target: {
                value: newFilterPillValue,
              },
            } = ev;
            if (newFilterPillValue !== '') {
              updateFilterPillValue(index, [newFilterPillValue]);
            } else {
              updateFilterPillValue(index, []);
            }
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter' && filterPillValue.length > 0) {
              setEditMode(false);
            }
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={classes.container}
      onClick={() => {
        setEditMode(true);
      }}
    >
      <div className={classes.valueContainer}>
        <div className={classes.value}>
          <Typography variant="x-small" fontWeight="bold">
            {filterPillValue[0]}
          </Typography>
        </div>
      </div>
    </div>
  );
};

FilterTextField.propTypes = {
  index: PropTypes.number,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  filterPillValue: PropTypes.array,
  updateFilterPillValue: PropTypes.func,
};

export default FilterTextField;
