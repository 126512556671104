import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {GET} from '../../verbs';

export default async function GET__admin_organization_ORGANIZATIONID_currentSubscription(authToken, perspectiveId, organizationId) {
  return await GET(
    `/admin/organization/${organizationId}/current-subscription`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
