import React from 'react';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import NodeAttachmentProgressBar from 'ui-library/components/NodeAttachmentProgressBar';

const useStyles = makeStyles({
  nodeAttachmentUpload: {
    display: theme.nodeAttachmentUpload.root.display,
    alignItems: theme.nodeAttachmentUpload.root.alignItems,
    margin: theme.nodeAttachmentUpload.root.margin,
    userSelect: theme.nodeAttachmentUpload.root.userSelect,
    color: props => props.isUploading ? theme.nodeAttachmentUpload.root.color.default : theme.nodeAttachmentUpload.root.color.pending,
  },
  nodeAttachmentInfo: {
    flex: theme.nodeAttachmentUpload.nodeAttachmentInfo.flex,
  },
  fileInfo: {
    display: theme.nodeAttachmentUpload.fileInfo.display,
    alignItems: theme.nodeAttachmentUpload.fileInfo.alignItems,
    justifyContent: theme.nodeAttachmentUpload.fileInfo.justifyContent,
  },
  fileName: {
    display: theme.nodeAttachmentUpload.fileName.display,
    fontSize: theme.nodeAttachmentUpload.fileName.fontSize,
    fontWeight: theme.nodeAttachmentUpload.fileName.fontWeight,
    transition: theme.nodeAttachmentUpload.fileName.transition,
  },
  fileSize: {
    display: theme.nodeAttachmentUpload.fileSize.display,
    color: theme.nodeAttachmentUpload.fileSize.color,
  },
  nodeAttachmentCloseAction: {
    display: theme.nodeAttachmentUpload.nodeAttachmentCloseAction.display,
    alignItems: theme.nodeAttachmentUpload.nodeAttachmentCloseAction.alignItems,
    justifyContent: theme.nodeAttachmentUpload.nodeAttachmentCloseAction.justifyContent,
    width: theme.nodeAttachmentUpload.nodeAttachmentCloseAction.width,
    height: theme.nodeAttachmentUpload.nodeAttachmentCloseAction.height,
    cursor: theme.nodeAttachmentUpload.nodeAttachmentCloseAction.cursor,
    color: theme.nodeAttachmentUpload.nodeAttachmentCloseAction.color,
  },
  errorMessageContainer: {
    color: theme.nodeAttachmentUpload.errorMessageContainer.color,
  },
  flexContainer: {
    display: theme.nodeAttachmentUpload.flexContainer.display,
  },
  errorIconContainer: {
    display: theme.nodeAttachmentUpload.errorIconContainer.display,
    flexDirection: theme.nodeAttachmentUpload.errorIconContainer.flexDirection,
    justifyContent: theme.nodeAttachmentUpload.errorIconContainer.justifyContent,
    color: theme.nodeAttachmentUpload.errorIconContainer.color,
  },
  nameContainer: {
    display: theme.nodeAttachmentUpload.nameContainer.display,
    flexDirection: theme.nodeAttachmentUpload.nameContainer.flexDirection,
    justifyContent: theme.nodeAttachmentUpload.nameContainer.justifyContent,
  },
});

const NodeAttachmentUpload = ({
  id,
  isUploading,
  bytesTransferred,
  fileName,
  fileSize,
  progress,
  errorMessage,
  handleUploadAbort,
  handleRemoveErroneousUpload,
}) => {

  const classes = useStyles({
    isUploading,
  });

  return (
    <div className={classes.nodeAttachmentUpload}>
      <div className={classes.nodeAttachmentInfo}>
        <div className={classes.fileInfo}>
          <div className={classes.fileName}>
            <div className={classes.flexContainer}>
              {
                Boolean(errorMessage) &&
                <div className={classes.errorIconContainer}>
                  <ReportProblemRoundedIcon />
                </div>
              }
              {
                isUploading ?
                  <div className={classes.nameContainer}>
                    <Typography
                      variant="caption"
                      fontWeight="bold"
                    >
                      {fileName}
                    </Typography>
                  </div>
                  :
                  <div className={classes.nameContainer}>
                    <Typography
                      variant="caption"
                      fontWeight="bold"
                    >
                      {fileName} pending
                    </Typography>
                  </div>
              }
            </div>
          </div>
          <div className={classes.size}>
            {
              isUploading ?
                <Typography
                  variant="caption"
                  color="grey"
                >
                  {bytesTransferred} / {fileSize}
                </Typography>
                :
                ''
            }
          </div>
        </div>
        <NodeAttachmentProgressBar
          progress={progress}
          hasError={Boolean(errorMessage)}
        />
        {
          errorMessage ?
            <Typography
              variant="x-small"
              color="error"
            >
              {errorMessage}
            </Typography>
            :
            null
        }
      </div>
      <div
        data-test-id="abort-upload-button"
        className={classes.nodeAttachmentCloseAction}
        onClick={() => {
          if (errorMessage === '') {
            handleUploadAbort(id);
          } else {
            handleRemoveErroneousUpload(id);
          }
        }}
      >
        <CloseRoundedIcon/>
      </div>
    </div>
  );
};

NodeAttachmentUpload.propTypes = {
  id: PropTypes.number,
  isUploading: PropTypes.bool,
  bytesTransferred: PropTypes.string,
  fileName: PropTypes.string,
  fileSize: PropTypes.string,
  progress: PropTypes.string,
  errorMessage: PropTypes.string,
  handleUploadAbort: PropTypes.func,
};

export default NodeAttachmentUpload;
