const phoneNumber = (
  value,
  errorMessage = 'Please enter a valid phone number!',
) => {

  if (value.replace(/\D/g,'').length >= 10 && /^\+?(\d{0,3})? ?((\(\d{1,3}\) ?((\d{3,5})[. -]?(\d{4})|(\d{2}[. -]?){4}))|([. -]?(\d{1,3}[. -]*)?((\d{3,5})[. -]?(\d{4})|(\d{2}[. -]?){4})))$/.test(value)) {
    return '';
  }

  return errorMessage;
};

export default phoneNumber;
