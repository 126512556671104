import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {GET} from '../../verbs';

export default async function GET__admin_recordTypes_RECORDTYPEID_types_TYPEID_usage(authToken, perspectiveId, params) {
  const {
    recordTypeId,
    typeId,
  } = params;

  return await GET(
    `/admin/record-types/${recordTypeId}/types/${typeId}/usage`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
