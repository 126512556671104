import selectVirtualLocationTreeNodeHintChildIdsById from './selectVirtualLocationTreeNodeHintChildIdsById';

function selectVirtualLocationTreeNodeHintDescendantIdsById(DM, virtualLocationTreeNodeHintId) {
  let descendants = [];

  function traverse(hintId) {
    const childIds = selectVirtualLocationTreeNodeHintChildIdsById(DM, hintId);
    for (let childId of childIds) {
      descendants.push(childId);
      traverse(childId);
    }
  }

  traverse(virtualLocationTreeNodeHintId, descendants);

  return (
    descendants
  );
}

export default selectVirtualLocationTreeNodeHintDescendantIdsById;
