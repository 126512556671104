import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {POST} from '../../verbs';

export default async function POST__admin_typeLocationGraph_edges(authToken, perspectiveId, params) {
  return await POST(
    '/admin/type-location-graph/edges',
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
