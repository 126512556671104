import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PUT} from '../verbs';

export default async function PUT__records_ID_permissions(authToken, perspectiveId, recordId, params) {

  return await PUT(
    `/records/${recordId}/permissions`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
