const parseFieldValue = ({
  type,
  value,
  setError,
}) => {
  try {
    switch (type) {
      case 'number':
        return Math.round(parseFloat(value * 100)) / 100;
      case 'currency':
        return Math.round(parseFloat(value * 100)) / 100;
      case 'boolean':
        return Boolean(value);
      case 'year':
        return parseInt(value);
      case 'date':
        return value;
      default:
        return value.toString();
    }
  } catch(error) {
    setError(error);
  }
};

export default parseFieldValue;
