import React from 'react';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';
import Message from 'ui-library/components/Message';

import NoPermissionsModal from 'components/NoPermissionsModal';

import WarningIcon from '@material-ui/icons/WarningRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import parseNodePermissions from 'utils/permissions/parseNodePermissions';

import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';
import deleteNode from 'services/Nodes/deleteNode';

const NewDeleteNodeDialog = () => {
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [loadingStatus, setLoadingStatus] = React.useState('notLoaded');
  const [nodeName, setNodeName] = React.useState('');
  const [canDelete, setCanDelete] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const {
    showDeleteNodeDialog,
    expandedNodeId,
    deleteNodeId,
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const [error, setError] = React.useState(false);
  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  React.useEffect(() => {
    async function loadNode() {
      try {
        setLoadingStatus('loading');
        const node = await genNodeByNodeId({
          authToken,
          perspectiveId: currentPerspectiveId,
          nodeId: deleteNodeId,
        });
        const {
          name: nodeName,
          permissions,
        } = node;
        setNodeName(nodeName);
        const parsedPermissions = parseNodePermissions(permissions);
        const {
          frontendPermissions: {
            delete: deletePermissions,
          },
        } = parsedPermissions;
        setCanDelete(deletePermissions);
        setLoadingStatus('loaded');
      } catch (error) {
        setError(error);
      }
    }
    if (showDeleteNodeDialog) {
      loadNode();
    }
  }, [authToken, currentPerspectiveId, deleteNodeId, showDeleteNodeDialog]);

  const handleDeleteNode = async() => {
    try {
      setIsDeleting(true);
      await deleteNode({authToken, currentPerspectiveId, id: deleteNodeId});
      if (expandedNodeId !== null) {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_NODE_ID',
          payload: null,
        });
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_NODE_LOADING_STATE',
          payload: 'not loaded',
        });
      }
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_DELETE_NODE_DIALOG',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: deleteNodeId,
      });
      setIsDeleting(false);
      setShowSuccessMessage(true);
    } catch(error) {
      setError(error);
    }
  };

  const onClose = () => {
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_DELETE_NODE_DIALOG',
      payload: false,
    });
    if (expandedNodeId) {
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_NODE_PANEL',
        payload: true,
      });
    }
    setCanDelete(false);
    setLoadingStatus('notLoaded');
  };

  if (loadingStatus !== 'loaded') {
    return null;
  }

  return (
    <>
      <NoPermissionsModal
        isOpen={showDeleteNodeDialog && !canDelete}
        onClose={onClose}
        operation="Delete Item"
      />

      <ModalCard
        open={showDeleteNodeDialog && canDelete}
        handleClose={() => {
          onClose();
        }}
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick={true}
      >
        <ModalCardHeader color="error">
          <ModalCardHeaderIcon color="error">
            <WarningIcon
              fontSize="inherit"
            />
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
          Delete Item
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent>
          <Typography variant="body">
            {`Are you sure you want to delete ${nodeName}?`}
          </Typography>
          <br/><br/>
          <Typography variant="caption">
          This item will be permanently deleted including any attachments you have added to this item. All links and location assignments associated with this item will also be deleted.
          </Typography>
          <br/><br/>
          <Typography
            variant="caption"
            fontWeight="bold"
            color="warning-dark">
            When deleting a property all items assigned to that property will also be deleted.
          </Typography>
        </ModalCardContent>
        <ModalCardActions>
          <Button
            data-test-id="modal-card-cancel-button"
            variant="text"
            onClick={() => {
              onClose();
            }}
            disabled={isDeleting}
          >
            CANCEL
          </Button>
          <Button
            data-test-id="modal-card-delete-button"
            variant="custom"
            textTransform="none"
            customBgColor="error"
            customHoverBgColor="error-dark"
            customTextColor="white"
            customHoverTextColor="white"
            customActiveBgColor="error-darker"
            customActiveTextColor="white"
            startIcon={isDeleting ? <CircularProgress size={24} color="white"/> : <DeleteIcon />}
            disabled={isDeleting}
            onClick={handleDeleteNode}
          >
            DELETE
          </Button>
        </ModalCardActions>
      </ModalCard>

      <Message
        variant="success"
        open={showSuccessMessage}
        onClose={() => {
          setShowSuccessMessage(false);
        }}
        messageTitle="Item deleted successfully"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    </>
  );
};

export default NewDeleteNodeDialog;
