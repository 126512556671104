import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const CreateNotificationPipelineDialog = ({
  open,
  onClose,
  onSave,
  controlsDisabled,
  notificationPipelineName,
  onSetNotificationPipelineName,
  notificationPipelineDescription,
  onSetNotificationPipelineDescription,
  notificationPipelineNotificationTextTemplate,
  onSetNotificationPipelineNotificationTextTemplate,
  notificationPipelinePerspectiveTypes,
  onAddNotificationPipelinePerspectiveType,
  onRemoveNotificationPipelinePerspectiveType,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Create a notification pipeline
      </DialogTitle>
      <DialogContent>

        <Typography
          variant="h6">
          Name
        </Typography>
        <DialogContentText>
          Set a name for this notification pipeline. Users will see this name in their notification settings; to them, it will appear as a "notification type". A good name is something like <em>"Appliance warranty expirations"</em>. Be succint, you have a maximum of 50 characters here.<br/>
          (you can come back and edit this if you need to)
        </DialogContentText>
        <TextField
          fullWidth
          margin="dense"
          disabled={controlsDisabled}
          value={notificationPipelineName}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 50) {
              onSetNotificationPipelineName(value);
            }
          }}
          placeholder="name"
        />
        <br/><br/><br/>

        <Typography
          variant="h6">
          Description
        </Typography>
        <DialogContentText>
          Set a description for this notification pipeline. This is for internal use only.<br/>
          (you can come back and edit this if you need to)
        </DialogContentText>
        <TextField
          fullWidth
          margin="dense"
          disabled={controlsDisabled}
          value={notificationPipelineDescription}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 100) {
              onSetNotificationPipelineDescription(value);
            }
          }}
          placeholder="description"
        />
        <br/><br/><br/>

        <Typography
          variant="h6">
          Notification text template
        </Typography>
        <DialogContentText>
          Set a notification text template for this notification pipeline.<br/>
          Every field watcher you define on this pipeline will emit data in the form of a field value and a node name. You may interpolate this data in the notification text template using a double curly bracket notation.<br/>
          Example:<br/>
          <em>"Warranty for {'{{nodeName}}'} will expire on {'{{fieldValue}}'}"</em> will result in a notification text like:<br/>
          <em>"Warranty for Kitchen Refrigerator will expire on 10/10/2020."</em><br/>
          <strong>fieldValue</strong> and <strong>nodeName</strong> are strict identifiers and you must use them with these exact names.<br/>
          (you can come back and edit this if you need to)
        </DialogContentText>
        <TextField
          fullWidth
          margin="dense"
          disabled={controlsDisabled}
          value={notificationPipelineNotificationTextTemplate}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 300) {
              onSetNotificationPipelineNotificationTextTemplate(value);
            }
          }}
          placeholder="notification text template"
        />
        <br/><br/><br/>

        <Typography
          variant="h6">
          Perspectives
        </Typography>
        <DialogContentText>
          Choose which perspective(s) will be able to enable or disable these notifications.<br/>
          <span style={{color: 'red'}}>(this setting cannot be changed once the notification pipeline has been created!)</span>
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              disabled={controlsDisabled}
              checked={notificationPipelinePerspectiveTypes.includes('company')}
              onChange={() => {
                if (notificationPipelinePerspectiveTypes.includes('company')) {
                  onRemoveNotificationPipelinePerspectiveType('company');
                } else {
                  onAddNotificationPipelinePerspectiveType('company');
                }
              }}
            />
          }
          label="company"
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={controlsDisabled}
              checked={notificationPipelinePerspectiveTypes.includes('household')}
              onChange={() => {
                if (notificationPipelinePerspectiveTypes.includes('household')) {
                  onRemoveNotificationPipelinePerspectiveType('household');
                } else {
                  onAddNotificationPipelinePerspectiveType('household');
                }
              }}
            />
          }
          label="household"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          disabled={controlsDisabled}
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled ||
            !notificationPipelineName ||
            !notificationPipelineDescription ||
            !notificationPipelineNotificationTextTemplate ||
            !notificationPipelinePerspectiveTypes.length
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNotificationPipelineDialog;
