import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../../verbs';

export default async function PATCH__admin_nodeLinkDefinitions_NODELINKDEFINITIONID(authToken, perspectiveId, params) {
  const {
    nodeLinkDefinitionId,
    ...patchParams
  } = params;

  return await PATCH(
    `/admin/node-link-definitions/${nodeLinkDefinitionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...patchParams,
    },
  );
}
