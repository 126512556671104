import React from 'react';
import AddIcon from '@material-ui/icons/Add';

const PillPlus = ({
  onClick,
  ...otherProps
}) => {
  return (
    <span style={{'lineHeight': 0}} {...otherProps}>
      <AddIcon
        style={{cursor: 'pointer'}}
        onClick={() => {
          if (typeof onClick !== 'function') {
            return;
          }
          onClick();
        }}
      />
    </span>
  );
};

export default PillPlus;
