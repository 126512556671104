export default function connectionName(
  value,
  errorMessage = 'invalid connection name',
) {

  const allowedCharacters = new RegExp(/^[A-Za-z0-9-.'`’‘ ñÑáéíóúäëïöüÄÖÜÉ]*$/);
  if (!allowedCharacters.test(value)) {
    return errorMessage;
  }

  if (value.trim().length === 0) {
    return errorMessage;
  }

  if (value.length > 255) {
    return errorMessage;
  }

  return '';
}
