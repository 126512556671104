import React from 'react';
import Bugsnag from '@bugsnag/js';
import styled from '@emotion/styled';
import ProblemIcon from '@material-ui/icons/ReportProblem';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';

const Viewport = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MainContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  color: red;
  padding: 20px;
`;

const TextContainer = styled.div`
`;

const FallbackUI = () => {
  return (
    <Viewport>
      <MainContainer>
        <IconContainer>
          <ProblemIcon
            style={{
              fontSize: 125,
            }}
          />
        </IconContainer>
        <TextContainer>
          <Typography variant="h3">
            Something went wrong.
          </Typography>
          <br/>
          <Typography variant="body">
            The Jules team has been notified.
          </Typography>
          <br/>
          <Button
            variant="text"
            onClick={() => {
              window.location = '/';
              return false;
            }}>
            get back to the app
          </Button>
          <Button
            variant="text"
            onClick={() => {
              window.location.reload();
              return false;
            }}>
            try again
          </Button>
        </TextContainer>
      </MainContainer>
    </Viewport>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    Bugsnag.notify(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <FallbackUI/>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
