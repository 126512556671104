import GET__records_ID_nodeCount from 'network-layer/endpoints/GET__records_ID_nodeCount';

function responseAdapter(response) {
  return response;
}

export default async function genInboundRecordsByConnectionId(authToken, perspectiveId, recordId) {
  const response = await GET__records_ID_nodeCount(authToken, perspectiveId, recordId);

  return responseAdapter(response);
}
