import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {DELETE} from '../verbs';

export default async function DELETE__notifications_devices(authToken, devicePlayerId) {

  return await DELETE(
    `/notifications/devices/${devicePlayerId}`,
    configWithAuthorization(defaultConfig(), authToken),
  );
}
