import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui-library/components/Button';
import FilterListIcon from '@material-ui/icons/FilterListRounded';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    display: theme.addNewFilterButton.root.display,
    alignItems: theme.addNewFilterButton.root.alignItems,
    padding: theme.addNewFilterButton.root.padding,
    cursor: theme.addNewFilterButton.root.cursor,
    transition: theme.addNewFilterButton.root.transition,
  },
});

const AddNewFilterButton = ({
  disabled,
  active,
  onClick,
  'data-test-id': dataTestId,
}) => {

  const classes = useStyles();

  return (
    <div
      className={classes.root}
      onClick={onClick}
      data-test-id={dataTestId}
    >
      <Button
        variant="custom"
        customBgColor={active ? 'tertiary' : 'tertiary-light'}
        customHoverBgColor="tertiary"
        customActiveBgColor="tertiary"
        style={{borderRadius: '4px'}}
        disabled={disabled}
        disableRipple
        startIcon={<FilterListIcon />}>
        FILTER
      </Button>
    </div>
  );
};

AddNewFilterButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default AddNewFilterButton;
