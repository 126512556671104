function selectPropertyConfigurationHintById(DM, propertyConfigurationHintId) {
  const {
    propertyConfigurationHintsById: {
      [propertyConfigurationHintId]: propertyConfigurationHint,
    },
  } = DM;

  return propertyConfigurationHint;
}

export default selectPropertyConfigurationHintById;
