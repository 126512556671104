import React from 'react';
import styled from '@emotion/styled';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Typography from 'ui-library/components/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleSwitch from 'ui-library/components/ToggleSwitch';
import Message from 'ui-library/components/Message';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Button from 'ui-library/components/Button';
import TimeAgo from 'timeago-react';
import Grid from '@material-ui/core/Grid';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import AddNewListItemButton from 'ui-library/components/AddNewListItemButton';
import WarningIcon from '@material-ui/icons/WarningRounded';
import PushNotificationsFinishSetupDialog from 'components/NotificationsPanel/PushNotificationsFinishSetupDialog';

import UserContext from 'contexts/User';
import PushNotificationsContext from 'contexts/PushNotifications';

import updateNotificationType from 'services/Notifications/updateNotificationType';
import genNotificationDevices from 'services/Notifications/genNotificationDevices';
import updateNotificationsPushStatus from 'services/Notifications/updateNotificationsPushStatus';
import deleteNotificationDevice from 'services/Notifications/deleteNotificationDevice';
import addNotificationsDevice from 'services/Notifications/addNotificationsDevice';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const NotificationTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
`;

const TitleContainer = styled.div`
  text-transform: uppercase;
  padding: 35px 0 0 0;
`;

const NotificationDevicesLoading = styled.div`
  margin: 8px 0;
`;

const NotificationDevicesContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const NotificationDeviceItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px 0px 3px 16px;
  justify-content: space-between;
  height: 56px;
  :not(:last-child) {
    border-bottom: 1px solid #E6E6E6;
  }
`;

const NotificationDeviceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NotificationDeviceActions = styled.div`
  display: flex;
`;

const CurrentDeviceContainer = styled.div`
  margin-top: 32px;
`;

const NotificationsSettings = ({
  isPanelOpen,
  pushStatus,
  notificationTypeIds,
  notificationTypesById,
  dispatchNotificationsAction,
}) => {
  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const {
    oneSignalPlayerId,
    browserNotifications,
    deviceListUpdated,
    setDeviceListUpdated,
  } = React.useContext(PushNotificationsContext);

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [messageType, setMessageType] = React.useState({
    messageTitle: '',
    messageBody: '',
    messageVariant: 'success',
  });
  const [notificationDevices, setNotificationDevices] = React.useState(null);
  const [deleteDeviceData, setDeleteDeviceData] = React.useState({
    playerId: null,
    deviceModel: '',
    deviceOs: '',
  });
  const [thisDeviceData, setThisDeviceData] = React.useState({
    playerId: null,
    deviceModel: '',
    deviceOs: '',
  });
  const [
    showBrowserNotificationsDisabledModal,
    setShowBrowserNotificationsDisabledModal,
  ] = React.useState(false);

  const loadDevices = React.useCallback(async() => {
    const allDevices = await genNotificationDevices(authToken);
    const thisDeviceIndex = allDevices.findIndex(e => e.playerId === oneSignalPlayerId);

    if (thisDeviceIndex !== -1) {
      const {
        playerId: thisDevicePlayerId,
        deviceModel: thisDeviceModel,
        deviceOs: thisDeviceOs,
      } = allDevices[thisDeviceIndex];
      setThisDeviceData({
        playerId: thisDevicePlayerId,
        deviceModel: thisDeviceModel,
        deviceOs: thisDeviceOs,
      });
      allDevices.splice(thisDeviceIndex, 1);
    }

    setNotificationDevices(allDevices);
  }, [authToken, oneSignalPlayerId]);

  React.useEffect(() => {
    if (pushStatus === 'enabled') {
      loadDevices();
    } else {
      setNotificationDevices([]);
    }
  }, [pushStatus, loadDevices]);

  React.useEffect(() => {
    if (deviceListUpdated) {
      loadDevices();
      setDeviceListUpdated(false);
    }
  }, [loadDevices, deviceListUpdated, setDeviceListUpdated]);

  return (
    <>
      <PaddedContainer>
        <TitleContainer>
          <Typography variant="caption" fontWeight="bold" color="primary">
            NOTIFICATION TYPES
          </Typography>
        </TitleContainer>
        <Typography variant="caption" fontWeight="normal" color="black">
          Select the types of notifications you would like to receive.
        </Typography>

        <NotificationTypesContainer>
          {
            notificationTypeIds.map(notificationTypeId => {
              const {
                name,
                enabledStatus,
              } = notificationTypesById[notificationTypeId];
              return (
                <div key={notificationTypeId}>
                  <FormControlLabel
                    control={
                      <ToggleSwitch
                        data-test-id="update-notificationType-switch"
                        checked={enabledStatus === 'enabled' ? true : false}
                        onChange={async() => {
                          try {
                            const updatedStatus = enabledStatus === 'enabled' ? 'disabled' : 'enabled';
                            dispatchNotificationsAction({
                              type: 'TOGGLE_NOTIFICATION_TYPE',
                              payload: {
                                notificationTypeId,
                                enabledStatus: updatedStatus,
                              },
                            });
                            await updateNotificationType({
                              authToken,
                              notificationTypeId,
                              enabledStatus: updatedStatus,
                            });
                            setShowSuccessMessage(true);
                            setMessageType({
                              messageTitle: 'Success',
                              messageBody: `Notification type ${updatedStatus} successfully!`,
                              messageVariant: 'success',
                            });
                          } catch(error) {
                            setShowSuccessMessage(true);
                            setMessageType({
                              messageTitle: 'Error',
                              messageBody: 'Notification type could not be updated.',
                              messageVariant: 'error',
                            });
                          }
                        }}
                        value={enabledStatus}
                      />}
                    label={
                      <Typography
                        variant="body"
                        fontWeight="normal"
                        fontFamily="primary"
                      >
                        {name}
                      </Typography>
                    }
                  />
                </div>
              );
            })
          }
        </NotificationTypesContainer>

        <FormControlLabel
          control={
            <ToggleSwitch
              data-test-id='update-push-notifications-switch'
              checked={pushStatus === 'enabled'}
              onChange={async() => {
                const updatedStatus = pushStatus === 'enabled' ? 'disabled' : 'enabled';
                dispatchNotificationsAction({
                  type: 'HYDRATE_PUSH_STATUS',
                  payload: updatedStatus,
                });
                await updateNotificationsPushStatus(authToken, updatedStatus);
              }}
            />
          }
          label={
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="caption" fontWeight="bold" color="primary">
                PUSH NOTIFICATIONS
              </Typography>
              <Typography variant="caption" fontWeight="normal">
                  Enable push notifications for registered devices.
              </Typography>
            </div>
          }
        />

        {
          notificationDevices !== null &&
          pushStatus === 'enabled' &&
          thisDeviceData.playerId === null &&
          <CurrentDeviceContainer>
            <AddNewListItemButton
              label="Register this device for push notifications"
              onClick={async() => {
                try {
                  if (!oneSignalPlayerId && browserNotifications !== 'denied') {
                    window.OneSignal.push(() => {
                      window.OneSignal.showSlidedownPrompt({
                        force: true,
                      });
                    });
                  }

                  if (oneSignalPlayerId !== null) {
                    switch (browserNotifications) {
                      case 'granted':
                      case 'denied':
                        await addNotificationsDevice(authToken, oneSignalPlayerId);
                        loadDevices();
                        break;
                      default:
                        window.OneSignal.push(() => {
                          window.OneSignal.showSlidedownPrompt({
                            force: true,
                          });
                        });
                    }
                  }
                } catch (error) {
                  setShowSuccessMessage(true);
                  setMessageType({
                    messageTitle: 'Error',
                    messageBody: 'Device could not be registered.',
                    messageVariant: 'error',
                  });
                }
              }}>
              {
                browserNotifications === 'denied' && (
                  <Button
                    variant="custom"
                    textTransform="none"
                    customBgColor="transparent"
                    customTextColor="error"
                    customHoverBgColor="transparent"
                    customHoverTextColor="error"
                    customActiveBgColor="transparent"
                    customActiveTextColor="error"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowBrowserNotificationsDisabledModal(true);
                    }}
                  >
                    <WarningIcon />
                  </Button>
                )
              }
            </AddNewListItemButton>
          </CurrentDeviceContainer>
        }

        {pushStatus === 'enabled' && thisDeviceData.playerId !== null && (
          <NotificationDevicesContainer>
            <NotificationDeviceItem>
              <NotificationDeviceInfo>
                <Typography
                  color="primary"
                  variant="small"
                  fontWeight="bold"
                  fontFamily="primary"
                >
                  This Device
                </Typography>
              </NotificationDeviceInfo>
              <NotificationDeviceActions>
                {browserNotifications === 'denied' && (
                  <Button
                    variant="custom"
                    textTransform="none"
                    customBgColor="transparent"
                    customTextColor="error"
                    customHoverBgColor="transparent"
                    customHoverTextColor="error"
                    customActiveBgColor="transparent"
                    customActiveTextColor="error"
                    onClick={() => setShowBrowserNotificationsDisabledModal(true)}
                  >
                    <WarningIcon />
                  </Button>
                )}
                <Button
                  variant="custom"
                  textTransform="none"
                  customBgColor="transparent"
                  customTextColor="grey"
                  customHoverBgColor="transparent"
                  customHoverTextColor="error"
                  customActiveBgColor="transparent"
                  customActiveTextColor="error"
                  onClick={() => setDeleteDeviceData({
                    playerId: thisDeviceData.playerId,
                    deviceModel: thisDeviceData.deviceModel,
                    deviceOs: thisDeviceData.deviceOs,
                  })}
                >
                  <CloseIcon />
                </Button>
              </NotificationDeviceActions>
            </NotificationDeviceItem>
          </NotificationDevicesContainer>
        )}

        {notificationDevices === null && (
          <NotificationDevicesLoading>
            <CenterpieceSpinner />
          </NotificationDevicesLoading>
        )}

        {notificationDevices !== null && notificationDevices.length > 0 && (
          <NotificationDevicesContainer>
            {notificationDevices.map((device, key) => (
              <NotificationDeviceItem key={key}>
                <NotificationDeviceInfo>
                  <Typography
                    variant="small"
                    fontWeight="bold"
                    fontFamily="primary"
                  >
                    {device.deviceModel} ({device.deviceOs})
                  </Typography>
                  <Typography
                    variant="caption"
                    fontWeight="normal"
                    fontFamily="primary"
                    color="grey"
                  >
                    <TimeAgo
                      datetime={device.lastActive * 1000}
                      locale='en-US'
                      live={false}
                    />
                  </Typography>
                </NotificationDeviceInfo>
                <NotificationDeviceActions>
                  <Button
                    variant="custom"
                    textTransform="none"
                    customBgColor="transparent"
                    customTextColor="grey"
                    customHoverBgColor="transparent"
                    customHoverTextColor="error"
                    customActiveBgColor="transparent"
                    customActiveTextColor="error"
                    onClick={() => setDeleteDeviceData({
                      playerId: device.playerId,
                      deviceModel: device.deviceModel,
                      deviceOs: device.deviceOs,
                    })}
                  >
                    <CloseIcon />
                  </Button>
                </NotificationDeviceActions>
              </NotificationDeviceItem>
            ))}
          </NotificationDevicesContainer>
        )}

      </PaddedContainer>
      <Message
        variant={messageType.messageVariant}
        open={showSuccessMessage}
        onClose={() => {
          setShowSuccessMessage(false);
        }}
        messageTitle={messageType.messageTitle}
        messageBody={messageType.messageBody}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />

      <ModalCard
        open={deleteDeviceData.playerId !== null}
        handleClose={() => setDeleteDeviceData({playerId: null})}
        maxWidth="sm"
        fullWidth={true}
      >
        <ModalCardHeader
          handleClose={() => setDeleteDeviceData({playerId: null})}
          color="error"
        >
          <ModalCardHeaderIcon
            color="error"
          >
            <WarningIcon
              fontSize="inherit"
            />
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
            Remove Device
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent>
          <Typography
            variant="body"
          >
            Are you sure you want to deregister this device from receiving push notifications?
          </Typography>
          <br/><br/>
          <Typography
            variant="body"
            fontWeight="bold"
          >
            {deleteDeviceData.deviceModel} ({deleteDeviceData.deviceOs})
          </Typography>
        </ModalCardContent>
        <ModalCardActions>
          <Grid item xs={8}/>
          <Grid item xs={2}>
            <Button
              variant="text"
              onClick={() => setDeleteDeviceData({playerId: null})}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={async() => {
                try {
                  await deleteNotificationDevice(authToken, deleteDeviceData.playerId);
                  if (thisDeviceData.playerId === deleteDeviceData.playerId) {
                    setThisDeviceData({
                      playerId: null,
                      deviceModel: '',
                      deviceOs: '',
                    });
                  }
                  loadDevices();
                  setDeleteDeviceData({playerId: null});
                  setShowSuccessMessage(true);
                  setMessageType({
                    messageTitle: 'Device removed successfully!',
                    messageBody: 'You will no longer receive push notifications on this device.',
                    messageVariant: 'success',
                  });
                } catch (error) {
                  setShowSuccessMessage(true);
                  setMessageType({
                    messageTitle: 'Error',
                    messageBody: 'Device could not be unregistered.',
                    messageVariant: 'error',
                  });
                }
              }}
            >
              Remove Device
            </Button>
          </Grid>
        </ModalCardActions>
      </ModalCard>

      <PushNotificationsFinishSetupDialog
        isOpen={showBrowserNotificationsDisabledModal}
        handleClose={() => setShowBrowserNotificationsDisabledModal(false)}
      />
    </>
  );
};

export default NotificationsSettings;
