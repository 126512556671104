import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspectiveWithoutDefaultHeaders from '../config/configWithAuthorizationAndPerspectiveWithoutDefaultHeaders';
import {GET} from '../verbs';

export default async function GET__imagePreviews_FILENAME(authToken, perspectiveId, fileName) {

  const response = await GET(
    `/image-previews/${fileName}`,
    configWithAuthorizationAndPerspectiveWithoutDefaultHeaders(defaultConfig(), authToken, perspectiveId),
  );

  return response;
}
