import React from 'react';
import styled from '@emotion/styled';
import PageContainer from 'components/chrome/PageContainer';
import PageTitle from 'components/chrome/PageTitle';
import PageDescription from 'components/chrome/PageDescription';
import PageContent from 'components/chrome/PageContent';
import SelectAllItems from 'components/SelectAllItems';
import Divider from '@material-ui/core/Divider';
import NodeList from 'components/NodeList';
import FilterRow from 'ui-library/components/FilterRow';
import FilterPill from 'ui-library/components/FilterPill';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import NewSearchBar from 'ui-library/components/NewSearchBar';
import SearchPill from 'ui-library/components/SearchPill';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import Tabs from 'ui-library/components/Tabs';
import Message from 'ui-library/components/Message';
import BatchActionButton from 'ui-library/components/BatchActionButton';
import BatchActionMenu from 'ui-library/components/BatchActionMenu';
import BatchDeleteWarningDialog from 'components/BatchDeleteWarningDialog';
import BatchDeleteDialog from 'components/BatchDeleteDialog';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';
import PushNotificationsContext from 'contexts/PushNotifications';

import getSearchParam from 'utils/getSearchParam';
import pushSearchParams from 'utils/pushSearchParams';
import replaceSearchParams from 'utils/replaceSearchParams';
import getURLParam from 'utils/getURLParam';
import buildURL from 'utils/buildURL';

import {
  INTERNAL_SEARCH_CONTEXT,
  EXTERNAL_SEARCH_CONTEXT,
  DEFAULT_SEARCH_CONTEXT,
  AVAILABLE_SEARCH_CONTEXTS,
  AVAILABLE_SEARCH_FILTERS,
} from 'constants/search';

import {
  initSearchState,
  searchReducer,
} from 'reducers/Search';

import genSearchFiltersCategoryTree from 'services/Search/genSearchFiltersCategoryTree';
import genSearchFilters from 'services/Search/genSearchFilters';
import genSearch from 'services/Search/genSearch';
import deleteNode from 'services/Nodes/deleteNode';
import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';
import genRecordByRecordId from 'services/Records/genRecordByRecordId';
import genConnectionByConnectionId from 'services/Connections/genConnectionByConnectionId';
import updateNotificationById from 'services/Notifications/updateNotificationById';
import genConversationsByNodeId from 'services/Conversations/genConversationsByNodeId';
import genConversationsByRecordId from 'services/Conversations/genConversationsByRecordId';
import genConversationsByConnectionId from 'services/Conversations/genConversationsByConnectionId';

const ListContainer = styled.div`
`;

const NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const HomeownerSearch = ({
  match,
  history,
  location,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = React.useState(false);
  const [isAddNewFilterDisabled, setIsAddNewFilterDisabled] = React.useState(false);

  const [showSearchPill, setShowSearchPill] = React.useState(false);

  const [status, setStatus] = React.useState('initializing', 'ready');
  const [nodes, setNodes] = React.useState([]);

  const [showBatchDeleteWarningDialog, setShowBatchDeleteWarningDialog] = React.useState(false);
  const [showBatchDeleteDialog, setShowBatchDeleteDialog] = React.useState(false);
  const [batchDeleteSuccess, setBatchDeleteSuccess] = React.useState(false);

  const [allContextSelectedNodeIds, setAllContextSelectedNodeIds] = React.useState([]);
  const [internalContextSelectedNodeIds, setInternalContextSelectedNodeIds] = React.useState([]);
  const [externalContextSelectedNodeIds, setExternalContextSelectedNodeIds] = React.useState([]);

  const [searchState, dispatchSearchAction] = React.useReducer(
    searchReducer,
    null,
    initSearchState,
  );

  const {
    dispatchUserInterfaceAction,
    nodeIdModifiedDestructivelyForLists,
  } = React.useContext(UserInterfaceContext);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const {
    notificationData,
    setNotificationData,
  } = React.useContext(PushNotificationsContext);

  React.useEffect(() => {
    const checkNotificationResourceAccess = async(resource) => {
      const {
        type,
      } = resource;

      switch (type) {
        case 'node': {
          const {
            nodeId,
          } = resource;

          try {
            await genNodeByNodeId({authToken, perspectiveId: currentPerspectiveId, nodeId});
            return true;
          } catch(error) {
            return false;
          }
        }
        case 'record': {
          const {
            recordId,
          } = resource;

          try {
            await genRecordByRecordId(authToken, currentPerspectiveId, recordId);
            return true;
          } catch(error) {
            return false;
          }
        }
        case 'connection': {
          const {
            connectionId,
          } = resource;

          try {
            await genConnectionByConnectionId(authToken, currentPerspectiveId, connectionId);
            return true;
          } catch(error) {
            return false;
          }
        }
        default: {
          break;
        }
      }
    };
    const parseNotification = async() => {
      const {
        notificationId,
        notificationEvent,
      } = notificationData;

      switch (notificationEvent) {
        case 'noop': {
          break;
        }
        case 'node emitted notification': {
          const {
            nodeId,
          } = notificationData;

          const hasAccess = await checkNotificationResourceAccess({
            type: 'node',
            nodeId,
          });
          if (hasAccess) {
            dispatchUserInterfaceAction({
              type: 'SET_EXPANDED_NODE_ID',
              payload: nodeId,
            });
            dispatchUserInterfaceAction({
              type: 'SET_BACK_NAVIGATION_ENABLED',
              payload: {
                backNavigationEnabled: true,
                backNavigationLanding: 'notifications',
              },
            });
            dispatchUserInterfaceAction({
              type: 'SET_SHOW_NODE_PANEL',
              payload: true,
            });
          }
          try {
            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'connection established': {
          const {
            connectionId,
          } = notificationData;

          const hasAccess = await checkNotificationResourceAccess({
            type: 'connection',
            connectionId,
          });
          if (hasAccess) {
            dispatchUserInterfaceAction({
              type: 'SET_EXPANDED_CONNECTION_ID',
              payload: connectionId,
            });
            dispatchUserInterfaceAction({
              type: 'SET_BACK_NAVIGATION_ENABLED',
              payload: {
                backNavigationEnabled: true,
                backNavigationLanding: 'notifications',
              },
            });
            dispatchUserInterfaceAction({
              type: 'SET_SHOW_CONNECTION_PANEL',
              payload: true,
            });
          }
          try {
            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'connection destroyed': {
          break;
        }
        case 'permissions granted on Record by partner or third party': {
          const {
            recordId,
          } = notificationData;

          const hasAccess = await checkNotificationResourceAccess({
            type: 'record',
            recordId,
          });
          if (hasAccess) {
            dispatchUserInterfaceAction({
              type: 'SET_EXPANDED_RECORD_ID',
              payload: recordId,
            });
            dispatchUserInterfaceAction({
              type: 'SET_BACK_NAVIGATION_ENABLED',
              payload: {
                backNavigationEnabled: true,
                backNavigationLanding: 'notifications',
              },
            });
            dispatchUserInterfaceAction({
              type: 'SET_SHOW_RECORD_PANEL',
              payload: true,
            });
          }
          try {
            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'permissions granted on Node by partner or third party': {
          const {
            nodeId,
          } = notificationData;

          const hasAccess = await checkNotificationResourceAccess({
            type: 'node',
            nodeId,
          });
          if (hasAccess) {
            dispatchUserInterfaceAction({
              type: 'SET_EXPANDED_NODE_ID',
              payload: nodeId,
            });
            dispatchUserInterfaceAction({
              type: 'SET_BACK_NAVIGATION_ENABLED',
              payload: {
                backNavigationEnabled: true,
                backNavigationLanding: 'notifications',
              },
            });
            dispatchUserInterfaceAction({
              type: 'SET_SHOW_NODE_PANEL',
              payload: true,
            });
          }
          try {
            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'conversation comment node': {
          try {
            const {
              nodeId,
              conversationId,
            } = notificationData;

            const conversations = await genConversationsByNodeId({
              authToken,
              perspectiveId: currentPerspectiveId,
              nodeId,
            });

            const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
            const {
              status: conversationStatus,
            } = conversation || {};

            if (conversationStatus === 'frozen') {
              dispatchUserInterfaceAction({
                type: 'SET_BACK_NAVIGATION_ENABLED',
                payload: {
                  backNavigationEnabled: true,
                  backNavigationLanding: 'conversations',
                },
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                payload: false,
              });
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                payload: conversation,
              });
            } else {
              const hasAccess = await checkNotificationResourceAccess({
                type: 'node',
                nodeId,
              });

              if (hasAccess) {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_NODE_ID',
                  payload: nodeId,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_NODE_PANEL_SELECTED_TAB',
                  payload: 5,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: conversation,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_NODE_PANEL_DISPLAY_MODE',
                  payload: 'conversation',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: true,
                    backNavigationLanding: 'conversations',
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_NODE_PANEL',
                  payload: true,
                });
              }
            }

            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'conversation comment node edit': {
          try {
            const {
              nodeId,
              conversationId,
            } = notificationData;


            const conversations = await genConversationsByNodeId({
              authToken,
              perspectiveId: currentPerspectiveId,
              nodeId,
            });

            const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
            const {
              status: conversationStatus,
            } = conversation || {};

            if (conversationStatus === 'frozen') {
              dispatchUserInterfaceAction({
                type: 'SET_BACK_NAVIGATION_ENABLED',
                payload: {
                  backNavigationEnabled: true,
                  backNavigationLanding: 'conversations',
                },
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                payload: false,
              });
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                payload: conversation,
              });
            } else {
              const hasAccess = await checkNotificationResourceAccess({
                type: 'node',
                nodeId,
              });

              if (hasAccess) {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_NODE_ID',
                  payload: nodeId,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_NODE_PANEL_SELECTED_TAB',
                  payload: 5,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: conversation,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_NODE_PANEL_DISPLAY_MODE',
                  payload: 'conversation',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: true,
                    backNavigationLanding: 'conversations',
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_NODE_PANEL',
                  payload: true,
                });
              }
            }

            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'conversation comment record': {
          try {
            const {
              recordId,
              conversationId,
            } = notificationData;

            const conversations = await genConversationsByRecordId({
              authToken,
              perspectiveId: currentPerspectiveId,
              recordId,
            });

            const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
            const {
              status: conversationStatus,
            } = conversation || {};

            if (conversationStatus === 'frozen') {
              dispatchUserInterfaceAction({
                type: 'SET_BACK_NAVIGATION_ENABLED',
                payload: {
                  backNavigationEnabled: true,
                  backNavigationLanding: 'conversations',
                },
              });
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                payload: conversation,
              });
            } else {
              const hasAccess = await checkNotificationResourceAccess({
                type: 'record',
                recordId,
              });

              if (hasAccess) {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_RECORD_ID',
                  payload: recordId,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_RECORD_PANEL_SELECTED_TAB',
                  payload: 1,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: conversation,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                  payload: 'conversation',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: true,
                    backNavigationLanding: 'conversations',
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_RECORD_PANEL',
                  payload: true,
                });
              }
            }

            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'conversation comment record edit': {
          try {
            const {
              recordId,
              conversationId,
            } = notificationData;

            const conversations = await genConversationsByRecordId({
              authToken,
              perspectiveId: currentPerspectiveId,
              recordId,
            });

            const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
            const {
              status: conversationStatus,
            } = conversation || {};

            if (conversationStatus === 'frozen') {
              dispatchUserInterfaceAction({
                type: 'SET_BACK_NAVIGATION_ENABLED',
                payload: {
                  backNavigationEnabled: true,
                  backNavigationLanding: 'conversations',
                },
              });
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                payload: conversation,
              });
            } else {
              const hasAccess = await checkNotificationResourceAccess({
                type: 'record',
                recordId,
              });

              if (hasAccess) {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_RECORD_ID',
                  payload: recordId,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_RECORD_PANEL_SELECTED_TAB',
                  payload: 1,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: conversation,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                  payload: 'conversation',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: true,
                    backNavigationLanding: 'conversations',
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_RECORD_PANEL',
                  payload: true,
                });
              }
            }

            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'conversation comment connection': {
          try {
            const {
              connectionId,
              conversationId,
            } = notificationData;

            const conversations = await genConversationsByConnectionId({
              authToken,
              perspectiveId: currentPerspectiveId,
              connectionId,
            });

            const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
            const {
              status: conversationStatus,
            } = conversation || {};

            if (conversationStatus === 'frozen') {
              dispatchUserInterfaceAction({
                type: 'SET_BACK_NAVIGATION_ENABLED',
                payload: {
                  backNavigationEnabled: true,
                  backNavigationLanding: 'conversations',
                },
              });
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                payload: conversation,
              });
            } else {
              const hasAccess = await checkNotificationResourceAccess({
                type: 'connection',
                connectionId,
              });

              if (hasAccess) {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_CONNECTION_ID',
                  payload: connectionId,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_CONNECTION_PANEL_DISPLAY_MODE',
                  payload: 'conversation',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: conversation,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: true,
                    backNavigationLanding: 'conversations',
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_CONNECTION_PANEL',
                  payload: true,
                });
              }
            }

            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        case 'conversation comment connection edit': {
          try {
            const {
              connectionId,
              conversationId,
            } = notificationData;

            const conversations = await genConversationsByConnectionId({
              authToken,
              perspectiveId: currentPerspectiveId,
              connectionId,
            });

            const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
            const {
              status: conversationStatus,
            } = conversation || {};

            if (conversationStatus === 'frozen') {
              dispatchUserInterfaceAction({
                type: 'SET_BACK_NAVIGATION_ENABLED',
                payload: {
                  backNavigationEnabled: true,
                  backNavigationLanding: 'conversations',
                },
              });
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                payload: conversation,
              });
            } else {
              const hasAccess = await checkNotificationResourceAccess({
                type: 'connection',
                connectionId,
              });

              if (hasAccess) {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_CONNECTION_ID',
                  payload: connectionId,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_CONNECTION_PANEL_DISPLAY_MODE',
                  payload: 'conversation',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: conversation,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: true,
                    backNavigationLanding: 'conversations',
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_CONNECTION_PANEL',
                  payload: true,
                });
              }
            }

            await updateNotificationById(authToken, notificationId, {readStatus: 'read'});
            setNotificationData(null);
          } catch (error) {
            setNotificationData(null);
          }
          break;
        }
        default: {
          break;
        }
      }
    };

    if (notificationData) {
      parseNotification();
    }
  }, [authToken, currentPerspectiveId, dispatchUserInterfaceAction, notificationData, setNotificationData]);

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const [error, setError] = React.useState(false);
  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  const loadCategoryTree = async(rootNodeId) => {
    try {
      return await genSearchFiltersCategoryTree(authToken, currentPerspectiveId, rootNodeId);
    } catch(error) {
      setError(error);
    }
  };

  const searchContext = getURLParam(match)('searchContext');
  const {
    [searchContext]: {
      filterPillCriteria,
      filterPillValues,
      searchQuery,
    },
  } = searchState;

  React.useEffect(() => {
    const searchQueryFromURL = getSearchParam(location)('searchQuery');
    if (searchQueryFromURL) {
      dispatchSearchAction({
        type: 'SET_SEARCH_QUERY',
        payload: {
          searchContext: searchContext,
          searchQuery: searchQueryFromURL,
        },
      });
      setShowSearchPill(true);
    }
    if (!searchQueryFromURL && showSearchPill) {
      setShowSearchPill(false);
      dispatchSearchAction({
        type: 'SET_SEARCH_QUERY',
        payload: {
          searchContext: searchContext,
          searchQuery: '',
        },
      });
    }
  }, [location, searchContext, showSearchPill]);

  React.useEffect(() => {
    const initializeFilterPillValues = (filterPillParams) => {
      const {
        filterValuesFromURL,
        options,
        filterName,
      } = filterPillParams;

      if (!filterValuesFromURL) {
        return [];
      }

      switch (filterName) {
        case 'categoryId': {
          const {
            parentId,
            rootId,
          } = filterPillParams;
          if (parentId && rootId) {
            return [{
              id: parseInt(filterValuesFromURL[0]),
              parentId: parseInt(parentId),
              rootId: parseInt(rootId),
            }];
          }
          return [{
            id: parseInt(filterValuesFromURL[0]),
          }];
        }
        default:
          return filterValuesFromURL.map(id => {
            return options.find(option => option.id === parseInt(id));
          });
      }
    };

    const loadActiveFilters = async() => {
      let criteria = [];
      let values = [];
      const displayIndex = getSearchParam(location)('displayIndex');
      if (displayIndex){
        for (let index in displayIndex) {
          const criterionName = displayIndex[index];
          const crtCriterion = AVAILABLE_SEARCH_FILTERS.find(
            criteria => criteria.name === criterionName,
          );

          const options = await genSearchFilters({
            authToken,
            currentPerspectiveId,
            context: searchContext,
            criterion: criterionName,
          });
          criteria = [
            ...criteria,
            {...crtCriterion, isPillLoading: false, options: options},
          ];

          const filterValuesFromURL = getSearchParam(location)(crtCriterion.filter);
          switch (criterionName) {
            case 'category': {
              const filterValues = initializeFilterPillValues({
                filterValuesFromURL,
                options,
                filterName: crtCriterion.filter,
                rootId: getSearchParam(location)('categoryRootId'),
                parentId: getSearchParam(location)('categoryParentId'),
              });
              values = [...values, filterValues];
              break;
            }
            default: {
              const filterValues = initializeFilterPillValues({
                filterValuesFromURL,
                options,
                filterName: crtCriterion.filter,
              });
              values = [...values, filterValues];
            }
          }
        }
      }
      dispatchSearchAction({
        type: 'SET_FILTER_PILLS',
        payload: {
          context: searchContext,
          filterPillCriteria: criteria,
          filterPillValues: values,
        },
      });
    };
    loadActiveFilters();
  }, [authToken, currentPerspectiveId, location, searchContext]);

  const loadNodes = React.useCallback(async(action, currentOffset) => {
    const searchQuery = getSearchParam(location)('searchQuery');

    const recordId = getSearchParam(location)('recordId');
    const typeId = getSearchParam(location)('typeId');
    const categoryId = getSearchParam(location)('categoryId');
    const nodeTagId = getSearchParam(location)('nodeTagId');
    const connectionId = getSearchParam(location)('connectionId');

    if (!searchContext) {
      return;
    }

    if (!AVAILABLE_SEARCH_CONTEXTS.includes(searchContext)){
      return;
    }

    if(![
      searchQuery,
      recordId,
      typeId,
      categoryId,
      nodeTagId,
      connectionId,
    ].some(value => value)) {
      setStatus('ready');
      setNodes([]);
      return;
    };

    try {
      const response = await genSearch(
        authToken,
        currentPerspectiveId,
        {
          searchContext,
          searchQuery,
          recordId,
          typeId,
          categoryId,
          nodeTagId,
          connectionId,
        },
      );

      setNodes(response.map(node => {
        return {
          ...node,
          isSelected: false,
        };
      }));
      setStatus('ready');
    } catch (error) {
      setError(error);
    }
  }, [authToken, currentPerspectiveId, location, searchContext]);

  React.useEffect(() => {
    loadNodes();
  }, [loadNodes, location]);

  React.useEffect(() => {
    if (nodeIdModifiedDestructivelyForLists !== null) {
      loadNodes();
      dispatchUserInterfaceAction({
        type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: null,
      });
    }
  }, [dispatchUserInterfaceAction, loadNodes, nodeIdModifiedDestructivelyForLists]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (isFilterMenuOpen) {
        setIsFilterMenuOpen(false);
      }
    };
    document.body.addEventListener('scroll', handleScroll);
    return () => document.body.removeEventListener('scroll', handleScroll);
  });

  /* ---------------------------------------------------------- */

  const addFilterPillCriterion = async(criterion) => {
    dispatchSearchAction({
      type: 'ADD_FILTER_PILL_CRITERION',
      payload: {
        searchContext: searchContext,
        filterPillCriterion: {...criterion, isPillLoading: true},
      },
    });
    setIsFilterMenuOpen(false);
    setAnchorEl(null);
    setIsAddNewFilterDisabled(true);

    const options = await genSearchFilters({
      authToken,
      currentPerspectiveId,
      context: searchContext,
      criterion: criterion.name,
    });

    dispatchSearchAction({
      type: 'SET_FILTER_PILL_OPTIONS',
      payload: {
        searchContext: searchContext,
        criterion: criterion.name,
        options: options,
      },
    });
  };

  const updateFilterPillValue = (index, valuesArray) => {
    dispatchSearchAction({
      type: 'UPDATE_FILTER_PILL_VALUE',
      payload: {
        searchContext: searchContext,
        index: index,
        valuesArray: valuesArray,
      },
    });

    if (valuesArray.length > 0) {
      isAddNewFilterDisabled === true && setIsAddNewFilterDisabled(false);
      saveFilterValuesToURL(index, valuesArray);
    } else {
      isAddNewFilterDisabled === false && setIsAddNewFilterDisabled(true);
    }
  };

  const removeFilterPillCriterion = (index) => {
    dispatchSearchAction({
      type: 'REMOVE_FILTER_PILL_CRITERION',
      payload: {
        searchContext: searchContext,
        index: index,
      },
    });
    isAddNewFilterDisabled === true && setIsAddNewFilterDisabled(false);
    removeFilterPillFromURL(index);
  };

  const updateFilterPillCriterion = async(index, criteriaObj) => {
    dispatchSearchAction({
      type: 'UPDATE_FILTER_PILL_CRITERION',
      payload: {
        searchContext: searchContext,
        index: index,
        filterPillCriterion: criteriaObj,
      },
    });

    const options = await genSearchFilters({
      authToken,
      currentPerspectiveId,
      context: getURLParam(match)('searchContext'),
      criterion: criteriaObj.name,
    });

    dispatchSearchAction({
      type: 'SET_FILTER_PILL_OPTIONS',
      payload: {
        searchContext: searchContext,
        criterion: criteriaObj.name,
        options: options,
      },
    });
    updateFilterPillCriterionURL(index, criteriaObj.name);
  };

  const clearFilters = () => {
    dispatchSearchAction({
      type: 'CLEAR_FILTERS',
      payload: {
        searchContext: searchContext,
      },
    });
    dispatchSearchAction({
      type: 'SET_SEARCH_QUERY',
      payload: {
        searchContext: searchContext,
        searchQuery: '',
      },
    });
    isAddNewFilterDisabled === true && setIsAddNewFilterDisabled(false);
    return history.push({
      search: '',
    });
  };

  /* ----------------------------------------------------------- */

  const removeSearchCriterion = () => {
    pushSearchParams(history, location, match)({
      'searchQuery': undefined,
    });
  };

  const addSearchCriterion = () => {
    if (searchQuery === '') {
      return;
    }
    pushSearchParams(history, location, match)({
      'searchQuery': searchQuery,
    });
  };

  /* ------------------------------------------------------------------ */

  const saveFilterValuesToURL = (index, valuesArray) => {
    if (valuesArray.length > 0) {
      const currentFilter = filterPillCriteria[index].filter;
      const currentFilterName = filterPillCriteria[index].name;

      let searchParamsToUpdate = {
        [currentFilter]: valuesArray.map(value => value.id),
      };
      switch (currentFilter) {
        case 'categoryId': {
          const categoryRootId  = valuesArray.map(value => value.rootId)[0];
          const categoryParentId = valuesArray.map(value => value.parentId)[0];
          searchParamsToUpdate = {
            ...searchParamsToUpdate,
            'categoryRootId': categoryRootId ? categoryRootId : undefined,
            'categoryParentId': categoryParentId ? categoryParentId : undefined,
          };
          break;
        }
        default:
          break;
      }
      const hasValue = getSearchParam(location)(currentFilter);
      if (hasValue) {
        replaceSearchParams(history, location, match)({
          ...searchParamsToUpdate,
        });
      } else {
        const displayIndex = getSearchParam(location)('displayIndex');
        searchParamsToUpdate = {
          ...searchParamsToUpdate,
          'displayIndex': displayIndex ?
            [
              ...displayIndex.slice(0, index),
              currentFilterName,
              ...displayIndex.slice(index + 1),
            ] :
            [currentFilterName],
        };
        pushSearchParams(history, location, match)({
          ...searchParamsToUpdate,
        });
      }
    }
  };

  const removeFilterPillFromURL = (index) => {
    const displayIndex = getSearchParam(location)('displayIndex');
    if (displayIndex) {
      let updateSearchParams = {
        [filterPillCriteria[index].filter]: undefined,
        'displayIndex': [
          ...displayIndex.slice(0, index),
          ...displayIndex.slice(index + 1),
        ],
      };
      if (filterPillCriteria[index].filter === 'categoryId') {
        updateSearchParams = {
          ...updateSearchParams,
          'categoryRootId': undefined,
          'categoryParentId': undefined,
        };
      }
      pushSearchParams(history, location, match)({
        ...updateSearchParams,
      });
    }
  };

  const updateFilterPillCriterionURL = (index, criterionName) => {
    const displayIndex = getSearchParam(location)('displayIndex');
    if (displayIndex) {
      let updateSearchParams = {
        [filterPillCriteria[index].filter]: undefined,
      };
      updateSearchParams = {
        ...updateSearchParams,
        'displayIndex': [
          ...displayIndex.slice(0, index),
          criterionName,
          ...displayIndex.slice(index + 1),
        ],
      };
      if (filterPillCriteria[index].filter === 'categoryId') {
        updateSearchParams = {
          ...updateSearchParams,
          'categoryRootId': undefined,
          'categoryParentId': undefined,
        };
      }
      replaceSearchParams(history, location, match)({...updateSearchParams});
    }
  };

  /* ----------------------------------------------------------- */

  const updateSearchContext = (context) => {
    const {
      [context]: {
        filterPillCriteria,
        filterPillValues,
        searchQuery,
      },
    } = searchState;
    const paramsToUpdate = {
      'searchContext': context,
    };

    const displayIndex = filterPillCriteria.map(filter => filter.name);
    let searchParamsToUpdate = {
      'displayIndex': displayIndex,
      'recordId': undefined,
      'typeId': undefined,
      'categoryId': undefined,
      'categoryParentId': undefined,
      'categoryRootId': undefined,
      'connectionId': undefined,
      'nodeTagId': undefined,
      'searchQuery': searchQuery ? searchQuery : undefined,
    };
    filterPillCriteria.map((criterion, index) => {
      const values = filterPillValues[index];
      switch(criterion.filter) {
        case 'categoryId': {
          const categoryRootId  = values.map(value => value.rootId)[0];
          const categoryParentId = values.map(value => value.parentId)[0];
          return searchParamsToUpdate = {
            ...searchParamsToUpdate,
            [criterion.filter]: values.map(value => value.id),
            'categoryRootId': categoryRootId ? categoryRootId : undefined,
            'categoryParentId': categoryParentId ? categoryParentId : undefined,
          };
        }
        default:
          return searchParamsToUpdate = {
            ...searchParamsToUpdate,
            [criterion.filter]: values.map(value => value.id),
          };
      }
    });
    history.push(
      buildURL({match, location, paramsToUpdate, searchParamsToUpdate}),
    );
  };

  const hasCriteriaSelected = () => {
    if (showSearchPill) return true;
    return filterPillCriteria.length > 0;
  };

  const isSelectAllChecked = () => {
    switch (searchContext) {
      case 'all': {
        return Boolean(allContextSelectedNodeIds.length === nodes.length);
      }
      case 'internal': {
        return Boolean(internalContextSelectedNodeIds.length === nodes.length);
      }
      case 'external': {
        return Boolean(externalContextSelectedNodeIds.length === nodes.length);
      }
      default: {
        return false;
      }
    }
  };

  const isSelectAllIndeterminate = () => {
    switch (searchContext) {
      case 'all': {
        return Boolean(allContextSelectedNodeIds.length && (allContextSelectedNodeIds.length !== nodes.length));
      }
      case 'internal': {
        return Boolean(internalContextSelectedNodeIds.length && (internalContextSelectedNodeIds.length !== nodes.length));
      }
      case 'external': {
        return Boolean(externalContextSelectedNodeIds.length && (externalContextSelectedNodeIds.length !== nodes.length));
      }
      default: {
        return false;
      }
    }
  };

  const genSelectedNodeIds = () => {
    switch (searchContext) {
      case 'all': {
        return allContextSelectedNodeIds;
      }
      case 'internal': {
        return internalContextSelectedNodeIds;
      }
      case 'external': {
        return externalContextSelectedNodeIds;
      }
      default: {
        return false;
      }
    }
  };

  const updateSelectedNodeIds = (nodes) => {
    switch (searchContext) {
      case 'all': {
        return setAllContextSelectedNodeIds(nodes);
      }
      case 'internal': {
        return setInternalContextSelectedNodeIds(nodes);
      }
      case 'external': {
        return setExternalContextSelectedNodeIds(nodes);
      }
      default: {
        return false;
      }
    }
  };

  /* -----------------BATCH ACTIONS---------------- */

  const batchDeleteNodes = () => {
    return new Promise((resolve, reject) => {
      const promises = genSelectedNodeIds().map(selectedNodeId => {
        return new Promise(async(resolve, reject) => {
          try {
            const result = await deleteNode({authToken, currentPerspectiveId, id: selectedNodeId});
            resolve(result);
          } catch(error) {
            const {
              body: {
                code,
              },
            } = error;
            switch(code) {
              case 3001:
                resolve(null);
                break;
              case 1014:
                resolve({selectedNodeId, code});
                break;
              default:
                reject(error);
            }
          }
        });
      });

      Promise.all(promises)
        .then(deleted => {
          const withErrors = deleted.filter(result => result !== null);
          resolve(withErrors);
          updateSelectedNodeIds([]);
          if (withErrors.length === 0) {
            setBatchDeleteSuccess(true);
          }
        })
        .catch(error => {
          setError(error);
        });
    });
  };

  /*--------------------------------------------------*/

  return (
    <PageContainer>
      <PageTitle>
        Search
      </PageTitle>
      <PageDescription>
        Search or filter your data.
      </PageDescription>
      <PageContent>
        <Tabs
          value={searchContext}
          onChange={(event, newContext) => {
            updateSearchContext(newContext);
          }}
          options={[
            {value: DEFAULT_SEARCH_CONTEXT, label: 'All items'},
            {value: INTERNAL_SEARCH_CONTEXT, label: 'My items'},
            {value: EXTERNAL_SEARCH_CONTEXT, label: 'External items'},
          ]}
        />

        <Divider/>

        <FilterRow
          hasActiveCriteria={filterPillCriteria.length > 0 || showSearchPill}
          clearFilters={clearFilters}
          isAddNewFilterDisabled={isAddNewFilterDisabled}
          isAddNewFilterActive={isFilterMenuOpen}
          handleAddNewFilter={(ev) => {
            if (!isAddNewFilterDisabled) {
              setAnchorEl(ev.currentTarget);
              setIsFilterMenuOpen(true);
            }
          }}
        >
          {
            filterPillCriteria.length > 0 && filterPillCriteria.map((filterPillCriterion, index) => {
              const {
                filter,
                isPillLoading,
              } = filterPillCriterion;

              /* each criterion that is already selected is marked as active,
              so it can be disabled to avoid selecting the same criterion twice */
              const criteria = AVAILABLE_SEARCH_FILTERS.map(criterion => {
                if (filterPillCriteria.map(activeFilterType => activeFilterType.filter).includes(criterion.filter)) {
                  return {...criterion, isActive: true};
                }
                return criterion;
              });

              switch (filter) {
                case 'categoryId':
                  return (
                    <FilterPill
                      key={index}
                      index={index}
                      isLoading={isPillLoading}
                      criteria={criteria}
                      filterPillCriterion={filterPillCriterion}
                      filterPillValue={filterPillValues[index]}
                      removeFilterPillCriterion={removeFilterPillCriterion}
                      updateFilterPillValue={updateFilterPillValue}
                      updateFilterPillCriterion={updateFilterPillCriterion}
                      onLoadTree={loadCategoryTree}
                    />
                  );
                default:
                  return (
                    <FilterPill
                      key={index}
                      index={index}
                      isLoading={isPillLoading}
                      criteria={criteria}
                      filterPillCriterion={filterPillCriterion}
                      filterPillValue={filterPillValues[index]}
                      removeFilterPillCriterion={removeFilterPillCriterion}
                      updateFilterPillValue={updateFilterPillValue}
                      updateFilterPillCriterion={updateFilterPillCriterion}
                    />
                  );
              }
            })
          }
          {
            showSearchPill ?
              <SearchPill
                searchQuery={searchQuery}
                removeSearchPill={removeSearchCriterion}
                onClick={() => {
                  const currentSearchQuery = searchQuery;
                  removeSearchCriterion();
                  dispatchSearchAction({
                    type: 'SET_SEARCH_QUERY',
                    payload: {
                      searchContext: searchContext,
                      searchQuery: currentSearchQuery,
                    },
                  });
                  setShowSearchPill(false);
                }}
              /> :
              <NewSearchBar
                searchQuery={searchQuery}
                autoFocus={searchQuery !== ''}
                onChange={(event) => {
                  dispatchSearchAction({
                    type: 'SET_SEARCH_QUERY',
                    payload: {
                      searchContext: searchContext,
                      searchQuery: event.target.value,
                    },
                  });
                }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    addSearchCriterion();
                  }
                }}
              />
          }
        </FilterRow>
        <Menu
          open={isFilterMenuOpen}
          onClose={() => {
            setIsFilterMenuOpen(false);
            setAnchorEl(null);
          }}
          anchorEl={anchorEl}
        >
          {
            AVAILABLE_SEARCH_FILTERS.map((option, index) => {
              const {
                label,
                filter,
              } = option;
              const activeFilters = filterPillCriteria.map(crt => crt.filter);
              return (
                <MenuItem
                  key={index}
                  disabled={activeFilters.includes(filter)}
                  onClick={() => addFilterPillCriterion(option)}>
                  {label}
                </MenuItem>
              );
            })
          }
        </Menu>

        {
          status === 'fetching data' &&
          <ListSkeleton />
        }
        {
          status === 'ready' &&
          nodes.length > 0 &&
          <>
            <SelectAllItems
              items={nodes}
              checked={isSelectAllChecked()}
              indeterminate={isSelectAllIndeterminate()}
              onChange={() => {
                switch (searchContext) {
                  case 'all': {
                    if (allContextSelectedNodeIds.length === nodes.length) {
                      setAllContextSelectedNodeIds([]);
                    } else {
                      setAllContextSelectedNodeIds([
                        ...nodes.map(node => node.id),
                      ]);
                    }
                    break;
                  }
                  case 'internal': {
                    if (internalContextSelectedNodeIds.length === nodes.length) {
                      setInternalContextSelectedNodeIds([]);
                    } else {
                      setInternalContextSelectedNodeIds([
                        ...nodes.map(node => node.id),
                      ]);
                    }
                    break;
                  }
                  case 'external': {
                    if (externalContextSelectedNodeIds.length === nodes.length) {
                      setExternalContextSelectedNodeIds([]);
                    } else {
                      setExternalContextSelectedNodeIds([
                        ...nodes.map(node => node.id),
                      ]);
                    }
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }}
            />
            <div>
              <BatchActionMenu>
                <BatchActionButton
                  data-test-id="batch-delete-button"
                  variant="delete"
                  onClick={() => {
                    if (genSelectedNodeIds().length > 0) {
                      setShowBatchDeleteDialog(true);
                    } else {
                      setShowBatchDeleteWarningDialog(true);
                    }
                  }}
                />
              </BatchActionMenu>
              <ListContainer>
                <NodeList
                  nodes={nodes}
                  selectedNodeIds={genSelectedNodeIds()}
                  onNodeAction={(nodeId, action) => {
                    switch(action) {
                      case 'expand': {
                        dispatchUserInterfaceAction({
                          type: 'SET_EXPANDED_NODE_ID',
                          payload: nodeId,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_NODE_PANEL',
                          payload: true,
                        });
                        break;
                      }
                      case 'share': {
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_SHARE_NODE_WIZARD',
                          payload: true,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_SHARE_NODE_ID',
                          payload: nodeId,
                        });
                        break;
                      }
                      case 'delete': {
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_DELETE_NODE_DIALOG',
                          payload: true,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_DELETE_NODE_ID',
                          payload: nodeId,
                        });
                        break;
                      }
                      case 'select': {
                        switch (searchContext) {
                          case 'all': {
                            setAllContextSelectedNodeIds([
                              ...allContextSelectedNodeIds,
                              nodeId,
                            ]);
                            break;
                          }
                          case 'internal': {
                            setInternalContextSelectedNodeIds([
                              ...internalContextSelectedNodeIds,
                              nodeId,
                            ]);
                            break;
                          }
                          case 'external': {
                            setExternalContextSelectedNodeIds([
                              ...externalContextSelectedNodeIds,
                              nodeId,
                            ]);
                            break;
                          }
                          default: {
                            break;
                          }
                        }
                        break;
                      }
                      case 'deselect': {
                        switch (searchContext) {
                          case 'all': {
                            const index = allContextSelectedNodeIds.indexOf(nodeId);
                            setAllContextSelectedNodeIds([
                              ...allContextSelectedNodeIds.slice(0, index),
                              ...allContextSelectedNodeIds.slice(index + 1),
                            ]);
                            break;
                          }
                          case 'internal': {
                            const index = internalContextSelectedNodeIds.indexOf(nodeId);
                            setInternalContextSelectedNodeIds([
                              ...internalContextSelectedNodeIds.slice(0, index),
                              ...internalContextSelectedNodeIds.slice(index + 1),
                            ]);
                            break;
                          }
                          case 'external': {
                            const index = externalContextSelectedNodeIds.indexOf(nodeId);
                            setExternalContextSelectedNodeIds([
                              ...externalContextSelectedNodeIds.slice(0, index),
                              ...externalContextSelectedNodeIds.slice(index + 1),
                            ]);
                            break;
                          }
                          default: {
                            break;
                          }
                        }
                        break;
                      }
                      default:
                        break;
                    }
                  }}
                />
              </ListContainer>
            </div>
            <BatchDeleteWarningDialog
              isOpen={showBatchDeleteWarningDialog}
              onClose={() => setShowBatchDeleteWarningDialog(false)}
            />
            <BatchDeleteDialog
              isOpen={showBatchDeleteDialog}
              onCancel={() => setShowBatchDeleteDialog(false)}
              onClose={() => {
                setShowBatchDeleteDialog(false);
                // refresh list
                dispatchUserInterfaceAction({
                  type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
                  payload: genSelectedNodeIds(),
                });
              }}
              nodes={nodes.filter((node) => {
                return genSelectedNodeIds().indexOf(node.id) !== -1;
              })}
              deleteNodes={batchDeleteNodes}
            />
            <Message
              variant="success"
              open={batchDeleteSuccess}
              onClose={() => setBatchDeleteSuccess(false)}
              messageTitle="Item(s) Deleted"
              messageBody={
                <Typography variant="body">
                  All selected items have been deleted successfully.
                </Typography>
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              width={532}
            />
          </>
        }
        {
          status === 'ready' &&
          !hasCriteriaSelected() &&
            <NoResultsContainer>
              <Typography variant="h4" color="grey" fontWeight="bold">
                Search for a specific item or apply filters for a custom view of
                your items.
              </Typography>
            </NoResultsContainer>
        }
        {
          status === 'ready' &&
          hasCriteriaSelected() &&
          nodes.length === 0 &&
            <NoResultsContainer>
              <Typography variant="h4" color="grey" fontWeight="bold">
                Your search yielded no results.
                <br />
                Check to make sure your search inputs are correct.
              </Typography>
            </NoResultsContainer>
        }
      </PageContent>
    </PageContainer>
  );
};

export default HomeownerSearch;
