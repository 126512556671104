import React from 'react';
import Textarea from 'ui-library/components/Textarea';

const ConversationTextArea = ({
  value,
  onChange,
  maxLength,
}) => {
  return (
    <Textarea
      rowsMax={3}
      placeholder="Type here to comment..."
      value={value}
      onChange={onChange}
      maxLength={maxLength}
    />
  );
};

export default ConversationTextArea;
