import React from 'react';
import styled from '@emotion/styled';
import {
  greyLighter,
} from 'ui-library/color-palette';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
  position: relative;
  min-height: 100%;
  background-color: ${greyLighter};
`;

const ChromeContainer = ({
  children,
}) => {
  return (
    <Fade
      in
      timeout={500}>
      <Root>
        {children}
      </Root>
    </Fade>
  );
};

export default ChromeContainer;
