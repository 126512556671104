import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationPipelineIcon from '@material-ui/icons/ClearAll';
import FieldWatcherIcon from '@material-ui/icons/TrackChanges';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FieldWatchersList from './FieldWatchersList.react';

const NotificationPipelinesList = ({
  DataModelQueries,
  DataModel,
  notificationPipelines,
  onRemoveNotificationPipeline,
  onEditNotificationPipeline,
  onAddFieldWatcher,
  onRemoveFieldWatcher,
  onEditFieldWatcher,
  onAddFieldDefinitionToFieldWatcher,
  onRemoveFieldDefinitionFromFieldWatcher,
  onEnableNotificationPipeline,
  onDisableNotificationPipeline,
}) => {
  return (
    <>
      {
        notificationPipelines
          .map(notificationPipeline => {
            const {
              id: notificationPipelineId,
              name: notificationPipelineName,
              enabledStatus: notificationPipelineEnabledStatus,
              description: notificationPipelineDescription,
              notificationTextTemplate: notificationPipelineNotificationTextTemplate,
              perspectiveTypes: notificationPipelinePerspectiveTypes,
            } = notificationPipeline;

            return (
              <NestedBox
                key={notificationPipelineId}>
                <ContentLine>
                  <NotificationPipelineIcon/>&nbsp;&nbsp;
                  <ContentLineText>
                    <Typography
                      variant="body1">
                      <strong>
                        {notificationPipelineName}
                      </strong>
                      &nbsp;
                      {
                        notificationPipelinePerspectiveTypes
                          .map((perspectiveType, index) => {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: 13,
                                  display: 'inline-block',
                                  padding: '.1em .5em',
                                  margin: '.25em',
                                  backgroundColor: '#ddd',
                                  borderRadius: '.75em',
                                }}>
                                {perspectiveType}
                              </span>
                            );
                          })
                      }
                    </Typography>
                    <Typography
                      variant="body2">
                      <em>
                        {notificationPipelineNotificationTextTemplate}
                      </em>
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        color: '#aaa',
                      }}>
                      {notificationPipelineDescription}
                    </Typography>
                  </ContentLineText>
                  <Tooltip
                    placement="top"
                    title={`${notificationPipelineEnabledStatus === 'disabled' ? 'enable' : 'disable'} "${notificationPipelineName}"`}>
                    <Switch
                      checked={notificationPipelineEnabledStatus === 'enabled'}
                      onChange={() => {
                        if (notificationPipelineEnabledStatus === 'disabled') {
                          onEnableNotificationPipeline(notificationPipelineId);
                        }
                        if (notificationPipelineEnabledStatus === 'enabled') {
                          onDisableNotificationPipeline(notificationPipelineId);
                        }
                      }}
                      color="primary"
                    />
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`edit "${notificationPipelineName}"`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onEditNotificationPipeline(notificationPipelineId);
                        }}>
                        <EditIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`add a field watcher to "${notificationPipelineName}"`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onAddFieldWatcher(notificationPipelineId);
                        }}>
                        <FieldWatcherIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`delete "${notificationPipelineName}"`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onRemoveNotificationPipeline(notificationPipelineId);
                        }}>
                        <DeleteIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                </ContentLine>

                <FieldWatchersList
                  DataModelQueries={DataModelQueries}
                  DataModel={DataModel}
                  fieldWatchers={
                    DataModelQueries.selectFieldWatchersByNotificationPipelineId(
                      DataModel,
                      notificationPipelineId,
                    )
                  }
                  onRemoveFieldWatcher={onRemoveFieldWatcher}
                  onEditFieldWatcher={onEditFieldWatcher}
                  onAddFieldDefinitionToFieldWatcher={onAddFieldDefinitionToFieldWatcher}
                  onRemoveFieldDefinitionFromFieldWatcher={onRemoveFieldDefinitionFromFieldWatcher}
                />
              </NestedBox>
            );
          })
      }
    </>
  );
};

export default NotificationPipelinesList;
