class AuthorizationError extends Error {
  constructor(responseStatus, errorBody) {
    super('Could not authorize the request!');

    this.name = 'AuthorizationError';
    this.body = errorBody;
    this.responseStatus = responseStatus;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthorizationError);
    }
  }
}

export default AuthorizationError;
