import buildURL from './buildURL';

export default (history, location, match) => {
  if (!history || !location || !match) {
    throw new Error('derp!');
  }

  return newSearchParams => {
    history.replace(buildURL({
      match,
      location,
      paramsToUpdate: {},
      searchParamsToUpdate: {
        ...newSearchParams,
      },
    }));
  };
};
