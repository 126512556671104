import React from 'react';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import TextField from 'ui-library/components/TextField';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import VisibilityIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffRounded';

import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const HelperTextContainer = styled.div`
  padding: 8px 0 0 10px;
`;

const RightPanelRowWithValidationGutter = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
  height: 80px;
`;

const ConfirmPasswordComponent = ({
  accountType,
  step,
  password,
  confirmPassword,
  setConfirmPassword,
  signUp,
  isLoading,
  isTransitioning,
}) => {

  const [showPassword, setShowPassword] = React.useState(false);
  const [passwordsMatch, setPasswordsMatch] = React.useState(false);
  const [isPristine, setIsPristine] = React.useState(true);

  return (
    <>
      <RightPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          Confirm your <strong>password...</strong>
        </Typography>
      </RightPanelRow>
      <RightPanelRowWithValidationGutter>
        <TextField
          fullWidth
          disabled={isLoading}
          type={showPassword === true ? 'text' : 'password'}
          autoFocus={true}
          placeholder="Type your answer here..."
          clickableAdornment={true}
          value={confirmPassword}
          onFocus={() => {
            setPasswordsMatch(password === confirmPassword);
          }}
          onChange={(event) => {
            setConfirmPassword(event.target.value);
            setPasswordsMatch(event.target.value === password);
            setIsPristine(false);
          }}
          onKeyPress={(event) => {
            if (event.which === 13 && password === confirmPassword && !isTransitioning) {
              signUp();
            }
          }}
          onBlur={() => {
            setPasswordsMatch(password === confirmPassword);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {
                  showPassword === true ?
                    <VisibilityIcon />
                    :
                    <VisibilityOffIcon />
                }
              </InputAdornment>
            ),
          }}
          error={!isPristine && !passwordsMatch}
          helperText={!isPristine && !passwordsMatch && 'passwords do not match'}
        />
      </RightPanelRowWithValidationGutter>
      <br/>
      <RightPanelRow>
        <Button
          data-test-id="sign-up-btn"
          variant="secondary"
          endIcon={
            !isLoading ?
              <ArrowForwardIcon />
              :
              <CircularProgress
                size={20}
                style={{
                  color: '#fff',
                }}
              />
          }
          disabled={confirmPassword === '' || !passwordsMatch || isLoading || isTransitioning}
          onClick={signUp}
        >
          sign up
        </Button>
        <HelperTextContainer>
          <Typography
            variant="tiny"
          >
            press <strong>ENTER</strong>
          </Typography>
        </HelperTextContainer>
      </RightPanelRow>
    </>
  );
};

export default ConfirmPasswordComponent;
