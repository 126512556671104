import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from '@material-ui/core/styles';
import {Link as RouterLink, Switch, Route} from 'react-router-dom';
import FullScreenSpinner from 'components/chrome/FullScreenSpinner';

import TypesAdmin from 'components/UserInterface/AdminApplication/TypesAdmin';
import RecordTypesAdmin from 'components/UserInterface/AdminApplication/RecordTypesAdmin';
import TypeCategoriesAdmin from 'components/UserInterface/AdminApplication/TypeCategoriesAdmin';
import TypeLocationGraphAdmin from 'components/UserInterface/AdminApplication/TypeLocationGraphAdmin';
import NodeLinkDefinitionsAdmin from 'components/UserInterface/AdminApplication/NodeLinkDefinitionsAdmin';
import TypeRelationshipsAdmin from 'components/UserInterface/AdminApplication/TypeRelationshipsAdmin';
import IntelligentDataFrameworkAdmin from 'components/UserInterface/AdminApplication/IntelligentDataFrameworkAdmin';
import NotificationPipelinesAdmin from 'components/UserInterface/AdminApplication/NotificationPipelinesAdmin';
import UsersAdmin from 'components/UserInterface/AdminApplication/UsersAdmin';
import EhrHub from 'components/UserInterface/AdminApplication/EhrHub';

import Prompt from 'components/admin/Prompt';

import PerspectiveContext from 'contexts/Perspective';
import UserContext from 'contexts/User';
import AdminContext from 'contexts/Admin';

import initDataModel from 'DataModel/state/_initDataModel';
import DataModelReducer from 'DataModel/state/_DataModelReducer';
import DataModelQueries from 'DataModel/state/queries';
import DataModelAPI from 'DataModel/API';

import genDataModel from 'DataModel/API/genDataModel';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: '#fff',
  },
  perspectiveSwitcher: {
    flex: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

function ListItemLink(props) {
  const {icon, primary, to, disabled} = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button disabled={disabled} component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

function ListSectionName({children}) {
  return (
    <ListItem style={{color: '#ccc'}}>
      <Typography variant="h5">{children}</Typography>
    </ListItem>
  );
}

const AdminApplication = () => {
  const {
    availablePerspectives,
    currentPerspective,
    setCurrentPerspective,
  } = React.useContext(PerspectiveContext);
  const {
    authToken,
    setAuthenticationStatus,
  } = React.useContext(UserContext);

  const [DataModel, dispatchDataModelAction] = React.useReducer(DataModelReducer, null, initDataModel);
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [APIException, setAPIException] = React.useState(null);

  React.useEffect(() => {
    async function init() {
      const DataModel = await genDataModel(authToken, currentPerspective.id);
      // console.log(DataModel);
      dispatchDataModelAction({
        type: 'HYDRATE_DATA_MODEL',
        payload: DataModel,
      });
      setIsInitialized(true);
    }

    init();
  }, [authToken, currentPerspective.id]);

  const classes = useStyles();

  if (!isInitialized) {
    return (
      <FullScreenSpinner/>
    );
  }

  // console.log(DataModel);

  return (
    <div className={classes.root}>

      <AppBar
        position="fixed"
        className={classes.appBar}>
        <Toolbar>
          <div className={classes.perspectiveSwitcher}>
            <Select
              value={currentPerspective}
              onChange={event => {
                setCurrentPerspective(event.target.value);
              }}>
              {
                availablePerspectives.map(perspective => {
                  return (
                    <MenuItem
                      key={perspective.id}
                      value={perspective}>
                      {perspective.name}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </div>
          <IconButton
            onClick={() => {
              setAuthenticationStatus('not-authenticated');
            }}>
            <LogoutIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left">
        <List>
          <ListSectionName>Data taxonomy</ListSectionName>
          <ListItemLink
            to="/types"
            primary="Types"
          />
          <ListItemLink
            to="/record-types"
            primary="Record Types"
          />
          <ListItemLink
            to="/type-categories"
            primary="Type Categories"
          />
        </List>
        <Divider/>
        <List>
          <ListSectionName>Locations</ListSectionName>
          <ListItemLink
            to="/type-location-graph"
            primary="Type Location Graph"
          />
        </List>
        <Divider/>
        <List>
          <ListSectionName>Linking</ListSectionName>
          <ListItemLink
            to="/node-link-definitions"
            primary="Link Definitions"
          />
          <ListItemLink
            to="/type-relationships"
            primary="Type Relationships"
          />
        </List>
        <Divider/>
        <List>
          <ListSectionName>Intelligent data framework</ListSectionName>
          <ListItemLink
            to="/intelligent-data-framework"
            primary="Property configurators"
          />
          <ListItemLink
            to="/ehr-hub"
            primary="eHR hub"
          />
        </List>
        <List>
          <ListSectionName>Notifications</ListSectionName>
          <ListItemLink
            to="/notification-pipelines"
            primary="Notification pipelines"
          />
        </List>
        <Divider/>
        <List>
          <ListSectionName>User Management</ListSectionName>
          <ListItemLink
            to="/users"
            primary="Users"
          />
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar}/>
        <AdminContext.Provider
          value={{
            authToken,
            perspectiveId: currentPerspective.id,
            DataModel,
            DataModelQueries,
            dispatchDataModelAction,
            DataModelAPI,
            setAPIException,
          }}>
          <Switch>
            <Route
              path="/types"
              component={TypesAdmin}
            />
            <Route
              path="/record-types"
              component={RecordTypesAdmin}
            />
            <Route
              path="/type-categories"
              component={TypeCategoriesAdmin}
            />
            <Route
              path="/type-location-graph"
              component={TypeLocationGraphAdmin}
            />
            <Route
              path="/node-link-definitions"
              component={NodeLinkDefinitionsAdmin}
            />
            <Route
              path="/type-relationships"
              component={TypeRelationshipsAdmin}
            />
            <Route
              path="/intelligent-data-framework"
              component={IntelligentDataFrameworkAdmin}
            />
            <Route
              path="/ehr-hub"
              component={EhrHub}
            />
            <Route
              path="/notification-pipelines"
              component={NotificationPipelinesAdmin}
            />
            <Route
              path="/users"
              component={UsersAdmin}
            />
          </Switch>
        </AdminContext.Provider>
      </main>

      {
        APIException &&
          <Prompt
            open
            title={`API exception ${APIException.body.code}!`}
            text={APIException.body.message}
            confirmLabel="ok"
            onConfirm={() => {
              setAPIException(null);
            }}
          />
      }
    </div>
  );
};

export default AdminApplication;
