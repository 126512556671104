import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {POST} from '../verbs';

export default async function POST__notifications_devices(authToken, playerId) {

  return await POST(
    '/notifications/devices',
    configWithAuthorization(defaultConfig(), authToken),
    {
      playerId,
    },
  );
}
