import React from 'react';
import PropTypes from 'prop-types';
import {BrowserRouter as Router} from 'react-router-dom';
import UserContext from 'contexts/User';
import GlobalConfigContext from 'contexts/GlobalConfig';
import PushNotificationsContext from 'contexts/PushNotifications';
import FullScreenSpinner from 'components/chrome/FullScreenSpinner';
import genGlobalConfig from 'services/GlobalConfig/genGlobalConfig';

import AuthenticatedApplication from 'components/AuthenticatedApplication';
import UnauthenticatedApplication from 'components/UnauthenticatedApplication';

const Application = ({
  localAuthToken,
}) => {
  const [globalConfig, setGlobalConfig] = React.useState(null);
  const [isGlobalConfigInitialized, setIsGlobalConfigInitialized] = React.useState(false);
  const [isAuthenticationInitialized, setIsAuthenticationInitialized] = React.useState(false);
  const [authenticationStatus, setAuthenticationStatus] = React.useState(null);
  const [authToken, setAuthToken] = React.useState(null);
  const [error, setError] = React.useState(null);
  // const [error, setError] = React.useState(new Error('test error'));

  const [oneSignalInitialization, setOneSignalInitialization] = React.useState('not-initialized'); // 'not-initialized', 'in progress', 'initialized'
  const [oneSignalPlayerId, setOneSignalPlayerId] = React.useState(null);
  const [notificationData, setNotificationData] = React.useState(null);
  const [browserNotifications, setBrowserNotifications] = React.useState(null);
  const [deviceListUpdated, setDeviceListUpdated] = React.useState(false);

  if (error) {
    throw error;
  }

  React.useEffect(() => {
    const initializeGlobalConfig = async() => {
      if (!globalConfig) {
        try {
          const response = await genGlobalConfig();
          setGlobalConfig(response);
          setIsGlobalConfigInitialized(true);
        } catch(error) {
          setError(error);
        }
      }
    };

    initializeGlobalConfig();
  }, [globalConfig]);

  React.useEffect(() => {
    const initializeAuthentication = (authToken) => {
      if (authToken) {
        try {
          setAuthToken(authToken);
          setAuthenticationStatus('authenticated');
          setIsAuthenticationInitialized(true);
        } catch (error) {
          setAuthenticationStatus('not-authenticated');
        }
      } else {
        setAuthenticationStatus('not-authenticated');
      }
      setIsAuthenticationInitialized(true);
    };

    initializeAuthentication(localAuthToken);
  }, [localAuthToken]);

  if (!isAuthenticationInitialized || !isGlobalConfigInitialized) {
    return (
      <FullScreenSpinner/>
    );
  }

  return (
    <Router>
      <GlobalConfigContext.Provider
        value={{
          ...globalConfig,
        }}
      >
        <PushNotificationsContext.Provider
          value={{
            oneSignalInitialization,
            setOneSignalInitialization,
            oneSignalPlayerId,
            setOneSignalPlayerId,
            notificationData,
            setNotificationData,
            browserNotifications,
            setBrowserNotifications,
            deviceListUpdated,
            setDeviceListUpdated,
          }}
        >
          <UserContext.Provider
            value={{
              authenticationStatus,
              setAuthenticationStatus,
              authToken,
              setAuthToken,
            }}>
            <AuthenticationSwitch/>
          </UserContext.Provider>
        </PushNotificationsContext.Provider>
      </GlobalConfigContext.Provider>
    </Router>
  );
};

Application.propTypes = {
  localAuthToken: PropTypes.string,
};

const AuthenticationSwitch = () => {
  const user = React.useContext(UserContext);

  switch(user.authenticationStatus) {
    case 'not-authenticated':
      return <UnauthenticatedApplication/>;
    case 'authenticated':
      return <AuthenticatedApplication/>;
    default:
      throw new Error(`Fatal error! Unknown authentication status "${user.authenticationStatus}".`);
  }
};

export default Application;
