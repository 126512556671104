import GET__perspectives from 'network-layer/endpoints/GET__perspectives';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return [
    ...response,
  ];
  // .sort((p1, p2) => {
  //   const perspectiveTypeOrder = ['admin', 'household', 'company'];
  //   if (perspectiveTypeOrder.indexOf(p1.type) > perspectiveTypeOrder.indexOf(p2.type)) {
  //     return 1;
  //   }
  //   if (perspectiveTypeOrder.indexOf(p1.type) < perspectiveTypeOrder.indexOf(p2.type)) {
  //     return -1;
  //   }
  //   return 0;
  // });
}

export default async function genPerspectives(authToken) {

  const response = await GET__perspectives(authToken);

  return responseAdapter(response);
}
