export default function organizationName(
  value,
  errorMessage = 'invalid field value',
) {
  if (value.length === 0) {
    return errorMessage;
  }

  if (value.length > 80) {
    return errorMessage;
  }

  return '';
}
