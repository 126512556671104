import GET__nodes_ID_permissionGrants from 'network-layer/endpoints/GET__nodes_ID_permissionGrants';

function responseAdapter(response) {
  return response;
}

export default async function genNodesPermissionGrants(authToken, perspectiveId, nodeId) {
  const response = await GET__nodes_ID_permissionGrants(authToken, perspectiveId, nodeId);

  return responseAdapter(response);
}
