import genRecordByRecordId from 'services/Records/genRecordByRecordId';

function genConnectionBreadcrumbsFromPathname(pathname, authToken, perspectiveId) {
  const path = pathname.split('/').filter(segment => segment);
  return new Promise((resolve, reject) => {
    const breadcrumbs = [
      {
        path: '/library',
        label: 'Records',
      },
    ];

    const dataSegments = [
      extractRecordId(path),
    ];

    const promises = [];

    for (let i = 0; i < dataSegments.length; i++) {
      if (dataSegments[i] === null) {
        break;
      }
      switch(i) {
        case 0: {
          promises.push(new Promise(async(resolve, reject) => {
            const record = await genRecordByRecordId(authToken, perspectiveId, dataSegments[i]);
            resolve(record);
          }));
          break;
        }
        default:
          break;
      }
    }

    Promise.all(promises).then(([record]) => {
      if (!record) {
        return resolve(breadcrumbs);
      }

      breadcrumbs.push({
        id: record.id,
        label: record.name,
        path: `/library/records/${record.id}`,
      });

      resolve(breadcrumbs);
    });
  });
}

function extractRecordId(path) {
  if (path[2]) {
    return +path[2];
  }
  return null;
}

export default genConnectionBreadcrumbsFromPathname;
