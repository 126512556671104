import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const TypeRecordTypeAssociations = ({
  open,
  controlsDisabled,
  onClose,
  typeId,
  onAssociateTypeWithRecordType,
  onDisassociateTypeFromRecordType,
  DataModelQueries,
  DataModel,
}) => {

  if (typeId === '') {
    return null;
  }

  const type = DataModelQueries.selectTypeById(DataModel, typeId);
  const {
    name: typeName,
  } = type;

  const allRecordTypes = DataModelQueries.selectRecordTypes(DataModel);
  const recordTypeIdsOfType = DataModelQueries.selectRecordTypeIdsOfTypeById(DataModel, typeId);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}>
      <DialogTitle>
        {typeName} record assignments
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please choose the Record Types that <strong>{typeName}</strong> should be associated with:
        </DialogContentText>
        <FormGroup>
          {
            allRecordTypes
              .sort((rt1, rt2) => {
                const recordTypeIdSortOrder = [4, 1, 2, 3, 5, 6];
                if (recordTypeIdSortOrder.indexOf(rt1.id) > recordTypeIdSortOrder.indexOf(rt2.id)) {
                  return 1;
                }
                if (recordTypeIdSortOrder.indexOf(rt1.id) < recordTypeIdSortOrder.indexOf(rt2.id)) {
                  return -1;
                }
                return 0;
              })
              .map(recordType => {
                const {
                  id: recordTypeId,
                  name: recordTypeName,
                } = recordType;

                const typeIsAssociatedWithRecordType = recordTypeIdsOfType.includes(recordTypeId);

                return (
                  <FormControlLabel
                    disabled={
                      controlsDisabled ||
                      recordTypeId === 4
                    }
                    key={recordTypeId}
                    label={recordTypeName}
                    control={
                      <Checkbox
                        color="primary"
                        checked={typeIsAssociatedWithRecordType}
                        onChange={() => {
                          if (typeIsAssociatedWithRecordType) {
                            onDisassociateTypeFromRecordType(typeId, recordTypeId);
                          } else {
                            onAssociateTypeWithRecordType(typeId, recordTypeId);
                          }
                        }}
                      />
                    }
                  />
                );
              })
          }
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}>
          done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TypeRecordTypeAssociations;
