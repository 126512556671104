import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../verbs';

export default async function PATCH__conversations_CONVERSATIONID_comments_COMMENTID(params) {
  const {
    authToken,
    perspectiveId,
    conversationId,
    commentId,
    message,
  } = params;

  return await PATCH(
    `/conversations/${conversationId}/comments/${commentId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      message,
    },
  );
}
