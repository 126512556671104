// returns a config that provides an "Authorization" header and an "Perspective" header

export default function configWithAuthorizationAndPerspective(config, authToken, perspectiveId) {
  return {
    ...config,
    headers: {
      ...config.headers,
      'Authorization': authToken,
      'Perspective': perspectiveId,
    },
  };
}
