export default (
  params,
  errorMessage = 'invalid field value',
) => {
  const {
    value,
  } = params;

  if ('' === value) {
    return '';
  }

  const allowedCharacters = new RegExp(/^[A-Za-z-.'`’‘ ñÑáéíóúäëïöüÄÖÜÉ]+$/);
  if (!allowedCharacters.test(value)) {
    return errorMessage;
  }

  if (value.trim().length === 0) {
    return errorMessage;
  }

  if (value.length > 120) {
    return errorMessage;
  }

  return '';
};
