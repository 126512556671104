import React from 'react';
import styled from '@emotion/styled';
import CheckmarkIcon from '@material-ui/icons/CheckCircleRounded';
import {
  secondary,
} from 'ui-library/color-palette';

const Root = styled.div`
  color: ${secondary};
`;

const CenterpieceCheckmark = () => {
  return (
    <Root>
      <CheckmarkIcon
        style={{
          fontSize: 60,
        }}
      />
    </Root>
  );
};

export default CenterpieceCheckmark;
