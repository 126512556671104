import selectTypes from './selectTypes';

function selectPropertyTypes(DM) {
  const {
    recordTypes_Types,
  } = DM;

  const propertyTypeIds = recordTypes_Types[4];

  return (
    selectTypes(DM)
      .filter(type => propertyTypeIds.includes(type.id))
  );
}

export default selectPropertyTypes;
