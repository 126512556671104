import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_intelligentDataFramework_virtualLocationTreeNodeHints_VIRTUALLOCATIONTREENODEHINTID(authToken, perspectiveId, params) {
  const {
    virtualLocationTreeNodeHintId,
  } = params;

  return await DELETE(
    `/admin/intelligent-data-framework/virtual-location-tree-node-hints/${virtualLocationTreeNodeHintId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
