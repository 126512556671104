import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root:{
    boxShadow: theme.contextMenu.root.boxShadow,
    '& .MuiList-root': {
      padding: theme.contextMenu.root.padding,
    },
  },
  popper: {
    zIndex: theme.contextMenu.popper.zIndex,
  },
});

const Menu = ({
  open,
  onClose,
  anchorEl,
  children,
  disablePortal,
  ...otherProps
}) => {

  const classes = useStyles();

  return (
    <Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal={disablePortal} className={classes.popper}>
      {({TransitionProps, placement}) => (
        <Grow
          {...TransitionProps}
          style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
        >
          <Paper
            className={classes.root}
          >
            <ClickAwayListener
              mouseEvent='onClick'
              onClickAway={() => {
                onClose();
              }}
            >
              <MenuList
                autoFocusItem={open}
                id="menu-list-grow"
                {...otherProps}
              >
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

Menu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
  children: PropTypes.node,
  disablePortal: PropTypes.bool,
};

export default Menu;
