import React from 'react';
import RemoveIcon from '@material-ui/icons/Remove';

const PillMinus = ({
  onClick,
  ...otherProps
}) => {
  return (
    <span style={{'lineHeight': 0}} {...otherProps}>
      <RemoveIcon
        style={{cursor: 'pointer'}}
        onClick={() => {
          if (typeof onClick !== 'function') {
            return;
          }
          onClick();
        }}
      />
    </span>
  );
};

export default PillMinus;
