import selectVirtualLocationTreeNodes from './selectVirtualLocationTreeNodes';

function selectVirtualLocationTreeNodeParent(
  configurationState,
  virtualLocationTreeNode,
) {
  const virtualLocationTreeNodeHints = selectVirtualLocationTreeNodes(configurationState);
  const {
    virtualLocationId,
  } = virtualLocationTreeNode;

  return (
    virtualLocationTreeNodeHints.find(virtualLocationTreeNode => {
      const {
        virtualId,
      } = virtualLocationTreeNode;

      return virtualLocationId === virtualId;
    })
  );
}

export default selectVirtualLocationTreeNodeParent;
