import React from 'react';
import {withRouter} from 'react-router';

import PushNotificationsContext from 'contexts/PushNotifications';
import UserContext from 'contexts/User';

import FullScreenSpinner from 'components/chrome/FullScreenSpinner';

const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
const ONESIGNAL_SAFARI_WEB_ID = process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID;

const Notifications = ({
  match,
  location,
  history,
}) => {
  const {
    authToken,
  } = React.useContext(UserContext);

  const {
    oneSignalInitialization,
    setOneSignalInitialization,
    notificationData,
    setNotificationData,
    setBrowserNotifications,
    setOneSignalPlayerId,
  } = React.useContext(PushNotificationsContext);

  if (oneSignalInitialization === 'not-initialized') {
    setOneSignalInitialization('in progress');
    window.OneSignal.push(() => {

      // initialize the OneSignal plugin
      if (!authToken) {
        window.OneSignal.init({
          appId: ONESIGNAL_APP_ID,
          safari_web_id: ONESIGNAL_SAFARI_WEB_ID,
        });
      }

      // set the player id in the state;
      window.OneSignal.getUserId((id) => {
        setOneSignalPlayerId(id);
      });

      // check if push notifications are enabled and set the browserNotifications flag
      window.OneSignal.isPushNotificationsEnabled((isEnabled) => {
        if (isEnabled) {
          localStorage.setItem('browserNotifications', 'granted');
          setBrowserNotifications('granted');
        }
      });

      // handler for opening a notification
      window.OneSignal.addListenerForNotificationOpened((pushNotification) => {
        const {
          data,
        } = pushNotification;

        if (!notificationData) {
          setNotificationData(data);
        }

        history.push('/search/all');
      });
    });
    setOneSignalInitialization('initialized');
  }


  return (
    <div>
      <FullScreenSpinner/>
    </div>
  );
};

export default withRouter(Notifications);
