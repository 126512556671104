import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    minWidth: theme.addNewListItemButton.root.minWidth,
    display: theme.addNewListItemButton.root.display,
    alignItems: theme.addNewListItemButton.root.alignItems,
    justifyContent: theme.addNewListItemButton.root.justifyContent,
    height: theme.addNewListItemButton.root.height,
    margin: theme.addNewListItemButton.root.margin,
    padding: theme.addNewListItemButton.root.padding,
    border: theme.addNewListItemButton.root.border,
    cursor: theme.addNewListItemButton.root.cursor,
    transition: theme.addNewListItemButton.root.transition,
    borderRadius: theme.addNewListItemButton.root.borderRadius,
    '&:hover': {
      border: theme.addNewListItemButton.root.hoverBorder,
    },
  },
});

const AddNewListItemButton = ({
  label,
  onClick,
  children,
  ...otherProps
}) => {

  const classes = useStyles();

  return (
    <div
      className={classes.root}
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick();
        }
      }}
      {...otherProps}
    >
      <Typography
        variant="body"
        color="primary">
        &#43;&nbsp;&nbsp;{label}
      </Typography>
      {children}
    </div>
  );
};

AddNewListItemButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default AddNewListItemButton;
