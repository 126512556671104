import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {POST} from '../../verbs';

export default async function POST__admin_types_TYPEID_fieldDefinitions(authToken, perspectiveId, params) {
  const {
    typeId,
    ...postParams
  } = params;

  return await POST(
    `/admin/types/${typeId}/field-definitions`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...postParams,
    },
  );
}
