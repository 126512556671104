import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../verbs';

export default async function PATCH__conversations_CONVERSATIONID(params) {
  const {
    authToken,
    perspectiveId,
    conversationId,
    status,
  } = params;

  return await PATCH(
    `/conversations/${conversationId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      status,
    },
  );
}
