import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import CheckIcon from '@material-ui/icons/CheckRounded';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';

const useStyles = makeStyles({
  root: {
    display: theme.filterCriterion.root.display,
    margin: theme.filterCriterion.root.margin,
    cursor: theme.filterCriterion.root.cursor,
  },
  option: {
    paddingTop: theme.filterCriterion.option.paddingTop,
    paddingLeft: theme.filterCriterion.option.paddingLeft,
    color: theme.filterCriterion.option.color,
  },
  valueContainer: {
    display: theme.filterCriterion.valueContainer.display,
  },
  value: {
    whiteSpace: theme.filterCriterion.value.whiteSpace,
  },
});

const FilterCriterion = ({
  children,
  criteria,
  selectCriterion,
  isCriterionOpen,
  setIsCriterionOpen,
  criterionLabel,
}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.root}
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget);
          setIsCriterionOpen(true);
        }}
      >
        <div className={classes.valueContainer}>
          <div className={classes.value}>
            {children}
          </div>
        </div>
      </div>

      <Menu
        open={isCriterionOpen}
        onClose={() => {
          setAnchorEl(null);
          setIsCriterionOpen(false);
        }}
        anchorEl={anchorEl}
      >
        {
          criteria.map((criteriaObj, index) => {
            const {
              label,
              isActive,
            } = criteriaObj;

            return (
              <MenuItem
                key={index}
                disabled={isActive && criterionLabel !== label}
                onClick={() => {
                  selectCriterion(criteriaObj);
                }}
              >
                {
                  criterionLabel === label ?
                    <>
                      <strong>{label}</strong>
                      <div
                        className={classes.option}
                      >
                        <CheckIcon/>
                      </div>
                    </>
                    :
                    <>
                      {label}
                    </>
                }
              </MenuItem>
            );
          })
        }
      </Menu>
    </>
  );
};

FilterCriterion.propTypes = {
  children: PropTypes.node,
  criteria: PropTypes.array,
  selectCriterion: PropTypes.func,
  isCriterionOpen: PropTypes.bool,
  setIsCriterionOpen: PropTypes.func,
  criterionLabel: PropTypes.string,
};

export default FilterCriterion;
