import selectVirtualLocationTreeNodes from './selectVirtualLocationTreeNodes';

function selectVirtualLocationTreeNodesByHintIdAndLocation(
  configurationState,
  virtualLocationTreeNodeHintId,
  locationId,
) {
  return (
    selectVirtualLocationTreeNodes(configurationState)
      .filter(virtualLocationTreeNode => {
        const {
          hintId,
          virtualLocationId,
        } = virtualLocationTreeNode;

        return (
          hintId === virtualLocationTreeNodeHintId &&
          virtualLocationId === locationId
        );
      })
  );
}

export default selectVirtualLocationTreeNodesByHintIdAndLocation;
