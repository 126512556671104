import React from 'react';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';
import ItemOwnershipTypeStep from './steps/ItemOwnershipTypeStep';
import ItemConnectionStep from './steps/ItemConnectionStep';
import ItemTypeStep from './steps/ItemTypeStep';
import ItemNicknameStep from './steps/ItemNicknameStep';
import ItemRecordTypeStep from './steps/ItemRecordTypeStep';
import ItemLocationStep from './steps/ItemLocationStep';
import ItemDetailsStep from './steps/ItemDetailsStep';
import ItemSaveStep from './steps/ItemSaveStep';

import saveNodeService from 'services/Nodes/saveNode';
import genTypeByTypeId from 'services/Types/genTypeByTypeId';
import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';

import {
  OWNERSHIP_STEP,
  TYPE_STEP,
  NICKNAME_STEP,
  RECORD_STEP,
  LOCATION_STEP,
  DETAILS_STEP,
  SAVE_STEP,
  CONNECTION_STEP,
} from 'constants/addItem.js';

import {
  initNodeState,
  nodeReducer,
} from 'reducers/NodePanel';

const steps = {
  'internal': [
    OWNERSHIP_STEP,
    TYPE_STEP,
    RECORD_STEP,
    NICKNAME_STEP,
    LOCATION_STEP,
    DETAILS_STEP,
    SAVE_STEP,
  ],
  'external': [
    OWNERSHIP_STEP,
    CONNECTION_STEP,
    TYPE_STEP,
    RECORD_STEP,
    NICKNAME_STEP,
    LOCATION_STEP,
    DETAILS_STEP,
    SAVE_STEP,
  ],
};

const AddItemWizard = ({
  onClose,
  onSwitchWizard,
}) => {

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
      type: currentPerspectiveType,
    },
  } = perspective;


  const [step, setStep] = React.useState(1);
  const [ownershipType, setOwnershipType] = React.useState('internal');
  const [connectionId, setConnectionId] = React.useState(null);
  const TOTAL_STEPS = steps[ownershipType].length;

  const [nodeState, dispatchNodeAction] = React.useReducer(
    nodeReducer,
    null,
    initNodeState,
  );
  // 'notSaved' | 'saving' | 'saved'
  const [nodeSaveState, setNodeSaveState] = React.useState('notSaved');

  const [fieldDefinitionsAreInitialized, setFieldDefinitionsAreInitialized] = React.useState(false);

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw fatalError;
  }

  const {
    node: {
      name,
      typeId,
      typeName,
      recordId,
      recordName,
    },
    fieldDefinitionsById,
    fieldsByFieldDefinitionId,
    fieldDefinitionIds,
    nodeLocation,
  } = nodeState;

  React.useEffect(() => {
    const loadFieldDefinitions = async() => {
      try {
        setFieldDefinitionsAreInitialized(false);
        const typeDefinition = await genTypeByTypeId({
          authToken,
          perspectiveId: currentPerspectiveId,
          typeId: typeId,
        });
        const {
          fieldDefinitions: fieldDefinitionsById,
        } = typeDefinition || {};

        const fieldDefinitionIds = Object.keys(fieldDefinitionsById || {}).map(key => {
          return {
            id: key,
            index: fieldDefinitionsById[key].index,
          };
        }).sort((a, b) => {
          const {
            index: indexA,
          } = a;
          const {
            index: indexB,
          } = b;

          return indexA - indexB;
        }).map(obj => obj.id);

        const fieldsByFieldDefinitionId = {};
        fieldDefinitionIds.forEach(id => {
          fieldsByFieldDefinitionId[id] = {
            value: fieldDefinitionsById[id].type === 'date' ? null : '',
            errorMessage: '',
            type: fieldDefinitionsById[id].type,
          };
        });

        dispatchNodeAction({
          type: 'HYDRATE_FIELDS',
          payload: {
            fieldDefinitionsById,
            fieldsByFieldDefinitionId,
            fieldDefinitionIds,
          },
        });

        setFieldDefinitionsAreInitialized(true);
      } catch (error) {
        setError(error);
      }
    };
    if (typeId) {
      loadFieldDefinitions();
    }
  }, [authToken, currentPerspectiveId, typeId]);

  const onSetItemRecordType = React.useCallback((itemRecordTypeId, itemRecordTypeName) => {
    dispatchNodeAction({
      type: 'SET_RECORD',
      payload: {
        recordId: itemRecordTypeId,
        recordName: itemRecordTypeName,
      },
    });
  }, [dispatchNodeAction]);

  async function saveItem(fields) {
    try {
      setNodeSaveState('saving');

      let params = {
        name,
        typeId,
        recordId,
      };

      if (fields) {
        params = {
          ...params,
          fields,
        };
      }

      if (nodeLocation.id) {
        params = {
          ...params,
          locationId: nodeLocation.id,
        };
      }

      const successfullySavedNode = await saveNodeService(
        authToken,
        currentPerspectiveId,
        params,
      );

      const {
        id: nodeId,
      } = successfullySavedNode;

      const successfullySavedNodeWithLocationPath = await genNodeByNodeId({authToken, perspectiveId: currentPerspectiveId, nodeId, queryParams: ['locationPath']});

      dispatchNodeAction({
        type: 'SET_SUCCESSFULLY_SAVED_NODE',
        payload: successfullySavedNodeWithLocationPath,
      });

      setNodeSaveState('saved');
    } catch (error) {
      setError(error);
    }
  }

  function hasState() {
    return step > 1;
  }

  function closeConfirmed() {
    if (!hasState()) {
      return true;
    }
    if (hasState() && window.confirm('Are you sure you want to quit the wizard? Your item will not be saved if you quit now.')) {
      return true;
    }
    return false;
  }

  function closeWizard() {
    if (!closeConfirmed()) {
      return;
    }
    onClose();
  }

  function closeWizardWithPrepend() {
    onClose();
  }

  const navigateToStep = (stepName) => {
    const index = steps[ownershipType].indexOf(stepName) + 1;
    return setStep(index);
  };

  return (
    <Wizard onClose={closeWizard}>
      <WizardProgress
        isVisible={true}
        progress={step}
        total={TOTAL_STEPS}
      />
      <WizardNavigation>
        <WizardBackButton
          isVisible={step > 0 && nodeSaveState === 'notSaved'}
          onClick={() => {
            step > 1 && setStep(step - 1);
            step === 1 && onSwitchWizard();
          }}
        />
        <WizardCloseButton
          isVisible={step < TOTAL_STEPS && nodeSaveState !== 'saving'}
          onClick={closeWizard}
        />
      </WizardNavigation>

      <ItemOwnershipTypeStep
        active={step === 1}
        onSetOwnershipType={newOwnershipType => {
          if (!newOwnershipType) {
            return;
          }
          if (newOwnershipType !== ownershipType) {
            setOwnershipType(newOwnershipType);
          }
          setConnectionId(null);
          setStep(step + 1);
        }}
        currentPerspectiveType={currentPerspectiveType}
      />

      <ItemConnectionStep
        active={step === steps[ownershipType].indexOf(CONNECTION_STEP) + 1}
        onSetConnection={selectedConnection => {
          const {
            id: selectedConnectionId,
          } = selectedConnection;
          setConnectionId(selectedConnectionId);
          setStep(step + 1);
        }}
      />

      <ItemTypeStep
        active={step === steps[ownershipType].indexOf(TYPE_STEP) + 1}
        connectionId={connectionId}
        onSetItemType={itemType => {
          if (itemType.typeId !== typeId) {
            dispatchNodeAction({
              type: 'SET_TYPE',
              payload: itemType,
            });
            dispatchNodeAction({
              type: 'SET_NAME',
              payload: '',
            });
            dispatchNodeAction({
              type: 'SET_RECORD',
              payload: {
                recordId: null,
                recordName: '',
              },
            });
          }
          setStep(step + 1);
        }}
        closeWizardWithPrepend={closeWizardWithPrepend}
      />

      <ItemRecordTypeStep
        active={step === steps[ownershipType].indexOf(RECORD_STEP) + 1}
        connectionId={connectionId}
        itemTypeId={typeId}
        itemTypeName={typeName}
        itemRecordTypeId={recordId}
        onSetItemRecordType={onSetItemRecordType}
        navigateToStep={navigateToStep}
        currentPerspectiveType={currentPerspectiveType}
      />

      <ItemNicknameStep
        active={step === steps[ownershipType].indexOf(NICKNAME_STEP) + 1}
        itemTypeName={typeName}
        itemRecordTypeName={recordName}
        onSetItemNickname={itemName => {
          dispatchNodeAction({
            type: 'SET_NAME',
            payload: itemName,
          });
          setStep(step + 1);
        }}
        navigateToStep={navigateToStep}
      />


      <ItemLocationStep
        active={step === steps[ownershipType].indexOf(LOCATION_STEP) + 1}
        itemTypeId={typeId}
        itemTypeName={typeName}
        itemRecordTypeName={recordName}
        itemNickname={name}
        itemRecordTypeId={recordId}
        onSetItemRecordType={onSetItemRecordType}
        navigateToStep={navigateToStep}
        dispatchNodeAction={dispatchNodeAction}
      />

      <ItemDetailsStep
        active={step === steps[ownershipType].indexOf(DETAILS_STEP) + 1}
        itemTypeName={typeName}
        itemNickname={name}
        itemRecordTypeName={recordName}
        itemLocationName={nodeLocation.name}
        fieldDefinitionsById={fieldDefinitionsById}
        fieldsByFieldDefinitionId={fieldsByFieldDefinitionId}
        fieldDefinitionIds={fieldDefinitionIds}
        fieldDefinitionsAreInitialized={fieldDefinitionsAreInitialized}
        onSetFieldValue={(fieldDefinitionId, fieldValue) => {
          dispatchNodeAction({
            type: 'SET_FIELD_VALUE',
            payload: {
              fieldDefinitionId,
              value: fieldValue,
            },
          });
        }}
        onSetFieldErrorMessage={(fieldDefinitionId, errorMessage) => {
          dispatchNodeAction({
            type: 'SET_ERROR_MESSAGE',
            payload: {
              fieldDefinitionId,
              errorMessage: errorMessage,
            },
          });
        }}
        saveItem={saveItem}
        navigateToStep={navigateToStep}
      />

      <ItemSaveStep
        active={step === steps[ownershipType].indexOf(SAVE_STEP) + 1}
        nodeSaveState={nodeSaveState}
        closeWizardWithPrepend={closeWizardWithPrepend}
      />
    </Wizard>
  );
};

export default AddItemWizard;
