import React from 'react';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';

import ShareRecordConnectionStep from './steps/ShareRecordConnectionStep';
import ShareRecordPermissionsStep from './steps/ShareRecordPermissionsStep';
import ShareRecordSummaryStep from './steps/ShareRecordSummaryStep';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import NoPermissionsModal from 'components/NoPermissionsModal';
import Message from 'ui-library/components/Message';

import parseRecordPermissions from 'utils/permissions/parseRecordPermissions';
import recordFrontendPermissionstoAtomicPermissions from 'utils/permissions/recordFrontendPermissionsToAtomicPermissions';
import recordAtomicPermissionsToPermissionsInt from 'utils/permissions/recordAtomicPermissionsToPermissionsInt';

import genRecordByRecordId from 'services/Records/genRecordByRecordId';
import updateRecordPermissions from 'services/Records/updateRecordPermissions';

const ShareRecordWizard = () => {
  const {
    showShareRecordWizard: open,
    sharedRecordId,
    expandedRecordId,
    dispatchUserInterfaceAction,
    selectedRecordPanelTab,
  } = React.useContext(UserInterfaceContext);

  const TOTAL_STEPS = 3;
  const [step, setStep] = React.useState(1);
  // 'notShared' | 'sharing' | 'shared' | 'shareError'
  const [shareStatus, setShareStatus] = React.useState('notShared');
  // 'notLoaded' | 'loading' | 'loaded'
  const [recordLoadingStatus, setRecordLoadingStatus] = React.useState('notLoaded');
  const [sharedRecordName, setSharedRecordName] = React.useState('');
  const [hasSharePermissions, setHasSharePermissions] = React.useState(false);

  const [connection, setConnection] = React.useState(null);
  const [sharedPermissions, setSharedPermissions] = React.useState({});
  const [givenPermissions, setGivenPermissions] = React.useState({});
  const [showMessage, setShowMessage] = React.useState(false);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw fatalError;
  }

  React.useEffect(() => {
    async function loadRecord() {
      try {
        setRecordLoadingStatus('loading');
        const record = await genRecordByRecordId(
          authToken,
          currentPerspectiveId,
          sharedRecordId,
        );
        const {
          name: recordName,
          permissions,
        } = record;
        setSharedRecordName(recordName);
        const parsedPermissions = parseRecordPermissions(permissions);
        const {
          frontendPermissions,
          frontendPermissions: {
            allow3rdPartyAccess,
          },
        } = parsedPermissions;
        setHasSharePermissions(allow3rdPartyAccess);
        setConnection(null);
        setSharedPermissions(frontendPermissions);
        setGivenPermissions(frontendPermissions);
        setShareStatus('notShared');
        setStep(1);
        setRecordLoadingStatus('loaded');
      } catch (error) {
        setError(error);
      }
    }
    if (open) {
      loadRecord();
    }
  }, [authToken, currentPerspectiveId, open, sharedRecordId]);

  const hasState = () => {
    return step > 1;
  };

  const closeConfirmed = () => {
    if (!hasState()) {
      return true;
    }
    if (hasState() && window.confirm('Are you sure you want to quit the wizard? Your changes will not be saved if you quit now.')) {
      return true;
    }
    return false;
  };

  const closeWizard = () => {
    if (!closeConfirmed()) {
      return;
    }
    onClose();
  };

  const onClose = () => {
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_SHARE_RECORD_WIZARD',
      payload: false,
    });
    if (expandedRecordId) {
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_RECORD_PANEL',
        payload: true,
      });
      if (selectedRecordPanelTab === 1) {
        dispatchUserInterfaceAction({
          type: 'SET_RELOAD_RECORD_CONVERSATION_TAB',
          payload: true,
        });
      }
    }
  };

  const shareRecord = async() => {
    try {
      setShareStatus('sharing');
      const atomicPermissions = recordFrontendPermissionstoAtomicPermissions(sharedPermissions);
      const permissionsInt = recordAtomicPermissionsToPermissionsInt(atomicPermissions);
      const {
        id: connectionId,
      } = connection;
      await updateRecordPermissions(
        authToken,
        currentPerspectiveId,
        sharedRecordId,
        {
          connectionId: connectionId,
          permissions: permissionsInt,
        },
      );
      if (expandedRecordId) {
        dispatchUserInterfaceAction({
          type: 'SET_RELOAD_RECORD_PERMISSION_GRANTS',
          payload: true,
        });
      }
      setShareStatus('shared');
      setShowMessage(true);
      // onClose();
    } catch (error) {
      setError(error);
      setShareStatus('shareError');
      setShowMessage(true);
    }
    onClose();
  };

  if (!open && shareStatus === 'shared') {
    const {
      peerName: partnerName,
    } = connection;
    return (
      <Message
        variant="success"
        open={showMessage}
        onClose={() => {
          setShowMessage(false);
        }}
        messageTitle="Share Success"
        messageBody={`${sharedRecordName} was shared successfully with ${partnerName}`}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    );
  }

  if (!open && shareStatus === 'shareError') {
    return (
      <Message
        variant="error"
        open={showMessage}
        onClose={() => {
          setShowMessage(false);
        }}
        messageTitle="Share Failed"
        messageBody="There was a problem sharing this item"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    );
  }

  if(!open) {
    return null;
  }

  if (recordLoadingStatus !== 'loaded') {
    return null;
  }

  if (open && !hasSharePermissions) {
    return (
      <NoPermissionsModal
        isOpen={true}
        onClose={onClose}
        operation="Share Record"
      />
    );
  }

  return (
    <Wizard onClose={closeWizard}>
      <WizardProgress
        isVisible={true}
        progress={step}
        total={TOTAL_STEPS}
      />
      <WizardNavigation>
        <WizardBackButton
          data-test-id="share-record-wizard-back-button"
          isVisible={step > 1 && shareStatus === 'notShared'}
          onClick={() => {
            step > 1 && setStep(step - 1);
          }}
        />
        <WizardCloseButton
          data-test-id="share-record-wizard-close-button"
          isVisible={step <= TOTAL_STEPS && shareStatus === 'notShared'}
          onClick={closeWizard}
        />
      </WizardNavigation>
      <ShareRecordConnectionStep
        active={step === 1}
        connection={connection}
        onSetConnection={selectedConnection => {
          setConnection(selectedConnection);
        }}
        onClose={() => {
          dispatchUserInterfaceAction({
            type: 'SET_SHOW_SHARE_RECORD_WIZARD',
            payload: false,
          });
        }}
        navigateToStep={setStep}
      />
      <ShareRecordPermissionsStep
        active={step === 2}
        connection={connection}
        permissions={sharedPermissions}
        givenPermissions={givenPermissions}
        onSetSharedPermissions={(permissionObject) => {
          setSharedPermissions(permissionObject);
        }}
        navigateToStep={setStep}
      />
      <ShareRecordSummaryStep
        active={step === 3}
        connection={connection}
        permissions={sharedPermissions}
        recordName={sharedRecordName}
        onShareData={() => {
          shareRecord();
        }}
        shareStatus={shareStatus}
        navigateToStep={setStep}
      />
    </Wizard>
  );
};

export default ShareRecordWizard;
