import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__nodes_ID(authToken, perspectiveId, nodeId, queryParams) {
  let url = `/nodes/${nodeId}`;

  if (queryParams) {
    url += `?include=${queryParams.join(',')}`;
  }

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
