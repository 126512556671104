import React from 'react';
import styled from '@emotion/styled';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import {
  warning,
} from 'ui-library/color-palette';

const Root = styled.div`
  color: ${warning};
`;

const CenterpieceWarning = () => {
  return (
    <Root>
      <WarningRoundedIcon
        style={{
          fontSize: 60,
        }}
      />
    </Root>
  );
};

export default CenterpieceWarning;
