import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../../verbs';

export default async function PATCH__admin_intelligentDataFramework_propertyConfigurationHints_PROPERTYCONFIGURATIONHINTID(authToken, perspectiveId, params) {
  const {
    propertyConfigurationHintId,
    ...patchParams
  } = params;

  return await PATCH(
    `/admin/intelligent-data-framework/property-configuration-hints/${propertyConfigurationHintId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...patchParams,
    },
  );
}
