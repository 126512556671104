import POST__login from 'network-layer/endpoints/POST__login';

export default async function signIn(email, password) {
  const response = await POST__login({
    email,
    password,
  });

  return response;
}
