import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Button from 'ui-library/components/Button';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const ContextualPanelCloseButton = ({
  isVisible,
  onClick,
  onMouseDown,
  'data-test-id': dataTestId,
}) => {
  return (
    <Fade
      in={isVisible}
      timeout={200}>
      <Root
        data-test-id={dataTestId}
        onMouseDown={onMouseDown}
        onClick={() => {
          typeof onClick === 'function' && onClick();
        }}>
        <Button
          variant="icon-text"
        >
          <CloseIcon/>
        </Button>
      </Root>
    </Fade>
  );
};

ContextualPanelCloseButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContextualPanelCloseButton;
