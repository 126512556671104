import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import AddNodeLinkDefinitionDialog from './AddNodeLinkDefinitionDialog.react';
import EditNodeLinkDefinitionDialog from './EditNodeLinkDefinitionDialog.react';
import Prompt from 'components/admin/Prompt';

const NodeLinkDefinitionsAdmin = () => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  const nodeLinkDefinitions = DataModelQueries.selectNodeLinkDefinitions(DataModel);

  return (
    <div>
      <Typography
        variant="h4">
        Link Definitions
      </Typography>
      <Typography
        variant="body2">
        create or edit Link Definitions
      </Typography>
      <br/><br/>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatchLocalAction({
            type: 'BEGIN_ADD_NODE_LINK_DEFINITION',
          });
        }}>
        create a new link definition
      </Button>
      <br/><br/>

      {
        nodeLinkDefinitions.map(nodeLinkDefinition => {
          const {
            id: nodeLinkDefinitionId,
            label: nodeLinkDefinitionLabel,
            directQualifier: nodeLinkDefinitionDirectQualifier,
            reverseQualifier: nodeLinkDefinitionReverseQualifier,
          } = nodeLinkDefinition;
          return (
            <NestedBox
              key={nodeLinkDefinitionId}>
              <ContentLine>
                <LinkIcon/>&nbsp;&nbsp;
                <ContentLineText>
                  <Typography
                    vartiant="body1">
                    {nodeLinkDefinitionLabel}
                    &nbsp;&nbsp;
                    <span
                      style={{
                        color: '#aaa',
                      }}>
                      ({nodeLinkDefinitionDirectQualifier}&nbsp;&nbsp;|&nbsp;&nbsp;{nodeLinkDefinitionReverseQualifier})
                    </span>
                  </Typography>
                </ContentLineText>
                <IconButton
                  onClick={() => {
                    const nodeLinkDefinition = DataModelQueries.selectNodeLinkDefinitionById(
                      DataModel,
                      nodeLinkDefinitionId,
                    );

                    const {
                      id,
                      label,
                      directQualifier,
                      reverseQualifier,
                    } = nodeLinkDefinition;

                    dispatchLocalAction({
                      type: 'BEGIN_EDIT_NODE_LINK_DEFINITION',
                      payload: {
                        id,
                        label,
                        directQualifier,
                        reverseQualifier,
                      },
                    });
                  }}
                >
                  <EditIcon/>
                </IconButton>
                <IconButton
                  onClick={() => {
                    dispatchLocalAction({
                      type: 'BEGIN_REMOVE_NODE_LINK_DEFINITION',
                      payload: nodeLinkDefinitionId,
                    });
                  }}
                >
                  <DeleteIcon/>
                </IconButton>
              </ContentLine>
            </NestedBox>
          );
        })
      }

      <AddNodeLinkDefinitionDialog
        open={
          localState.status === 'adding node link definition' ||
          localState.status === 'requesting add node link definition'
        }
        onClose={() => {
          dispatchLocalAction({
            type: 'END_ADD_NODE_LINK_DEFINITION',
          });
        }}
        controlsDisabled={
          localState.status === 'requesting add node link definition'
        }
        label={localState.label}
        directQualifier={localState.directQualifier}
        reverseQualifier={localState.reverseQualifier}
        onSetLabel={value => {
          dispatchLocalAction({
            type: 'SET_LABEL',
            payload: value,
          });
        }}
        onSetDirectQualifier={value => {
          dispatchLocalAction({
            type: 'SET_DIRECT_QUALIFIER',
            payload: value,
          });
        }}
        onSetReverseQualifier={value => {
          dispatchLocalAction({
            type: 'SET_REVERSE_QUALIFIER',
            payload: value,
          });
        }}
        onSaveNodeLinkDefinition={async() => {
          dispatchLocalAction({
            type: 'REQUEST_ADD_NODE_LINK_DEFINITION',
          });

          try {
            const {
              label,
              directQualifier,
              reverseQualifier,
            } = localState;

            const newNodeLinkDefinition = await DataModelAPI.createNodeLinkDefinition(
              authToken,
              perspectiveId,
              {
                label,
                directQualifier,
                reverseQualifier,
              },
            );

            dispatchDataModelAction({
              type: 'ADD_NODE_LINK_DEFINITION',
              payload: newNodeLinkDefinition,
            });

            dispatchLocalAction({
              type: 'NODE_LINK_DEFINITION_ADDED',
            });

          } catch(error) {
            setAPIException(error);
          }

        }}
      />

      <Prompt
        open={
          localState.status === 'node link definition added'
        }
        title="Success!"
        text={
          <span>
            Link Definition <strong>{localState.label}</strong> was successfully added.
          </span>
        }
        onConfirm={() => {
          dispatchLocalAction({
            type: 'END_ADD_NODE_LINK_DEFINITION',
          });
        }}
      />

      <EditNodeLinkDefinitionDialog
        open={
          localState.status === 'editing node link definition' ||
          localState.status === 'requesting edit node link definition'
        }
        onClose={() => {
          dispatchLocalAction({
            type: 'END_EDIT_NODE_LINK_DEFINITION',
          });
        }}
        controlsDisabled={false}
        label={localState.label}
        directQualifier={localState.directQualifier}
        reverseQualifier={localState.reverseQualifier}
        onSetLabel={value => {
          dispatchLocalAction({
            type: 'SET_LABEL',
            payload: value,
          });
        }}
        onSetDirectQualifier={value => {
          dispatchLocalAction({
            type: 'SET_DIRECT_QUALIFIER',
            payload: value,
          });
        }}
        onSetReverseQualifier={value => {
          dispatchLocalAction({
            type: 'SET_REVERSE_QUALIFIER',
            payload: value,
          });
        }}
        onSaveNodeLinkDefinition={async() => {
          dispatchLocalAction({
            type: 'REQUEST_ADD_NODE_LINK_DEFINITION',
          });

          try {
            const {
              label,
              directQualifier,
              reverseQualifier,
            } = localState;

            await DataModelAPI.updateNodeLinkDefinition(
              authToken,
              perspectiveId,
              {
                nodeLinkDefinitionId: localState.id,
                label,
                directQualifier,
                reverseQualifier,
              },
            );

            dispatchDataModelAction({
              type: 'UPDATE_NODE_LINK_DEFINITION',
              payload: {
                nodeLinkDefinitionId: localState.id,
                patch: {
                  label,
                  directQualifier,
                  reverseQualifier,
                },
              },
            });

            dispatchLocalAction({
              type: 'NODE_LINK_DEFINITION_EDITED',
            });

          } catch(error) {
            setAPIException(error);
          }

        }}
      />

      <Prompt
        open={
          localState.status === 'node link definition edited'
        }
        title="Success!"
        text={
          <span>
            Link Definition <strong>{localState.label}</strong> was successfully edited.
          </span>
        }
        onConfirm={() => {
          dispatchLocalAction({
            type: 'END_EDIT_NODE_LINK_DEFINITION',
          });
        }}
      />

      <Prompt
        open={
          localState.status === 'removing node link definition'
        }
        title="Confirm action"
        text={
          <span>
            Are you sure you want to remove this Link Definition? This action cannot be undone.
          </span>
        }
        onCancel={() => {
          dispatchLocalAction({
            type: 'END_REMOVE_NODE_LINK_DEFINITION',
          });
        }}
        onConfirm={async() => {
          try {
            await DataModelAPI.deleteNodeLinkDefinition(
              authToken,
              perspectiveId,
              {
                nodeLinkDefinitionId: localState.id,
              },
            );

            dispatchDataModelAction({
              type: 'REMOVE_NODE_LINK_DEFINITION',
              payload: localState.id,
            });

            dispatchLocalAction({
              type: 'NODE_LINK_DEFINITION_REMOVED',
            });
          } catch(error) {
            setAPIException(error);
          }
        }}
      />

      <Prompt
        open={
          localState.status === 'node link definition removed'
        }
        title="Success!"
        text={
          <span>
            Link Definition was successfully deleted.
          </span>
        }
        onConfirm={() => {
          dispatchLocalAction({
            type: 'END_REMOVE_NODE_LINK_DEFINITION',
          });
        }}
      />

    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    // DATA
    case 'SET_LABEL': {
      return {
        ...state,
        label: payload,
      };
    }

    case 'SET_DIRECT_QUALIFIER': {
      return {
        ...state,
        directQualifier: payload,
      };
    }

    case 'SET_REVERSE_QUALIFIER': {
      return {
        ...state,
        reverseQualifier: payload,
      };
    }



    // ADD
    case 'BEGIN_ADD_NODE_LINK_DEFINITION': {
      return {
        ...state,
        status: 'adding node link definition',
      };
    }

    case 'REQUEST_ADD_NODE_LINK_DEFINITION': {
      return {
        ...state,
        status: 'requesting add node link definition',
      };
    }

    case 'NODE_LINK_DEFINITION_ADDED': {
      return {
        ...state,
        status: 'node link definition added',
      };
    }

    case 'END_ADD_NODE_LINK_DEFINITION': {
      return {
        ...initState(),
      };
    }



    // EDIT
    case 'BEGIN_EDIT_NODE_LINK_DEFINITION': {
      const {
        id,
        label,
        directQualifier,
        reverseQualifier,
      } = payload;

      return {
        ...state,
        status: 'editing node link definition',
        id,
        label,
        directQualifier,
        reverseQualifier,
      };
    }

    case 'REQUEST_EDIT_NODE_LINK_DEFINITION': {
      return {
        ...state,
        status: 'requesting edit node link definition',
      };
    }

    case 'NODE_LINK_DEFINITION_EDITED': {
      return {
        ...state,
        status: 'node link definition edited',
      };
    }

    case 'END_EDIT_NODE_LINK_DEFINITION': {
      return {
        ...initState(),
      };
    }



    // REMOVE
    case 'BEGIN_REMOVE_NODE_LINK_DEFINITION': {
      const id = payload;

      return {
        ...state,
        status: 'removing node link definition',
        id,
      };
    }

    case 'REQUEST_REMOVE_NODE_LINK_DEFINITION': {
      return {
        ...state,
        status: 'requesting remove node link definition',
      };
    }

    case 'NODE_LINK_DEFINITION_REMOVED': {
      return {
        ...state,
        status: 'node link definition removed',
      };
    }

    case 'END_REMOVE_NODE_LINK_DEFINITION': {
      return {
        ...initState(),
      };
    }



    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  /*
    idle

    adding node link definition
    requesting add node link definition
    node link definition added

    removing node link definition
    requesting remove node link definition
    node link definition removed

    editing node link definition
    requesting edit node link definition
    node link definition edited
  */
  return {
    status: 'idle',
    id: null,
    label: '',
    directQualifier: '',
    reverseQualifier: '',
  };
}

export default NodeLinkDefinitionsAdmin;
