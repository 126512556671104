import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__subscriptions_upgradeSubscriptionPlans(authToken, perspectiveId) {
  const url = '/subscriptions/upgrade-subscription-plans';

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
