import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

import Typography from 'ui-library/components/Typography';

import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/WarningRounded';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: WarningIcon,
  info: InfoIcon,
};

const ALLOWED_VARIANTS = [
  'success',
  'warning',
  'error',
  'info',
];

const useStyles = makeStyles({
  root: {
    width: props => props.width,
    minWidth: theme.inlineMessage.minWidth,
    borderRadius: props => props.borderType === 'flat' ? 'none' : theme.inlineMessage.borderRadius,
    backgroundColor: props => theme.inlineMessage[props.variant].backgroundColor,
    flexWrap: props => props.withTitle === true ? theme.inlineMessage.withTitle.flexWrap : theme.inlineMessage.noTitle.flexWrap,
    alignItems: props => props.withTitle === true ? theme.inlineMessage.withTitle.alignItems : theme.inlineMessage.noTitle.alignItems,
    display: theme.inlineMessage.display,
    height: props => props.height ? props.height : 'auto',
  },
  icon: {
    fontSize: theme.inlineMessage.icon.fontSize,
    opacity: theme.inlineMessage.icon.opacity,
    justifyContent: theme.inlineMessage.icon.justifyContent,
    color: props => theme.inlineMessage[props.variant].color,
  },
  closeIcon: {
    fontSize: theme.inlineMessage.closeIcon.fontSize,
    opacity: theme.inlineMessage.closeIcon.opacity,
    color: props => theme.inlineMessage[props.variant].color,
  },
  closeContainer: theme.inlineMessage.closeContainer,
  messageContainer: {
    display: theme.inlineMessage.messageContainer.display,
    padding: props => props.padding ? props.padding : theme.inlineMessage.messageContainer.padding,
    width: theme.inlineMessage.messageContainer.width,
    alignItems: props => props.withTitle ? theme.inlineMessage.withTitle.alignItems : theme.inlineMessage.noTitle.alignItems,
  },
  message: {
    paddingLeft: theme.inlineMessage.message.paddingLeft,
    color: props => theme.inlineMessage[props.variant].color,
    width: theme.inlineMessage.message.width,
  },
});

const InlineMessage = ({
  variant,
  messageTitle,
  messageBody,
  open,
  onClose,
  persistent = false,
  padding,
  width,
  height,
  borderType,
}) => {
  const classes = useStyles({
    variant: ALLOWED_VARIANTS.indexOf(variant) > -1 ? variant : 'info',
    width: width ? width : '100%',
    height: height ? height : 'auto',
    withTitle: messageTitle ? true : false,
    borderType: borderType ? borderType : null,
    padding: padding ? padding : null,
  });
  const Icon = variantIcon[variant];

  return (
    <div>
      {
        open &&
        <div className={classes.root}>
          <div className={classes.messageContainer}>
            <Icon className={classes.icon} />
            <div className={classes.message}>
              {
                messageTitle &&
                <div>
                  <Typography variant="body" fontWeight="bold">
                    {messageTitle}
                  </Typography>
                </div>
              }
              {messageBody}
            </div>
          </div>
          {
            !persistent &&
            <div className={classes.closeContainer}>
              <IconButton className={classes.closeIcon} key="close" aria-label="close" color="inherit" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          }
        </div>
      }
    </div>
  );
};


InlineMessage.propTypes = {
  variant: PropTypes.oneOf([
    'success',
    'error',
    'warning',
    'info',
  ]),
  messageTitle: PropTypes.string,
  messageBody: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.number,
};

export default InlineMessage;
