import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui-library/components/Typography';

const NotificationMessage = ({
  status,
  children,
}) => {
  return (
    <Typography
      variant="small"
      fontWeight={status === 'unread' ? 'bold' : 'normal'}
    >
      {children}
    </Typography>
  );
};

NotificationMessage.propTypes = {
  status: PropTypes.string,
  children: PropTypes.node,
};

export default NotificationMessage;
