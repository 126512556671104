function initConnectionState() {
  return {
    connectionType: null,
    connectionName: '',
    isConnectionNamePristine: true,
    connectionToken: '',
    isConnectionTokenPristine: true,
    connectionSaveState: 'notSaved', // 'notSaved' | 'saving' | 'saved'
    connectionErrorMessage: '',
  };
}

function connectionReducer(state, {type: actionType, payload}) {
  switch(actionType) {
    case 'RESET_CONNECTION_STATE': {
      return {
        connectionType: null,
        connectionName: '',
        isConnectionNamePristine: true,
        connectionToken: '',
        isConnectionTokenPristine: true,
        connectionSaveState: 'notSaved',
        connectionErrorMessage: '',
      };
    }
    case 'SET_CONNECTION_TYPE': {
      return {
        ...state,
        connectionType: payload,
      };
    }
    case 'SET_CONNECTION_NAME': {
      return {
        ...state,
        connectionName: payload,
      };
    }
    case 'SET_IS_CONNECTION_NAME_PRISTINE': {
      return {
        ...state,
        isConnectionNamePristine: payload,
      };
    }
    case 'SET_CONNECTION_TOKEN': {
      return {
        ...state,
        connectionToken: payload,
      };
    }
    case 'SET_IS_CONNECTION_TOKEN_PRISTINE': {
      return {
        ...state,
        isConnectionTokenPristine: payload,
      };
    }
    case 'SET_CONNECTION_SAVE_STATE': {
      return {
        ...state,
        connectionSaveState: payload,
      };
    }
    case 'SET_CONNECTION_ERROR_MESSAGE': {
      return {
        ...state,
        connectionErrorMessage: payload,
      };
    }
    default:
      throw new Error(`Unknown connection action "${actionType}"!`);
  }
}

export {
  initConnectionState,
  connectionReducer,
};
