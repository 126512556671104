import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__conversations_records_RECORDID(params) {
  const {
    authToken,
    perspectiveId,
    recordId,
  } = params;

  return await GET(
    `/conversations/records/${recordId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
