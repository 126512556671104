import GET__config from 'network-layer/endpoints/GET__config';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return response;
}

export default async function genGlobalConfig() {

  const response = await GET__config();

  return responseAdapter(response);
}
