import PATCH__users_lastName from 'network-layer/endpoints/PATCH__users_lastName';

function responseAdapter(response) {
  return response;
}

export default async function updatePhoneNumber(params) {
  const {
    authToken,
    lastName,
  } = params;
  const response = await PATCH__users_lastName(authToken, {value: lastName});

  return responseAdapter(response);
}
