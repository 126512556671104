import AuthorizationError from './AuthorizationError';
import BadRequestError from './BadRequestError';
import ForbiddenError from './ForbiddenError';
import RateLimiterError from './RateLimiterError';
import VagueAPIError from './VagueAPIError';

export default function(responseStatus, errorBody) {
  switch(responseStatus) {
    case 400:
      return new BadRequestError(responseStatus, errorBody);
    case 403:
      return new ForbiddenError(responseStatus, errorBody);
    case 401:
      return new AuthorizationError(responseStatus, errorBody);
    case 429:
      return new RateLimiterError(responseStatus, errorBody);

    default:
      // for when the API returns a status code that is not accounted for
      return new VagueAPIError(responseStatus, errorBody);
  }
}
