import React from 'react';
import PropTypes from 'prop-types';
import MUIDialog from '@material-ui/core/Dialog';

const ModalCard = ({
  isOpen,
  handleClose,
  children,
  ...otherOptions
}) => {

  return (
    <MUIDialog
      open={isOpen}
      onClose={handleClose}
      {...otherOptions}
    >
      {children}
    </MUIDialog>
  );
};

ModalCard.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.node,
};

export default ModalCard;
