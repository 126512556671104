import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__records(authToken,  perspectiveId) {
  return await GET(
    '/records',
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
