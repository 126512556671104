import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ContextualPanel from 'components/chrome/ContextualPanel';
import ContextualPanelNavigation from 'components/chrome/ContextualPanelNavigation';
import ContextualPanelBackButton from 'components/chrome/ContextualPanelBackButton';
import ContextualPanelCloseButton from 'components/chrome/ContextualPanelCloseButton';
import ContextualPanelHeader from 'components/chrome/ContextualPanelHeader';
import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import ContextualPanelActions from 'components/chrome/ContextualPanelActions';
import ContextualPanelTabs from 'components/chrome/ContextualPanelTabs';
import ContextualPanelTab from 'components/chrome/ContextualPanelTab';
import ConversationContainer from 'components/ConversationContainer';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import MailRoundedIcon from '@material-ui/icons/MailRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import TextField from 'ui-library/components/TextField';
import Field from 'ui-library/components/Field';
import EditableTitle from 'ui-library/components/EditableTitle';

import genConnectionByConnectionId from 'services/Connections/genConnectionByConnectionId';
import updateConnection from 'services/Connections/updateConnection.js';
import genConversationsByConnectionId from 'services/Conversations/genConversationsByConnectionId';
import createConnectionConversation from 'services/Conversations/createConnectionConversation';
import updateConversationStatus from 'services/Conversations/updateConversationStatus';
import genConversationByConversationId from 'services/Conversations/genConversationByConversationId';

import UserInterfaceContext from 'contexts/UserInterface';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import {
  connectionName as connectionNameValidator,
} from 'utils/validator';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const SubtitleContainer = styled.div`
  padding-left: 12px;
`;

const Actions = styled.div`
  display: flex;
  padding-top: 8px;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
`;

const FieldContainer = styled.div`
  padding-top: 23px;
  padding-bottom: 8px;
`;

const ConnectionPanel = () => {
  const {
    expandedConnectionId,
    expandedConnectionLoadingState,
    showConnectionPanel,
    backNavigationEnabled,
    backNavigationLanding,
    dispatchUserInterfaceAction,
    selectedConversation,
    newConversation,
    selectedConnectionPanelTab,
    connectionPanelDisplayMode,
  } = React.useContext(UserInterfaceContext);

  const {
    connectionId,
    connectionName: conversationConnectionName,
    interlocutorOrganizationName: conversationInterlocutorOrganizationName,
    nodeName: conversationTetherName,
  } = selectedConversation || newConversation || {};

  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
      name: currentPerspectiveName,
    },
  } =  React.useContext(PerspectiveContext);

  const [connection, setConnection] = React.useState(null);
  const [hoveredTab, setHoveredTab] = React.useState(null);

  const [titleIsEditable, setTitleIsEditable] = React.useState(false);
  const [titleErrorMessage, setTitleErrorMessage] = React.useState('');
  const [title, setTitle] = React.useState('');

  const [nameFieldIsEditable, setNameFieldIsEditable] = React.useState(false);
  const [nameFieldErrorMessage, setNameFieldErrorMessage] = React.useState('');
  const [nameFieldValue, setNameFieldValue] = React.useState('');

  const [nameIsSaving, setNameIsSaving] = React.useState(false);
  const [previousName, setPreviousName] = React.useState('');

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        setAuthToken(null);
        setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw(fatalError);
  }

  React.useEffect(() => {
    const loadConnection = async() => {
      try {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_CONNECTION_LOADING_STATE',
          payload: 'loading',
        });
        const response = await genConnectionByConnectionId(
          authToken,
          currentPerspectiveId,
          expandedConnectionId,
        );
        setConnection(response);
        setTitle(response.name);
        setNameFieldValue(response.name);
        setPreviousName(response.name);
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_CONNECTION_LOADING_STATE',
          payload: 'loaded',
        });
      } catch (error) {
        setError(error);
      }
    };
    if (expandedConnectionId && expandedConnectionLoadingState === 'not loaded') {
      loadConnection();
    }
  }, [authToken, connection, currentPerspectiveId, dispatchUserInterfaceAction, expandedConnectionId, expandedConnectionLoadingState, newConversation, selectedConversation, showConnectionPanel]);

  React.useEffect(() => {
    const initiateConversation = async() => {
      const conversations = await genConversationsByConnectionId({
        authToken,
        perspectiveId: currentPerspectiveId,
        connectionId: expandedConnectionId,
      });

      // there is max one non-frozen conversation per connection
      const currentConversation = conversations.filter(conversation =>
        conversation.status === 'active' || conversation.status === 'archived')[0];

      if (currentConversation) {
        dispatchUserInterfaceAction({
          type: 'SET_SELECTED_CONVERSATION',
          payload: currentConversation,
        });
      } else {
        const {
          id,
          name,
          peerName,
        } = connection;

        dispatchUserInterfaceAction({
          type: 'SET_NEW_CONVERSATION',
          payload: {
            connectionId: id,
            connectionName: name,
            interlocutorOrganizationName: peerName,
          },
        });
      }
    };
    if (!selectedConversation && !newConversation && expandedConnectionId && connection) {
      initiateConversation();
    }
  }, [authToken, connection, currentPerspectiveId, dispatchUserInterfaceAction, expandedConnectionId, newConversation, selectedConversation]);

  const saveConnectionName = async({
    newValue,
    previousValue,
    errorMessage,
  }) => {
    try {
      nameFieldIsEditable && setNameFieldIsEditable(false);
      titleIsEditable && setTitleIsEditable(false);

      if (newValue !== previousValue && !Boolean(errorMessage)) {
        setNameIsSaving(true);
        await updateConnection(
          authToken,
          currentPerspectiveId,
          expandedConnectionId,
          {name: newValue},
        );
        dispatchUserInterfaceAction({
          type: 'SET_CONNECTION_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
          payload: expandedConnectionId,
        });
        setPreviousName(newValue);
        setNameIsSaving(false);
        nameFieldIsEditable && setTitle(newValue);
        titleIsEditable && setNameFieldValue(newValue);
      }
    } catch (error) {
      setError(error);
    }
  };

  const revertConnectionName = () => {
    if (titleIsEditable) {
      setTitle(previousName);
      setTitleErrorMessage('');
      setTitleIsEditable(false);
      setNameIsSaving(false);
    }

    if (nameFieldIsEditable) {
      setNameFieldValue(previousName);
      setNameFieldErrorMessage('');
      setNameFieldIsEditable(false);
      setNameIsSaving(false);
    }
  };

  const closePanel = () => {
    const changesInProgress = nameFieldValue !== previousName || title !== previousName;
    let cancelChanges = false;
    if (changesInProgress) {
      if (window.confirm('There are modified fields that have not been saved. Are you sure you want to continue?')) {
        cancelChanges = true;
      }
    } else {
      cancelChanges = true;
    }

    if (cancelChanges) {
      dispatchUserInterfaceAction({
        type: 'SET_CONNECTION_PANEL_DISPLAY_MODE',
        payload: 'default',
      });
      dispatchUserInterfaceAction({
        type: 'SET_EXPANDED_CONNECTION_ID',
        payload: null,
      });
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_CONNECTION_PANEL',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_SELECTED_CONVERSATION',
        payload: null,
      });
      dispatchUserInterfaceAction({
        type: 'SET_CONNECTION_PANEL_SELECTED_TAB',
        payload: 0,
      });
      dispatchUserInterfaceAction({
        type: 'SET_NEW_CONVERSATION',
        payload: null,
      });
      revertConnectionName();
      dispatchUserInterfaceAction({
        type: 'SET_EXPANDED_CONNECTION_LOADING_STATE',
        payload: 'not loaded',
      });
      if (backNavigationEnabled) {
        dispatchUserInterfaceAction({
          type: 'SET_BACK_NAVIGATION_ENABLED',
          payload: {
            backNavigationEnabled: false,
            backNavigationLanding: null,
          },
        });
      }
    }
  };

  const {
    status: connectionStatus,
    token: connectionToken,
    peerName,
  } = (connection || {});

  return (
    <ContextualPanel
      expanded={!!expandedConnectionId && showConnectionPanel}
      handleEscKey={() => closePanel()}
      onMouseDown={(event) => {
        if (nameFieldIsEditable === true && !nameFieldErrorMessage) {
          saveConnectionName({
            newValue: nameFieldValue,
            previousValue: previousName,
            errorMessage: nameFieldErrorMessage,
          });
        }
        if (titleIsEditable === true && !titleErrorMessage) {
          saveConnectionName({
            newValue: title,
            previousValue: previousName,
            errorMessage: titleErrorMessage,
          });
        }
      }}
    >
      <ContextualPanelNavigation
        expanded={!!expandedConnectionId && showConnectionPanel}
      >
        <ContextualPanelBackButton
          data-test-id="connection-contextual-panel-back-button"
          isVisible={backNavigationEnabled}
          onClick={() => {
            switch (backNavigationLanding) {
              case 'notifications': {
                closePanel();
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                  payload: true,
                });
                break;
              }
              case 'conversations': {
                dispatchUserInterfaceAction({
                  type: 'SET_CONNECTION_PANEL_DISPLAY_MODE',
                  payload: 'default',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_NEW_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: false,
                    backNavigationLanding: null,
                  },
                });
                break;
              }
              default:
                closePanel();
            }
          }}
        />
        <ContextualPanelCloseButton
          data-test-id="connection-panel-close-button"
          isVisible={true}
          onClick={() => closePanel()}
        />
      </ContextualPanelNavigation>

      {
        expandedConnectionLoadingState === 'loaded' &&
        connectionPanelDisplayMode !== 'conversation' &&
        <ContextualPanelHeader panelDisplayMode='default'>
          <EditableTitle
            autoFocus={true}
            value={title}
            editable={titleIsEditable}
            setEditable={() => {
              if (Boolean(nameFieldErrorMessage)) {
                return;
              }
              setTitleIsEditable(true);
            }}
            error={Boolean(titleErrorMessage)}
            helperText={titleErrorMessage}
            isSaving={nameIsSaving}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                if (titleErrorMessage) {
                  return;
                }
                if (title !== previousName) {
                  saveConnectionName({
                    newValue: title,
                    previousValue: previousName,
                    errorMessage: titleErrorMessage,
                  });
                } else {
                  setTitleIsEditable(false);
                }
              }
            }}
            onChange={(event) => {
              setTitle(event.target.value);
              setTitleErrorMessage(
                connectionNameValidator(event.target.value),
              );
            }}
            onMouseDown={(event) => event.stopPropagation()}
            saveName={() => {
              saveConnectionName({
                newValue: title,
                previousValue: previousName,
                errorMessage: titleErrorMessage,
              });
            }}
            revertName={() => revertConnectionName()}
          />
          <SubtitleContainer>
            {
              peerName ?
                <Typography variant="h5">{peerName}</Typography> :
                <Typography variant="h5">
                  Status:&nbsp;
                  <Typography variant="h5" color="error">
                    {connectionStatus.charAt(0).toUpperCase() + connectionStatus.slice(1)}
                  </Typography>
                </Typography>
            }
          </SubtitleContainer>
        </ContextualPanelHeader>
      }

      {
        expandedConnectionLoadingState === 'loaded' &&
        connectionPanelDisplayMode === 'default' &&
        <>
          <ContextualPanelContent panelDisplayMode='default'>
            <ContextualPanelTabs
              selectedTab={selectedConnectionPanelTab}
            >
              <ContextualPanelTab
                onMouseEnter={() => {
                  setHoveredTab(0);
                }}
                onMouseLeave={() => {
                  setHoveredTab(null);
                }}
                tabIndex={0}
                selectedTab={selectedConnectionPanelTab}
                hoveredTab={hoveredTab}
                setSelectedTab={(value) => {
                  dispatchUserInterfaceAction({
                    type: 'SET_CONNECTION_PANEL_SELECTED_TAB',
                    payload: value,
                  });
                }}
                label="Connection Details"
                icon={<InfoRoundedIcon />}
              />
              {
                connectionStatus !== 'pending' &&
                  <ContextualPanelTab
                    onMouseEnter={() => {
                      setHoveredTab(1);
                    }}
                    onMouseLeave={() => {
                      setHoveredTab(null);
                    }}
                    tabIndex={1}
                    selectedTab={selectedConnectionPanelTab}
                    hoveredTab={hoveredTab}
                    setSelectedTab={() => {
                      dispatchUserInterfaceAction({
                        type: 'SET_CONNECTION_PANEL_DISPLAY_MODE',
                        payload: 'conversation',
                      });
                      dispatchUserInterfaceAction({
                        type: 'SET_BACK_NAVIGATION_ENABLED',
                        payload: {
                          backNavigationEnabled: true,
                          backNavigationLanding: 'conversations',
                        },
                      });
                    }}
                    label="Conversations"
                    icon={<ChatBubbleOutlineIcon />}
                  />
              }
            </ContextualPanelTabs>
            {
              selectedConnectionPanelTab === 0 &&
              <PaddedContainer>
                <FieldContainer>
                  <TextField
                    disabled
                    label="Connection Code"
                    value={connectionToken}
                  />
                </FieldContainer>

                {
                  connectionStatus === 'pending' &&
                  <Actions>
                    <Button
                      variant="primary"
                      startIcon={<MailRoundedIcon />}
                      onClick={() => {
                        const href =
                          'mailto:?subject=A Jules Connection Code has been shared with you!' +
                          '&body=' +
                          `${currentPerspectiveName} is inviting you to collaborate using Jules!` +
                          '%0D%0A%0D%0A' +
                          'Below is your unique Connection Code. Use this code to accept this collaboration request from your Jules web or mobile app.' +
                          '%0D%0A%0D%0A' +
                          `${connectionToken}` +
                          '%0D%0A%0D%0A' +
                          'Step-by-step instructions to accept Connection Code:' +
                          '%0D%0A%0D%0A' +
                          '1. Log in to your Jules account. If you do not have a Jules account, click on any of the links below to get started today!' +
                          '%0D%0A' +
                          '  Log in to Jules Web: https://jules.app/sign-in' +
                          '%0D%0A' +
                          '  Login to Jules Mobile (iOS): https://apps.apple.com/us/app/jules-mobile/id1443574567' +
                          '%0D%0A' +
                          '  Log in to Jules Mobile (Android): https://play.google.com/store/apps/details?id=app.jules.mobile%26hl=en_US%26gl=US' +
                          '%0D%0A' +
                          '2. Go to "Add" Page from Navigation Bar' +
                          '%0D%0A' +
                          '3. Select "Connection"' +
                          '%0D%0A' +
                          '4. Select "Incoming"' +
                          '%0D%0A' +
                          '5. Copy %26 Paste your Connection Code from above and Name your new Connection' +
                          '%0D%0A' +
                          '6. Continue and you will see your Connection has been successfully established!' +
                          '%0D%0A%0D%0A' +
                          'Accepting this Connection does not share any of your Jules information with this Connection except your account name. Once you have established this Connection, you will be able to access and manage ' + currentPerspectiveName + '\'s information in accordance to the permissions you have been provided by ' + currentPerspectiveName + '. Through this Connection, you may also share access to your information with ' + currentPerspectiveName + '. In order to share your information, you must be logged in to Jules Web.' +
                          '%0D%0A%0D%0A';

                        window.location.href = href;
                      }}
                    >
                      send code by email
                    </Button>
                    <Button
                      variant="primary"
                      startIcon={<FileCopyRoundedIcon />}
                      onClick={() => {
                        navigator.clipboard.writeText(connectionToken);
                      }}
                    >
                      copy code to clipboard
                    </Button>
                  </Actions>
                }

                <Field
                  data-test-id="connection-name-input"
                  inlineEdit={true}
                  autoFocus={true}
                  type="name"
                  label="Connection Name"
                  value={nameFieldValue}
                  required={false}
                  isEditable={nameFieldIsEditable}
                  isSaving={nameIsSaving}
                  error={Boolean(nameFieldErrorMessage)}
                  helperText={nameFieldErrorMessage}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    if (Boolean(titleErrorMessage)) {
                      return;
                    }
                    if (!nameFieldIsEditable && !nameIsSaving) {
                      setNameFieldIsEditable(true);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      if (nameFieldErrorMessage) {
                        return;
                      }
                      saveConnectionName({
                        newValue: nameFieldValue,
                        previousValue: previousName,
                        errorMessage: nameFieldErrorMessage,
                      });
                    }
                  }}
                  onChange={(event) => {
                    setNameFieldValue(event.target.value);
                    setNameFieldErrorMessage(
                      connectionNameValidator(event.target.value),
                    );
                  }}
                  onMouseDown={(ev) => {
                    ev.stopPropagation();
                  }}
                  revertField={() => revertConnectionName()}
                  saveField={() => {
                    saveConnectionName({
                      newValue: nameFieldValue,
                      previousValue: previousName,
                      errorMessage: nameFieldErrorMessage,
                    });
                  }}
                />
              </PaddedContainer>
            }
          </ContextualPanelContent>

          <ContextualPanelActions
            expanded={!!expandedConnectionId && showConnectionPanel}
          >
            <Button
              data-test-id="delete-connection-button"
              variant="custom"
              customBgColor="transparent"
              customHoverBgColor="error-lightest"
              customTextColor="error"
              customHoverTextColor="error-dark"
              customActiveBgColor="white"
              customActiveTextColor="error-darker"
              onClick={() => {
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_CONNECTION_PANEL',
                  payload: false,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_DELETE_CONNECTION_DIALOG',
                  payload: true,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_DELETE_CONNECTION_ID',
                  payload: expandedConnectionId,
                });
              }}
            >
              DELETE CONNECTION
            </Button>
          </ContextualPanelActions>
        </>
      }

      {
        expandedConnectionLoadingState === 'loaded' &&
        connectionPanelDisplayMode === 'conversation' &&
        <ConversationContainer
          newConversationConnectionId={connectionId}
          conversationConnectionName={conversationConnectionName}
          conversationInterlocutorOrganizationName={conversationInterlocutorOrganizationName}
          conversationTetherName={conversationTetherName}
          conversation={selectedConversation}
          onCreateNewConversation={async(connectionId, message) => {
            const newConversation = await createConnectionConversation(
              authToken,
              currentPerspectiveId,
              {
                message,
                connectionId,
              },
            );
            dispatchUserInterfaceAction({
              type: 'SET_SELECTED_CONVERSATION',
              payload: newConversation,
            });
          }}
          onUpdateConversationStatus={async(conversationId, status) => {
            await updateConversationStatus({
              authToken,
              perspectiveId: currentPerspectiveId,
              conversationId,
              status,
            });
            const updatedConversation = await genConversationByConversationId({
              authToken,
              perspectiveId: currentPerspectiveId,
              conversationId,
            });
            dispatchUserInterfaceAction({
              type: 'SET_SELECTED_CONVERSATION',
              payload: updatedConversation,
            });
          }}
        />
      }
    </ContextualPanel>
  );
};

ConnectionPanel.propTypes = {
  expandedConnectionId: PropTypes.number,
  showConnectionPanel: PropTypes.bool,
  dispatchUserInterfaceAction: PropTypes.func,
};

export default ConnectionPanel;
