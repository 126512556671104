function propertyConfigurationHintUIStateInitializer() {
  return {
    expandedVirtualLocationTreeNodeHints: [],
    editingVirtualLocationTreeNodeId: null,
    editingVirtualLocationTreeNodeName: '',
    editingVirtualLocationTreeNodeTypeName: '',
  };
}

export default propertyConfigurationHintUIStateInitializer;
