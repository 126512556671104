import GET__types from 'network-layer/endpoints/GET__types';

function responseAdapter(response) {
  return response;
}

export default async function genTypes(authToken, perspectiveId) {
  const response = await GET__types(authToken, perspectiveId);

  return responseAdapter(response);
}
