function selectFieldWatcherIdsByNotificationPipelineId(DM, notificationPipelineId) {
  const {
    fieldWatchersById,
    fieldWatcherIds,
  } = DM;

  return (
    fieldWatcherIds
      .map(fieldWatcherId => fieldWatchersById[fieldWatcherId])
      .filter(fieldWatcher => fieldWatcher.notificationPipelineId === notificationPipelineId)
      .map(fieldWatcher => fieldWatcher.id)
  );
}

export default selectFieldWatcherIdsByNotificationPipelineId;
