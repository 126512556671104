import GET__user from 'network-layer/endpoints/GET__user';

function responseAdapter(response) {
  return response;
}

export default async function genUser(authToken) {
  const response = await GET__user(authToken);

  return responseAdapter(response);
}
