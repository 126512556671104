import React from 'react';
import styled from '@emotion/styled';
import Tabs from '@material-ui/core/Tabs';

import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const TabsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const useStyles = makeStyles({
  root: {
    borderBottom: theme.contextualPanelTabs.tabs.borderBottom,
    padding: theme.contextualPanelTabs.tabs.padding,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.contextualPanelTabs.tabIndicator.backgroundColor,
      height: theme.contextualPanelTabs.tabIndicator.height,
      borderRadius: theme.contextualPanelTabs.tabIndicator.borderRadius,
    },
    '& .MuiTab-root': {
      textTransform: theme.contextualPanelTabs.tab.textTransform,
    },
  },
});
const ContextualPanelTabs = ({
  children,
  selectedTab,
}) => {

  const classes = useStyles();

  return (
    <TabsContainer>
      <Tabs
        className={classes.root}
        value={selectedTab}>
        {children}
      </Tabs>
    </TabsContainer>
  );
};

export default ContextualPanelTabs;
