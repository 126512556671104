import selectVirtualLocationTreeNodeHints from './selectVirtualLocationTreeNodeHints';

function selectVirtualLocationTreeNodeHintChildren(configurationState, virtualLocationTreeNodeHint) {
  const {
    id,
  } = virtualLocationTreeNodeHint;

  return (
    selectVirtualLocationTreeNodeHints(configurationState)
      .filter(virtualLocationTreeNodeHint => {
        const {
          parentId,
        } = virtualLocationTreeNodeHint;
        return parentId === id;
      })
  );
}

export default selectVirtualLocationTreeNodeHintChildren;
