function sortVirtualLocationTreeNodeHintsByPrevalence(hint1, hint2) {
  const prevalenceSortOrder = [
    'ubiquitous',
    'common',
    'rare',
  ];

  const p1 = prevalenceSortOrder.indexOf(hint1.prevalence);
  const p2 = prevalenceSortOrder.indexOf(hint2.prevalence);
  const pw1 = hint1.prevalenceWeight;
  const pw2 = hint2.prevalenceWeight;

  if (p1 < p2) {
    return -1;
  }

  if (p1 > p2) {
    return 1;
  }

  if (pw1 > pw2) {
    return -1;
  }

  if (pw1 < pw2) {
    return 1;
  }

  return 0;
}

export default sortVirtualLocationTreeNodeHintsByPrevalence;
