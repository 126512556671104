class VagueAPIError extends Error {
  constructor(responseStatus, errorBody) {
    super(`Vague API Error! Unhandled status code ${responseStatus}!`);

    this.name = 'VagueAPIError';
    this.body = errorBody;
    this.responseStatus = responseStatus;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, VagueAPIError);
    }
  }
}

export default VagueAPIError;
