import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__conversations_connections_CONNECTIONID(params) {
  const {
    authToken,
    perspectiveId,
    connectionId,
  } = params;

  return await GET(
    `/conversations/connections/${connectionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
