import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

const HintEditButton = ({
  onClick,
}) => {
  return (
    <EditIcon
      style={{cursor: 'pointer', fontSize: 16, color: '#666'}}
      onClick={() => {
        if (typeof onClick !== 'function') {
          return;
        }
        onClick();
      }}
    />
  );
};

export default HintEditButton;
