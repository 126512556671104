import React from 'react';
import Fade from '@material-ui/core/Fade';

const PageContainer = ({
  children,
}) => {
  return (
    <Fade
      in
      timeout={500}>
      <div>
        {children}
      </div>
    </Fade>
  );
};

export default PageContainer;
