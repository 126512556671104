import isEmail from 'validator/lib/isEmail';

export default function fieldIsEmail(
  params,
  errorMessage = 'invalid e-mail',
) {
  const {
    value,
  } = params;

  if (value === '' || isEmail(value)) {
    return '';
  }
  return errorMessage;
}
