import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import BannerMessage from 'ui-library/components/BannerMessage';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Grid from '@material-ui/core/Grid';
import Field from 'ui-library/components/Field';
import parseFieldValue from 'utils/parseFieldValue';
import getDateToAPIFormat from 'utils/getDateToAPIFormat';
import getDateWithTimezoneOffset from 'utils/getDateWithTimezoneOffset';

import {
  PERSON_STEP,
  SAVE_STEP,
} from 'constants/addItem.js';

import {
  fieldIsText,
  fieldIsLongformText,
  fieldIsNumber,
  fieldIsCurrency,
  fieldIsDate,
  fieldIsUrl,
  fieldIsPhoneNumber,
  fieldIsEmail,
  fieldIsEnum,
  fieldIsBoolean,
  fieldIsYear,
  fieldIsName,
  fieldIsZipCode,
} from 'utils/validator';

const FIELD_VALIDATION = {
  'text': fieldIsText,
  'longform text': fieldIsLongformText,
  'number': fieldIsNumber,
  'currency': fieldIsCurrency,
  'date': fieldIsDate,
  'url': fieldIsUrl,
  'phone number': fieldIsPhoneNumber,
  'e-mail address': fieldIsEmail,
  'enum': fieldIsEnum,
  'boolean': fieldIsBoolean,
  'year': fieldIsYear,
  'name': fieldIsName,
  'zip code': fieldIsZipCode,
};

const EHRItemDetailsStep = ({
  active,
  personName,
  healthRecordId,
  healthTypeName,
  onSetFieldValue,
  onSetFieldErrorMessage,
  fieldDefinitionsById,
  fieldsByFieldDefinitionId,
  fieldDefinitionIds,
  fieldDefinitionsAreInitialized,
  navigateToStep,
  saveEHRItem,
}) => {

  if (!active) {
    return null;
  }

  const fieldsHaveErrors = fieldDefinitionIds.map(fieldDefinitionId => {
    const {
      errorMessage,
    } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

    return errorMessage === '';
  }).includes(false);

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add eHR
          </WizardBreadcrumb>
          <WizardBreadcrumb onClick={() => navigateToStep(PERSON_STEP)}>
            {personName}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            {healthTypeName}
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          You can edit the details later from the Library Section.
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn width="100%" padding="20px 200px">
        <form onSubmit={(event) => {
          event.preventDefault();
          const fields = {};
          Object.keys(fieldsByFieldDefinitionId).forEach(fieldDefinitionId => {
            const {
              type,
              value,
            } = fieldsByFieldDefinitionId[fieldDefinitionId];

            if (![null, '', 'N/A'].includes(value)) {
              fields[fieldDefinitionId] = parseFieldValue({type, value, setError: (error) => {throw error;}});
            }
          });

          saveEHRItem(fields);
          navigateToStep(SAVE_STEP);
        }}>
          <WizardStepContent>
            {
              !fieldDefinitionsAreInitialized &&
                <CenterpieceSpinner/>
            }
            {
              fieldDefinitionsAreInitialized &&
              <>
                <div style={{margin: '40px -12px 32px -12px'}}>
                  <BannerMessage
                    icon={<InfoRoundedIcon/>}
                    title="Attachments can be added from the Library."
                    type="info"
                  />
                </div>
                <Grid
                  container
                  spacing={8}>
                  {
                    fieldDefinitionIds.map((fieldDefinitionId, index) => {
                      const {
                        value,
                        errorMessage,
                      } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

                      const {
                        type,
                        label,
                        description,
                        enumValues,
                      } = fieldDefinitionsById[fieldDefinitionId];

                      const options = type === 'enum' ? ['N/A', ...enumValues] : enumValues;

                      return (
                        <Grid
                          key={fieldDefinitionId}
                          container
                          item
                          spacing={2}
                          xs={12}
                          sm={12}
                          md={6}
                          lg={4}
                          xl={3}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          {
                            type === 'date' ?
                              <Field
                                type="date"
                                label={label}
                                helperText={errorMessage ? errorMessage : description}
                                error={Boolean(errorMessage)}
                                isEditable={true}
                                required={false}
                                value={value === null ? value : getDateWithTimezoneOffset(value)}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                }}
                                onChange={(value) => {
                                  onSetFieldValue(
                                    fieldDefinitionId,
                                    value === null ? null : getDateToAPIFormat(value),
                                  );
                                  onSetFieldErrorMessage(
                                    fieldDefinitionId,
                                    FIELD_VALIDATION['date']({value: value}),
                                  );
                                }}
                                onMouseDown={(ev) =>{
                                  ev.stopPropagation();
                                }}
                                disabled={false}
                              />
                              :
                              <Field
                                inlineEdit={false}
                                autoFocus={index === 0}
                                type={type}
                                label={label}
                                helperText={Boolean(errorMessage) ? errorMessage : description}
                                error={Boolean(errorMessage)}
                                isEditable={true}
                                value={value}
                                options={options}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();
                                }}
                                onChange={(event) => {
                                  onSetFieldValue(
                                    fieldDefinitionId,
                                    event.target.value,
                                  );
                                  onSetFieldErrorMessage(
                                    fieldDefinitionId,
                                    FIELD_VALIDATION[type]({value: event.target.value, options: options}),
                                  );
                                }}
                                onMouseDown={(ev) =>{
                                  ev.stopPropagation();
                                }}
                                disabled={false}
                                required={false}
                              />
                          }
                        </Grid>
                      );
                    })
                  }
                </Grid>
              </>
            }
          </WizardStepContent>
          {
            fieldDefinitionsAreInitialized &&
            <WizardStepActions>
              <WizardSubmitWithEnterButton
                data-test-id="ehr-item-details-step-save-item-button"
                disabled={!fieldDefinitionsAreInitialized || fieldsHaveErrors}>
                {
                  Object.values(fieldsByFieldDefinitionId).find(
                    field => ![null, '', 'N/A'].includes(field.value)) ? 'Save Item' : 'Skip and save'
                } &nbsp;<ForwardIcon/>
              </WizardSubmitWithEnterButton>
            </WizardStepActions>
          }
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

EHRItemDetailsStep.propTypes = {
  onSetFieldValue: PropTypes.func.isRequired,
  onSetFieldErrorMessage: PropTypes.func.isRequired,
  saveItem: PropTypes.func,
  active: PropTypes.bool,
  personName: PropTypes.string,
  healthTypeName: PropTypes.string,
  healthRecordId: PropTypes.number,
  fieldDefinitionsAreInitialized: PropTypes.bool,
  navigateToStep: PropTypes.func,
};

export default EHRItemDetailsStep;
