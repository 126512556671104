import React from 'react';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNewRounded';

import UserContext from 'contexts/User';

import signOut from 'services/Auth/signOut';

const LogOutModal = ({
  isOpen,
  handleClose,
}) => {
  const user = React.useContext(UserContext);

  const {
    authToken,
  } = user;

  return (
    <ModalCard
      open={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <ModalCardHeader>
        <ModalCardHeaderIcon>
          <PowerSettingsNewIcon fontSize="inherit"/>
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle>
          Log Out
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        <Typography variant="body">
          Are you sure you want to log out?
        </Typography>
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="cancel-logout-button"
          variant="text"
          onClick={handleClose}>
          Cancel
        </Button>
        <Button
          data-test-id="confirm-logout-button"
          variant="primary"
          onClick={() => {
            signOut(authToken);
            localStorage.removeItem('authToken');
            user.setAuthenticationStatus('not-authenticated');
            user.setAuthToken(null);
          }}>
          Log out
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

export default LogOutModal;
