import React from 'react';
import PropTypes from 'prop-types';
import NodeAttachmentProgressBar from 'ui-library/components/NodeAttachmentProgressBar';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import NodeAttachmentTitle from 'ui-library/components/NodeAttachmentTitle';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles({
  attachmentWrapper: {
    diplay: theme.nodeAttachmentDownload.attachmentWrapper.diplay,
    flexDirection: theme.nodeAttachmentDownload.attachmentWrapper.flexDirection,
    padding: theme.nodeAttachmentDownload.attachmentWrapper.padding,
  },
  attachment: {
    display: theme.nodeAttachmentDownload.attachment.display,
    borderRadius: theme.nodeAttachmentDownload.attachment.borderRadius,
    padding: theme.nodeAttachmentDownload.attachment.padding,
    transition: theme.nodeAttachmentDownload.attachment.transition,
    backgroundColor: props => props.isDownloading ? theme.nodeAttachmentDownload.attachment.downloadBackgroundColor : props.isDeleteDialogVisible ? theme.nodeAttachmentDownload.attachment.deleteBackgroundColor : '',
    '&:hover': {
      backgroundColor: props => props.isDeleteDialogVisible ? theme.nodeAttachmentDownload.attachment.deleteBackgroundColor : theme.nodeAttachmentDownload.attachment.hoverBackgroundColor,
    },
  },
  attachmentThumbnail: {
    display: theme.nodeAttachmentDownload.attachmentThumbnail.display,
    alignItems: theme.nodeAttachmentDownload.attachmentThumbnail.alignItems,
    justifyContent: theme.nodeAttachmentDownload.attachmentThumbnail.justifyContent,
    padding: theme.nodeAttachmentDownload.attachmentThumbnail.padding,
    textTransform: theme.nodeAttachmentDownload.attachmentThumbnail.textTransform,
    fontWeight: theme.nodeAttachmentDownload.attachmentThumbnail.fontWeight,
    color: theme.nodeAttachmentDownload.attachmentThumbnail.color,
    width: theme.nodeAttachmentDownload.attachmentThumbnail.width,
    height: theme.nodeAttachmentDownload.attachmentThumbnail.height,
    borderRadius: theme.nodeAttachmentDownload.attachmentThumbnail.borderRadius,
    backgroundColor: props => props.isDownloading ? theme.nodeAttachmentDownload.attachmentThumbnail.downloadBackgroundColor : props.isDeleteDialogVisible ? theme.nodeAttachmentDownload.attachmentThumbnail.deleteBackgroundColor : theme.nodeAttachmentDownload.attachmentThumbnail.backgroundColor,
    downloadBackgroundColor: theme.nodeAttachmentDownload.attachmentThumbnail.downloadBackgroundColor,
    backgroundImage: props => props.thumbnailUrl,
    minWidth: theme.nodeAttachmentDownload.attachmentThumbnail.minWidth,
  },
  attachmentInfo: {
    flex: theme.nodeAttachmentDownload.attachmentInfo.flex,
    display: theme.nodeAttachmentDownload.attachmentInfo.display,
    flexDirection: theme.nodeAttachmentDownload.attachmentInfo.flexDirection,
    justifyContent: theme.nodeAttachmentDownload.attachmentInfo.justifyContent,
    padding: theme.nodeAttachmentDownload.attachmentInfo.padding,
    minWidth: theme.nodeAttachmentDownload.attachmentInfo.minWidth,
  },
  attachmentInfoMain: {
    display: theme.nodeAttachmentDownload.attachmentInfoMain.display,
  },
  attachmentFileSize: {
    width: theme.nodeAttachmentDownload.attachmentFileSize.width,
    display: theme.nodeAttachmentDownload.attachmentFileSize.display,
    alignItems: theme.nodeAttachmentDownload.attachmentFileSize.alignItems,
    justifyContent: theme.nodeAttachmentDownload.attachmentFileSize.justifyContent,
  },
  attachmentInfoDownloadProgress: {
  },
  attachmentActions: {
    width: theme.nodeAttachmentDownload.attachmentActions.width,
    display: theme.nodeAttachmentDownload.attachmentActions.display,
    alignItems: theme.nodeAttachmentDownload.attachmentActions.alignItems,
    justifyContent: theme.nodeAttachmentDownload.attachmentActions.justifyContent,
  },
  attachmentAction: {
    visibility: props => props.isDownloading ? 'hidden' : '',
    display: theme.nodeAttachmentDownload.attachmentAction.display,
    color: props => props.isDeleteDialogVisible ? theme.nodeAttachmentDownload.attachmentAction.deleteColor : props.isMenuOpen ?  theme.nodeAttachmentDownload.attachmentAction.highlightColor : theme.nodeAttachmentDownload.attachmentAction.defaultColor,
    cursor: props => props.isDeleteDialogVisible ? theme.nodeAttachmentDownload.attachmentAction.disabledCursor : theme.nodeAttachmentDownload.attachmentAction.cursor,
  },
  attachmentCancelDownloadAction: {
    display: theme.nodeAttachmentDownload.attachmentCancelDownloadAction.display,
    alignItems: theme.nodeAttachmentDownload.attachmentCancelDownloadAction.alignItems,
    justifyContent: theme.nodeAttachmentDownload.attachmentCancelDownloadAction.justifyContent,
    width: theme.nodeAttachmentDownload.attachmentCancelDownloadAction.width,
    cursor: theme.nodeAttachmentDownload.attachmentCancelDownloadAction.cursor,
    color: theme.nodeAttachmentDownload.attachmentCancelDownloadAction.color,
  },
  attachmentDeleteWarning: {
    display: theme.nodeAttachmentDownload.attachmentDeleteWarning.display,
    marginTop: theme.nodeAttachmentDownload.attachmentDeleteWarning.marginTop,
  },
  attachmentDeleteText: {
    display: theme.nodeAttachmentDownload.attachmentDeleteText.display,
    alignItems: theme.nodeAttachmentDownload.attachmentDeleteText.alignItems,
    justifyContent: theme.nodeAttachmentDownload.attachmentDeleteText.justifyContent,
  },
  attachmentDeleteButtons: {
    display: theme.nodeAttachmentDownload.attachmentDeleteButtons.display,
    alignItems: theme.nodeAttachmentDownload.attachmentDeleteButtons.alignItems,
    justifyContent: theme.nodeAttachmentDownload.attachmentDeleteButtons.justifyContent,
    flexGrow: theme.nodeAttachmentDownload.attachmentDeleteButtons.flexGrow,
  },
});

const NodeAttachmentDownload = ({
  id,
  title,
  onTitleChange,
  saveTitle,
  revertTitle,
  isEditable,
  setIsEditable,
  titleError,
  isTitleSaving,
  hasPreview,
  thumbnailBlobURL,
  fileExtension,
  fileSize,
  progress,
  isDownloading,
  isDeleting,
  isDeleteDialogVisible,
  displayDeleteDialog,
  performDelete,
  handleAbort,
  handleDownload,
  handlePreview,
  isMenuOpen,
  setIsMenuOpen,
  handleCloseDownloadMenu,
  anchorEl,
  setMenuAnchorEl,
}) => {

  const classes = useStyles({
    thumbnailUrl: thumbnailBlobURL && !isDownloading ? `url(${thumbnailBlobURL})` : 'none',
    isDownloading,
    isDeleteDialogVisible,
    isMenuOpen,
  });

  if (isDeleting) {
    return (
      <div>Attachment is deleting ...</div>
    );
  }

  return (
    <>
      <div className={classes.attachmentWrapper}>
        <div className={classes.attachment}>
          <div
            data-test-id="attachment-thumbnail-button"
            className={classes.attachmentThumbnail}
            onClick={() => {
              if (thumbnailBlobURL) {
                handlePreview(id);
              }
            }}
          >
            {
              isDownloading ?
                `${progress}`
                :
                !thumbnailBlobURL ?
                  <Typography
                    variant="body"
                    fontWeight="bold"
                  >
                    {fileExtension !== '' ? fileExtension : 'N/A'}
                  </Typography>
                  :
                  null
            }
          </div>

          <div className={classes.attachmentInfo}>
            <div className={classes.attachmentInfoMain}>
              <NodeAttachmentTitle
                id={id}
                title={title}
                onTitleChange={onTitleChange}
                saveTitle={saveTitle}
                revertTitle={revertTitle}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                isDownloading={isDownloading}
                isDisabled={false}
                titleError={titleError}
                isTitleSaving={isTitleSaving}
              />

              <div className={classes.attachmentFileSize}>
                <Typography
                  variant="body"
                >
                  {fileSize}
                </Typography>
              </div>
            </div>
            {
              isDownloading &&
            <div className={classes.attachmentInfoDownloadProgress}>
              <NodeAttachmentProgressBar
                railColor="tertiary-lightest"
                progressColor="tertiary"
                progress={progress}
                hasError={false}
              />
            </div>
            }
          </div>
          {
            <div className={classes.attachmentActions}>
              <div
                data-test-id="attachment-context-menu-button"
                className={isDownloading ? classes.attachmentCancelDownloadAction : classes.attachmentAction}
                onClick={(ev) => {
                  if (isDownloading) {
                    handleAbort(id);
                  } else {
                    if (isDeleteDialogVisible || titleError) {
                      return;
                    }
                    setMenuAnchorEl(id, ev.currentTarget);
                    setIsMenuOpen(id, true);
                  }
                }}
              >
                {
                  isDownloading ?
                    <CloseRoundedIcon />
                    :
                    <MoreHorizRoundedIcon />
                }
              </div>
            </div>
          }
        </div>
        <Collapse in={isDeleteDialogVisible} timeout={200}>
          <div className={classes.attachmentDeleteWarning}>
            <div className={classes.attachmentDeleteText}>
              <Typography
                variant="body"
                fontWeight="bold"
                color="error"
              >
                  Are you sure you want to delete this attachment?
              </Typography>
            </div>
            <div className={classes.attachmentDeleteButtons}>
              <Button
                data-test-id="attachment-delete-no-button"
                variant="text"
                textTransform="uppercase"
                onClick={() => {
                  displayDeleteDialog(id, false);
                }}
              >
                <Typography
                  variant="small"
                  fontWeight="bold"
                  fontFamily="primary"
                  color="primary"
                >
                    no
                </Typography>
              </Button>
              <Button
                data-test-id="attachment-delete-yes-button"
                variant="custom"
                textTransform="uppercase"
                customBgColor="transparent"
                customTextColor="error"
                customHoverBgColor="error-lightest"
                customHoverTextColor="error"
                customActiveBgColor="error-lightest"
                customActiveTextColor="error"
                onClick={() => {
                  performDelete(id);
                }}
              >
                <Typography
                  variant="small"
                  fontWeight="bold"
                  fontFamily="primary"
                >
                    yes
                </Typography>
              </Button>
            </div>
          </div>
        </Collapse>
      </div>

      <Menu
        open={isMenuOpen}
        onClose={() => {
          handleCloseDownloadMenu(id);
        }}
        anchorEl={anchorEl}
        disablePortal={true}
      >
        <MenuItem
          data-test-id="attachment-preview-button"
          disabled={!hasPreview}
          showDivider={true}
          onClick={() => {
            handlePreview(id);
            handleCloseDownloadMenu(id);
          }}
        >
          {`${hasPreview ? 'Preview' : 'No Preview'}`}
        </MenuItem>
        <MenuItem
          data-test-id="attachment-download-button"
          onClick={() => {
            handleCloseDownloadMenu(id);
            handleDownload(id);
          }}
          showDivider={true}
        >
          Download
        </MenuItem>
        <MenuItem
          data-test-id="attachment-rename-button"
          onClick={() => {
            setIsEditable(id, true);
            handleCloseDownloadMenu(id);
          }}
          showDivider={true}
        >
          Rename
        </MenuItem>
        <MenuItem
          data-test-id="attachment-delete-button"
          onClick={() => {
            displayDeleteDialog(id, true);
            handleCloseDownloadMenu(id);
          }}
          showDivider={true}
          last={true}
          color="error"
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

NodeAttachmentDownload.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
  saveTitle: PropTypes.func,
  revertTitle: PropTypes.func,
  isEditable: PropTypes.bool,
  setIsEditable: PropTypes.func,
  titleError: PropTypes.string,
  isTitleSaving: PropTypes.bool,
  hasPreview: PropTypes.bool,
  thumbnailBlobURL: PropTypes.string,
  fileExtension: PropTypes.string,
  fileSize: PropTypes.string,
  progress: PropTypes.string,
  isDownloading: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isDeleteDialogVisible: PropTypes.bool,
  displayDeleteDialog: PropTypes.func,
  performDelete: PropTypes.func,
  handleAbort: PropTypes.func,
  handleDownload: PropTypes.func,
  handlePreview: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  setIsMenuOpen: PropTypes.func,
  handleCloseDownloadMenu: PropTypes.func,
  anchorEl: PropTypes.any,
  setMenuAnchorEl: PropTypes.func,
};

export default NodeAttachmentDownload;
