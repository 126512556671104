function selectTypeLocationGraphParentIdsOfTypeById(DM, typeId) {
  const {
    typeLocationGraph,
  } = DM;

  const locationParentIds = [];

  for (let locationParentId of Object.keys(typeLocationGraph)) {
    if (typeLocationGraph[locationParentId].includes(typeId)) {
      locationParentIds.push(+locationParentId);
    }
  }

  return locationParentIds;
}

export default selectTypeLocationGraphParentIdsOfTypeById;
