import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_recordTypes_RECORDTYPEID_types_TYPEID(authToken, perspectiveId, params) {
  const {
    recordTypeId,
    typeId,
  } = params;

  return await DELETE(
    `/admin/record-types/${recordTypeId}/types/${typeId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
