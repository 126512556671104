import React from 'react';
import {Route, Switch} from 'react-router-dom';
import InboundRecordsByConnectionListRoute from './InboundRecordsByConnectionListRoute';
import InboundRecordRoute from './InboundRecordRoute';

const InboundRecordsByConnectionRoute = ({
  match,
}) => {
  return (
    <>
      <Switch>
        <Route
          exact path={`${match.path}`}
          component={InboundRecordsByConnectionListRoute}
        />
        <Route
          path={`${match.path}/:recordId`}
          component={InboundRecordRoute}
        />
      </Switch>
    </>
  );
};

export default InboundRecordsByConnectionRoute;
