import React from 'react';
import styled from '@emotion/styled';
import RecordList from 'components/RecordList';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import ConnectionsContext from './context';
import genInboundRecordsByConnectionId from 'services/Records/genInboundRecordsByConnectionId';

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const InboundRecordsByConnectionListRoute = ({
  match,
}) => {
  const {
    authToken,
    currentPerspectiveId,
    dispatchUserInterfaceAction,
    filter,
    pushAndClearFilter,
  } = React.useContext(ConnectionsContext);
  const [status, setStatus] = React.useState('fetching data');
  const [fatalError, setFatalError] = React.useState(null);
  const [records, setRecords] = React.useState(null);

  React.useEffect(() => {
    async function initialize() {
      try {
        const records = await genInboundRecordsByConnectionId(authToken, currentPerspectiveId, match.params.connectionId);
        setRecords(records);
        setStatus('ready');
      } catch(error) {
        setFatalError(error);
      }
    }

    initialize();
  }, [authToken, currentPerspectiveId, match.params.connectionId]);

  if (fatalError) {
    throw fatalError;
  }

  if (status !== 'ready') {
    return (
      <ListSkeleton/>
    );
  }

  if (records.length === 0) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
          No information has been shared with you by the other party. When you receive access to external Jules data, the shared records will appear here.
        </Typography>
      </EmptyList>
    );
  }

  const renderedRecords = records.filter(record => {
    if (!filter) {
      return true;
    }
    const {
      name,
    } = record;
    return (
      name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  });

  return (
    <div>
      <RecordList
        records={renderedRecords}
        onRecordAction={(recordId, action, recordName, recordPermissions) => {
          switch(action) {
            case 'expand': {
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_RECORD_ID',
                payload: recordId,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_RECORD_PANEL',
                payload: true,
              });
              break;
            }
            case 'share': {
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_SHARE_RECORD_WIZARD',
                payload: true,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHARED_RECORD_ID',
                payload: recordId,
              });
              break;
            }
            case 'navigate': {
              pushAndClearFilter(`${match.url}/${recordId}`);
              break;
            }
            default:
              break;
          }
        }}
      />
    </div>
  );
};

export default InboundRecordsByConnectionListRoute;
