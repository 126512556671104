import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../verbs';

export default async function DEL__nodes_ID(params) {
  const {
    authToken,
    currentPerspectiveId,
    id,
  } = params;

  return await DELETE(
    `/nodes/${id}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, currentPerspectiveId),
  );
}
