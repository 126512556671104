import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {POST} from '../verbs';

export default async function POST__backup(authToken) {
  return await POST(
    '/backup',
    configWithAuthorization(defaultConfig(), authToken),
  );
}
