import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui-library/components/Button';

const ConversationPostButton = ({
  onClick,
  disabled,
}) => {
  return (
    <Button
      variant="primary"
      disabled={disabled}
      onClick={onClick}
    >
      Post
    </Button>
  );
};

ConversationPostButton.propTypes = {
  onClick: PropTypes.func,
};

export default ConversationPostButton;
