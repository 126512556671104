import endpointURL from '../util/endpointURL';

export default async function attachments(authToken, perspectiveId, uploadRequest, params) {
  const {
    nodeId,
    title,
    file,
  } = params;

  const formData = new FormData();

  formData.append('nodeId', nodeId);
  formData.append('title', title);
  formData.append('file', file);

  uploadRequest.open('POST', endpointURL('/attachments'));

  uploadRequest.setRequestHeader('Authorization', authToken);
  uploadRequest.setRequestHeader('Perspective', perspectiveId);

  return await uploadRequest.send(formData);
}
