import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import {
  name,
} from 'utils/validator';

import {
  TYPE_STEP,
  RECORD_STEP,
} from 'constants/addItem.js';

const Description = styled.div`
  margin-bottom: 18px;
`;

const ItemNicknameStep = ({
  active,
  itemTypeName,
  itemRecordTypeName,
  onSetItemNickname,
  navigateToStep,
}) => {
  const [itemNickname, setItemNickname] = React.useState('');
  const [isPristine, setIsPristine] = React.useState(true);

  React.useEffect(() => {
    setItemNickname('');
    setIsPristine(true);
  }, [itemTypeName]);

  if (!active) {
    return null;
  }

  const errorMessage = !isPristine && name(itemNickname);

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Item
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="nickname-step-type-breadcrumb"
            textTransform="capitalize"
            onClick={() => navigateToStep(TYPE_STEP)}>
            {itemTypeName}
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="item-details-step-assign-to-record-breadcrumb"
            onClick={() => navigateToStep(RECORD_STEP)}>
            {itemRecordTypeName}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Name
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Nicknames are used to differentiate similar items. Assign a nickname that best identifies this item.
        </WizardStepDescription>
      </WizardStepHead>

      <WizardContentColumn>
        <form
          onSubmit={event => {
            event.preventDefault();
            if (!itemNickname) {
              return;
            }
            onSetItemNickname(itemNickname);
          }}
        >
          <WizardStepContent>
            <Description>
              <Typography variant="lead">
                Add a nickname for your&nbsp;
                <span style={{textTransform: 'capitalize'}}>
                  <Typography variant="lead" fontWeight="bold">
                    {itemTypeName}&hellip;
                  </Typography>
                </span>
              </Typography>
            </Description>

            <TextField
              data-test-id="nickname-input"
              autoFocus
              value={itemNickname}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
              onChange={event => {
                setItemNickname(event.target.value);
                setIsPristine(false);
              }}
            />

          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              data-test-id="nickname-step-continue-button"
              variant="primary"
              disabled={!Boolean(itemNickname) || Boolean(errorMessage)}>
              Continue &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ItemNicknameStep.propTypes = {
  onSetItemNickname: PropTypes.func.isRequired,
};

export default ItemNicknameStep;
