function selectVirtualLocationTreeNodeHints(DM) {
  const {
    virtualLocationTreeNodeHintsById,
    virtualLocationTreeNodeHintIds,
  } = DM;

  return (
    virtualLocationTreeNodeHintIds
      .map(virtualLocationTreeNodeHintId => virtualLocationTreeNodeHintsById[virtualLocationTreeNodeHintId])
  );
}

export default selectVirtualLocationTreeNodeHints;
