import React from 'react';
import Tabs from 'ui-library/components/Tabs';
import {Route, Switch, Redirect} from 'react-router-dom';
import ConnectionsContext from './context';
import InboundRecordsByConnectionRoute from './InboundRecordsByConnectionRoute';
import OutboundRecordsByConnectionRoute from './OutboundRecordsByConnectionRoute';

const ConnectionRoute = ({
  match,
}) => {
  const {
    pushAndClearFilter,
  } = React.useContext(ConnectionsContext);
  return (
    <>
      {
        [
          `${match.url}/inbound-records`,
          `${match.url}/outbound-records`,
        ].includes(window.location.pathname) &&
          <>
            <Tabs
              options={[
                {
                  value: 'inbound',
                  label: 'External records',
                },
                {
                  value: 'outbound',
                  label: 'Shared records',
                },
              ]}
              value={(() => {
                switch(window.location.pathname) {
                  case `${match.url}/inbound-records`: {
                    return 'inbound';
                  }
                  case `${match.url}/outbound-records`: {
                    return 'outbound';
                  }
                  default:
                    break;
                }
              })()}
              onChange={(event, newValue) => {
                pushAndClearFilter((() => {
                  switch(newValue) {
                    case 'inbound': {
                      return `${match.url}/inbound-records`;
                    }
                    case 'outbound': {
                      return `${match.url}/outbound-records`;
                    }
                    default:
                      break;
                  }
                })());
              }}
            />
          </>
      }
      <Switch>
        <Route
          path={`${match.path}/inbound-records`}
          component={InboundRecordsByConnectionRoute}
        />
        <Route
          path={`${match.path}/outbound-records`}
          component={OutboundRecordsByConnectionRoute}
        />
        <Redirect
          to={`${match.path}/inbound-records`}
        />
      </Switch>
    </>
  );
};

export default ConnectionRoute;
