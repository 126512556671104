import React from 'react';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';
import Message from 'ui-library/components/Message';

import WarningIcon from '@material-ui/icons/WarningRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import UserInterfaceContext from 'contexts/UserInterface';

import deleteConnection from 'services/Connections/deleteConnection';

const DeleteConnectionDialog = () => {
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const {
    showDeleteConnectionDialog,
    isDeletingConnection,
    expandedConnectionId,
    deleteConnectionId,

    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const handleDeleteConnection = async() => {
    try {
      dispatchUserInterfaceAction({
        type: 'SET_IS_DELETING_CONNECTION',
        payload: true,
      });
      await deleteConnection({authToken, currentPerspectiveId, connectionId: deleteConnectionId});
      if (expandedConnectionId !== null) {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_CONNECTION_ID',
          payload: null,
        });
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_CONNECTION_LOADING_STATE',
          payload: 'not loaded',
        });
      }
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_DELETE_CONNECTION_DIALOG',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_IS_DELETING_CONNECTION',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_CONNECTION_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: deleteConnectionId,
      });
      setShowSuccessMessage(true);
    } catch(error) {
      const {
        name,
      } = error;
      switch (name) {
        case 'AuthorizationError':
          user.setAuthToken(null);
          user.setAuthenticationStatus('not-authenticated');
          localStorage.removeItem('authToken');
          break;
        default:
          setFatalError(error);
      }
    }
  };

  return (
    <>
      <ModalCard
        open={showDeleteConnectionDialog}
        handleClose={() => {
          dispatchUserInterfaceAction({
            type: 'SET_SHOW_DELETE_CONNECTION_DIALOG',
            payload: false,
          });
        }}
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick={true}
      >
        <ModalCardHeader color="error">
          <ModalCardHeaderIcon color="error">
            <WarningIcon
              fontSize="inherit"
            />
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
            Delete Connection
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent>
          <Typography variant="body">
            Are you sure you want to delete this connection?
          </Typography>
          <br/><br/>
          <Typography variant="caption">
            You will not have access to any of the information that was shared through this connection.
          </Typography>
        </ModalCardContent>
        <ModalCardActions>
          <Button
            data-test-id="delete-connection-cancel-button"
            variant="text"
            onClick={() => {
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_DELETE_CONNECTION_DIALOG',
                payload: false,
              });
              if (expandedConnectionId) {
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_CONNECTION_PANEL',
                  payload: true,
                });
              }
            }}
            disabled={isDeletingConnection}
          >
            CANCEL
          </Button>
          <Button
            data-test-id="delete-connection-delete-button"
            variant="custom"
            textTransform="none"
            customBgColor="error"
            customHoverBgColor="error-dark"
            customTextColor="white"
            customHoverTextColor="white"
            customActiveBgColor="error-darker"
            customActiveTextColor="white"
            startIcon={isDeletingConnection ? <CircularProgress size={24} color="white"/> : <DeleteIcon />}
            disabled={isDeletingConnection}
            onClick={handleDeleteConnection}
          >
            DELETE CONNECTION
          </Button>
        </ModalCardActions>
      </ModalCard>

      <Message
        variant="success"
        open={showSuccessMessage}
        onClose={() => {
          setShowSuccessMessage(false);
        }}
        messageTitle="Connection deleted successfully"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    </>
  );
};

export default DeleteConnectionDialog;
