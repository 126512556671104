class BadRequestError extends Error {
  constructor(responseStatus, errorBody) {
    super('Could not process the request!');

    this.name = 'BadRequestError';
    this.body = errorBody;
    this.responseStatus = responseStatus;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, BadRequestError);
    }
  }
}

export default BadRequestError;
