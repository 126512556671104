const tagValidator = (
  value,
  errorMessage = 'Please enter a valid tag!',
) => {

  const allowedCharacters = new RegExp(/^[a-zA-Z0-9-]{1,32}$/);

  if (!allowedCharacters.test(value)) {
    return errorMessage;
  }

  return '';
};

export default tagValidator;
