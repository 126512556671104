import React from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import styled from '@emotion/styled';
import ContextualPanel from 'components/chrome/ContextualPanel';
import ContextualPanelNavigation from 'components/chrome/ContextualPanelNavigation';
import ContextualPanelBackButton from 'components/chrome/ContextualPanelBackButton';
import ContextualPanelCloseButton from 'components/chrome/ContextualPanelCloseButton';
import ContextualPanelHeader from 'components/chrome/ContextualPanelHeader';
import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import ContextualPanelTabs from 'components/chrome/ContextualPanelTabs';
import ContextualPanelTab from 'components/chrome/ContextualPanelTab';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import CircularProgress from 'ui-library/components/CircularProgress';
import AccountPanelSkeleton from './AccountPanelSkeleton';
import Grid from '@material-ui/core/Grid';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import Field from 'ui-library/components/Field';
import Typography from 'ui-library/components/Typography';
import AddCardDialog from 'components/AddCardDialog';
import RemoveCardDialog from 'components/RemoveCardDialog';
import PaymentDialog from 'components/PaymentDialog';
import UpdateCardDialog from 'components/UpdateCardDialog';
import UpgradeMembershipDialog from 'components/UpgradeMembershipDialog';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Button from 'ui-library/components/Button';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleSwitch from 'ui-library/components/ToggleSwitch';
import Message from 'ui-library/components/Message';
import InlineMessage from 'ui-library/components/InlineMessage';
import LocationWarning from 'ui-library/components/LocationWarning';

import {
  initAccountState,
  accountReducer,
} from 'reducers/AccountPanel';

import UserContext from 'contexts/User';
import SubscriptionContext from 'contexts/Subscription';
import PerspectiveContext from 'contexts/Perspective';

import genUser from 'services/User/genUser';
import updateFirstName from 'services/User/updateFirstName';
import updateLastName from 'services/User/updateLastName';
import updatePhoneNumber from 'services/User/updatePhoneNumber';
import genPaymentHistory from 'services/Subscription/genPaymentHistory';
import genPaymentMethod from 'services/Subscription/genPaymentMethod';
import deletePaymentMethod from 'services/Subscription/deletePaymentMethod';
import setPaymentMethod from 'services/Subscription/setPaymentMethod';
import renewCurrentSubscription from 'services/Subscription/renewCurrentSubscription';
import modifyCurrentSubscriptionAutoRenewal from 'services/Subscription/modifyCurrentSubscriptionAutoRenewal';
import genPaymentInvoice from 'services/Subscription/genPaymentInvoice';
import genSubscriptionUpgrades from 'services/Subscription/genSubscriptionUpgrades';
import purchaseNewSubscription from 'services/Subscription/purchaseNewSubscription';
import genBackupMetadata from 'services/Backup/genBackupMetadata';
import genNewBackup from 'services/Backup/genNewBackup';
import downloadBackup from 'services/Backup/downloadBackup';

import {
  greyLight,
} from 'ui-library/color-palette';

import {
  userFirstName as firstNameValidator,
  userLastName as lastNameValidator,
  phoneNumber as phoneNumberValidator,
} from 'utils/validator';

import prettyFileSize from 'utils/prettyFileSize';
import getDateWithTimezoneOffset from 'utils/getDateWithTimezoneOffset';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const MessageContainer = styled.div`
  padding: 28px 0 12px 0;
`;

const AccountPanel = ({
  isPanelOpen,
  setIsPanelOpen,
  onCollapse,
}) => {
  const stripe = useStripe();
  const elements = useElements();


  // 'loading' | 'loaded'
  const [accountLoadingState, setAccountLoadingState] = React.useState('loading');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [hoveredTab, setHoveredTab] = React.useState(null);

  const [isDeletePaymentDialogOpen, setIsDeletePaymentDialogOpen] = React.useState(false);
  const [isDeletingPayment, setIsDeletingPayment] = React.useState(false);

  const [isAddPaymentDialogOpen, setIsAddPaymentDialogOpen] = React.useState(false);
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = React.useState(false);

  const [isMakePaymentDialogOpen, setIsMakePaymentDialogOpen] = React.useState(false);
  const [isMakingPayment, setIsMakingPayment] = React.useState(false);

  const [isUpdatePaymentMethodDialogOpen, setIsUpdatePaymentMethodDialogOpen] = React.useState(false);
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = React.useState(false);

  const [isUpgradeMembershipDialogOpen, setIsUpgradeMembershipDialogOpen] = React.useState(false);
  const [isUpgradingMembership, setIsUpgradingMembership] = React.useState(false);
  const [subscriptionUpgradePlan, setSubscriptionUpgradePlan] = React.useState(null);

  const [isRenewingAutomatically, setIsRenewingAutomatically] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState('');

  const [isCreditCardExpired, setIsCreditCardExpired] = React.useState(false);

  const [backupFilename, setBackupFilename] = React.useState(null);
  const [backupSize, setBackupSize] = React.useState(null);
  const [backupStatus, setBackupStatus] = React.useState(null);
  const [isBackupAvailable, setIsBackupAvailable] = React.useState(false);
  const [backupDownloadProgress, setBackupDownloadProgress] = React.useState(null);
  const [backupGenerationLimitReached, setBackupGenerationLimitReached] = React.useState(false);
  const [backupDownloadLimitReached, setBackupDownloadLimitReached] = React.useState(false);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      type: currentPerspectiveType,
      id: currentPerspectiveId,
      name: currentPerspectiveName,
    },
  } = perspective;

  const subscription = React.useContext(SubscriptionContext);
  const {
    currentSubscriptionStatus,
    currentSubscriptionName,
    currentSubscriptionDescription,
    currentSubscriptionIsFreeTrial,
    currentSubscriptionPrice,
    currentSubscriptionIsRenewable,
    currentSubscriptionAutoRenew,
    currentSubscriptionAutoRenewDate,
    currentSubscriptionCycleDurationInYears,
    currentSubscriptionRemainingDays,

    paymentInvoicesById,
    paymentInvoiceIds,

    creditCardId,
    creditCardBrand,
    creditCardLast4,
    creditCardExpirationDate,

    dispatchSubscriptionAction,
  } = subscription;

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw(fatalError);
  }

  const [accountState, dispatchAccountAction] = React.useReducer(
    accountReducer,
    null,
    initAccountState,
  );

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [messageType, setMessageType] = React.useState({
    messageTitle: '',
    messageBody: '',
    messageVariant: 'success',
  });

  const formatCurrentSubscriptionAutoRenewDate = (apiDateString) => {
    if (!apiDateString) {
      return '';
    };
    const segments = new Date(apiDateString).toUTCString().split(' ');
    return [segments[2], segments[1], segments[3]].join(' ').toUpperCase();
  };

  const {
    firstName: {
      value: firstNameValue,
      previousValue: firstNamePreviousValue,
      isFocused: firstNameIsFocused,
      isSaving: firstNameIsSaving,
      isEditable: firstNameIsEditable,
      errorMessage: firstNameErrorMessage,
    },
    lastName: {
      value: lastNameValue,
      previousValue: lastNamePreviousValue,
      isFocused: lastNameIsFocused,
      isSaving: lastNameIsSaving,
      isEditable: lastNameIsEditable,
      errorMessage: lastNameErrorMessage,
    },
    phoneNumber: {
      value: phoneNumberValue,
      previousValue: phoneNumberPreviousValue,
      isFocused: phoneNumberIsFocused,
      isSaving: phoneNumberIsSaving,
      isEditable: phoneNumberIsEditable,
      errorMessage: phoneNumberErrorMessage,
    },
  } = accountState;

  const fieldsHaveChanges = firstNameValue !== firstNamePreviousValue || lastNameValue !== lastNamePreviousValue || phoneNumberValue !== phoneNumberPreviousValue;

  const generateBackupMetadata = React.useCallback(async() => {
    const backupMetadata = await genBackupMetadata(authToken);
    const {
      file,
      inProgress,
    } = backupMetadata;

    if (!file) {
      if (inProgress) {
        setBackupSize(null);
        setIsBackupAvailable(false);
        setBackupStatus('Pending ...');
      } else {
        setBackupSize(null);
        setIsBackupAvailable(false);
        setBackupStatus(null);
      }
    }

    if (file) {
      const {
        dateTime,
        filename,
        filesize,
      } = file;

      setBackupFilename(filename);

      if (inProgress) {
        setBackupSize(null);
        setIsBackupAvailable(false);
        setBackupStatus('Pending ...');
      } else {
        setBackupSize(filesize);
        setIsBackupAvailable(true);
        setBackupStatus(`${getDateWithTimezoneOffset(dateTime).toLocaleString('en-US')}`);
      }
    }
  }, [authToken]);

  React.useEffect(() => {
    let interval;
    if (backupStatus === 'Pending ...' && !interval) {
      interval = setInterval(() => {
        generateBackupMetadata();
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [authToken, backupStatus, generateBackupMetadata]);

  React.useEffect(() => {
    const loadUserInfo = async() => {
      try {
        setAccountLoadingState('loading');

        // BACKUP INFO
        await generateBackupMetadata();

        // USER INFO
        const userInfo = await genUser(authToken);

        const {
          firstName,
          lastName,
          phoneNumber,
        } = userInfo;

        dispatchAccountAction({
          type: 'HYDRATE_ACCOUNT',
          payload: {
            firstName: {
              value: firstName,
              previousValue: firstName,
              errorMessage: '',
              isSaving: false,
              isFocused: false,
              isEditable: false,
            },
            lastName: {
              value: lastName,
              previousValue: lastName,
              errorMessage: '',
              isSaving: false,
              isFocused: false,
              isEditable: false,
            },
            phoneNumber: {
              value: phoneNumber,
              previousValue: phoneNumber,
              errorMessage: '',
              isSaving: false,
              isFocused: false,
              isEditable: false,
            },
          },
        });

        if (!currentSubscriptionIsFreeTrial) {
          const paymentHistory = await genPaymentHistory(authToken, currentPerspectiveId);
          dispatchSubscriptionAction({
            type: 'HYDRATE_PAYMENT_HISTORY',
            payload: paymentHistory,
          });


          const paymentMethod = await genPaymentMethod(authToken, currentPerspectiveId);
          dispatchSubscriptionAction({
            type: 'HYDRATE_CREDIT_CARD',
            payload: paymentMethod,
          });
        }

        if (currentSubscriptionIsFreeTrial) {
          const subscriptionUpgrades = await genSubscriptionUpgrades(authToken, currentPerspectiveId);
          setSubscriptionUpgradePlan(subscriptionUpgrades[0]);
        }

        setAccountLoadingState('loaded');
      } catch(error) {
        setError(error);
      }
    };

    if (isPanelOpen && accountLoadingState !== 'loaded') {
      loadUserInfo();
    }
  }, [accountLoadingState, authToken, currentPerspectiveId, currentSubscriptionAutoRenew, currentSubscriptionIsFreeTrial, dispatchSubscriptionAction, generateBackupMetadata, isPanelOpen]);

  React.useEffect(() => {
    if (creditCardExpirationDate) {
      const now = new Date();
      const [month, year] = creditCardExpirationDate.split('/');

      setIsCreditCardExpired(now.getTime() > new Date(parseInt(`20${year}`), parseInt(month), 0).getTime());
    }
  }, [creditCardExpirationDate]);

  // save node field vlue
  const saveField = async({
    value,
    previousValue,
    fieldName,
    errorMessage,
  }) => {

    dispatchAccountAction({
      type: 'SET_FIELD_IS_EDITABLE',
      payload: {
        fieldName,
        isEditable: false,
      },
    });

    try {
      if (value !== previousValue && !Boolean(errorMessage)) {
        dispatchAccountAction({
          type: 'SET_FIELD_IS_SAVING',
          payload: {
            fieldName,
            isSaving: true,
          },
        });

        switch (fieldName) {
          case 'firstName':
            await updateFirstName({authToken, firstName: value});
            break;
          case 'lastName':
            await updateLastName({authToken, lastName: value});
            break;
          case 'phoneNumber':
            await updatePhoneNumber({authToken, phoneNumber: value});
            break;
          default:
            setError(new Error(`Unknown field name ${fieldName}`));
        }

        setShowSuccessMessage(true);
        setMessageType({
          messageTitle: 'Success',
          messageBody: 'Account Info updated successfully',
          messageVariant: 'success',
        });

        dispatchAccountAction({
          type: 'SET_FIELD_PREVIOUS_VALUE',
          payload: {
            fieldName,
            previousValue: value,
          },
        });
        dispatchAccountAction({
          type: 'SET_FIELD_IS_SAVING',
          payload: {
            fieldName,
            isSaving: false,
          },
        });
        dispatchAccountAction({
          type: 'SET_FIELD_IS_FOCUSED',
          payload: {
            fieldName,
            isFocused: false,
          },
        });
        dispatchAccountAction({
          type: 'SET_FIELD_IS_EDITABLE',
          payload: {
            fieldName,
            isEditable: false,
          },
        });
      } else {
        dispatchAccountAction({
          type: 'SET_FIELD_IS_FOCUSED',
          payload: {
            fieldName,
            isFocused: false,
          },
        });
      }
    } catch(error) {
      setError(error);
    }
  };

  const revertField = ({
    fieldName,
    previousValue,
  }) => {
    dispatchAccountAction({
      type: 'SET_FIELD_VALUE',
      payload: {
        fieldName,
        value: previousValue,
      },
    });
    dispatchAccountAction({
      type: 'SET_FIELD_ERROR_MESSAGE',
      payload: {
        fieldName,
        errorMessage: '',
      },
    });
    dispatchAccountAction({
      type: 'SET_FIELD_IS_EDITABLE',
      payload: {
        fieldName,
        isEditable: false,
      },
    });
    dispatchAccountAction({
      type: 'SET_FIELD_IS_FOCUSED',
      payload: {
        fieldName,
        isFocused: false,
      },
    });
    dispatchAccountAction({
      type: 'SET_FIELD_IS_SAVING',
      payload: {
        fieldName,
        isSaving: false,
      },
    });
  };


  const handleDeletePaymentMethod = async() => {
    try {
      setIsDeletingPayment(true);
      await deletePaymentMethod(authToken, currentPerspectiveId);
      dispatchSubscriptionAction({
        type: 'HYDRATE_CREDIT_CARD',
        payload: null,
      });
      setIsDeletingPayment(false);
      setIsDeletePaymentDialogOpen(false);
      setIsPanelOpen(true);
      setShowSuccessMessage(true);
      setMessageType({
        messageTitle: 'Success',
        messageBody: 'Payment method deleted successfully',
        messageVariant: 'success',
      });
    } catch(error) {
      setError(error);
    }
  };

  const handleAddPaymentMethod = async(event) => {
    try {
      setIsAddingPaymentMethod(true);
      event.preventDefault();
      const cardElement = elements.getElement(CardElement);
      const {token, error} = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
        setIsAddingPaymentMethod(false);
      } else {
        const setPaymentMethodResponse = await setPaymentMethod(authToken, currentPerspectiveId, {stripePayload: token});
        dispatchSubscriptionAction({
          type: 'HYDRATE_CREDIT_CARD',
          payload: setPaymentMethodResponse,
        });
        setIsAddingPaymentMethod(false);
        setIsAddPaymentDialogOpen(false);
        setIsPanelOpen(true);
        setShowSuccessMessage(true);
        setMessageType({
          messageTitle: 'Success',
          messageBody: 'Payment method added successfully',
          messageVariant: 'success',
        });
      }
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleMakePayment = async() => {
    try {
      setIsMakingPayment(true);
      const renewCurrentSubscriptionResponse = await renewCurrentSubscription(authToken, currentPerspectiveId);
      dispatchSubscriptionAction({
        type: 'HYDRATE_CURRENT_SUBSCRIPTION',
        payload: {
          ...renewCurrentSubscriptionResponse,
        },
      });

      const paymentHistoryResponse = await genPaymentHistory(authToken, currentPerspectiveId);
      dispatchSubscriptionAction({
        type: 'HYDRATE_PAYMENT_HISTORY',
        payload: paymentHistoryResponse,
      });

      setIsMakingPayment(false);
      setIsMakePaymentDialogOpen(false);
      setIsPanelOpen(true);
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsMakingPayment(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsMakingPayment(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsMakingPayment(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleUpdatePaymentMethod = async(event) => {
    try {
      setIsUpdatingPaymentMethod(true);
      event.preventDefault();
      const cardElement = elements.getElement(CardElement);
      const {token, error} = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
        setIsUpdatingPaymentMethod(false);
      } else {
        const setPaymentMethodResponse = await setPaymentMethod(authToken, currentPerspectiveId, {stripePayload: token});
        dispatchSubscriptionAction({
          type: 'HYDRATE_CREDIT_CARD',
          payload: setPaymentMethodResponse,
        });
        setIsUpdatingPaymentMethod(false);
        setIsUpdatePaymentMethodDialogOpen(false);
        setIsPanelOpen(true);
      }
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsUpdatingPaymentMethod(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsUpdatingPaymentMethod(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsUpdatingPaymentMethod(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleUpgradeMembership = async(event) => {
    try {
      setIsPanelOpen(false);
      setIsAddingPaymentMethod(true);
      setIsUpgradingMembership(true);
      event.preventDefault();
      const cardElement = elements.getElement(CardElement);
      const {token, error} = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
        setIsAddingPaymentMethod(false);
        setIsUpgradingMembership(false);
      } else {

        const setPaymentMethodResponse = await setPaymentMethod(authToken, currentPerspectiveId, {stripePayload: token});

        dispatchSubscriptionAction({
          type: 'HYDRATE_CREDIT_CARD',
          payload: setPaymentMethodResponse,
        });

        const purchaseNewSubscriptionResponse = await purchaseNewSubscription(authToken, currentPerspectiveId, {subscriptionPlanId: subscriptionUpgradePlan.id});

        dispatchSubscriptionAction({
          type: 'HYDRATE_CURRENT_SUBSCRIPTION',
          payload: purchaseNewSubscriptionResponse,
        });

        const paymentHistoryResponse = await genPaymentHistory(authToken, currentPerspectiveId);
        dispatchSubscriptionAction({
          type: 'HYDRATE_PAYMENT_HISTORY',
          payload: paymentHistoryResponse,
        });

        setIsAddingPaymentMethod(false);
        setIsUpgradingMembership(false);
        setIsUpgradeMembershipDialogOpen(false);
        setIsPanelOpen(true);
      }
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          setIsUpgradingMembership(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          setIsUpgradingMembership(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          setIsUpgradingMembership(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleRenewAutomatically = async() => {
    try {
      setIsRenewingAutomatically(true);
      await modifyCurrentSubscriptionAutoRenewal(authToken, currentPerspectiveId, {autoRenew: !currentSubscriptionAutoRenew});
      dispatchSubscriptionAction({
        type: 'SET_CURRENT_SUBSCRIPTION_AUTORENEW',
        payload: !currentSubscriptionAutoRenew,
      });
      setIsRenewingAutomatically(false);
    } catch(error) {
      setError(error);
    }
  };

  const closePanel = () => {
    if (fieldsHaveChanges) {
      if (window.confirm('There are modified fields that have not been saved. Are you sure you want to continue?')) {
        dispatchAccountAction({
          type: 'RESET_ACCOUNT',
        });
        onCollapse();
      }
    } else {
      dispatchAccountAction({
        type: 'RESET_ACCOUNT',
      });
      setBackupGenerationLimitReached(false);
      setBackupDownloadLimitReached(false);
      setSelectedTab(0);
      onCollapse();
    }
    setAccountLoadingState('loading');
  };

  const generateBackup = async() => {
    try {

      await genNewBackup(authToken);
      setBackupStatus('Pending ...');

    } catch (error) {
      const {
        body: {
          code,
        },
      } = error;

      switch (code) {
        case 1035: {
          setBackupGenerationLimitReached(true);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleDownloadBackup = async() => {
    const downloadRequest = new XMLHttpRequest();

    downloadRequest.addEventListener('readystatechange', () => {
      if (downloadRequest.readyState === XMLHttpRequest.DONE) {
        const status = downloadRequest.status;

        if (status === 429) {
          setBackupDownloadLimitReached(true);
          setBackupDownloadProgress(null);
          return;
        }

        if (status >= 200 && status < 400) {
          const url = URL.createObjectURL(downloadRequest.response);
          const invisibleAnchor = document.createElement('a');
          invisibleAnchor.href = url;
          invisibleAnchor.download = backupFilename;
          document.body.appendChild(invisibleAnchor);
          invisibleAnchor.click();
          document.body.removeChild(invisibleAnchor);
          setBackupDownloadProgress(null);
        }
      }
    });

    downloadRequest.addEventListener('progress', event => {
      const {
        loaded,
      } = event;

      const progress = Math.floor((loaded * 100) / backupSize);
      setBackupDownloadProgress(progress);
    });

    try {
      await downloadBackup(authToken, downloadRequest, {filename: backupFilename});
    } catch(error) {
      setError(error);
    }
  };

  return (
    <ContextualPanel
      expanded={isPanelOpen}
      handleEscKey={() => {
        closePanel();
      }}
      onMouseDown={(ev) => {
        if (firstNameIsFocused === true && !firstNameErrorMessage) {
          saveField({value: firstNameValue, previousValue: firstNamePreviousValue, fieldName: 'firstName', errorMessage: firstNameErrorMessage});
        }

        if (lastNameIsFocused === true && !lastNameErrorMessage) {
          saveField({value: lastNameValue, previousValue: lastNamePreviousValue, fieldName: 'lastName', errorMessage: lastNameErrorMessage});
        }

        if (phoneNumberIsFocused === true && !phoneNumberErrorMessage) {
          saveField({value: phoneNumberValue, previousValue: phoneNumberPreviousValue, fieldName: 'phoneNumber', errorMessage: phoneNumberErrorMessage});
        }
      }}
    >
      <ContextualPanelNavigation
        expanded={isPanelOpen}>
        <ContextualPanelBackButton
          data-test-id="contextual-panel-back-button"
          isVisible={false}
          onClick={() => {}}
        />
        <ContextualPanelCloseButton
          data-test-id="account-contextual-panel-close-button"
          isVisible={true}
          onMouseDown={(ev) => {
            ev.stopPropagation();
          }}
          onClick={() => {
            closePanel();
          }}
        />
      </ContextualPanelNavigation>

      <ContextualPanelHeader
        panelDisplayMode={'default'}
        expanded={isPanelOpen}>
        {
          accountLoadingState === 'loaded' &&
          <Typography
            variant="h3"
            fontWeight="bold"
          >
            My Account
          </Typography>
        }
        {
          accountLoadingState === 'loaded' &&
          <Typography
            variant="caption"
          >
            {
              currentPerspectiveType === 'company' ?
                'From here you can edit your account info.'
                :
                'Manage your account info or membership details.'
            }
          </Typography>
        }
      </ContextualPanelHeader>

      <ContextualPanelContent panelDisplayMode={'default'}>
        {
          (accountLoadingState === 'loading') &&
          <AccountPanelSkeleton>
            <CenterpieceSpinner/>
          </AccountPanelSkeleton>
        }
        {
          (accountLoadingState === 'loaded') &&
          <>
            <ContextualPanelTabs
              selectedTab={selectedTab}
            >
              <ContextualPanelTab
                data-test-id="account-info-tab"
                onMouseEnter={() => {
                  setHoveredTab(0);
                }}
                onMouseLeave={() => {
                  setHoveredTab(null);
                }}
                tabIndex={0}
                selectedTab={selectedTab}
                hoveredTab={hoveredTab}
                setSelectedTab={setSelectedTab}
                label="Account Info"
                icon={<PersonRoundedIcon />}
              />
              <ContextualPanelTab
                data-test-id="subscription-membership-tab"
                onMouseEnter={() => {
                  setHoveredTab(1);
                }}
                onMouseLeave={() => {
                  setHoveredTab(null);
                }}
                tabIndex={1}
                selectedTab={selectedTab}
                hoveredTab={hoveredTab}
                setSelectedTab={setSelectedTab}
                label={currentPerspectiveType === 'company' ? 'Subscription' : 'Membership'}
                icon={<AttachMoneyRoundedIcon />}
              />
              <ContextualPanelTab
                data-test-id="backup_export-tab"
                onMouseEnter={() => {
                  setHoveredTab(2);
                }}
                onMouseLeave={() => {
                  setHoveredTab(null);
                }}
                tabIndex={2}
                selectedTab={selectedTab}
                hoveredTab={hoveredTab}
                setSelectedTab={setSelectedTab}
                label={'Backup & Export'}
                icon={<ArchiveOutlinedIcon />}
              />
            </ContextualPanelTabs>
            {
              selectedTab === 0 &&
              <PaddedContainer>
                <Grid
                  container
                  spacing={3}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    style={{
                      cursor: 'pointer',
                      height: '92px',
                    }}
                  >
                    <Field
                      inlineEdit={true}
                      autoFocus={true}
                      type="name"
                      label='First Name'
                      helperText={firstNameErrorMessage}
                      error={Boolean(firstNameErrorMessage)}
                      isEditable={firstNameIsEditable}
                      isSaving={firstNameIsSaving}
                      value={firstNameValue}
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        if (!firstNameIsEditable && !firstNameIsSaving) {
                          dispatchAccountAction({
                            type: 'SET_FIELD_IS_EDITABLE',
                            payload: {
                              fieldName: 'firstName',
                              isEditable: true,
                            },
                          });
                          dispatchAccountAction({
                            type: 'SET_FIELD_IS_FOCUSED',
                            payload: {
                              fieldName: 'firstName',
                              isFocused: true,
                            },
                          });
                        }
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          if (!firstNameErrorMessage) {
                            dispatchAccountAction({
                              type: 'SET_FIELD_VALUE',
                              payload: {
                                fieldName: 'firstName',
                                value: ev.target.value,
                              },
                            });
                            saveField({
                              value: firstNameValue,
                              previousValue: firstNamePreviousValue,
                              fieldName: 'firstName',
                              errorMessage: firstNameErrorMessage,
                            });
                          }
                        }
                      }}
                      onChange={(ev) => {
                        dispatchAccountAction({
                          type: 'SET_FIELD_VALUE',
                          payload: {
                            fieldName: 'firstName',
                            value: ev.target.value,
                          },
                        });
                        dispatchAccountAction({
                          type: 'SET_FIELD_ERROR_MESSAGE',
                          payload: {
                            fieldName: 'firstName',
                            errorMessage: firstNameValidator(ev.target.value),
                          },
                        });
                      }}
                      onMouseDown={(ev) =>{
                        ev.stopPropagation();
                      }}
                      disabled={false}
                      required={false}
                      revertField={() => {
                        revertField({
                          fieldName: 'firstName',
                          previousValue: firstNamePreviousValue,
                        });
                      }}
                      saveField={() => {
                        saveField({value: firstNameValue, previousValue: firstNamePreviousValue, fieldName: 'firstName', errorMessage: firstNameErrorMessage});
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    style={{
                      cursor: 'pointer',
                      height: '92px',
                    }}
                  >
                    <Field
                      inlineEdit={true}
                      autoFocus={true}
                      type="name"
                      label='Last Name'
                      helperText={lastNameErrorMessage}
                      error={Boolean(lastNameErrorMessage)}
                      isEditable={lastNameIsEditable}
                      isSaving={lastNameIsSaving}
                      value={lastNameValue}
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        if (!lastNameIsEditable && !lastNameIsSaving) {
                          dispatchAccountAction({
                            type: 'SET_FIELD_IS_EDITABLE',
                            payload: {
                              fieldName: 'lastName',
                              isEditable: true,
                            },
                          });
                          dispatchAccountAction({
                            type: 'SET_FIELD_IS_FOCUSED',
                            payload: {
                              fieldName: 'lastName',
                              isFocused: true,
                            },
                          });
                        }
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          if (!lastNameErrorMessage) {
                            dispatchAccountAction({
                              type: 'SET_FIELD_VALUE',
                              payload: {
                                fieldName: 'lastName',
                                value: ev.target.value,
                              },
                            });
                            saveField({
                              value: lastNameValue,
                              previousValue: lastNamePreviousValue,
                              fieldName: 'lastName',
                              errorMessage: lastNameErrorMessage,
                            });
                          }
                        }
                      }}
                      onChange={(ev) => {
                        dispatchAccountAction({
                          type: 'SET_FIELD_VALUE',
                          payload: {
                            fieldName: 'lastName',
                            value: ev.target.value,
                          },
                        });
                        dispatchAccountAction({
                          type: 'SET_FIELD_ERROR_MESSAGE',
                          payload: {
                            fieldName: 'lastName',
                            errorMessage: lastNameValidator(ev.target.value),
                          },
                        });
                      }}
                      onMouseDown={(ev) =>{
                        ev.stopPropagation();
                      }}
                      disabled={false}
                      required={false}
                      revertField={() => {
                        revertField({
                          fieldName: 'lastName',
                          previousValue: lastNamePreviousValue,
                        });
                      }}
                      saveField={() => {
                        saveField({value: lastNameValue, previousValue: lastNamePreviousValue, fieldName: 'lastName', errorMessage: lastNameErrorMessage});
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    style={{
                      cursor: 'pointer',
                      height: '92px',
                    }}
                  >
                    <Field
                      inlineEdit={true}
                      autoFocus={true}
                      type="phone number"
                      label='Phone #'
                      helperText={phoneNumberErrorMessage}
                      error={Boolean(phoneNumberErrorMessage)}
                      isEditable={phoneNumberIsEditable}
                      isSaving={phoneNumberIsSaving}
                      value={phoneNumberValue}
                      onClick={(ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        if (!phoneNumberIsEditable && !phoneNumberIsSaving) {
                          dispatchAccountAction({
                            type: 'SET_FIELD_IS_EDITABLE',
                            payload: {
                              fieldName: 'phoneNumber',
                              isEditable: true,
                            },
                          });
                          dispatchAccountAction({
                            type: 'SET_FIELD_IS_FOCUSED',
                            payload: {
                              fieldName: 'phoneNumber',
                              isFocused: true,
                            },
                          });
                        }
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          if (!phoneNumberErrorMessage) {
                            dispatchAccountAction({
                              type: 'SET_FIELD_VALUE',
                              payload: {
                                fieldName: 'phoneNumber',
                                value: ev.target.value,
                              },
                            });
                            saveField({
                              value: phoneNumberValue,
                              previousValue: phoneNumberPreviousValue,
                              fieldName: 'phoneNumber',
                              errorMessage: phoneNumberErrorMessage,
                            });
                          }
                        }
                      }}
                      onChange={(ev) => {
                        dispatchAccountAction({
                          type: 'SET_FIELD_VALUE',
                          payload: {
                            fieldName: 'phoneNumber',
                            value: ev.target.value,
                          },
                        });
                        dispatchAccountAction({
                          type: 'SET_FIELD_ERROR_MESSAGE',
                          payload: {
                            fieldName: 'phoneNumber',
                            errorMessage: phoneNumberValidator(ev.target.value),
                          },
                        });
                      }}
                      onMouseDown={(ev) =>{
                        ev.stopPropagation();
                      }}
                      disabled={false}
                      required={false}
                      revertField={() => {
                        revertField({
                          fieldName: 'phoneNumber',
                          previousValue: phoneNumberPreviousValue,
                        });
                      }}
                      saveField={() => {
                        saveField({value: phoneNumberValue, previousValue: phoneNumberPreviousValue, fieldName: 'phoneNumber', errorMessage: phoneNumberErrorMessage});
                      }}
                    />
                  </Grid>
                </Grid>
              </PaddedContainer>
            }

            {/* COMPANY SUBSCRIPTION TAB */}
            {
              selectedTab === 1 && currentPerspectiveType === 'company' &&
              <div style={{
                paddingTop: 24,
              }}>
                <PaddedContainer>
                  <Grid
                    container
                    spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                    >
                      <Typography
                        variant="body"
                      >
                        Subscription type:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={9}
                    >
                      <Typography
                        variant="body"
                        fontWeight="bold"
                      >
                        {currentSubscriptionName.toUpperCase()}
                      </Typography>
                      <br/>
                      <Typography
                        variant="caption"
                      >
                        {currentSubscriptionDescription}
                      </Typography>
                    </Grid>
                  </Grid>
                </PaddedContainer>
              </div>
            }

            {/* HOUSEHOLD MEMBERSHIP TAB & FREE TRIAL */}
            {
              selectedTab === 1 && currentPerspectiveType === 'household' &&  currentSubscriptionIsFreeTrial &&
              <div style={{
                paddingTop: 24,
              }}>
                <PaddedContainer>
                  <Grid
                    container
                    spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                    >
                      <Typography
                        variant="body"
                      >
                        Membership type:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={9}
                    >
                      <Typography
                        variant="body"
                        fontWeight="bold"
                      >
                        {currentSubscriptionName.toUpperCase()}
                      </Typography>
                      <br/>
                      <Typography
                        variant="caption"
                      >
                        {currentSubscriptionDescription}
                      </Typography>
                      <MessageContainer>
                        <InlineMessage
                          variant="info"
                          open={true}
                          persistent={true}
                          messageBody={
                            <Typography variant="body" fontWeight="bold">
                              {`Trial Period - ${currentSubscriptionRemainingDays} days remaining`}
                            </Typography>
                          }
                        />
                      </MessageContainer>
                      <Button
                        data-test-id="upgrade-button"
                        variant="icon-outlined"
                        startIcon={<ArrowUpwardRoundedIcon />}
                        onClick={() => {
                          setIsPanelOpen(false);
                          setIsUpgradeMembershipDialogOpen(true);
                        }}
                      >
                        Upgrade
                      </Button>
                    </Grid>
                  </Grid>
                </PaddedContainer>
              </div>
            }

            {/* HOUSEHOLD MEMBERSHIP TAB & NOT FREE TRIAL */}
            {
              selectedTab === 1 && currentPerspectiveType === 'household' && !currentSubscriptionIsFreeTrial &&
              <div style={{
                paddingTop: 24,
              }}>
                <PaddedContainer>
                  <Grid
                    container
                    spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                    >
                      <Typography
                        variant="body"
                      >
                        Membership type:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={9}
                    >
                      <Typography
                        variant="body"
                        fontWeight="bold"
                      >
                        {currentSubscriptionName.toUpperCase()}
                      </Typography>
                      <br/>
                      <Typography
                        variant="caption"
                      >
                        {currentSubscriptionDescription}
                      </Typography>
                    </Grid>
                    {
                      currentSubscriptionStatus === 'expired' &&
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={3}
                        />
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={9}
                        >
                          <Button
                            data-test-id="make-a-one-off-payment-button"
                            variant="text"
                            disabled={creditCardId === null || isCreditCardExpired}
                            onClick={() => {
                              setIsPanelOpen(false);
                              setIsMakePaymentDialogOpen(true);
                            }}
                          >
                            Make a one-off payment
                          </Button>
                        </Grid>
                      </>
                    }
                    {
                      currentSubscriptionAutoRenew &&
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={3}
                        >
                          <Typography
                            variant="body"
                          >
                            Next payment
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={9}
                        >
                          <Typography
                            variant="body"
                            fontWeight="bold"
                          >
                            {formatCurrentSubscriptionAutoRenewDate(currentSubscriptionAutoRenewDate)} / ${currentSubscriptionPrice} / {currentSubscriptionCycleDurationInYears} {currentSubscriptionCycleDurationInYears === 1 ? 'YEAR' : 'YEARS'}
                          </Typography>
                          <br/>
                          <Typography
                            variant="caption"
                          >
                            Please make sure you have a valid credit card added to your account to avoid any interruptions in your membership.
                          </Typography>
                        </Grid>

                      </>
                    }
                    {
                      currentSubscriptionIsRenewable &&
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={3}
                        />
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={9}
                        >
                          <FormControlLabel
                            control={
                              <ToggleSwitch
                                data-test-id="renew-automatically-switch"
                                checked={currentSubscriptionAutoRenew}
                                onChange={handleRenewAutomatically}
                                value={currentSubscriptionAutoRenew}
                                disabled={isRenewingAutomatically}
                              />}
                            label={
                              <Typography
                                variant="body"
                                fontWeight="normal"
                                fontFamily="primary"
                              >
                                {currentSubscriptionAutoRenew ? 'Renew automatically' : 'Renew automatically'}
                              </Typography>
                            }
                          />
                        </Grid>
                      </>
                    }
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                    >
                      <Typography
                        variant="body"
                      >
                        Credit Card:
                      </Typography>
                    </Grid>
                    {
                      creditCardId !== null ?
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={9}
                          >
                            <Typography
                              variant="small"
                              fontWeight="bold"
                            >
                              {creditCardBrand} **{creditCardLast4}
                            </Typography>
                            <br/>
                            <Typography
                              variant="caption"
                              color={isCreditCardExpired ? 'error' : 'black'}
                            >
                              {`Expiration date: ${creditCardExpirationDate}`}
                            </Typography>
                            {
                              isCreditCardExpired &&
                            <Typography
                              variant="caption"
                              fontWeight="bold"
                              color="error"
                            >
                              &nbsp;(card expired)
                            </Typography>
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={3}
                          />
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={9}
                          >
                            <Button
                              data-test-id="change-card-button"
                              variant="outlined"
                              endIcon={<CreditCardRoundedIcon />}
                              onClick={() => {
                                setIsPanelOpen(false);
                                setIsUpdatePaymentMethodDialogOpen(true);
                              }}
                            >
                              Change
                            </Button>
                            <Button
                              data-test-id="delete-card-button"
                              variant="text"
                              textTransform="uppercase"
                              style={{
                                marginLeft: 10,
                              }}
                              onClick={() => {
                                setIsPanelOpen(false);
                                setIsDeletePaymentDialogOpen(true);
                              }}
                            >
                              <Typography
                                variant="caption"
                                fontWeight="bold"
                                fontFamily="primary"
                                color="error"
                              >
                                  Delete
                              </Typography>
                            </Button>
                          </Grid>
                        </>
                        :
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={9}
                        >
                          <Button
                            data-test-id="add-card-button"
                            variant="text"
                            endIcon={<CreditCardRoundedIcon />}
                            onClick={() => {
                              setIsPanelOpen(false);
                              setIsAddPaymentDialogOpen(true);
                            }}
                          >
                            Add
                          </Button>
                        </Grid>
                    }
                  </Grid>
                </PaddedContainer>
              </div>
            }

            {/* BACKUP & EXPORT TAB */}
            {
              selectedTab === 2 &&
              <div style={{
                paddingTop: 24,
              }}>
                <PaddedContainer>
                  <Grid
                    container
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        variant="body"
                      >
                        Generate a backup for<Typography variant="body" fontWeight="bold">&nbsp;all&nbsp;</Typography>your data.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        variant="body"
                      >
                        Once it is generated it will appear here and it can be dowloaded.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        marginBottom: 12,
                      }}
                    >
                      <Typography
                        variant="body"
                      >
                        You can generate 2 backups / day and can download backups twice / day.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Button
                        data-test-id="upgrade-button"
                        variant="icon-primary"
                        startIcon={<ArchiveOutlinedIcon />}
                        onClick={() => {
                          generateBackup();
                        }}
                        disabled={backupStatus === 'Pending ...'}
                      >
                        CREATE BACKUP
                      </Button>
                    </Grid>
                    <br/>
                    <br/>
                    <br/>
                    {
                      isBackupAvailable &&
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: 32,
                        }}
                      >
                        <Typography
                          variant="caption"
                        >
                          Creating another backup will override the current one.
                        </Typography>
                      </Grid>
                    }
                    {
                      backupGenerationLimitReached &&
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: 24,
                        }}
                      >
                        <LocationWarning
                          warning="You’ve reached the “Create Backup” limit. The limit is 2 per day."
                        />
                      </Grid>
                    }
                    {
                      backupDownloadLimitReached &&
                      <Grid
                        item
                        xs={12}
                        style={{
                          marginBottom: 24,
                        }}
                      >
                        <LocationWarning
                          warning="You’ve reached the “Download Backup” limit. The limit is 2 downloads per day."
                        />
                      </Grid>
                    }
                    {
                      (isBackupAvailable || backupStatus) &&
                      <Grid
                        item
                        xs={12}
                      >
                        <ListItem
                          marginTop={'8px'}
                          border={`1px solid ${greyLight}`}
                        >
                          <ListItemContentSegment>
                            <ListItemText>
                              <Typography
                                variant="small"
                                fontWeight="bold"
                              >
                                {currentPerspectiveName} Data Backup
                              </Typography>
                            </ListItemText>
                            <ListItemText>
                              <Typography
                                variant="caption"
                                fontStyle={backupStatus === 'Pending ...' ? 'italic' : 'normal'}
                                color="grey"
                              >
                                  Size: {backupSize ? prettyFileSize(backupSize) : 'Pending ...'}
                              </Typography>
                            </ListItemText>
                          </ListItemContentSegment>
                          <ListItemContentSegment
                            width={300}
                          >
                            <ListItemText>
                              <Typography
                                variant="small"
                                fontStyle={backupStatus === 'Pending ...' ? 'italic' : 'normal'}
                                color={backupStatus === 'Pending ...' ? 'error' : 'black'}
                              >
                                {backupStatus}
                              </Typography>
                            </ListItemText>
                          </ListItemContentSegment>
                          <ListItemContentSegment
                            width={100}
                            justifyContent="flex-end"
                          >
                            <ListItemActions>
                              {
                                !backupDownloadProgress ?
                                  <Button
                                    variant="icon-text"
                                    onClick={(ev) => {
                                      handleDownloadBackup();
                                    }}
                                    disabled={!isBackupAvailable}
                                  >
                                    <GetAppRoundedIcon />
                                  </Button>
                                  :
                                  <>
                                    <div
                                      style={{
                                        marginRight: 10,
                                      }}
                                    >
                                      {backupDownloadProgress}%
                                    </div>
                                    <CircularProgress variant="determinate" value={backupDownloadProgress} color="primary"/>
                                  </>
                              }
                            </ListItemActions>
                          </ListItemContentSegment>
                        </ListItem>
                      </Grid>
                    }
                  </Grid>
                </PaddedContainer>
              </div>
            }
          </>
        }
        {
          accountLoadingState === 'loaded' && selectedTab === 1 && !currentSubscriptionIsFreeTrial && currentPerspectiveType !== 'company' &&
          <ContextualPanelContent panelDisplayMode={'default'}>
            <ContextualPanelTabs
              selectedTab={0}
            >
              <ContextualPanelTab
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                tabIndex={0}
                selectedTab={0}
                hoveredTab={0}
                setSelectedTab={0}
                label="Payment History"
                icon={<ListAltRoundedIcon />}
              />
            </ContextualPanelTabs>
            <PaddedContainer style={{'marginTop': '8px'}}>
              <Grid
                container
                spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                >
                  {
                    paymentInvoiceIds.length === 0 ?
                      <Grid item xs={12} style={{padding: '10px 0px 0px 0px'}}>
                        <Typography
                          variant="small"
                          fontWeight="bold"
                        >
                          There are no invoices available.
                        </Typography>
                      </Grid>
                      :
                      paymentInvoiceIds.map((id, index) => {
                        const {
                          date,
                          amount,
                          details,
                        } = paymentInvoicesById[id];

                        return (
                          <ListItem
                            key={index}
                            marginTop={'8px'}
                            border={`1px solid ${greyLight}`}
                          >
                            <ListItemContentSegment
                              width={120}
                            >
                              <ListItemText>
                                <Typography
                                  variant="caption"
                                >
                                  {date}
                                </Typography>
                              </ListItemText>
                            </ListItemContentSegment>
                            <ListItemContentSegment
                              width={100}
                            >
                              <ListItemText>
                                <Typography
                                  variant="caption"
                                  fontWeight="bold"
                                >
                                    $ {amount}
                                </Typography>
                              </ListItemText>
                            </ListItemContentSegment>
                            <ListItemContentSegment>
                              <ListItemText>
                                <Typography
                                  variant="caption"
                                >
                                  {details}
                                </Typography>
                              </ListItemText>
                            </ListItemContentSegment>
                            <ListItemContentSegment
                              width={100}
                              justifyContent="flex-end"
                            >
                              <ListItemActions>
                                <Button
                                  variant="icon-text"
                                  onClick={(ev) => {
                                    genPaymentInvoice(authToken, currentPerspectiveId, paymentInvoicesById[id]);
                                  }}
                                >
                                  <GetAppRoundedIcon />
                                </Button>
                              </ListItemActions>
                            </ListItemContentSegment>
                          </ListItem>
                        );
                      })
                  }
                </Grid>
              </Grid>
            </PaddedContainer>
          </ContextualPanelContent>
        }
      </ContextualPanelContent>
      <AddCardDialog
        isVisible={isAddPaymentDialogOpen}
        isProcessing={isAddingPaymentMethod}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleClose={() => {
          setErrorMessage('');
          setIsPanelOpen(true);
          setIsAddPaymentDialogOpen(false);
        }}
        handleSubmit={handleAddPaymentMethod}
      />
      <RemoveCardDialog
        isVisible={isDeletePaymentDialogOpen}
        isProcessing={isDeletingPayment}
        handleClose={() => {
          setIsPanelOpen(true);
          setIsDeletePaymentDialogOpen(false);
        }}
        handleSubmit={handleDeletePaymentMethod}
      />
      <PaymentDialog
        price={currentSubscriptionPrice}
        isVisible={isMakePaymentDialogOpen}
        isProcessing={isMakingPayment}
        handleClose={() => {
          setErrorMessage('');
          setIsPanelOpen(true);
          setIsMakePaymentDialogOpen(false);
        }}
        handleSubmit={handleMakePayment}
        errorMessage={errorMessage}
      />
      <UpdateCardDialog
        isVisible={isUpdatePaymentMethodDialogOpen}
        isProcessing={isUpdatingPaymentMethod}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleClose={() => {
          setErrorMessage('');
          setIsPanelOpen(true);
          setIsUpdatePaymentMethodDialogOpen(false);
        }}
        handleSubmit={handleUpdatePaymentMethod}
      />
      <UpgradeMembershipDialog
        isVisible={isUpgradeMembershipDialogOpen}
        isProcessing={isUpgradingMembership}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        handleClose={() => {
          setErrorMessage('');
          setIsPanelOpen(true);
          setIsUpgradeMembershipDialogOpen(false);
        }}
        handleSubmit={handleUpgradeMembership}
        subscriptionUpgradePlan={subscriptionUpgradePlan}
      />

      <Message
        variant={messageType.messageVariant}
        open={showSuccessMessage}
        onClose={() => {
          setShowSuccessMessage(false);
        }}
        messageTitle={messageType.messageTitle}
        messageBody={messageType.messageBody}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    </ContextualPanel>
  );
};

export default AccountPanel;
