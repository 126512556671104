import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {DELETE} from '../verbs';

export default async function DELETE__notifications(authToken) {

  return await DELETE(
    '/notifications',
    configWithAuthorization(defaultConfig(), authToken),
  );
}
