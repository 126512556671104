import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import PropertyConfigurationHintsList from './PropertyConfigurationHintsList';
import VirtualLocationTreeNodeHints from './VirtualLocationTreeNodeHints';

const IntelligentDataFrameworkAdmin = ({
  match,
}) => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path={`${match.url}/property-configuration-hints`}
          component={PropertyConfigurationHintsList}
        />
        <Route
          path={`${match.url}/property-configuration-hints/:id`}
          component={VirtualLocationTreeNodeHints}
        />
        <Redirect
          to={`${match.url}/property-configuration-hints`}
        />
      </Switch>
    </div>
  );
};

export default IntelligentDataFrameworkAdmin;
