import normalizedDataModel from './_normalizeDataModel';
import queries from './queries';

function DataModelReducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'HYDRATE_DATA_MODEL': {
      return {
        ...state,
        ...normalizedDataModel(payload),
      };
    }

    // EHR HUB
    case 'ITEM_ADDED': {
      const {
        typeId,
        displayIndex,
      } = payload;

      const {
        typesById,
        ehrHubTypes,
        ehrHubTypeIds,
        ehrHubTypesById,
      } = state;

      const {
        name: typeName,
      } = typesById[typeId];

      return {
        ...state,
        ehrHubTypes: [
          ...ehrHubTypes,
          {
            displayIndex,
            typeId,
            typeName,
          },
        ],
        ehrHubTypeIds: [
          ...ehrHubTypeIds,
          typeId,
        ],
        ehrHubTypesById: {
          ...ehrHubTypesById,
          [typeId]: {
            displayIndex,
            typeId,
            typeName,
          },
        },
      };
    }

    case 'ITEM_REMOVED': {
      const {
        typeId,
      } = payload;

      const {
        ehrHubTypes,
        ehrHubTypesById,
        ehrHubTypeIds,
      } = state;

      const {
        [typeId]: removed,
        ...remainingEhrHubTypesById
      } = ehrHubTypesById;

      const typeIndex = ehrHubTypes.findIndex(type => type.typeId === typeId);
      const idIndex = ehrHubTypeIds.findIndex(id => id === typeId);

      return {
        ...state,
        ehrHubTypesById: {
          ...remainingEhrHubTypesById,
        },
        ehrHubTypes: [
          ...ehrHubTypes.slice(0, typeIndex),
          ...ehrHubTypes.slice(typeIndex + 1),
        ],
        ehrHubTypeIds: [
          ...ehrHubTypeIds.slice(0,idIndex),
          ...ehrHubTypeIds.slice(idIndex + 1),
        ],
      };
    }

    case 'UPDATE_ITEM_DISPLAY_INDEX': {
      const {
        typeId,
        displayIndex,
      } = payload;

      const {
        ehrHubTypes,
        ehrHubTypesById,
        ehrHubTypesById: {
          [typeId]: selectedEhrHubType,
        },
      } = state;

      const index = ehrHubTypes.findIndex(type => type.typeId === typeId);

      return {
        ...state,
        ehrHubTypes: [
          ...ehrHubTypes.slice(0, index),
          {
            ...selectedEhrHubType,
            displayIndex,
          },
          ...ehrHubTypes.slice(index + 1),
        ],
        ehrHubTypesById: {
          ...ehrHubTypesById,
          [typeId]:{
            ...selectedEhrHubType,
            displayIndex,
          },
        },
      };
    }


    // USERS
    case 'CHANGE_EMAIL': {
      const {
        userId,
        email,
      } = payload;

      const {
        usersById,
        usersById: {
          [userId]: selectedUser,
        },
      } = state;

      return {
        ...state,
        usersById: {
          ...usersById,
          [userId]: {
            ...selectedUser,
            email,
          },
        },
      };
    }


    // TYPES
    case 'ADD_TYPE': {
      return {
        ...state,
        typesById: {
          ...state.typesById,
          [payload.id]: payload,
        },
        typeIds: [
          ...state.typeIds,
          payload.id,
        ],
      };
    }

    case 'UPDATE_TYPE': {
      const {
        typeId,
        isEnabled,
        isPublic,
        name,
        pluralName,
        aliases,
        description,
      } = payload;

      const typePatch = {};

      if (isEnabled !== undefined) {
        typePatch.isEnabled = isEnabled;
      }
      if (isPublic !== undefined) {
        typePatch.isPublic = isPublic;
      }
      if (name !== undefined) {
        typePatch.name = name;
      }
      if (pluralName !== undefined) {
        typePatch.pluralName = pluralName;
      }
      if (description !== undefined) {
        typePatch.description = description;
      }
      if (aliases !== undefined) {
        typePatch.aliases = aliases;
      }

      return {
        ...state,
        typesById: {
          ...state.typesById,
          [typeId]: {
            ...state.typesById[typeId],
            ...typePatch,
          },
        },
      };
    }

    case 'ADD_FIELD_DEFINITION': {
      return {
        ...state,
        fieldDefinitionsById: {
          ...state.fieldDefinitionsById,
          [payload.id]: payload,
        },
        fieldDefinitionIds: [
          ...state.fieldDefinitionIds,
          payload.id,
        ],
      };
    }

    case 'UPDATE_FIELD_DEFINITION': {
      const {
        fieldDefinitionId,
        patch,
      } = payload;

      return {
        ...state,
        fieldDefinitionsById: {
          ...state.fieldDefinitionsById,
          [fieldDefinitionId]: {
            ...state.fieldDefinitionsById[fieldDefinitionId],
            ...patch,
          },
        },
      };
    }

    case 'REMOVE_FIELD_DEFINITION': {
      const fieldDefinitionId = payload;

      const {
        [fieldDefinitionId]: removedfieldDefinition,
        ...remainingfieldDefinitions
      } = state.fieldDefinitionsById;

      return {
        ...state,
        fieldDefinitionsById: {
          ...remainingfieldDefinitions,
        },
        fieldDefinitionIds: [
          ...state.fieldDefinitionIds.slice(0, state.fieldDefinitionIds.indexOf(fieldDefinitionId)),
          ...state.fieldDefinitionIds.slice(state.fieldDefinitionIds.indexOf(fieldDefinitionId) + 1),
        ],
      };
    }

    case 'REORDER_FIELD_DEFINITIONS': {
      const indexPatches = payload;

      const patchedFieldDefinitions = {};

      for (let patch of indexPatches) {
        const {
          id,
          newIndex,
        } = patch;
        patchedFieldDefinitions[id] = {
          ...state.fieldDefinitionsById[id],
          index: newIndex,
        };
      }

      return {
        ...state,
        fieldDefinitionsById: {
          ...state.fieldDefinitionsById,
          ...patchedFieldDefinitions,
        },
      };
    }



    // RECORD TYPES - TYPES
    case 'ADD_TYPE_TO_RECORD_TYPE': {
      const {
        recordTypeId,
        typeId,
      } = payload;

      return {
        ...state,
        recordTypes_Types: {
          ...state.recordTypes_Types,
          [recordTypeId]: [
            ...state.recordTypes_Types[recordTypeId],
            typeId,
          ],
        },
      };
    }

    case 'REMOVE_TYPE_FROM_RECORD_TYPE': {
      const {
        recordTypeId,
        typeId,
      } = payload;

      return {
        ...state,
        recordTypes_Types: {
          ...state.recordTypes_Types,
          [recordTypeId]: [
            ...state.recordTypes_Types[recordTypeId].slice(0, state.recordTypes_Types[recordTypeId].indexOf(typeId)),
            ...state.recordTypes_Types[recordTypeId].slice(state.recordTypes_Types[recordTypeId].indexOf(typeId) + 1),
          ],
        },
      };
    }



    // TYPE CATEGORIES
    case 'SET_TYPE_CATEGORY_NAME': {
      return {
        ...state,
        typeCategoriesById: {
          ...state.typeCategoriesById,
          [payload.typeCategoryId]: {
            ...state.typeCategoriesById[payload.typeCategoryId],
            name: payload.typeCategoryName,
          },
        },
      };
    }



    // TYPE LOCATION GRAPH
    case 'CREATE_TYPE_LOCATION_GRAPH_EDGE': {
      const {
        ancestorId,
        descendantId,
      } = payload;

      return {
        ...state,
        typeLocationGraph: {
          ...state.typeLocationGraph,
          [ancestorId]: [
            ...(state.typeLocationGraph[ancestorId] || []),
            descendantId,
          ],
        },
      };
    }

    case 'DELETE_TYPE_LOCATION_GRAPH_EDGE': {
      const {
        ancestorId,
        descendantId,
      } = payload;

      return {
        ...state,
        typeLocationGraph: {
          ...state.typeLocationGraph,
          [ancestorId]: [
            ...state.typeLocationGraph[ancestorId].slice(0, state.typeLocationGraph[ancestorId].indexOf(descendantId)),
            ...state.typeLocationGraph[ancestorId].slice(state.typeLocationGraph[ancestorId].indexOf(descendantId) + 1),
          ],
        },
      };
    }



    // NODE LINK DEFINITIONS
    case 'ADD_NODE_LINK_DEFINITION': {
      return {
        ...state,
        nodeLinkDefinitionsById: {
          ...state.nodeLinkDefinitionsById,
          [payload.id]: payload,
        },
        nodeLinkDefinitionIds: [
          ...state.nodeLinkDefinitionIds,
          payload.id,
        ],
      };
    }

    case 'UPDATE_NODE_LINK_DEFINITION': {
      const {
        nodeLinkDefinitionId,
        patch,
      } = payload;

      return {
        ...state,
        nodeLinkDefinitionsById: {
          ...state.nodeLinkDefinitionsById,
          [nodeLinkDefinitionId]: {
            ...state.nodeLinkDefinitionsById[nodeLinkDefinitionId],
            ...patch,
          },
        },
      };
    }

    case 'REMOVE_NODE_LINK_DEFINITION': {
      const nodeLinkDefinitionId = payload;

      const {
        [nodeLinkDefinitionId]: removedNodeLinkDefinition,
        ...remainingNodeLinkDefinitions
      } = state.nodeLinkDefinitionsById;

      return {
        ...state,
        nodeLinkDefinitionsById: {
          ...remainingNodeLinkDefinitions,
        },
        nodeLinkDefinitionIds: [
          ...state.nodeLinkDefinitionIds.slice(0, state.nodeLinkDefinitionIds.indexOf(nodeLinkDefinitionId)),
          ...state.nodeLinkDefinitionIds.slice(state.nodeLinkDefinitionIds.indexOf(nodeLinkDefinitionId) + 1),
        ],
      };
    }



    // TYPE RELATIONSHIPS
    case 'ADD_TYPE_RELATIONSHIP': {
      return {
        ...state,
        typeRelationshipsById: {
          ...state.typeRelationshipsById,
          [payload.id]: payload,
        },
        typeRelationshipIds: [
          ...state.typeRelationshipIds,
          payload.id,
        ],
      };
    }

    case 'REMOVE_TYPE_RELATIONSHIP': {
      const typeRelationshipId = payload;

      const {
        [typeRelationshipId]: removedTypeRelationship,
        ...remainingTypeRelationships
      } = state.typeRelationshipsById;

      return {
        ...state,
        typeRelationshipsById: {
          ...remainingTypeRelationships,
        },
        typeRelationshipIds: [
          ...state.typeRelationshipIds.slice(0, state.typeRelationshipIds.indexOf(typeRelationshipId)),
          ...state.typeRelationshipIds.slice(state.typeRelationshipIds.indexOf(typeRelationshipId) + 1),
        ],
      };
    }



    // INTELLIGENT DATA FRAMEWORK
    case 'ADD_PROPERTY_CONFIGURATION_HINT': {
      return {
        ...state,
        propertyConfigurationHintsById: {
          ...state.propertyConfigurationHintsById,
          [payload.id]: payload,
        },
        propertyConfigurationHintIds: [
          ...state.propertyConfigurationHintIds,
          payload.id,
        ],
      };
    }

    case 'REMOVE_PROPERTY_CONFIGURATION_HINT': {
      const {
        propertyConfigurationHintsById,
        propertyConfigurationHintIds,
        virtualLocationTreeNodeHintsById,
        virtualLocationTreeNodeHintIds,
      } = state;

      const {
        [payload]: excludedPropertyConfigurationHint,
        ...newPropertyConfigurationHintsById
      } = propertyConfigurationHintsById;

      const newVirtualLocationTreeNodeHintsById = {
        ...virtualLocationTreeNodeHintsById,
      };

      let newVirtualLocationTreeNodeHintIds = [
        ...virtualLocationTreeNodeHintIds,
      ];

      const virtualLocationTreeNodeHintsToRemove = queries.selectVirtualLocationTreeNodeHintsOfPropertyConfigurationHint(
        state,
        payload,
      );

      for (let virtualLocationTreeNodeHint of virtualLocationTreeNodeHintsToRemove) {
        const {
          id,
        } = virtualLocationTreeNodeHint;
        delete newVirtualLocationTreeNodeHintsById[id];
        newVirtualLocationTreeNodeHintIds = [
          ...newVirtualLocationTreeNodeHintIds.slice(0, newVirtualLocationTreeNodeHintIds.indexOf(id)),
          ...newVirtualLocationTreeNodeHintIds.slice(newVirtualLocationTreeNodeHintIds.indexOf(id) + 1),
        ];
      }

      return {
        ...state,
        propertyConfigurationHintsById: newPropertyConfigurationHintsById,
        propertyConfigurationHintIds: [
          ...propertyConfigurationHintIds.slice(0, propertyConfigurationHintIds.indexOf(payload)),
          ...propertyConfigurationHintIds.slice(propertyConfigurationHintIds.indexOf(payload) + 1),
        ],
        virtualLocationTreeNodeHintsById: newVirtualLocationTreeNodeHintsById,
        virtualLocationTreeNodeHintIds: newVirtualLocationTreeNodeHintIds,
      };
    }

    case 'UPDATE_PROPERTY_CONFIGURATION_HINT': {
      const {
        propertyConfigurationHintId,
        virtualLocationTreeTitle,
        virtualLocationTreeDescription,
      } = payload;

      return {
        ...state,
        propertyConfigurationHintsById: {
          [propertyConfigurationHintId]: {
            ...state.propertyConfigurationHintsById[propertyConfigurationHintId],
            virtualLocationTreeTitle,
            virtualLocationTreeDescription,
          },
        },
      };
    }

    case 'ADD_VIRTUAL_LOCATION_TREE_NODE_HINT': {
      return {
        ...state,
        virtualLocationTreeNodeHintsById: {
          ...state.virtualLocationTreeNodeHintsById,
          [payload.id]: payload,
        },
        virtualLocationTreeNodeHintIds: [
          ...state.virtualLocationTreeNodeHintIds,
          payload.id,
        ],
      };
    }

    case 'REMOVE_VIRTUAL_LOCATION_TREE_NODE_HINTS': {
      const virtualLocationTreeNodeHintIdsToRemove = payload;
      const {
        virtualLocationTreeNodeHintsById,
        virtualLocationTreeNodeHintIds,
      } = state;
      const newVirtualLocationTreeNodeHintsById = {};
      const newVirtualLocationTreeNodeHintIds = [];

      for (let id of virtualLocationTreeNodeHintIds) {
        if (virtualLocationTreeNodeHintIdsToRemove.includes(id)) {
          continue;
        }
        newVirtualLocationTreeNodeHintsById[id] = virtualLocationTreeNodeHintsById[id];
        newVirtualLocationTreeNodeHintIds.push(id);
      }

      return {
        ...state,
        virtualLocationTreeNodeHintsById: newVirtualLocationTreeNodeHintsById,
        virtualLocationTreeNodeHintIds: newVirtualLocationTreeNodeHintIds,
      };
    }



    // NOTIFICATIONS
    case 'ADD_NOTIFICATION_PIPELINE': {
      return {
        ...state,
        notificationPipelinesById: {
          ...state.notificationPipelinesById,
          [payload.id]: payload,
        },
        notificationPipelineIds: [
          ...state.notificationPipelineIds,
          payload.id,
        ],
      };
    }

    case 'REMOVE_NOTIFICATION_PIPELINE': {
      const notificationPipelineId = payload;

      const {
        [notificationPipelineId]: removedNotificationPipeline,
        ...remainingNotificationPipelines
      } = state.notificationPipelinesById;

      return {
        ...state,
        notificationPipelinesById: {
          ...remainingNotificationPipelines,
        },
        notificationPipelineIds: [
          ...state.notificationPipelineIds.slice(0, state.notificationPipelineIds.indexOf(notificationPipelineId)),
          ...state.notificationPipelineIds.slice(state.notificationPipelineIds.indexOf(notificationPipelineId) + 1),
        ],
      };
    }

    case 'UPDATE_NOTIFICATION_PIPELINE': {
      const {
        notificationPipelineId,
        patch,
      } = payload;

      return {
        ...state,
        notificationPipelinesById: {
          ...state.notificationPipelinesById,
          [notificationPipelineId]: {
            ...state.notificationPipelinesById[notificationPipelineId],
            ...patch,
          },
        },
      };
    }

    case 'REMOVE_FIELD_WATCHERS': {
      const fieldWatcherIdsToRemove = payload;
      const {
        fieldWatchersById,
        fieldWatcherIds,
      } = state;
      const newfieldWatchersById = {};
      const newfieldWatcherIds = [];

      for (let id of fieldWatcherIds) {
        if (fieldWatcherIdsToRemove.includes(id)) {
          continue;
        }
        newfieldWatchersById[id] = fieldWatchersById[id];
        newfieldWatcherIds.push(id);
      }

      return {
        ...state,
        fieldWatchersById: newfieldWatchersById,
        fieldWatcherIds: newfieldWatcherIds,
      };
    }

    case 'ADD_FIELD_WATCHER': {
      return {
        ...state,
        fieldWatchersById: {
          ...state.fieldWatchersById,
          [payload.id]: payload,
        },
        fieldWatcherIds: [
          ...state.fieldWatcherIds,
          payload.id,
        ],
      };
    }

    case 'REMOVE_FIELD_WATCHER': {
      const fieldWatcherId = payload;

      const {
        [fieldWatcherId]: removedFieldWatcher,
        ...remainingFieldWatchers
      } = state.fieldWatchersById;

      return {
        ...state,
        fieldWatchersById: {
          ...remainingFieldWatchers,
        },
        fieldWatcherIds: [
          ...state.fieldWatcherIds.slice(0, state.fieldWatcherIds.indexOf(fieldWatcherId)),
          ...state.fieldWatcherIds.slice(state.fieldWatcherIds.indexOf(fieldWatcherId) + 1),
        ],
      };
    }

    case 'UPDATE_FIELD_WATCHER': {
      const {
        fieldWatcherId,
        patch,
      } = payload;

      return {
        ...state,
        fieldWatchersById: {
          ...state.fieldWatchersById,
          [fieldWatcherId]: {
            ...state.fieldWatchersById[fieldWatcherId],
            ...patch,
          },
        },
      };
    }

    case 'ADD_FIELD_DEFINITION_TO_FIELD_WATCHER': {
      const {
        fieldWatcherId,
        fieldDefinitionId,
      } = payload;

      return {
        ...state,
        fieldWatchersById: {
          ...state.fieldWatchersById,
          [fieldWatcherId]: {
            ...state.fieldWatchersById[fieldWatcherId],
            fieldDefinitionIds: [
              ...state.fieldWatchersById[fieldWatcherId].fieldDefinitionIds,
              fieldDefinitionId,
            ],
          },
        },
      };
    }

    case 'REMOVE_FIELD_DEFINITION_FROM_FIELD_WATCHER': {
      const {
        fieldWatcherId,
        fieldDefinitionId,
      } = payload;

      return {
        ...state,
        fieldWatchersById: {
          ...state.fieldWatchersById,
          [fieldWatcherId]: {
            ...state.fieldWatchersById[fieldWatcherId],
            fieldDefinitionIds: [
              ...state.fieldWatchersById[fieldWatcherId].fieldDefinitionIds.slice(0, state.fieldWatchersById[fieldWatcherId].fieldDefinitionIds.indexOf(fieldDefinitionId)),
              ...state.fieldWatchersById[fieldWatcherId].fieldDefinitionIds.slice(state.fieldWatchersById[fieldWatcherId].fieldDefinitionIds.indexOf(fieldDefinitionId) + 1),
            ],
          },
        },
      };
    }

    case 'ENABLE_NOTIFICATION_PIPELINE': {
      const notificationPipelineId = payload;

      return {
        ...state,
        notificationPipelinesById: {
          ...state.notificationPipelinesById,
          [notificationPipelineId]: {
            ...state.notificationPipelinesById[notificationPipelineId],
            enabledStatus: 'enabled',
          },
        },
      };
    }

    case 'DISABLE_NOTIFICATION_PIPELINE': {
      const notificationPipelineId = payload;

      return {
        ...state,
        notificationPipelinesById: {
          ...state.notificationPipelinesById,
          [notificationPipelineId]: {
            ...state.notificationPipelinesById[notificationPipelineId],
            enabledStatus: 'disabled',
          },
        },
      };
    }

    default:
      throw new Error(`Unknown Data Model action "${actionType}"!`);
  }
}

export default DataModelReducer;
