import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';

const AddFieldDefinitionToFieldWatcherDialog = ({
  open,
  onClose,
  onSave,
  DataModelQueries,
  DataModel,
  type,
  onSetType,
  fieldDefinitionId,
  onSetFieldDefinitionId,
  controlsDisabled,
}) => {
  let dateFieldDefinitions = [];

  if (type !== null) {
    dateFieldDefinitions = DataModelQueries.selectFieldDefinitionsByTypeId(
      DataModel,
      type.id,
    )
      .filter(fieldDefinition => fieldDefinition.type === 'date');
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Watch a Field with this Field Watcher
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={DataModelQueries.selectTypes(DataModel)}
          value={type}
          onChange={(event, newType) => {
            onSetType(newType);
          }}
          getOptionLabel={option => option.name}
          getOptionSelected={(option, value) => option.name === value.name}
          renderInput={params => {
            return (
              <TextField
                {...params}
                fullWidth
                label="Select a Type"
              />
            );
          }}
        />
        <br/><br/>

        <InputLabel
          id="select-date-field">
          select a date Field
        </InputLabel>
        <Select
          fullWidth
          labelId="select-date-field"
          margin="dense"
          value={fieldDefinitionId}
          onChange={event => {
            const value = event.target.value;
            onSetFieldDefinitionId(value);
          }}>
          {
            dateFieldDefinitions
              .map(fieldDefinition => {
                const {
                  id,
                  label,
                } = fieldDefinition;
                return (
                  <MenuItem
                    key={id}
                    value={id}>
                    {label}
                  </MenuItem>
                );
              })
          }
        </Select>
      </DialogContent>

      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFieldDefinitionToFieldWatcherDialog;
