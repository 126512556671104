import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import InputAdornment from '@material-ui/core/InputAdornment';

const Create = ({
  history,
}) => {
  const {
    authToken,
    perspectiveId,
    // DataModel,
    // DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);

  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  return (
    <div>
      <Typography
        variant="h4">
        Create a new Type
      </Typography>
      <br/><br/>
      <Typography
        variant="h6">
        Set the basic details of the new Type below. You'll be able to modify flags and fields in the next step, once you've saved these details.
      </Typography>
      <br/><br/>

      <Typography
        variant="h6">
        Name
      </Typography>
      <TextField
        autoFocus
        fullWidth
        disabled={
          localState.status === 'pending save'
        }
        helperText="mandatory"
        value={localState.name}
        onChange={event => {
          if (event.target.value.length > 64) {
            return;
          }
          dispatchLocalAction({
            type: 'SET_NAME',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      <Typography
        variant="h6">
        Plural name
      </Typography>
      <TextField
        fullWidth
        disabled={
          localState.status === 'pending save'
        }
        helperText="mandatory"
        value={localState.pluralName}
        onChange={event => {
          if (event.target.value.length > 64) {
            return;
          }
          dispatchLocalAction({
            type: 'SET_PLURAL_NAME',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      <Typography
        variant="h6">
        Description
      </Typography>
      <TextField
        fullWidth
        disabled={
          localState.status === 'pending save'
        }
        helperText="mandatory"
        value={localState.description}
        onChange={event => {
          if (event.target.value.length > 255) {
            return;
          }
          dispatchLocalAction({
            type: 'SET_DESCRIPTION',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      <Typography
        variant="h6">
        Aliases (optional)
      </Typography>
      {
        localState.aliases
          .map((alias, index) => {
            return (
              <React.Fragment
                key={index}>
                <TextField
                  fullWidth
                  disabled={
                    localState.status === 'pending save'
                  }
                  InputProps={{
                    startAdornment:
                      <InputAdornment
                        position="start">
                        <IconButton
                          onClick={() => {
                            dispatchLocalAction({
                              type: 'SPLICE_ALIAS',
                              payload: index,
                            });
                          }}>
                          <RemoveIcon/>
                        </IconButton>
                      </InputAdornment>,
                  }}
                  value={alias}
                  onChange={event => {
                    if (event.target.value.length > 64) {
                      return;
                    }
                    dispatchLocalAction({
                      type: 'SET_ALIAS',
                      payload: {
                        value: event.target.value,
                        index,
                      },
                    });
                  }}
                />
                <br/><br/>
              </React.Fragment>
            );
          })
      }
      <Tooltip
        placement="top"
        title="add an alias">
        <span>
          <IconButton
            disabled={
              !!localState.aliases.length &&
              !localState.aliases[localState.aliases.length - 1].length
            }
            onClick={() => {
              dispatchLocalAction({
                type: 'PUSH_BLANK_ALIAS',
              });
            }}>
            <AddIcon/>
          </IconButton>
        </span>
      </Tooltip>
      <br/><br/><br/><br/>

      <Button
        variant="contained"
        color="primary"
        disabled={
          localState.status === 'pending save' ||
          !localState.name ||
          !localState.pluralName ||
          !localState.description
        }
        onClick={async() => {
          dispatchLocalAction({
            type: 'REQUEST_SAVE',
          });
          try {
            const {
              name,
              pluralName,
              description,
              aliases,
            } = localState;
            const newType = await DataModelAPI.addType(
              authToken,
              perspectiveId,
              {
                name,
                pluralName,
                description,
                aliases: aliases.filter(alias => !!alias).length ? aliases.filter(alias => !!alias) : null,
              },
            );
            dispatchDataModelAction({
              type: 'ADD_TYPE',
              payload: newType,
            });
            history.push(`/types/${newType.id}`);
          } catch(error) {
            dispatchLocalAction({
              type: 'END_SAVE',
            });
            setAPIException(error);
          }
        }}>
        save basic type details
      </Button>
      &nbsp;
      <Button
        variant="text"
        onClick={() => {
          history.push('/types/list');
        }}>
        go to the list of Types
      </Button>
    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'SET_NAME': {
      return {
        ...state,
        name: payload,
      };
    }

    case 'SET_PLURAL_NAME': {
      return {
        ...state,
        pluralName: payload,
      };
    }

    case 'SET_DESCRIPTION': {
      return {
        ...state,
        description: payload,
      };
    }

    case 'PUSH_BLANK_ALIAS': {
      return {
        ...state,
        aliases: [
          ...state.aliases,
          '',
        ],
      };
    }

    case 'SPLICE_ALIAS': {
      const spliceIndex = payload;

      return {
        ...state,
        aliases: [
          ...state.aliases.slice(0, spliceIndex),
          ...state.aliases.slice(spliceIndex + 1),
        ],
      };
    }

    case 'SET_ALIAS': {
      const {
        value,
        index,
      } = payload;

      return {
        ...state,
        aliases: [
          ...state.aliases.slice(0, index),
          value,
          ...state.aliases.slice(index + 1),
        ],
      };
    }

    case 'REQUEST_SAVE': {
      return {
        ...state,
        status: 'pending save',
      };
    }

    case 'END_SAVE': {
      return {
        ...state,
        status: 'idle',
      };
    }

    case 'RESET': {
      return initState();
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      pending save
    */
    status: 'idle',

    name: '',
    pluralName: '',
    description: '',
    aliases: [],
  };
}


export default Create;
