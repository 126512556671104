import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import Typography from 'ui-library/components/Typography';
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded';

const useStyles = makeStyles({
  root: {
    cursor: theme.fileDNDTarget.cursor,
    userSelect: theme.fileDNDTarget.userSelect,
    padding: theme.fileDNDTarget.padding,
    backgroundColor: theme.fileDNDTarget.backgroundColor,
    border: theme.fileDNDTarget.border,
    borderRadius: theme.fileDNDTarget.borderRadius,
    transition: theme.fileDNDTarget.transition,
    borderColor: props => props.fileDNDTargetState === 'idle' ? theme.fileDNDTarget.idleBorderColor : theme.fileDNDTarget.draggedOverBorderColor,
    '&:hover': {
      borderColor: theme.fileDNDTarget.draggedOverBorderColor,
      '& $iconContainer': {
        color: theme.fileDNDTarget.iconContainer.iconHoverColor,
      },
      '& $textContainer': {
        color: theme.fileDNDTarget.textContainer.textHoverColor,
      },
    },
  },
  icon: {
    height: theme.fileDNDTarget.icon.height,
    width: theme.fileDNDTarget.icon.width,
  },
  flexWrapper: {
    display: theme.fileDNDTarget.flexWrapper.display,
    alignItems: theme.fileDNDTarget.flexWrapper.alignItems,
    justifyContent: theme.fileDNDTarget.flexWrapper.justifyContent,
  },
  iconContainer: {
    display: theme.fileDNDTarget.iconContainer.display,
    alignItems: theme.fileDNDTarget.iconContainer.alignItems,
    justifyContent: theme.fileDNDTarget.iconContainer.justifyContent,
    marginRight: theme.fileDNDTarget.iconContainer.marginRight,
    transition: theme.fileDNDTarget.iconContainer.transition,
    color: props => props.fileDNDTargetState === 'idle' ? theme.fileDNDTarget.iconContainer.defaultColor : theme.fileDNDTarget.iconContainer.iconHoverColor,
  },
  textContainer: {
    transition: theme.fileDNDTarget.textContainer.transition,
    color: props => props.fileDNDTargetState === 'idle' ? theme.fileDNDTarget.textContainer.defaultColor : theme.fileDNDTarget.textContainer.textHoverColor,
  },
  dndText: {
    marginTop: theme.fileDNDTarget.text.marginTop,
  },
  maxSizeText: {
    marginTop: theme.fileDNDTarget.text.marginTop,
    color: theme.fileDNDTarget.text.defaultColor,
  },
  fileInput: {
    display: theme.fileDNDTarget.fileInput.display,
  },
});

const FileDNDTarget = ({
  fileDNDTargetState,
  onSubmit,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDragEnd,
  onDrop,
  uploadFiles,
  fileInput,
  maxFileUploadSize,
}) => {
  const classes = useStyles({
    fileDNDTargetState,
  });

  return (
    <form className={classes.root}
      onSubmit={onSubmit}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      onClick={() => {
        fileInput.current.click();
      }}
    >
      <div
        className={classes.flexWrapper}
      >
        <div className={classes.iconContainer}>
          <CloudUploadRoundedIcon
            className={classes.icon}
          />
        </div>

        <div className={classes.textContainer}>
          <div className={classes.dndText}>
            <Typography
              variant="body"
              fontWeight="bold"
              color="inherit"
            >
              Drag & Drop files or &nbsp;
            </Typography>
            <Typography
              variant="body"
              fontWeight="bold"
              color="primary"
            >
              Browse
            </Typography>
          </div>
          <div className={classes.maxSizeText}>
            <Typography
              variant="tiny"
              color="inherit"
            >
                Maximum file size: {maxFileUploadSize}
            </Typography>
          </div>
          <input
            ref={fileInput}
            data-test-id="browse-files-button"
            multiple
            type="file"
            id="file-input"
            className={classes.fileInput}
            onChange={(event) => {
              uploadFiles({
                files: Array.from(event.target.files),
              });
              event.target.value = null;
            }}
          />
        </div>
      </div>
    </form>
  );
};

FileDNDTarget.propTypes = {
  fileDNDTargetState: PropTypes.string,
  uploadFiles: PropTypes.func,
  onSubmit: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrop: PropTypes.func,
  fileInput: PropTypes.object,
  maxFileUploadSize: PropTypes.string,
};

export default FileDNDTarget;
