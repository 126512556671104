import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../verbs';

export default async function DEL__conversations_CONVERSATIONID_comments_COMMENTID(params) {
  const {
    authToken,
    perspectiveId,
    conversationId,
    commentId,
  } = params;

  return await DELETE(
    `/conversations/${conversationId}/comments/${commentId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
