import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
  root: {
    flexGrow: theme.newSearchBar.root.flexGrow,
    display: theme.newSearchBar.root.display,
    alignItems: theme.newSearchBar.root.alignItems,
    justifyContent: theme.newSearchBar.root.justifyContent,
    margin: theme.newSearchBar.root.margin,
  },
});

const NewSearchBar = ({
  searchQuery,
  onChange,
  onKeyPress,
  onSubmitSearchQuery,
  autoFocus,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Input
        type="text"
        fullWidth
        disableUnderline
        autoFocus={autoFocus}
        value={searchQuery}
        placeholder="Type and Search..."
        onChange={onChange}
        onKeyPress={onKeyPress}
        {...otherProps}
      />
    </div>
  );
};

NewSearchBar.propTypes = {
  searchQuery: PropTypes.string,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default NewSearchBar;
