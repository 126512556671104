import React from 'react';
import styled from '@emotion/styled';

import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';

import Button from 'ui-library/components/Button';
import {greyLight} from 'ui-library/color-palette';
import {greySemi} from 'ui-library/color-palette';
import Message from 'ui-library/components/Message';
import {primary} from 'ui-library/color-palette';

import internalCodeErrorMessage from 'api-error-codes/internalCodeErrorMessage';

import UnauthenticatedHeader from 'components/UnauthenticatedHeader';
import UnauthenticatedFooter from 'components/UnauthenticatedFooter';
import DefaultAccount from 'components/SignUp/LeftPanel/DefaultAccount';
import PersonalAccount from 'components/SignUp/LeftPanel/PersonalAccount';
import BusinessAccount from 'components/SignUp/LeftPanel/BusinessAccount';

import SelectAccountComponent from 'components/SignUp/RightPanel/SelectAccount';
import FirstNameComponent from 'components/SignUp/RightPanel/FirstName';
import LastNameComponent from 'components/SignUp/RightPanel/LastName';
import EmailComponent from 'components/SignUp/RightPanel/Email';
import PasswordComponent from 'components/SignUp/RightPanel/Password';
import ConfirmPasswordComponent from 'components/SignUp/RightPanel/ConfirmPassword';
import ActivationComponent from 'components/SignUp/RightPanel/Activation';
import BusinessNameComponent from 'components/SignUp/RightPanel/BusinessName';

import createAccount from 'services/SignUp/createAccount';
import resendEmail from 'services/SignUp/resendEmail';
import checkEmailAvailability from 'services/SignUp/checkEmailAvailability';

import {Transition} from 'react-transition-group';

import './signup.css';

import {
  singupWizardProgressHeight,
  signupWizardnavigationHeight,
  singupWizardHeaderHeight,
  singupWizardFooterHeight,
} from 'constants/layout';

const signupWizardFixedHeightElements = singupWizardProgressHeight + signupWizardnavigationHeight + singupWizardHeaderHeight + singupWizardFooterHeight;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: ${greyLight};
  min-width: 917px;
`;

const ProgressBarContainer = styled.div`
  background-color: ${greySemi};
  height: 4px;
  position: fixed;
  width: 100%;
`;

const ProgressBar = styled.div`
  background-color: ${primary};
  height: 4px;
  position: fixed;
  width: ${props => props.progressBarWidth}%;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 50px;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 150px;
`;

const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 300px;
`;

const ContentContainer = styled.div`
  align-items: center;
  display: flex;
  height: calc(100vh - ${props => props.signupWizardFixedHeightElements}px);
  justify-content: center;
  overflow: hidden;
  min-height: 500px;
`;

const ContentLeft = styled.div`
  border-right: 2px solid ${greySemi};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 50px 0 20px;
  width: 100%;
  max-width: 600px;

`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px 0 50px;
  width: 100%;
  max-width: 600px;
`;

const TransitionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const SignUp = () => {

  const PERSONAL_COMPONENTS_STEPS = 7;
  const BUSINESS_COMPONENTS_STEPS = 8;

  const [accountType, setAccountType] = React.useState(null);
  const [step, setStep] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isAtLastStep, setIsAtLastStep] = React.useState(false);
  const [progressBarWidth, setProgressBarWidth] = React.useState(0);
  const [wizardDirection, setWizardDirection] = React.useState('forward');
  const [isTransitioning, setIsTransitioning] = React.useState(false);

  const [stepZeroFinished, setStepZeroFinished] = React.useState(false);
  const [stepOneFinished, setStepOneFinished] = React.useState(false);
  const [stepTwoFinished, setStepTwoFinished] = React.useState(false);
  const [stepThreeFinished, setStepThreeFinished] = React.useState(false);
  const [stepFourFinished, setStepFourFinished] = React.useState(false);
  const [stepFiveFinished, setStepFiveFinished] = React.useState(false);
  const [stepSixFinished, setStepSixFinished] = React.useState(false);
  const [stepSevenFinished, setStepSevenFinished] = React.useState(false);

  const [stepZeroLeftFinished, setStepZeroLeftFinished] = React.useState(false);
  const [stepOneLeftFinished, setStepOneLeftFinished] = React.useState(false);

  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [businessName, setBusinessName] = React.useState('');

  const [emailAvailability, setEmailAvailability] = React.useState(true);
  const [emailAvailabilityMessage, setEmailAvailabilityMessage] = React.useState('');

  const [resendConfirmationEmail, setResendConfirmationEmail] = React.useState(false);

  const [error, setError] = React.useState('');

  // TEMPORARY PASSWORD VALIDATION
  const [hasCorrectLength, setHasCorrectLength] = React.useState(false);
  const [hasOneLowerCase, setHasOneLowerCase] = React.useState(false);
  const [hasOneUpperCase, setHasOneUpperCase] = React.useState(false);
  const [hasOneDigit, setHasOneDigit] = React.useState(false);
  const [hasOneSpecial, setHasOneSpecial] = React.useState(false);
  const [passwordIsValid, setPasswordIsValid] = React.useState(false);

  React.useEffect(() => {
    const correctLength = new RegExp(/^(?=.{8,72}$).*/);
    const oneLowerCase = new RegExp(/(?=.*[a-z]).*$/);
    const oneUpperCase = new RegExp(/(?=.*[A-Z]).*$/);
    const oneDigit = new RegExp(/(?=.*[0-9]).*$/);
    const oneSpecial = new RegExp(/(?=.*[^a-zA-Z0-9]).*$/);

    setHasCorrectLength(correctLength.test(password));
    setHasOneLowerCase(oneLowerCase.test(password));
    setHasOneUpperCase(oneUpperCase.test(password));
    setHasOneDigit(oneDigit.test(password));
    setHasOneSpecial(oneSpecial.test(password));

    setPasswordIsValid(hasCorrectLength && hasOneLowerCase && hasOneUpperCase && hasOneDigit && hasOneSpecial);
  }, [
    password,
    hasCorrectLength,
    setHasCorrectLength,
    hasOneLowerCase,
    setHasOneLowerCase,
    hasOneUpperCase,
    setHasOneUpperCase,
    hasOneDigit,
    setHasOneDigit,
    hasOneSpecial,
    setHasOneSpecial,
    passwordIsValid,
    setPasswordIsValid,
  ]);

  // RESET ALL COMPONENTS IF ACCOUNT TYPE CHANGES
  React.useEffect(() => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setStepZeroFinished(false);
    setStepOneFinished(false);
    setStepTwoFinished(false);
    setStepThreeFinished(false);
    setStepFourFinished(false);
    setStepFiveFinished(false);
    setStepSixFinished(false);
    setStepSevenFinished(false);
    setWizardDirection('forward');
  }, [
    accountType,
    setFirstName,
    setLastName,
    setEmail,
    setPassword,
    setConfirmPassword,
  ]);

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw(fatalError);
  }

  const handleError = (error) => {
    setIsLoading(false);
    const {
      body: {
        code: internalErrorCode,
      },
    } = error;
    switch(internalErrorCode) {
      case 1001: return setError('Invalid parameter');
      default: {
        try {
          const internalErrorMessage = internalCodeErrorMessage(internalErrorCode);
          return setError(internalErrorMessage);
        } catch(error) {
          setFatalError(error);
        }
      }
    }
  };

  const signUp = async() => {
    setWizardDirection('forward');
    setIsLoading(true);
    try {
      await createAccount({
        accountType,
        email,
        password,
        firstName,
        lastName,
        businessName,
      });
      setIsLoading(false);
      setStep(step + 1);
    } catch (error) {
      handleError(error);
    };
  };

  const sendActivationEmail = async() => {
    setWizardDirection('forward');
    setIsLoading(true);
    try {
      await resendEmail(email);
      setIsLoading(false);
      setResendConfirmationEmail(true);
    } catch (error) {
      handleError(error);
    }
  };

  const checkEmail = async() => {
    try {
      setIsLoading(true);
      setEmailAvailabilityMessage('');
      const response = await checkEmailAvailability(encodeURIComponent(email));
      const {
        isEmailAvailable,
      } = response;
      setEmailAvailability(isEmailAvailable);
      setEmailAvailabilityMessage(isEmailAvailable ? '' : 'e-mail address is already in use');
      if (isEmailAvailable) {
        setWizardDirection('forward');
        setStep(step + 1);
      }
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  // CHECK IF WE'RE AT THE LAST STEP & COMPUTE PROGRESS BAR WIDTH
  React.useEffect(() => {
    if ((accountType === 'personal' && step === PERSONAL_COMPONENTS_STEPS - 1) || (accountType === 'business' && step === BUSINESS_COMPONENTS_STEPS - 1)) {
      setIsAtLastStep(true);
      setProgressBarWidth(100);
    } else {
      setIsAtLastStep(false);
      if (accountType === 'personal') {
        setProgressBarWidth((100 / PERSONAL_COMPONENTS_STEPS) * (step + 1));
      } else if (accountType === 'business') {
        setProgressBarWidth((100 / BUSINESS_COMPONENTS_STEPS) * (step + 1));
      } else {
        setProgressBarWidth(0);
      }
    }
  }, [step, accountType, setIsAtLastStep, setProgressBarWidth]);

  return (
    <Container>
      <Message
        data-test-id="signup-error"
        variant="error"
        open={!!error}
        onClose={() => setError('')}
        messageTitle={error}
        messageBody={error}
      />
      {/* TODO: AT SOME POINT IN TIME, ADD THE NEW PROGRESS BAR COMPONENT */}
      <ProgressBarContainer>
        <ProgressBar
          progressBarWidth={progressBarWidth}
        />
      </ProgressBarContainer>
      <NavigationContainer>
        {
          isAtLastStep || step === 0 ?
            null
            :
            <Button
              data-set-button-id="signup-backward-button"
              variant="icon-text"
              onClick={() => {
                setWizardDirection('backward');
                setStep(step > 0 ? step - 1 : 0);
              }}
              disabled={isLoading || isTransitioning || isLoading}
            >
              <ArrowBackIcon />
            </Button>
        }
      </NavigationContainer>
      <HeaderContainer>
        <UnauthenticatedHeader />
      </HeaderContainer>
      <ContentContainer
        signupWizardFixedHeightElements={signupWizardFixedHeightElements}
      >
        {/* LEFT PANEL PERSONAL CONTENT WITH ANIMATION*/}
        <ContentLeft>
          <Transition
            in={(wizardDirection === 'forward' && step === 0) || (wizardDirection === 'backward' && step === 0 && stepOneLeftFinished === true)}
            timeout={150}
            appear
            onEnter={() => {
            }}
            onEntered={() => {
            }}
            onExit={() => {
            }}
            onExited={() => {
              setStepZeroLeftFinished(true);
              setStepOneLeftFinished(false);
            }}
            mountOnEnter
            unmountOnExit
          >
            {(status) => (
              <div className={`leftBox leftBox-${status}`}>
                <DefaultAccount />
              </div>
            )}
          </Transition>

          <Transition
            in={(accountType === 'personal' && step > 0 && stepZeroLeftFinished === true)}
            timeout={150}
            appear
            onEnter={() => {
            }}
            onEntered={() => {
            }}
            onExit={() => {
            }}
            onExited={() => {
              setStepZeroLeftFinished(false);
              setStepOneLeftFinished(true);
            }}
            mountOnEnter
            unmountOnExit
          >
            {(status) => (
              <div className={`leftBox leftBox-${status}`}>
                <PersonalAccount />
              </div>
            )}
          </Transition>

          <Transition
            in={(accountType === 'business' && step > 0 && stepZeroLeftFinished === true)}
            timeout={150}
            appear
            onEnter={() => {
            }}
            onEntered={() => {
            }}
            onExit={() => {
            }}
            onExited={() => {
              setStepZeroLeftFinished(false);
              setStepOneLeftFinished(true);
            }}
            mountOnEnter
            unmountOnExit
          >
            {(status) => (
              <div className={`leftBox leftBox-${status}`}>
                <BusinessAccount />
              </div>
            )}
          </Transition>
        </ContentLeft>

        {/* RIGHT PANEL CONTENT */}
        <ContentRight>
          {/* SELECT ACCOUNT RIGHT */}
          <Transition
            in={(wizardDirection === 'forward' && step === 0) || (wizardDirection === 'backward' && step === 0 && stepOneFinished === true)}
            timeout={150}
            appear
            onEnter={() => {
              setIsTransitioning(true);
            }}
            onEntered={() => {
              setIsTransitioning(false);
            }}
            onExit={() => {
              setIsTransitioning(true);
            }}
            onExited={() => {
              setIsTransitioning(false);
              setStepZeroFinished(true);
              setStepOneFinished(false);
            }}
            mountOnEnter
            unmountOnExit
          >
            {(status) => (
              <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                <SelectAccountComponent
                  accountType={accountType}
                  setAccountType={setAccountType}
                  step={step}
                  setStep={setStep}
                  setWizardDirection={setWizardDirection}
                  isTransitioning={isTransitioning}
                />
              </TransitionContainer>
            )}
          </Transition>

          {
            accountType === 'personal' ?
              <>
                {/* FIRST NAME */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 1 && stepZeroFinished === true) || (wizardDirection === 'backward' && step === 1 && stepTwoFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepZeroFinished(false);
                    setStepOneFinished(true);
                    setStepTwoFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <FirstNameComponent
                        accountType={accountType}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        step={step}
                        setStep={setStep}
                        setWizardDirection={setWizardDirection}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* LAST NAME */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 2 && stepOneFinished === true) || (wizardDirection === 'backward' && step === 2 && stepThreeFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepOneFinished(false);
                    setStepTwoFinished(true);
                    setStepThreeFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <LastNameComponent
                        accountType={accountType}
                        lastName={lastName}
                        setLastName={setLastName}
                        step={step}
                        setStep={setStep}
                        setWizardDirection={setWizardDirection}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* EMAIL */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 3 && stepTwoFinished === true) || (wizardDirection === 'backward' && step === 3 && stepFourFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepTwoFinished(false);
                    setStepThreeFinished(true);
                    setStepFourFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <EmailComponent
                        accountType={accountType}
                        email={email}
                        setEmail={setEmail}
                        step={step}
                        isTransitioning={isTransitioning}
                        checkEmail={checkEmail}
                        emailAvailability={emailAvailability}
                        emailAvailabilityMessage={emailAvailabilityMessage}
                        isLoading={isLoading}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* PASSWORD */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 4 && stepThreeFinished === true) || (wizardDirection === 'backward' && step === 4 && stepFiveFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepThreeFinished(false);
                    setStepFourFinished(true);
                    setStepFiveFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <PasswordComponent
                        accountType={accountType}
                        password={password}
                        setPassword={setPassword}
                        step={step}
                        setStep={setStep}
                        hasCorrectLength={hasCorrectLength}
                        hasOneLowerCase={hasOneLowerCase}
                        hasOneUpperCase={hasOneUpperCase}
                        hasOneDigit={hasOneDigit}
                        hasOneSpecial={hasOneSpecial}
                        passwordIsValid={passwordIsValid}
                        setWizardDirection={setWizardDirection}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* CONFIRM PASSWORD */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 5 && stepFourFinished === true) || (wizardDirection === 'backward' && step === 5 && stepSixFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepFourFinished(false);
                    setStepFiveFinished(true);
                    setStepSixFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => {
                    return (
                      <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                        <ConfirmPasswordComponent
                          accountType={accountType}
                          step={step}
                          password={password}
                          confirmPassword={confirmPassword}
                          setConfirmPassword={setConfirmPassword}
                          signUp={signUp}
                          isLoading={isLoading}
                          isTransitioning={isTransitioning}
                        />
                      </TransitionContainer>
                    );}}
                </Transition>
                {/* ACTIVATION EMAIL */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 6 && stepFiveFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepFiveFinished(false);
                    setStepSixFinished(true);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <ActivationComponent
                        sendActivationEmail={sendActivationEmail}
                        isTransitioning={isTransitioning}
                        isLoading={isLoading}
                        resendConfirmationEmail={resendConfirmationEmail}
                        setResendConfirmationEmail={setResendConfirmationEmail}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
              </>
              :
              <>
                {/* BUSINESS NAME */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 1 && stepZeroFinished === true) || (wizardDirection === 'backward' && step === 1 && stepTwoFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setIsTransitioning(false);
                    setStepZeroFinished(false);
                    setStepOneFinished(true);
                    setStepTwoFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <BusinessNameComponent
                        businessName={businessName}
                        setBusinessName={setBusinessName}
                        step={step}
                        setStep={setStep}
                        setWizardDirection={setWizardDirection}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* FIRST NAME */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 2 && stepOneFinished === true) || (wizardDirection === 'backward' && step === 2 && stepThreeFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepOneFinished(false);
                    setStepTwoFinished(true);
                    setStepThreeFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <FirstNameComponent
                        accountType={accountType}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        step={step}
                        setStep={setStep}
                        setWizardDirection={setWizardDirection}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* LAST NAME */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 3 && stepTwoFinished === true) || (wizardDirection === 'backward' && step === 3 && stepFourFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepTwoFinished(false);
                    setStepThreeFinished(true);
                    setStepFourFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <LastNameComponent
                        accountType={accountType}
                        lastName={lastName}
                        setLastName={setLastName}
                        step={step}
                        setStep={setStep}
                        setWizardDirection={setWizardDirection}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* EMAIL */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 4 && stepThreeFinished === true) || (wizardDirection === 'backward' && step === 4 && stepFiveFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepThreeFinished(false);
                    setStepFourFinished(true);
                    setStepFiveFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <EmailComponent
                        accountType={accountType}
                        email={email}
                        setEmail={setEmail}
                        step={step}
                        isTransitioning={isTransitioning}
                        checkEmail={checkEmail}
                        emailAvailability={emailAvailability}
                        emailAvailabilityMessage={emailAvailabilityMessage}
                        isLoading={isLoading}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* PASSWORD */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 5 && stepFourFinished === true) || (wizardDirection === 'backward' && step === 5 && stepSixFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepFourFinished(false);
                    setStepFiveFinished(true);
                    setStepSixFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <PasswordComponent
                        accountType={accountType}
                        password={password}
                        setPassword={setPassword}
                        step={step}
                        setStep={setStep}
                        hasCorrectLength={hasCorrectLength}
                        hasOneLowerCase={hasOneLowerCase}
                        hasOneUpperCase={hasOneUpperCase}
                        hasOneDigit={hasOneDigit}
                        hasOneSpecial={hasOneSpecial}
                        passwordIsValid={passwordIsValid}
                        setWizardDirection={setWizardDirection}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* CONFIRM PASSWORD */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 6 && stepFiveFinished === true) || (wizardDirection === 'backward' && step === 6 && stepSevenFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepFiveFinished(false);
                    setStepSixFinished(true);
                    setStepSevenFinished(false);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <ConfirmPasswordComponent
                        accountType={accountType}
                        step={step}
                        password={password}
                        confirmPassword={confirmPassword}
                        setConfirmPassword={setConfirmPassword}
                        signUp={signUp}
                        isLoading={isLoading}
                        isTransitioning={isTransitioning}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
                {/* ACTIVATION EMAIL */}
                <Transition
                  timeout={150}
                  in={(wizardDirection === 'forward' && step === 7 && stepSixFinished === true)}
                  onEnter={() => {
                    setIsTransitioning(true);
                  }}
                  onEntered={() => {
                    setIsTransitioning(false);
                  }}
                  onExit={() => {
                    setIsTransitioning(true);
                  }}
                  onExited={() => {
                    setIsTransitioning(false);
                    setStepSixFinished(false);
                    setStepSevenFinished(true);
                  }}
                  mountOnEnter
                  unmountOnExit
                >
                  {(status) => (
                    <TransitionContainer className={`box box-${wizardDirection}-${status}`}>
                      <ActivationComponent
                        sendActivationEmail={sendActivationEmail}
                        isTransitioning={isTransitioning}
                        isLoading={isLoading}
                        resendConfirmationEmail={resendConfirmationEmail}
                        setResendConfirmationEmail={setResendConfirmationEmail}
                      />
                    </TransitionContainer>
                  )}
                </Transition>
              </>
          }
        </ContentRight>
      </ContentContainer>
      <FooterContainer>
        <UnauthenticatedFooter />
      </FooterContainer>
    </Container>
  );
};

export default SignUp;
