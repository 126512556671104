function initNodeState() {
  return {
    node: {
      name: '',
      locationPath: [],
      record: {
        name: '',
      },
      typeName: '',
      typeId: null,
    },

    isNodeSaving: false,

    // Item Name Component
    previousNodeName: '',
    isNodeNameSaving: false,
    isNodeNameEditable: false,

    // Item Permissions
    nodePermissions: {
      frontendPermissions: {},
    },

    // Item Details Tab
    fieldDefinitionsById: {},
    fieldsByFieldDefinitionId: {},
    fieldDefinitionIds: [],

    // Item Attachments Tab
    attachmentsById: {},
    attachmentIds: [],
    uploadsById: {},
    uploadIds: [],
    attachmentsSortDirection: 'descending',
    attachmentsSortCriteria: 'name',
    attachmentsRequireSort: false,

    // Item Links Tab
    nodeLinks: [],
    isLinkInfoLoading: false,
    linkInfo: {
      name: '',
      fields: [],
    },
    isLinkInfoOpen: false,
    nodeLinksAreLoaded: false,
    isLinkCandidatesListLoading: false,
    nodeLinkCandidates: [],

    // Item Tags Tab
    tags: [],
    nodeTags: [],

    // Item Permissions Tab
    inboundGrants: [],
    outboundGrants: [],
    downstreamGrants: [],

    nodeGrantFrontendPermissions: {},
    nodeGrantAtomicPermissions: {},
    nodeGrantConnection: null,
  };
}

function nodeReducer(state, {type: actionType, payload}) {
  switch(actionType) {
    case 'SET_NODE': {
      return {
        ...state,
        node: {
          ...payload,
        },
      };
    }
    case 'SET_IS_NODE_SAVING': {
      return {
        ...state,
        isNodeSaving: payload,
      };
    }

    // START ITEM NAME ACTIONS
    case 'SET_NODE_NAME': {
      const {
        node,
      } = state;

      return {
        ...state,
        node: {
          ...node,
          name: payload,
        },
      };
    }
    case 'SET_PREVIOUS_NODE_NAME': {
      return {
        ...state,
        previousNodeName: payload,
      };
    }
    case 'SET_IS_NODE_NAME_SAVING': {
      return {
        ...state,
        isNodeNameSaving: payload,
      };
    }
    case 'SET_IS_NODE_NAME_EDITABLE': {
      return {
        ...state,
        isNodeNameEditable: payload,
      };
    }
    // END ITEM NAME ACTIONS

    // START ITEM PERMISSIONS ACTIONS
    case 'SET_NODE_PERMISSIONS': {
      return {
        ...state,
        nodePermissions: {
          ...payload,
        },
      };
    }
    // END ITEM PERMISSIONS ACTIONS

    // START ITEM DETAILS ACTIONS
    case 'HYDRATE_FIELDS': {
      return {
        ...state,
        ...payload,
      };
    }
    case 'REVERT_FIELDS': {
      const {
        revertIds,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
      } = state;

      const revertedFieldsByFieldDefinitionId = {};
      revertIds.forEach(fieldDefinitionId => {
        revertedFieldsByFieldDefinitionId[fieldDefinitionId] = {
          ...fieldsByFieldDefinitionId[fieldDefinitionId],
          value: fieldsByFieldDefinitionId[fieldDefinitionId].previousValue,
          isEditable: false,
          isSaving: false,
          isFocused: false,
          errorMessage: '',
          isPristine: true,
        };
      });

      return ({
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          ...revertedFieldsByFieldDefinitionId,
        },
      });
    }
    case 'SET_FIELD_IS_EDITABLE': {
      const {
        fieldDefinitionId,
        isEditable,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedField,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedField,
            isEditable,
          },
        },
      };
    }
    case 'SET_FIELD_VALUE': {
      const {
        fieldDefinitionId,
        value,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedField,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedField,
            value,
          },
        },
      };
    }
    case 'SET_FIELD_IS_SAVING': {
      const {
        fieldDefinitionId,
        isSaving,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedField,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedField,
            isSaving,
          },
        },
      };
    }
    case 'SET_FIELD_IS_FOCUSED': {
      const {
        fieldDefinitionId,
        isFocused,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedField,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedField,
            isFocused,
          },
        },
      };
    }
    case 'SET_FIELD_PREVIOUS_VALUE': {
      const {
        fieldDefinitionId,
        value,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedField,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedField,
            previousValue: value,
          },
        },
      };
    }
    case 'SET_FIELD_ERROR_MESSAGE': {
      const {
        fieldDefinitionId,
        errorMessage,
      } = payload;

      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedField,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedField,
            errorMessage,
          },
        },
      };
    }
    case 'SET_FIELD_INLINE_EDIT_MODE': {
      const {
        fieldDefinitionId,
        inlineEditMode,
      } = payload;

      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            inlineEditMode,
          },
        },
      };
    }
    // END ITEM DETAILS ACTIONS

    // START ITEM ATTACHMENTS ACTIONS
    case 'HYDRATE_ATTACHMENTS': {
      return {
        ...state,
        ...payload,
      };
    }
    case 'PUSH_UPLOAD': {
      const {
        uploadIds,
        uploadsById,
      } = state;

      let nextUploadId = 0;

      while (uploadIds.includes(nextUploadId)) {
        nextUploadId++;
      }

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [nextUploadId]: {
            id: nextUploadId,
            ...payload,
          },
        },
        uploadIds: [
          ...uploadIds,
          nextUploadId,
        ],
      };
    }
    case 'REMOVE_UPLOAD': {
      const {
        id,
      } = payload;

      const {
        uploadsById: {
          [id]: excludedUpload,
          ...remainingUploads
        },
        uploadIds,
      } = state;

      return {
        ...state,
        uploadsById: remainingUploads,
        uploadIds: [
          ...uploadIds.slice(0, uploadIds.indexOf(id)),
          ...uploadIds.slice(uploadIds.indexOf(id) + 1),
        ],
      };
    }
    case 'SET_UPLOAD_ERROR': {
      const {
        id,
        errorMessage,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            errorMessage,
          },
        },
      };
    }
    case 'UPDATE_BYTES_TRANSFERED': {
      const {
        id,
        bytesTransferred,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            bytesTransferred,
          },
        },
      };
    }
    case 'UPDATE_IS_UPLOADING': {
      const {
        id,
        isUploading,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            isUploading,
          },
        },
      };
    }
    case 'UPDATE_UPLOAD_PROGRESS': {
      const {
        id,
        progress,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            progress,
          },
        },
      };
    }
    case 'ADD_UPLOAD_REQUEST': {
      const {
        id,
        request,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            request,
          },
        },
      };
    }
    case 'PUSH_ATTACHMENT': {
      return {
        ...state,
        attachmentsById: {
          ...state.attachmentsById,
          [payload.id]: {
            ...payload,
          },
        },
        attachmentIds: [
          payload.id,
          ...state.attachmentIds,
        ],
      };
    }
    case 'REMOVE_ATTACHMENT': {
      const {
        [payload.id]: excludedAttachment,
        ...remainingAttachments
      } = state.attachmentsById;

      return {
        ...state,
        attachmentsById: remainingAttachments,
        attachmentIds: [
          ...state.attachmentIds.slice(0, state.attachmentIds.indexOf(payload.id)),
          ...state.attachmentIds.slice(state.attachmentIds.indexOf(payload.id) + 1),
        ],
      };
    }
    case 'SET_ATTACHMENT_TITLE': {
      const {
        id,
        title,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            title,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_PREVIOUS_TITLE': {
      const {
        id,
        previousTitle,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            previousTitle,
          },
        },
      };
    }
    case 'SET_DISPLAY_DELETE_DIALOG': {
      const {
        id,
        isDeleteDialogVisible,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isDeleteDialogVisible,
          },
        },
      };
    }
    case 'SET_IS_ATTACHMENT_TITLE_EDITABLE': {
      const {
        id,
        isEditable,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isEditable,
          },
        },
      };
    }
    case 'SET_IS_ATTACHMENT_TITLE_SAVING': {
      const {
        id,
        isTitleSaving,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isTitleSaving,
          },
        },
      };
    }
    case 'SET_DOWNLOAD_MENU_ANCHOR': {
      const {
        id,
        anchorEl,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            anchorEl,
          },
        },
      };
    }
    case 'SET_IS_DOWNLOAD_MENU_OPEN': {
      const {
        id,
        isMenuOpen,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isMenuOpen,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_TITLE_ERROR': {
      const {
        id,
        titleError,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            titleError,
          },
        },
      };
    }
    case 'SET_IS_ATTACHMENT_DOWNLOADING': {
      const {
        id,
        isDownloading,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isDownloading,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_DOWNLOAD_REQUEST': {
      const {
        id,
        downloadRequest,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            downloadRequest,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_THUMBNAIL_BLOB_URL': {
      const {
        id,
        thumbnailBlobURL,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            thumbnailBlobURL,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_DOWNLOAD_PROGRESS': {
      const {
        id,
        progress,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            progress,
          },
        },
      };
    }
    case 'SET_SORT_CRITERIA': {
      return {
        ...state,
        attachmentsSortCriteria: payload,
      };
    }
    case 'SET_SORT_DIRECTION': {
      return {
        ...state,
        attachmentsSortDirection: payload,
      };
    }
    case 'SET_ATTACHMENT_IDS': {
      return {
        ...state,
        attachmentIds: payload,
      };
    }
    case 'SET_ATTACHMENTS_REQUIRE_SORT': {
      return {
        ...state,
        attachmentsRequireSort: payload,
      };
    }
    // END ITEM ATTACHMENTS ACTIONS

    // START ITEM LINKS ACTIONS
    case 'SET_NODE_LINKS': {
      return {
        ...state,
        nodeLinks: payload,
      };
    }
    case 'SET_IS_LINK_INFO_LOADING': {
      return {
        ...state,
        isLinkInfoLoading: payload,
      };
    }
    case 'SET_LINK_INFO': {
      return {
        ...state,
        linkInfo: payload,
      };
    }
    case 'SET_IS_LINK_INFO_OPEN': {
      return {
        ...state,
        isLinkInfoOpen: payload,
      };
    }
    case 'SET_NODE_LINKS_ARE_LOADED': {
      return {
        ...state,
        nodeLinksAreLoaded: payload,
      };
    }
    case 'REMOVE_LINK': {
      const {
        nodeLinks,
      } = state;

      return {
        ...state,
        nodeLinks: [
          ...nodeLinks.slice(0, payload),
          ...nodeLinks.slice(payload + 1),
        ],
      };
    }
    case 'ADD_LINK': {
      const {
        nodeLinks,
      } = state;

      return {
        ...state,
        nodeLinks: [
          ...nodeLinks,
          payload,
        ],
      };
    }
    case 'SET_IS_LINK_CANDIDATES_LIST_LOADING': {
      return {
        ...state,
        isLinkCandidatesListLoading: payload,
      };
    }
    case 'SET_NODE_LINK_CANDIDATES': {
      return {
        ...state,
        nodeLinkCandidates: payload,
      };
    }
    case 'SET_LINK_CANDIDATE': {
      const {
        value,
        candidateIndex,
        linkId,
      } = payload;
      const {
        nodeLinkCandidates,
        nodeLinkCandidates: {
          [candidateIndex]: selectedCandidate,
        },
      } = state;

      return {
        ...state,
        nodeLinkCandidates: [
          ...nodeLinkCandidates.slice(0, candidateIndex),
          {
            ...selectedCandidate,
            linked: value,
            linkId,
          },
          ...nodeLinkCandidates.slice(candidateIndex + 1),
        ],
      };
    }
    case 'SET_CANDIDATE_OPERATION_IN_PROGRESS': {
      const {
        value,
        candidateIndex,
      } = payload;
      const {
        nodeLinkCandidates,
        nodeLinkCandidates: {
          [candidateIndex]: selectedCandidate,
        },
      } = state;

      return {
        ...state,
        nodeLinkCandidates: [
          ...nodeLinkCandidates.slice(0, candidateIndex),
          {
            ...selectedCandidate,
            operationInProgress: value,
          },
          ...nodeLinkCandidates.slice(candidateIndex + 1),
        ],
      };
    }
    case 'SET_IS_LINK_DELETING': {
      const {
        value,
        index,
      } = payload;

      const {
        nodeLinks,
      } = state;

      return {
        ...state,
        nodeLinks: [
          ...nodeLinks.slice(0, index),
          {
            ...nodeLinks[index],
            isLinkDeleting: value,
          },
          ...nodeLinks.slice(index + 1),
        ],
      };
    }
    case 'CLEAR_LINKS_INFO': {
      return {
        ...state,
        linksAreLoading: false,
        nodeLinks: [],
        isLinkInfoLoading: false,
        linkInfo: {
          name: '',
          fields: [],
        },
        isLinkInfoOpen: false,
        nodeLinksAreLoaded: false,
        isLinkCandidatesListLoading: false,
        nodeLinkCandidates: [],
      };
    }
    // END ITEM LINKS ACTIONS

    // START ITEM TAGS ACTIONS
    case 'SET_TAGS': {
      return {
        ...state,
        tags: payload,
      };
    }
    case 'SET_NODE_TAGS': {
      return {
        ...state,
        nodeTags: payload,
      };
    }
    case 'ADD_NODE_TAG': {
      const {
        nodeTags,
      } = state;

      return {
        ...state,
        nodeTags: [
          ...nodeTags,
          payload,
        ],
      };
    }
    case 'ADD_TAG': {
      const {
        tags,
      } = state;

      return {
        ...state,
        tags: [
          ...tags,
          payload,
        ],
      };
    }
    case 'REMOVE_NODE_TAG': {
      const {
        nodeTags,
      } = state;
      const index = nodeTags.map(tag => tag.name).indexOf(payload);

      return {
        ...state,
        nodeTags: [
          ...nodeTags.slice(0, index),
          ...nodeTags.slice(index + 1),
        ],
      };
    }
    // END ITEM TAGS ACTIONS

    // START ITEM EDIT LOCATION ACTIONS
    case 'SET_LOCATION_CANDIDATE_ROOTS': {
      return {
        ...state,
        nodeLocationCandidateRoots: payload,
      };
    }
    case 'SET_LOCATION': {
      return {
        ...state,
        nodeLocation: payload,
      };
    }
    case 'SET_LOCATION_PATH': {
      const {
        node,
      } = state;
      return {
        ...state,
        node: {
          ...node,
          locationPath: payload,
        },
      };
    }
    case 'SET_RECORD': {
      const {
        node,
      } = state;
      return {
        ...state,
        node: {
          ...node,
          record: {
            id: payload.recordId,
            name: payload.recordName,
          },
        },
      };
    }
    // END ITEM EDIT LOCATION ACTIONS

    // START ITEM PERMISSIONS ACTIONS
    case 'SET_INBOUND_GRANTS': {
      return {
        ...state,
        inboundGrants: payload,
      };
    }
    case 'SET_OUTBOUND_GRANTS': {
      return {
        ...state,
        outboundGrants: payload,
      };
    }
    case 'SET_DOWNSTREAM_GRANTS': {
      return {
        ...state,
        downstreamGrants: payload,
      };
    }
    case 'SET_NODE_GRANT_FRONTEND_PERMISSIONS': {
      return {
        ...state,
        nodeGrantFrontendPermissions: payload,
      };
    }
    case 'SET_NODE_GRANT_ATOMIC_PERMISSIONS': {
      return {
        ...state,
        nodeGrantAtomicPermissions: payload,
      };
    }
    case 'SET_NODE_GRANT_CONNECTION': {
      return {
        ...state,
        nodeGrantConnection: payload,
      };
    }
    // END ITEM PERMISSIONS ACTIONS
    default:
      throw new Error(`Unknown item action "${actionType}"!`);
  }
}

export {
  initNodeState,
  nodeReducer,
};
