import POST__nodes from 'network-layer/endpoints/POST__nodes';

function responseAdapter(response) {
  return response;
}

export default async function saveNode(authToken, perspectiveId, params) {
  const response = await POST__nodes(authToken, perspectiveId, params);

  return responseAdapter(response);
}
