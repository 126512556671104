import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import MUITextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  root: {
    // right padding for input to align the text
    '& input': {
      paddingRight: props => props.endAdornment ? theme.textField.input.paddingRightEndAdornment : theme.textField.input.paddingRight,
      fontSize: props => props.fontSize,
      fontWeight: props => props.fontWeight,
    },

    // boxShadow on input hover
    '& .MuiInputBase-root:hover': {
      boxShadow: props => props.disabled || props.title ? theme.textField.inputBase.disabledBoxShadow : theme.textField.inputBase.activeBoxShadow,
    },

    // label
    '& .MuiFormLabel-root': {
      color: props => props.hasError ? theme.textField.label.error : props.hasSuccess ? theme.textField.label.success : theme.textField.label.default,
      // label focused
      '&.Mui-focused': {
        color: props => props.hasError ? theme.textField.label.error : props.hasSuccess ? theme.textField.label.success : theme.textField.label.focused,
      },
    },

    // bg color
    '& .MuiFilledInput-root': {
      backgroundColor: props => props.title ? 'transparent' : theme.textField.background.activeColor,
      '&.Mui-focused': {
        backgroundColor: props => props.title ? 'transparent' : theme.textField.background.focusedColor,
        boxShadow: props => props.disabled || props.title ? theme.textField.inputBase.disabledBoxShadow : theme.textField.inputBase.activeBoxShadow,
      },
      // disabled bg color
      '&.Mui-disabled': {
        backgroundColor: theme.textField.background.disabledColor,
      },
    },

    // border
    '& .MuiFilledInput-underline': {
      '&:before': {
        borderBottom: props => props.disabled ? theme.textField.border.disabled : props.hasError ? theme.textField.border.error : props.hasSuccess ? theme.textField.border.success : theme.textField.border.defaultDisabled,
      },
      '&:after': {
        borderBottom: props => props.disabled ? theme.textField.border.disabled : props.hasError ? theme.textField.border.error : props.hasSuccess ? theme.textField.border.success : theme.textField.border.defaultActive,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: props => props.hasError ? theme.textField.border.error : props.hasSuccess ? theme.textField.border.success : theme.textField.border.hover,
      },
    },

    // adornment
    '& .MuiInputAdornment-filled': {
      '&.MuiInputAdornment-positionStart': {
        color: theme.textField.adornment.color,
        cursor: props => props.clickableAdornment ? theme.textField.adornment.pointer : theme.textField.adornment.default,
      },
      '&.MuiInputAdornment-positionEnd': {
        color: theme.textField.adornment.color,
        cursor: props => props.clickableAdornment ? theme.textField.adornment.pointer : theme.textField.adornment.default,
        '&:hover': {
          color: props => props.clickableAdornment ? theme.textField.adornment.hoverColor : theme.textField.adornment.color,
        },
      },
    },

    // helper text
    '& .MuiFormHelperText-root': {
      color: props => props.hasSuccess ? theme.textField.label.success : props.hasError ? theme.textField.label.error : theme.textField.label.default,
    },
  },
});

const TextField = ({
  title,
  disabled,
  success,
  error,
  clickableAdornment,
  InputProps,
  ...otherProps
}) => {

  const startAdornment = InputProps ? InputProps.startAdornment ? true : false : false;
  const endAdornment = InputProps ? InputProps.endAdornment ? true : false : false;

  const classes = useStyles({
    title,
    disabled,
    hasError: error,
    hasSuccess: success,
    startAdornment,
    endAdornment,
    clickableAdornment,
  });

  return (
    <MUITextField
      variant="filled"
      InputLabelProps={{
        shrink: true,
      }}
      disabled={disabled}
      className={classes.root}
      {...otherProps}
      InputProps={InputProps}
      fullWidth={true}
    />
  );
};

TextField.propTypes = {
  title: PropTypes.bool,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  clickableAdornment: PropTypes.bool,
  InputProps: PropTypes.object,
};

export default TextField;
