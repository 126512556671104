import genRecordTypes from 'services/ControlPanel/genRecordTypes';
import genTypes from 'services/ControlPanel/genTypes';
import genFieldDefinitions from 'services/ControlPanel/genFieldDefinitions';
import genTypesInRecordType from 'services/ControlPanel/genTypesInRecordType';
import genTypeCategories from 'services/ControlPanel/genTypeCategories';
import genTypeLocationGraph from 'services/ControlPanel/genTypeLocationGraph';
import genNodeLinkDefinitions from 'services/ControlPanel/genNodeLinkDefinitions';
import genTypeRelationships from 'services/ControlPanel/genTypeRelationships';
import genPropertyConfigurationHints from 'services/ControlPanel/genPropertyConfigurationHints';
import genNotificationPipelines from 'services/ControlPanel/genNotificationPipelines';
import genFieldWatchers from 'services/ControlPanel/genFieldWatchers';
import genUsers from 'services/ControlPanel/genUsers';
import genEhrHub from 'services/ControlPanel/genEhrHub';

function genDataModel(authToken, perspectiveId) {
  return new Promise((resolve, reject) => {
    Promise.all([
      genRecordTypes(authToken, perspectiveId),
      genTypes(authToken, perspectiveId),
      genFieldDefinitions(authToken, perspectiveId),
      genTypeCategories(authToken, perspectiveId),
      genTypeLocationGraph(authToken, perspectiveId),
      genNodeLinkDefinitions(authToken, perspectiveId),
      genTypeRelationships(authToken, perspectiveId),
      genPropertyConfigurationHints(authToken, perspectiveId),
      genNotificationPipelines(authToken, perspectiveId),
      genFieldWatchers(authToken, perspectiveId),
      genUsers(authToken, perspectiveId),
      genEhrHub(authToken, perspectiveId),
    ])
      .then(([
        recordTypes,
        types,
        fieldDefinitions,
        typeCategories,
        typeLocationGraph,
        nodeLinkDefinitions,
        typeRelationships,
        propertyConfigurationHints,
        notificationPipelines,
        fieldWatchers,
        users,
        ehrHubTypes,
      ]) => {
        const recordTypeIds = recordTypes.map(recordType => recordType.id);
        const recordTypes_Types = {};
        for (let recordTypeId of recordTypeIds) {
          recordTypes_Types[recordTypeId] = [];
        }

        Promise.all(
          recordTypes.map(recordType => {
            return genTypesInRecordType(authToken, perspectiveId, {recordTypeId: recordType.id});
          }),
        )
          .then(typeAssociations => {
            for (let i = 0; i < typeAssociations.length; i++) {
              recordTypes_Types[recordTypeIds[i]] = [...typeAssociations[i]];
            }

            resolve({
              recordTypes,
              types,
              fieldDefinitions,
              recordTypes_Types,
              typeCategories,
              typeLocationGraph,
              nodeLinkDefinitions,
              typeRelationships,
              propertyConfigurationHints,
              notificationPipelines,
              fieldWatchers,
              users,
              ehrHubTypes,
            });
          });
      });
  });
}

export default genDataModel;
