import React from 'react';
import {Transition} from 'react-transition-group';
import Divider from '@material-ui/core/Divider';
import Link from 'ui-library/components/Link';
import Typography from 'ui-library/components/Typography';

const PermissionGrantInformation = ({
  label,
  dismissLabel,
  explanatoryText,
}) => {

  const [showGrantInformation, setShowGrantInformation] = React.useState(false);
  const duration = 150;
  const defaultStyle = {
    transition: `height ${duration}ms ease-in-out`,
    height: 0,
    overflow: 'hidden',
  };
  const transitionStyles = {
    entering: {
      height: 'auto',
    },
    entered:  {
      height: 'auto',
    },
    exiting:  {
      height: 0,
    },
    exited:  {
      height: 0,
    },
  };

  return (
    <span>
      <Transition in={showGrantInformation} timeout={duration}>
        {state => (
          <div style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}>
            <div
              style={{
                paddingTop: 8,
              }}
            >
              <Divider />
              <div
                style={{
                  paddingTop: 8,
                }}
              >
                <Typography
                  variant="caption"
                  fontWeight="normal"
                  fontFamily="primary"
                  color="black"
                >
                  {explanatoryText}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Transition>

      <div
        style={{
          paddingTop: 8,
        }}
      >
        <Link
          value="#"
          label={showGrantInformation ? dismissLabel : label}
          color="primary"
          variant="x-small"
          fontWeight="normal"
          onClick={() => {
            setShowGrantInformation(!showGrantInformation);
          }}
        />
      </div>
    </span>
  );
};

export default PermissionGrantInformation;
