import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__search_filters_categoryTree(authToken, perspectiveId, rootCategoryId) {
  return await GET(
    `/search/filters/category-tree?rootCategoryId=${rootCategoryId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
