import DELETE__notifications_ID from 'network-layer/endpoints/DELETE__notifications_ID';

function responseAdapter(response) {
  return response;
}

export default async function deleteNotificationById(authToken, notificationId) {
  const response = await DELETE__notifications_ID(authToken, notificationId);

  return responseAdapter(response);
}
