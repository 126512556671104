import GET__connections from 'network-layer/endpoints/GET__connections';

function responseAdapter(response) {
  return response;
}

export default async function genConnections(authToken, perspectiveId) {
  const response = await GET__connections(authToken, perspectiveId);

  return responseAdapter(response);
}
