import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import TypeIcon from '@material-ui/icons/DonutLarge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import EditRecordAssignmentsIcon from '@material-ui/icons/SettingsInputComposite';
import DeleteIcon from '@material-ui/icons/Delete';
import LocationParentsIcon from '@material-ui/icons/AccountTree';
import Typography from '@material-ui/core/Typography';

const TypesList = ({
  types,
  onEditType,
  onEditRecordAssignments,
  onSetLocationParents,
}) => {
  return (
    <>
      {
        types
          .map(type => {
            const {
              id: typeId,
              name: typeName,
              aliases: typeAliases,
            } = type;

            return (
              <NestedBox
                key={typeId}>
                <ContentLine>
                  <TypeIcon/>&nbsp;&nbsp;
                  <ContentLineText>
                    <Typography
                      variant="body1">
                      {typeName}
                      {
                        !!typeAliases.length &&
                        <>
                          &nbsp;&nbsp;
                          <span
                            style={{
                              color: '#aaa',
                            }}>
                            ({typeAliases.join(', ')})
                          </span>
                        </>
                      }
                    </Typography>
                  </ContentLineText>
                  <Tooltip
                    placement="top"
                    title={`edit ${typeName}`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onEditType(typeId);
                        }}>
                        <EditIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`edit record assignments for ${typeName}`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onEditRecordAssignments(typeId);
                        }}>
                        <EditRecordAssignmentsIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`set location parents for ${typeName}`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onSetLocationParents(typeId);
                        }}>
                        <LocationParentsIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <IconButton disabled>
                    <DeleteIcon/>
                  </IconButton>
                </ContentLine>
              </NestedBox>
            );
          })
      }
    </>
  );
};

function areTypesTheSame(previousProps, nextProps) {
  const {
    types: previousTypes,
  } = previousProps;
  const {
    types: nextTypes,
  } = nextProps;

  if (previousTypes.length !== nextTypes.length) {
    return false;
  }

  for (let i = 0; i < previousTypes.length; i++) {
    const previousType = previousTypes[i];
    const nextType = nextTypes[i];

    const {
      id: previousTypeId,
      name: previousTypeName,
    } = previousType;
    const {
      id: nextTypeId,
      name: nextTypeName,
    } = nextType;

    if (
      previousTypeId !== nextTypeId ||
      previousTypeName !== nextTypeName
    ) {
      return false;
    }
  }

  return true;
}

export default React.memo(TypesList, areTypesTheSame);
