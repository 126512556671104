import React from 'react';
import Tab from '@material-ui/core/Tab';
import Typography from 'ui-library/components/Typography';
import {makeStyles} from '@material-ui/styles';
import {
  greyDark,
  greySemi,
  black,
  primary,
} from 'ui-library/color-palette';

const useStyles = makeStyles({
  root: {
    '&.MuiTab-root': {
      minWidth: 0,
      padding: '0 16px 0 12px',
      borderRadius: '4px 4px 0 0',
      '&:hover': {
        backgroundColor: `${greySemi}`,
      },
    },
  },
  labelContainer: {
    display: 'flex',
    borderRadius: '4px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '5px',
    color: props => props.selected ? `${primary}` : props.hovered ? `${black}` : `${greyDark}`,
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 0px 5px 12px',
    textAlign: 'center',
    color: props => props.selected ? `${black}` : props.hovered ? `${black}` : `${greyDark}`,
  },
});

const ContextualPanelTab = ({
  tabIndex,
  selectedTab,
  setSelectedTab,
  label,
  icon,
  hoveredTab,
  onMouseEnter,
  onMouseLeave,
  ...otherProps
}) => {

  const classes = useStyles({
    selected: selectedTab === tabIndex,
    hovered: hoveredTab === tabIndex,
  });

  return (
    <Tab
      {...otherProps}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classes.root}
      onClick={() => {
        if (tabIndex !== selectedTab) {
          setSelectedTab(tabIndex);
        }
      }}
      label={
        <div className={classes.labelContainer}>
          <div
            className={classes.iconContainer}
          >
            <Typography
              variant="caption"
              color="inherit"
            >
              {icon}
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography
              variant="caption"
              fontWeight="bold"
              color="inherit"
            >
              {label}
            </Typography>
          </div>
        </div>
      }
    />
  );
};

export default ContextualPanelTab;
