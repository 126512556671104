import PATCH__attachments_ID from 'network-layer/endpoints/PATCH__attachments_ID';

function responseAdapter(response) {
  return response;
}

export default async function updateAttachmentTitle(params) {
  const {
    authToken,
    currentPerspectiveId,
    id,
    title,
  } = params;
  const response = await PATCH__attachments_ID(authToken, currentPerspectiveId, id, {title});

  return responseAdapter(response);
}
