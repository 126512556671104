import React from 'react';
import AdminContext from 'contexts/Admin';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CreatePropertyConfigurationHintDialog from './CreatePropertyConfigurationHintDialog.react';
import EditPropertyConfigurationHintDialog from './EditPropertyConfigurationHintDialog.react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Prompt from 'components/admin/Prompt';

const PropertyConfigurationHintsList = ({
  match,
}) => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
  } = React.useContext(AdminContext);
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  const propertyTypes = DataModelQueries.selectPropertyTypes(DataModel);
  const propertyConfigurationHints = DataModelQueries.selectPropertyConfigurationHints(DataModel);
  const unconfigurablePropertyTypes = propertyTypes.filter(type => {
    for (let propertyConfigurationHint of propertyConfigurationHints) {
      const {
        targetTypeId,
      } = propertyConfigurationHint;
      if (type.id === targetTypeId) {
        return false;
      }
    }
    return true;
  });

  return (
    <div>
      <Typography
        variant="h4">
        Property configurators
      </Typography>
      <Typography
        variant="body2">
        edit or create configurators for property types
      </Typography>
      <br/><br/>
      {
        propertyTypes.length === propertyConfigurationHints.length ?
          <Typography
            variant="body2">
            <em>All property types have configurators defined. Good job!</em>
          </Typography>
          :
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              dispatchLocalAction({
                type: 'CREATE_PROPERTY_CONFIGURATION_HINT',
              });
            }}>
            create a configurator
          </Button>
      }

      <br/><br/>
      {
        propertyConfigurationHints.map(hint => {
          const {
            id: propertyConfigurationHintId,
            targetTypeId,
            virtualLocationTreeTitle,
            virtualLocationTreeDescription,
          } = hint;
          return (

            <NestedBox
              key={propertyConfigurationHintId}>
              <ContentLine>
                <ContentLineText>
                  <Typography
                    variant="h5">
                    <strong>Property type name:</strong> {DataModelQueries.selectTypeById(DataModel, targetTypeId).name}
                  </Typography>
                  <br/>
                  <Typography
                    variant="body2">
                    <strong>Top-level title:</strong> {virtualLocationTreeTitle}
                  </Typography>
                  <br/>
                  <Typography
                    variant="body2">
                    <strong>Top-level description:</strong> {virtualLocationTreeDescription}
                  </Typography>
                </ContentLineText>
                <Link
                  to={`${match.url}/${propertyConfigurationHintId}`}>
                  <Typography
                    variant="body1">
                    edit configurator hints
                  </Typography>
                </Link>
                <IconButton
                  onClick={() => {
                    dispatchLocalAction({
                      type: 'EDIT_PROPERTY_CONFIGURATION_HINT',
                      payload: {
                        propertyConfigurationHintId,
                        virtualLocationTreeTitle,
                        virtualLocationTreeDescription,
                      },
                    });
                  }}>
                  <EditIcon/>
                </IconButton>
                <IconButton
                  onClick={() => {
                    dispatchLocalAction({
                      type: 'DELETE_PROPERTY_CONFIGURATION_HINT',
                      payload: propertyConfigurationHintId,
                    });
                  }}>
                  <DeleteIcon/>
                </IconButton>
              </ContentLine>
            </NestedBox>
          );
        })
      }

      <CreatePropertyConfigurationHintDialog
        open={
          localState.status === 'creating'
        }
        onClose={() => {
          dispatchLocalAction({
            type: 'RESET',
          });
        }}
        propertyTypes={unconfigurablePropertyTypes}
        targetTypeId={localState.targetTypeId}
        onSetTargetTypeId={value => {
          dispatchLocalAction({
            type: 'SET_TARGET_TYPE_ID',
            payload: value,
          });
        }}
        virtualLocationTreeTitle={localState.virtualLocationTreeTitle}
        onSetVirtualLocationTreeTitle={value => {
          dispatchLocalAction({
            type: 'SET_VIRTUAL_LOCATION_TREE_TITLE',
            payload: value,
          });
        }}
        virtualLocationTreeDescription={localState.virtualLocationTreeDescription}
        onSetVirtualLocationTreeDescription={value => {
          dispatchLocalAction({
            type: 'SET_VIRTUAL_LOCATION_TREE_DESCRIPTION',
            payload: value,
          });
        }}
        onSave={async() => {
          const {
            targetTypeId,
            virtualLocationTreeTitle,
            virtualLocationTreeDescription,
          } = localState;
          dispatchLocalAction({
            type: 'REQUEST_CREATE_PROPERTY_CONFIGURATION_HINT',
          });
          try {
            const newPropertyConfigurationHint = await DataModelAPI.createPropertyConfigurationHint(
              authToken,
              perspectiveId,
              {
                targetTypeId,
                virtualLocationTreeTitle,
                virtualLocationTreeDescription,
              },
            );
            dispatchLocalAction({
              type: 'RESET',
            });
            dispatchDataModelAction({
              type: 'ADD_PROPERTY_CONFIGURATION_HINT',
              payload: newPropertyConfigurationHint,
            });
          } catch(error) {
            throw error;
          }
        }}
      />

      {
        localState.status === 'pending create' &&
          <Prompt
            open
            title="Please wait..."
            text="Creating configurator..."
          />
      }

      <EditPropertyConfigurationHintDialog
        open={
          localState.status === 'editing'
        }
        onClose={() => {
          dispatchLocalAction({
            type: 'RESET',
          });
        }}
        virtualLocationTreeTitle={localState.virtualLocationTreeTitle}
        onSetVirtualLocationTreeTitle={value => {
          dispatchLocalAction({
            type: 'SET_VIRTUAL_LOCATION_TREE_TITLE',
            payload: value,
          });
        }}
        virtualLocationTreeDescription={localState.virtualLocationTreeDescription}
        onSetVirtualLocationTreeDescription={value => {
          dispatchLocalAction({
            type: 'SET_VIRTUAL_LOCATION_TREE_DESCRIPTION',
            payload: value,
          });
        }}
        onSave={async() => {
          const {
            propertyConfigurationHintId,
            virtualLocationTreeTitle,
            virtualLocationTreeDescription,
          } = localState;
          dispatchLocalAction({
            type: 'REQUEST_EDIT_PROPERTY_CONFIGURATION_HINT',
          });
          try {
            await DataModelAPI.updatePropertyConfigurationHint(
              authToken,
              perspectiveId,
              {
                propertyConfigurationHintId,
                virtualLocationTreeTitle,
                virtualLocationTreeDescription,
              },
            );
            dispatchLocalAction({
              type: 'RESET',
            });
            dispatchDataModelAction({
              type: 'UPDATE_PROPERTY_CONFIGURATION_HINT',
              payload: {
                propertyConfigurationHintId,
                virtualLocationTreeTitle,
                virtualLocationTreeDescription,
              },
            });
          } catch(error) {
            throw error;
          }
        }}
      />

      {
        localState.status === 'pending edit' &&
          <Prompt
            open
            title="Please wait..."
            text="Updating configurator..."
          />
      }

      {
        localState.status === 'deleting' &&
          <Prompt
            open
            title="Confirm operation"
            text="This is a highly destructive operation! This configurator will be deleted entirely. Are you sure you want to do this?"
            confirmLabel="yes, delete this configurator"
            onCancel={() => {
              dispatchLocalAction({
                type: 'RESET',
              });
            }}
            onConfirm={async() => {
              const {
                propertyConfigurationHintId,
              } = localState;
              dispatchLocalAction({
                type: 'REQUEST_DELETE_PROPERTY_CONFIGURATION_HINT',
              });
              try {
                await DataModelAPI.deletePropertyConfigurationHint(
                  authToken,
                  perspectiveId,
                  {
                    propertyConfigurationHintId,
                  },
                );
                dispatchDataModelAction({
                  type: 'REMOVE_PROPERTY_CONFIGURATION_HINT',
                  payload: propertyConfigurationHintId,
                });
                dispatchLocalAction({
                  type: 'RESET',
                });
              } catch(error) {
                throw error;
              }
            }}
          />
      }

      {
        localState.status === 'pending delete' &&
          <Prompt
            open
            title="Please wait..."
            text="Deleting configurator..."
          />
      }
    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'CREATE_PROPERTY_CONFIGURATION_HINT': {
      return {
        ...initState(),
        status: 'creating',
      };
    }

    case 'REQUEST_CREATE_PROPERTY_CONFIGURATION_HINT': {
      return {
        ...state,
        status: 'pending create',
      };
    }

    case 'EDIT_PROPERTY_CONFIGURATION_HINT': {
      const {
        propertyConfigurationHintId,
        virtualLocationTreeTitle,
        virtualLocationTreeDescription,
      } = payload;

      return {
        ...initState(),
        status: 'editing',
        propertyConfigurationHintId,
        virtualLocationTreeTitle,
        virtualLocationTreeDescription,
      };
    }

    case 'REQUEST_EDIT_PROPERTY_CONFIGURATION_HINT': {
      return {
        ...state,
        status: 'pending edit',
      };
    }

    case 'DELETE_PROPERTY_CONFIGURATION_HINT': {
      const propertyConfigurationHintId = payload;

      return {
        ...initState(),
        status: 'deleting',
        propertyConfigurationHintId,
      };
    }

    case 'REQUEST_DELETE_PROPERTY_CONFIGURATION_HINT': {
      return {
        ...state,
        status: 'pending delete',
      };
    }

    case 'SET_TARGET_TYPE_ID': {
      const targetTypeId = payload;

      return {
        ...state,
        targetTypeId,
      };
    }

    case 'SET_VIRTUAL_LOCATION_TREE_TITLE': {
      const virtualLocationTreeTitle = payload;

      return {
        ...state,
        virtualLocationTreeTitle,
      };
    }

    case 'SET_VIRTUAL_LOCATION_TREE_DESCRIPTION': {
      const virtualLocationTreeDescription = payload;

      return {
        ...state,
        virtualLocationTreeDescription,
      };
    }

    case 'RESET': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      creating
      pending create
      editing
      pending edit
      deleting
      pending delete
    */
    status: 'idle',
    propertyConfigurationHintId: '',
    targetTypeId: '',
    virtualLocationTreeTitle: '',
    virtualLocationTreeDescription: '',
  };
}

export default PropertyConfigurationHintsList;
