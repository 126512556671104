import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import Typography from 'ui-library/components/Typography';

const useStyles = makeStyles({
  headerTitle: {
    paddingTop: theme.modalCardHeader.headerTitle.paddingTop,
    paddingLeft: theme.modalCardHeader.headerTitle.paddingLeft,
    border: theme.modalCardHeader.headerTitle.border,
    whiteSpace: theme.modalCardHeader.headerTitle.whiteSpace,
    overflow: theme.modalCardHeader.headerTitle.overflow,
    textOverflow: theme.modalCardHeader.headerTitle.textOverflow,
  },
});

const ModalCardHeaderTitle = ({
  children,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.headerTitle}>
      <Typography
        variant="h4"
        fontWeight="bold"
        fontFamily="primary"
      >
        {children}
      </Typography>
    </div>
  );
};

ModalCardHeaderTitle.propTypes = {
  children: PropTypes.node,
};

export default ModalCardHeaderTitle;
