import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Grid from '@material-ui/core/Grid';

import FileDNDTarget from 'ui-library/components/FileDNDTarget';
import NodeAttachmentUpload from 'ui-library/components/NodeAttachmentUpload';
import NodeAttachmentDownload from 'ui-library/components/NodeAttachmentDownload';
import NodeAttachmentList from 'ui-library/components/NodeAttachmentList';
import NodeAttachmentPreviewImage from 'ui-library/components/NodeAttachmentPreviewImage';
import Typography from 'ui-library/components/Typography';

import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';

import {
  greyLighter,
} from 'ui-library/color-palette';

import prettyFileSize from 'utils/prettyFileSize';
import getFileExtension from 'utils/getFileExtension';
import getFileName from 'utils/getFileName';

import GlobalConfigContext from 'contexts/GlobalConfig';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import uploadAttachment from 'services/Attachments/uploadAttachment';
import deleteAttachment from 'services/Attachments/deleteAttachment';
import updateAttachmentTitle from 'services/Attachments/updateAttachmentTitle';
import downloadAttachments from 'services/Attachments/downloadAttachments';
import genThumbnails from 'services/Attachments/genThumbnails';
import genImagePreview from 'services/Attachments/genImagePreview';
import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';

import {
  attachmentTitle as attachmentTitleValidator,
} from 'utils/validator';

const TargetContainer = styled.div`
  width: 100%;
  padding: 28px 60px;
  background-color: ${greyLighter};
`;

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const NoAccessContainer = styled.div`
  padding: 1px 35px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const NodeAttachments = ({
  nodeState,
  dispatchNodeAction,
  setError,
}) => {
  const {
    node: {
      attachments,
      id: nodeId,
    },
    nodePermissions,
    attachmentsById,
    attachmentIds,
    uploadsById,
    uploadIds,
    attachmentsSortDirection,
    attachmentsSortCriteria,
    attachmentsRequireSort,
  } = nodeState;

  const [tabState, setTabState] = React.useState('not loaded'); // 'not loaded', 'loading', 'loaded'
  const [isPreviewLoading, setIsPreviewLoading] = React.useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);
  const [previewUrl, setPreviewUrl] = React.useState(null);
  const [fileDNDTargetState, setFileDNDTargetState] = React.useState('idle');
  const fileInput = React.useRef(null);

  const {
    frontendPermissions: {
      manageAttachments,
    },
  } = nodePermissions;

  const {
    attachments: {
      deniedFileExtensions,
      deniedMimeTypes,
      maxFileUploadSize,
    },
  } = React.useContext(GlobalConfigContext);

  const {
    authToken,
  } = React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  const {
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  React.useEffect(() => {
    const loadAttachments = async() => {
      setTabState('loading');

      try {
        const response = await genNodeByNodeId({
          authToken,
          perspectiveId: currentPerspectiveId,
          nodeId: nodeId,
          queryParams: ['attachments'],
        });

        const {
          attachments,
        } =  response;

        const attachmentsById = {};
        const attachmentIds = attachments.map(attachment => {
          attachmentsById[attachment.id] = {
            id: attachment.id,
            title: attachment.title,
            previousTitle: attachment.title,
            isEditable: false,
            titleError: '',
            isTitleSaving: false,
            hasPreview: attachment.hasThumbnail,
            thumbnailBlobURL: '',
            fileExtension: attachment.fileExtension,
            bytes: attachment.fileSize,
            fileName: attachment.fileName,
            fileSize: prettyFileSize(attachment.fileSize),
            progress: '',
            isDownloading: false,
            isDeleting: false,
            isDeleteDialogVisible: false,
            anchorEl: null,
            isMenuOpen: false,
            donwloadRequest: null,
          };
          return attachment.id;
        });

        attachmentIds.sort((a,b) => (attachmentsById[a].title.toLowerCase() > attachmentsById[b].title.toLowerCase()) ? 1 : ((attachmentsById[b].title.toLowerCase() > attachmentsById[a].title.toLowerCase()) ? -1 : 0));

        dispatchNodeAction({
          type: 'HYDRATE_ATTACHMENTS',
          payload: {
            attachmentIds,
            attachmentsById,
          },
        });
      } catch (e) {
        setError(e);
      }

      setTabState('loaded');
    };

    if (tabState !== 'loaded' && manageAttachments) {
      loadAttachments();
    }
  }, [attachments, authToken, currentPerspectiveId, dispatchNodeAction, manageAttachments, nodeId, setError, tabState]);

  React.useEffect(() => {
    if (attachmentsRequireSort) {
      if (attachmentsSortCriteria === 'name') {
        if (attachmentsSortDirection === 'ascending') {
          const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].title.toLowerCase() < attachmentsById[b].title.toLowerCase()) ? 1 : ((attachmentsById[b].title.toLowerCase() < attachmentsById[a].title.toLowerCase()) ? -1 : 0));
          dispatchNodeAction({
            type: 'SET_ATTACHMENT_IDS',
            payload: sortedAttachmentIds,
          });
        } else {
          const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].title.toLowerCase() > attachmentsById[b].title.toLowerCase()) ? 1 : ((attachmentsById[b].title.toLowerCase() > attachmentsById[a].title.toLowerCase()) ? -1 : 0));
          dispatchNodeAction({
            type: 'SET_ATTACHMENT_IDS',
            payload: sortedAttachmentIds,
          });
        }
      } else {
        if (attachmentsSortDirection === 'ascending') {
          const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].bytes < attachmentsById[b].bytes) ? 1 : ((attachmentsById[b].bytes < attachmentsById[a].bytes) ? -1 : 0));
          dispatchNodeAction({
            type: 'SET_ATTACHMENT_IDS',
            payload: sortedAttachmentIds,
          });
        } else {
          const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].bytes > attachmentsById[b].bytes) ? 1 : ((attachmentsById[b].bytes > attachmentsById[a].bytes) ? -1 : 0));
          dispatchNodeAction({
            type: 'SET_ATTACHMENT_IDS',
            payload: sortedAttachmentIds,
          });
        }
      }
      dispatchNodeAction({
        type: 'SET_ATTACHMENTS_REQUIRE_SORT',
        payload: false,
      });
    }
  });

  React.useEffect(() => {
    attachmentIds.forEach(async(id) => {
      const {
        hasPreview,
        thumbnailBlobURL,
        fileName,
      } = attachmentsById[id];

      if (hasPreview && !thumbnailBlobURL) {
        try {
          const thumbnailResponse = await genThumbnails({
            authToken,
            currentPerspectiveId,
            size: 60,
            fileName,
          });

          const thumbnailBlobURL = URL.createObjectURL(thumbnailResponse);

          dispatchNodeAction({
            type: 'SET_ATTACHMENT_THUMBNAIL_BLOB_URL',
            payload: {
              id,
              thumbnailBlobURL,
            },
          });

        } catch(error) {
          setError(error);
        }
      }
    });
  });

  React.useEffect(() => {
    uploadIds.forEach(async(uploadId) => {
      const {
        file,
        request,
      } = uploadsById[uploadId];

      // we have an upload without a request
      if (!request) {
        // create the upload request
        const uploadRequest = new XMLHttpRequest();

        // event listener for upload finish (push into attachment)
        uploadRequest.addEventListener('readystatechange', () => {
          if (uploadRequest.readyState === XMLHttpRequest.DONE) {
            const status = uploadRequest.status;
            if (status >= 200 && status < 400) {
              const attachment = JSON.parse(uploadRequest.responseText);
              const {
                id: downloadId,
                title,
                fileName,
                fileSize,
                hasThumbnail,
                fileExtension,
              } = attachment;

              dispatchNodeAction({
                type: 'REMOVE_UPLOAD',
                payload: {
                  id: uploadId,
                },
              });
              dispatchNodeAction({
                type: 'PUSH_ATTACHMENT',
                payload: {
                  id: downloadId,
                  title,
                  previousTitle: title,
                  isEditable: false,
                  titleError: '',
                  isTitleSaving: false,
                  hasPreview: hasThumbnail,
                  thumbnailBlobURL: '',
                  fileExtension,
                  bytes: fileSize,
                  fileName,
                  fileSize: prettyFileSize(fileSize),
                  progress: '',
                  isDownloading: false,
                  isDeleting: false,
                  isDeleteDialogVisible: false,
                  anchorEl: null,
                  isMenuOpen: false,
                  downloadRequest: null,
                },
              });
              dispatchNodeAction({
                type: 'SET_ATTACHMENTS_REQUIRE_SORT',
                payload: true,
              });
            } else {
              dispatchNodeAction({
                type: 'SET_UPLOAD_ERROR',
                payload: {
                  id: uploadId,
                  errorMessage: 'An unexpected error has occured.',
                },
              });
            }
          }
        });

        // event listener for upload progress
        uploadRequest.upload.addEventListener('progress', event => {
          const {
            loaded: bytesTransferred,
            total: bytesTotal,
          } = event;

          dispatchNodeAction({
            type: 'UPDATE_BYTES_TRANSFERED',
            payload: {
              id: uploadId,
              bytesTransferred: prettyFileSize(bytesTransferred),
            },
          });

          dispatchNodeAction({
            type: 'UPDATE_IS_UPLOADING',
            payload: {
              id: uploadId,
              isUploading: true,
            },
          });

          dispatchNodeAction({
            type: 'UPDATE_UPLOAD_PROGRESS',
            payload: {
              id: uploadId,
              progress: `${bytesTransferred / bytesTotal * 100}%`,
            },
          });
        });

        // event listener for aborting the upload
        uploadRequest.addEventListener('abort', () => {
          dispatchNodeAction({
            type: 'REMOVE_UPLOAD',
            payload: {
              id: uploadId,
            },
          });
        });

        // add request for this upload in the state
        dispatchNodeAction({
          type: 'ADD_UPLOAD_REQUEST',
          payload: {
            id: uploadId,
            request: uploadRequest,
          },
        });

        // call the upload service passing params (from file) & the upload request
        const params = {
          nodeId: nodeId,
          title: getFileName(file.name),
          file,
        };

        try {
          await uploadAttachment(authToken, currentPerspectiveId, uploadRequest, params);
        } catch(error) {
          setError(error);
          dispatchNodeAction({
            type: 'SET_UPLOAD_ERROR',
            payload: {
              id: uploadId,
            },
          });
        }
      }
    });
  });

  const uploadFiles = (params) => {
    const MAX_FILE_UPLOAD_SIZE = parseInt(maxFileUploadSize);
    const {
      files,
    } = params;

    const filesExceedingUploadSizeLimit = files.filter(file => file.size > MAX_FILE_UPLOAD_SIZE);
    const filesWithErroneousMimeType = files.filter(file => deniedMimeTypes.includes(file.type));
    const filesWithErroneousExtension = files.filter(file => deniedFileExtensions.includes(getFileExtension(file.name)));
    const filesWithInvalidTitleLength = files.filter(file => file.name.length - getFileExtension(file.name).length - 1 > 60);
    const filesWithInvalidCharacters = files.filter(file => attachmentTitleValidator((getFileName(file.name))));

    const allowedFiles = files.filter(file => file.size <= MAX_FILE_UPLOAD_SIZE &&
      !deniedMimeTypes.includes(file.type) &&
      !deniedFileExtensions.includes(getFileExtension(file.name)) &&
      file.name.length - getFileExtension(file.name).length - 1 <= 60 &&
      !attachmentTitleValidator(getFileName(file.name)),
    );

    if (files.length === filesExceedingUploadSizeLimit.length) {
      return window.alert(
        `The following file(s) exceed the maximum allowed size of ${prettyFileSize(MAX_FILE_UPLOAD_SIZE)}:\n\n` +
        filesExceedingUploadSizeLimit.map(file => `${file.name} (${prettyFileSize(file.size)})`).join('\n'),
      );
    }

    if (files.length === filesWithErroneousMimeType.length) {
      return window.alert(
        'The following file(s) have types that are not allowed:\n\n' +
        filesWithErroneousMimeType.map(file => `${file.name} (${file.type})`).join('\n'),
      );
    }

    if (files.length === filesWithErroneousExtension.length) {
      return window.alert(
        'The following file(s) have extensions that are not allowed:\n\n' +
        filesWithErroneousExtension.map(file => `${file.name} (${getFileExtension(file.name)})`).join('\n'),
      );
    }

    if (files.length === filesWithInvalidTitleLength.length) {
      return window.alert(
        'The following file(s) have title that are longer than 60 characters:\n\n' +
        filesWithInvalidTitleLength.map(file => `${file.name}`).join('\n'),
      );
    }

    if (files.length === filesWithInvalidCharacters.length) {
      return window.alert(
        'The following file(s) contain invalid characters in their name:\n\n' +
        filesWithInvalidCharacters.map(file => `${file.name}`).join('\n'),
      );
    }

    let continueUpload = true;

    const uploadError = filesExceedingUploadSizeLimit.length ||
      filesWithErroneousMimeType.length ||
      filesWithErroneousExtension.length ||
      filesWithInvalidTitleLength.length ||
      filesWithInvalidCharacters.length;

    if (uploadError) {
      continueUpload = window.confirm(
        'The following files will not be uploaded because:' +
        `${filesExceedingUploadSizeLimit.length ? '\n\n- exceed the maximum allowed size:\n' + filesExceedingUploadSizeLimit.map(file => `${file.name} (${prettyFileSize(file.size)})`).join('\n') : ''}` +
        `${filesWithErroneousMimeType.length ? '\n\n- contain types that are not allowed:\n' + filesWithErroneousMimeType.map(file => `${file.name} (${file.type})`).join('\n') : ''}` +
        `${filesWithErroneousExtension.length ? '\n\n- contain extensions that are not allowed:\n' + filesWithErroneousExtension.map(file => `${file.name} (${getFileExtension(file.name)})`).join('\n') : ''}` +
        `${filesWithInvalidTitleLength.length ? '\n\n- have titles longer than 60 chars:\n' + filesWithInvalidTitleLength.map(file => `${file.name}`).join('\n') : ''}` +
        '\n\nContinue with the rest?\n',
      );
    }

    if (continueUpload) {
      allowedFiles.forEach(file => {
        dispatchNodeAction({
          type: 'PUSH_UPLOAD',
          payload: {
            isUploading: false,
            bytesTransferred: prettyFileSize(0),
            fileName: file.name,
            fileSize: prettyFileSize(file.size),
            progress: '0%',
            errorMessage: '',
            file,
          },
        });
      });
    }
  };
  const handleNameSort = () => {
    if (attachmentsSortCriteria === 'name') {
      if (attachmentsSortDirection === 'ascending') {
        dispatchNodeAction({
          type: 'SET_SORT_DIRECTION',
          payload: 'descending',
        });
        const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].title.toLowerCase() > attachmentsById[b].title.toLowerCase()) ? 1 : ((attachmentsById[b].title.toLowerCase() > attachmentsById[a].title.toLowerCase()) ? -1 : 0));
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_IDS',
          payload: sortedAttachmentIds,
        });
      } else {
        dispatchNodeAction({
          type: 'SET_SORT_DIRECTION',
          payload: 'ascending',
        });
        const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].title.toLowerCase() < attachmentsById[b].title.toLowerCase()) ? 1 : ((attachmentsById[b].title.toLowerCase() < attachmentsById[a].title.toLowerCase()) ? -1 : 0));
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_IDS',
          payload: sortedAttachmentIds,
        });
      }
    } else {
      dispatchNodeAction({
        type: 'SET_SORT_CRITERIA',
        payload: 'name',
      });
      dispatchNodeAction({
        type: 'SET_SORT_DIRECTION',
        payload: 'ascending',
      });
      const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].title.toLowerCase() < attachmentsById[b].title.toLowerCase()) ? 1 : ((attachmentsById[b].title.toLowerCase() < attachmentsById[a].title.toLowerCase()) ? -1 : 0));
      dispatchNodeAction({
        type: 'SET_ATTACHMENT_IDS',
        payload: sortedAttachmentIds,
      });
    }
  };
  const handleSizeSort = () => {
    if (attachmentsSortCriteria === 'size') {
      if (attachmentsSortDirection === 'ascending') {
        dispatchNodeAction({
          type: 'SET_SORT_DIRECTION',
          payload: 'descending',
        });
        const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].bytes > attachmentsById[b].bytes) ? 1 : ((attachmentsById[b].bytes > attachmentsById[a].bytes) ? -1 : 0));
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_IDS',
          payload: sortedAttachmentIds,
        });
      } else {
        dispatchNodeAction({
          type: 'SET_SORT_DIRECTION',
          payload: 'ascending',
        });
        const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].bytes < attachmentsById[b].bytes) ? 1 : ((attachmentsById[b].bytes < attachmentsById[a].bytes) ? -1 : 0));
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_IDS',
          payload: sortedAttachmentIds,
        });
      }
    } else {
      dispatchNodeAction({
        type: 'SET_SORT_CRITERIA',
        payload: 'size',
      });
      dispatchNodeAction({
        type: 'SET_SORT_DIRECTION',
        payload: 'ascending',
      });
      const sortedAttachmentIds = attachmentIds.sort((a,b) => (attachmentsById[a].bytes < attachmentsById[b].bytes) ? 1 : ((attachmentsById[b].bytes < attachmentsById[a].bytes) ? -1 : 0));
      dispatchNodeAction({
        type: 'SET_ATTACHMENT_IDS',
        payload: sortedAttachmentIds,
      });
    }
  };
  const handleUploadAbort = (id) => {
    const {
      request,
    } = uploadsById[id];

    request.abort();
  };
  const handleDownloadAbort = (id) => {
    const {
      downloadRequest,
    } = attachmentsById[id];

    downloadRequest.abort();
  };
  const handleRemoveErroneousUpload = (id) => {
    dispatchNodeAction({
      type: 'REMOVE_UPLOAD',
      payload: {
        id,
      },
    });
  };
  const onTitleChange = (id, value) => {
    dispatchNodeAction({
      type: 'SET_ATTACHMENT_TITLE',
      payload: {
        id,
        title: value,
      },
    });
    dispatchNodeAction({
      type: 'SET_ATTACHMENT_TITLE_ERROR',
      payload: {
        id,
        titleError: attachmentTitleValidator(value),
      },
    });
  };
  const saveTitle = async(id) => {
    const {
      title,
      titleError,
    } = attachmentsById[id];
    if (!titleError) {
      try {
        dispatchNodeAction({
          type: 'SET_IS_ATTACHMENT_TITLE_SAVING',
          payload: {
            id,
            isTitleSaving: true,
          },
        });
        await updateAttachmentTitle({
          authToken,
          currentPerspectiveId,
          id,
          title,
        });
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_PREVIOUS_TITLE',
          payload: {
            id,
            previousTitle: title,
          },
        });
        dispatchNodeAction({
          type: 'SET_IS_ATTACHMENT_TITLE_EDITABLE',
          payload: {
            id,
            isEditable: false,
          },
        });
        dispatchNodeAction({
          type: 'SET_IS_ATTACHMENT_TITLE_SAVING',
          payload: {
            id,
            isTitleSaving: false,
          },
        });
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_TITLE_ERROR',
          payload: {
            id,
            titleError: '',
          },
        });
      } catch(error) {
        setError(error);
      }
    }
  };
  const revertTitle = (id) => {
    const {
      previousTitle,
    } = attachmentsById[id];
    dispatchNodeAction({
      type: 'SET_ATTACHMENT_TITLE',
      payload: {
        id,
        title: previousTitle,
      },
    });
    dispatchNodeAction({
      type: 'SET_IS_ATTACHMENT_TITLE_EDITABLE',
      payload: {
        id,
        isEditable: false,
      },
    });
    dispatchNodeAction({
      type: 'SET_ATTACHMENT_TITLE_ERROR',
      payload: {
        id,
        titleError: '',
      },
    });
  };
  const setIsEditable = (id, value) => {
    dispatchNodeAction({
      type: 'SET_IS_ATTACHMENT_TITLE_EDITABLE',
      payload: {
        id,
        isEditable: value,
      },
    });
  };
  const displayDeleteDialog = (id, value) => {
    dispatchNodeAction({
      type: 'SET_DISPLAY_DELETE_DIALOG',
      payload: {
        id,
        isDeleteDialogVisible: value,
      },
    });
  };
  const performDelete = async(id) => {
    try {
      await deleteAttachment({
        authToken,
        currentPerspectiveId,
        id,
      });
      dispatchNodeAction({
        type: 'SET_DISPLAY_DELETE_DIALOG',
        payload: {
          id,
          isDeleteDialogVisible: false,
        },
      });
      dispatchNodeAction({
        type: 'REMOVE_ATTACHMENT',
        payload: {
          id,
        },
      });
    } catch(error) {
      setError(error);
    }
  };
  const handleDownload = async(id) => {
    const {
      title,
      fileExtension,
      fileName,
      bytes,
    } = attachmentsById[id];

    dispatchNodeAction({
      type: 'SET_IS_DOWNLOAD_MENU_OPEN',
      payload: {
        id,
        isMenuOpen: false,
      },
    });
    dispatchNodeAction({
      type: 'SET_IS_ATTACHMENT_DOWNLOADING',
      payload: {
        id,
        isDownloading: true,
      },
    });
    dispatchNodeAction({
      type: 'SET_ATTACHMENT_DOWNLOAD_PROGRESS',
      payload: {
        id,
        progress: '0%',
      },
    });
    const downloadRequest = new XMLHttpRequest();

    downloadRequest.addEventListener('readystatechange', () => {
      if (downloadRequest.readyState === XMLHttpRequest.DONE) {
        const status = downloadRequest.status;
        if (status >= 200 && status < 400) {
          dispatchNodeAction({
            type: 'SET_ATTACHMENT_DOWNLOAD_REQUEST',
            payload: {
              id,
              downloadRequest: null,
            },
          });
          dispatchNodeAction({
            type: 'SET_IS_ATTACHMENT_DOWNLOADING',
            payload: {
              id,
              isDownloading: false,
            },
          });
          const url = URL.createObjectURL(downloadRequest.response);
          const invisibleAnchor = document.createElement('a');
          invisibleAnchor.href = url;
          invisibleAnchor.download = `${title}.${fileExtension}`;
          document.body.appendChild(invisibleAnchor);
          invisibleAnchor.click();
          document.body.removeChild(invisibleAnchor);
        }
      }
    });

    // event listener for download progress
    downloadRequest.addEventListener('progress', event => {
      const {
        loaded,
      } = event;
      dispatchNodeAction({
        type: 'SET_ATTACHMENT_DOWNLOAD_PROGRESS',
        payload: {
          id,
          progress: `${parseInt(loaded / bytes * 100)}%`,
        },
      });
    });

    // event listener for aborting the download
    downloadRequest.addEventListener('abort', () => {
      dispatchNodeAction({
        type: 'SET_IS_ATTACHMENT_DOWNLOADING',
        payload: {
          id,
          isDownloading: false,
        },
      });
      dispatchNodeAction({
        type: 'SET_ATTACHMENT_DOWNLOAD_REQUEST',
        payload: {
          id,
          downloadRequest: null,
        },
      });
    });

    dispatchNodeAction({
      type: 'SET_ATTACHMENT_DOWNLOAD_REQUEST',
      payload: {
        id,
        downloadRequest,
      },
    });

    const params = {
      fileName,
    };
    try {
      await downloadAttachments(authToken, currentPerspectiveId, downloadRequest, params);
    } catch(error) {
      setError(error);
    }
  };
  const handleClosePreview = () => {
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_NODE_PANEL',
      payload: true,
    });
    setIsPreviewOpen(false);
  };
  const handlePreview = async(id) => {
    const {
      fileName,
    } = attachmentsById[id];

    setIsPreviewLoading(true);
    try {
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_NODE_PANEL',
        payload: false,
      });
      setIsPreviewOpen(true);
      const previewImageResponse = await genImagePreview({
        authToken,
        currentPerspectiveId,
        fileName,
      });

      const thumbnailBlobURL = URL.createObjectURL(previewImageResponse);

      setPreviewUrl(thumbnailBlobURL);

      setIsPreviewLoading(false);
    } catch(error) {
      setError(error);
    }
  };
  const handleCloseDownloadMenu = (id) => {
    dispatchNodeAction({
      type: 'SET_IS_DOWNLOAD_MENU_OPEN',
      payload: {
        id,
        isMenuOpen: false,
      },
    });
  };
  const setIsMenuOpen = (id, value) => {
    dispatchNodeAction({
      type: 'SET_IS_DOWNLOAD_MENU_OPEN',
      payload: {
        id,
        isMenuOpen: value,
      },
    });
  };
  const setMenuAnchorEl = (id, target) => {
    dispatchNodeAction({
      type: 'SET_DOWNLOAD_MENU_ANCHOR',
      payload: {
        id,
        anchorEl: target,
      },
    });
  };

  if (!nodePermissions) {
    return null;
  }

  if (!manageAttachments) {
    return (
      <NoAccessContainer>
        <Typography
          variant="h5"
          color="grey"
          fontWeight="bold"
        >
        You don’t have permission to view attachments for this item. Please contact the relevant Connection to update your permissions if necessary.
        </Typography>
      </NoAccessContainer>
    );
  }

  return (
    <>
      <TargetContainer>
        <FileDNDTarget
          maxFileUploadSize={prettyFileSize(maxFileUploadSize)}
          fileDNDTargetState={fileDNDTargetState}
          uploadFiles={uploadFiles}
          onSubmit={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
          onDragOver={event => {
            event.stopPropagation();
            event.preventDefault();
            setFileDNDTargetState('dragged-over');
          }}
          onDragEnter={event => {
            event.stopPropagation();
            event.preventDefault();
            setFileDNDTargetState('dragged-over');
          }}
          onDragLeave={event => {
            event.stopPropagation();
            event.preventDefault();
            setFileDNDTargetState('idle');
          }}
          onDragEnd={event => {
            event.stopPropagation();
            event.preventDefault();
            setFileDNDTargetState('idle');
          }}
          onDrop={event => {
            event.stopPropagation();
            event.preventDefault();
            setFileDNDTargetState('idle');
            uploadFiles({
              files: Array.from(event.dataTransfer.files),
            });
          }}
          fileInput={fileInput}
        />

        {
          uploadIds.map(id => {
            return (
              <NodeAttachmentUpload
                key={id}
                {...uploadsById[id]}
                handleUploadAbort={handleUploadAbort}
                handleRemoveErroneousUpload={handleRemoveErroneousUpload}
              />
            );
          })
        }
      </TargetContainer>
      <PaddedContainer>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            style={{
              cursor: 'pointer',
              height: '92px',
              flexGrow: 1,
              paddingTop: 40,
            }}
          >
            {
              attachmentIds.length > 0 ?
                <NodeAttachmentList
                  attachmentsSortDirection={attachmentsSortDirection}
                  attachmentsSortCriteria={attachmentsSortCriteria}
                  handleNameSort={handleNameSort}
                  handleSizeSort={handleSizeSort}
                >
                  {
                    attachmentIds.map(id => {
                      return (
                        <NodeAttachmentDownload
                          key={id}
                          {...attachmentsById[id]}
                          onTitleChange={onTitleChange}
                          saveTitle={saveTitle}
                          revertTitle={revertTitle}
                          handleAbort={handleDownloadAbort}
                          displayDeleteDialog={displayDeleteDialog}
                          performDelete={performDelete}
                          handleDownload={handleDownload}
                          handlePreview={handlePreview}
                          setIsMenuOpen={setIsMenuOpen}
                          handleCloseDownloadMenu={handleCloseDownloadMenu}
                          setMenuAnchorEl={setMenuAnchorEl}
                          setIsEditable={setIsEditable}
                        />
                      );
                    })
                  }
                </NodeAttachmentList>
                :
                null
            }
          </Grid>
        </Grid>
      </PaddedContainer>
      <NodeAttachmentPreviewImage
        isLoading={isPreviewLoading}
        loadingSpinner={<CenterpieceSpinner/>}
        url={previewUrl}
        open={isPreviewOpen}
        onClose={() => {
          handleClosePreview();
        }}
      />
    </>
  );
};

NodeAttachments.propTypes = {
  expandedNodeId: PropTypes.number,
};

export default NodeAttachments;
