import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {POST} from '../verbs';

export default async function POST__logout(authToken) {
  const login = await POST(
    '/logout',
    configWithAuthorization(defaultConfig(), authToken),
    {},
  );
  return login;
}
