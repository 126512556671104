import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_types_fieldDefinitions_FIELDDEFINITIONID(authToken, perspectiveId, params) {
  const {
    fieldDefinitionId,
  } = params;

  return await DELETE(
    `/admin/types/field-definitions/${fieldDefinitionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {},
  );
}
