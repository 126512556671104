import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import MUIChip from '@material-ui/core/Chip';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    borderRadius: props => props.isPermission ? 8 : 4,
    backgroundColor: props => props.isSelected ? props.isLocationValid ? props.isLast ? props.isPermission ? theme.palette['secondary'] : theme.palette['primary'] : theme.palette['primary'] : theme.palette['grey-semi'] : theme.palette['tertiary-lightest'],
    color: props => props.isSelected ? theme.palette['white'] : theme.palette['black'],
    fontWeight: props => props.isSelected ? 'bold' : 'normal',
    margin: '16px 8px',
  },
});

const Chip = ({
  label,
  onClick,
  isSelected,
  isLocationValid,
  isLast,
  isPermission,
}) => {
  const classes = useStyles({
    isSelected,
    isLocationValid,
    isLast,
    isPermission,
  });

  return (
    <MUIChip
      clickable={false}
      className={classes.root}
      label={label}
      onClick={onClick}
    />
  );
};

Chip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isLocationValid: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default Chip;
