const getDateWithTimezoneOffset = (value) => {
  const timezoneOffset = new Date(value).getTimezoneOffset();
  const timezoneOffsetInMilliseconds = timezoneOffset * 60 * 1000;
  const timeInMilliseconds = new Date(value).getTime();

  if (timezoneOffset < 0) {
    return new Date(timeInMilliseconds + Math.abs(timezoneOffsetInMilliseconds));
  } else {
    return new Date(timeInMilliseconds - Math.abs(timezoneOffsetInMilliseconds));
  }
};

export default getDateWithTimezoneOffset;
