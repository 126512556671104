import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_nodeLinkDefinitions_NODELINKDEFINITIONID(authToken, perspectiveId, params) {
  const {
    nodeLinkDefinitionId,
  } = params;

  return await DELETE(
    `/admin/node-link-definitions/${nodeLinkDefinitionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {},
  );
}
