import POST__connections_accept from 'network-layer/endpoints/POST__connections_accept';

function responseAdapter(response) {
  return response;
}

export default async function saveIncomingConnection(authToken, perspectiveId, params) {
  const response = await POST__connections_accept(authToken, perspectiveId, params);

  return responseAdapter(response);
}
