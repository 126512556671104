import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import NotificationStatus from 'ui-library/components/NotificationStatus';
import NotificationSuggestedAction from 'ui-library/components/NotificationSuggestedAction';
import NotificationAge from 'ui-library/components/NotificationAge';
import NotificationInfo from 'ui-library/components/NotificationInfo';
import NotificationActions from 'ui-library/components/NotificationActions';
import NotificationMessage from 'ui-library/components/NotificationMessage';

const useStyles = makeStyles({
  root: {
    display: theme.notification.root.display,
    height: theme.notification.root.height,
    padding: theme.notification.root.padding,
    backgroundColor: theme.notification.root.backgroundColor,
    boxShadow: theme.notification.root.boxShadow,
    minWidth: theme.notification.root.minWidth,
    transition: theme.notification.root.transition,
    '&:hover': {
      ...theme.notification.hover,
    },
    '&:first-of-type': {
      borderTopLeftRadius: theme.notification.root.firstItem.borderTopLeftRadius,
      borderTopRightRadius: theme.notification.root.firstItem.borderTopRightRadius,
    },
    '&:last-of-type': {
      borderBottomLeftRadius: theme.notification.root.lastItem.borderBottomLeftRadius,
      borderBottomRightRadius: theme.notification.root.lastItem.borderBottomRightRadius,
    },
  },
  iconButton: {
    alignItems: theme.notificationActions.iconButton.alignItems,
    padding: theme.notificationActions.iconButton.padding,
    margin: theme.notificationActions.iconButton.margin,
    '&:hover': {
      backgroundColor: theme.notificationActions.iconButton.backgroundColor,
    },
  },
});

const Notification = React.forwardRef(({
  message,
  age,
  readStatus,
  onToggleReadStatus,
  onDelete,
  event,
  onClickAction,
}, ref) => {

  const classes = useStyles();

  return (
    <div ref={ref} className={classes.root}>
      <NotificationInfo>
        <NotificationMessage status={readStatus}>
          {message}
        </NotificationMessage>
        {
          [
            'node emitted notification',
            'permissions granted on Record by partner or third party',
            'permissions granted on Node by partner or third party',
            'connection established',
            'conversation comment node',
            'conversation comment node edit',
            'conversation comment record',
            'conversation comment record edit',
            'conversation comment connection',
            'conversation comment connection edit',
          ].includes(event) &&
          <NotificationSuggestedAction
            event={event}
            onClick={onClickAction}
          />
        }
        <NotificationAge>{age + ' UTC'}</NotificationAge>
      </NotificationInfo>
      <NotificationActions>
        <IconButton
          className={classes.iconButton}
          disableRipple
          onClick={onDelete}>
          <CloseIcon style={{width: '24px', height: '24px'}}/>
        </IconButton>
        <NotificationStatus
          checked={readStatus === 'unread'}
          onChange={onToggleReadStatus}
        />
      </NotificationActions>
    </div>
  );
});

Notification.displayName = '';

Notification.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  age: PropTypes.any,
  event: PropTypes.string,
  readStatus: PropTypes.string,
  onDelete: PropTypes.func,
  onToggleReadStatus: PropTypes.func,
  onClickAction: PropTypes.func,
};

export default Notification;
