import {
  RECORD_ATOMIC_PERMISSIONS,
  RECORD_FRONTEND_PERMISSIONS,
} from './PERMISSION_FLAGS';

function recordAtomicPermissionsToFrontendPermissions(atomicPermissions) {
  if (typeof atomicPermissions !== 'object' || atomicPermissions === null) {
    throw new Error(`Invalid Record atomic permissions! Expected an object. Received ${typeof atomicPermissions}.`);
  }

  const receivedAtomicPermissions = Object.keys(atomicPermissions);
  const missingAtomicPermissions = [];
  for (let requiredAtomicPermission of RECORD_ATOMIC_PERMISSIONS) {
    if (!receivedAtomicPermissions.includes(requiredAtomicPermission)) {
      missingAtomicPermissions.push(requiredAtomicPermission);
    }
  }
  if (missingAtomicPermissions.length) {
    throw new Error(`Incomplete Record atomic permissions! Missing permissions: ${missingAtomicPermissions.map(missingAtomicPermission => `"${missingAtomicPermission}"`).join(', ')}.`);
  }

  const frontendPermissions = {};

  for (let frontendPermission of Object.keys(RECORD_FRONTEND_PERMISSIONS)) {
    frontendPermissions[frontendPermission] = true;
    const atomicPermissionGroup = RECORD_FRONTEND_PERMISSIONS[frontendPermission];
    for (let atomicPermission of atomicPermissionGroup) {
      if (!atomicPermissions[atomicPermission]) {
        frontendPermissions[frontendPermission] = false;
        break;
      }
    }
  }

  return frontendPermissions;
}

export default recordAtomicPermissionsToFrontendPermissions;
