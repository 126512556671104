function selectRecordTypeById(DM, recordTypeId) {
  const {
    recordTypesById: {
      [recordTypeId]: recordType,
    },
  } = DM;

  return recordType;
}

export default selectRecordTypeById;
