import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const CreateVirtualLocationTreeNodeHintDialog = ({
  open,
  onClose,
  candidateTypes,
  typeId,
  onSetTypeId,
  text,
  onSetText,
  prevalence,
  onSetPrevalence,
  prevalenceWeight,
  onSetPrevalenceWeight,
  onSave,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Create a location or item hint
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a type and designate it here
        </DialogContentText>

        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            fullWidth
            value={typeId}
            onChange={event => {
              onSetTypeId(event.target.value);
            }}>
            {
              candidateTypes.map(type => {
                const {
                  id: typeId,
                  name: typeName,
                } = type;
                return (
                  <MenuItem
                    key={typeId}
                    value={typeId}>
                    {typeName}
                  </MenuItem>
                );
              })
            }
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          value={text}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 100) {
              onSetText(event.target.value);
            }
          }}
          label="Text"
        />

        <FormControl fullWidth>
          <InputLabel>Prevalence</InputLabel>
          <Select
            fullWidth
            value={prevalence}
            onChange={event => {
              onSetPrevalence(event.target.value);
            }}>
            <MenuItem
              value="ubiquitous">
              Ubiquitous
            </MenuItem>
            <MenuItem
              value="common">
              Common
            </MenuItem>
            <MenuItem
              value="rare">
              Rare
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Prevalence weight</InputLabel>
          <Select
            fullWidth
            value={prevalenceWeight}
            onChange={event => {
              onSetPrevalenceWeight(event.target.value);
            }}>
            <MenuItem
              value={1}>
              1
            </MenuItem>
            <MenuItem
              value={2}>
              2
            </MenuItem>
            <MenuItem
              value={3}>
              3
            </MenuItem>
            <MenuItem
              value={4}>
              4
            </MenuItem>
            <MenuItem
              value={5}>
              5
            </MenuItem>
          </Select>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            typeId === '' ||
            !text ||
            !prevalence ||
            !prevalenceWeight
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateVirtualLocationTreeNodeHintDialog;
