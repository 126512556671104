import {
  RECORD_ATOMIC_PERMISSIONS,
} from './PERMISSION_FLAGS';

function recordPermissionsIntToAtomicPermissions(permissionsInt) {
  if (
    typeof permissionsInt !== 'number' ||
    permissionsInt < 0 ||
    permissionsInt !== Math.floor(permissionsInt)
  ) {
    throw new Error(`Invalid Record permissions integer! Expected an integer number. Received "${permissionsInt}".`);
  }
  if ((permissionsInt >>> 0).toString(2).length > RECORD_ATOMIC_PERMISSIONS.length) {
    throw new Error(`Invalid Record permissions integer! Converts to ${(permissionsInt >>> 0).toString(2).length} bits, but the expected number of bits is ${RECORD_ATOMIC_PERMISSIONS.length}.`);
  }

  const atomicPermissions = {};

  for (let atomicPermissionFlagIndex = 0; atomicPermissionFlagIndex < RECORD_ATOMIC_PERMISSIONS.length; atomicPermissionFlagIndex++) {
    const permissionFlag = RECORD_ATOMIC_PERMISSIONS[atomicPermissionFlagIndex];

    const permissionFlagCount = RECORD_ATOMIC_PERMISSIONS.length;
    const exponent = permissionFlagCount - atomicPermissionFlagIndex - 1;
    const decimalPermission = Math.pow(2, exponent);

    atomicPermissions[permissionFlag] = (permissionsInt & decimalPermission) === decimalPermission;
  }

  return atomicPermissions;
}

export default recordPermissionsIntToAtomicPermissions;
