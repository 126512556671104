import endpointURL from '../util/endpointURL';
import successBody from '../util/successBody';

export default async function PUT(
  endpoint,
  config,
  body,
) {
  const response = await fetch(
    endpointURL(endpoint),
    {
      ...config,
      method: 'PUT',
      body: JSON.stringify(body),
    },
  );

  return await successBody(response);
}
