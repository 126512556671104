import selectTypeCategories from './selectTypeCategories';

function selectChildTypeCategoriesOfTypeCategoryById(DM, parentTypeCategoryId) {
  return (
    selectTypeCategories(DM)
      .filter(typeCategory => {
        const {
          parentId,
        } = typeCategory;
        return parentId === parentTypeCategoryId;
      })
  );
}

export default selectChildTypeCategoriesOfTypeCategoryById;
