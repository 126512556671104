import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import Autocomplete from 'components/Autocomplete';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genPersonsService from 'services/Persons/genPersons';

const EHRPersonStep = ({
  active,
  onSetEHRPerson,
  closeWizardWithPrepend,
}) => {
  const [persons, setPersons] = React.useState([]);
  const [selectedPerson, setSelectedPerson] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  React.useEffect(() => {
    async function init() {
      setIsLoading(true);
      const data = await genPersonsService(
        authToken,
        currentPerspectiveId,
      );
      setPersons(data);
      setIsLoading(false);
    }
    init();
  }, [authToken, currentPerspectiveId]);

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add eHR
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Person
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Select who to add health information for.
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        {
          isLoading ?
            <CenterpieceSpinner /> :
            <form onSubmit={(event) => {
              event.preventDefault();
              if (!selectedPerson) {
                return;
              }
              const {
                id: personId,
                name: personName,
              } = selectedPerson;
              onSetEHRPerson({
                personId,
                personName,
              });
            }}>
              <WizardStepContent>
                <Typography variant="lead">
                  Who would you like to add health information for?
                </Typography>
                <Autocomplete
                  options={persons}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.name === value.name}
                  label="People"
                  value={selectedPerson}
                  placeholder="Start typing..."
                  onChange={(event, newPerson) => {
                    setSelectedPerson(newPerson);
                  }}
                />
              </WizardStepContent>
              <WizardStepActions>
                <WizardSubmitWithEnterButton
                  data-test-id="add-item-type-step-continue-button"
                  variant="primary"
                  disabled={!Boolean(selectedPerson)}>
                  Continue &nbsp;<ForwardIcon/>
                </WizardSubmitWithEnterButton>
              </WizardStepActions>
            </form>
        }
      </WizardContentColumn>
    </WizardStep>
  );
};

EHRPersonStep.propTypes = {
  onSetEHRPerson: PropTypes.func.isRequired,
};

export default EHRPersonStep;
