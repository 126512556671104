import POST__resetPassword from 'network-layer/endpoints/POST__resetPassword';

function responseAdapter(response) {
  return response;
}

export default async function resetPassword(password, passwordResetToken) {
  const response = await POST__resetPassword(password, passwordResetToken);

  return responseAdapter(response);
}
