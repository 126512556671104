function selectTypeCategories(DM) {
  const {
    typeCategoriesById,
    typeCategoryIds,
  } = DM;

  return (
    typeCategoryIds
      .map(typeCategoryId => typeCategoriesById[typeCategoryId])
  );
}

export default selectTypeCategories;
