import selectVirtualLocationTreeNodes from './selectVirtualLocationTreeNodes';

function selectVirtualLocationTreeNodeLocationDescendants(configurationState, virtualLocationTreeNode, locationDescendants) {
  const virtualLocationTreeNodes = selectVirtualLocationTreeNodes(configurationState);

  const {
    virtualId,
  } = virtualLocationTreeNode;

  const children = virtualLocationTreeNodes.filter(node => node.virtualLocationId === virtualId);

  for (let child of children) {
    locationDescendants.push(child);
    selectVirtualLocationTreeNodeLocationDescendants(configurationState, child, locationDescendants);
  }
}

export default selectVirtualLocationTreeNodeLocationDescendants;
