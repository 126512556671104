import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import Link from 'ui-library/components/Link';
import theme from 'ui-library/theme';
import LinkIcon from '@material-ui/icons/LinkRounded';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  adornment: theme.field.adornment,
  viewContainer: {
    display: theme.field.viewContainer.display,
    flexDirection: theme.field.viewContainer.flexDirection,
    paddingLeft: theme.field.viewContainer.paddingLeft,
    paddingTop: theme.field.viewContainer.paddingTop,
    paddingBottom: theme.field.viewContainer.paddingBottom,
    backgroundColor: theme.field.viewContainer.backgroundColor,
    height: theme.field.viewContainer.height,
    marginTop: props => props.inlineEdit ? theme.field.viewContainer.marginTopInlineEdit : theme.field.viewContainer.marginTop,
  },
  iconValueContainer: theme.field.iconValueContainer,
  ellipsisContainer: theme.field.ellipsisContainer,
});

const UrlViewMode = ({
  inlineEdit,
  label,
  value,
  isSaving,
  onClick,
}) => {

  const classes = useStyles({
    inlineEdit,
  });

  return (
    <div
      className={classes.viewContainer}
      onClick={onClick}
    >
      <div className={classes.ellipsisContainer}>
        <Typography
          variant="x-small"
          color="grey"
        >
          {label}
        </Typography>
      </div>
      <div className={classes.iconValueContainer}>
        <LinkIcon className={classes.adornment}/>
        <Link
          value={value}
          variant="small"
          color="primary"
          hoverColor="white"
        >
          <div className={classes.ellipsisContainer}>
            <Typography
              variant="small"
              color={isSaving ? 'black' : 'primary'}
            >
              {isSaving ? 'Saving...' : value !== '' ? value : 'N/A'}
            </Typography>
          </div>
        </Link>
      </div>
    </div>
  );
};

UrlViewMode.propTypes = {
  inlineEdit: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  isSaving: PropTypes.bool,
  onClick: PropTypes.func,
};

export default UrlViewMode;
