import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'ui-library/components/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import AttachMoney from '@material-ui/icons/AttachMoneyRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import NumberFormat from 'react-number-format';

import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  editContainer: theme.field.editContainer,
  actionButtonsContainer: theme.field.actionButtonsContainer,
  actionButton: theme.field.actionButton,
});

const checkDecimalFormatting = (value) => {
  const decimalSeparator = '.';
  const segments = value.split(decimalSeparator);

  if (segments.length > 2) {
    return false;
  }

  if (segments.length === 2 && segments[1].length > 2) {
    return false;
  }

  return true;
};

const CurrencyEditMode = ({
  autoFocus,
  inlineEdit,
  label,
  helperText,
  error,
  value,
  disabled,
  required,
  onChange,
  onKeyPress,
  onBlur,
  onFocus,
  onMouseDown,
  saveField,
  revertField,
}) => {

  const classes = useStyles();

  if (!inlineEdit) {
    return (
      <NumberFormat
        customInput={TextField}
        thousandSeparator=","
        decimalSeparator="."
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        value={value}
        onValueChange={values => {
          if (!checkDecimalFormatting(values.value)) {
            return;
          }
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        disabled={disabled}
        required={required}
        onKeyPress={values => {
          if (typeof onKeyPress === 'function') {
            onKeyPress({
              key: values.key,
              target: {
                value: value,
              },
            });
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <div
      className={classes.editContainer}
    >
      <div
        className={classes.actionButtonsContainer}
      >
        <div
          className={classes.actionButton}
          onMouseDown={onMouseDown}
        >
          <CloseIcon
            data-test-id="inline-revert-field-button"
            onClick={revertField}
          />
        </div>
        <div
          className={classes.actionButton}
        >
          <CheckIcon
            data-test-id="inline-save-field-button"
            onClick={() => {
              if (!error) {
                saveField();
              }
            }}
            onMouseDown={onMouseDown}
          />
        </div>
      </div>

      <NumberFormat
        customInput={TextField}
        thousandSeparator=","
        decimalSeparator="."
        onValueChange={values => {
          if (!checkDecimalFormatting(values.value)) {
            return;
          }
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        value={value}
        disabled={disabled}
        required={required}
        onKeyPress={values => {
          if (typeof onKeyPress === 'function') {
            onKeyPress({
              key: values.key,
              target: {
                value: value,
              },
            });
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoney />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

CurrencyEditMode.propTypes = {
  autoFocus: PropTypes.bool,
  inlineEdit: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  saveField: PropTypes.func,
  revertField: PropTypes.func,
};

export default CurrencyEditMode;
