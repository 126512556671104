import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_hub_TYPEID(authToken, perspectiveId, params) {
  const {
    typeId,
  } = params;

  return await DELETE(
    `/admin/hub/${typeId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
