import PUT__notifications_push from 'network-layer/endpoints/PUT__notifications_push';

function responseAdapter(response) {
  return response;
}

export default async function updateNotificationsPushStatus(authToken, enabledStatus) {
  const response = await PUT__notifications_push(
    authToken,
    {enabledStatus},
  );

  return responseAdapter(response);
}
