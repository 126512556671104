import React from 'react';
import styled from '@emotion/styled';

import Typography from 'ui-library/components/Typography';

import LoginRedirect from 'components/SignUp/LeftPanel/LoginRedirect';

const LeftPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  text-align: right;
  margin-bottom: 20px;
`;

const DefaultAccount = () => {

  return (
    <>
      <LeftPanelRow>
        <Typography
          variant="h1"
          fontWeight="bold"
          fontFamily="primary"
          color="grey"
        >
          SIGN-UP
        </Typography>
      </LeftPanelRow>
      <LeftPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          Jules unlocks the power of your data, allowing for you to actively manage your business or personal information.
        </Typography>
      </LeftPanelRow>
      <LeftPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          Choose the type of account you would like to create.
        </Typography>
      </LeftPanelRow>
      <br/>
      <br/>
      <LeftPanelRow>
        <LoginRedirect />
      </LeftPanelRow>
    </>

  );
};

export default DefaultAccount;
