import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

const AddFieldWatcherDialog = ({
  open,
  onClose,
  onSave,
  controlsDisabled,
  saveDisabled,
  DataModelQueries,
  DataModel,
  notificationPipelineId,
  fieldWatcherType,
  onSetFieldWatcherType,
  fieldWatcherTimeDelta,
  onSetFieldWatcherTimeDelta,
}) => {
  const notificationPipeline = DataModelQueries.selectNotificationPipelineById(
    DataModel,
    notificationPipelineId,
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Add a Field Watcher to "{notificationPipeline.name}"
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h6">
          Type
        </Typography>
        <DialogContentText>
          Select the type of the Field Watcher; this must be the same as the type of Field you want to watch.<br/>
          Currently only <strong>date</strong> Fields can be watched.<br/>
          <span
            style={{
              color: 'red',
            }}>
            (this setting cannot be changed once the Field Watcher has been created!)
          </span>
        </DialogContentText>
        <Select
          fullWidth
          margin="dense"
          value={fieldWatcherType}
          onChange={event => {
            const value = event.target.value;
            onSetFieldWatcherType(value);
          }}>
          <MenuItem
            value="date">
            date
          </MenuItem>
        </Select>
        <br/><br/><br/>

        <Typography
          variant="h6">
          Time delta
        </Typography>
        <DialogContentText>
          Set a time delta for this Field Watcher. Time deltas work as follows:<br/>
          Setting a time delta of <strong>3</strong> on a field watcher will result in a notification being generated <em>three days prior to the date value on the Field that is being watched</em>. Time deltas can be negative, resulting in notifications being generated after the date that is defined on the Field that is being watched.<br/>
          The time delta is measured in days and it must be an integer number between <strong>-366</strong> and <strong>366</strong>.<br/>
          (you can come back and edit this if you need to)
        </DialogContentText>
        <TextField
          fullWidth
          margin="dense"
          disabled={controlsDisabled}
          value={fieldWatcherTimeDelta}
          onChange={event => {
            const value = event.target.value;
            onSetFieldWatcherTimeDelta(value);
          }}
          placeholder="time delta"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            saveDisabled ||
            controlsDisabled ||
            fieldWatcherType === '' ||
            fieldWatcherTimeDelta === ''
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFieldWatcherDialog;
