import PATCH__notifications from 'network-layer/endpoints/PATCH__notifications';

function responseAdapter(response) {
  return response;
}

export default async function updateNotifications(authToken, params) {
  const response = await PATCH__notifications(authToken, params);

  return responseAdapter(response);
}
