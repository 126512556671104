import POST__conversations_connections_CONNECTIONID from 'network-layer/endpoints/POST__conversations_connections_CONNECTIONID';

function responseAdapter(response) {
  return response;
}

export default async function createConnectionConversation(authToken, perspectiveId, params) {
  const response = await POST__conversations_connections_CONNECTIONID(authToken, perspectiveId, params);

  return responseAdapter(response);
}
