import React from 'react';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import TextField from 'ui-library/components/TextField';
import {organizationName} from 'utils/validator';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const HelperTextContainer = styled.div`
  padding: 8px 0 0 10px;
`;

const RightPanelRowWithValidationGutter = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
  height: 80px;
`;


const BusinessNameComponent = ({
  businessName,
  setBusinessName,
  step,
  setStep,
  setWizardDirection,
  isTransitioning,
}) => {

  const [isPristine, setIsPristine] = React.useState(true);

  return (
    <>
      <RightPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
            Start with the name of <strong>your business...</strong>
        </Typography>
      </RightPanelRow>
      <RightPanelRowWithValidationGutter>
        <TextField
          value={businessName}
          onChange={(ev) => {
            setIsPristine(false);
            setBusinessName(ev.target.value);
          }}
          fullWidth
          placeholder="Type your answer here..."
          autoFocus={true}
          onKeyPress={(event) => {
            if (event.which === 13 && !organizationName(businessName) && !isTransitioning) {
              setWizardDirection('forward');
              setStep(step + 1);
            }
          }}
          error={!isPristine && !!organizationName(businessName)}
          helperText={!isPristine && organizationName(businessName)}
        />
      </RightPanelRowWithValidationGutter>
      <br/>
      <RightPanelRow>
        <Button
          data-test-id="business-name-continue-btn"
          variant="primary"
          endIcon={<ArrowForwardIcon />}
          disabled={!!organizationName(businessName) || isTransitioning}
          onClick={() => {
            setWizardDirection('forward');
            setStep(step + 1);
          }}
        >
            CONTINUE
        </Button>
        <HelperTextContainer>
          <Typography
            variant="tiny"
          >
              press <strong>ENTER</strong>
          </Typography>
        </HelperTextContainer>
      </RightPanelRow>
    </>
  );

};

export default BusinessNameComponent;
