import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {POST} from '../verbs';

export default async function POST__nodeLinks(authToken, perspectiveId, params) {

  return await POST(
    '/node-links',
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
