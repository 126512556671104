export default function fieldIsZipCode(
  params,
  errorMessage = 'invalid zip code',
) {
  const {
    value,
  } = params;

  const zipcodeFormat = new RegExp(/^\d{5}(-\d{4})?$/);

  if (value === '' || zipcodeFormat.test(value)) {
    return '';
  }
  return errorMessage;
}
