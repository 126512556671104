import React from 'react';
import PropTypes from 'prop-types';
import FilterTextField from 'ui-library/components/FilterTextField';
import FilterSelect from 'ui-library/components/FilterSelect';
import FilterAutocomplete from 'ui-library/components/FilterAutocomplete';
import FilterMultipleSelect from 'ui-library/components/FilterMultipleSelect';
import FilterDrillDown from 'ui-library/components/FilterDrillDown';


const FilterValue = ({
  index,
  filterPillCriterion,
  removeFilterPillCriterion,
  editMode,
  setEditMode,
  filterPillValue,
  updateFilterPillValue,
  onLoadTree,
}) => {

  const {
    component,
    options,
  } = filterPillCriterion;

  switch (component) {
    case 'input':
      return (
        <FilterTextField
          index={index}
          filterPillValue={filterPillValue}
          updateFilterPillValue={updateFilterPillValue}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      );
    case 'select':
      return (
        <FilterSelect
          index={index}
          filterPillValue={filterPillValue}
          updateFilterPillValue={updateFilterPillValue}
          options={options}
        />
      );
    case 'autocomplete':
      return (
        <FilterAutocomplete
          index={index}
          filterPillValue={filterPillValue}
          updateFilterPillValue={updateFilterPillValue}
          editMode={editMode}
          setEditMode={setEditMode}
          options={options}
          removeFilterPillCriterion={removeFilterPillCriterion}
        />
      );
    case 'multiple-select':
      return (
        <FilterMultipleSelect
          index={index}
          filterPillValue={filterPillValue}
          updateFilterPillValue={updateFilterPillValue}
          options={options}
        />
      );
    case 'drill-down':
      return (
        <FilterDrillDown
          index={index}
          filterPillValue={filterPillValue}
          updateFilterPillValue={updateFilterPillValue}
          options={options}
          onLoadTree={onLoadTree}
          removeFilterPillCriterion={removeFilterPillCriterion}
        />
      );
    default:
      return null;
  }
};

FilterValue.propTypes = {
  index: PropTypes.number,
  filterPillCriterion: PropTypes.object,
  editMode:  PropTypes.bool,
  setEditMode: PropTypes.func,
  filterPillValue: PropTypes.any,
  updateFilterPillValue: PropTypes.func,
  fieldValueRef: PropTypes.any,
  onLoadTree: PropTypes.func,
  removeFilterPillCriterion: PropTypes.func,
};

export default FilterValue;
