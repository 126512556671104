const getFileName = (fileName) => {
  const segments = fileName.split('.');
  const length = segments.length;

  if (length === 1) {
    return segments[0];
  }

  return segments.slice(0, segments.length - 1).join('.');
};

export default getFileName;
