function normalizedDataModel(DataModel) {
  const {
    recordTypes,
    types,
    fieldDefinitions,
    recordTypes_Types,
    typeCategories,
    typeLocationGraph,
    nodeLinkDefinitions,
    typeRelationships,
    propertyConfigurationHints,
    notificationPipelines,
    fieldWatchers,
    users,
    ehrHubTypes,
  } = DataModel;

  // EHR HUB TYPES
  const ehrHubTypesById = {};
  for (let ehrHubType of ehrHubTypes) {
    ehrHubTypesById[ehrHubType.typeId] = ehrHubType;
  }
  const ehrHubTypeIds = ehrHubTypes.map(ehrHubType => ehrHubType.typeId);

  // RECORD TYPES
  const recordTypesById = {};
  for (let recordType of recordTypes) {
    recordTypesById[recordType.id] = recordType;
  }
  const recordTypeIds = recordTypes.map(recordType => recordType.id);


  // TYPES
  const typesById = {};
  for (let type of types) {
    typesById[type.id] = type;
  }
  const typeIds = types.map(type => type.id);


  // FIELD DEFINITIONS
  const fieldDefinitionsById = {};
  for (let fieldDefinition of fieldDefinitions) {
    fieldDefinitionsById[fieldDefinition.id] = fieldDefinition;
  }
  const fieldDefinitionIds = fieldDefinitions.map(fieldDefinition => fieldDefinition.id);

  // USERS
  const usersById = {};
  for (let user of users) {
    usersById[user.userId] = user;
  }
  const userIds = users.map(user => user.userId);


  // TYPE CATEGORIES
  const typeCategoriesById = {};
  for (let typeCategory of typeCategories) {
    typeCategoriesById[typeCategory.id] = typeCategory;
  }
  const typeCategoryIds = typeCategories.map(typeCategory => typeCategory.id);


  // TYPE LOCATION GRAPH


  // NODE LINK DEFINITIONS
  const nodeLinkDefinitionsById = {};
  for (let nodeLinkDefinition of nodeLinkDefinitions) {
    nodeLinkDefinitionsById[nodeLinkDefinition.id] = nodeLinkDefinition;
  }
  const nodeLinkDefinitionIds = nodeLinkDefinitions.map(nodeLinkDefinition => nodeLinkDefinition.id);


  // TYPE RELATIONSHIPS
  const typeRelationshipsById = {};
  for (let typeRelationship of typeRelationships) {
    typeRelationshipsById[typeRelationship.id] = typeRelationship;
  }
  const typeRelationshipIds = typeRelationships.map(typeRelationship => typeRelationship.id);


  // PROPERTY CONFIGURATION HINTS
  const propertyConfigurationHintsById = {};
  const propertyConfigurationHintIds = [];
  const virtualLocationTreeNodeHintsById = {};
  const virtualLocationTreeNodeHintIds = [];
  for (let propertyConfigurationHint of propertyConfigurationHints) {
    const {
      id: propertyConfigurationHintId,
      virtualLocationTreeNodeHints,
    } = propertyConfigurationHint;

    propertyConfigurationHintIds.push(propertyConfigurationHintId);

    propertyConfigurationHintsById[propertyConfigurationHintId] = propertyConfigurationHint;

    for (let virtualLocationTreeNodeHint of virtualLocationTreeNodeHints) {
      const {
        id: virtualLocationTreeNodeHintId,
      } = virtualLocationTreeNodeHint;
      virtualLocationTreeNodeHintsById[virtualLocationTreeNodeHintId] = virtualLocationTreeNodeHint;
      virtualLocationTreeNodeHintIds.push(virtualLocationTreeNodeHintId);
    }
  }


  // NOTIFICATION PIPELINES
  const notificationPipelinesById = {};
  for (let notificationPipeline of notificationPipelines) {
    notificationPipelinesById[notificationPipeline.id] = notificationPipeline;
  }
  const notificationPipelineIds = notificationPipelines.map(notificationPipeline => notificationPipeline.id);


  // FIELD WATCHERS
  const fieldWatchersById = {};
  for (let fieldWatcher of fieldWatchers) {
    fieldWatchersById[fieldWatcher.id] = fieldWatcher;
  }
  const fieldWatcherIds = fieldWatchers.map(fieldWatcher => fieldWatcher.id);


  return {
    recordTypesById,
    recordTypeIds,
    typesById,
    typeIds,
    fieldDefinitionsById,
    fieldDefinitionIds,
    recordTypes_Types,
    typeCategoriesById,
    typeCategoryIds,
    typeLocationGraph,
    nodeLinkDefinitionsById,
    nodeLinkDefinitionIds,
    typeRelationshipsById,
    typeRelationshipIds,
    propertyConfigurationHintsById,
    propertyConfigurationHintIds,
    virtualLocationTreeNodeHintsById,
    virtualLocationTreeNodeHintIds,
    notificationPipelinesById,
    notificationPipelineIds,
    fieldWatchersById,
    fieldWatcherIds,
    usersById,
    userIds,
    ehrHubTypes,
    ehrHubTypesById,
    ehrHubTypeIds,
  };
}

export default normalizedDataModel;
