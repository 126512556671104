function initSearchState() {
  return {
    all: {
      filterPillCriteria: [],
      filterPillValues: [],
      searchQuery: '',
    },
    internal: {
      filterPillCriteria: [],
      filterPillValues: [],
      searchQuery: '',
    },
    external: {
      filterPillCriteria: [],
      filterPillValues: [],
      searchQuery: '',
    },
  };
}

function searchReducer(state, {type: actionType, payload}) {
  switch(actionType) {
    case 'SET_SEARCH_QUERY': {
      const {
        searchContext,
        searchQuery,
      } = payload;

      return {
        ...state,
        [searchContext]: {
          ...state[searchContext],
          searchQuery: searchQuery,
        },
      };
    }

    case 'SET_FILTER_PILLS': {
      const {
        context,
        filterPillCriteria,
        filterPillValues,
      } = payload;
      return {
        ...state,
        [context]: {
          ...state[context],
          filterPillCriteria: filterPillCriteria,
          filterPillValues: filterPillValues,
        },
      };
    }

    case 'ADD_FILTER_PILL_CRITERION': {
      const {
        searchContext,
        filterPillCriterion,
      } = payload;

      const {
        [searchContext]: {
          filterPillCriteria,
          filterPillValues,
        },
      } = state;

      return {
        ...state,
        [searchContext]: {
          ...state[searchContext],
          filterPillCriteria: [
            ...filterPillCriteria,
            filterPillCriterion,
          ],
          filterPillValues: [
            ...filterPillValues,
            [],
          ],
        },
      };
    }

    case 'SET_FILTER_PILL_OPTIONS': {
      const {
        searchContext,
        criterion,
        options,
      } = payload;

      const {
        [searchContext]: {
          filterPillCriteria,
        },
      } = state;

      const index = filterPillCriteria.findIndex(
        filter => filter.name === criterion,
      );

      return {
        ...state,
        [searchContext]: {
          ...state[searchContext],
          filterPillCriteria: [
            ...filterPillCriteria.slice(0, index),
            {...filterPillCriteria[index], options: options, isPillLoading: false},
            ...filterPillCriteria.slice(index + 1),
          ],
        },
      };
    }

    case 'REMOVE_FILTER_PILL_CRITERION': {
      const {
        searchContext,
        index,
      } = payload;

      const {
        [searchContext]: {
          filterPillCriteria,
          filterPillValues,
        },
      } = state;

      return {
        ...state,
        [searchContext]: {
          ...state[searchContext],
          filterPillCriteria: [
            ...filterPillCriteria.slice(0, index),
            ...filterPillCriteria.slice(index + 1),
          ],
          filterPillValues: [
            ...filterPillValues.slice(0, index),
            ...filterPillValues.slice(index + 1),
          ],
        },
      };
    }

    case 'UPDATE_FILTER_PILL_CRITERION': {
      const {
        searchContext,
        index,
        filterPillCriterion,
      } = payload;

      const {
        [searchContext]: {
          filterPillCriteria,
        },
      } = state;

      return {
        ...state,
        [searchContext]: {
          ...state[searchContext],
          filterPillCriteria: [
            ...filterPillCriteria.slice(0, index),
            {...filterPillCriterion, isPillLoading: true},
            ...filterPillCriteria.slice(index + 1),
          ],
        },
      };
    }

    case 'UPDATE_FILTER_PILL_VALUE': {
      const {
        searchContext,
        index,
        valuesArray,
      } = payload;

      const {
        [searchContext]: {
          filterPillValues,
        },
      } = state;

      return {
        ...state,
        [searchContext]: {
          ...state[searchContext],
          filterPillValues: [
            ...filterPillValues.slice(0, index),
            valuesArray,
            ...filterPillValues.slice(index + 1),
          ],
        },
      };
    }

    case 'CLEAR_FILTERS': {
      const {
        searchContext,
      } = payload;
      return {
        ...state,
        [searchContext]: {
          filterPillCriteria: [],
          filterPillValues: [],
        },
      };
    }

    default:
      throw new Error(`Unknown search action "${actionType}"!`);
  }
}

export {
  initSearchState,
  searchReducer,
};
