import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const AddTypeRelationshipDialog = ({
  open,
  onClose,
  controlsDisabled,
  nodeLinkDefinitions,
  types,
  nodeLinkDefinition,
  originType,
  destinationType,
  onSetNodeLinkDefinition,
  onSetOriginType,
  onSetDestinationType,
  onSaveTypeRelationship,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Add Type Relationship
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h6">
          Origin Type
        </Typography>
        <Autocomplete
          id="origin-type"
          options={types}
          getOptionLabel={option => option.name}
          renderInput={params => {
            return (
              <TextField
                fullWidth
                {...params}
                margin="normal"
              />
            );
          }}
          value={originType}
          onChange={(event, newValue) => {
            onSetOriginType(newValue);
          }}
        />
        <br/><br/>

        <Typography
          variant="h6">
          Link Definition
        </Typography>
        <Autocomplete
          id="link-definition"
          options={nodeLinkDefinitions}
          getOptionLabel={option => `${option.directQualifier} (${option.label})`}
          renderInput={params => {
            return (
              <TextField
                fullWidth
                {...params}
                margin="normal"
              />
            );
          }}
          value={nodeLinkDefinition}
          onChange={(event, newValue) => {
            onSetNodeLinkDefinition(newValue);
          }}
        />
        <br/><br/>

        <Typography
          variant="h6">
          Destination Type
        </Typography>
        <Autocomplete
          id="destination-type"
          options={types}
          getOptionLabel={option => option.name}
          renderInput={params => {
            return (
              <TextField
                fullWidth
                {...params}
                margin="normal"
              />
            );
          }}
          value={destinationType}
          onChange={(event, newValue) => {
            onSetDestinationType(newValue);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={controlsDisabled}
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled ||
            nodeLinkDefinition === null ||
            originType === null ||
            destinationType === null
          }
          variant="contained"
          color="primary"
          onClick={onSaveTypeRelationship}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTypeRelationshipDialog;
