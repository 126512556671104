import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import FullScreenSpinner from 'components/chrome/FullScreenSpinner';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ErrorBoundary from 'components/ErrorBoundary';
import Application from 'components/Application';

const environment = process.env.REACT_APP_ENVIRONMENT;
const BugsnagProductionSettings = {};

if (environment === 'production') {
  BugsnagProductionSettings.appVersion = '3.7.0';
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_TOKEN,
  plugins: [new BugsnagPluginReact()],
  releaseStage: environment,
  ...BugsnagProductionSettings,
});

window.OneSignal = window.OneSignal || [];

ReactDOM.render(
  <>
    <CssBaseline/>
    <ErrorBoundary>
      <React.Suspense
        fallback={<FullScreenSpinner/>}>
        <Application
          localAuthToken={localStorage.getItem('authToken')}
        />
      </React.Suspense>
    </ErrorBoundary>
  </>,
  document.getElementById('root'),
);
