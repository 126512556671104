import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import {
  name,
} from 'utils/validator';

const Description = styled.div`
  margin-bottom: 18px;
`;

const PropertyNicknameStep = ({
  active,
  propertyNickname,
  onSetPropertyNickname,
  navigateToStep,
}) => {
  const [isPristine, setIsPristine] = React.useState(true);

  if (!active) {
    return null;
  }

  const errorMessage = !isPristine && name(propertyNickname);

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Property
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Name
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Name this property.
        </WizardStepDescription>
      </WizardStepHead>

      <WizardContentColumn>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (!propertyNickname) {
            return;
          }
          navigateToStep(2);
        }}>
          <WizardStepContent>
            <Description>
              <Typography
                variant="lead">
                  Give your property a&nbsp;
                <Typography
                  variant="lead"
                  fontWeight="bold">
                    nickname&hellip;
                </Typography>
              </Typography>
            </Description>

            <TextField
              data-test-id="nickname-input"
              autoFocus
              value={propertyNickname}
              error={Boolean(errorMessage)}
              helperText={errorMessage}
              onChange={event => {
                setIsPristine(false);
                onSetPropertyNickname(event.target.value);
              }}
            />

          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              data-test-id="save-property-button"
              variant="primary"
              disabled={!Boolean(propertyNickname) || Boolean(errorMessage)}>
              Continue &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

PropertyNicknameStep.propTypes = {
  onSetPropertyNickname: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  propertyNickname: PropTypes.string,
  navigaeteToStep: PropTypes.func,
};

export default PropertyNicknameStep;
