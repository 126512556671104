import React from 'react';
import styled from '@emotion/styled';
import {
  contextualPanelActionsHeight,
  contextualPanelNavigationHeight,
  contextualPanelHeaderHeight,
  gutter,
} from 'constants/layout';
import {
  greyLightest,
  greyLighter,
} from 'ui-library/color-palette';

const Root = styled.div`
  padding-top: ${gutter}px;
  padding-bottom: ${contextualPanelActionsHeight + 50}px;
  min-height: calc(100% - ${contextualPanelHeaderHeight}px - ${contextualPanelNavigationHeight}px);
  background-color: ${props => props.backgroundColor};
`;

const ContextualPanelContent = ({
  panelDisplayMode,
  children,
}) => {

  const backgroundColor = panelDisplayMode === 'default' ? greyLightest : greyLighter;

  return (
    <Root
      backgroundColor={backgroundColor}
    >
      {children}
    </Root>
  );
};

export default ContextualPanelContent;
