import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {PATCH} from '../verbs';

export default async function PATCH__notifications(authToken, params) {
  return await PATCH(
    '/notifications',
    configWithAuthorization(defaultConfig(), authToken),
    {
      ...params,
    },
  );
}
