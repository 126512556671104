import PATCH__nodes_ID from 'network-layer/endpoints/PATCH__nodes_ID';

function responseAdapter(response) {
  return response;
}

export default async function updateNodeLocation(params) {
  const {
    authToken,
    currentPerspectiveId,
    id,
    recordId,
    locationId,
  } = params;
  const response = await PATCH__nodes_ID(authToken, currentPerspectiveId, id, {recordId, locationId});

  return responseAdapter(response);
}
