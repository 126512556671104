import React from 'react';
import PropTypes from 'prop-types';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Button from 'ui-library/components/Button';
import Field from 'ui-library/components/Field';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  row: {
    marginBottom: theme.nodeLinkInfoCard.row.marginBottom,
  },
});

const NodeLinkInfoCard = ({
  open,
  handleClose,
  loading,
  linkInfo,
}) => {

  const classes = useStyles();

  const {
    name,
    fields,
  } = linkInfo;

  return (
    <ModalCard
      onKeyDown={handleClose}
      open={open}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <ModalCardHeader
        handleClose={handleClose}
      >
        <ModalCardHeaderIcon>
          <ListAltRoundedIcon
            fontSize="inherit"
          />
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle>
          {
            loading ?
              'Loading ...'
              :
              name
          }
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent
        height={60}
      >
        {
          loading ?
            <ListSkeleton
              itemCount={5}
            />
            :
            fields.map((field, index) => {
              return (
                <div
                  key={index}
                  className={classes.row}
                >
                  <Field
                    inlineEdit={false}
                    type={field.type}
                    label={field.label}
                    isEditable={false}
                    value={field.value}
                  />
                </div>
              );
            })
        }
      </ModalCardContent>
      <ModalCardActions>
        <Grid item xs={10}/>
        <Grid item xs={2}>
          <Button
            data-test-id="close-node-link-info-button"
            fullWidth={true}
            variant="primary"
            onClick={handleClose}
          >
            OK
          </Button>
        </Grid>
      </ModalCardActions>
    </ModalCard>
  );
};

NodeLinkInfoCard.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  linkInfo: PropTypes.object,
};

export default NodeLinkInfoCard;
