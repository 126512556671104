function selectTypes(DM) {
  const {
    typesById,
    typeIds,
  } = DM;

  return (
    typeIds
      .map(typeId => typesById[typeId])
  );
}

export default selectTypes;
