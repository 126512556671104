import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import Typography from 'ui-library/components/Typography';
import {
  white,
  black,
  primary,
} from 'ui-library/color-palette';

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 530px;
  height: 80px;
  margin-bottom: 8px;
  padding: 1em;
  transition: background-color 0.2s;
  background-color: ${white};
  border-radius: 20px;
  color: ${black};
  cursor: pointer;
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);
  :hover {
    transition: background-color 0.2s;
    color: ${white};
    background-color: ${primary};
    > .oval {
      color: ${primary};
      transition: background-color 0.2s;
      background-color: ${white};
    }
    > .arrow {
      color: ${white};
    }
  }
`;

const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Arrow = styled.div`
  justify-content: flex-end;
  align-self: center;
  color: ${primary};
`;

const Oval = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  text-transform: uppercase;
  transition: background-color 0.2s;
  background-color: ${primary};
  color: ${white};
`;

const TitleContainer = styled.div`
  text-transform: uppercase;
`;

const WizardTypeTile = ({
  icon,
  name,
  description,
  ...otherProps
}) => {

  return (
    <Root {...otherProps}>
      <Oval className="oval">
        {
          icon ?
            icon
            :
            <Typography
              variant="h5"
              fontWeight="bold">
              {name.charAt(0)}
            </Typography>
        }
      </Oval>
      <TypeContainer>
        <TitleContainer>
          <Typography
            variant="h5"
            fontWeight="bold"
          >
            {name}
          </Typography>
        </TitleContainer>
        <Typography
          variant="caption">
          {description}
        </Typography>
      </TypeContainer>
      <Arrow className="arrow">
        <ArrowForwardIcon />
      </Arrow>
    </Root>
  );
};

WizardTypeTile.propTypes = {
  icon: PropTypes.element,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default WizardTypeTile;
