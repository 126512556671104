import GET__payments_invoices_ID_download from 'network-layer/endpoints/GET__payments_invoices_ID_download';

export default (authToken, perspectiveId, invoice) => {
  const {
    id: invoiceId,
    date: invoiceDate,
  } = invoice;

  return new Promise(async(resolve, reject) => {
    try {
      const title = `Jules Invoice ${invoiceDate}.pdf`;
      const response = await GET__payments_invoices_ID_download(authToken, perspectiveId, invoiceId);
      const buffer = await response.arrayBuffer();
      const blob = new Blob(
        [buffer],
        {type: 'application/pdf'},
      );
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', title);

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};
