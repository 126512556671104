import React from 'react';
import styled from '@emotion/styled';
import PerspectiveContext from 'contexts/Perspective';
import Typography from 'ui-library/components/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Root = styled.div`
  max-width: 500px;
`;

const PerspectiveName = styled.span`
  cursor: pointer;
`;

const PerspectiveSwitcher = () => {
  const {
    availablePerspectives,
    currentPerspective,
    setCurrentPerspective,
  } = React.useContext(PerspectiveContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <PerspectiveName>
        <Typography
          aria-controls="simple-menu"
          aria-haspopup={true}
          onClick={handleClick}
          fontFamily="primary"
          fontWeight="bold"
          variant="body">
          {currentPerspective.name}
        </Typography>
      </PerspectiveName>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {
          availablePerspectives.map((perspective, index) => {
            return (
              <MenuItem
                key={index}
                selected={currentPerspective.id === perspective.id}
                onClick={() => {
                  handleClose();
                  setCurrentPerspective(perspective);
                }}>
                {perspective.name}
              </MenuItem>
            );
          })
        }
      </Menu>
    </Root>
  );
};

export default PerspectiveSwitcher;
