import GET__records from 'network-layer/endpoints/GET__records';

function responseAdapter(response) {
  return response;
}

export default async function genRecords(authToken, perspectiveId) {
  const response = await GET__records(authToken, perspectiveId);

  return responseAdapter(response);
}
