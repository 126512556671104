class UnknownContentTypeHeaderError extends Error {
  constructor(errorBody) {
    super('UnknownContentType error!');

    this.name = 'UnknownContentTypeHeaderError';
    this.body = errorBody;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownContentTypeHeaderError);
    }
  }
}

export default UnknownContentTypeHeaderError;
