import queryString from 'query-string';
import interpolateURLParamValues from './interpolateURLParamValues';

export default ({
  match,
  match: {
    path,
    params,
  },
  location,
  location: {
    search,
  },
  paramsToUpdate,
  searchParamsToUpdate,
}) => {
  const newParams = {
    ...params,
    ...paramsToUpdate,
  };

  const newSearch = queryString.stringify({
    ...queryString.parse(search, {arrayFormat: 'index'}),
    ...searchParamsToUpdate,
  }, {arrayFormat: 'index'});

  let newURL = path;

  for (let newParam in newParams) {
    newURL = interpolateURLParamValues(newURL, newParam, newParams[newParam]);
  }

  return `${newURL}${newSearch ? `?${newSearch}` : ''}`;
};
