import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PUT} from '../verbs';

export default async function PUT__nodes_ID_permissions(authToken, perspectiveId, nodeId, params) {

  return await PUT(
    `/nodes/${nodeId}/permissions`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
