import React from 'react';
import PropTypes from 'prop-types';

import StripeForm from 'components/StripeForm';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';

const UpgradeMembershipdDialog = ({
  isVisible,
  isProcessing,
  errorMessage,
  setErrorMessage,
  handleClose,
  handleSubmit,
  subscriptionUpgradePlan,
}) => {

  return (
    <ModalCard
      open={isVisible}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <ModalCardHeader
        handleClose={handleClose}
        color="primary"
        disabled={isProcessing}
      >
        <ModalCardHeaderIcon
          color="primary"
        >
          <ArrowUpwardRoundedIcon
            fontSize="inherit"
          />
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle
        >
          {
            subscriptionUpgradePlan &&
            `Upgrade Membership : $${subscriptionUpgradePlan.price}`
          }
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        {
          subscriptionUpgradePlan &&
          <>
            <Typography
              variant="body"
            >
              Add a credit or debit card to be billed for your membership.
            </Typography>
            <div style={{
              height: 80,
              paddingTop: 8,
            }}>
              <StripeForm
                setErrorMessage={setErrorMessage}
              />
              {
                errorMessage ?
                  <Typography
                    variant="x-small"
                    color="error"
                  >
                    {errorMessage}
                  </Typography>
                  :
                  null
              }
            </div>
            <Typography
              variant="body"
            >
              Upgrade to an annual Jules Membership. Any days remaining in your Free Trial will be added to your annual membership.
            </Typography>
          </>
        }
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="modal-add-card-cancel-button"
          variant="text"
          onClick={handleClose}
          disabled={isProcessing}
        >
            Cancel
        </Button>
        <Button
          data-test-id="modal-add-card-submit-button"
          onClick={handleSubmit}
          disabled={isProcessing}
          startIcon={isProcessing ? <CircularProgress size={24} color="white"/> : <CheckCircleOutlineRoundedIcon />}
        >
            Pay Now
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

UpgradeMembershipdDialog.propTypes = {
  isVisible: PropTypes.bool,
  isProcessing: PropTypes.bool,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  subscriptionUpgradePlan: PropTypes.object,
};

export default UpgradeMembershipdDialog;
