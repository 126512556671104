import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__intelligentDataFramework_propertyConfigurationHints(authToken, perspectiveId, params) {
  const {
    targetTypeId,
  } = params;

  return await GET(
    `/intelligent-data-framework/property-configuration-hints?targetTypeId=${targetTypeId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
