import PATCH__notificationTypes_ID from 'network-layer/endpoints/PATCH__notificationTypes_ID';

function responseAdapter(response) {
  return response;
}

export default async function updateNotificationType(params) {
  const {
    authToken,
    notificationTypeId,
    enabledStatus,
  } = params;

  const response = await PATCH__notificationTypes_ID(
    authToken,
    notificationTypeId,
    {enabledStatus},
  );

  return responseAdapter(response);
}
