import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../../verbs';

export default async function PATCH__admin_users_USERID(authToken, perspectiveId, userId, email) {
  return await PATCH(
    `/admin/users/${userId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      userEmail: email,
    },
  );
}
