import React from 'react';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';

import ConnectionTypeStep from './steps/ConnectionTypeStep';
import ConnectionNameStep from './steps/ConnectionNameStep';
import ConnectionSaveStep from './steps/ConnectionSaveStep';

import saveIncomingConnection from 'services/Connections/saveIncomingConnection';
import saveOutgoingConnection from 'services/Connections/saveOutgoingConnection';

import internalCodeErrorMessage from 'api-error-codes/internalCodeErrorMessage';

import {
  initConnectionState,
  connectionReducer,
} from 'reducers/AddConnection';

const AddConnectionWizard = ({
  onClose,
  onSwitchWizard,
}) => {

  const TOTAL_STEPS = 3;
  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } = React.useContext(UserContext);


  const {
    currentPerspective: {
      id: currentPerspectiveId,
      name: currentPerspectiveName,
    },
  } = React.useContext(PerspectiveContext);


  const [step, setStep] = React.useState(1);
  const [connectionState, dispatchConnectionAction] = React.useReducer(
    connectionReducer,
    null,
    initConnectionState,
  );

  const {
    connectionType,
    connectionName,
    connectionToken,
    connectionSaveState,
  } = connectionState;

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        setAuthToken(null);
        setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw fatalError;
  }

  async function saveConnection() {
    try {
      dispatchConnectionAction({
        type: 'SET_CONNECTION_SAVE_STATE',
        payload: 'saving',
      });
      if (connectionType === 'outgoing') {
        const response = await saveOutgoingConnection(authToken, currentPerspectiveId, {name: connectionName});
        const {
          token,
        } = response;
        dispatchConnectionAction({
          type: 'SET_CONNECTION_TOKEN',
          payload: token,
        });
      } else if (connectionType === 'incoming') {
        await saveIncomingConnection(authToken, currentPerspectiveId, {name: connectionName, connectionToken: connectionToken});
      }
      dispatchConnectionAction({
        type: 'SET_CONNECTION_SAVE_STATE',
        payload: 'saved',
      });
      return true;
    } catch (err) {
      const {
        body: {
          code,
        },
      } = err;
      switch (code) {
        case 1069:
          dispatchConnectionAction({
            type: 'SET_CONNECTION_ERROR_MESSAGE',
            payload: internalCodeErrorMessage(code),
          });
          dispatchConnectionAction({
            type: 'SET_CONNECTION_SAVE_STATE',
            payload: 'notSaved',
          });
          return false;
        case 1070:
          dispatchConnectionAction({
            type: 'SET_CONNECTION_ERROR_MESSAGE',
            payload: internalCodeErrorMessage(code),
          });
          dispatchConnectionAction({
            type: 'SET_CONNECTION_SAVE_STATE',
            payload: 'notSaved',
          });
          return false;
        default:
          setError(err);
      }
    }
  }

  function hasState() {
    return step > 1;
  }

  function closeConfirmed() {
    if (!hasState()) {
      return true;
    }
    if (hasState() && window.confirm('Are you sure you want to quit the wizard? Your connection will not be saved if you quit now.')) {
      return true;
    }
    return false;
  }

  function closeWizard() {
    if (!closeConfirmed()) {
      return;
    }
    onClose();
  }

  function closeWizardWithPrepend() {
    onClose();
  }

  return (
    <Wizard onClose={closeWizard}>
      <WizardProgress
        isVisible={true}
        progress={step}
        total={TOTAL_STEPS}
      />
      <WizardNavigation>
        <WizardBackButton
          isVisible={step > 0 && connectionSaveState === 'notSaved'}
          onClick={() => {
            step > 1 && setStep(step - 1);
            step === 1 && onSwitchWizard();
          }}
        />
        <WizardCloseButton
          isVisible={step < TOTAL_STEPS && connectionSaveState !== 'saving'}
          onClick={closeWizard}
        />
      </WizardNavigation>

      <ConnectionTypeStep
        active={step === 1}
        dispatchConnectionAction={dispatchConnectionAction}
        navigateToStep={setStep}
      />

      <ConnectionNameStep
        active={step === 2}
        connectionState={connectionState}
        dispatchConnectionAction={dispatchConnectionAction}
        navigateToStep={setStep}
        saveConnection={saveConnection}
      />

      <ConnectionSaveStep
        active={step === 3}
        connectionState={connectionState}
        navigateToStep={setStep}
        currentPerspectiveName={currentPerspectiveName}
        closeWizardWithPrepend={closeWizardWithPrepend}
      />

    </Wizard>
  );
};

export default AddConnectionWizard;
