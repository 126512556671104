import GET__locations_locationCandidateRoots from 'network-layer/endpoints/GET__locations_locationCandidateRoots';

function responseAdapter(response) {
  return response;
}

export default async function genLocationCandidateRoots(authToken, perspectiveId, typeId, recordId) {
  const response = await GET__locations_locationCandidateRoots(authToken, perspectiveId, typeId, recordId);

  return responseAdapter(response);
}
