import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import MUIButton from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import {
  COLOR_MONIKERS,
} from 'ui-library/color-palette';

const ALLOWED_VARIANTS = [
  'custom',
  'outlined',
  'icon-outlined',
  'primary',
  'icon-primary',
  'secondary',
  'icon-secondary',
  'text',
  'icon-text',
  'fab-standard',
  'fab-extended',
  'icon-tertiary-light',
  'icon-custom',
];

const useStyles = makeStyles({
  button: {
    boxShadow: theme.button.boxShadow,
    fontWeight: theme.button.fontWeight,
    textAlign: theme.button.textAlign,
    textTransform: props => props.textTransform,
    background: props => ['custom', 'icon-custom'].includes(props.variant) ? theme.palette[props.customBgColor] : theme.button[props.variant].background,
    color: props => ['custom', 'icon-custom'].includes(props.variant) ? theme.palette[props.customTextColor] : theme.button[props.variant].color,
    border: props => ['outlined', 'icon-outlined'].includes(props.variant) ? theme.button.outlined.border.default : '',
    '&:hover': {
      background: props => ['custom', 'icon-custom'].includes(props.variant) ? theme.palette[props.customHoverBgColor] : theme.button[props.variant].hoverBackground,
      color: props => ['custom', 'icon-custom'].includes(props.variant) ? theme.palette[props.customHoverTextColor] : theme.button[props.variant].hoverColor,
      border: props => ['outlined', 'icon-outlined'].includes(props.variant) ? theme.button.outlined.border.hover : '',
      boxShadow: theme.button.boxShadow,
    },
    '&:active': {
      background: props => ['custom', 'icon-custom'].includes(props.variant) ? theme.palette[props.customActiveBgColor] : theme.button[props.variant].activeBackground,
      color: props => ['custom', 'icon-custom'].includes(props.variant) ? theme.palette[props.customActiveTextColor] : theme.button[props.variant].activeColor,
      border: props => ['outlined', 'icon-outlined'].includes(props.variant) ? theme.button.outlined.border.active : '',
      boxShadow: theme.button.boxShadow,
    },
    '&:disabled':{
      background: props => theme.button[props.variant].disabledBackground,
      color: props => theme.button[props.variant].disabledColor,
      border: props => ['outlined', 'icon-outlined'].includes(props.variant) ? theme.button.outlined.border.disabled : '',
      boxShadow: theme.button.boxShadow,
    },
    minWidth: props => theme.button[props.variant].minWidth,
    padding: props => theme.button[props.variant].padding,
  },
});

const Button = React.forwardRef(({
  children,
  variant,
  startIcon,
  endIcon,
  textTransform,
  customBgColor,
  customHoverBgColor,
  customTextColor,
  customHoverTextColor,
  customActiveBgColor,
  customActiveTextColor,
  ...otherProps
}, ref) => {

  const classes = useStyles({
    variant: ALLOWED_VARIANTS.indexOf(variant) > -1 ? variant : 'primary',
    textTransform: textTransform === 'none' ? textTransform : '',
    customBgColor: [...COLOR_MONIKERS].indexOf(customBgColor) > -1 ? customBgColor : 'primary',
    customTextColor: [...COLOR_MONIKERS].indexOf(customTextColor) > -1 ? customTextColor : 'white',
    customHoverBgColor: [...COLOR_MONIKERS].indexOf(customHoverBgColor) > -1 ? customHoverBgColor : 'primary-lighter',
    customHoverTextColor: [...COLOR_MONIKERS].indexOf(customHoverTextColor) > -1 ? customHoverTextColor : 'white',
    customActiveBgColor: [...COLOR_MONIKERS].indexOf(customActiveBgColor) > -1 ? customActiveBgColor : 'primary-lightest',
    customActiveTextColor: [...COLOR_MONIKERS].indexOf(customActiveTextColor) > -1 ? customActiveTextColor : 'white',
  });

  if (variant === 'outlined') {
    return (
      <MUIButton
        ref={ref}
        variant="outlined"
        startIcon={startIcon}
        endIcon={endIcon}
        className={classes.button}
        {...otherProps}
      >
        {children}
      </MUIButton>
    );
  }

  if (variant === 'fab-standard') {
    return (
      <Fab
        className={classes.button}
        {...otherProps}
      >
        {children}
      </Fab>
    );
  }

  if (variant === 'fab-extended') {
    return (
      <Fab
        variant="extended"
        className={classes.button}
        {...otherProps}
      >
        {children}
      </Fab>
    );
  }

  if (variant === 'icon-custom') {
    return (
      <IconButton
        className={classes.button}
        {...otherProps}
      >
        {children}
      </IconButton>
    );
  }

  return (
    <MUIButton
      ref={ref}
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      className={classes.button}
      {...otherProps}
    >
      {children}
    </MUIButton>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'custom',
    'outlined',
    'icon-outlined',
    'primary',
    'icon-primary',
    'secondary',
    'icon-secondary',
    'text',
    'icon-text',
    'fab-standard',
    'fab-extended',
    'icon-tertiary-light',
    'icon-custom',
  ]),
  startIcon:PropTypes.node,
  endIcon:PropTypes.node,
  textTransform: PropTypes.string,
  customBgColor: PropTypes.oneOf([...COLOR_MONIKERS]),
  customHoverBgColor: PropTypes.oneOf([...COLOR_MONIKERS]),
  customTextColor: PropTypes.oneOf([...COLOR_MONIKERS]),
  customHoverTextColor: PropTypes.oneOf([...COLOR_MONIKERS]),
  customActiveBgColor: PropTypes.oneOf([...COLOR_MONIKERS]),
  customActiveTextColor: PropTypes.oneOf([...COLOR_MONIKERS]),
};

export default Button;
