import selectVirtualLocationTreeNodeLocation from './selectVirtualLocationTreeNodeLocation';

function deriveVirtualLocationTreeNodeLocationDepth(
  configurationState,
  virtualLocationTreeNode,
) {
  let locationDepth = 0;
  let currentLocation = selectVirtualLocationTreeNodeLocation(configurationState, virtualLocationTreeNode);

  while(currentLocation !== null) {
    currentLocation = selectVirtualLocationTreeNodeLocation(configurationState, currentLocation);
    locationDepth++;
  }

  return locationDepth;
}

export default deriveVirtualLocationTreeNodeLocationDepth;
