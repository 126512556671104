import React from 'react';
import styled from '@emotion/styled';

import Typography from 'ui-library/components/Typography';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ConnectionNameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 48px;
  margin-bottom: 8px;
`;
const OrganizationNameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 32px;
  margin-bottom: 24px;
`;
const TetherNameContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 24px;
`;

const ConversationHeader = ({
  conversationConnectionName,
  conversationInterlocutorOrganizationName,
  conversationTetherName,
}) => {

  return (
    <HeaderContainer>
      <ConnectionNameContainer>
        <Typography
          variant="h3"
          fontWeight="bold"
        >
          {conversationConnectionName}
        </Typography>
      </ConnectionNameContainer>
      <OrganizationNameContainer>
        <Typography
          variant="h5"
        >
          {conversationInterlocutorOrganizationName}
        </Typography>
      </OrganizationNameContainer>
      <TetherNameContainer>
        <Typography
          variant="caption"
          color="grey"
        >
          {conversationTetherName}
        </Typography>
      </TetherNameContainer>
    </HeaderContainer>
  );
};

export default ConversationHeader;
