function selectNotificationPipelineById(DM, notificationPipelineId) {
  const {
    notificationPipelinesById: {
      [notificationPipelineId]: notificationPipeline,
    },
  } = DM;

  return notificationPipeline;
}

export default selectNotificationPipelineById;
