import GET__nodes_ID_tags from 'network-layer/endpoints/GET__nodes_ID_tags';

function responseAdapter(response) {
  return response;
}

export default async function genNodeTags(authToken, perspectiveId, nodeId) {
  const response = await GET__nodes_ID_tags(authToken, perspectiveId, nodeId);

  return responseAdapter(response);
}
