class UnknownSuccessCodeError extends Error {
  constructor(code) {
    super(`Unknown success code ${code}!`);

    this.name = 'UnknownSuccessCodeError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UnknownSuccessCodeError);
    }
  }
}

export default UnknownSuccessCodeError;
