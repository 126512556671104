import selectVirtualLocationTreeNodeParent from './selectVirtualLocationTreeNodeParent';

function selectVirtualLocationTreeNodeLocationPath(configurationState, virtualLocationTreeNode) {
  const locationPath = [];

  let parent = selectVirtualLocationTreeNodeParent(configurationState, virtualLocationTreeNode);

  while(parent) {
    locationPath.push(parent);
    parent = selectVirtualLocationTreeNodeParent(configurationState, parent);
  }

  return locationPath;
}

export default selectVirtualLocationTreeNodeLocationPath;
