import GET__search_filters from 'network-layer/endpoints/GET__search_filters';

function responseAdapter(response) {
  return response;
}

export default async function genSearchFilters(params) {
  const {
    authToken,
    currentPerspectiveId: perspectiveId,
    context,
    criterion,
  } = params;
  const response = await GET__search_filters(authToken, perspectiveId, {context, criterion});

  return responseAdapter(response);
}
