import selectVirtualLocationTreeNodeHints from './selectVirtualLocationTreeNodeHints';

function selectVirtualLocationTreeNodeHintsOfPropertyConfigurationHint(DM, propertyConfigurationHintId) {
  return (
    selectVirtualLocationTreeNodeHints(DM)
      .filter(virtualLocationTreeNodeHint => {
        const {
          propertyConfigurationHintId: id,
        } = virtualLocationTreeNodeHint;
        return id === propertyConfigurationHintId;
      })
  );
}

export default selectVirtualLocationTreeNodeHintsOfPropertyConfigurationHint;
