import GET__backup from 'network-layer/endpoints/GET__backup';

function responseAdapter(response) {
  return response;
}

export default async function getBackupMetadata(authToken) {

  const response = await GET__backup(authToken);

  return responseAdapter(response);
}
