import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import UsersList from './UsersList.react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {email} from 'utils/validator';

import genUserProperties from 'services/ControlPanel/genUserProperties';
import transferProperty from 'services/ControlPanel/transferProperty';

const List = ({
  history,
}) => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    setAPIException,
    dispatchDataModelAction,
  } = React.useContext(AdminContext);
  let [showAll, setShowAll] = React.useState(false);
  let [filterQuery, setFilterQuery] = React.useState('');
  const filteredUsers = DataModelQueries.selectUsers(DataModel).filter(
    user =>
      user.firstName.toLocaleLowerCase().includes(
        filterQuery.toLocaleLowerCase(),
      ) ||
      user.lastName.toLocaleLowerCase().includes(
        filterQuery.toLocaleLowerCase(),
      ) ||
      user.email.toLocaleLowerCase().includes(
        filterQuery.toLocaleLowerCase(),
      ),
  );

  const [selectedUserEmail, setSelectedUserEmail] = React.useState('');
  const [selectedUserId, setSelectedUserId] = React.useState(null);
  const [selectedUserFirstName, setSelectedUserFirstName] = React.useState('');
  const [selectedUserLastName, setSelectedUserLastName] = React.useState('');

  const [showChangeEmailDialog, setShowChangeEmailDialog] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');

  const [showTransferPropertyDialog, setShowTransferPropertyDialog] = React.useState(false);
  const [transferUserId, setTransferUserId] = React.useState(null);
  const [selectedPropertyId, setSelectedPropertyId] = React.useState(null);
  const [isTransfering, setIsTransfering] = React.useState(false);

  const [availableProperties, setAvailableProperties] = React.useState([]);
  const availableUsersForPropertyTransfer = DataModelQueries.selectUsers(DataModel).filter(
    user => user.email !== selectedUserEmail,
  );

  const handleCloseChangeEmailDialog = () => {
    setShowChangeEmailDialog(false);
    setSelectedUserEmail('');
    setSelectedUserId(null);
    setIsSaving(false);
    setHasChanged(false);
  };

  const handleChangeEmail = async() => {
    try {
      setIsSaving(true);

      await DataModelAPI.changeEmail(
        authToken,
        perspectiveId,
        selectedUserId,
        selectedUserEmail,
      );

      dispatchDataModelAction({
        type: 'CHANGE_EMAIL',
        payload: {
          userId: selectedUserId,
          email: selectedUserEmail,
        },
      });

      setShowChangeEmailDialog(false);
      setSelectedUserEmail('');
      setSelectedUserId(null);
      setIsSaving(false);
      setHasChanged(false);

    } catch(error) {
      setAPIException(error);
      setIsSaving(false);
    }
  };

  const handleCloseTransferPropertyDialog = () => {
    setShowTransferPropertyDialog(false);
    setSelectedUserId(null);
    setSelectedUserEmail('');
    setSelectedUserFirstName('');
    setSelectedUserLastName('');
    setSelectedPropertyId(null);
    setTransferUserId(null);
  };

  const handleTransferProperty = async() => {
    try {
      setIsTransfering(true);
      await transferProperty(authToken, perspectiveId, transferUserId, selectedPropertyId);
      handleCloseTransferPropertyDialog();
      setIsTransfering(false);
    } catch (error) {
      setAPIException(error);
      setIsTransfering(false);
    }
  };

  return (
    <div>
      <Typography
        variant="h4">
        Users
      </Typography>
      <Typography
        variant="body2">
        view or edit Users
      </Typography>

      <br/><br/>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setFilterQuery('');
          setShowAll(!(showAll && !filterQuery));
        }}>
        {showAll && !filterQuery ? 'hide' : 'show'} all users
      </Button>
      <br/><br/>

      <TextField
        fullWidth
        placeholder="filter by user name"
        value={filterQuery}
        onChange={event => {
          setFilterQuery(event.target.value);
          setShowAll(true);
        }}
      />
      <br/><br/><br/>

      {showAll && (
        <UsersList
          users={filteredUsers}
          onEditUser={userId => {
            history.push(`/users/${userId}`);
          }}
          onEditEmail={(userId, email) => {
            setSelectedUserEmail(email);
            setSelectedUserId(userId);
            setShowChangeEmailDialog(true);
          }}
          onTransferProperty={async(userId, firstName, lastName, email) => {
            const properties = await genUserProperties(authToken, perspectiveId, userId);
            setAvailableProperties(properties);
            setSelectedUserId(userId);
            setSelectedUserEmail(email);
            setSelectedUserFirstName(firstName);
            setSelectedUserLastName(lastName);
            setShowTransferPropertyDialog(true);
          }}
        />
      )}

      {/* CHANGE E-MAIL DIALOG */}
      <Dialog
        open={showChangeEmailDialog}
        onClose={handleCloseChangeEmailDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Edit Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit the user's email address
          </DialogContentText>
          <TextField
            value={selectedUserEmail}
            onChange={(ev) => {
              setEmailErrorMessage(email(ev.target.value));
              setHasChanged(true);
              setSelectedUserEmail(ev.target.value);
            }}
            margin="dense"
            id="name"
            label="Email Address"
            placeholder="Email Address"
            type="email"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={
              !selectedUserEmail ||
              isSaving ||
              !hasChanged ||
              !!emailErrorMessage
            }
            onClick={handleChangeEmail}
            color="primary"
            variant="contained"
          >
            CHANGE EMAIL
          </Button>
          <Button
            onClick={handleCloseChangeEmailDialog}
            color="primary"
            variant="text"
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

      {/* TRANSFER PROPERTY DIALOG */}
      <Dialog
        open={showTransferPropertyDialog}
        onClose={handleCloseTransferPropertyDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Tranfer Property</DialogTitle>
        {
          availableProperties.length > 0 ?
            <DialogContent>
              <DialogContentText>
                Transfer a property from {selectedUserFirstName} {selectedUserLastName} ({selectedUserEmail}) to someone else.
              </DialogContentText>

              <FormControl fullWidth>
                <InputLabel id="property-select-label">1. Select property</InputLabel>
                <Select
                  labelId="property-select-label"
                  id="property-select"
                  value={selectedPropertyId || ''}
                  label="Property"
                  onChange={(ev) => {
                    setSelectedPropertyId(ev.target.value);
                  }}
                >
                  {
                    availableProperties.map(property => {
                      const {
                        name,
                        id,
                      } = property;

                      return (
                        <MenuItem
                          key={id}
                          value={id}
                        >
                          {name}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
              <br/>
              <br/>
              <br/>
              <FormControl fullWidth>
                <InputLabel id="property-select-label">2. Select user</InputLabel>
                <Select
                  labelId="property-select-label"
                  id="property-select"
                  value={transferUserId || ''}
                  label="Age"
                  onChange={(ev) => {
                    setTransferUserId(ev.target.value);
                  }}
                >
                  {
                    availableUsersForPropertyTransfer.map((user) => {
                      const {
                        userId,
                        firstName,
                        lastName,
                      } = user;

                      return (
                        <MenuItem key={userId} value={userId}>{firstName} {lastName}</MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
              <br/>
              <br/>
              <br/>
              <DialogContentText>
                Please take into account that transfering a property completely can take up to 5 minutes.
              </DialogContentText>
            </DialogContent>
            :
            <DialogContent>
              <DialogContentText>
                This user does not have any properties.
              </DialogContentText>
            </DialogContent>
        }
        <DialogActions>
          <Button
            disabled={
              !availableProperties.length ||
              !transferUserId ||
              !selectedPropertyId ||
              isTransfering
            }
            onClick={handleTransferProperty}
            color="primary"
            variant="contained"
          >
            TRANSFER PROPERTY
          </Button>
          <Button
            onClick={handleCloseTransferPropertyDialog}
            color="primary"
            variant="text"
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default List;
