import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__locations_locationCandidatesTree(authToken, perspectiveId, typeId, recordId, rootLocationId) {

  return await GET(
    `/locations/location-candidates-tree?typeId=${typeId}&recordId=${recordId}&rootLocationId=${rootLocationId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
