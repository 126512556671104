import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import MUICheckbox from '@material-ui/core/Checkbox';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';

const useStyles = makeStyles({
  root: {
    display: theme.notificationStatus.display,
    alignItems: theme.notificationStatus.alignItems,
    padding: theme.notificationStatus.padding,
    '&:hover': {
      backgroundColor: theme.notificationStatus.backgroundColor,
    },
    '&$checked': {
      color: theme.notificationStatus['checked'].color,
      '&:hover': {
        backgroundColor: theme.notificationStatus.backgroundColor,
      },
    },
  },
  icon: {
    width: theme.notificationStatus.width,
    height: theme.notificationStatus.height,
  },
  checked: {},
});

const NotificationStatus = ({
  checked,
  onChange,
}) => {

  const classes = useStyles();

  return (
    <MUICheckbox
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
      disableRipple
      checked={checked}
      icon={<FiberManualRecordOutlinedIcon className={classes.icon}/>}
      checkedIcon={<FiberManualRecordRoundedIcon className={classes.icon}/>}
      onChange={onChange}
    />
  );
};

NotificationStatus.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default NotificationStatus;
