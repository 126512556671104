import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import Typography from 'ui-library/components/Typography';
import FilterCloseButton from 'ui-library/components/FilterCloseButton';

const useStyles = makeStyles({
  root: {
    display: theme.filterPill.root.display,
  },
  container: {
    backgroundColor: theme.filterPill.container.backgroundColor,
    display: theme.filterPill.container.display,
    paddingLeft: theme.filterPill.container.paddingLeft,
    height: theme.filterPill.container.height,
    borderRadius: theme.filterPill.container.borderRadius,
    justifyContent: theme.filterPill.container.justifyContent,
    margin: theme.filterPill.container.margin,
  },
  valueContainer: {
    display: 'grid',
  },
  content: {
    whiteSpace: theme.searchPill.content.whiteSpace,
    overflow: theme.searchPill.content.overflow,
    textOverflow: theme.searchPill.content.textOverflow,
    margin: theme.searchPill.content.margin,
  },
});

const SearchPill = ({
  searchQuery,
  onClick,
  removeSearchPill,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          className={classes.valueContainer}
          onClick={onClick}
        >
          <div className={classes.content}>
            <Typography variant="x-small" fontWeight="bold" color="tertiary">
              Search:
            </Typography>
            &nbsp;
            <Typography variant="x-small" fontWeight="bold" color="black">
              {searchQuery}
            </Typography>
          </div>
        </div>

        <FilterCloseButton
          onClick={removeSearchPill}
        />
      </div>
    </div>
  );
};

SearchPill.propTypes = {
  searchQuery: PropTypes.string,
  onClick: PropTypes.func,
  removeSearchPill: PropTypes.func,
};

export default SearchPill;
