import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CreateNotificationPipelineDialog from './CreateNotificationPipelineDialog.react';
import EditNotificationPipelineDialog from './EditNotificationPipelineDialog.react';
import CreateFieldWatcherDialog from './CreateFieldWatcherDialog.react';
import EditFieldWatcherDialog from './EditFieldWatcherDialog.react';
import AddFieldDefinitionToFieldWatcherDialog from './AddFieldDefinitionToFieldWatcherDialog.react';
import NotificationPipelinesList from './NotificationPipelinesList.react';
import Prompt from 'components/admin/Prompt';

const NotificationPipelines = () => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  return (
    <div>
      <Typography
        variant="h4">
        Notification pipelines
      </Typography>
      <Typography
        variant="body2">
        create or edit notification pipelines
      </Typography>

      <br/><br/>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatchLocalAction({
            type: 'CREATE_NOTIFICATION_PIPELINE',
          });
        }}>
        create a notification pipeline
      </Button>
      <br/><br/>

      <NotificationPipelinesList
        DataModelQueries={DataModelQueries}
        DataModel={DataModel}
        notificationPipelines={DataModelQueries.selectNotificationPipelines(DataModel)}
        onRemoveNotificationPipeline={notificationPipelineId => {
          dispatchLocalAction({
            type: 'DELETE_NOTIFICATION_PIPELINE',
            payload: notificationPipelineId,
          });
        }}
        onEditNotificationPipeline={notificationPipelineId => {
          dispatchLocalAction({
            type: 'UPDATE_NOTIFICATION_PIPELINE',
            payload: notificationPipelineId,
          });
        }}
        onAddFieldWatcher={notificationPipelineId => {
          dispatchLocalAction({
            type: 'CREATE_FIELD_WATCHER',
            payload: notificationPipelineId,
          });
        }}
        onRemoveFieldWatcher={async fieldWatcherId => {
          try {
            await DataModelAPI.deleteFieldWatcher(
              authToken,
              perspectiveId,
              {
                fieldWatcherId,
              },
            );
            dispatchDataModelAction({
              type: 'REMOVE_FIELD_WATCHER',
              payload: fieldWatcherId,
            });
          } catch(error) {
            setAPIException(error);
          }
        }}
        onEditFieldWatcher={fieldWatcherId => {
          dispatchLocalAction({
            type: 'UPDATE_FIELD_WATCHER',
            payload: fieldWatcherId,
          });
        }}
        onAddFieldDefinitionToFieldWatcher={fieldWatcherId => {
          dispatchLocalAction({
            type: 'ADD_FIELD_DEFINITION_TO_FIELD_WATCHER',
            payload: fieldWatcherId,
          });
        }}
        onRemoveFieldDefinitionFromFieldWatcher={async(fieldDefinitionId, fieldWatcherId) => {
          try {
            await DataModelAPI.removeFieldDefinitionIdFromFieldWatcher(
              authToken,
              perspectiveId,
              {
                fieldWatcherId,
                fieldDefinitionId,
              },
            );
            dispatchDataModelAction({
              type: 'REMOVE_FIELD_DEFINITION_FROM_FIELD_WATCHER',
              payload: {
                fieldWatcherId,
                fieldDefinitionId,
              },
            });
          } catch(error) {
            setAPIException(error);
          }
        }}
        onEnableNotificationPipeline={async notificationPipelineId => {
          try {
            await DataModelAPI.updateNotificationPipeline(
              authToken,
              perspectiveId,
              {
                notificationPipelineId,
                enabledStatus: 'enabled',
              },
            );
            dispatchDataModelAction({
              type: 'ENABLE_NOTIFICATION_PIPELINE',
              payload: notificationPipelineId,
            });
          } catch(error) {
            setAPIException(error);
          }
        }}
        onDisableNotificationPipeline={async notificationPipelineId => {
          try {
            await DataModelAPI.updateNotificationPipeline(
              authToken,
              perspectiveId,
              {
                notificationPipelineId,
                enabledStatus: 'disabled',
              },
            );
            dispatchDataModelAction({
              type: 'DISABLE_NOTIFICATION_PIPELINE',
              payload: notificationPipelineId,
            });
          } catch(error) {
            setAPIException(error);
          }
        }}
      />

      {
        (
          localState.status === 'updating notification pipeline' ||
          localState.status === 'requesting update notification pipeline'
        )
        &&
        <EditNotificationPipelineDialog
          open
          onClose={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
          controlsDisabled={localState.status === 'requesting update notification pipeline'}
          notificationPipelineName={localState.notificationPipelineName}
          onSetNotificationPipelineName={value => {
            dispatchLocalAction({
              type: 'SET_NOTIFICATION_PIPELINE_NAME',
              payload: value,
            });
          }}
          notificationPipelineDescription={localState.notificationPipelineDescription}
          onSetNotificationPipelineDescription={value => {
            dispatchLocalAction({
              type: 'SET_NOTIFICATION_PIPELINE_DESCRIPTION',
              payload: value,
            });
          }}
          notificationPipelineNotificationTextTemplate={localState.notificationPipelineNotificationTextTemplate}
          onSetNotificationPipelineNotificationTextTemplate={value => {
            dispatchLocalAction({
              type: 'SET_NOTIFICATION_PIPELINE_NOTIFICATION_TEXT_TEMPLATE',
              payload: value,
            });
          }}
          onSave={async() => {
            dispatchLocalAction({
              type: 'REQUEST_UPDATE_NOTIFICATION_PIPELINE',
            });
            const {
              notificationPipelineId,
              notificationPipelineName: name,
              notificationPipelineDescription: description,
              notificationPipelineNotificationTextTemplate: notificationTextTemplate,
            } = localState;
            const patch = {};
            if (name) {
              patch.name = name;
            }
            if (description) {
              patch.description = description;
            }
            if (notificationTextTemplate) {
              patch.notificationTextTemplate = notificationTextTemplate;
            }
            try {
              await DataModelAPI.updateNotificationPipeline(
                authToken,
                perspectiveId,
                {
                  notificationPipelineId,
                  ...patch,
                },
              );
              dispatchDataModelAction({
                type: 'UPDATE_NOTIFICATION_PIPELINE',
                payload: {
                  notificationPipelineId,
                  patch,
                },
              });
              dispatchLocalAction({
                type: 'RESET',
              });
            } catch(error) {
              setAPIException(error);
              dispatchLocalAction({
                type: 'RESET',
              });
            }
          }}
        />
      }

      {
        (
          localState.status === 'updating field watcher' ||
          localState.status === 'requesting update field watcher'
        )
        &&
        <EditFieldWatcherDialog
          open
          onClose={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
          fieldWatcherTimeDelta={localState.fieldWatcherTimeDelta}
          onSetFieldWatcherTimeDelta={value => {
            dispatchLocalAction({
              type: 'SET_FIELD_WATCHER_TIME_DELTA',
              payload: value,
            });
          }}
          controlsDisabled={
            localState.status === 'requesting update field watcher'
          }
          saveDisabled={
            ((timeDeltaString) => {
              const timeDelta = +timeDeltaString;
              if (isNaN(timeDelta)) {
                return true;
              }
              if (timeDelta < -366 || timeDelta > 366) {
                return true;
              }
              if (timeDelta >= 0) {
                if (Math.floor(timeDelta) !== timeDelta) {
                  return true;
                }
              } else {
                if (
                  Math.floor(timeDelta) !== timeDelta &&
                  Math.floor(timeDelta) + 1 !== timeDelta
                ) {
                  return true;
                }
              }
              return false;
            })(localState.fieldWatcherTimeDelta)
          }
          onSave={async() => {
            dispatchLocalAction({
              type: 'REQUEST_UPDATE_FIELD_WATCHER',
            });
            try {
              await DataModelAPI.updateFieldWatcher(
                authToken,
                perspectiveId,
                {
                  fieldWatcherId: localState.fieldWatcherId,
                  timeDelta: localState.fieldWatcherTimeDelta,
                },
              );
              dispatchDataModelAction({
                type: 'UPDATE_FIELD_WATCHER',
                payload: {
                  fieldWatcherId: localState.fieldWatcherId,
                  patch: {
                    timeDelta: localState.fieldWatcherTimeDelta,
                  },
                },
              });
              dispatchLocalAction({
                type: 'RESET',
              });
            } catch(error) {
              dispatchLocalAction({
                type: 'RESET',
              });
              setAPIException(error);
            }
          }}
        />
      }

      {
        (
          localState.status === 'deleting notification pipeline'
        )
        &&
        <Prompt
          open
          title="Confirm operation"
          text={
            <>
              You are about to delete the <strong>{DataModelQueries.selectNotificationPipelineById(DataModel, localState.notificationPipelineId).name}</strong> notification pipeline. This is a destructive operation that cannot be rolled back. Are you sure you want to do this?
            </>
          }
          onCancel={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
          confirmLabel="yes, delete this notification pipeline"
          onConfirm={async() => {
            dispatchLocalAction({
              type: 'REQUEST_DELETE_NOTIFICATION_PIPELINE',
            });
            try {
              await DataModelAPI.deleteNotificationPipeline(
                authToken,
                perspectiveId,
                {
                  notificationPipelineId: localState.notificationPipelineId,
                },
              );
              dispatchDataModelAction({
                type: 'REMOVE_NOTIFICATION_PIPELINE',
                payload: localState.notificationPipelineId,
              });
              dispatchDataModelAction({
                type: 'REMOVE_FIELD_WATCHERS',
                payload: DataModelQueries.selectFieldWatcherIdsByNotificationPipelineId(
                  DataModel,
                  localState.notificationPipelineId,
                ),
              });
              dispatchLocalAction({
                type: 'RESET',
              });
            } catch(error) {
              dispatchLocalAction({
                type: 'RESET',
              });
              setAPIException(error);
            }
          }}
        />
      }

      {
        (
          localState.status === 'requesting delete notification pipeline'
        )
        &&
        <Prompt
          open
          title="Please wait"
          text={
            <>
              Deleting notification pipeline...
            </>
          }
        />
      }

      {
        (
          localState.status === 'creating notification pipeline' ||
          localState.status === 'requesting create notification pipeline'
        )
        &&
        <CreateNotificationPipelineDialog
          open
          onClose={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
          controlsDisabled={localState.status === 'requesting create notification pipeline'}
          notificationPipelineName={localState.notificationPipelineName}
          onSetNotificationPipelineName={value => {
            dispatchLocalAction({
              type: 'SET_NOTIFICATION_PIPELINE_NAME',
              payload: value,
            });
          }}
          notificationPipelineDescription={localState.notificationPipelineDescription}
          onSetNotificationPipelineDescription={value => {
            dispatchLocalAction({
              type: 'SET_NOTIFICATION_PIPELINE_DESCRIPTION',
              payload: value,
            });
          }}
          notificationPipelineNotificationTextTemplate={localState.notificationPipelineNotificationTextTemplate}
          onSetNotificationPipelineNotificationTextTemplate={value => {
            dispatchLocalAction({
              type: 'SET_NOTIFICATION_PIPELINE_NOTIFICATION_TEXT_TEMPLATE',
              payload: value,
            });
          }}
          notificationPipelinePerspectiveTypes={localState.notificationPipelinePerspectiveTypes}
          onAddNotificationPipelinePerspectiveType={value => {
            dispatchLocalAction({
              type: 'ADD_NOTIFICATION_PIPELINE_PERSPECTIVE_TYPE',
              payload: value,
            });
          }}
          onRemoveNotificationPipelinePerspectiveType={value => {
            dispatchLocalAction({
              type: 'REMOVE_NOTIFICATION_PIPELINE_PERSPECTIVE_TYPE',
              payload: value,
            });
          }}
          onSave={async() => {
            dispatchLocalAction({
              type: 'REQUEST_CREATE_NOTIFICATION_PIPELINE',
            });
            const {
              notificationPipelineName: name,
              notificationPipelineDescription: description,
              notificationPipelineNotificationTextTemplate: notificationTextTemplate,
              notificationPipelinePerspectiveTypes: perspectiveTypes,
            } = localState;
            try {
              const notificationPipeline = await DataModelAPI.createNotificationPipeline(
                authToken,
                perspectiveId,
                {
                  name,
                  description,
                  perspectiveTypes,
                  notificationTextTemplate,
                },
              );
              dispatchDataModelAction({
                type: 'ADD_NOTIFICATION_PIPELINE',
                payload: notificationPipeline,
              });
              dispatchLocalAction({
                type: 'SUCCESS_CREATE_NOTIFICATION_PIPELINE',
              });
            } catch(error) {
              setAPIException(error);
              dispatchLocalAction({
                type: 'RESET',
              });
            }
          }}
        />
      }

      {
        localState.status === 'created notification pipeline' &&
          <Prompt
            open
            title="Success!"
            text={
              <>
                You have created the <strong>{localState.notificationPipelineName}</strong> notification pipeline!<br/>
                <br/>
                It's time to add some field watchers to this notification pipeline. After you're done, you must enable it manually; it is not enabled by default.<br/>
                <br/>
                Once you've enabled this notification pipeline it will begin generating notifications for users.
              </>
            }
            confirmLabel="ok"
            onConfirm={() => {
              dispatchLocalAction({
                type: 'RESET',
              });
            }}
          />
      }

      {
        (
          localState.status === 'creating field watcher' ||
          localState.status === 'requesting create field watcher'
        )
        &&
        <CreateFieldWatcherDialog
          open
          onClose={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
          controlsDisabled={
            localState.status === 'requesting create field watcher'
          }
          DataModelQueries={DataModelQueries}
          DataModel={DataModel}
          notificationPipelineId={localState.notificationPipelineId}
          fieldWatcherType={localState.fieldWatcherType}
          onSetFieldWatcherType={value => {
            dispatchLocalAction({
              type: 'SET_FIELD_WATCHER_TYPE',
              payload: value,
            });
          }}
          fieldWatcherTimeDelta={localState.fieldWatcherTimeDelta}
          onSetFieldWatcherTimeDelta={value => {
            dispatchLocalAction({
              type: 'SET_FIELD_WATCHER_TIME_DELTA',
              payload: value,
            });
          }}
          saveDisabled={
            ((timeDeltaString) => {
              const timeDelta = +timeDeltaString;
              if (isNaN(timeDelta)) {
                return true;
              }
              if (timeDelta < -366 || timeDelta > 366) {
                return true;
              }
              if (timeDelta >= 0) {
                if (Math.floor(timeDelta) !== timeDelta) {
                  return true;
                }
              } else {
                if (
                  Math.floor(timeDelta) !== timeDelta &&
                  Math.floor(timeDelta) + 1 !== timeDelta
                ) {
                  return true;
                }
              }
              return false;
            })(localState.fieldWatcherTimeDelta)
          }
          onSave={async() => {
            dispatchLocalAction({
              type: 'REQUEST_CREATE_FIELD_WATCHER',
            });
            const {
              notificationPipelineId,
              fieldWatcherType: type,
              fieldWatcherTimeDelta: timeDelta,
            } = localState;
            try {
              const fieldWatcher = await DataModelAPI.createFieldWatcher(
                authToken,
                perspectiveId,
                {
                  notificationPipelineId,
                  type,
                  timeDelta,
                },
              );
              dispatchDataModelAction({
                type: 'ADD_FIELD_WATCHER',
                payload: fieldWatcher,
              });
              dispatchLocalAction({
                type: 'SUCCESS_CREATE_FIELD_WATCHER',
              });
            } catch(error) {
              setAPIException(error);
              dispatchLocalAction({
                type: 'RESET',
              });
            }
          }}
        />
      }

      {
        localState.status === 'created field watcher' &&
          <Prompt
            open
            title="Success!"
            text={
              <>
                You have created a Field Watcher!<br/>
                Adding some Fields to this Field Watcher will allow the Notification Pipeline to generate notifications for these Fields.<br/>
              </>
            }
            confirmLabel="ok"
            onConfirm={() => {
              dispatchLocalAction({
                type: 'RESET',
              });
            }}
          />
      }

      {
        (
          localState.status === 'adding field definition to field watcher' ||
          localState.status === 'requesting add field definition to field watcher'
        )
        &&
        <AddFieldDefinitionToFieldWatcherDialog
          open
          onClose={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
          controlsDisabled={
            localState.status === 'requesting add field definition to field watcher'
          }
          DataModelQueries={DataModelQueries}
          DataModel={DataModel}
          type={localState.type}
          onSetType={value => {
            dispatchLocalAction({
              type: 'SET_TYPE',
              payload: value,
            });
          }}
          fieldDefinitionId={localState.fieldDefinitionId}
          onSetFieldDefinitionId={value => {
            dispatchLocalAction({
              type: 'SET_FIELD_DEFINITION_ID',
              payload: value,
            });
          }}
          onSave={async() => {
            const {
              fieldWatcherId,
              fieldDefinitionId,
            } = localState;
            dispatchLocalAction({
              type: 'REQUEST_ADD_FIELD_DEFINITION_TO_FIELD_WATCHER',
            });
            try {
              await DataModelAPI.addFieldDefinitionIdToFieldWatcher(
                authToken,
                perspectiveId,
                {
                  fieldWatcherId,
                  fieldDefinitionId,
                },
              );
              dispatchDataModelAction({
                type: 'ADD_FIELD_DEFINITION_TO_FIELD_WATCHER',
                payload: {
                  fieldWatcherId,
                  fieldDefinitionId,
                },
              });
              dispatchLocalAction({
                type: 'RESET',
              });
            } catch(error) {
              setAPIException(error);
              dispatchLocalAction({
                type: 'RESET',
              });
            }
          }}
        />
      }
    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'CREATE_NOTIFICATION_PIPELINE': {
      return {
        ...state,
        status: 'creating notification pipeline',
      };
    }

    case 'DELETE_NOTIFICATION_PIPELINE': {
      const notificationPipelineId = payload;

      return {
        ...state,
        status: 'deleting notification pipeline',
        notificationPipelineId,
      };
    }

    case 'REQUEST_DELETE_NOTIFICATION_PIPELINE': {
      return {
        ...state,
        status: 'requesting delete notification pipeline',
      };
    }

    case 'UPDATE_NOTIFICATION_PIPELINE': {
      const notificationPipelineId = payload;

      return {
        ...state,
        status: 'updating notification pipeline',
        notificationPipelineId,
      };
    }

    case 'REQUEST_UPDATE_NOTIFICATION_PIPELINE': {
      return {
        ...state,
        status: 'requesting update notification pipeline',
      };
    }

    case 'SET_NOTIFICATION_PIPELINE_NAME': {
      const notificationPipelineName = payload;

      return {
        ...state,
        notificationPipelineName,
      };
    }

    case 'SET_NOTIFICATION_PIPELINE_DESCRIPTION': {
      const notificationPipelineDescription = payload;

      return {
        ...state,
        notificationPipelineDescription,
      };
    }

    case 'SET_NOTIFICATION_PIPELINE_NOTIFICATION_TEXT_TEMPLATE': {
      const notificationPipelineNotificationTextTemplate = payload;

      return {
        ...state,
        notificationPipelineNotificationTextTemplate,
      };
    }

    case 'ADD_NOTIFICATION_PIPELINE_PERSPECTIVE_TYPE': {
      const perspectiveType = payload;

      return {
        ...state,
        notificationPipelinePerspectiveTypes: [
          ...state.notificationPipelinePerspectiveTypes,
          perspectiveType,
        ],
      };
    }

    case 'REMOVE_NOTIFICATION_PIPELINE_PERSPECTIVE_TYPE': {
      const perspectiveType = payload;

      return {
        ...state,
        notificationPipelinePerspectiveTypes: [
          ...state.notificationPipelinePerspectiveTypes.slice(0, state.notificationPipelinePerspectiveTypes.indexOf(perspectiveType)),
          ...state.notificationPipelinePerspectiveTypes.slice(state.notificationPipelinePerspectiveTypes.indexOf(perspectiveType) + 1),
        ],
      };
    }

    case 'REQUEST_CREATE_NOTIFICATION_PIPELINE': {
      return {
        ...state,
        status: 'requesting create notification pipeline',
      };
    }

    case 'SUCCESS_CREATE_NOTIFICATION_PIPELINE': {
      return {
        ...state,
        status: 'created notification pipeline',
      };
    }

    case 'CREATE_FIELD_WATCHER': {
      const notificationPipelineId = payload;

      return {
        ...state,
        status: 'creating field watcher',
        notificationPipelineId,
      };
    }

    case 'UPDATE_FIELD_WATCHER': {
      const fieldWatcherId = payload;

      return {
        ...state,
        status: 'updating field watcher',
        fieldWatcherId,
      };
    }

    case 'REQUEST_UPDATE_FIELD_WATCHER': {
      return {
        ...state,
        status: 'requesting update field watcher',
      };
    }

    case 'SET_FIELD_WATCHER_TYPE': {
      const fieldWatcherType = payload;

      return {
        ...state,
        fieldWatcherType,
      };
    }

    case 'SET_FIELD_WATCHER_TIME_DELTA': {
      const fieldWatcherTimeDelta = payload;

      return {
        ...state,
        fieldWatcherTimeDelta,
      };
    }

    case 'REQUEST_CREATE_FIELD_WATCHER': {
      return {
        ...state,
        status: 'requesting create field watcher',
      };
    }

    case 'SUCCESS_CREATE_FIELD_WATCHER': {
      return {
        ...state,
        status: 'created field watcher',
      };
    }

    case 'ADD_FIELD_DEFINITION_TO_FIELD_WATCHER': {
      const fieldWatcherId = payload;

      return {
        ...state,
        status: 'adding field definition to field watcher',
        fieldWatcherId,
      };
    }

    case 'SET_TYPE': {
      const type = payload;

      return {
        ...state,
        type,
        fieldDefinitionId: '',
      };
    }

    case 'SET_FIELD_DEFINITION_ID': {
      const fieldDefinitionId = payload;

      return {
        ...state,
        fieldDefinitionId,
      };
    }

    case 'REQUEST_ADD_FIELD_DEFINITION_TO_FIELD_WATCHER': {
      return {
        ...state,
        status: 'requesting add field definition to field watcher',
      };
    }

    case 'RESET': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      creating notification pipeline
      requesting create notification pipeline
      created notification pipeline
      deleting notification pipeline
      requesting delete notification pipeline
      updating notification pipeline
      requesting update notification pipeline

      creating field watcher
      requesting create field watcher
      created field watcher
      updating field watcher
      requesting update field watcher

      adding field definition to field watcher
      requesting add field definition to field watcher
    */
    status: 'idle',
    notificationPipelineId: null,
    notificationPipelineName: '',
    notificationPipelineDescription: '',
    notificationPipelineNotificationTextTemplate: '',
    notificationPipelinePerspectiveTypes: [],
    fieldWatcherType: '',
    fieldWatcherTimeDelta: '',
    fieldWatcherId: null,
    type: null,
    fieldDefinitionId: '',
  };
}

export default NotificationPipelines;
