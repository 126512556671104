function selectTypeLocationGraphRootIds(DM) {
  const {
    typeIds,
    typeLocationGraph,
  } = DM;

  const typeLocationGraphRootIds = typeIds.filter(typeId => {
    let isRoot = true;
    for (let typeLocationGraphParentId of Object.keys(typeLocationGraph)) {
      if (typeLocationGraph[typeLocationGraphParentId].includes(typeId)) {
        isRoot = false;
        break;
      }
    }
    return isRoot;
  });

  return typeLocationGraphRootIds;
}

export default selectTypeLocationGraphRootIds;
