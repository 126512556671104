import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const EditUser = ({
  history,
  match,
}) => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    setAPIException,
  } = React.useContext(AdminContext);

  const [currentOrganization, setCurrentOrganization] = React.useState(null);
  const [organizationOptions, setOrganizationOptions] = React.useState([]);

  const [currentSubscription, setCurrentSubscription] = React.useState(null);
  const [subscriptionOptions, setSubscriptionOptions] = React.useState([]);
  const [selectedSubscription, setSelectedSubscription] = React.useState(null);

  const [isSaving, setIsSaving] = React.useState(false);

  const selectedUser = DataModelQueries.selectUserById(DataModel, match.params.userId);

  const [newEmail, setNewEmail] = React.useState('');

  React.useEffect(() => {
    const initialize = async() => {
      const userOrganizations = await DataModelAPI.genUserOrganizations(
        authToken,
        perspectiveId,
        match.params.userId,
      );
      setOrganizationOptions(userOrganizations);
      setCurrentOrganization(userOrganizations[0]);

      const currentOrganizationId = userOrganizations[0].id;
      const organizationSubscriptions = await DataModelAPI.genOrganizationSubscriptions(
        authToken,
        perspectiveId,
        currentOrganizationId,
      );
      setSubscriptionOptions(organizationSubscriptions);

      const currentSubscription = await DataModelAPI.genCurrentSubscription(
        authToken,
        perspectiveId,
        currentOrganizationId,
      );
      setCurrentSubscription(currentSubscription);
      setSelectedSubscription(currentSubscription);
      setNewEmail(selectedUser.email);
    };

    initialize();
  }, [DataModelAPI, authToken, match.params.userId, perspectiveId, selectedUser.email]);


  return (
    <div>
      <Typography variant="h4">
        Edit user <strong>{selectedUser.firstName}&nbsp;{selectedUser.lastName}</strong>
      </Typography>

      <br/><br/><br/><br/>

      <Autocomplete
        value={currentOrganization}
        placeholder="Organizations..."
        disableClearable
        onChange={() => {}}
        options={organizationOptions}
        getOptionLabel={option => option.name}
        getOptionSelected={option => option.id === currentOrganization.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="User Organization"
            placeholder="Organizations..."
            margin="normal"
            fullWidth
          />
        )}
        renderOption={(option) => {
          return `${option.name} (${option.type})`;
        }}
      />

      <Autocomplete
        value={selectedSubscription}
        placeholder="Organizations..."
        disableClearable
        onChange={(event, newSubscription) => {
          setSelectedSubscription(newSubscription);
        }}
        options={subscriptionOptions}
        getOptionLabel={option => option.name}
        getOptionSelected={option => option.id === selectedSubscription.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Organization subscription"
            placeholder="Subscriptions..."
            margin="normal"
            fullWidth
          />
        )}
        renderOption={option => option.name}
      />

      <TextField
        label="Username"
        placeholder="Username"
        margin="normal"
        fullWidth
        value={newEmail}
        onChange={(ev) => {
          setNewEmail(ev.target.value);
        }}
      />

      <br/><br/><br/><br/>

      <Button
        variant="contained"
        color="primary"
        disabled={
          !currentOrganization ||
          !currentSubscription ||
          isSaving ||
          currentSubscription === selectedSubscription ||
          !newEmail
        }
        onClick={async() => {
          try {
            setIsSaving(true);
            await DataModelAPI.updateCurrentSubscription(
              authToken,
              perspectiveId,
              currentOrganization.id,
              {
                subscriptionPlanId: selectedSubscription.id,
              },
            );
            setCurrentSubscription(selectedSubscription);
            setIsSaving(false);
          } catch (error) {
            setAPIException(error);
          }
        }}
      >
        Save changes to this user
      </Button>
      &nbsp;
      <Button
        variant="text"
        onClick={() => {
          history.push('/users/list');
        }}>
        go to the list of Users
      </Button>
    </div>
  );
};

export default EditUser;
