import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../../verbs';

export default async function PATCH__admin_notificationPipelines_NOTIFICATIONPIPELINEID(authToken, perspectiveId, params) {
  const {
    notificationPipelineId,
    ...otherParams
  } = params;

  return await PATCH(
    `/admin/notification-pipelines/${notificationPipelineId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...otherParams,
    },
  );
}
