import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import SearchIcon from '@material-ui/icons/SearchRounded';
import Input from '@material-ui/core/Input';
import Button from 'ui-library/components/Button';

const useStyles = makeStyles({
  root: {
    display: theme.searchBar.root.display,
    alignItems: theme.searchBar.root.alignItems,
    height: theme.searchBar.root.height,
    boxShadow: theme.searchBar.boxShadow,
    borderRadius: theme.searchBar.root.borderRadius,
    backgroundColor: theme.searchBar.root.backgroundColor,
    minWidth: theme.searchBar.root.minWidth,
    justifyContent: theme.searchBar.root.justifyContent,
    marginBottom: theme.searchBar.root.marginBottom,
    marginTop: theme.searchBar.root.marginTop,
    transition: theme.searchBar.root.transition,
    '&:hover': {
      boxShadow: theme.searchBar.hoverBoxShadow,
      '& .MuiSvgIcon-root': {
        color: theme.searchBar.adornmentActiveColor,
      },
      '& .MuiButton-root': {
        backgroundColor: theme.searchBar.adornmentActiveColor,
      },
      '& .MuiInputBase-root, & .MuiInputBase-root input' : {
        cursor: theme.searchBar.cursor,
      },
    },
  },
  input: {
    padding: theme.searchBar.input.padding,
    color: theme.searchBar.input.color,
    fontSize: theme.searchBar.input.fontSize,
  },
  focused: {
    backgroundColor: theme.searchBar.inputActiveColor,
    borderRadius: theme.searchBar.borderRadius,
    boxShadow: theme.searchBar.activeBoxShadow,
    // adornment
    '& .MuiSvgIcon-root': {
      color: theme.searchBar.adornmentActiveColor,
    },
    '& .MuiButton-root': {
      backgroundColor: theme.searchBar.adornmentActiveColor,
    },
  },
  icon: {
    color: theme.searchBar.icon.color,
    width: theme.searchBar.icon.width,
    height: theme.searchBar.icon.height,
  },
});

const SearchBar = ({
  searchQuery,
  onChange,
  onSubmitSearchQuery,
  hideSearchButton,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Input
        classes={{
          root: classes.input,
          focused: classes.focused,
        }}
        type="text"
        fullWidth
        disableUnderline
        value={searchQuery}
        placeholder="Type and Search..."
        onChange={(event) => onChange(event.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onSubmitSearchQuery();
          }
        }}
        startAdornment={
          <SearchIcon className={classes.icon}/>
        }
        endAdornment={
          hideSearchButton ?
            null
            :
            <Button
              data-test-id="search-button"
              variant="custom"
              customBgColor="tertiary-light"
              customHoverBgColor="tertiary-light"
              customTextColor="white"
              customActiveBgColor="white"
              onClick={onSubmitSearchQuery}>
              Search
            </Button>
        }
        {...otherProps}
      />
    </div>
  );
};

SearchBar.propTypes = {
  searchQuery: PropTypes.string,
  onChange: PropTypes.func,
  onSubmitSearchQuery: PropTypes.func,
  hideSearchButton: PropTypes.bool,
};

export default SearchBar;
