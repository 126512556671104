import React from 'react';
import Typography from 'ui-library/components/Typography';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const useStyles = makeStyles({
  main: theme.locationWarning.main,
  wrapper: theme.locationWarning.wrapper,
  headerIcon: theme.locationWarning.headerIcon,
  headerText: theme.locationWarning.headerText,
  bodyText: theme.locationWarning.bodyText,
});

const LocationWarning = ({
  warning,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <div className={classes.headerIcon}>
          <WarningRoundedIcon
            fontSize="large"
          />
        </div>
        <div className={classes.headerText}>
          <Typography
            variant="body"
            fontWeight="bold"
          >
            Warning
          </Typography>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.bodyText}>
          <Typography
            variant="body"
          >
            {warning}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LocationWarning;
