const PATTERN = param => new RegExp(`(:${param}[?]?)([/]|$)`);

export default (str, param, value) => {
  return str.replace(
    PATTERN(param),
    (match, captureGroup1, captureGroup2) => {
      return `${value === undefined ? '' : encodeURIComponent(value)}${captureGroup2}`;
    },
  );
};
