import DEL__nodes_ID from 'network-layer/endpoints/DEL__nodes_ID';

function responseAdapter(response) {
  return response;
}

export default async function deleteNode(params) {
  const response = await DEL__nodes_ID(params);

  return responseAdapter(response);
}
