import React from 'react';

import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import Typography from 'ui-library/components/Typography';

const ListItemNameWrapper = React.memo(({
  name,
  typeName,
}) => {
  return (
    <ListItemContentSegment
      weight={2}
      minWidth={200}
    >
      <ListItemText>
        <Typography
          variant="small"
          fontWeight="bold"
        >
          {name}
        </Typography>
      </ListItemText>
      <ListItemText>
        <Typography
          variant="caption"
          color="grey"
          textTransform="capitalize"
        >
          {typeName}
        </Typography>
      </ListItemText>
    </ListItemContentSegment>
  );
}, (prevProps, nextProps) => {
  return true;
});

export default ListItemNameWrapper;
