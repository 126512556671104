function selectUserById(DM, userId) {
  const {
    usersById: {
      [userId]: user,
    },
  } = DM;

  return user;
}

export default selectUserById;
