import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import TextField from 'ui-library/components/TextField';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles({
  attachmentTitleViewMode: {
    flex: theme.nodeAttachmentTitle.attachmentTitle.flex,
    alignItems: theme.nodeAttachmentTitle.attachmentTitle.alignItems,
    fontSize: theme.nodeAttachmentTitle.attachmentTitle.fontSize,
    fontWeight: theme.nodeAttachmentTitle.attachmentTitle.fontWeight,
    paddingLeft: theme.nodeAttachmentTitle.attachmentTitle.paddingLeft,
    textOverflow: theme.nodeAttachmentTitle.attachmentTitle.textOverflow,
    overflow: theme.nodeAttachmentTitle.attachmentTitle.overflow,
    whiteSpace: theme.nodeAttachmentTitle.attachmentTitle.whiteSpace,
  },
  attachmentTitleEditMode: {
    flex: theme.nodeAttachmentTitle.attachmentTitle.flex,
    display: theme.nodeAttachmentTitle.attachmentTitle.display,
    alignItems: theme.nodeAttachmentTitle.attachmentTitle.alignItems,
    fontSize: theme.nodeAttachmentTitle.attachmentTitle.fontSize,
    fontWeight: theme.nodeAttachmentTitle.attachmentTitle.fontWeight,
    position: 'relative',
  },
  input: {
    fontSize: theme.nodeAttachmentTitle.attachmentTitle.inputFontSize,
    padding: theme.nodeAttachmentTitle.attachmentTitle.inputPadding,
  },
  titleActionButtonsContainer: theme.nodeAttachmentTitle.titleActionButtonsContainer,
  titleErrorContainer: theme.nodeAttachmentTitle.titleErrorContainer,
  titleActionButton: theme.nodeAttachmentTitle.titleActionButton,
});

const NodeAttachmentTitle = ({
  id,
  title,
  onTitleChange,
  saveTitle,
  revertTitle,
  isEditable,
  isDownloading,
  isDisabled,
  titleError,
  isTitleSaving,
}) => {

  const handleClickAway = () => {
    if (!titleError) {
      saveTitle(id);
    }
  };

  const classes = useStyles({
    isEditable,
  });

  if (isTitleSaving) {
    return (
      <div className={classes.attachmentTitleViewMode}>
        <Typography
          variant="body"
          fontWeight="bold"
        >
          {`Saving ${title} ...`}
        </Typography>
      </div>
    );
  }

  if (isEditable) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.attachmentTitleEditMode}>
          <TextField
            autoFocus={true}
            value={title}
            fullWidth
            placeholder="Default"
            onChange={(ev) => {
              onTitleChange(id, ev.currentTarget.value);
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                saveTitle(id);
              }
            }}
            InputProps={{
              classes: {
                input: classes.input,
              },
            }}
            error={Boolean(titleError)}
          />
          <div
            className={classes.titleErrorContainer}
          >
            <Typography
              variant="x-small"
              color="error"
              fontWeight="normal"
            >
              {titleError}
            </Typography>
          </div>
          <div
            className={classes.titleActionButtonsContainer}
          >
            {
              !isDisabled &&
              <div
                className={classes.titleActionButton}
              >
                <CloseIcon
                  data-test-id="revert-attachment-title-button"
                  onClick={() => {
                    revertTitle(id);
                  }}
                />
              </div>
            }
            {
              !isDisabled &&
              <div
                className={classes.titleActionButton}
              >
                <CheckIcon
                  data-test-id="save-attachment-title-button"
                  onClick={() => {
                    if (!titleError) {
                      saveTitle(id);
                    }
                  }}
                />
              </div>
            }
          </div>
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <div className={classes.attachmentTitleViewMode}>
      <Typography
        variant="body"
        fontWeight="bold"
      >
        {`${isDownloading ? '[Downloading...]' : ''} ${title}`}
      </Typography>
    </div>
  );
};

NodeAttachmentTitle.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  onTitleChange: PropTypes.func,
  saveTitle: PropTypes.func,
  revertTitle: PropTypes.func,
  isEditable: PropTypes.bool,
  isDownloading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  titleError: PropTypes.string,
  isTitleSaving: PropTypes.bool,
};

export default NodeAttachmentTitle;
