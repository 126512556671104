import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddBox';
import RemoveIcon from '@material-ui/icons/Remove';
import ForbiddenIcon from '@material-ui/icons/Block';
import RecordTypeIcon from '@material-ui/icons/Book';
import TypeIcon from '@material-ui/icons/DonutLarge';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const RecordTypeWithTypes = ({
  recordTypeId,
  recordTypeName,
  types,
  onAddTypeToRecordType,
  onRemoveTypeFromRecordType,
  DataModelQueries,
  DataModel,
}) => {
  return (
    <NestedBox>
      <ContentLine>
        <RecordTypeIcon/>&nbsp;&nbsp;
        <ContentLineText>
          <Typography
            vartiant="body1">
            {recordTypeName}
          </Typography>
        </ContentLineText>
        <Tooltip
          placement="top"
          title={`Associate a type with ${recordTypeName}`}>
          <span>
            <IconButton
              disabled={recordTypeId === 4}
              onClick={() => {
                onAddTypeToRecordType(recordTypeId);
              }}>
              <AddIcon/>
            </IconButton>
          </span>
        </Tooltip>
      </ContentLine>

      {
        types.map(type => {
          const {
            id: typeId,
            name: typeName,
            aliases: typeAliases,
          } = type;

          const recordTypeIds = DataModelQueries.selectRecordTypeIdsOfTypeById(
            DataModel,
            typeId,
          );

          return (
            <NestedBox
              key={typeId}>
              <ContentLine>
                <TypeIcon/>&nbsp;&nbsp;
                <ContentLineText>
                  <Typography
                    vartiant="body1">
                    {typeName}
                    {
                      !!typeAliases.length &&
                      <>
                        &nbsp;&nbsp;
                        <span
                          style={{
                            color: '#aaa',
                          }}>
                          ({typeAliases.join(', ')})
                        </span>
                      </>
                    }
                  </Typography>
                </ContentLineText>
                <Tooltip
                  placement="top"
                  title={
                    recordTypeIds.length === 1 ?
                      `Not allowed! "${typeName}" is not associated with any other record type! Types must be associated with at least one record type.`
                      :
                      `Disassociate "${typeName}" from ${recordTypeName}`
                  }>
                  <span>
                    <IconButton
                      disabled={recordTypeIds.length === 1}
                      onClick={() => {
                        onRemoveTypeFromRecordType(recordTypeId, typeId);
                      }}>
                      {
                        recordTypeIds.length === 1 ?
                          <ForbiddenIcon/>
                          :
                          <RemoveIcon/>
                      }
                    </IconButton>
                  </span>
                </Tooltip>
              </ContentLine>

            </NestedBox>
          );
        })
      }
    </NestedBox>
  );
};

export default RecordTypeWithTypes;
