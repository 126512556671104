import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {POST} from '../verbs';

export default async function validateToken(authToken, perspectiveId, params) {
  return await POST(
    '/connections',
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
