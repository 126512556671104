import React from 'react';
import PropTypes from 'prop-types';

const ListItemContentSegment = ({
  children,
  width,
  weight = 1,
  minWidth = 30,
  justifyContent = 'flex-start',
  padding = '0 8px',
  onClick,
}) => {

  return (
    <div style={{
      display: 'flex',
      justifyContent: justifyContent,
      alignItems: 'center',
      flexWrap: 'wrap',
      width: width,
      flex: width ? null : weight,
      minWidth: minWidth,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding,
    }} onClick={onClick}>
      {children}
    </div>
  );
};

ListItemContentSegment.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number,
  weight: PropTypes.number,
  minWidth: PropTypes.number,
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end']),
};

export default ListItemContentSegment;
