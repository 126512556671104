import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import PermissionSwitches from 'components/Permissions/PermissionSwitches';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import recordFrontendPermissionLabel from 'utils/permissions/recordFrontendPermissionLabel';
import recordFrontendPermissionsToAtomicPermissions from 'utils/permissions/recordFrontendPermissionsToAtomicPermissions';
import recordAtomicPermissionsToFrontendPermissions from 'utils/permissions/recordAtomicPermissionsToFrontendPermissions';
import recordFrontendPermissionsToImpliedFrontendPermissions from 'utils/permissions/recordFrontendPermissionsToImpliedFrontendPermissions';
import {
  RECORD_FRONTEND_DISABLED_PERMISSIONS,
} from 'utils/permissions/PERMISSION_FLAGS';

const PermissionsContainer = styled.div`
  margin-top: 24px;
`;

const DisclaimerContainer = styled.div`
  margin-bottom: 10px;
`;

const ShareRecordPermissionsStep = ({
  active,
  connection,
  permissions,
  givenPermissions,
  onSetSharedPermissions,
  navigateToStep,
}) => {
  const [sharedFrontendPermissions, setSharedFrontendPermissions] = React.useState(permissions);
  const [sharedAtomicPermissions, setSharedAtomicPermisisons] = React.useState(recordFrontendPermissionsToAtomicPermissions(permissions));

  if (!active) {
    return null;
  }

  if (!connection) {
    return null;
  }

  if (!sharedFrontendPermissions) {
    return null;
  }

  const {
    peerName: partnerName,
  } = connection;

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Share Record
          </WizardBreadcrumb>
          <WizardBreadcrumb onClick={() => navigateToStep(1)}>
            {partnerName}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Permissions
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Choose the level of control this connection has of the selected items.
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (!(Object.values(sharedFrontendPermissions).includes(true))) {
            return;
          }
          onSetSharedPermissions(sharedFrontendPermissions);
          navigateToStep(3);
        }}>
          <WizardStepContent>
            <Typography variant="lead">
              What can {partnerName} do with these items?
            </Typography>
            <PermissionsContainer>
              <PermissionSwitches
                permissions={recordAtomicPermissionsToFrontendPermissions(sharedAtomicPermissions)}
                getPermissionReadableFormat={recordFrontendPermissionLabel}
                editable={givenPermissions}
                disabledPermissions={RECORD_FRONTEND_DISABLED_PERMISSIONS}
                onTogglePermission={permission => {
                  const newSharedFrontendPermissions = {
                    ...sharedFrontendPermissions,
                    [permission]: !sharedFrontendPermissions[permission],
                  };
                  setSharedFrontendPermissions(recordFrontendPermissionsToImpliedFrontendPermissions(newSharedFrontendPermissions));

                  const newSharedAtomicPermissions = recordFrontendPermissionsToAtomicPermissions(newSharedFrontendPermissions);
                  setSharedAtomicPermisisons(newSharedAtomicPermissions);
                }}
              />
            </PermissionsContainer>
          </WizardStepContent>
          <WizardStepActions>
            <DisclaimerContainer>
              <Typography variant="caption">
                If this Connection needs to share access to these items with another party, then "Allow 3rd Party Access" should be enabled.
              </Typography>
            </DisclaimerContainer>
            <WizardSubmitWithEnterButton
              autoFocus={true}
              data-test-id="add-item-type-step-continue-button"
              variant="primary"
              disabled={!(Object.values(sharedFrontendPermissions).includes(true))}>
              Continue &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ShareRecordPermissionsStep.propTypes = {
  active: PropTypes.bool,
  onSetPermissions: PropTypes.func,
};

export default ShareRecordPermissionsStep;
