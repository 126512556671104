import styled from '@emotion/styled';
import {
  appBarHeight,
  navigationWidth,
  navigationClearance,
  gutter,
} from 'constants/layout';
import {
  greyLighter,
} from 'ui-library/color-palette';

const AppBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: ${appBarHeight}px;
  padding-left: ${navigationWidth + 2 * navigationClearance + gutter * 2}px;
  padding-right: 16px;
  background-color: ${greyLighter};
  z-index: 2;
`;

export default AppBarContainer;
