import React from 'react';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import TextField from 'ui-library/components/TextField';
// import Message from 'ui-library/components/Message';
import {notEmpty, email as isEmail} from 'utils/validator';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import CircularProgress from '@material-ui/core/CircularProgress';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const RightPanelRowWithValidationGutter = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
  height: 80px;
`;

const HelperTextContainer = styled.div`
  padding: 8px 0 0 10px;
`;

const EmailComponent = ({
  accountType,
  email,
  setEmail,
  step,
  isTransitioning,
  checkEmail,
  emailAvailability,
  emailAvailabilityMessage,
  isLoading,
}) => {

  const [isPristine, setIsPristine] = React.useState(true);

  return (
    <>
      <RightPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          {accountType === 'personal' ? 'Your' : 'And your'} <strong>{accountType === 'personal' ? 'email' : 'work email'}...</strong>
        </Typography>
      </RightPanelRow>
      <RightPanelRowWithValidationGutter>
        <TextField
          value={email}
          disabled={isLoading}
          onChange={(event) => {
            setIsPristine(false);
            setEmail(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.which === 13 && !notEmpty(email) && !isEmail(email) && !isTransitioning) {
              checkEmail();
            }
          }}
          fullWidth
          placeholder="Type your answer here..."
          autoFocus={true}
          error={!isPristine && (!!notEmpty(email) || !!isEmail(email) || !emailAvailability)}
          helperText={!isPristine && (notEmpty(email) || isEmail(email) || emailAvailabilityMessage)}
        />
      </RightPanelRowWithValidationGutter>
      <br/>
      <RightPanelRow>
        <Button
          data-test-id="email-continue-btn"
          variant="primary"
          endIcon={
            !isLoading ?
              <ArrowForwardIcon />
              :
              <CircularProgress
                size={20}
                style={{
                  color: '#fff',
                }}
              />
          }
          disabled={!!notEmpty(email) || !!isEmail(email) || isTransitioning || isLoading}
          onClick={() => {
            checkEmail();
          }}
        >
            CONTINUE
        </Button>
        <HelperTextContainer>
          <Typography
            variant="tiny"
          >
              press <strong>ENTER</strong>
          </Typography>
        </HelperTextContainer>
      </RightPanelRow>
    </>
  );
};

export default EmailComponent;
