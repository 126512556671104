export const INTERNAL_SEARCH_CONTEXT = 'internal';
export const EXTERNAL_SEARCH_CONTEXT = 'external';
export const DEFAULT_SEARCH_CONTEXT = 'all';

export const AVAILABLE_SEARCH_CONTEXTS = [
  INTERNAL_SEARCH_CONTEXT,
  EXTERNAL_SEARCH_CONTEXT,
  DEFAULT_SEARCH_CONTEXT,
];

export const RECORD_SEARCH_CRITERION = {
  label: 'Record',
  operator: 'is',
  component: 'autocomplete',
  filter: 'recordId',
  name: 'record',
};
export const TYPE_SEARCH_CRITERION = {
  label: 'Type',
  operator: 'is',
  component: 'autocomplete',
  filter: 'typeId',
  name: 'type',
};
export const CATEGORY_SEARCH_CRITERION = {
  label: 'Category',
  operator: 'contains',
  component: 'drill-down',
  filter: 'categoryId',
  name: 'category',
};
export const TAG_SEARCH_CRITERION = {
  label: 'Tag',
  operator: 'is',
  component: 'autocomplete',
  filter: 'nodeTagId',
  name: 'tag',
};
export const CONNECTION_SEARCH_CRITERION = {
  label: 'Connection',
  operator: 'is',
  component: 'autocomplete',
  filter: 'connectionId',
  name: 'connection',
};

export const AVAILABLE_SEARCH_FILTERS = [
  RECORD_SEARCH_CRITERION,
  TYPE_SEARCH_CRITERION,
  CATEGORY_SEARCH_CRITERION,
  TAG_SEARCH_CRITERION,
  CONNECTION_SEARCH_CRITERION,
];
