import React from 'react';
import './style.css';

const CenterpieceSpinner = () => {
  return (
    <div className="centerpiece-spinner">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

export default CenterpieceSpinner;
