import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Grid from '@material-ui/core/Grid';

import Typography from 'ui-library/components/Typography';
import NodeLinksList from 'ui-library/components/NodeLinksList';

import genNodeLinks from 'services/Links/genNodeLinks';
import genNodeLinkCandidates from 'services/Links/genNodeLinkCandidates';
import deleteNodeLink from 'services/Links/deleteNodeLink';

import UserContext from 'contexts/User';
import UserInterfaceContext from 'contexts/UserInterface';
import PerspectiveContext from 'contexts/Perspective';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const NoAccessContainer = styled.div`
  padding: 1px 35px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const NodeLinks = ({
  nodeState,
  dispatchNodeAction,
  setError,
  handleLinkInfo,
}) => {
  const {
    node: {
      id: nodeId,
    },
    nodePermissions,
    nodeLinks,
    linksAreLoading,
  } = nodeState;

  const {
    frontendPermissions: {
      manageLinks,
    },
  } = nodePermissions;

  const {
    authToken,
  } =  React.useContext(UserContext);

  const {
    dispatchUserInterfaceAction,
  } =  React.useContext(UserInterfaceContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const [tabState, setTabState] = React.useState('not loaded'); // 'not loaded', 'loading', 'loaded'



  React.useEffect(() => {
    const loadNodeLinks = async() => {
      try {
        setTabState('loading');
        const response = await genNodeLinks(authToken, currentPerspectiveId, nodeId, ['definition', 'nodes']);
        const links = response.map(link => {
          return {
            ...link,
            isLinkDeleting: false,
          };
        });
        dispatchNodeAction({
          type: 'SET_NODE_LINKS',
          payload: links,
        });
        dispatchNodeAction({
          type: 'SET_NODE_LINKS_ARE_LOADED',
          payload: true,
        });
        setTabState('loaded');
      } catch(error) {
        setError(error);
      }
    };

    if (tabState !== 'loaded' && manageLinks) {
      loadNodeLinks();
    }

  }, [authToken, currentPerspectiveId, dispatchNodeAction, manageLinks, nodeId, setError, tabState]);


  const handleAddNewLink = async() => {
    try {
      dispatchNodeAction({
        type: 'SET_IS_LINK_CANDIDATES_LIST_LOADING',
        payload: true,
      });
      dispatchUserInterfaceAction({
        type: 'SET_NODE_PANEL_DISPLAY_MODE',
        payload: 'addNewLink',
      });
      const response = await genNodeLinkCandidates(authToken, currentPerspectiveId, nodeId);
      response.forEach((candidate) => {
        candidate.linked = false;
        candidate.isVisible = true;
        candidate.operationInProgress = false;
      });
      dispatchNodeAction({
        type: 'SET_NODE_LINK_CANDIDATES',
        payload: response,
      });
      dispatchNodeAction({
        type: 'SET_IS_LINK_CANDIDATES_LIST_LOADING',
        payload: false,
      });
    } catch(error) {
      setError(error);
    }
  };

  const handleRemoveLink = async(link) => {
    let excludedLinkIndex;
    nodeLinks.forEach((nodeLink, index) => {
      if (link.id === nodeLink.id) {
        excludedLinkIndex = index;
      }
    });

    try {
      dispatchNodeAction({
        type: 'SET_IS_LINK_DELETING',
        payload: {
          value: true,
          index: excludedLinkIndex,
        },
      });
      await deleteNodeLink(authToken, currentPerspectiveId, link.id);
      dispatchNodeAction({
        type: 'REMOVE_LINK',
        payload: excludedLinkIndex,
      });
    } catch(error) {
      setError(error);
    }
  };

  if (!manageLinks) {
    return (
      <NoAccessContainer>
        <Typography
          variant="h5"
          color="grey"
          fontWeight="bold"
        >
            You don’t have permission to view links for this item. Please contact the relevant Connection to update your permissions if necessary.
        </Typography>
      </NoAccessContainer>
    );
  }

  return (
    <PaddedContainer>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            cursor: 'pointer',
            height: '100%',
            flexGrow: 1,
          }}
        >
          <NodeLinksList
            selectedNodeId={nodeId}
            nodeLinks={nodeLinks}
            isLinksListLoading={linksAreLoading}
            handleAddNewLink={handleAddNewLink}
            handleRemoveLink={handleRemoveLink}
            handleLinkInfo={handleLinkInfo}
          />
        </Grid>
      </Grid>

    </PaddedContainer>
  );
};

NodeLinks.propTypes = {
  selectedNodeId: PropTypes.number,
};

export default NodeLinks;
