import PUT__nodes_ID_permissions from 'network-layer/endpoints/PUT__nodes_ID_permissions';

function responseAdapter(response) {
  return response;
}

export default async function updateNodePermissions(authToken, perspectiveId, nodeId, params) {
  const response = await PUT__nodes_ID_permissions(authToken, perspectiveId, nodeId, params);

  return responseAdapter(response);
}
