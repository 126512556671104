import React from 'react';
import styled from '@emotion/styled';
import Button from 'ui-library/components/Button';
import DeleteConversationDialog from 'components/DeleteConversationDialog';
import ListItem from 'ui-library/components/ListItem';
import ListItemActions from 'ui-library/components/ListItemActions';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import Message from 'ui-library/components/Message';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import PanelIcon from '@material-ui/icons/VerticalSplitRounded';
import Typography from 'ui-library/components/Typography';

import PerspectiveContext from 'contexts/Perspective';
import UserContext from 'contexts/User';
import UserInterfaceContext from 'contexts/UserInterface';

import deleteConversationService from 'services/Conversations/deleteConversation';

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;
const ListContainer = styled.div`
  margin-top: 24px;
`;

const ConversationList = ({
  conversations,
  isEmpty,
  onDelete,
}) => {
  const {
    authToken,
  } = React.useContext(UserContext);
  const {
    currentPerspective: {
      id: perspectiveId,
    },
  } = React.useContext(PerspectiveContext);
  const {
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);
  const [contextualMenuState, setContextualMenuState] = React.useState({
    id: null,
    anchorEl: null,
  });
  const [deleteConversation, setDeleteConversation] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState(null);

  if (conversations === null) {
    return (
      <ListSkeleton/>
    );
  }

  if (isEmpty) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
          All your conversations are currently active. You can find an active conversation on the Item, Record, or Connection where the conversation was initiated.
        </Typography>
      </EmptyList>
    );
  }

  if (conversations.length === 0) {
    return <EmptyList />;
  }

  return (
    <ListContainer>
      {conversations.map((conversation, index) => (
        <ListItem
          key={index}
          onClick={() => {}}>
          <ListItemContentSegment>
            <ListItemText>
              <div>
                <Typography
                  variant="small"
                  fontWeight="bold"
                >
                  {conversation.connectionName}
                </Typography>
              </div>
              <Typography variant="caption" color="grey">
                {conversation.interlocutorOrganizationName}
              </Typography>
            </ListItemText>
          </ListItemContentSegment>
          <ListItemContentSegment>
            <ListItemText>
              <Typography variant="small">
                {conversation.nodeName || conversation.recordName || conversation.connectionName}
              </Typography>
            </ListItemText>
          </ListItemContentSegment>
          <ListItemContentSegment
            justifyContent="flex-end"
          >
            <ListItemActions>
              <Button
                data-test-id="conversation-context-menu-button"
                variant="icon-custom"
                textTransform="none"
                customBgColor="transparent"
                customHoverBgColor="primary-lightest"
                customTextColor="grey"
                customHoverTextColor="primary-dark"
                customActiveBgColor="white"
                customActiveTextColor="primary-darker"
                onClick={event => {
                  event.stopPropagation();
                  setContextualMenuState({
                    id: conversation.id,
                    anchorEl: event.currentTarget,
                  });
                }}
              >
                <MoreHorizIcon/>
              </Button>
              <Button
                data-test-id="conversation-open-connection-panel-button"
                textTransform="none"
                variant="icon-custom"
                customBgColor="transparent"
                customHoverBgColor="primary-lightest"
                customTextColor="grey"
                customHoverTextColor="primary-dark"
                customActiveBgColor="white"
                customActiveTextColor="primary-darker"
                onClick={event => {
                  event.stopPropagation();
                  dispatchUserInterfaceAction({
                    type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                    payload: conversation,
                  });
                }}>
                <PanelIcon/>
              </Button>
            </ListItemActions>
          </ListItemContentSegment>
          <Menu
            open={contextualMenuState.id === conversation.id}
            anchorEl={contextualMenuState.anchorEl}
            onClose={() => {
              setContextualMenuState({
                id: null,
                anchorEl: null,
              });
            }}>
            <MenuItem
              data-test-id="conversation-context-menu-delete-button"
              onClick={event => {
                event.stopPropagation();
                setContextualMenuState({
                  id: null,
                  anchorEl: null,
                });
                setDeleteConversation(conversation);
              }}
            >
              <Typography variant="small" color="error">
                Delete Forever
              </Typography>
            </MenuItem>
          </Menu>
        </ListItem>
      ))}
      <DeleteConversationDialog
        deleteConversation={deleteConversation}
        setDeleteConversation={setDeleteConversation}
        isDeleting={isDeleting}
        onDelete={async() => {
          setIsDeleting(true);
          try {
            await deleteConversationService({
              authToken,
              perspectiveId,
              conversationId: deleteConversation.id,
            });
            onDelete();
            setDeleteMessage({
              variant: 'success',
              messageBody: 'Successfully deleted frozen conversation.',
            });
          } catch (e) {
            setDeleteMessage({
              variant: 'error',
              messageBody: 'Something went wrong. Please try again.',
            });
          }
          setDeleteConversation(null);
          setIsDeleting(false);
        }}
      />
      {deleteMessage && (
        <Message
          variant={deleteMessage.variant}
          open={true}
          onClose={() => setDeleteMessage(null)}
          messageBody={deleteMessage.messageBody}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          width={532}
        />
      )}
    </ListContainer>
  );
};

export default ConversationList;
