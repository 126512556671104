import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ContextualPanelShield from 'components/chrome/ContextualPanelShield';
import {
  greyLightest,
} from 'ui-library/color-palette';

const Root = styled.div`
  position: fixed;
  top: 0;
  right: ${props => props.expanded ? 0 : '-100vw'};
  width: 85vw;
  height: 100vh;
  overflow-y: auto;
  background-color: ${greyLightest};
  box-shadow: 0 0 10px hsla(0, 0%, 0%, .4);
  transition: right .3s ease;
  z-index: 102;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media (min-width: 1200px) {
    width: 75vw;
  }
  @media (min-width: 1600px) {
    width: 60vw;
  }
  @media (min-width: 1920px) {
    width: 50vw;
  }
`;

const ContextualPanel = ({
  expanded,
  children,
  onMouseDown,
  handleEscKey,
}) => {
  React.useEffect(() => {
    function handleKeyUp(event) {
      if (event.key === 'Escape') {
        handleEscKey();
      }
    }

    if (expanded) {
      document.body.addEventListener('keydown', handleKeyUp);
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.body.removeEventListener('keydown', handleKeyUp);
      document.documentElement.style.overflowY = 'auto';
    }

    return () => document.body.removeEventListener('keydown', handleKeyUp);
  }, [expanded, handleEscKey]);

  return (
    <div
      onMouseDown={onMouseDown}
    >
      <ContextualPanelShield
        onClick={handleEscKey}
        open={expanded}
        transitionDuration={300}>
      </ContextualPanelShield>
      <Root
        expanded={expanded}>
        {children}
      </Root>
    </div>
  );
};

ContextualPanel.propTypes = {
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onMouseDown: PropTypes.func,
  handleEscKey: PropTypes.func,
};

export default ContextualPanel;
