import React from 'react';
import PropTypes from 'prop-types';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';

const PaymentDialog = ({
  price,
  isVisible,
  isProcessing,
  handleClose,
  handleSubmit,
  errorMessage,
}) => {

  return (
    <ModalCard
      open={isVisible}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <ModalCardHeader
        color="primary"
      >
        <ModalCardHeaderIcon
          color="primary"
        >
          <CreditCardRoundedIcon
            fontSize="inherit"
          />
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle
        >
          One-Off Payment: ${price}
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        <Typography
          variant="body"
        >
          Pay <strong>${price}</strong> to restore full access to your Jules account and extend your membership by 1 year.
        </Typography>
        {
          errorMessage &&
          <div>
            <br/>
            <br/>
            <Typography
              variant="body"
              color="error"
            >
              {errorMessage}
            </Typography>
          </div>
        }
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="modal-one-payment-cancel-button"
          variant="text"
          onClick={handleClose}
          disabled={isProcessing}
        >
          Cancel
        </Button>
        <Button
          data-test-id="modal-one-payment-submit-button"
          disabled={isProcessing}
          onClick={handleSubmit}
          startIcon={isProcessing ? <CircularProgress size={24} color="white"/> : <CheckCircleOutlineRoundedIcon />}
        >
          Pay Now
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

PaymentDialog.propTypes = {
  price: PropTypes.number,
  isVisible: PropTypes.bool,
  isProcessing: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default PaymentDialog;
