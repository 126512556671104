import deriveVirtualLocationTreeNodeLocationDepth from './deriveVirtualLocationTreeNodeLocationDepth';

function selectVirtualLocationTreeNodesByLocationDepth(configurationState, locationDepth) {
  const {
    virtualLocationTreeNodesById,
    virtualLocationTreeNodeIds,
  } = configurationState;

  return (
    virtualLocationTreeNodeIds
      .map(id => virtualLocationTreeNodesById[id])
      .filter(virtualLocationTreeNode => locationDepth === deriveVirtualLocationTreeNodeLocationDepth(configurationState, virtualLocationTreeNode))
  );
}

export default selectVirtualLocationTreeNodesByLocationDepth;
