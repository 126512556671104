const ERROR_CODES = {
  1000: 'E-mail is already in use!',
  1001: 'Invalid parameter',
  1002: 'Invalid/Expired Token',
  1003: 'This email has already been validated.',
  1004: 'User email is not validated.',
  1014: 'Missing edit permissions',
  1035: 'Max requests reached!',
  1069: 'The Connection Code is invalid.',
  1070: 'You cannot create a connection with yourself.',
  2000: 'Invalid email/password combination',
  3001: 'Item does not exist.',
};

export default function internalCodeErrorMessage(internalErrorCode) {
  const errorMessage = ERROR_CODES[internalErrorCode];

  if (errorMessage) {
    return errorMessage;
  }

  throw new Error(`Unknonwn internal error code "${internalErrorCode}!"`);
}
