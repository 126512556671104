import endpointURL from '../util/endpointURL';

export default async function files(authToken, perspectiveId, downloadRequest, params) {
  const {
    fileName,
  } = params;

  downloadRequest.open('GET', endpointURL(`/files/${fileName}`));
  downloadRequest.responseType = 'blob';
  downloadRequest.setRequestHeader('Authorization', authToken);
  downloadRequest.setRequestHeader('Perspective', perspectiveId);

  return await downloadRequest.send();
}
