import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import InputAdornment from '@material-ui/core/InputAdornment';

function areAliasesTheSame(newAliases, oldAliases) {
  const newOnes = newAliases.filter(alias => !!alias);
  const oldOnes = oldAliases.filter(alias => !!alias);

  if (oldOnes.length !== newOnes.length) {
    return false;
  }
  for (let i = 0; i < oldOnes.length; i++) {
    if (oldOnes[i] !== newOnes[i]) {
      return false;
    }
  }
  return true;
}

const Edit = ({
  history,
  match,
}) => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);

  const currentTypeRef = React.useRef(DataModelQueries.selectTypeById(DataModel, match.params.typeId));

  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    currentTypeRef.current,
    initState,
  );

  if (!currentTypeRef.current) {
    return (
      <div>
        <Typography
          variant="h4">
          Error: Type with id {match.params.typeId} does not exist!
        </Typography>
      </div>
    );
  }

  const currentType = currentTypeRef.current;

  return (
    <div>
      <Typography
        variant="h4">
        Edit <strong>"{currentType.name}"</strong> Type
      </Typography>
      <br/><br/>

      <Typography
        variant="h6">
        Status flags
      </Typography>
      <Typography
        variant="body1">
        {
          currentType.isPublic ?
            'This Type is currently public, meaning that as long as you\'ve assigned it to a record it is accessible to users for instantiation.'
            :
            'Be sure you\'re satisfied with the Type details and Field Definitions before making this Type public. If users start instantiating this Type you may not be able to modify some of its Field Definitions.'
        }
      </Typography>
      <Checkbox
        color="primary"
        checked={localState.isEnabled}
        onChange={() => {
          dispatchLocalAction({
            type: 'SET_ENABLED_FLAG',
            payload: !localState.isEnabled,
          });
        }}
      />
      <span
        style={{
          color: currentType.isEnabled === localState.isEnabled ? '#222' : 'blue',
        }}>
        enabled
      </span>
      &nbsp;&nbsp;
      <Checkbox
        color="primary"
        checked={localState.isPublic}
        onChange={() => {
          dispatchLocalAction({
            type: 'SET_PUBLIC_FLAG',
            payload: !localState.isPublic,
          });
        }}
      />
      <span
        style={{
          color: currentType.isPublic === localState.isPublic ? '#222' : 'blue',
        }}>
        public
      </span>
      <br/><br/><br/>


      <span
        style={{
          color: currentType.name === localState.name ? '#222' : 'blue',
        }}>
        <Typography
          variant="h6">
          Name
        </Typography>
      </span>
      <TextField
        autoFocus
        fullWidth
        disabled={
          localState.status === 'pending save'
        }
        helperText="mandatory"
        value={localState.name}
        onChange={event => {
          if (event.target.value.length > 64) {
            return;
          }
          dispatchLocalAction({
            type: 'SET_NAME',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      <span
        style={{
          color: currentType.pluralName === localState.pluralName ? '#222' : 'blue',
        }}>
        <Typography
          variant="h6">
          Plural name
        </Typography>
      </span>
      <TextField
        fullWidth
        disabled={
          localState.status === 'pending save'
        }
        helperText="mandatory"
        value={localState.pluralName}
        onChange={event => {
          if (event.target.value.length > 64) {
            return;
          }
          dispatchLocalAction({
            type: 'SET_PLURAL_NAME',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      <span
        style={{
          color: currentType.description === localState.description ? '#222' : 'blue',
        }}>
        <Typography
          variant="h6">
          Description
        </Typography>
      </span>
      <TextField
        fullWidth
        disabled={
          localState.status === 'pending save'
        }
        helperText="mandatory"
        value={localState.description}
        onChange={event => {
          if (event.target.value.length > 255) {
            return;
          }
          dispatchLocalAction({
            type: 'SET_DESCRIPTION',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      <span
        style={{
          color: areAliasesTheSame(localState.aliases, currentType.aliases) ? '#222' : 'blue',
        }}>
        <Typography
          variant="h6">
          Aliases (optional)
        </Typography>
      </span>
      {
        localState.aliases
          .map((alias, index) => {
            return (
              <React.Fragment
                key={index}>
                <TextField
                  fullWidth
                  disabled={
                    localState.status === 'pending save'
                  }
                  InputProps={{
                    startAdornment:
                      <InputAdornment
                        position="start">
                        <IconButton
                          onClick={() => {
                            dispatchLocalAction({
                              type: 'SPLICE_ALIAS',
                              payload: index,
                            });
                          }}>
                          <RemoveIcon/>
                        </IconButton>
                      </InputAdornment>,
                  }}
                  value={alias}
                  onChange={event => {
                    if (event.target.value.length > 64) {
                      return;
                    }
                    dispatchLocalAction({
                      type: 'SET_ALIAS',
                      payload: {
                        value: event.target.value,
                        index,
                      },
                    });
                  }}
                />
                <br/><br/>
              </React.Fragment>
            );
          })
      }
      <Tooltip
        placement="top"
        title="add an alias">
        <span>
          <IconButton
            disabled={
              !!localState.aliases.length &&
              !localState.aliases[localState.aliases.length - 1].length
            }
            onClick={() => {
              dispatchLocalAction({
                type: 'PUSH_BLANK_ALIAS',
              });
            }}>
            <AddIcon/>
          </IconButton>
        </span>
      </Tooltip>
      <br/><br/><br/>

      <Typography
        variant="h6">
        Field Definitions
      </Typography>
      <br/>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          history.push(`${match.url}/field-definitions`);
        }}>
        customize field definitions
      </Button>
      <br/><br/><br/><br/>

      <Button
        variant="contained"
        color="primary"
        disabled={
          localState.status === 'pending save' ||
          !localState.name ||
          !localState.pluralName ||
          !localState.description ||
          (
            localState.isEnabled === currentType.isEnabled &&
            localState.isPublic === currentType.isPublic &&
            localState.name === currentType.name &&
            localState.pluralName === currentType.pluralName &&
            localState.description === currentType.description &&
            areAliasesTheSame(localState.aliases, currentType.aliases)
          )
        }
        onClick={async() => {
          const typePatch = {};

          if (currentType.isEnabled !== localState.isEnabled) {
            typePatch.isEnabled = localState.isEnabled;
          }
          if (currentType.isPublic !== localState.isPublic) {
            typePatch.isPublic = localState.isPublic;
          }
          if (currentType.name !== localState.name) {
            typePatch.name = localState.name;
          }
          if (currentType.pluralName !== localState.pluralName) {
            typePatch.pluralName = localState.pluralName;
          }
          if (currentType.description !== localState.description) {
            typePatch.description = localState.description;
          }
          if (!areAliasesTheSame(localState.aliases, currentType.aliases)) {
            typePatch.aliases = localState.aliases;
          }

          dispatchLocalAction({
            type: 'REQUEST_SAVE',
          });
          try {
            await DataModelAPI.updateType(
              authToken,
              perspectiveId,
              {
                typeId: currentType.id,
                ...typePatch,
              },
            );
            currentTypeRef.current = {
              ...currentTypeRef.current,
              ...typePatch,
            };
            dispatchDataModelAction({
              type: 'UPDATE_TYPE',
              payload: {
                typeId: currentType.id,
                ...typePatch,
              },
            });
            dispatchLocalAction({
              type: 'END_SAVE',
            });
          } catch(error) {
            dispatchLocalAction({
              type: 'END_SAVE',
            });
            setAPIException(error);
          }
        }}>
        save changes to this type
      </Button>
      &nbsp;
      <Button
        variant="text"
        disabled={
          localState.isEnabled === currentType.isEnabled &&
          localState.isPublic === currentType.isPublic &&
          localState.name === currentType.name &&
          localState.pluralName === currentType.pluralName &&
          localState.description === currentType.description &&
          areAliasesTheSame(localState.aliases, currentType.aliases)
        }
        onClick={() => {
          dispatchLocalAction({
            type: 'REVERT_CHANGES',
            payload: currentType,
          });
        }}>
        revert changes
      </Button>
      &nbsp;
      <Button
        variant="text"
        onClick={() => {
          history.push('/types/list');
        }}>
        go to the list of Types
      </Button>
    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'SET_NAME': {
      return {
        ...state,
        name: payload,
      };
    }

    case 'SET_PLURAL_NAME': {
      return {
        ...state,
        pluralName: payload,
      };
    }

    case 'SET_DESCRIPTION': {
      return {
        ...state,
        description: payload,
      };
    }

    case 'PUSH_BLANK_ALIAS': {
      return {
        ...state,
        aliases: [
          ...state.aliases,
          '',
        ],
      };
    }

    case 'SPLICE_ALIAS': {
      const spliceIndex = payload;

      return {
        ...state,
        aliases: [
          ...state.aliases.slice(0, spliceIndex),
          ...state.aliases.slice(spliceIndex + 1),
        ],
      };
    }

    case 'SET_ALIAS': {
      const {
        value,
        index,
      } = payload;

      return {
        ...state,
        aliases: [
          ...state.aliases.slice(0, index),
          value,
          ...state.aliases.slice(index + 1),
        ],
      };
    }

    case 'SET_ENABLED_FLAG': {
      const isEnabled = payload;

      return {
        ...state,
        isEnabled,
        isPublic: !isEnabled ? false : state.isPublic,
      };
    }

    case 'SET_PUBLIC_FLAG': {
      const isPublic = payload;

      return {
        ...state,
        isEnabled: isPublic ? true : state.isEnabled,
        isPublic,
      };
    }

    case 'REQUEST_SAVE': {
      return {
        ...state,
        status: 'pending save',
      };
    }

    case 'END_SAVE': {
      return {
        ...state,
        status: 'idle',
      };
    }

    case 'REVERT_CHANGES': {
      return {
        ...state,
        ...payload,
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState(currentType) {
  if (!currentType) {
    return;
  }

  return {
    /*
      idle
      pending save
    */
    status: 'idle',
    ...currentType,
  };
}


export default Edit;
