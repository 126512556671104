class ParseError extends Error {
  constructor(errorBody) {
    super('Parse error!');

    this.name = 'ParseError';
    this.body = errorBody;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ParseError);
    }
  }
}

export default ParseError;
