import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ContextualPanel from 'components/chrome/ContextualPanel';
import ContextualPanelNavigation from 'components/chrome/ContextualPanelNavigation';
import ContextualPanelBackButton from 'components/chrome/ContextualPanelBackButton';
import ContextualPanelCloseButton from 'components/chrome/ContextualPanelCloseButton';
import ContextualPanelHeader from 'components/chrome/ContextualPanelHeader';
import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import ContextualPanelTabs from 'components/chrome/ContextualPanelTabs';
import ContextualPanelTab from 'components/chrome/ContextualPanelTab';
import ContextualPanelActions from 'components/chrome/ContextualPanelActions';
import PermissionGrant from 'components/Permissions/PermissionGrant';
import PermissionGrantInformation from 'components/PermissionGrantInformation';
import PermissionSwitches from 'components/Permissions/PermissionSwitches';
import StopSharingRecordDialog from 'components/StopSharingRecordDialog';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import RecordConversationsTab from 'components/RecordConversationsTab';
import ConversationContainer from 'components/ConversationContainer';
import StartConversation from 'components/StartConversation';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import Grid from '@material-ui/core/Grid';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';

import genRecordsPermissionGrants from 'services/Records/genRecordsPermissionGrants';
import genNodeCountByRecordId from 'services/Nodes/genNodeCountByRecordId';
import genRecordByRecordId from 'services/Records/genRecordByRecordId';
import updateRecordPermissions from 'services/Records/updateRecordPermissions';
import genAvailableConnectionsByRecordId from 'services/Conversations/genAvailableConnectionsByRecordId';
import createRecordConversation from 'services/Conversations/createRecordConversation';
import updateConversationStatus from 'services/Conversations/updateConversationStatus';
import genConversationByConversationId from 'services/Conversations/genConversationByConversationId';

import UserInterfaceContext from 'contexts/UserInterface';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import parseRecordPermissions from 'utils/permissions/parseRecordPermissions';
import recordAtomicPermissionLabel from 'utils/permissions/recordAtomicPermissionLabel';
import recordFrontendPermissionLabel from 'utils/permissions/recordFrontendPermissionLabel';
import recordAtomicPermissionsToPermissionsInt from 'utils/permissions/recordAtomicPermissionsToPermissionsInt';
import recordFrontendPermissionsToAtomicPermissions from 'utils/permissions/recordFrontendPermissionsToAtomicPermissions';
import recordAtomicPermissionsToFrontendPermissions from 'utils/permissions/recordAtomicPermissionsToFrontendPermissions';
import recordFrontendPermissionsToImpliedFrontendPermissions from 'utils/permissions/recordFrontendPermissionsToImpliedFrontendPermissions';
import {
  RECORD_FRONTEND_DISABLED_PERMISSIONS,
} from 'utils/permissions/PERMISSION_FLAGS';

import {
  greySemi,
} from 'ui-library/color-palette';

const ItemCountContainer = styled.div`
  width: 100%;
  padding: 16px 35px;
  border-bottom: 1px solid ${greySemi};
  display: flex;
`;

const CounterDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px;
`;

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const PermissionsContainer = styled.div`
  margin-top: 24px;
  padding-left: 20px;
`;

const SecondButtonContainer = styled.div`
  margin-left: 8px;
`;

const PermissionGrantsHeadingRoot = styled.div`
  padding: 25px 20px 15px 20px;
`;

const PermissionsGrantsHeadingTitle = styled.div`
  padding: 0px 0px 10px 25px;
`;

const NoTagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
`;

const getPermissionGrantExplanatoryText = (grant) => {
  const {
    grantSpecificity,
    grantLocality,
    granterName,
    granteeName,
    grantedViaNodeName,
  } = grant;
  switch (grantSpecificity) {
    case 'explicit':
      switch (grantLocality) {
        case 'inbound':
          return `${granterName} shared this record with you with these permissions.`;
        case 'outbound':
          return `You provided access to this record to ${granteeName} with these permissions.`;
        case 'downstream':
          return `${granterName} shared this record with these permissions with ${granteeName}.`;
        default:
          return null;
      }
    case 'implicit':
      switch (grantLocality) {
        case 'inbound':
          return `You have these permissions for this record because ${grantedViaNodeName} was shared with you.`;
        case 'outbound':
          return `${granteeName} has these permissions for this record because you shared ${grantedViaNodeName} with them.`;
        case 'downstream':
          return `${granteeName} has these permissions for this record because ${granterName} shared ${grantedViaNodeName} with them.`;
        default:
          return null;
      }
    default:
      return null;
  }
};

const RecordPanel = () => {
  const {
    expandedRecordId,
    showRecordPanel,
    backNavigationEnabled,
    backNavigationLanding,
    dispatchUserInterfaceAction,
    reloadRecordPermissionGrants,
    selectedConversation,
    newConversation,
    selectedRecordPanelTab,
    recordPanelDisplayMode,
  } = React.useContext(UserInterfaceContext);

  const {
    connectionId,
    connectionName: conversationConnectionName,
    interlocutorOrganizationName: conversationInterlocutorOrganizationName,
    recordName: conversationTetherName,
  } = selectedConversation || newConversation || {};

  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const [record, setRecord] = React.useState(null);
  const [itemCount, setItemCount] = React.useState(0);
  const [hoveredTab, setHoveredTab] = React.useState(null);
  const [isPanelMenuOpen, setIsPanelMenuOpen] = React.useState(false);
  const [panelMenuAnchorEl, setPanelMenuAnchorEl] = React.useState(null);
  const [tabState, setTabState] = React.useState('not loaded'); // 'not loaded', 'loading', 'loaded'
  const [recordFrontendPermissions, setRecordFrontendPermissions] = React.useState(null);
  const [inboundGrants, setInboundGrants] = React.useState([]);
  const [outboundGrants, setOutboundGrants] = React.useState([]);
  const [downstreamGrants, setDownstreamGrants] = React.useState([]);
  const [grantConnnectionId, setGrantConnectionId] = React.useState(null);
  const [grantConnectionName, setGrantConnectionName] = React.useState('');
  const [grantFrontendPermissions, setGrantFrontendPermissions] = React.useState(null);
  const [grantAtomicPermissions, setGrantAtomicPermissions] = React.useState(null);
  const [isRecordSaving, setIsRecordSaving] = React.useState(false);
  const [showStopSharingRecordDialog, setShowStopSharingRecordDialog] = React.useState(false);

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        setAuthToken(null);
        setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw(fatalError);
  }

  const loadRecord = React.useCallback(async() => {
    try {
      setTabState('loading');
      const counter = await genNodeCountByRecordId(
        authToken,
        currentPerspectiveId,
        expandedRecordId,
      );
      const {
        nodesCount,
      } = counter;
      setItemCount(nodesCount);

      const responseRecord = await genRecordByRecordId(
        authToken,
        currentPerspectiveId,
        expandedRecordId,
      );
      setRecord(responseRecord);

      const {
        permissions,
      } = responseRecord;

      const {
        frontendPermissions,
      } = parseRecordPermissions(permissions);

      setRecordFrontendPermissions(frontendPermissions);

      const recordPermissionGrants = await genRecordsPermissionGrants(authToken, currentPerspectiveId, expandedRecordId);

      const inboundGrants = recordPermissionGrants.filter(grant => grant.grantLocality === 'inbound').map(grant => {
        const {
          permissions,
        } = grant;

        return {
          ...grant,
          parsedPermissions: parseRecordPermissions(permissions),
        };
      });
      setInboundGrants(inboundGrants);

      const outboundGrants = recordPermissionGrants.filter(grant => grant.grantLocality === 'outbound').map(grant => {
        const {
          permissions,
        } = grant;

        return {
          ...grant,
          parsedPermissions: parseRecordPermissions(permissions),
        };
      });
      setOutboundGrants(outboundGrants);

      const downstreamGrants = recordPermissionGrants.filter(grant => grant.grantLocality === 'downstream').map(grant => {
        const {
          permissions,
        } = grant;

        return {
          ...grant,
          parsedPermissions: parseRecordPermissions(permissions),
        };
      });
      setDownstreamGrants(downstreamGrants);
      dispatchUserInterfaceAction({
        type: 'SET_RELOAD_RECORD_PERMISSION_GRANTS',
        payload: false,
      });
      setTabState('loaded');
    } catch (error) {
      setError(error);
    }
  }, [authToken, currentPerspectiveId, dispatchUserInterfaceAction, expandedRecordId]);

  React.useEffect(() => {
    if (showRecordPanel && (tabState === 'not loaded' || reloadRecordPermissionGrants)) {
      loadRecord();
    }
  }, [loadRecord, reloadRecordPermissionGrants, showRecordPanel, tabState]);

  if (!record) {
    return null;
  }

  const {
    name: recordName,
    id: recordId,
    permissions,
  } = record;

  const {
    frontendPermissions: {
      allow3rdPartyAccess,
    },
  } = parseRecordPermissions(permissions);

  const saveRecordPermissions = async() => {
    try {
      setIsRecordSaving(true);
      const atomicPermissions = recordFrontendPermissionsToAtomicPermissions(grantFrontendPermissions);
      const permissionsInt = recordAtomicPermissionsToPermissionsInt(atomicPermissions);
      await updateRecordPermissions(authToken, currentPerspectiveId, expandedRecordId, {
        connectionId: grantConnnectionId,
        permissions: permissionsInt,
      });

      dispatchUserInterfaceAction({
        type: 'SET_RELOAD_RECORD_PERMISSION_GRANTS',
        payload: true,
      });

      setIsRecordSaving(false);
      dispatchUserInterfaceAction({
        type: 'SET_RECORD_PANEL_DISPLAY_MODE',
        payload: 'default',
      });
    } catch(error) {
      setError(error);
    }
  };

  const onClose = () => {
    setTabState('not loaded');
    dispatchUserInterfaceAction({
      type: 'SET_RECORD_PANEL_DISPLAY_MODE',
      payload: 'default',
    });
    dispatchUserInterfaceAction({
      type: 'SET_EXPANDED_RECORD_ID',
      payload: null,
    });
    dispatchUserInterfaceAction({
      type: 'SET_RELOAD_RECORD_PERMISSION_GRANTS',
      payload: false,
    });
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_RECORD_PANEL',
      payload: false,
    });
    dispatchUserInterfaceAction({
      type: 'SET_SELECTED_CONVERSATION',
      payload: null,
    });
    dispatchUserInterfaceAction({
      type: 'SET_NEW_CONVERSATION',
      payload: null,
    });
    dispatchUserInterfaceAction({
      type: 'SET_RECORD_PANEL_SELECTED_TAB',
      payload: 0,
    });
    if (backNavigationEnabled) {
      dispatchUserInterfaceAction({
        type: 'SET_BACK_NAVIGATION_ENABLED',
        payload: {
          backNavigationEnabled: false,
          backNavigationLanding: null,
        },
      });
    }
  };

  return (
    <ContextualPanel
      expanded={!!expandedRecordId && showRecordPanel}
      handleEscKey={() => {
        onClose();
      }}
    >
      <ContextualPanelNavigation
        expanded={!!expandedRecordId && showRecordPanel}
      >
        <ContextualPanelBackButton
          data-test-id="record-contextual-panel-back-button"
          isVisible={backNavigationEnabled}
          onClick={() => {
            switch (backNavigationLanding) {
              case 'notifications': {
                onClose();
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                  payload: true,
                });
                break;
              }
              case 'conversations': {
                dispatchUserInterfaceAction({
                  type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                  payload: 'default',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_NEW_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: false,
                    backNavigationLanding: null,
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_RECORD_PANEL_SELECTED_TAB',
                  payload: 1,
                });
                break;
              }
              default:
                onClose();
            }
          }}
        />
        <ContextualPanelCloseButton
          data-test-id="record-panel-close-button"
          isVisible={true}
          onClick={() => {
            onClose();
          }}
        />
      </ContextualPanelNavigation>

      {
        recordPanelDisplayMode !== 'conversation' && recordPanelDisplayMode !== 'start_conversation' &&
        <ContextualPanelHeader>
          <Typography variant="h3" fontWeight="bold">
            {recordName}
          </Typography>
        </ContextualPanelHeader>
      }

      {/* LOADING */}
      {
        tabState !== 'loaded' && recordPanelDisplayMode !== 'conversation' &&
        <>
          <ContextualPanelContent>
            <PaddedContainer>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{
                    height: '100%',
                    flexGrow: 1,
                    width: '100%',
                  }}
                >
                  <NoTagsContainer>
                    <CenterpieceSpinner/>
                  </NoTagsContainer>
                </Grid>
              </Grid>
            </PaddedContainer>
          </ContextualPanelContent>
          <ContextualPanelActions expanded={showRecordPanel} />
        </>
      }

      {/* PANEL DISPLAY MODE: 'default' */}
      {
        recordPanelDisplayMode === 'default' &&
        <>
          <ContextualPanelContent>
            <ContextualPanelTabs
              selectedTab={selectedRecordPanelTab}
            >
              <ContextualPanelTab
                onMouseEnter={() => {
                  setHoveredTab(0);
                }}
                onMouseLeave={() => {
                  setHoveredTab(null);
                }}
                tabIndex={0}
                selectedTab={selectedRecordPanelTab}
                hoveredTab={hoveredTab}
                setSelectedTab={(value) => {
                  dispatchUserInterfaceAction({
                    type: 'SET_RECORD_PANEL_SELECTED_TAB',
                    payload: value,
                  });
                }}
                label="Record Details"
                icon={<InfoOutlinedIcon />}
              />
              <ContextualPanelTab
                onMouseEnter={() => {
                  setHoveredTab(1);
                }}
                onMouseLeave={() => {
                  setHoveredTab(null);
                }}
                tabIndex={1}
                selectedTab={selectedRecordPanelTab}
                hoveredTab={hoveredTab}
                setSelectedTab={(value) => {
                  dispatchUserInterfaceAction({
                    type: 'SET_RECORD_PANEL_SELECTED_TAB',
                    payload: value,
                  });
                }}
                label="Conversations"
                icon={<ChatBubbleOutlineIcon />}
              />
            </ContextualPanelTabs>

            {
              selectedRecordPanelTab === 0 &&
              <>
                <ItemCountContainer>
                  <Typography variant="h1" fontWeight="bold" color="grey">
                    {itemCount}
                  </Typography>
                  <CounterDescription>
                    <Typography variant="caption" color="black">
                      items
                    </Typography>
                    <Typography variant="caption" color="black">
                      attached to this record
                    </Typography>
                  </CounterDescription>
                </ItemCountContainer>

                {/* INBOUND GRANTS */}
                {
                  inboundGrants.length > 0 &&
                  <PermissionGrantsHeadingRoot>
                    <PermissionsGrantsHeadingTitle>
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        fontFamily="primary"
                        color="tertiary">
                          PERMISSIONS RECEIVED FROM
                      </Typography>
                    </PermissionsGrantsHeadingTitle>
                    {
                      inboundGrants.map((grant, index) => {
                        const {
                          parsedPermissions: {
                            frontendPermissions,
                            atomicPermissions,
                          },
                          grantedViaNodeName,
                        } = grant;
                        const activePermissions = Object.keys(frontendPermissions).filter(key => frontendPermissions[key]).map(key => recordFrontendPermissionLabel(key));

                        // if there are no frontendPermissions, show the available atomic permissions
                        if (activePermissions.length === 0) {
                          for (const key in atomicPermissions) {
                            if (atomicPermissions[key]) {
                              activePermissions.push(recordAtomicPermissionLabel(key));
                            }
                          }
                        }

                        return (
                          <PermissionGrant
                            key={index}
                            title={grant.grantedViaConnectionName}
                            subTitle={grant.granterName}
                          >
                            <Typography
                              variant="caption"
                              fontWeight="bold"
                              fontFamily="primary"
                              color="grey"
                            >
                              {
                                grantedViaNodeName ?
                                  `PERMISSIONS FROM ${grantedViaNodeName.toUpperCase()}:`
                                  :
                                  'PERMISSIONS:'
                              }
                            </Typography>
                            <Typography
                              variant="caption"
                              fontWeight="normal"
                              fontFamily="primary"
                              color="grey">
                              {
                                activePermissions.join(', ')
                              }
                            </Typography>
                            <PermissionGrantInformation
                              label='Why does my Organization have permissions on this record?'
                              dismissLabel='Dismiss'
                              explanatoryText={getPermissionGrantExplanatoryText(grant)}
                            />
                          </PermissionGrant>
                        );
                      })
                    }
                  </PermissionGrantsHeadingRoot>
                }

                {/* OUTBOUND GRANTS */}
                {
                  outboundGrants.length > 0 &&
                  <PermissionGrantsHeadingRoot>
                    <PermissionsGrantsHeadingTitle>
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        fontFamily="primary"
                        color="tertiary">
                        PARTNER PERMISSIONS
                      </Typography>
                    </PermissionsGrantsHeadingTitle>
                    {
                      outboundGrants.map((grant, index) => {
                        const {
                          parsedPermissions: {
                            frontendPermissions,
                            atomicPermissions,
                          },
                          granteeName,
                          grantedViaConnectionName,
                          grantedViaNodeName,
                          grantedViaConnectionId,
                        } = grant;
                        const activePermissions = Object.keys(frontendPermissions).filter(key => frontendPermissions[key]).map(key => recordFrontendPermissionLabel(key));
                        // if there are no frontendPermissions, show the available atomic permissions
                        if (activePermissions.length === 0) {
                          for (const key in atomicPermissions) {
                            if (atomicPermissions[key]) {
                              activePermissions.push(recordAtomicPermissionLabel(key));
                            }
                          }
                        }
                        return (
                          <PermissionGrant
                            key={index}
                            title={grantedViaConnectionName}
                            subTitle={granteeName}
                            menuOptions={
                              grantedViaNodeName ?
                                []
                                :
                                [
                                  {
                                    label: 'Edit Permissions',
                                    onClick: () => {
                                      setGrantFrontendPermissions(frontendPermissions);
                                      setGrantAtomicPermissions(atomicPermissions);
                                      setGrantConnectionId(grantedViaConnectionId);
                                      dispatchUserInterfaceAction({
                                        type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                                        payload: 'editPermissions',
                                      });
                                    },
                                  },
                                  {
                                    label: 'Stop Sharing',
                                    onClick: () => {
                                      setGrantConnectionName(grantedViaConnectionName);
                                      setGrantConnectionId(grantedViaConnectionId);
                                      dispatchUserInterfaceAction({
                                        type: 'SET_SHOW_RECORD_PANEL',
                                        payload: false,
                                      });
                                      setShowStopSharingRecordDialog(true);
                                    },
                                  },
                                ]
                            }
                          >
                            <Typography
                              variant="caption"
                              fontWeight="bold"
                              fontFamily="primary"
                              color="grey">
                              {
                                grantedViaNodeName ?
                                  `PERMISSIONS FROM ${grantedViaNodeName.toUpperCase()}:`
                                  :
                                  'PERMISSIONS:'
                              }
                            </Typography>
                            <Typography
                              variant="caption"
                              fontWeight="normal"
                              fontFamily="primary"
                              color="grey">
                              {
                                activePermissions.join(', ')
                              }
                            </Typography>
                            <PermissionGrantInformation
                              label='Why does this Organization have these permissions for this record?'
                              dismissLabel='Dismiss'
                              explanatoryText={getPermissionGrantExplanatoryText(grant)}
                            />
                          </PermissionGrant>
                        );
                      })
                    }
                  </PermissionGrantsHeadingRoot>
                }

                {/* DOWNSTREAM GRANTS */}
                {
                  downstreamGrants.length > 0 &&
                  <PermissionGrantsHeadingRoot>
                    <PermissionsGrantsHeadingTitle>
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        fontFamily="primary"
                        color="tertiary">
                        3RD PARTY PERMISSIONS
                      </Typography>
                    </PermissionsGrantsHeadingTitle>
                    {
                      downstreamGrants.map((grant, index) => {
                        const {
                          parsedPermissions: {
                            frontendPermissions,
                            atomicPermissions,
                          },
                          granteeName,
                          granterName,
                          grantedViaNodeName,
                        } = grant;
                        const activePermissions = Object.keys(frontendPermissions).filter(key => frontendPermissions[key]).map(key => recordFrontendPermissionLabel(key));

                        // if there are no frontendPermissions, show the available atomic permissions
                        if (activePermissions.length === 0) {
                          for (const key in atomicPermissions) {
                            if (atomicPermissions[key]) {
                              activePermissions.push(recordAtomicPermissionLabel(key));
                            }
                          }
                        }
                        return (
                          <PermissionGrant
                            key={index}
                            title={granteeName}
                          >
                            <Typography
                              variant="caption"
                              fontWeight="bold"
                              fontFamily="primary"
                              color="grey">
                              {
                                grantedViaNodeName ?
                                  `PERMISSIONS FROM ${granterName.toUpperCase()} VIA ${grantedViaNodeName.toUpperCase()}:`
                                  :
                                  `PERMISSIONS FROM ${granterName.toUpperCase()}:`
                              }
                            </Typography>
                            <Typography
                              variant="caption"
                              fontWeight="normal"
                              fontFamily="primary"
                              color="grey">
                              {
                                activePermissions.join(', ')
                              }
                            </Typography>
                            <PermissionGrantInformation
                              label='Why does this Organization have these permissions for this record?'
                              dismissLabel='Dismiss'
                              explanatoryText={getPermissionGrantExplanatoryText(grant)}
                            />
                          </PermissionGrant>
                        );
                      })
                    }
                  </PermissionGrantsHeadingRoot>
                }
              </>
            }
            {
              selectedRecordPanelTab === 1 &&
              <RecordConversationsTab
                recordId={recordId}
                isShared={
                  inboundGrants.length > 0 ||
                  outboundGrants.length > 0 ||
                  downstreamGrants.length > 0
                }
                setPanelDisplayMode={(value) => {
                  dispatchUserInterfaceAction({
                    type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                    payload: value,
                  });
                }}
                dispatchUserInterfaceAction={dispatchUserInterfaceAction}
              />
            }
          </ContextualPanelContent>
          <ContextualPanelActions expanded={showRecordPanel}>
            {
              allow3rdPartyAccess &&
              <>
                <Button
                  data-test-id="record-actions-button"
                  variant="icon-text"
                  onClick={(ev) => {
                    setPanelMenuAnchorEl(ev.currentTarget);
                    setIsPanelMenuOpen(true);
                  }}
                >
                  <MoreHorizIcon />
                </Button>
                <Menu
                  open={isPanelMenuOpen}
                  onClose={() => {
                    setIsPanelMenuOpen(false);
                    setPanelMenuAnchorEl(null);
                  }}
                  anchorEl={panelMenuAnchorEl}
                  disablePortal={true}
                >
                  <MenuItem
                    data-test-id="node-panel-context-menu-delete-button"
                    disabled={false}
                    onClick={() => {
                      dispatchUserInterfaceAction({
                        type: 'SET_SHOW_SHARE_RECORD_WIZARD',
                        payload: true,
                      });
                      dispatchUserInterfaceAction({
                        type: 'SET_SHARED_RECORD_ID',
                        payload: expandedRecordId,
                      });
                      dispatchUserInterfaceAction({
                        type: 'SET_SHOW_RECORD_PANEL',
                        payload: false,
                      });
                      setIsPanelMenuOpen(false);
                    }}
                  >
                    <Typography variant="small">
                        Share Record
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            }
          </ContextualPanelActions>
        </>
      }

      {/* PANEL DISPLAY MODE: 'editPermissions' */}
      {
        recordPanelDisplayMode === 'editPermissions' &&
        <>
          <ContextualPanelContent
            panelDisplayMode={recordPanelDisplayMode}
          >
            <PaddedContainer>
              <Grid
                container
                spacing={3}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <PermissionsContainer>
                    <PermissionSwitches
                      disabled={isRecordSaving}
                      permissions={recordAtomicPermissionsToFrontendPermissions(grantAtomicPermissions)}
                      getPermissionReadableFormat={recordFrontendPermissionLabel}
                      editable={recordFrontendPermissions}
                      disabledPermissions={RECORD_FRONTEND_DISABLED_PERMISSIONS}
                      onTogglePermission={(permission) => {
                        const newSharedFrontendPermissions = {
                          ...grantFrontendPermissions,
                          [permission]: !grantFrontendPermissions[permission],
                        };
                        setGrantFrontendPermissions(recordFrontendPermissionsToImpliedFrontendPermissions(newSharedFrontendPermissions));

                        const newSharedAtomicPermissions = recordFrontendPermissionsToAtomicPermissions(newSharedFrontendPermissions);
                        setGrantAtomicPermissions(newSharedAtomicPermissions);
                      }}
                    />
                  </PermissionsContainer>
                </Grid>
              </Grid>
            </PaddedContainer>
          </ContextualPanelContent>
          <ContextualPanelActions expanded={showRecordPanel}>
            <Button
              data-test-id="save-all-fields-button"
              disabled={tabState !== 'loaded' || isRecordSaving}
              onClick={() => {
                saveRecordPermissions();
              }}
            >
              {isRecordSaving ? 'SAVING...' : 'SAVE'}
            </Button>
            <SecondButtonContainer>
              <Button
                data-test-id="cancel-button"
                variant="text"
                disabled={tabState !== 'loaded' || isRecordSaving}
                onClick={() => {
                  dispatchUserInterfaceAction({
                    type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                    payload: 'default',
                  });
                }}
              >
                CANCEL
              </Button>
            </SecondButtonContainer>
          </ContextualPanelActions>
        </>
      }

      {/* PANEL DISPLAY MODE: 'conversation' */}
      {
        recordPanelDisplayMode === 'conversation' &&
        <ConversationContainer
          newConversationConnectionId={connectionId}
          conversationConnectionName={conversationConnectionName}
          conversationInterlocutorOrganizationName={conversationInterlocutorOrganizationName}
          conversationTetherName={conversationTetherName}
          conversation={selectedConversation}
          onCreateNewConversation={async(connectionId, message) => {
            const newConversation = await createRecordConversation(
              authToken,
              currentPerspectiveId,
              {
                recordId: expandedRecordId,
                message,
                connectionId,
              },
            );
            dispatchUserInterfaceAction({
              type: 'SET_SELECTED_CONVERSATION',
              payload: newConversation,
            });
          }}
          onUpdateConversationStatus={async(conversationId, status) => {
            await updateConversationStatus({
              authToken,
              perspectiveId: currentPerspectiveId,
              conversationId,
              status,
            });
            const updatedConversation = await genConversationByConversationId({
              authToken,
              perspectiveId: currentPerspectiveId,
              conversationId,
            });
            dispatchUserInterfaceAction({
              type: 'SET_SELECTED_CONVERSATION',
              payload: updatedConversation,
            });
          }}
        />
      }

      {/* PANEL DISPLAY MODE: 'start_conversation' */}
      {
        recordPanelDisplayMode === 'start_conversation' &&
        <StartConversation
          panelDisplayMode={recordPanelDisplayMode}
          loadConnectionsList={async() => {
            const result = await genAvailableConnectionsByRecordId({
              authToken,
              perspectiveId: currentPerspectiveId,
              recordId,
            });

            return result;
          }}
          onSelectConnection={(connection) => {
            const {
              id: connectionId,
              name,
              peerName,
            } = connection;

            dispatchUserInterfaceAction({
              type: 'SET_NEW_CONVERSATION',
              payload: {
                connectionId,
                connectionName: name,
                interlocutorOrganizationName: peerName,
                recordName: recordName,
              },
            });
            dispatchUserInterfaceAction({
              type: 'SET_RECORD_PANEL_DISPLAY_MODE',
              payload: 'conversation',
            });
          }}
        />
      }

      {/* STOP SHARING RECORD DIALOG */}
      <StopSharingRecordDialog
        showStopSharingRecordDialog={showStopSharingRecordDialog}
        setShowStopSharingRecordDialog={setShowStopSharingRecordDialog}
        stopSharingRecordId={recordId}
        stopSharingRecordName={recordName}
        stopSharingRecordConnectionId={grantConnnectionId}
        stopSharingRecordConnectionName={grantConnectionName}
        dispatchUserInterfaceAction={dispatchUserInterfaceAction}
        setTabState={setTabState}
      />
    </ContextualPanel>
  );
};

RecordPanel.propTypes = {
  expandedConnectionId: PropTypes.number,
  showConnectionPanel: PropTypes.bool,
  dispatchUserInterfaceAction: PropTypes.func,
};

export default RecordPanel;
