import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import Typography from 'ui-library/components/Typography';
import Modal from '@material-ui/core/Modal';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles({
  modalBody: {
    display: theme.nodeAttachmentPreviewImage.modalBody.display,
    alignItems: theme.nodeAttachmentPreviewImage.modalBody.alignItems,
    justifyContent: theme.nodeAttachmentPreviewImage.modalBody.justifyContent,
    width: theme.nodeAttachmentPreviewImage.modalBody.width,
    height: theme.nodeAttachmentPreviewImage.modalBody.height,
  },
  modalContent: {
    display: theme.nodeAttachmentPreviewImage.modalContent.display,
    flexDirection: theme.nodeAttachmentPreviewImage.modalContent.flexDirection,
    alignItems: theme.nodeAttachmentPreviewImage.modalContent.alignItems,
    justifyContent: theme.nodeAttachmentPreviewImage.modalContent.justifyContent,
  },
  modalHeaderWrap: {
    display: theme.nodeAttachmentPreviewImage.modalHeaderWrap.display,
    justifyContent: theme.nodeAttachmentPreviewImage.modalHeaderWrap.justifyContent,
    width: theme.nodeAttachmentPreviewImage.modalHeaderWrap.width,
    height: theme.nodeAttachmentPreviewImage.modalHeaderWrap.height,
  },
  modalHeader: {
    display: theme.nodeAttachmentPreviewImage.modalHeader.display,
    width: theme.nodeAttachmentPreviewImage.modalHeader.width,
    justifyContent: theme.nodeAttachmentPreviewImage.modalHeader.justifyContent,
    alignItems:theme.nodeAttachmentPreviewImage.modalHeader.alignItems,
    color:theme.nodeAttachmentPreviewImage.modalHeader.color,
    height:theme.nodeAttachmentPreviewImage.modalHeader.height,
    cursor: theme.nodeAttachmentPreviewImage.modalHeader.cursor,
  },
  modalImage: {
    maxWidth: theme.nodeAttachmentPreviewImage.modalImage.maxWidth,
    maxHeight: theme.nodeAttachmentPreviewImage.modalImage.maxHeight,
    border: theme.nodeAttachmentPreviewImage.modalImage.border,
  },
});

const NodeAttachmentPreviewImage = ({
  isLoading,
  loadingSpinner,
  url,
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className={classes.modalBody}>
        <div className={classes.modalContent}>
          {
            isLoading ?
              <ClickAwayListener
                onClickAway={onClose}
              >
                <span>
                  {loadingSpinner}
                </span>
              </ClickAwayListener>
              :
              <>
                <Fade in={!isLoading} timeout={100}>
                  <div className={classes.modalHeaderWrap}>
                    <div className={classes.modalHeader}>
                      <Typography
                        data-test-id="close-attachment-preview-button"
                        variant="body"
                        color="white"
                        fontWeight="bold"
                        onClick={onClose}
                      >
                        CLOSE
                      </Typography>
                      <CloseRoundedIcon/>
                    </div>
                  </div>
                </Fade>
                <ClickAwayListener onClickAway={onClose}>
                  <Fade in={!isLoading} timeout={100}>
                    <img
                      src={url}
                      alt="attachment-preview"
                      className={classes.modalImage}/>
                  </Fade>
                </ClickAwayListener>
              </>
          }
        </div>
      </div>
    </Modal>
  );
};

NodeAttachmentPreviewImage.propTypes = {
  isLoading: PropTypes.bool,
  loadingSpinner: PropTypes.node,
  url: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NodeAttachmentPreviewImage;
