function propertyConfigurationHintReducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'HYDRATE_PROPERTY_CONFIGURATION_HINT': {
      const {
        virtualLocationTreeTitle,
        virtualLocationTreeDescription,
        virtualLocationTreeHints,
      } = payload;

      const virtualLocationTreeNodeHintsById = {};
      const virtualLocationTreeNodeHintIds = [];

      for (let virtualLocationTreeNodeHint of virtualLocationTreeHints) {
        const {
          id,
        } = virtualLocationTreeNodeHint;

        virtualLocationTreeNodeHintsById[id] = virtualLocationTreeNodeHint;
        virtualLocationTreeNodeHintIds.push(id);
      }

      return {
        ...state,
        virtualLocationTreeTitle,
        virtualLocationTreeDescription,
        virtualLocationTreeNodeHintsById,
        virtualLocationTreeNodeHintIds,
      };
    }

    case 'ADD_VIRTUAL_LOCATION_TREE_NODE': {
      const {
        virtualId,
      } = payload;

      return {
        ...state,
        virtualLocationTreeNodesById: {
          ...state.virtualLocationTreeNodesById,
          [virtualId]: payload,
        },
        virtualLocationTreeNodeIds: [
          ...state.virtualLocationTreeNodeIds,
          virtualId,
        ],
      };
    }

    case 'SET_VIRTUAL_LOCATION_TREE_NODE_NAME': {
      return {
        ...state,
        virtualLocationTreeNodesById: {
          ...state.virtualLocationTreeNodesById,
          [payload.virtualLocationTreeNodeId]: {
            ...state.virtualLocationTreeNodesById[payload.virtualLocationTreeNodeId],
            name: payload.newName,
          },
        },
      };
    }

    case 'REMOVE_VIRTUAL_LOCATION_TREE_NODES': {
      let newVirtualLocationTreeNodesById = {
        ...state.virtualLocationTreeNodesById,
      };
      let newVirtualLocationTreeNodeIds = [
        ...state.virtualLocationTreeNodeIds,
      ];

      for (let virtualLocationTreeNodeId of payload) {
        delete newVirtualLocationTreeNodesById[virtualLocationTreeNodeId];
        newVirtualLocationTreeNodeIds = [
          ...newVirtualLocationTreeNodeIds.slice(0, newVirtualLocationTreeNodeIds.indexOf(virtualLocationTreeNodeId)),
          ...newVirtualLocationTreeNodeIds.slice(newVirtualLocationTreeNodeIds.indexOf(virtualLocationTreeNodeId) + 1),
        ];
      }

      return {
        ...state,
        virtualLocationTreeNodesById: newVirtualLocationTreeNodesById,
        virtualLocationTreeNodeIds: newVirtualLocationTreeNodeIds,
      };
    }

    default:
      throw new Error(`Unknown property configuration action "${actionType}"!`);
  }
}

export default propertyConfigurationHintReducer;
