import React from 'react';
import AdminContext from 'contexts/Admin';
import RecordTypeWithTypes from './RecordTypeWithTypes.react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import AddTypeToRecordTypeDialog from './AddTypeToRecordTypeDialog.react';
import Prompt from 'components/admin/Prompt';

const RecordTypesAdmin = () => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
  } = React.useContext(AdminContext);
  const [filterQuery, setFilterQuery] = React.useState('');
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  const localRecordType = DataModelQueries.selectRecordTypeById(
    DataModel,
    localState.recordTypeId,
  );
  const localType = DataModelQueries.selectTypeById(
    DataModel,
    localState.typeId,
  );
  const isTypeIdActiveInEhrHub = DataModelQueries.selectElectronicHealthHubTypeIds(DataModel).includes(localState.typeId);
  const isEhrRecordTypeSelected = localState.recordTypeId === 7;

  return (
    <div>
      <Typography
        variant="h4">
        Record Types
      </Typography>
      <Typography
        variant="body2">
        edit Record Type - Type associations.
      </Typography>
      <br/><br/>
      <TextField
        fullWidth
        placeholder="filter by type name or type alias (3 characters minimum)"
        value={filterQuery}
        onChange={event => {
          setFilterQuery(event.target.value);
        }}
      />
      <br/><br/><br/>

      {
        DataModelQueries.selectRecordTypes(DataModel)
          .sort((rt1, rt2) => {
            const recordTypeIdSortOrder = [4, 1, 2, 3, 5, 6];
            if (recordTypeIdSortOrder.indexOf(rt1.id) > recordTypeIdSortOrder.indexOf(rt2.id)) {
              return 1;
            }
            if (recordTypeIdSortOrder.indexOf(rt1.id) < recordTypeIdSortOrder.indexOf(rt2.id)) {
              return -1;
            }
            return 0;
          })
          .map((recordType, index, arr) => {
            const {
              id: recordTypeId,
              name: recordTypeName,
            } = recordType;
            return (
              <React.Fragment
                key={recordTypeId}>
                <RecordTypeWithTypes
                  recordTypeId={recordTypeId}
                  recordTypeName={recordTypeName}
                  types={
                    DataModelQueries.selectTypesByIds(
                      DataModel,
                      DataModelQueries.selectTypeIdsInRecordTypeById(DataModel, recordTypeId),
                    )
                      .filter(type => {
                        const {
                          name: typeName,
                          aliases: typeAliases,
                        } = type;

                        if (filterQuery.length < 3) {
                          return false;
                        }

                        let match = false;

                        if (typeName.toLocaleLowerCase().includes(filterQuery.toLocaleLowerCase())) {
                          match = true;
                        }
                        for (let typeAlias of typeAliases) {
                          if (typeAlias.toLocaleLowerCase().includes(filterQuery.toLocaleLowerCase())) {
                            match = true;
                            break;
                          }
                        }

                        return match;
                      })
                  }
                  onAddTypeToRecordType={recordTypeId => {
                    dispatchLocalAction({
                      type: 'BEGIN_ADD_TYPE_TO_RECORD_TYPE',
                      payload: recordTypeId,
                    });
                  }}
                  onRemoveTypeFromRecordType={async(recordTypeId, typeId) => {
                    try {
                      dispatchLocalAction({
                        type: 'BEGIN_CHECK_TYPE_IN_RECORD_TYPE_USAGE',
                        payload: {
                          recordTypeId,
                          typeId,
                        },
                      });
                      const usage = await DataModelAPI.genTypeUsageInRecordType(
                        authToken,
                        perspectiveId,
                        {
                          recordTypeId,
                          typeId,
                        },
                      );
                      dispatchLocalAction({
                        type: 'RESOLVE_CHECK_TYPE_IN_RECORD_TYPE_USAGE',
                        payload: usage,
                      });
                      dispatchLocalAction({
                        type: 'BEGIN_REMOVE_TYPE_FROM_RECORD_TYPE',
                        payload: {
                          recordTypeId,
                          typeId,
                        },
                      });
                    } catch(error) {
                      throw error;
                    }
                  }}
                  DataModelQueries={DataModelQueries}
                  DataModel={DataModel}
                />
                {
                  index < arr.length - 1 &&
                    <Divider/>
                }
              </React.Fragment>
            );
          })
      }

      <AddTypeToRecordTypeDialog
        open={localState.status === 'adding type to record type'}
        onClose={() => {
          dispatchLocalAction({
            type: 'RESET',
          });
        }}
        recordTypeName={
          DataModelQueries.selectRecordTypeById(DataModel, localState.recordTypeId) &&
            DataModelQueries.selectRecordTypeById(DataModel, localState.recordTypeId).name
        }
        candidateTypes={
          DataModelQueries.selectTypes(DataModel)
            .filter(type => {
              const excludedTypeIds = DataModelQueries.selectTypeIdsInRecordTypeById(
                DataModel,
                localState.recordTypeId,
              );

              if (excludedTypeIds.includes(type.id)) {
                return false;
              }

              return true;
            })
        }
        typeId={localState.typeId}
        onSetTypeId={typeId => {
          dispatchLocalAction({
            type: 'SET_TYPE_ID',
            payload: typeId,
          });
        }}
        onSave={async() => {
          const {
            recordTypeId,
            typeId,
          } = localState;
          try {
            await DataModelAPI.addTypeToRecordType(
              authToken,
              perspectiveId,
              {
                recordTypeId,
                typeId,
              },
            );
            dispatchLocalAction({
              type: 'RESET',
            });
            dispatchDataModelAction({
              type: 'ADD_TYPE_TO_RECORD_TYPE',
              payload: {
                recordTypeId,
                typeId,
              },
            });
          } catch(error) {
            throw error;
          }
        }}
      />

      {
        localState.status === 'pending type in record type usage' &&
          <Prompt
            open
            title="Please wait..."
            text={`Checking number of ${localType.pluralName} in ${localRecordType.name}...`}
          />
      }

      {
        localState.status === 'removing type from record type' &&
        localState.typeInRecordTypeUsage !== 0 &&
          <Prompt
            open
            title="Operation forbidden!"
            text={`The "${localType.name}" type cannot be disassociated with the "${localRecordType.name}" record type because there aready exist ${localState.typeInRecordTypeUsage} ${localType.pluralName} added to ${localRecordType.name} records in the data graph!`}
            onConfirm={() => {
              dispatchLocalAction({
                type: 'RESET',
              });
            }}
          />
      }
      {
        localState.status === 'removing type from record type' &&
        localState.typeInRecordTypeUsage === 0 &&
        (!isEhrRecordTypeSelected || (isEhrRecordTypeSelected && !isTypeIdActiveInEhrHub)) &&
          <Prompt
            open
            title="Confirm operation"
            text={`There are currently no ${localType.pluralName} added to any ${localRecordType.name} records across the entire data graph. If you disassociate the "${localType.name}" type from the "${localRecordType.name}" record type then users will no longer be able to assign ${localType.pluralName} to ${localRecordType.name} records.`}
            onCancel={() => {
              dispatchLocalAction({
                type: 'RESET',
              });
            }}
            onConfirm={async() => {
              try {
                const {
                  recordTypeId,
                  typeId,
                } = localState;
                await DataModelAPI.removeTypeFromRecordType(
                  authToken,
                  perspectiveId,
                  {
                    recordTypeId,
                    typeId,
                  },
                );
                dispatchDataModelAction({
                  type: 'REMOVE_TYPE_FROM_RECORD_TYPE',
                  payload: {
                    recordTypeId,
                    typeId,
                  },
                });
                dispatchLocalAction({
                  type: 'RESET',
                });
              } catch(error) {
                throw error;
              }
            }}
            confirmLabel="disassociate"
          />
      }
      {
        localState.status === 'removing type from record type' &&
        isEhrRecordTypeSelected &&
        isTypeIdActiveInEhrHub &&
        <Prompt
          open
          title="Operation forbidden!"
          text={`The "${localType.name}" type cannot be disassociated with the "${localRecordType.name}" record type because it is used in the eHR Hub!`}
          onConfirm={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
        />
      }

    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'BEGIN_ADD_TYPE_TO_RECORD_TYPE': {
      const recordTypeId = payload;

      return {
        ...state,
        status: 'adding type to record type',
        recordTypeId,
      };
    }

    case 'SET_TYPE_ID': {
      const typeId = payload;

      return {
        ...state,
        typeId,
      };
    }

    case 'BEGIN_CHECK_TYPE_IN_RECORD_TYPE_USAGE': {
      const {
        recordTypeId,
        typeId,
      } = payload;

      return {
        ...state,
        status: 'pending type in record type usage',
        recordTypeId,
        typeId,
      };
    }

    case 'RESOLVE_CHECK_TYPE_IN_RECORD_TYPE_USAGE': {
      const typeInRecordTypeUsage = payload;

      return {
        ...state,
        typeInRecordTypeUsage,
      };
    }

    case 'BEGIN_REMOVE_TYPE_FROM_RECORD_TYPE': {
      const {
        recordTypeId,
        typeId,
      } = payload;

      return {
        ...state,
        status: 'removing type from record type',
        recordTypeId,
        typeId,
      };
    }


    case 'RESET': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      adding type to record type
      removing type from record type
      pending type in record type usage
    */
    status: 'idle',
    recordTypeId: '',
    typeId: '',
    typeInRecordTypeUsage: null,
  };
}

export default RecordTypesAdmin;
