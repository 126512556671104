import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import MUICheckbox from '@material-ui/core/Checkbox';
import classnames from 'classnames';
import CheckIcon from '@material-ui/icons/CheckRounded';
import RemoveIcon from '@material-ui/icons/RemoveRounded';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: props => theme.checkbox[props.type].background,
    },
    '&:active': {
      backgroundColor: props => theme.checkbox[props.type].background,
    },
  },
  icon: {
    border: props => theme.checkbox[props.type].icon.border,
    borderRadius: props => theme.checkbox[props.type].icon.borderRadius,
    height: props => theme.checkbox[props.type].icon.height,
    width: props => theme.checkbox[props.type].icon.width,
  },
  checkedIcon: {
    background: props => theme.checkbox[props.type].checkedIcon.background,
    border: props => theme.checkbox[props.type].checkedIcon.border,
    color: props => theme.checkbox[props.type].checkedIcon.color,
  },
});

const Checkbox = ({
  disabled,
  indeterminate,
  ...otherProps
}) => {

  const type = disabled ? 'disabled' : 'active';

  const classes = useStyles({
    type,
  });

  return (
    <MUICheckbox
      className={classes.root}
      color="default"
      disabled={disabled}
      indeterminate={indeterminate}
      indeterminateIcon={<RemoveIcon className={classnames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      checkedIcon={<CheckIcon className={classnames(classes.icon, classes.checkedIcon)} />}
      {...otherProps}
    />
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
};

export default Checkbox;
