import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PUT} from '../../verbs';

export default async function PUT__admin_recordTypes_RECORDTYPEID_types_TYPEID(authToken, perspectiveId, params) {
  const {
    recordTypeId,
    typeId,
  } = params;

  return await PUT(
    `/admin/record-types/${recordTypeId}/types/${typeId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {},
  );
}
