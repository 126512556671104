import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRightRounded';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles({
  input: {
    '& .MuiTextField-root': {
      backgroundColor: theme.datepicker.background.activeColor,
    },

    // label
    '& .MuiFormLabel-root': {
      color: props => props.error ? theme.datepicker.label.error : theme.datepicker.label.default,
      // label focused
      '&.Mui-focused': {
        color: props => props.error ? theme.datepicker.label.error : theme.datepicker.label.focused,
      },
    },

    // input
    '& .MuiFilledInput-root': {
      backgroundColor: theme.datepicker.background.activeColor,
      // boxShadow on input hover
      '&:hover': {
        boxShadow: props => props.disabled ? theme.datepicker.inputBase.disabledBoxShadow : theme.datepicker.inputBase.activeBoxShadow,
      },
      '&.Mui-focused': {
        backgroundColor: theme.datepicker.background.focusedColor,
        boxShadow: props => props.disabled ? theme.datepicker.inputBase.disabledBoxShadow : theme.datepicker.inputBase.activeBoxShadow,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.datepicker.background.disabledColor,
      },
    },

    // border
    '& .MuiFilledInput-underline': {
      '&:before': {
        borderBottom: props => props.disabled ? theme.datepicker.border.disabled : props.error ? theme.datepicker.border.error : theme.datepicker.border.defaultDisabled,
      },
      '&:after': {
        borderBottom: props => props.disabled ? theme.datepicker.border.disabled : props.error ? theme.datepicker.border.error : theme.datepicker.border.defaultActive,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: props => props.error ? theme.datepicker.border.error : theme.datepicker.border.hover,
      },
    },

    // helper text
    '& .MuiFormHelperText-root': {
      color: props => props.error ? theme.datepicker.label.error : theme.datepicker.label.default,
    },
  },

  // calendar header arrows
  arrows: {
    color: theme.datepicker.arrow.color,
  },

  popover: {
    // toolbar
    '& .MuiToolbar-root': {
      backgroundColor: theme.datepicker.toolbar.backgroundColor,
      '& .MuiTypography-h4': theme.typography.lead,
      '& .MuiTypography-subtitle1': theme.typography.small,
      '& .MuiPickersToolbarText-toolbarTxt': {
        color: theme.datepicker.toolbar.color,
      },
      '& .MuiPickersToolbarText-toolbarBtnSelected': {
        color: theme.datepicker.toolbar.selectedColor,
        '&.MuiTypography-subtitle1': {
          fontWeight: theme.typography.fontWeight.bold,
        },
      },
    },

    // year
    '& .MuiPickersYear-root': {
      '&:hover': {
        backgroundColor: theme.datepicker.year.hoverColor,
      },
      '&.MuiTypography-h5': theme.typography.small,
    },
    '& .MuiPickersYear-yearSelected': {
      color: theme.datepicker.selectedYear.color,
      backgroundColor: theme.datepicker.selectedYear.backgroundColor,
      fontWeight: theme.typography.fontWeight.bold,
    },

    // calendar header
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: theme.datepicker.calendarHeader.color,
      fontWeight: theme.typography.fontWeight.bold,
      '&.MuiTypography-caption': theme.typography.small,
    },
    '& .MuiPickersCalendarHeader-transitionContainer': {
      color: theme.datepicker.calendarHeader.color,
      '& .MuiTypography-body1': {
        fontSize: theme.typography.small.fontSize,
        lineHeight: theme.typography.small.lineHeight,
        fontWeight: theme.typography.fontWeight.bold,
      },
    },

    // selected day
    '& .MuiButtonBase-root': {
      '&.MuiPickersDay-daySelected': {
        backgroundColor: theme.datepicker.selectedDay.backgroundColor,
        color: theme.datepicker.selectedDay.color,
        '& .MuiTypography-body2': {
          fontWeight: theme.typography.fontWeight.bold,
        },
      },
      '&.MuiPickersDay-day': {
        color: theme.datepicker.day.color,
        '& .MuiTypography-body2': theme.typography.small,
      },
    },
  },

});


const Datepicker = ({
  autoFocus,
  label,
  helperText,
  error,
  required,
  value,
  disabled,
  onClose,
  onChange,
  onKeyPress,
  onClick,
  onFocus,
  onMouseDown,
}) => {
  const classes = useStyles({
    disabled,
    error,
  });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          className={classes.input}
          autoFocus={autoFocus}
          label={label}
          helperText={helperText}
          error={error}
          required={required}
          value={value}
          disabled={disabled}
          onClose={onClose}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onClick={onClick}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          variant="inline"
          format="MM/dd/yyyy"
          leftArrowIcon={<KeyboardArrowLeft className={classes.arrows}/>}
          rightArrowIcon={<KeyboardArrowRight className={classes.arrows}/>}
          inputVariant="filled"
          fullWidth={true}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          PopoverProps={{
            className: classes.popover,
            onMouseDown: onMouseDown,
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

Datepicker.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
};

export default Datepicker;
