import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__nodesLinks_candidates(authToken, perspectiveId, nodeId) {

  return await GET(
    `/node-links/candidates?nodeId=${nodeId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
