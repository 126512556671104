import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import MUICircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    color: props => theme.circularProgress.color[props.color],
  },
});

const CircularProgress = ({
  color,
  size,
  variant,
  value,
}) => {
  const classes = useStyles({
    color,
  });

  const circularProgressSize = size || 24;

  if (variant && value) {
    return (
      <MUICircularProgress
        className={classes.root}
        size={circularProgressSize}
        variant={variant}
        value={value}
      />
    );
  }

  return (
    <MUICircularProgress
      className={classes.root}
      size={circularProgressSize}
    />
  );
};

CircularProgress.propTypes = {
  color: PropTypes.oneOf(['white', 'primary', 'error']),
  size: PropTypes.number,
};

export default CircularProgress;
