function initUserInterfaceState() {
  return {
    nodeIdModifiedDestructivelyForLists: null,
    expandedNodeId: null,
    expandedNodeLoadingState: 'not loaded', // 'not loaded', 'loading', 'loaded';
    showNodePanel: false,
    selectedNodePanelTab: 0,
    nodePanelDisplayMode: 'default', // 'default', 'editLocation', 'editItem', 'addNewLink', 'conversation', 'start_conversation'
    reloadNodeConversationTab: false,

    showDeleteNodeDialog: false,
    deleteNodeId: null,

    showShareNodeWizard: false,
    shareNodeId: null,

    connectionIdModifiedDestructivelyForLists: null,
    expandedConnectionId: null,
    expandedConnectionLoadingState: 'not loaded', // 'not loaded', 'loading', 'loaded';
    showConnectionPanel: false,
    selectedConnectionPanelTab: 0,
    connectionPanelDisplayMode: 'default', // 'default', 'conversation'

    showDeleteConnectionDialog: false,
    isDeletingConnection: false,
    deleteConnectionId: null,

    recordIdModifiedDestructivelyForLists: null,
    expandedRecordId: null,
    showRecordPanel: false,
    reloadRecordConversationTab: false,
    externalRecordConnectionId: null,
    selectedRecordPanelTab: 0,
    recordPanelDisplayMode: 'default', // 'default', 'editPermissions', 'conversation', 'start_conversation'

    showShareRecordWizard: false,
    sharedRecordId: null,

    showNotificationsPanel: false,
    notificationsUnreadCount: 0,

    stopSharingNodeId: null,
    stopSharingNodeName: '',
    stopSharingNodeConnectionId: null,
    stopSharingNodeConnectionName: '',
    showStopSharingNodeDialog: false,

    reloadItemPermissionGrants: false,
    reloadRecordPermissionGrants: false,

    personIdModifiedDestructivelyForLists: null,
    expandedPersonId: null,
    expandedPersonIsCurrentUser: false,
    expandedPersonLoadingState: 'not loaded', // 'not loaded', 'loading', 'loaded';
    showPersonPanel: false,
    selectedPersonId: null,
    selectedPersonRecordId: null,

    showDeletePersonDialog: false,
    deletePersonId: null,

    backNavigationEnabled: false,
    backNavigationLanding: null,

    expandedFrozenConversation: null,

    selectedConversation: null,
    newConversation: null,

    deleteNodeConversation: null,

    deleteRecordConversation: null,
    recordConversationIdModifiedDestructivelyForLists: null,
  };
};

function userInterfaceReducer(state, {type: actionType, payload}) {
  switch (actionType) {

    case 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS': {
      return {
        ...state,
        nodeIdModifiedDestructivelyForLists: payload,
      };
    }

    case 'SET_EXPANDED_NODE_ID': {
      return {
        ...state,
        expandedNodeId: payload,
      };
    }

    case 'SET_SHOW_NODE_PANEL': {
      return {
        ...state,
        showNodePanel: payload,
      };
    }

    case 'SET_NODE_PANEL_DISPLAY_MODE': {
      return {
        ...state,
        nodePanelDisplayMode: payload,
      };
    }

    case 'SET_SHOW_DELETE_NODE_DIALOG': {
      return {
        ...state,
        showDeleteNodeDialog: payload,
      };
    }

    case 'SET_DELETE_NODE_ID': {
      return {
        ...state,
        deleteNodeId: payload,
      };
    }

    case 'SET_SHOW_NOTIFICATIONS_PANEL': {
      return {
        ...state,
        showNotificationsPanel: payload,
      };
    }

    case 'SET_SHOW_NOTIFICATIONS_UNREAD_COUNT': {
      return {
        ...state,
        notificationsUnreadCount: payload,
      };
    }

    case 'SET_CONNECTION_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS': {
      return {
        ...state,
        connectionIdModifiedDestructivelyForLists: payload,
      };
    }

    case 'SET_EXPANDED_CONNECTION_ID': {
      return {
        ...state,
        expandedConnectionId: payload,
      };
    }

    case 'SET_SHOW_CONNECTION_PANEL': {
      return {
        ...state,
        showConnectionPanel: payload,
      };
    }

    case 'SET_SHOW_DELETE_CONNECTION_DIALOG': {
      return {
        ...state,
        showDeleteConnectionDialog: payload,
      };
    }

    case 'SET_IS_DELETING_CONNECTION': {
      return {
        ...state,
        isDeletingConnection: payload,
      };
    }

    case 'SET_DELETE_CONNECTION_ID': {
      return {
        ...state,
        deleteConnectionId: payload,
      };
    }

    case 'SET_RECORD_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS': {
      return {
        ...state,
        recordIdModifiedDestructivelyForLists: payload,
      };
    }

    case 'SET_EXPANDED_RECORD_ID': {
      return {
        ...state,
        expandedRecordId: payload,
      };
    }

    case 'SET_SHOW_RECORD_PANEL': {
      return {
        ...state,
        showRecordPanel: payload,
      };
    }

    case 'SET_SHOW_SHARE_RECORD_WIZARD': {
      return {
        ...state,
        showShareRecordWizard: payload,
      };
    }

    case 'SET_SHARED_RECORD_ID': {
      return {
        ...state,
        sharedRecordId: payload,
      };
    }

    case 'SET_SHOW_SHARE_NODE_WIZARD': {
      return {
        ...state,
        showShareNodeWizard: payload,
      };
    }

    case 'SET_SHARE_NODE_ID': {
      return {
        ...state,
        shareNodeId: payload,
      };
    }

    case 'SET_STOP_SHARING_NODE_ID': {
      return {
        ...state,
        stopSharingNodeId: payload,
      };
    }

    case 'SET_STOP_SHARING_NODE_NAME': {
      return {
        ...state,
        stopSharingNodeName: payload,
      };
    }

    case 'SET_STOP_SHARING_NODE_CONNECTION_ID': {
      return {
        ...state,
        stopSharingNodeConnectionId: payload,
      };
    }

    case 'SET_STOP_SHARING_NODE_CONNECTION_NAME': {
      return {
        ...state,
        stopSharingNodeConnectionName: payload,
      };
    }

    case 'SET_SHOW_STOP_SHARING_NODE_DIALOG': {
      return {
        ...state,
        showStopSharingNodeDialog: payload,
      };
    }

    case 'SET_EXTERNAL_RECORD_CONNECTION_ID': {
      return {
        ...state,
        externalRecordConnectionId: payload,
      };
    }

    case 'SET_RELOAD_ITEM_PERMISSION_GRANTS': {
      return {
        ...state,
        reloadItemPermissionGrants: payload,
      };
    }

    case 'SET_RELOAD_RECORD_PERMISSION_GRANTS': {
      return {
        ...state,
        reloadRecordPermissionGrants : payload,
      };
    }

    case 'SET_EXPANDED_NODE_LOADING_STATE': {
      return {
        ...state,
        expandedNodeLoadingState: payload,
      };
    }

    case 'SET_EXPANDED_CONNECTION_LOADING_STATE': {
      return {
        ...state,
        expandedConnectionLoadingState: payload,
      };
    }

    case 'SET_PERSON_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS': {
      return {
        ...state,
        personIdModifiedDestructivelyForLists: payload,
      };
    }

    case 'SET_EXPANDED_PERSON_ID': {
      return {
        ...state,
        expandedPersonId: payload,
      };
    }

    case 'SET_EXPANDED_PERSON_IS_CURRENT_USER': {
      return {
        ...state,
        expandedPersonIsCurrentUser: payload,
      };
    }

    case 'SET_SELECTED_PERSON_RECORD_ID': {
      return {
        ...state,
        selectedPersonRecordId: payload,
      };
    }

    case 'SET_SELECTED_PERSON_ID': {
      return {
        ...state,
        selectedPersonId: payload,
      };
    }

    case 'SET_SHOW_PERSON_PANEL': {
      return {
        ...state,
        showPersonPanel: payload,
      };
    }

    case 'SET_SHOW_DELETE_PERSON_DIALOG': {
      return {
        ...state,
        showDeletePersonDialog: payload,
      };
    }

    case 'SET_DELETE_PERSON_ID': {
      return {
        ...state,
        deletePersonId: payload,
      };
    }

    case 'SET_EXPANDED_PERSON_LOADING_STATE': {
      return {
        ...state,
        expandedPersonLoadingState: payload,
      };
    }

    case 'SET_BACK_NAVIGATION_ENABLED': {
      const {
        backNavigationEnabled,
        backNavigationLanding,
      } = payload;
      return {
        ...state,
        backNavigationEnabled: backNavigationEnabled,
        backNavigationLanding: backNavigationLanding,

      };
    }

    case 'SET_EXPANDED_FROZEN_CONVERSATION': {
      return {
        ...state,
        expandedFrozenConversation: payload,
      };
    }

    case 'SET_SELECTED_CONVERSATION': {
      return {
        ...state,
        selectedConversation: payload,
      };
    }

    case 'SET_NEW_CONVERSATION': {
      return {
        ...state,
        newConversation: payload,
      };
    }

    case 'SET_NODE_PANEL_SELECTED_TAB': {
      return {
        ...state,
        selectedNodePanelTab: payload,
      };
    }

    case 'SET_RECORD_PANEL_SELECTED_TAB': {
      return {
        ...state,
        selectedRecordPanelTab: payload,
      };
    }

    case 'SET_RECORD_PANEL_DISPLAY_MODE': {
      return {
        ...state,
        recordPanelDisplayMode: payload,
      };
    }

    case 'SET_CONNECTION_PANEL_SELECTED_TAB': {
      return {
        ...state,
        selectedConnectionPanelTab: payload,
      };
    }

    case 'SET_CONNECTION_PANEL_DISPLAY_MODE': {
      return {
        ...state,
        connectionPanelDisplayMode: payload,
      };
    }

    case 'SET_DELETE_NODE_CONVERSATION': {
      return {
        ...state,
        deleteNodeConversation: payload,
      };
    }

    case 'SET_DELETE_RECORD_CONVERSATION': {
      return {
        ...state,
        deleteRecordConversation: payload,
      };
    }

    case 'SET_RELOAD_NODE_CONVERSATION_TAB': {
      return {
        ...state,
        reloadNodeConversationTab: payload,
      };
    }

    case 'SET_RELOAD_RECORD_CONVERSATION_TAB': {
      return {
        ...state,
        reloadRecordConversationTab: payload,
      };
    }

    default:
      throw new Error(`Unknown user interface action "${actionType}"!`);

  }
};

export {
  initUserInterfaceState,
  userInterfaceReducer,
};
