import GET__imageThumbnails_SIZE_FILENAME from 'network-layer/endpoints/GET__imageThumbnails_SIZE_FILENAME';

function responseAdapter(response) {
  return response;
}

export default async function genThumbnails(params) {
  const {
    authToken,
    currentPerspectiveId,
    size,
    fileName,
  } = params;

  const response = await GET__imageThumbnails_SIZE_FILENAME(authToken, currentPerspectiveId, size, fileName);

  return responseAdapter(response);
}
