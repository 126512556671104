import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {POST} from '../../verbs';

export default async function POST__admin_intelligentDataFramework_propertyconfigurationHints_PROPERTYCONFIGURATIONHINTID_virtualLocationTreeNodeHints(authToken, perspectiveId, params) {
  const {
    propertyConfigurationHintId,
    ...postParams
  } = params;

  return await POST(
    `/admin/intelligent-data-framework/property-configuration-hints/${propertyConfigurationHintId}/virtual-location-tree-node-hints`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...postParams,
    },
  );
}
