import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'ui-library/components/TextField';
import Link from 'ui-library/components/Link';
import InputAdornment from '@material-ui/core/InputAdornment';

import CallIcon from '@material-ui/icons/CallRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';

import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  editContainer: theme.field.editContainer,
  actionButtonsContainer: theme.field.actionButtonsContainer,
  actionButton: theme.field.actionButton,
});

const PhoneNumberEditMode = ({
  autoFocus,
  inlineEdit,
  label,
  helperText,
  error,
  value,
  disabled,
  required,
  onChange,
  onKeyPress,
  onBlur,
  onFocus,
  onMouseDown,
  saveField,
  revertField,
}) => {

  const classes = useStyles();

  if (!inlineEdit) {
    return (
      <TextField
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        value={value}
        disabled={disabled}
        required={required}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CallIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <div
      className={classes.editContainer}
    >
      <div
        className={classes.actionButtonsContainer}
      >
        {
          !disabled &&
          <div
            className={classes.actionButton}
            onMouseDown={onMouseDown}
          >
            <Link
              value={`tel:${value}`}
              variant="small"
              color="primary"
              hoverColor="white"
            >
              <CallIcon
                data-test-id="inline-action-field-button"
                onClick={revertField}
              />
            </Link>
          </div>
        }
        {
          !disabled &&
          <div
            className={classes.actionButton}
            onMouseDown={onMouseDown}
          >
            <CloseIcon
              data-test-id="inline-revert-field-button"
              onClick={revertField}
            />
          </div>
        }
        {
          !disabled &&
          <div
            className={classes.actionButton}
          >
            <CheckIcon
              data-test-id="inline-save-field-button"
              onClick={() => {
                if (!error) {
                  saveField();
                }
              }}
              onMouseDown={onMouseDown}
            />
          </div>
        }
      </div>
      <TextField
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        value={value}
        disabled={disabled}
        required={required}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CallIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

PhoneNumberEditMode.propTypes = {
  autoFocus: PropTypes.bool,
  inlineEdit: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  saveField: PropTypes.func,
  revertField: PropTypes.func,
};

export default PhoneNumberEditMode;
