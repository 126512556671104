import React from 'react';
import styled from '@emotion/styled';
import Button from 'ui-library/components/Button';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';
import Link from 'ui-library/components/Link';
import Message from 'ui-library/components/Message';
import UnauthenticatedFooter from 'components/UnauthenticatedFooter';
import UnauthenticatedHeader from 'components/UnauthenticatedHeader';
import Divider from '@material-ui/core/Divider';

import forgotPasswordService from 'services/Auth/forgotPassword';

import {email} from 'utils/validator';

import {
  signinHeaderHeight,
  signinFooterHeight,
} from 'constants/layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 500px;
`;


const HeaderContainer = styled.div`
  min-height: ${signinHeaderHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  padding: 110px 0;
  min-height: 380px;
  height: calc(100vh - ${signinHeaderHeight}px - ${signinFooterHeight}px); 
  flex-grow: 1;
`;

const Content = styled.div`
  width: 100%;
`;

const FieldWithValidationGutter = styled.div`
  height: 80px;
`;

const FooterContainer = styled.div`
  min-height: ${signinFooterHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  margin-bottom: 16px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
`;

const ForgotPassword = () => {
  const [emailAddress, setEmailAddress] = React.useState('');
  const [isPristine, setIsPristine] = React.useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);

  return (
    <Container>
      <HeaderContainer>
        <UnauthenticatedHeader />
      </HeaderContainer>
      <Message
        variant='error'
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
        messageBody={
          <Typography variant="body">
            Something went wrong
          </Typography>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        width={480}
      />
      <Message
        variant='success'
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
        messageTitle="Email Sent"
        messageBody={
          <Typography variant="body">
            {/* Check your email and follow instructions to get access to your account */}
            If you have an account registered with this email address you will receive an email with the instructions you need to follow to get access to your account.
          </Typography>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        width={480}
      />
      <ContentContainer>
        <Typography variant="body" color="grey" fontWeight="bold">
          Enter your email and we'll send you instructions to get back into your acccount.
        </Typography>
        <Content>
          <FieldWithValidationGutter>
            <TextField
              fullWidth
              style={{paddingBottom: '16px'}}
              autoComplete="on"
              label="Email"
              value={emailAddress}
              placeholder="Enter your email"
              disabled={false}
              onChange={(event) => {
                setEmailAddress(event.target.value);
                setIsPristine(false);
              }}
              error={email(emailAddress) !== '' && !isPristine}
              helperText={!isPristine ? email(emailAddress, 'Please enter a valid email address!') : ''}
            />
          </FieldWithValidationGutter>

          <ButtonsContainer>
            <Button
              data-test-id="login-button"
              variant="primary"
              disabled={email(emailAddress) !== ''}
              onClick={async() => {
                try {
                  await forgotPasswordService(emailAddress);
                  setShowSuccessMessage(true);
                } catch(err) {
                  setShowErrorMessage(true);
                }
              }}>
              Send email
            </Button>
          </ButtonsContainer>

          <LinkContainer>
            <Typography variant="small" color="grey">
              Changed your mind?
            </Typography>
            &nbsp;
            <Link
              data-test-id="sign-in-link"
              value="/sign-in"
              label="Back to login"
              color="primary"
              variant="small"
              fontWeight="bold"
            />
            <Divider />
          </LinkContainer>

          <Divider />

          <LinkContainer>
            <Typography variant="small" color="grey">
              Don't have an account?
            </Typography>
            &nbsp;
            <Link
              data-test-id="sign-up-link"
              value="/sign-up"
              label="Sign Up."
              color="primary"
              variant="small"
              fontWeight="bold"
            />
          </LinkContainer>
        </Content>
      </ContentContainer>
      <FooterContainer>
        <UnauthenticatedFooter />
      </FooterContainer>
    </Container>
  );
};

export default ForgotPassword;
