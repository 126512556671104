function initNodeState() {
  return {
    node: {
      id: null,
      name: '',
      previousName: '',
      typeName: '',
      typeId: null,
      locationPath: [],
      recordId: null,
      recordName: '',
    },
    fieldDefinitionsById: {},
    fieldsByFieldDefinitionId: {},
    fieldDefinitionIds: [],
    nameIsEditable: false,
    successfullySavedNodeWithLocationPath: null,
    attachmentsById: {},
    attachmentIds: [],
    uploadsById: {},
    uploadIds: [],
    attachmentsSortDirection: 'descending',
    attachmentsSortCriteria: 'name',
    attachmentsRequireSort: false,
    linksAreLoading: false,
    nodeLinks: [],
    isLinkInfoLoading: false,
    linkInfo: {
      name: '',
      fields: [],
    },
    isLinkInfoOpen: false,
    nodeLinksAreLoaded: false,
    isLinkCandidatesListLoading: false,
    nodeLinkCandidates: [],
    nodeRecordCandidates: [],
    nodeLocationCandidateRoots: [],
    nodeLocation: {},
    tags: [],
    nodeTags: [],
  };
}

function nodeReducer(state, {type: actionType, payload}) {
  switch(actionType) {
    case 'HYDRATE_NODE': {
      const {
        node: {
          id,
          name,
          previousName,
          typeName,
          typeId,
          record,
          locationPath,
        },
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
        attachmentIds,
        attachmentsById,
      } = payload;

      return {
        ...state,
        node: {
          id,
          recordId: record.id,
          recordName: record.name,
          name,
          previousName,
          typeName,
          typeId,
          locationPath,
        },
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
        attachmentIds,
        attachmentsById,
      };
    }
    case 'HYDRATE_FIELDS': {
      const {
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
      } = payload;

      return {
        ...state,
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
      };
    }
    case 'CLEAR': {
      return {
        ...state,
        node: {
          typeId: null,
          typeName: '',
          recordId: null,
          recordName: '',
          name: '',
          successfullySavedNode: null,
        },
        fieldDefinitionsById: {},
        fieldsByFieldDefinitionId: {},
        fieldDefinitionIds: [],
        nameIsEditable: false,
        successfullySavedNode: null,
        attachmentsById: {},
        attachmentIds: [],
        uploadsById: {},
        uploadIds: [],
      };
    }
    case 'SET_SUCCESSFULLY_SAVED_NODE': {
      return {
        ...state,
        successfullySavedNodeWithLocationPath: {...payload},
      };
    }
    case 'SET_TYPE': {
      return {
        ...state,
        node: {
          typeId: payload.typeId,
          typeName: payload.typeName,
        },
      };
    }
    case 'SET_RECORD': {
      const {
        node,
      } = state;
      return {
        ...state,
        node: {
          ...node,
          recordId: payload.recordId,
          recordName: payload.recordName,
        },
      };
    }
    case 'SET_NAME': {
      const {
        node,
      } = state;
      return {
        ...state,
        node: {
          ...node,
          name: payload,
        },
      };
    }
    case 'SET_PREVIOUS_NAME': {
      const {
        node,
      } = state;
      return {
        ...state,
        node: {
          ...node,
          previousName: payload,
        },
      };
    }
    case 'SET_NAME_IS_EDITABLE': {
      return {
        ...state,
        nameIsEditable: payload,
      };
    }
    case 'SET_FIELD_IS_EDITABLE': {
      const {
        fieldDefinitionId,
        isEditable,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            isEditable,
          },
        },
      };
    }
    case 'SET_FIELD_VALUE': {
      const {
        fieldDefinitionId,
        value,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            value,
          },
        },
      };
    }
    case 'SET_INLINE_EDIT_MODE': {
      const {
        fieldDefinitionId,
        inlineEditMode,
      } = payload;

      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            inlineEditMode,
          },
        },
      };
    }
    case 'SET_FIELD_IS_SAVING': {
      const {
        fieldDefinitionId,
        isSaving,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            isSaving,
          },
        },
      };
    }
    case 'SET_FIELD_IS_FOCUSED': {
      const {
        fieldDefinitionId,
        isFocused,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            isFocused,
          },
        },
      };
    }
    case 'SET_FIELD_PREVIOUS_VALUE': {
      const {
        fieldDefinitionId,
        value,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            previousValue: value,
          },
        },
      };
    }
    case 'REVERT_FIELDS': {
      const {
        revertIds,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
      } = state;

      const revertedFieldsByFieldDefinitionId = {};
      revertIds.forEach(fieldDefinitionId => {
        revertedFieldsByFieldDefinitionId[fieldDefinitionId] = {
          ...fieldsByFieldDefinitionId[fieldDefinitionId],
          value: fieldsByFieldDefinitionId[fieldDefinitionId].previousValue,
          isEditable: false,
          isSaving: false,
          isFocused: false,
          errorMessage: '',
          isPristine: true,
        };
      });

      return ({
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          ...revertedFieldsByFieldDefinitionId,
        },
      });
    }
    case 'REVERT_NAME': {
      const {
        node,
        node: {
          previousName,
        },
      } = state;

      return ({
        ...state,
        node: {
          ...node,
          name: previousName,
        },
      });
    }
    case 'SET_ERROR_MESSAGE': {
      const {
        fieldDefinitionId,
        errorMessage,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            errorMessage,
          },
        },
      };
    }
    case 'PUSH_UPLOAD': {
      const {
        uploadIds,
        uploadsById,
      } = state;

      let nextUploadId = 0;

      while (uploadIds.includes(nextUploadId)) {
        nextUploadId++;
      }

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [nextUploadId]: {
            id: nextUploadId,
            ...payload,
          },
        },
        uploadIds: [
          ...uploadIds,
          nextUploadId,
        ],
      };
    }
    case 'REMOVE_UPLOAD': {
      const {
        id,
      } = payload;

      const {
        uploadsById: {
          [id]: excludedUpload,
          ...remainingUploads
        },
        uploadIds,
      } = state;

      return {
        ...state,
        uploadsById: remainingUploads,
        uploadIds: [
          ...uploadIds.slice(0, uploadIds.indexOf(id)),
          ...uploadIds.slice(uploadIds.indexOf(id) + 1),
        ],
      };
    }
    case 'SET_UPLOAD_ERROR': {
      const {
        id,
        errorMessage,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            errorMessage,
          },
        },
      };
    }
    case 'UPDATE_BYTES_TRANSFERED': {
      const {
        id,
        bytesTransferred,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            bytesTransferred,
          },
        },
      };
    }
    case 'UPDATE_IS_UPLOADING': {
      const {
        id,
        isUploading,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            isUploading,
          },
        },
      };
    }
    case 'UPDATE_UPLOAD_PROGRESS': {
      const {
        id,
        progress,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            progress,
          },
        },
      };
    }
    case 'ADD_UPLOAD_REQUEST': {
      const {
        id,
        request,
      } = payload;

      const {
        uploadsById,
        uploadsById: {
          [id]: currentUpload,
        },
      } = state;

      return {
        ...state,
        uploadsById: {
          ...uploadsById,
          [id]: {
            ...currentUpload,
            request,
          },
        },
      };
    }
    case 'PUSH_ATTACHMENT': {
      return {
        ...state,
        attachmentsById: {
          ...state.attachmentsById,
          [payload.id]: {
            ...payload,
          },
        },
        attachmentIds: [
          payload.id,
          ...state.attachmentIds,
        ],
      };
    }
    case 'REMOVE_ATTACHMENT': {
      const {
        [payload.id]: excludedAttachment,
        ...remainingAttachments
      } = state.attachmentsById;

      return {
        ...state,
        attachmentsById: remainingAttachments,
        attachmentIds: [
          ...state.attachmentIds.slice(0, state.attachmentIds.indexOf(payload.id)),
          ...state.attachmentIds.slice(state.attachmentIds.indexOf(payload.id) + 1),
        ],
      };
    }
    case 'SET_ATTACHMENT_TITLE': {
      const {
        id,
        title,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            title,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_PREVIOUS_TITLE': {
      const {
        id,
        previousTitle,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            previousTitle,
          },
        },
      };
    }
    case 'SET_DISPLAY_DELETE_DIALOG': {
      const {
        id,
        isDeleteDialogVisible,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isDeleteDialogVisible,
          },
        },
      };
    }
    case 'SET_IS_ATTACHMENT_TITLE_EDITABLE': {
      const {
        id,
        isEditable,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isEditable,
          },
        },
      };
    }
    case 'SET_IS_ATTACHMENT_TITLE_SAVING': {
      const {
        id,
        isTitleSaving,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isTitleSaving,
          },
        },
      };
    }
    case 'SET_DOWNLOAD_MENU_ANCHOR': {
      const {
        id,
        anchorEl,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            anchorEl,
          },
        },
      };
    }
    case 'SET_IS_DOWNLOAD_MENU_OPEN': {
      const {
        id,
        isMenuOpen,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isMenuOpen,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_TITLE_ERROR': {
      const {
        id,
        titleError,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            titleError,
          },
        },
      };
    }
    case 'SET_IS_ATTACHMENT_DOWNLOADING': {
      const {
        id,
        isDownloading,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            isDownloading,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_DOWNLOAD_REQUEST': {
      const {
        id,
        downloadRequest,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            downloadRequest,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_THUMBNAIL_BLOB_URL': {
      const {
        id,
        thumbnailBlobURL,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            thumbnailBlobURL,
          },
        },
      };
    }
    case 'SET_ATTACHMENT_DOWNLOAD_PROGRESS': {
      const {
        id,
        progress,
      } = payload;

      const {
        attachmentsById,
        attachmentsById: {
          [id]: currentDownload,
        },
      } = state;

      return {
        ...state,
        attachmentsById: {
          ...attachmentsById,
          [id]: {
            ...currentDownload,
            progress,
          },
        },
      };
    }
    case 'SET_SORT_CRITERIA': {
      return {
        ...state,
        attachmentsSortCriteria: payload,
      };
    }
    case 'SET_SORT_DIRECTION': {
      return {
        ...state,
        attachmentsSortDirection: payload,
      };
    }
    case 'SET_ATTACHMENT_IDS': {
      return {
        ...state,
        attachmentIds: payload,
      };
    }
    case 'SET_ATTACHMENTS_REQUIRE_SORT': {
      return {
        ...state,
        attachmentsRequireSort: payload,
      };
    }
    case 'SET_LINKS_ARE_LOADING': {
      return {
        ...state,
        linksAreLoading: payload,
      };
    }
    case 'SET_NODE_LINKS': {
      return {
        ...state,
        nodeLinks: payload,
      };
    }
    case 'SET_IS_LINK_INFO_LOADING': {
      return {
        ...state,
        isLinkInfoLoading: payload,
      };
    }
    case 'SET_LINK_INFO': {
      return {
        ...state,
        linkInfo: payload,
      };
    }
    case 'SET_IS_LINK_INFO_OPEN': {
      return {
        ...state,
        isLinkInfoOpen: payload,
      };
    }
    case 'SET_NODE_LINKS_ARE_LOADED': {
      return {
        ...state,
        nodeLinksAreLoaded: payload,
      };
    }
    case 'REMOVE_LINK': {
      const {
        nodeLinks,
      } = state;

      return {
        ...state,
        nodeLinks: [
          ...nodeLinks.slice(0, payload),
          ...nodeLinks.slice(payload + 1),
        ],
      };
    }
    case 'ADD_LINK': {
      const {
        nodeLinks,
      } = state;

      return {
        ...state,
        nodeLinks: [
          ...nodeLinks,
          payload,
        ],
      };
    }
    case 'SET_IS_LINK_CANDIDATES_LIST_LOADING': {
      return {
        ...state,
        isLinkCandidatesListLoading: payload,
      };
    }
    case 'SET_NODE_LINK_CANDIDATES': {
      return {
        ...state,
        nodeLinkCandidates: payload,
      };
    }
    case 'SET_LINK_CANDIDATE': {
      const {
        value,
        candidateIndex,
        linkId,
      } = payload;
      const {
        nodeLinkCandidates,
        nodeLinkCandidates: {
          [candidateIndex]: selectedCandidate,
        },
      } = state;

      return {
        ...state,
        nodeLinkCandidates: [
          ...nodeLinkCandidates.slice(0, candidateIndex),
          {
            ...selectedCandidate,
            linked: value,
            linkId,
          },
          ...nodeLinkCandidates.slice(candidateIndex + 1),
        ],
      };
    }
    case 'SET_CANDIDATE_OPERATION_IN_PROGRESS': {
      const {
        value,
        candidateIndex,
      } = payload;
      const {
        nodeLinkCandidates,
        nodeLinkCandidates: {
          [candidateIndex]: selectedCandidate,
        },
      } = state;

      return {
        ...state,
        nodeLinkCandidates: [
          ...nodeLinkCandidates.slice(0, candidateIndex),
          {
            ...selectedCandidate,
            operationInProgress: value,
          },
          ...nodeLinkCandidates.slice(candidateIndex + 1),
        ],
      };
    }
    case 'SET_IS_LINK_DELETING': {
      const {
        value,
        index,
      } = payload;

      const {
        nodeLinks,
      } = state;

      return {
        ...state,
        nodeLinks: [
          ...nodeLinks.slice(0, index),
          {
            ...nodeLinks[index],
            isLinkDeleting: value,
          },
          ...nodeLinks.slice(index + 1),
        ],
      };
    }
    case 'CLEAR_LINKS_INFO': {
      return {
        ...state,
        linksAreLoading: false,
        nodeLinks: [],
        isLinkInfoLoading: false,
        linkInfo: {
          name: '',
          fields: [],
        },
        isLinkInfoOpen: false,
        nodeLinksAreLoaded: false,
        isLinkCandidatesListLoading: false,
        nodeLinkCandidates: [],
      };
    }
    case 'SET_LOCATION_CANDIDATE_ROOTS': {
      return {
        ...state,
        nodeLocationCandidateRoots: payload,
      };
    }
    case 'SET_LOCATION': {
      return {
        ...state,
        nodeLocation: payload,
      };
    }
    case 'SET_LOCATION_PATH': {
      const {
        node,
      } = state;
      return {
        ...state,
        node: {
          ...node,
          locationPath: payload,
        },
      };
    }
    case 'SET_TAGS': {
      return {
        ...state,
        tags: payload,
      };
    }
    case 'SET_NODE_TAGS': {
      return {
        ...state,
        nodeTags: payload,
      };
    }
    case 'ADD_NODE_TAG': {
      const {
        nodeTags,
      } = state;

      return {
        ...state,
        nodeTags: [
          ...nodeTags,
          payload,
        ],
      };
    }
    case 'ADD_TAG': {
      const {
        tags,
      } = state;

      return {
        ...state,
        tags: [
          ...tags,
          payload,
        ],
      };
    }
    case 'REMOVE_NODE_TAG': {
      const {
        nodeTags,
      } = state;
      const index = nodeTags.map(tag => tag.name).indexOf(payload);

      return {
        ...state,
        nodeTags: [
          ...nodeTags.slice(0, index),
          ...nodeTags.slice(index + 1),
        ],
      };
    }
    default:
      throw new Error(`Unknown item action "${actionType}"!`);
  }
}

export {
  initNodeState,
  nodeReducer,
};
