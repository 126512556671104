import React from 'react';
import {useLocation} from 'react-router-dom';
import PerspectiveContext from 'contexts/Perspective';

import genPerspectives from 'services/Perspective/genPerspectives';
import genCurrentSubscription from 'services/Subscription/genCurrentSubscription';
import UserContext from 'contexts/User';

import NodeListContext from 'contexts/NodeList';
import SubscriptionContext from 'contexts/Subscription';
import PushNotificationsContext from 'contexts/PushNotifications';

import FullScreenSpinner from 'components/chrome/FullScreenSpinner';

import getSearchParam from 'utils/getSearchParam';

import addNotificationsDevice from 'services/Notifications/addNotificationsDevice';

import {
  initSubscriptionState,
  subscriptionReducer,
} from 'reducers/Subscription';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import UnauthenticatedApplication from 'components/UnauthenticatedApplication';
import UserInterface from 'components/UserInterface';

const STRIPE_API_TOKEN = process.env.REACT_APP_STRIPE_API_TOKEN;
const stripePromise = loadStripe(STRIPE_API_TOKEN);

const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
const ONESIGNAL_SAFARI_WEB_ID = process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID;

const AuthenticatedApplication = () => {

  const [isInitialized, setIsInitialized] = React.useState(false);
  const [availablePerspectives, setAvailablePerspectives] = React.useState([]);
  const [currentPerspective, setCurrentPerspective] = React.useState(null);
  const [fatalError, setFatalError] = React.useState(null);

  const [reloadList, setReloadList] = React.useState(false);

  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } = React.useContext(UserContext);

  const {
    oneSignalInitialization,
    setOneSignalInitialization,
    oneSignalPlayerId,
    setOneSignalPlayerId,
    notificationData,
    setNotificationData,
    setBrowserNotifications,
    setDeviceListUpdated,
  } = React.useContext(PushNotificationsContext);

  const [subscriptionState, dispatchSubscriptionAction] = React.useReducer(
    subscriptionReducer,
    null,
    initSubscriptionState,
  );

  const {
    currentSubscriptionName,
    currentSubscriptionDescription,
    currentSubscriptionStatus,
    currentSubscriptionPrice,
    currentSubscriptionCycleDuration,
    currentSubscriptionRemainingDays,
    currentSubscriptionIsFreeTrial,
    currentSubscriptionIsRenewable,
    currentSubscriptionAutoRenew,
    currentSubscriptionCycleDurationInYears,
    currentSubscriptionAutoRenewDate,

    creditCardId,
    creditCardBrand,
    creditCardLast4,
    creditCardExpirationDate,

    paymentInvoicesById,
    paymentInvoiceIds,
  } = subscriptionState;

  const location = useLocation();
  if (getSearchParam(location)('passwordResetToken')) {
    setAuthToken(null);
    setAuthenticationStatus('not-authenticated');
    localStorage.removeItem('authToken');
  }

  React.useEffect(() => {
    async function init() {
      try {
        const availablePerspectives = await genPerspectives(authToken);
        setAvailablePerspectives(availablePerspectives);
        setCurrentPerspective(availablePerspectives[0]);
        const currentSubscription = await genCurrentSubscription(authToken, availablePerspectives[0].id);
        dispatchSubscriptionAction({
          type: 'HYDRATE_CURRENT_SUBSCRIPTION',
          payload: {
            ...currentSubscription,
          },
        });
        setIsInitialized(true);
      } catch (error) {
        const {
          name,
        } = error;
        switch (name) {
          case 'AuthorizationError':
            setAuthToken(null);
            setAuthenticationStatus('not-authenticated');
            localStorage.removeItem('authToken');
            break;
          default:
            setFatalError(error);
        }
      }
    }
    if (authToken) {
      init();
    }
  }, [authToken, currentSubscriptionCycleDuration, setAuthToken, setAuthenticationStatus]);

  React.useEffect(() => {
    const initializeOneSignal = () => {
      setOneSignalInitialization('in progress');
      window.OneSignal.push(() => {
        // initialize the OneSignal plugin
        window.OneSignal.init({
          appId: ONESIGNAL_APP_ID,
          safari_web_id: ONESIGNAL_SAFARI_WEB_ID,
        });

        // set the plyaer id in the state;
        window.OneSignal.getUserId((id) => {
          setOneSignalPlayerId(id);
        });

        // check if push notifications are enabled and set the browserNotifications flag
        window.OneSignal.isPushNotificationsEnabled((isEnabled) => {
          if (isEnabled) {
            localStorage.setItem('browserNotifications', 'granted');
            setBrowserNotifications('granted');
          }
        });

        // event handler for permission change in Native Prompt
        window.OneSignal.on('notificationPermissionChange', function(permissionChange) {
          const currentPermission = permissionChange.to;
          localStorage.setItem('browserNotifications', currentPermission);
          setBrowserNotifications(currentPermission);
        });

        // event handler for subscription change
        window.OneSignal.on('subscriptionChange', (isSubscribed) => {
          if (isSubscribed) {
            window.OneSignal.getUserId(async(id) => {
              if (id) {
                if (!oneSignalPlayerId) {
                  setOneSignalPlayerId(id);
                }
                try {
                  await addNotificationsDevice(authToken, id);
                  setDeviceListUpdated(true);
                } catch (error) {
                  const {
                    name,
                  } = error;
                  switch (name) {
                    case 'AuthorizationError':
                      setAuthToken(null);
                      setAuthenticationStatus('not-authenticated');
                      localStorage.removeItem('authToken');
                      break;
                    default:
                      setFatalError(error);
                  }
                }
              }
            });
          }
        });
      });
      setOneSignalInitialization('initialized');
    };
    if (isInitialized && oneSignalInitialization === 'not-initialized') {
      initializeOneSignal();
    }
  }, [authToken, isInitialized, notificationData, oneSignalInitialization, oneSignalPlayerId, setAuthToken, setAuthenticationStatus, setBrowserNotifications, setDeviceListUpdated, setNotificationData, setOneSignalInitialization, setOneSignalPlayerId]);

  if (fatalError) {
    throw fatalError;
  }

  if (authToken === null) {
    return (
      <UnauthenticatedApplication/>
    );
  }

  if (!isInitialized) {
    return (
      <FullScreenSpinner/>
    );
  }

  return (
    <Elements stripe={stripePromise}>
      <PerspectiveContext.Provider
        value={{
          availablePerspectives,
          currentPerspective,
          setCurrentPerspective,
        }}>
        <SubscriptionContext.Provider
          value={{
            currentSubscriptionName,
            currentSubscriptionDescription,
            currentSubscriptionStatus,
            currentSubscriptionPrice,
            currentSubscriptionCycleDuration,
            currentSubscriptionRemainingDays,
            currentSubscriptionIsFreeTrial,
            currentSubscriptionIsRenewable,
            currentSubscriptionAutoRenew,
            currentSubscriptionCycleDurationInYears,
            currentSubscriptionAutoRenewDate,

            creditCardId,
            creditCardBrand,
            creditCardLast4,
            creditCardExpirationDate,

            paymentInvoicesById,
            paymentInvoiceIds,
            dispatchSubscriptionAction,
          }}>
          <NodeListContext.Provider value={{
            reloadList,
            setReloadList,
          }}>
            <UserInterface />
          </NodeListContext.Provider>
        </SubscriptionContext.Provider>
      </PerspectiveContext.Provider>
    </Elements>
  );
};

export default AuthenticatedApplication;
