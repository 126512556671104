import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {GET} from '../verbs';

export default async function GET__notifications_push(authToken) {
  return await GET(
    '/notifications/push',
    configWithAuthorization(defaultConfig(), authToken),
  );
}
