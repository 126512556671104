import DEL__nodes_ID_tags_ID from 'network-layer/endpoints/DEL__nodes_ID_tags_ID';

function responseAdapter(response) {
  return response;
}

export default async function deleteNodeTag(params) {
  const response = await DEL__nodes_ID_tags_ID(params);

  return responseAdapter(response);
}
