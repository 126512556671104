import React from 'react';

import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import Typography from 'ui-library/components/Typography';

const ListItemLocationWrapper = React.memo(({
  locationPath,
}) => {
  return (
    <ListItemContentSegment
      weight={4}
    >
      <ListItemText>
        <Typography
          variant="small"
          color={locationPath ? locationPath.length ? 'black' : 'grey' : 'grey'}
        >
          {locationPath ? locationPath.length ? locationPath.join(' / ') : 'No Location' : 'No Location'}
        </Typography>
      </ListItemText>
    </ListItemContentSegment>
  );
}, (prevProps, nextProps) => {
  return true;
});

export default ListItemLocationWrapper;
