import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {PUT} from '../verbs';

export default async function PUT__notifications_push(authToken, params) {

  return await PUT(
    '/notifications/push',
    configWithAuthorization(defaultConfig(), authToken),
    {
      ...params,
    },
  );
}
