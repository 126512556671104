import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import NotificationsIcon from '@material-ui/icons/NotificationsRounded';
import Typography from 'ui-library/components/Typography';

const useStyles = makeStyles({
  root: {
    display: theme.notificationsCount.display,
    borderRadius: theme.notificationsCount.borderRadius,
    width: props => props.count > 0 && theme.notificationsCount.width,
    height: props => props.count > 0 && theme.notificationsCount.height,
    backgroundColor: props => props.count > 0 ?
      theme.notificationsCount.activeBackgroundColor :
      theme.notificationsCount.backgroundColor,
    alignItems: theme.notificationsCount.alignItems,
    justifyContent: theme.notificationsCount.justifyContent,
  },
  icon: {
    color: props => props.count ?
      theme.notificationsCount.icon.activeColor :
      theme.notificationsCount.icon.color,
    width: theme.notificationsCount.icon.width,
    height: theme.notificationsCount.icon.height,
  },
});

const NotificationsCount = ({
  onClick,
  count,
  ...otherProps
}) => {

  const classes = useStyles({count});

  return (
    <div className={classes.root} onClick={onClick} {...otherProps}>
      <NotificationsIcon className={classes.icon}/>
      {
        count > 0 &&
        <Typography variant="caption" fontWeight="bold" color="tertiary">
          {count}
        </Typography>
      }
    </div>
  );
};

NotificationsCount.propTypes = {
  onClick: PropTypes.func,
  count: PropTypes.number,
};

export default NotificationsCount;
