import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    display: theme.notificationActions.root.display,
    justifyContent: theme.notificationActions.root.justifyContent,
    width: theme.notificationActions.root.width,
  },
});

const NotificationActions = ({
  children,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

NotificationActions.propTypes = {
  children: PropTypes.node,
};

export default NotificationActions;
