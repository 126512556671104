import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../verbs';

export default async function DELETE__payments_paymentMethod(authToken, perspectiveId) {
  const url = '/payments/payment-method';

  return await DELETE(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
