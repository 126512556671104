import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';

const NotificationSuggestedAction = ({
  event,
  onClick,
}) => {

  return (
    <div onClick={onClick}>
      <Typography variant="small" color="primary">
        {actionLabelByEvent(event)}
      </Typography>
    </div>
  );

};

NotificationSuggestedAction.propTypes = {
  onClick: PropTypes.func,
};

function actionLabelByEvent(event) {
  switch(event) {
    case 'node emitted notification': {
      return 'Go to Item';
    }
    case 'permissions granted on Record by partner or third party': {
      return 'Go to Record';
    }
    case 'permissions granted on Node by partner or third party': {
      return 'Go to Item';
    }
    case 'connection established': {
      return 'Go to Connection';
    }
    case 'conversation comment node': {
      return 'Go to conversation';
    }
    case 'conversation comment node edit': {
      return 'Go to conversation';
    }
    case 'conversation comment record': {
      return 'Go to conversation';
    }
    case 'conversation comment record edit': {
      return 'Go to conversation';
    }
    case 'conversation comment connection': {
      return 'Go to conversation';
    }
    case 'conversation comment connection edit': {
      return 'Go to conversation';
    }
    default:
      throw new Error(`Cannot generate action label! Unknown notification event "${event}"!`);
  }
}

export default NotificationSuggestedAction;
