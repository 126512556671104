function initDataModel() {
  return {
    recordTypesById: {},
    recordTypeIds: [],

    typesById: {},
    typeIds: [],

    recordTypes_Types: {},

    typeCategoriesById: {},
    typeCategoryIds: [],

    typeLocationGraph: {},

    nodeLinkDefinitionsById: {},
    nodeLinkDefinitionIds: [],

    typeRelationshipsById: {},
    typeRelationshipIds: [],

    propertyConfigurationHintsById: {},
    propertyConfigurationHintIds: [],

    virtualLocationTreeNodeHintsById: {},
    virtualLocationTreeNodeHintIds: [],

    notificationPipelinesById: {},
    notificationPipelineIds: [],

    fieldWatchersById: {},
    fieldWatcherIds: [],

    usersById: {},
    userIds: [],

    ehrHubTypes: [],
    ehrHubTypesById: {},
    ehrHubTypeIds: [],
  };
}

export default initDataModel;
