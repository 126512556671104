import React from 'react';
import Pill from '../Pill';
import PillPlus from '../PillPlus';
import PillMinus from '../PillMinus';
import PillContainer from '../PillContainer';
import HintContainer from '../HintContainer';
import HintEditButton from '../HintEditButton';
import Typography from 'ui-library/components/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import selectVirtualLocationTreeNodeHints from '../../API/selectVirtualLocationTreeNodeHints';
import selectVirtualLocationTreeNodes from '../../API/selectVirtualLocationTreeNodes';
import deriveVirtualLocationTreeNodeHintLocationDepth from '../../API/deriveVirtualLocationTreeNodeHintLocationDepth';
import createVirtualLocationTreeNode from '../../API/createVirtualLocationTreeNode';
import deriveNextVirtualLocationTreeNodeId from '../../API/deriveNextVirtualLocationTreeNodeId';
import selectVirtualLocationTreeNodesByHintIdAndLocation from '../../API/selectVirtualLocationTreeNodesByHintIdAndLocation';
import selectFirstVirtualLocationTreeNodeByHintId from '../../API/selectFirstVirtualLocationTreeNodeByHintId';
import selectLastVirtualLocationTreeNodeByHintId from '../../API/selectLastVirtualLocationTreeNodeByHintId';
import selectVirtualLocationTreeNodeHintById from '../../API/selectVirtualLocationTreeNodeHintById';
import selectVirtualLocationTreeNodeHintChildren from '../../API/selectVirtualLocationTreeNodeHintChildren';
import selectVirtualLocationTreeNodeLocationPath from '../../API/selectVirtualLocationTreeNodeLocationPath';
import selectVirtualLocationTreeNodeLocationDescendants from '../../API/selectVirtualLocationTreeNodeLocationDescendants';
import selectVirtualLocationTreeTitle from '../../API/selectVirtualLocationTreeTitle';
import selectVirtualLocationTreeDescription from '../../API/selectVirtualLocationTreeDescription';
import sortVirtualLocationTreeNodeHintsByPrevalence from '../../API/sortVirtualLocationTreeNodeHintsByPrevalence';
import selectExpandedVirtualLocationTreeNodeHints from '../../API/selectExpandedVirtualLocationTreeNodeHints';

const VirtualLocationTreeCrossSection = ({
  locationDepth,
  configurationState,
  dispatchPropertyConfigurationAction,
  UIState,
  dispatchUIAction,
}) => {

  if (locationDepth === 0) {
    return (
      <div>
        <Typography
          variant="h5"
          fontWeight="bold">
          {selectVirtualLocationTreeTitle(configurationState)}
        </Typography>
        <br/>
        <Typography
          variant="caption"
          color="grey">
          {selectVirtualLocationTreeDescription(configurationState)}
        </Typography>
        <br/><br/>

        {
          selectVirtualLocationTreeNodeHints(configurationState)
            .filter(virtualLocationTreeNodeHint => {
              return (
                deriveVirtualLocationTreeNodeHintLocationDepth(
                  configurationState,
                  virtualLocationTreeNodeHint,
                ) === 0
              );
            })
            .filter(virtualLocationTreeNodeHint => {
              if (selectExpandedVirtualLocationTreeNodeHints(UIState).includes(null)) {
                return true;
              }
              if (['ubiquitous', 'common'].includes(virtualLocationTreeNodeHint.prevalence)) {
                return true;
              }
              return false;
            })
            .sort(sortVirtualLocationTreeNodeHintsByPrevalence)
            .map(virtualLocationTreeNodeHint => {
              const {
                id: virtualLocationTreeNodeHintId,
                typeId,
                typeName,
              } = virtualLocationTreeNodeHint;

              const virtualLocationTreeNodesByHintId = selectVirtualLocationTreeNodesByHintIdAndLocation(configurationState, virtualLocationTreeNodeHintId, null);

              return (
                <PillContainer
                  key={virtualLocationTreeNodeHintId}>
                  <Pill
                    variant={virtualLocationTreeNodesByHintId.length ? 'highlighted' : 'plain'}>
                    <PillMinus
                      onClick={() => {
                        const firstVirtualLocationTreeNodeByHintId = selectFirstVirtualLocationTreeNodeByHintId(configurationState, virtualLocationTreeNodeHintId);

                        if (firstVirtualLocationTreeNodeByHintId === null) {
                          return;
                        }

                        const {
                          virtualId,
                        } = firstVirtualLocationTreeNodeByHintId;

                        const locationDescendants = [];
                        selectVirtualLocationTreeNodeLocationDescendants(configurationState, firstVirtualLocationTreeNodeByHintId, locationDescendants);

                        dispatchPropertyConfigurationAction({
                          type: 'REMOVE_VIRTUAL_LOCATION_TREE_NODES',
                          payload: [
                            virtualId,
                            ...locationDescendants.map(virtualLocationTreeNode => virtualLocationTreeNode.virtualId),
                          ],
                        });
                      }}
                      data-test-id="configure-property-decrement-node-count-button"
                    />
                    &nbsp;&nbsp;
                    <Typography
                      variant="small">
                      {typeName}: {virtualLocationTreeNodesByHintId.length}
                    </Typography>
                    &nbsp;&nbsp;
                    <PillPlus
                      onClick={() => {
                        dispatchPropertyConfigurationAction({
                          type: 'ADD_VIRTUAL_LOCATION_TREE_NODE',
                          payload: createVirtualLocationTreeNode(
                            typeId,
                            virtualLocationTreeNodeHint,
                            deriveNextVirtualLocationTreeNodeId(configurationState),
                            null,
                          ),
                        });
                      }}
                      data-test-id="configure-property-increment-node-count-button"
                    />
                  </Pill>
                </PillContainer>
              );
            })
        }
        {
          selectVirtualLocationTreeNodeHints(configurationState)
            .filter(virtualLocationTreeNodeHint => {
              return (
                deriveVirtualLocationTreeNodeHintLocationDepth(
                  configurationState,
                  virtualLocationTreeNodeHint,
                ) === 0
              );
            })
            .filter(virtualLocationTreeNodeHint => {
              return virtualLocationTreeNodeHint.prevalence === 'rare';
            })
            .length > 0 &&
            <PillContainer>
              {
                selectExpandedVirtualLocationTreeNodeHints(UIState).includes(null) ?
                  <Pill
                    data-test-id="configure-property-expand-collapse-hints-button"
                    variant="ghost"
                    onClick={() => {
                      dispatchUIAction({
                        type: 'COLLAPSE_VIRTUAL_LOCATION_TREE_NODE_HINT',
                        payload: null,
                      });
                    }}>
                    <ChevronLeftIcon/>
                    &nbsp;
                    <Typography
                      variant="small">
                    Less
                    </Typography>
                    &nbsp;
                  </Pill>
                  :
                  <Pill
                    variant="ghost"
                    onClick={() => {
                      dispatchUIAction({
                        type: 'EXPAND_VIRTUAL_LOCATION_TREE_NODE_HINT',
                        payload: null,
                      });
                    }}>
                    <ChevronRightIcon/>
                    &nbsp;
                    <Typography
                      variant="small">
                      More
                    </Typography>
                    &nbsp;
                  </Pill>
              }
            </PillContainer>
        }
      </div>
    );
  }

  return (
    <div>
      {
        selectVirtualLocationTreeNodes(configurationState)
          .filter(virtualLocationTreeNode => {
            const {
              hintId,
            } = virtualLocationTreeNode;
            const virtualLocationTreeNodeHint = selectVirtualLocationTreeNodeHintById(configurationState, hintId);
            return deriveVirtualLocationTreeNodeHintLocationDepth(configurationState, virtualLocationTreeNodeHint) === locationDepth - 1;
          })
          .sort((node1, node2) => {
            return sortVirtualLocationTreeNodeHintsByPrevalence(
              selectVirtualLocationTreeNodeHintById(configurationState, node1.hintId),
              selectVirtualLocationTreeNodeHintById(configurationState, node2.hintId),
            );
          })
          .map(virtualLocationTreeNode => {
            const {
              virtualId,
              typeName,
              name,
              hintId,
            } = virtualLocationTreeNode;

            const stringLocationPath =
              selectVirtualLocationTreeNodeLocationPath(configurationState, virtualLocationTreeNode)
                .map(virtualLocationTreeNode => virtualLocationTreeNode.name)
                .join(' :: ');

            return (
              <HintContainer
                key={virtualId}>
                <Typography
                  variant="h5"
                  fontWeight="bold">
                  {`${stringLocationPath}${stringLocationPath && ' :: '}${name}`}
                </Typography>
                &nbsp;&nbsp;
                <HintEditButton
                  onClick={() => {
                    dispatchUIAction({
                      type: 'EDIT_VIRTUAL_LOCATION_TREE_NODE',
                      payload: {
                        virtualLocationTreeNodeId: virtualId,
                        virtualLocationTreeNodeName: name,
                        virtualLocationTreeNodeTypeName: typeName,
                      },
                    });
                  }}
                />
                <br/>
                <Typography
                  variant="caption"
                  color="grey">
                  {selectVirtualLocationTreeNodeHintById(configurationState, hintId).text}
                </Typography>
                <br/><br/>
                {
                  selectVirtualLocationTreeNodeHintChildren(
                    configurationState,
                    selectVirtualLocationTreeNodeHintById(configurationState, hintId),
                  )
                    .filter(virtualLocationTreeNodeHint => {
                      if (selectExpandedVirtualLocationTreeNodeHints(UIState).includes(hintId)) {
                        return true;
                      }
                      if (['ubiquitous', 'common'].includes(virtualLocationTreeNodeHint.prevalence)) {
                        return true;
                      }
                      return false;
                    })
                    .sort(sortVirtualLocationTreeNodeHintsByPrevalence)
                    .map(virtualLocationTreeNodeHint => {
                      const {
                        id: virtualLocationTreeNodeHintId,
                        typeId,
                        typeName,
                      } = virtualLocationTreeNodeHint;

                      const virtualLocationTreeNodesByHintId = selectVirtualLocationTreeNodesByHintIdAndLocation(configurationState, virtualLocationTreeNodeHintId, virtualId);

                      return (
                        <PillContainer
                          key={virtualLocationTreeNodeHintId}>
                          <Pill
                            variant={virtualLocationTreeNodesByHintId.length ? 'highlighted' : 'plain'}>
                            <PillMinus
                              onClick={() => {
                                const lastVirtualLocationTreeNodeByHintId = selectLastVirtualLocationTreeNodeByHintId(configurationState, virtualLocationTreeNodeHintId);

                                if (lastVirtualLocationTreeNodeByHintId === null) {
                                  return;
                                }

                                const {
                                  virtualId,
                                } = lastVirtualLocationTreeNodeByHintId;

                                const locationDescendants = [];
                                selectVirtualLocationTreeNodeLocationDescendants(configurationState, lastVirtualLocationTreeNodeByHintId, locationDescendants);

                                dispatchPropertyConfigurationAction({
                                  type: 'REMOVE_VIRTUAL_LOCATION_TREE_NODES',
                                  payload: [
                                    virtualId,
                                    ...locationDescendants.map(virtualLocationTreeNode => virtualLocationTreeNode.virtualId),
                                  ],
                                });
                              }}
                              data-test-id="configure-property-decrement-node-count-button"
                            />
                            &nbsp;&nbsp;
                            {typeName}: {virtualLocationTreeNodesByHintId.length}
                            &nbsp;&nbsp;
                            <PillPlus
                              onClick={() => {
                                dispatchPropertyConfigurationAction({
                                  type: 'ADD_VIRTUAL_LOCATION_TREE_NODE',
                                  payload: createVirtualLocationTreeNode(
                                    typeId,
                                    virtualLocationTreeNodeHint,
                                    deriveNextVirtualLocationTreeNodeId(configurationState),
                                    virtualId,
                                  ),
                                });
                              }}
                              data-test-id="configure-property-increment-node-count-button"
                            />
                          </Pill>
                        </PillContainer>
                      );
                    })
                }
                {
                  selectVirtualLocationTreeNodeHintChildren(
                    configurationState,
                    selectVirtualLocationTreeNodeHintById(configurationState, hintId),
                  )
                    .filter(virtualLocationTreeNodeHint => {
                      return virtualLocationTreeNodeHint.prevalence === 'rare';
                    })
                    .length > 0 &&
                    <PillContainer>
                      {
                        selectExpandedVirtualLocationTreeNodeHints(UIState).includes(hintId) ?
                          <Pill
                            data-test-id="configure-property-expand-collapse-hints-button"
                            variant="ghost"
                            onClick={() => {
                              dispatchUIAction({
                                type: 'COLLAPSE_VIRTUAL_LOCATION_TREE_NODE_HINT',
                                payload: hintId,
                              });
                            }}>
                            <ChevronLeftIcon/>
                            &nbsp;
                            <Typography
                              variant="small">
                              Less
                            </Typography>
                            &nbsp;
                          </Pill>
                          :
                          <Pill
                            variant="ghost"
                            onClick={() => {
                              dispatchUIAction({
                                type: 'EXPAND_VIRTUAL_LOCATION_TREE_NODE_HINT',
                                payload: hintId,
                              });
                            }}>
                            <ChevronRightIcon/>
                            &nbsp;
                            <Typography
                              variant="small">
                              More
                            </Typography>
                            &nbsp;
                          </Pill>
                      }
                    </PillContainer>
                }
              </HintContainer>
            );
          })
      }
    </div>
  );
};

export default VirtualLocationTreeCrossSection;
