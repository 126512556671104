import defaultConfig from '../config/defaultConfig';
import configWithContentType from '../config/configWithContentType';
import {POST} from '../verbs';

export default async function POST__users(params) {
  const {
    email,
    password,
    firstName,
    lastName,
    groupType,
    groupName,
  } = params;

  const requestObject = {
    email,
    password,
    firstName,
    lastName,
    groupType,
  };

  if (groupName) {
    requestObject.groupName = groupName;
  }

  return await POST(
    '/users',
    configWithContentType(defaultConfig(), 'application/json'),
    {
      ...requestObject,
    },
  );
}
