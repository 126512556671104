import React from 'react';
import styled from '@emotion/styled';

import ConversationPanelActions from 'components/chrome/ConversationPanelActions';
import ConversationTextArea from 'components/ConversationTextArea';
import ConversationPostButton from 'components/ConversationPostButton';
import ConversationOriginalComment from 'components/ConversationOriginalComment';

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 8px 0;
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 0;
  position: fixed;
`;

const ConversationFooter = ({
  originalComment,
  commentStatus,
  message,
  disabled,
  onChange,
  onAddComment,
  onEditComment,
  onReplyComment,
  onCancelEdit,
  onCancelReply,
}) => {

  return (
    <Root>
      {
        originalComment &&
        <ConversationOriginalComment
          isOwn={originalComment.isOwn}
          originalMessage={originalComment.message}
          actionName={
            commentStatus === 'reply' ? 'Replying to:'
              : commentStatus === 'edit' ? 'Editing Comment' : ''
          }
          onCancel={() => {
            if (commentStatus === 'edit') onCancelEdit();
            if (commentStatus === 'reply') onCancelReply();
          }}
        />
      }
      <ConversationPanelActions expanded multiline>
        <InputContainer>
          <ConversationTextArea
            value={message}
            onChange={onChange}
            maxLength={600}
          />
          <ButtonContainer>
            <ConversationPostButton
              disabled={disabled}
              onClick={() => {
                switch(commentStatus) {
                  case 'add': {
                    onAddComment();
                    break;
                  }
                  case 'edit': {
                    onEditComment();
                    break;
                  }
                  case 'reply': {
                    onReplyComment();
                    break;
                  }
                  default:
                    break;
                }
              }}
            />
          </ButtonContainer>
        </InputContainer>
      </ConversationPanelActions>
    </Root>
  );
};

export default ConversationFooter;
