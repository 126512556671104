import React from 'react';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemCheckbox from 'ui-library/components/ListItemCheckbox';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PanelIcon from '@material-ui/icons/VerticalSplitRounded';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';

const CurrentUserPerson = ({
  person,
  onPersonAction,
}) => {
  const [contextualMenuState, setContextualMenuState] = React.useState({
    id: null,
    anchorEl: null,
  });

  const {
    id,
    name,
  } = person;

  return (
    <>
      <ListItem
        key={id}
        onClick={() => {
          onPersonAction(id, 'expand');
        }}>
        <ListItemContentSegment
          width={44}
        >
          <ListItemCheckbox
            checked={false}
            disabled={true}
            onChange={() => {}}
          />
        </ListItemContentSegment>
        <ListItemContentSegment
          weight={1}
        >
          <ListItemText>
            <Typography
              variant="small"
              fontWeight="bold"
              color="primary"
            >
              {name} (You)
            </Typography>
          </ListItemText>
        </ListItemContentSegment>
        <ListItemContentSegment
          width={200}
          justifyContent="flex-end"
        >
          <ListItemActions>
            <Button
              data-test-id="person-context-menu-button"
              variant="icon-custom"
              textTransform="none"
              customBgColor="transparent"
              customHoverBgColor="primary-lightest"
              customTextColor="grey"
              customHoverTextColor="primary-dark"
              customActiveBgColor="white"
              customActiveTextColor="primary-darker"
              onClick={event => {
                event.stopPropagation();
                setContextualMenuState({
                  id,
                  anchorEl: event.currentTarget,
                });
              }}
            >
              <MoreHorizIcon/>
            </Button>
            <Button
              data-test-id="item-open-person-panel-button"
              textTransform="none"
              variant="icon-custom"
              customBgColor="transparent"
              customHoverBgColor="primary-lightest"
              customTextColor="grey"
              customHoverTextColor="primary-dark"
              customActiveBgColor="white"
              customActiveTextColor="primary-darker"
              onClick={event => {
                event.stopPropagation();
                onPersonAction(id, 'expand');
              }}>
              <PanelIcon/>
            </Button>
            <Button
              data-test-id="drill-down-person-button"
              textTransform="none"
              variant="icon-custom"
              customBgColor="transparent"
              customHoverBgColor="primary-lightest"
              customTextColor="grey"
              customHoverTextColor="primary-dark"
              customActiveBgColor="white"
              customActiveTextColor="primary-darker"
              onClick={event => {
                event.stopPropagation();
                onPersonAction(id, 'navigate');
              }}>
              <NavigateNextIcon/>
            </Button>
          </ListItemActions>
        </ListItemContentSegment>
        <Menu
          open={contextualMenuState.id === id}
          anchorEl={contextualMenuState.anchorEl}
          onClose={() => {
            setContextualMenuState({
              id: null,
              anchorEl: null,
            });
          }}>
          <MenuItem
            data-test-id="context-menu-view-button"
            onClick={event => {
              event.stopPropagation();
              onPersonAction(id, 'expand');
              setContextualMenuState({
                id: null,
                anchorEl: null,
              });
            }}
          >
            <Typography variant="small">
            View Details
            </Typography>
          </MenuItem>
        </Menu>
      </ListItem>
    </>
  );
};

export default CurrentUserPerson;
