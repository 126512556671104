import React from 'react';
import PropTypes from 'prop-types';
import Select from 'ui-library/components/Select';

import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';

import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  editContainer: theme.field.editContainer,
  actionButtonsContainer: theme.field.actionButtonsContainer,
  actionButton: theme.field.actionButton,
});

const BooleanEditMode = ({
  autoFocus,
  inlineEdit,
  label,
  helperText,
  error,
  value,
  onChange,
  onBlur,
  disabled,
  required,
  onFocus,
  onMouseDown,
  saveField,
  revertField,
}) => {

  const customOptions = [
    {
      label: 'N/A',
      value: 'N/A',
    },
    {
      label: 'YES',
      value: true,
    },
    {
      label: 'NO',
      value: false,
    },
  ];

  const classes = useStyles();

  if (!inlineEdit) {
    return (
      <Select
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        required={required}
        customOptions={customOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
      />
    );
  }

  return (
    <div
      className={classes.editContainer}
    >
      <div
        className={classes.actionButtonsContainer}
      >
        <div
          className={classes.actionButton}
          onMouseDown={onMouseDown}
        >
          <CloseIcon
            data-test-id="inline-revert-field-button"
            onClick={revertField}
          />
        </div>
        <div
          className={classes.actionButton}
        >
          <CheckIcon
            data-test-id="inline-save-field-button"
            onClick={() => {
              if (!error) {
                saveField();
              }
            }}
            onMouseDown={onMouseDown}
          />
        </div>
      </div>
      <Select
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        required={required}
        customOptions={customOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
      />
    </div>
  );
};

BooleanEditMode.propTypes = {
  autoFocus: PropTypes.bool,
  inlineEdit: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  saveField: PropTypes.func,
  revertField: PropTypes.func,
};

export default BooleanEditMode;
