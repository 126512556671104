import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const NameDialog = ({
  open,
  onClose,
  controlsDisabled,
  type,
  name,
  pluralName,
  onSetName,
  onSetPluralName,
  onSave,
}) => {
  if (!type) {
    return null;
  }

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Rename {type.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Choose a new name and a new plural name for <strong>{type.name}</strong>. Both are mandatory.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          disabled={controlsDisabled}
          margin="normal"
          placeholder="singular name"
          value={name}
          onChange={event => {
            onSetName(event.target.value);
          }}
        />
        <TextField
          fullWidth
          disabled={controlsDisabled}
          margin="normal"
          placeholder="plural name"
          value={pluralName}
          onChange={event => {
            onSetPluralName(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={controlsDisabled}
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled ||
            name === '' ||
            pluralName === ''
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NameDialog;
