import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import UserIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import Typography from '@material-ui/core/Typography';

const UsersList = ({
  users,
  onEditUser,
  onEditEmail,
  onTransferProperty,
}) => {
  return (
    <>
      {
        users
          .map(user => {
            const {
              userId,
              email,
              firstName,
              lastName,
            } = user;

            return (
              <NestedBox
                key={userId}>
                <ContentLine>
                  <UserIcon/>&nbsp;&nbsp;
                  <ContentLineText>
                    <Typography variant="body1">
                      {firstName} {lastName}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {email}
                    </Typography>
                  </ContentLineText>
                  <Tooltip
                    placement="top"
                    title={`transfer a property from ${firstName} ${lastName}`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onTransferProperty(userId, firstName, lastName, email);
                        }}>
                        <HomeRoundedIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`edit email ${email}`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onEditEmail(userId, email);
                        }}>
                        <MailOutlineRoundedIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={`edit user ${firstName} ${lastName}`}>
                    <span>
                      <IconButton
                        onClick={() => {
                          onEditUser(userId);
                        }}>
                        <EditIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                </ContentLine>
              </NestedBox>
            );
          })
      }
    </>
  );
};

export default UsersList;
