import {
  RECORD_ATOMIC_PERMISSION_LABELS,
} from './PERMISSION_FLAGS';

function recordAtomicPermissionLabel(permission) {
  if (!permission) {
    throw new Error('Cannot provide Record atomic permission label! Permission is undefined!');
  }
  return RECORD_ATOMIC_PERMISSION_LABELS[permission];
}

export default recordAtomicPermissionLabel;
