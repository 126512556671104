import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import Autocomplete from 'components/Autocomplete';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genConnectionsService from 'services/Connections/genConnections';

const ItemConnectionStep = ({
  active,
  onSetConnection,
}) => {
  const [connections, setConnections] = React.useState([]);
  const [selectedConnection, setSelectedConnection] = React.useState(null);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  React.useEffect(() => {
    async function init() {
      const connections = await genConnectionsService(authToken, currentPerspectiveId);
      const filteredConnections = connections.filter(connection => connection.status !== 'pending');
      setConnections(filteredConnections);
    }
    init();
  }, [authToken, currentPerspectiveId]);

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Item
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Connection
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          You may add an item for any Connection for which you have permission to do so.
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (!selectedConnection) {
            return;
          }
          onSetConnection(selectedConnection);
        }}>
          <WizardStepContent>
            <Typography variant="lead">
              Which Connection would you like to add this item for?
            </Typography>
            <Autocomplete
              options={connections}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={selectedConnection}
              placeholder="Connections..."
              onChange={(event, newConnection) => {
                setSelectedConnection(newConnection);
              }}
            />
          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              data-test-id="add-item-type-step-continue-button"
              variant="primary"
              disabled={!Boolean(selectedConnection)}>
              Continue &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ItemConnectionStep.propTypes = {
  onSetConnection: PropTypes.func.isRequired,
};

export default ItemConnectionStep;
