import React from 'react';
import styled from '@emotion/styled';
import RecordList from 'components/RecordList';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import LibraryContext from './context';
import genRecords from 'services/Records/genRecords';

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const InternalRecordsListRoute = ({
  match,
}) => {
  const {
    authToken,
    currentPerspectiveId,
    dispatchUserInterfaceAction,
    filter,
    pushAndClearFilter,
  } = React.useContext(LibraryContext);
  const [status, setStatus] = React.useState('fetching data');
  const [fatalError, setFatalError] = React.useState(null);
  const [records, setRecords] = React.useState(null);

  React.useEffect(() => {
    async function initialize() {
      try {
        const records = await genRecords(authToken, currentPerspectiveId);
        setRecords(records);
        setStatus('ready');
      } catch(error) {
        setFatalError(error);
      }
    }

    initialize();
  }, [authToken, currentPerspectiveId]);

  if (fatalError) {
    throw fatalError;
  }

  if (status !== 'ready') {
    return (
      <ListSkeleton/>
    );
  }

  if (records.length === 0) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
        You have not added any items yet. When you add an item to your Jules account, you will need to assign that item to a Record.
        </Typography>
      </EmptyList>
    );
  }

  const renderedRecords = records.filter(record => {
    if (!filter) {
      return true;
    }
    const {
      name,
    } = record;
    return (
      name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  });

  return (
    <div>
      <RecordList
        records={renderedRecords}
        onRecordAction={(recordId, action) => {
          switch(action) {
            case 'expand': {
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_RECORD_ID',
                payload: recordId,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_RECORD_PANEL',
                payload: true,
              });
              break;
            }
            case 'share': {
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_SHARE_RECORD_WIZARD',
                payload: true,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHARED_RECORD_ID',
                payload: recordId,
              });
              break;
            }
            case 'navigate': {
              pushAndClearFilter(`${match.url}/${recordId}`);
              break;
            }
            default:
              break;
          }
        }}
      />
    </div>
  );
};

export default InternalRecordsListRoute;
