import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import TypeLocationGraphParent from './TypeLocationGraphParent.react';
import TextField from '@material-ui/core/TextField';

const TypeLocationGraphAdmin = () => {
  const {
    // authToken,
    // perspectiveId,
    DataModel,
    DataModelQueries,
    // DataModelAPI,
    // dispatchDataModelAction,
  } = React.useContext(AdminContext);
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  const typeLocationGraphParentIds = DataModelQueries.selectTypeLocationGraphParentIds(DataModel);

  return (
    <div>
      <Typography
        variant="h4">
        Type Location Graph
      </Typography>
      <Typography
        variant="body2">
        edit the Data Model location structure
      </Typography>
      <br/><br/>
      <TextField
        fullWidth
        placeholder="filter by type name or type alias (3 characters minimum)"
        value={localState.filterQuery}
        onChange={event => {
          dispatchLocalAction({
            type: 'SET_FILTER_QUERY',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      {
        DataModelQueries.selectTypesByIds(
          DataModel,
          typeLocationGraphParentIds,
        )
          .filter(type => {
            const {
              name: typeName,
              aliases: typeAliases,
            } = type;

            if (localState.filterQuery.length < 3) {
              return false;
            }

            let match = false;

            if (typeName.toLocaleLowerCase().includes(localState.filterQuery)) {
              match = true;
            }
            for (let typeAlias of typeAliases) {
              if (typeAlias.toLocaleLowerCase().includes(localState.filterQuery.toLocaleLowerCase())) {
                match = true;
                break;
              }
            }

            return match;
          })
          .map(type => {
            const {
              id: typeId,
            } = type;

            const locationChildTypeIds = DataModelQueries.selectTypeLocationGraphChildIdsOfTypeById(
              DataModel,
              typeId,
            );
            const locationChildTypes = DataModelQueries.selectTypesByIds(
              DataModel,
              locationChildTypeIds,
            );

            return (
              <TypeLocationGraphParent
                key={typeId}
                type={type}
                locationChildTypes={locationChildTypes}
              />
            );
          })
      }
    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'SET_FILTER_QUERY': {
      const filterQuery = payload;

      return {
        ...state,
        filterQuery,
      };
    }

    case 'RESET': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    filterQuery: '',
  };
}

export default TypeLocationGraphAdmin;
