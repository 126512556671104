import GET__genericTypes from 'network-layer/endpoints/GET__genericTypes';

function responseAdapter(response) {
  return response;
}

export default async function genGenericTypes(authToken, perspectiveId, connectionId) {
  const response = await GET__genericTypes(authToken, perspectiveId, connectionId);

  return responseAdapter(response);
}
