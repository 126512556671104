import PUT__nodes_ID_fields_ID from 'network-layer/endpoints/PUT__nodes_ID_fields_ID';

function responseAdapter(response) {
  return response;
}

export default async function replaceNodeFieldValue(params) {
  const response = await PUT__nodes_ID_fields_ID(params);

  return responseAdapter(response);
}
