import React from 'react';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';

import ShareRoundedIcon from '@material-ui/icons/ShareRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import updateRecordPermissions from 'services/Records/updateRecordPermissions';

const StopSharingRecordDialog = ({
  showStopSharingRecordDialog,
  setShowStopSharingRecordDialog,
  stopSharingRecordId,
  stopSharingRecordName,
  stopSharingRecordConnectionId,
  stopSharingRecordConnectionName,
  setTabState,
}) => {
  const [isStopSharing, setIsStopSharing] = React.useState(false);

  const {
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const [error, setError] = React.useState(false);
  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  const handleStopSharingRecord = async() => {
    try {
      setIsStopSharing(true);
      await updateRecordPermissions(authToken, currentPerspectiveId, stopSharingRecordId, {connectionId: stopSharingRecordConnectionId, permissions: 0});
      setShowStopSharingRecordDialog(false);
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_RECORD_PANEL',
        payload: true,
      });
      dispatchUserInterfaceAction({
        type: 'SET_RECORD_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: true,
      });
      setIsStopSharing(false);
      setTabState('not loaded');
    } catch(error) {
      setError(error);
    }
  };

  const onClose = () => {
    setShowStopSharingRecordDialog(false);
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_RECORD_PANEL',
      payload: true,
    });
  };

  return (
    <>
      <ModalCard
        open={showStopSharingRecordDialog}
        handleClose={() => {
          onClose();
        }}
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick={true}
      >
        <ModalCardHeader color="error">
          <ModalCardHeaderIcon color="error">
            <ShareRoundedIcon
              fontSize="inherit"
            />
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
          Stop Sharing
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent>
          <Typography variant="body">
            {`Are you sure you want to stop sharing ${stopSharingRecordName} with ${stopSharingRecordConnectionName}?`}
          </Typography>
          <br/><br/>
          <Typography variant="caption">
          Your Connection will no longer have access to any information related to this record.
          </Typography>
        </ModalCardContent>
        <ModalCardActions>
          <Button
            data-test-id="modal-card-cancel-button"
            variant="text"
            onClick={() => {
              onClose();
            }}
          >
            CANCEL
          </Button>
          <Button
            data-test-id="modal-card-delete-button"
            variant="custom"
            textTransform="none"
            customBgColor="error"
            customHoverBgColor="error-dark"
            customTextColor="white"
            customHoverTextColor="white"
            customActiveBgColor="error-darker"
            customActiveTextColor="white"
            startIcon={isStopSharing ? <CircularProgress size={24} color="white"/> : <ShareRoundedIcon />}
            disabled={isStopSharing}
            onClick={handleStopSharingRecord}
          >
            STOP SHARING
          </Button>
        </ModalCardActions>
      </ModalCard>
    </>
  );
};

export default StopSharingRecordDialog;
