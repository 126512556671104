import React from 'react';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import SaveConfirmationScreen from './SaveConfirmationScreen';

const ItemSaveStep = ({
  active,
  nodeSaveState,
  closeWizardWithPrepend,
}) => {

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Item
          </WizardBreadcrumb>
        </WizardStepTitle>
      </WizardStepHead>

      <WizardContentColumn>
        {
          nodeSaveState === 'saving' &&
            <CenterpieceSpinner/>
        }
        {
          nodeSaveState === 'saved' &&
          <SaveConfirmationScreen
            closeWizardWithPrepend={closeWizardWithPrepend}
          />
        }
      </WizardContentColumn>
    </WizardStep>
  );
};

export default ItemSaveStep;
