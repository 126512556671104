import React from 'react';

import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import BusinessApplication from './BusinessApplication';
import HomeownerApplication from './HomeownerApplication';
import AdminApplication from './AdminApplication';

import FullScreenSpinner from 'components/chrome/FullScreenSpinner';
import NewNodePanel from 'components/NewNodePanel';
import NewDeleteNodeDialog from 'components/NewDeleteNodeDialog';
import PersonPanel from 'components/PersonPanel';
import DeletePersonDialog from 'components/DeletePersonDialog';
import NotificationsPanel from 'components/NotificationsPanel';
import ConnectionPanel from 'components/ConnectionPanel';
import DeleteConnectionDialog from 'components/DeleteConnectionDialog';
import RecordPanel from 'components/RecordPanel';
import ShareRecordWizard from 'components/ShareRecordWizard';
import ShareItemWizard from 'components/ShareItemWizard';
import StopSharingNodeDialog from 'components/StopSharingNodeDialog';
import ConversationVaultPanel from 'components/ConversationVaultPanel';
import DeleteNodeConversationDialog from 'components/DeleteNodeConversationDialog';
import DeleteRecordConversationDialog from 'components/DeleteRecordConversationDialog';

import {
  initUserInterfaceState,
  userInterfaceReducer,
} from 'reducers/UserInterface';

const UserInterface = () => {

  const {
    currentPerspective,
  } = React.useContext(PerspectiveContext);

  const [userInterfaceState, dispatchUserInterfaceAction] = React.useReducer(
    userInterfaceReducer,
    null,
    initUserInterfaceState,
  );

  const {
    nodeIdModifiedDestructivelyForLists,
    connectionIdModifiedDestructivelyForLists,

    expandedNodeId,
    expandedNodeLoadingState,
    showNodePanel,
    nodePanelDisplayMode,
    selectedNodePanelTab,
    reloadNodeConversationTab,
    reloadItemPermissionGrants,

    personIdModifiedDestructivelyForLists,
    expandedPersonId,
    expandedPersonIsCurrentUser,
    selectedPersonId,
    expandedPersonLoadingState,
    showPersonPanel,
    reloadRecordConversationTab,
    selectedPersonRecordId,

    showDeletePersonDialog,
    deletePersonId,

    showDeleteNodeDialog,
    deleteNodeId,

    showShareNodeWizard,
    shareNodeId,

    showNotificationsPanel,
    notificationsUnreadCount,

    expandedConnectionId,
    expandedConnectionLoadingState,
    showConnectionPanel,
    connectionPanelDisplayMode,
    selectedConnectionPanelTab,


    showDeleteConnectionDialog,
    isDeletingConnection,
    deleteConnectionId,

    expandedRecordId,
    showRecordPanel,
    externalRecordConnectionId,
    recordPanelDisplayMode,
    selectedRecordPanelTab,

    showShareRecordWizard,
    sharedRecordId,
    reloadRecordPermissionGrants,

    stopSharingNodeId,
    stopSharingNodeName,
    stopSharingNodeConnectionId,
    stopSharingNodeConnectionName,
    showStopSharingNodeDialog,

    backNavigationEnabled,
    backNavigationLanding,
    expandedFrozenConversation,

    selectedConversation,
    newConversation,

    deleteNodeConversation,
    nodeConversationIdModifiedDestructivelyForLists,

    deleteRecordConversation,
    recordConversationIdModifiedDestructivelyForLists,
  } = userInterfaceState;

  return (
    <>
      <UserInterfaceContext.Provider
        value={{
          nodeIdModifiedDestructivelyForLists,
          connectionIdModifiedDestructivelyForLists,

          expandedNodeId,
          expandedNodeLoadingState,
          showNodePanel,
          nodePanelDisplayMode,
          selectedNodePanelTab,
          reloadNodeConversationTab,
          reloadItemPermissionGrants,

          personIdModifiedDestructivelyForLists,
          expandedPersonId,
          expandedPersonIsCurrentUser,
          selectedPersonId,
          expandedPersonLoadingState,
          showPersonPanel,
          selectedPersonRecordId,

          showDeletePersonDialog,
          deletePersonId,

          showDeleteNodeDialog,
          deleteNodeId,

          showNotificationsPanel,
          notificationsUnreadCount,

          expandedConnectionId,
          expandedConnectionLoadingState,
          showConnectionPanel,
          connectionPanelDisplayMode,
          selectedConnectionPanelTab,

          showDeleteConnectionDialog,
          isDeletingConnection,
          deleteConnectionId,

          expandedRecordId,
          showRecordPanel,
          reloadRecordConversationTab,
          externalRecordConnectionId,
          recordPanelDisplayMode,
          selectedRecordPanelTab,

          showShareRecordWizard,
          sharedRecordId,
          reloadRecordPermissionGrants,

          showShareNodeWizard,
          shareNodeId,

          dispatchUserInterfaceAction,

          stopSharingNodeId,
          stopSharingNodeName,
          stopSharingNodeConnectionId,
          stopSharingNodeConnectionName,
          showStopSharingNodeDialog,

          backNavigationEnabled,
          backNavigationLanding,
          expandedFrozenConversation,

          selectedConversation,
          newConversation,

          deleteNodeConversation,
          nodeConversationIdModifiedDestructivelyForLists,

          deleteRecordConversation,
          recordConversationIdModifiedDestructivelyForLists,
        }}
      >
        <React.Suspense
          fallback={<FullScreenSpinner/>}>
          {
            (() => {
              switch(currentPerspective.type) {
                case 'company':
                  return <BusinessApplication/>;
                case 'household':
                  return <HomeownerApplication/>;
                case 'admin':
                  return <AdminApplication/>;
                default:
                  throw new Error(`Unknown perspective "${JSON.stringify(currentPerspective)}"!`);
              }
            })()
          }
        </React.Suspense>
        <NewDeleteNodeDialog/>
        <StopSharingNodeDialog/>
        <NewNodePanel/>

        <DeletePersonDialog/>
        <PersonPanel/>

        <DeleteConnectionDialog />
        <ConnectionPanel/>

        <RecordPanel />

        <ShareItemWizard />
        <ShareRecordWizard />

        <NotificationsPanel/>

        <ConversationVaultPanel/>

        <DeleteNodeConversationDialog/>
        <DeleteRecordConversationDialog/>
      </UserInterfaceContext.Provider>
    </>
  );
};

export default UserInterface;
