import selectVirtualLocationTreeNodeHintParent from './selectVirtualLocationTreeNodeHintParent';

function deriveVirtualLocationTreeNodeHintLocationDepth(
  configurationState,
  virtualLocationTreeNodeHint,
) {
  let currentVirtualLocationTreeHint = virtualLocationTreeNodeHint;
  let locationDepth = 0;

  while(currentVirtualLocationTreeHint.parentId !== null) {
    currentVirtualLocationTreeHint = selectVirtualLocationTreeNodeHintParent(
      configurationState,
      currentVirtualLocationTreeHint,
    );
    locationDepth++;
  }

  return locationDepth;
}

export default deriveVirtualLocationTreeNodeHintLocationDepth;
