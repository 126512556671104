import GET__connections_ID_records_ID_nodes from 'network-layer/endpoints/GET__connections_ID_records_ID_nodes';

function responseAdapter(response) {
  return response;
}

export default async function genNodesByConnectionIdAndRecordId(authToken, perspectiveId, connectionId, recordId) {
  const response = await GET__connections_ID_records_ID_nodes(authToken, perspectiveId, connectionId, recordId);

  return responseAdapter(response);
}
