import React from 'react';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import TextField from 'ui-library/components/TextField';
import {userLastName} from 'utils/validator';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const HelperTextContainer = styled.div`
  padding: 8px 0 0 10px;
`;

const RightPanelRowWithValidationGutter = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
  height: 80px;
`;

const LastNameComponent = ({
  accountType,
  lastName,
  setLastName,
  step,
  setStep,
  setWizardDirection,
  isTransitioning,
}) => {

  const [isPristine, setIsPristine] = React.useState(true);

  return (
    <>
      <RightPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          {accountType === 'personal' ? 'And your' : 'Your'} <strong>last name...</strong>
        </Typography>
      </RightPanelRow>
      <RightPanelRowWithValidationGutter>
        <TextField
          value={lastName}
          onChange={(event) => {
            setIsPristine(false);
            setLastName(event.target.value);
          }}
          fullWidth
          placeholder="Type your answer here..."
          autoFocus={true}
          onKeyPress={(event) => {
            if (event.which === 13 && !userLastName(lastName) && !isTransitioning) {
              setWizardDirection('forward');
              setStep(step + 1);
            }
          }}
          error={!isPristine && !!userLastName(lastName)}
          helperText={!isPristine && userLastName(lastName)}
        />
      </RightPanelRowWithValidationGutter>
      <br/>
      <RightPanelRow>
        <Button
          data-test-id="last-name-continue-btn"
          variant="primary"
          endIcon={<ArrowForwardIcon />}
          disabled={!!userLastName(lastName) || isTransitioning}
          onClick={() => {
            setWizardDirection('forward');
            setStep(step + 1);
          }}
        >
            CONTINUE
        </Button>
        <HelperTextContainer>
          <Typography
            variant="tiny"
          >
              press <strong>ENTER</strong>
          </Typography>
        </HelperTextContainer>
      </RightPanelRow>
    </>
  );
};

export default LastNameComponent;
