import endpointURL from '../util/endpointURL';

export default async function GET__backupDownload_FILENAME(authToken, downloadRequest, params) {
  const {
    filename,
  } = params;

  downloadRequest.open('GET', endpointURL(`/backup-download/${filename}`));
  downloadRequest.responseType = 'blob';
  downloadRequest.setRequestHeader('Authorization', authToken);

  return await downloadRequest.send();
}
