import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const EditNotificationPipelineDialog = ({
  open,
  onClose,
  onSave,
  controlsDisabled,
  notificationPipelineName,
  onSetNotificationPipelineName,
  notificationPipelineDescription,
  onSetNotificationPipelineDescription,
  notificationPipelineNotificationTextTemplate,
  onSetNotificationPipelineNotificationTextTemplate,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Edit notification pipeline
      </DialogTitle>
      <DialogContent>

        <TextField
          fullWidth
          margin="normal"
          disabled={controlsDisabled}
          value={notificationPipelineName}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 50) {
              onSetNotificationPipelineName(value);
            }
          }}
          placeholder="name"
        />

        <TextField
          fullWidth
          margin="normal"
          disabled={controlsDisabled}
          value={notificationPipelineDescription}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 100) {
              onSetNotificationPipelineDescription(value);
            }
          }}
          placeholder="description"
        />

        <TextField
          fullWidth
          margin="normal"
          disabled={controlsDisabled}
          value={notificationPipelineNotificationTextTemplate}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 300) {
              onSetNotificationPipelineNotificationTextTemplate(value);
            }
          }}
          placeholder="notification text template"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          disabled={controlsDisabled}
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled ||
            (
              !notificationPipelineName &&
              !notificationPipelineDescription &&
              !notificationPipelineNotificationTextTemplate
            )
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditNotificationPipelineDialog;
