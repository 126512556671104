import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import TypeRelationshipIcon from '@material-ui/icons/CompareArrows';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AddTypeRelationshipDialog from './AddTypeRelationshipDialog.react';
import Prompt from 'components/admin/Prompt';

const TypeRelationshipsAdmin = () => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  const typeRelationships = DataModelQueries.selectTypeRelationships(DataModel);

  return (
    <div>
      <Typography
        variant="h4">
        Type Relationships
      </Typography>
      <Typography
        variant="body2">
        create or edit Type Relationships
      </Typography>
      <br/><br/>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatchLocalAction({
            type: 'BEGIN_ADD_TYPE_RELATIONSHIP',
          });
        }}>
        create a new type relationship
      </Button>
      <br/><br/>

      <TextField
        fullWidth
        placeholder="filter by type name, type alias, link label or qualifiers (3 characters minimum)"
        value={localState.filterQuery}
        onChange={event => {
          dispatchLocalAction({
            type: 'SET_FILTER_QUERY',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      {
        typeRelationships
          .filter(typeRelationship => {
            const {
              originTypeId,
              destinationTypeId,
              nodeLinkDefinitionId,
            } = typeRelationship;

            if (localState.filterQuery.length < 3) {
              return false;
            }

            let match = false;

            const originType = DataModelQueries.selectTypeById(
              DataModel,
              originTypeId,
            );
            const destinationType = DataModelQueries.selectTypeById(
              DataModel,
              destinationTypeId,
            );
            const nodeLinkDefinition = DataModelQueries.selectNodeLinkDefinitionById(
              DataModel,
              nodeLinkDefinitionId,
            );

            if (
              originType.name.toLocaleLowerCase().includes(localState.filterQuery) ||
              destinationType.name.toLocaleLowerCase().includes(localState.filterQuery) ||
              nodeLinkDefinition.label.toLocaleLowerCase().includes(localState.filterQuery) ||
              nodeLinkDefinition.directQualifier.toLocaleLowerCase().includes(localState.filterQuery) ||
              nodeLinkDefinition.reverseQualifier.toLocaleLowerCase().includes(localState.filterQuery)
            ) {
              match = true;
            }
            if (!match) {
              for (let typeAlias of originType.aliases) {
                if (typeAlias.toLocaleLowerCase().includes(localState.filterQuery.toLocaleLowerCase())) {
                  match = true;
                  break;
                }
              }
              for (let typeAlias of destinationType.aliases) {
                if (typeAlias.toLocaleLowerCase().includes(localState.filterQuery.toLocaleLowerCase())) {
                  match = true;
                  break;
                }
              }
            }

            return match;
          })
          .map(typeRelationship => {
            const {
              id: typeRelationshipId,
              originTypeId,
              destinationTypeId,
              nodeLinkDefinitionId,
            } = typeRelationship;

            const originType = DataModelQueries.selectTypeById(
              DataModel,
              originTypeId,
            );
            const destinationType = DataModelQueries.selectTypeById(
              DataModel,
              destinationTypeId,
            );
            const nodeLinkDefinition = DataModelQueries.selectNodeLinkDefinitionById(
              DataModel,
              nodeLinkDefinitionId,
            );

            return (
              <NestedBox
                key={typeRelationshipId}>
                <ContentLine>
                  <TypeRelationshipIcon/>&nbsp;&nbsp;&nbsp;
                  <ContentLineText>
                    <Typography
                      vartiant="body1">
                      {originType.name}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <span
                        style={{
                          color: '#aaa',
                        }}>
                        {nodeLinkDefinition.directQualifier}&nbsp;&nbsp;|&nbsp;&nbsp;{nodeLinkDefinition.reverseQualifier}
                      </span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {destinationType.name}
                    </Typography>
                  </ContentLineText>
                  <IconButton
                    onClick={() => {
                      dispatchLocalAction({
                        type: 'BEGIN_REMOVE_TYPE_RELATIONSHIP',
                        payload: typeRelationshipId,
                      });
                    }}
                  >
                    <DeleteIcon/>
                  </IconButton>
                </ContentLine>
              </NestedBox>
            );
          })
      }

      <AddTypeRelationshipDialog
        open={
          localState.status === 'adding type relationship' ||
          localState.status === 'requesting add type relationship'
        }
        onClose={() => {
          dispatchLocalAction({
            type: 'END_ADD_TYPE_RELATIONSHIP',
          });
        }}
        controlsDisabled={
          localState.status === 'requesting add type relationship'
        }
        nodeLinkDefinitions={DataModelQueries.selectNodeLinkDefinitions(DataModel)}
        types={DataModelQueries.selectTypes(DataModel)}
        nodeLinkDefinition={localState.nodeLinkDefinition}
        orignType={localState.orignType}
        destinationType={localState.destinationType}
        onSetNodeLinkDefinition={value => {
          dispatchLocalAction({
            type: 'SET_NODE_LINK_DEFINITION',
            payload: value,
          });
        }}
        onSetOriginType={value => {
          dispatchLocalAction({
            type: 'SET_ORIGIN_TYPE',
            payload: value,
          });
        }}
        onSetDestinationType={value => {
          dispatchLocalAction({
            type: 'SET_DESTINATION_TYPE',
            payload: value,
          });
        }}
        onSaveTypeRelationship={async() => {
          dispatchLocalAction({
            type: 'REQUEST_ADD_TYPE_RELATIONSHIP',
          });
          const {
            nodeLinkDefinition,
            originType,
            destinationType,
          } = localState;

          try {
            const newTypeRelationship = await DataModelAPI.createTypeRelationship(
              authToken,
              perspectiveId,
              {
                nodeLinkDefinitionId: nodeLinkDefinition.id,
                originTypeId: originType.id,
                destinationTypeId: destinationType.id,
              },
            );

            dispatchDataModelAction({
              type: 'ADD_TYPE_RELATIONSHIP',
              payload: newTypeRelationship,
            });

            dispatchLocalAction({
              type: 'TYPE_RELATIONSHIP_ADDED',
            });
          } catch(error) {
            setAPIException(error);
          }
        }}
      />

      <Prompt
        open={
          localState.status === 'type relationship added'
        }
        title="Success!"
        text={
          <span>
            Type Relationship was successfully added.
          </span>
        }
        onConfirm={() => {
          dispatchLocalAction({
            type: 'END_ADD_TYPE_RELATIONSHIP',
          });
        }}
      />

      <Prompt
        open={
          localState.status === 'removing type relationship'
        }
        title="Confirm action"
        text={
          <span>
            Are you sure you want to remove this Type Relationship? This action cannot be undone.
          </span>
        }
        onCancel={() => {
          dispatchLocalAction({
            type: 'END_REMOVE_TYPE_RELATIONSHIP',
          });
        }}
        onConfirm={async() => {
          try {
            await DataModelAPI.deleteTypeRelationship(
              authToken,
              perspectiveId,
              {
                typeRelationshipId: localState.id,
              },
            );

            dispatchDataModelAction({
              type: 'REMOVE_TYPE_RELATIONSHIP',
              payload: localState.id,
            });

            dispatchLocalAction({
              type: 'TYPE_RELATIONSHIP_REMOVED',
            });
          } catch(error) {
            setAPIException(error);
          }
        }}
      />

      <Prompt
        open={
          localState.status === 'type relationship removed'
        }
        title="Success!"
        text={
          <span>
            Type Relationship was successfully deleted.
          </span>
        }
        onConfirm={() => {
          dispatchLocalAction({
            type: 'END_REMOVE_TYPE_RELATIONSHIP',
          });
        }}
      />

    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    // DATA
    case 'SET_FILTER_QUERY': {
      const filterQuery = payload;

      return {
        ...state,
        filterQuery,
      };
    }

    case 'SET_NODE_LINK_DEFINITION': {
      return {
        ...state,
        nodeLinkDefinition: payload,
      };
    }

    case 'SET_ORIGIN_TYPE': {
      return {
        ...state,
        originType: payload,
      };
    }

    case 'SET_DESTINATION_TYPE': {
      return {
        ...state,
        destinationType: payload,
      };
    }



    // ADD
    case 'BEGIN_ADD_TYPE_RELATIONSHIP': {
      return {
        ...state,
        status: 'adding type relationship',
      };
    }

    case 'REQUEST_ADD_TYPE_RELATIONSHIP': {
      return {
        ...state,
        status: 'requesting add type relationship',
      };
    }

    case 'TYPE_RELATIONSHIP_ADDED': {
      return {
        ...state,
        status: 'type relationship added',
      };
    }

    case 'END_ADD_TYPE_RELATIONSHIP': {
      return {
        ...initState(),
      };
    }



    // REMOVE
    case 'BEGIN_REMOVE_TYPE_RELATIONSHIP': {
      const id = payload;

      return {
        ...state,
        status: 'removing type relationship',
        id,
      };
    }

    case 'REQUEST_REMOVE_TYPE_RELATIONSHIP': {
      return {
        ...state,
        status: 'requesting remove type relationship',
      };
    }

    case 'TYPE_RELATIONSHIP_REMOVED': {
      return {
        ...state,
        status: 'type relationship removed',
      };
    }

    case 'END_REMOVE_TYPE_RELATIONSHIP': {
      return {
        ...initState(),
      };
    }



    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  /*
    idle

    adding type relationship
    requesting add type relationship
    type relationship added

    removing type relationship
    requesting remove type relationship
    type relationship removed
  */
  return {
    status: 'idle',
    filterQuery: '',
    id: null,
    nodeLinkDefinition: null,
    originType: null,
    destinationType: null,
  };
}

export default TypeRelationshipsAdmin;
