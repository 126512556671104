import React from 'react';
import styled from '@emotion/styled';

import Grid from '@material-ui/core/Grid';

import AddNewListItemButton from 'ui-library/components/AddNewListItemButton';
import InlineMessage from 'ui-library/components/InlineMessage';
import Typography from 'ui-library/components/Typography';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleSwitch from 'ui-library/components/ToggleSwitch';
import ConversationList from 'components/ConversationList';

import PerspectiveContext from 'contexts/Perspective';
import UserContext from 'contexts/User';
import UserInterfaceContext from 'contexts/UserInterface';

import genConversationsByRecordId from 'services/Conversations/genConversationsByRecordId';
import updateConversationStatus from 'services/Conversations/updateConversationStatus';

const PaddedContainer = styled.div`
  padding: 20px 35px 1px 35px;
`;

const NoConversationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
`;

const NoConversationsMessageRow = styled.div`
  display: flex;
  flex: 1,
  justify-content: center;
`;

const ConversationListContainer = styled.div`
  margin-top: 8px;
`;

const RecordConversationsTab = ({
  recordId,
  isShared,
  setPanelDisplayMode,
  dispatchUserInterfaceAction,
}) => {
  const [conversations, setConversations] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);
  // 'not loaded', 'loading', 'loaded'
  const [tabState, setTabState] = React.useState('not loaded');
  const [fatalError, setFatalError] = React.useState(null);

  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: perspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  const {
    reloadRecordConversationTab,
  } = React.useContext(UserInterfaceContext);


  React.useEffect(() => {
    const loadConversations = async() => {
      try {
        setTabState('loading');
        setConversations(await genConversationsByRecordId({
          authToken,
          perspectiveId,
          recordId,
        }));
        setTabState('loaded');
        dispatchUserInterfaceAction({
          type: 'SET_RELOAD_RECORD_CONVERSATION_TAB',
          payload: false,
        });
      } catch(error) {
        const {
          name,
        } = error;
        switch (name) {
          case 'AuthorizationError':
            setAuthToken(null);
            setAuthenticationStatus('not-authenticated');
            localStorage.removeItem('authToken');
            break;
          default:
            setFatalError(error);
        }
      }
    };

    if (tabState === 'not loaded' || reloadRecordConversationTab === true) {
      loadConversations();
    }
  }, [authToken, perspectiveId, recordId, setAuthToken, setAuthenticationStatus, tabState, reloadRecordConversationTab, dispatchUserInterfaceAction]);

  if (fatalError) {
    throw fatalError;
  }

  if (tabState !== 'loaded') {
    return (
      <PaddedContainer>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            style={{
              height: '100%',
              flexGrow: 1,
              width: '100%',
            }}
          >
            <NoConversationsContainer>
              <CenterpieceSpinner/>
            </NoConversationsContainer>
          </Grid>
        </Grid>
      </PaddedContainer>
    );
  }

  if (!isShared) {
    return (
      <PaddedContainer>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            style={{
              cursor: 'pointer',
              height: '100%',
              flexGrow: 1,
            }}
          >
            <InlineMessage
              variant="info"
              open={true}
              persistent={true}
              messageBody={
                <Typography variant="body">
                  This item needs to be shared before you can start a conversation.
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </PaddedContainer>
    );
  }

  return (
    <PaddedContainer>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          style={{
            cursor: 'pointer',
            height: '100%',
            flexGrow: 1,
          }}
        >
          <AddNewListItemButton
            label="Start a new conversation"
            onClick={() => {
              setPanelDisplayMode('start_conversation');
              dispatchUserInterfaceAction({
                type: 'SET_BACK_NAVIGATION_ENABLED',
                payload: {
                  backNavigationEnabled: true,
                  backNavigationLanding: 'conversations',
                },
              });
            }}
          />
          {
            conversations.length === 0 &&
            <NoConversationsContainer>
              <NoConversationsMessageRow>
                <Typography variant="h4" fontWeight="bold" color="grey-semi">
                  There are no conversations&nbsp;
                </Typography>
              </NoConversationsMessageRow>
              <NoConversationsMessageRow>
                <Typography variant="h4" fontWeight="bold" color="grey-semi">
                  on this record.
                </Typography>
              </NoConversationsMessageRow>
            </NoConversationsContainer>
          }
          {
            conversations.length > 0 &&
            <>
              <FormControlLabel
                labelPlacement="end"
                style={{marginTop: '8px'}}
                control={
                  <ToggleSwitch
                    checked={showAll}
                    onChange={() => setShowAll(!showAll)}
                    value={false}
                  />
                }
                label={
                  <Typography
                    variant="body"
                    fontWeight="normal"
                    fontFamily="primary"
                  >
                  Show All&nbsp;&nbsp;
                  </Typography>
                }
              />
              <ConversationListContainer>
                <ConversationList
                  dispatchUserInterfaceAction={dispatchUserInterfaceAction}
                  conversations={showAll ? conversations : conversations.filter(
                    conversation => conversation.status === 'active',
                  )}
                  onConversationAction={async(conversationId, action) => {
                    const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
                    switch (action) {
                      case 'open': {
                        dispatchUserInterfaceAction({
                          type: 'SET_SELECTED_CONVERSATION',
                          payload: conversation,
                        });
                        setPanelDisplayMode('conversation');
                        dispatchUserInterfaceAction({
                          type: 'SET_BACK_NAVIGATION_ENABLED',
                          payload: {
                            backNavigationEnabled: true,
                            backNavigationLanding: 'conversations',
                          },
                        });
                        break;
                      }
                      case 'archive': {
                        setTabState('loading');
                        await updateConversationStatus({
                          authToken,
                          perspectiveId,
                          conversationId,
                          status: 'archived',
                        });
                        setConversations(
                          await genConversationsByRecordId({
                            authToken,
                            perspectiveId,
                            recordId,
                          }),
                        );
                        setTabState('loaded');
                        break;
                      }
                      case 'unarchive': {
                        setTabState('loading');
                        await updateConversationStatus({
                          authToken,
                          perspectiveId,
                          conversationId,
                          status: 'active',
                        });
                        setConversations(
                          await genConversationsByRecordId({
                            authToken,
                            perspectiveId,
                            recordId,
                          }),
                        );
                        setTabState('loaded');
                        break;
                      }
                      case 'delete': {
                        dispatchUserInterfaceAction({
                          type: 'SET_DELETE_RECORD_CONVERSATION',
                          payload: conversation,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_RECORD_PANEL',
                          payload: false,
                        });
                        break;
                      }
                      default:
                        break;
                    }
                  }}
                />
              </ConversationListContainer>
            </>
          }
        </Grid>
      </Grid>
    </PaddedContainer>
  );
};

export default RecordConversationsTab;
