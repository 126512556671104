import React from 'react';
import styled from '@emotion/styled';
import RecordList from 'components/RecordList';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import ConnectionsContext from './context';
import genOutboundRecordsByConnectionId from 'services/Records/genOutboundRecordsByConnectionId';

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const OutboundRecordsByConnectionListRoute = ({
  match,
}) => {
  const {
    authToken,
    currentPerspectiveId,
    recordIdModifiedDestructivelyForLists,
    dispatchUserInterfaceAction,
    filter,
    pushAndClearFilter,
  } = React.useContext(ConnectionsContext);
  const [status, setStatus] = React.useState('fetching data');
  const [fatalError, setFatalError] = React.useState(null);
  const [records, setRecords] = React.useState(null);

  const initialize = React.useCallback(async() => {
    try {
      setStatus('fetching data');
      const records = await genOutboundRecordsByConnectionId(authToken, currentPerspectiveId, match.params.connectionId);
      setRecords(records);
      setStatus('ready');
    } catch(error) {
      setFatalError(error);
    }
  }, [authToken, currentPerspectiveId, match.params.connectionId]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  React.useEffect(() => {
    if (recordIdModifiedDestructivelyForLists !== null) {
      initialize();
      dispatchUserInterfaceAction({
        type: 'SET_RECORD_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        value: null,
      });
    }
  }, [dispatchUserInterfaceAction, initialize, recordIdModifiedDestructivelyForLists]);


  if (fatalError) {
    throw fatalError;
  }

  if (status !== 'ready') {
    return (
      <ListSkeleton/>
    );
  }

  if (records.length === 0) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
          You have not shared any of your information through this Connection. When you share access to your Jules data the shared records will appear here.
        </Typography>
      </EmptyList>
    );
  }

  const renderedRecords = records.filter(record => {
    if (!filter) {
      return true;
    }
    const {
      name,
    } = record;
    return (
      name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  });

  return (
    <div>
      <RecordList
        records={renderedRecords}
        onRecordAction={(recordId, action, recordName, recordPermissions) => {
          switch(action) {
            case 'expand': {
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_RECORD_ID',
                payload: recordId,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_RECORD_PANEL',
                payload: true,
              });
              break;
            }
            case 'share': {
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_SHARE_RECORD_WIZARD',
                payload: true,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHARED_RECORD_ID',
                payload: recordId,
              });
              break;
            }
            case 'navigate': {
              pushAndClearFilter(`${match.url}/${recordId}`);
              break;
            }
            default:
              break;
          }
        }}
      />
    </div>
  );
};

export default OutboundRecordsByConnectionListRoute;
