import {
  RECORD_FRONTEND_PERMISSIONS,
  RECORD_FRONTEND_IMPLIED_PERMISSIONS,
} from './PERMISSION_FLAGS';

function recordFrontendPermissionsToImpliedFrontendPermissions(frontendPermissions) {
  if (typeof frontendPermissions !== 'object' || frontendPermissions === null) {
    throw new Error(`Invalid Node frontend permissions! Expected an object. Received ${typeof frontendPermissions}.`);
  }

  const receivedFrontendPermissions = Object.keys(frontendPermissions);
  const missingFrontendPermissions = [];
  for (let requiredFrontendPermission of Object.keys(RECORD_FRONTEND_PERMISSIONS)) {
    if (!receivedFrontendPermissions.includes(requiredFrontendPermission)) {
      missingFrontendPermissions.push(requiredFrontendPermission);
    }
  }
  if (missingFrontendPermissions.length) {
    throw new Error(`Incomplete Record frontend permissions! Missing permissions: ${missingFrontendPermissions.map(missingAtomicPermission => `"${missingAtomicPermission}"`).join(', ')}.`);
  }

  const impliedFrontendPermissions = {...frontendPermissions};

  for (let frontendPermission of Object.keys(frontendPermissions)) {
    if (frontendPermissions[frontendPermission] === true && RECORD_FRONTEND_IMPLIED_PERMISSIONS[frontendPermission]) {
      RECORD_FRONTEND_IMPLIED_PERMISSIONS[frontendPermission].forEach(inferedPermission => {
        impliedFrontendPermissions[inferedPermission] = true;
      });
    }
  }

  return impliedFrontendPermissions;
}

export default recordFrontendPermissionsToImpliedFrontendPermissions;
