function selectVirtualLocationTreeNodeHints(configurationState) {
  const {
    virtualLocationTreeNodeHintsById,
    virtualLocationTreeNodeHintIds,
  } = configurationState;

  return (
    virtualLocationTreeNodeHintIds
      .map(id => virtualLocationTreeNodeHintsById[id])
  );
}

export default selectVirtualLocationTreeNodeHints;
