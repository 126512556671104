import React from 'react';
import PropTypes from 'prop-types';
import MUIMenuItem from '@material-ui/core/MenuItem';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import {
  COLOR_MONIKERS,
} from 'ui-library/color-palette';

const useStyles = makeStyles({
  menuItem: {
    height: '43px',
    borderRadius: theme.contextMenuItem.borderRadius,
    marginBottom: theme.contextMenuItem.marginBottom,
    marginTop: theme.contextMenuItem.marginTop,
    color: props => theme.palette[props.menuItemColor],
    cursor: props => props.disabled ? theme.contextMenuItem.disabledCursor : theme.contextMenuItem.cursor,
    justifyContent: props => props.centered,
  },
  menuItemWrapper: {
    borderBottom: props => !props.showDivider ? false : props.last ? theme.contextMenuItem.dividerLast : theme.contextMenuItem.divider,
  },
});

const MenuItem = React.forwardRef((props, ref) => {
  const {
    onClick,
    children,
    last,
    color,
    showDivider,
    'data-test-id': dataTestId,
    disabled,
    centeredContent,
    ...otherProps
  } = props;

  const menuItemColor = disabled ? 'grey' : COLOR_MONIKERS.indexOf(color) > -1 ? color : 'black';
  const centered = centeredContent ? 'center' : null;

  const classes = useStyles({
    last,
    menuItemColor,
    showDivider,
    disabled,
    centered,
  });

  return (
    <div
      data-test-id={dataTestId}
      className={classes.menuItemWrapper}>
      <MUIMenuItem
        className={classes.menuItem}
        ref={ref}
        {...otherProps}
        onClick={(ev) => {
          if (disabled) {
            ev.stopPropagation();
            return;
          }
          onClick(ev);
        }}
      >
        {children}
      </MUIMenuItem>
    </div>
  );
});

MenuItem.displayName = '';

MenuItem.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  label: PropTypes.string,
  color: PropTypes.oneOf(COLOR_MONIKERS),
  last: PropTypes.bool,
  showDivider: PropTypes.bool,
  disabled: PropTypes.bool,
  centeredContent: PropTypes.bool,
  'data-test-id': PropTypes.string,
};

export default MenuItem;
