import GET__connections_ID_outboundRecords from 'network-layer/endpoints/GET__connections_ID_outboundRecords';

function responseAdapter(response) {
  return response;
}

export default async function genOutboundRecordsByConnectionId(authToken, perspectiveId, connectionId) {
  const response = await GET__connections_ID_outboundRecords(authToken, perspectiveId, connectionId);

  return responseAdapter(response);
}
