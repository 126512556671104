import React from 'react';
import styled from '@emotion/styled';
import {
  greyLighter,
} from 'ui-library/color-palette';
import Fade from '@material-ui/core/Fade';

const Root = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: ${greyLighter};
  overflow-y: auto;
  z-index: 4;
`;

const Wizard = ({
  children,
  onClose,
}) => {
  React.useEffect(() => {
    function handleEscKey(event) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    document.body.addEventListener('keyup', handleEscKey);
    document.documentElement.style.overflowY = 'hidden';

    return () => {
      document.body.removeEventListener('keyup', handleEscKey);
      document.documentElement.style.overflowY = 'auto';
    };
  }, [onClose]);

  return (
    <Fade
      in
      timeout={200}>
      <Root>
        {children}
      </Root>
    </Fade>
  );
};

export default Wizard;
