import PATCH__nodes_ID from 'network-layer/endpoints/PATCH__nodes_ID';

function responseAdapter(response) {
  return response;
}

export default async function updateNodeName(authToken, perspectiveId, nodeId, nodeName) {

  const response = await PATCH__nodes_ID(authToken, perspectiveId, nodeId, {name: nodeName});

  return responseAdapter(response);
}
