import GET__locations_locationCandidatesTree from 'network-layer/endpoints/GET__locations_locationCandidatesTree';

function responseAdapter(response) {
  return response;
}

export default async function genLocationCandidatesTree(authToken, perspectiveId, typeId, recordId, rootLocationId) {
  const response = await GET__locations_locationCandidatesTree(authToken, perspectiveId, typeId, recordId, rootLocationId);

  return responseAdapter(response);
}
