import React from 'react';
import styled from '@emotion/styled';
import InlineMessage from 'ui-library/components/InlineMessage';
import Typography from 'ui-library/components/Typography';
import {
  gutter,
} from 'constants/layout';

const MessageContainer = styled.div`
  margin-top: -${gutter}px;
  padding-bottom: ${gutter}px;
`;

const GracePeriodMessage = ({
  remainingDays,
}) => {
  const days = remainingDays > 1 ? 'days' : 'day';
  return (
    <MessageContainer>
      <InlineMessage
        variant="warning"
        open={true}
        persistent={true}
        messageTitle="Subscription Expired"
        messageBody={
          <Typography variant="body">
            {
              `Your membership has expired. You may use Jules for 
            ${remainingDays} ${days}, after which you will 
            lose access to your data. 
            To continue using Jules please update your Membership.`
            }
          </Typography>
        }
      />
    </MessageContainer>
  );
};

export default GracePeriodMessage;
