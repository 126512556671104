import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Typography from 'ui-library/components/Typography';
import Select from 'ui-library/components/Select';
import InlineMessage from 'ui-library/components/InlineMessage';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genRecordCandidates from 'services/Types/genRecordCandidates';

import {
  TYPE_STEP,
  NICKNAME_STEP,
} from 'constants/addItem.js';

const Description = styled.div`
  margin-bottom: 18px;
`;

const MessageContainer = styled.div`
  padding: 24px 0;
  width: 100%;
`;

const ItemRecordTypeStep = ({
  active,
  connectionId,
  itemTypeId,
  itemTypeName,
  itemRecordTypeId,
  onSetItemRecordType,
  navigateToStep,
  currentPerspectiveType,
}) => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [recordCandidates, setRecordCandidates] = React.useState([]);
  const [showWarning, setShowWarning] = React.useState(false);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const ref = React.createRef();

  React.useEffect(() => {
    async function init() {
      setIsInitialized(false);
      const records = await genRecordCandidates(authToken, currentPerspectiveId, itemTypeId, connectionId);
      const options = records.map(record => {
        return {
          label: record.name,
          value: record.id,
          name: record.name,
        };
      });
      if (options.length === 1) {
        onSetItemRecordType(options[0].value, options[0].name);
        !connectionId && setShowWarning(true);
      }
      setRecordCandidates(options);
      setIsInitialized(true);
    }
    if (itemTypeId) {
      init();
    }
  }, [authToken, connectionId, currentPerspectiveId, itemTypeId, onSetItemRecordType]);

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Item
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="record-type-step-type-breadcrumb"
            textTransform="capitalize"
            onClick={() => navigateToStep(TYPE_STEP)}>
            {itemTypeName}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Assign
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          {
            currentPerspectiveType === 'company' ?
              'Business Assets belong to your business whereas Property Information items belong to the owner of the property this item is being assigned to.'
              :
              'Property Information includes all items that would be sold with the property whereas Personal Property are items that would be retained if the property was sold.'
          }
        </WizardStepDescription>
        <MessageContainer>
          <InlineMessage
            variant="warning"
            open={showWarning}
            onClose={() => setShowWarning(false)}
            messageTitle="Warning"
            messageBody={
              <Typography variant="body">
                If the record you select is currently being shared via a Connection, then this item will also be accessible via that Connection.
              </Typography>
            }
          />
        </MessageContainer>
      </WizardStepHead>

      <WizardContentColumn>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (!itemRecordTypeId) {
            return;
          }
          navigateToStep(NICKNAME_STEP);
        }}>
          <WizardStepContent>
            <Description>
              <Typography
                variant="lead">
                  What does this item&nbsp;
                <Typography
                  variant="lead"
                  fontWeight="bold">
                  belong&nbsp;
                </Typography>
                to?
              </Typography>
              <Typography variant="lead">?</Typography>
            </Description>
            {
              isInitialized ?
                <Select
                  data-test-id="add-item-select-record"
                  value={itemRecordTypeId ? itemRecordTypeId : ''}
                  onFocus={() => {
                    if (recordCandidates.length === 1) {
                      ref.current.focus();
                    }
                  }}
                  onExited={()=>{
                    ref.current.focus();
                  }}
                  onChange={(event) => {
                    if (event.target.value !== itemRecordTypeId) {
                      onSetItemRecordType(
                        event.target.value,
                        recordCandidates.find(record => record.value === event.target.value).name,
                      );
                      !connectionId && setShowWarning(true);
                    }
                    ref.current.focus();
                  }}
                  placeholder="Select from this list..."
                  autoFocus
                  customOptions={recordCandidates}
                /> : <CenterpieceSpinner />
            }
          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              ref={ref}
              data-test-id="record-type-step-continue-button"
              variant="primary"
              disabled={!Boolean(itemRecordTypeId)}>
              Continue &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ItemRecordTypeStep.propTypes = {
  onSetItemRecordType: PropTypes.func.isRequired,
  active: PropTypes.bool,
  connectionId: PropTypes.number,
  itemTypeId: PropTypes.number,
  itemTypeName: PropTypes.string,
  itemNickname: PropTypes.string,
  itemRecordTypeId: PropTypes.number,
  navigateToStep: PropTypes.func,
  currentPerspectiveType: PropTypes.string,
};

export default ItemRecordTypeStep;
