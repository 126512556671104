function selectFieldWatchers(DM) {
  const {
    fieldWatchersById,
    fieldWatcherIds,
  } = DM;

  return (
    fieldWatcherIds
      .map(fieldWatcherId => fieldWatchersById[fieldWatcherId])
  );
}

export default selectFieldWatchers;
