function propertyConfigurationHintStateInitializer() {
  return {
    virtualLocationTreeTitle: '',
    virtualLocationTreeDescription: '',
    virtualLocationTreeNodeHintsById: {},
    virtualLocationTreeNodeHintIds: [],
    virtualLocationTreeNodesById: {},
    virtualLocationTreeNodeIds: [],
  };
}

export default propertyConfigurationHintStateInitializer;
