function selectVirtualLocationTreeNodes(configurationState) {
  const {
    virtualLocationTreeNodesById,
    virtualLocationTreeNodeIds,
  } = configurationState;

  return (
    virtualLocationTreeNodeIds
      .map(id => virtualLocationTreeNodesById[id])
  );
}

export default selectVirtualLocationTreeNodes;
