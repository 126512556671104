import styled from '@emotion/styled';
import {
  navigationWidth,
  navigationClearance,
  floatingListActionsClearance,
  floatingListActionsWidth,
  appBarHeight,
  gutter,
} from 'constants/layout';

const Content = styled.div`
  padding-top: ${appBarHeight + gutter}px;
  padding-right: ${floatingListActionsWidth + 2 * floatingListActionsClearance + gutter * 2}px;
  padding-bottom: ${gutter}px;
  padding-left: ${navigationWidth + 2 * navigationClearance + gutter * 2}px;
  min-height: 100%;
`;

export default Content;
