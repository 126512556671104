import defaultConfig from '../config/defaultConfig';
import configWithContentType from '../config/configWithContentType';
import {POST} from '../verbs';

export default async function POST__verifyEmail(emailValidationToken) {

  return await POST(
    '/verify-email',
    configWithContentType(defaultConfig(), 'application/json'),
    {
      emailValidationToken,
    },
  );
}
