import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__nodesLinks(authToken, perspectiveId, nodeId, params) {

  let url = `/node-links?nodeId=${nodeId}`;

  if (params) {
    url += `&include=${params.join(',')}`;
  }

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
