import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Typography from 'ui-library/components/Typography';
import Select from 'ui-library/components/Select';
import Chip from 'ui-library/components/Chip';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import {
  greySemi,
} from 'ui-library/color-palette';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genLocationCandidateRoots from 'services/Locations/genLocationCandidateRoots';
import genLocationCandidatesTree from 'services/Locations/genLocationCandidatesTree';

import {
  TYPE_STEP,
  RECORD_STEP,
  NICKNAME_STEP,
  DETAILS_STEP,
} from 'constants/addItem.js';

const Description = styled.div`
  margin-bottom: 18px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid ${greySemi};
  flex-wrap: wrap;
`;

const ItemLocationStep = ({
  active,
  itemTypeId,
  itemTypeName,
  itemNickname,
  itemRecordTypeName,
  itemRecordTypeId,
  navigateToStep,
  dispatchNodeAction,
}) => {
  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const ref = React.createRef();

  const [isLoading, setIsLoading] = React.useState(true);
  const [locationNodes, setLocationNodes] = React.useState([]);
  const [locationPath, setLocationPath] = React.useState('No Location');
  const [isLocationValid, setIsLocationValid] = React.useState(true);
  const [locationCandidateRoots, setLocationCandidateRoots] = React.useState([]);
  const [locationCandidateOptions, setLocationCandidateOptions] = React.useState([]);
  const [activeCandidateRoot, setActiveCandidateRoot] = React.useState(null);
  const [locationCandidatesTree, setLocationCandidatesTree] = React.useState([]);
  const [locationLevels, setLocationLevels] = React.useState([]);
  const [isPristine, setIsPristine] = React.useState(true);

  React.useEffect(() => {
    setActiveCandidateRoot(null);
    setLocationNodes([]);
    setLocationCandidatesTree([]);
    setLocationLevels([]);
  }, [itemTypeName, itemRecordTypeId]);

  // generate the location candidate roots
  React.useEffect(() => {
    const loadLocationCandidateRoots = async() => {
      setIsLoading(true);
      const response = await genLocationCandidateRoots(authToken, currentPerspectiveId, itemTypeId, itemRecordTypeId);
      setLocationCandidateRoots([
        {
          id: 0,
          isLocationCandidate: true,
          locationId: null,
          name: 'No Location',
        },
        ...response,
      ]);
      setIsLoading(false);
    };

    if (active) {
      loadLocationCandidateRoots();
    }
  }, [active, authToken, currentPerspectiveId, itemRecordTypeId, itemTypeId]);

  // generate the select options for the candidate roots
  React.useEffect(() => {
    if (active) {
      const options = locationCandidateRoots.map((locationCandidateRoot) => {
        return {
          label: locationCandidateRoot.name,
          value: locationCandidateRoot.id,
        };
      });
      setLocationCandidateOptions(options);
    }
  }, [active, locationCandidateRoots]);

  // generate the location candidates tree
  React.useEffect(() => {
    const loadLocationCandidatesTree = async() => {
      if (isPristine === true) {
        setIsLoading(true);
        setLocationNodes([activeCandidateRoot]);
        const response = await genLocationCandidatesTree(authToken, currentPerspectiveId, itemTypeId, itemRecordTypeId, activeCandidateRoot.id);
        setLocationCandidatesTree(response);
        setLocationLevels([{
          id: activeCandidateRoot.id,
          nodes: response.filter(node => node.locationId === activeCandidateRoot.id),
        }]);
        setIsLoading(false);
      }
    };

    if (active && activeCandidateRoot) {
      if (activeCandidateRoot.id !== 0) {
        loadLocationCandidatesTree();
      }

    }
  }, [active, authToken, currentPerspectiveId, itemRecordTypeId, itemTypeId, activeCandidateRoot, isPristine]);

  // generate the location path
  React.useEffect(() => {
    if (active) {
      const path = locationNodes.length > 0 ? locationNodes.map((node) => node.name).join(' / ') : 'No Location';
      setLocationPath(path);
      if (locationNodes.length === 0) {
        setIsLocationValid(true);
      } else {
        setIsLocationValid(locationNodes[locationNodes.length - 1].isLocationCandidate);
      }
    }
  }, [active, activeCandidateRoot, locationNodes]);

  React.useEffect(() => {
    if (active && ref.current && isLocationValid) {
      ref.current.focus();
    }
  }, [active, isLocationValid, ref]);

  const handleCandidateRootChange = (event) => {
    setIsPristine(true);
    const candidateRoot = locationCandidateRoots.filter(node => node.id === event.target.value)[0];
    setActiveCandidateRoot(candidateRoot);
    if (candidateRoot.id === 0) {
      setLocationNodes([]);
      setLocationCandidatesTree([]);
      setLocationLevels([]);
    }
  };

  const getNodeLevelIndex = (node) => {
    const {
      locationId,
    } = node;

    const isSelectedNode = (element) => {
      const {
        id,
      } = element;
      let found = true;

      if (id !== locationId) {
        found = false;
      }
      return found;
    };
    const nodeLevelIndex = locationLevels.findIndex(isSelectedNode);

    return nodeLevelIndex;
  };

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Item
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="location-step-type-breadcrumb"
            textTransform="capitalize"
            onClick={() => navigateToStep(TYPE_STEP)}>
            {itemTypeName}
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="location-step-assign-to-record-breadcrumb"
            onClick={() => navigateToStep(RECORD_STEP)}>
            {itemRecordTypeName}
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="location-step-nickname-breadcrumb"
            onClick={() => navigateToStep(NICKNAME_STEP)}>
            {itemNickname}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Location
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Home Information contains all items that would be sold with the property whereas Personal Property are items that you would retain if the property was sold.
        </WizardStepDescription>
      </WizardStepHead>

      <WizardContentColumn>
        <form onSubmit={(event) => {
          event.preventDefault();
          dispatchNodeAction({
            type: 'SET_LOCATION',
            payload: locationNodes.length === 0 ? 'Location' : locationNodes[locationNodes.length - 1],
          });
          navigateToStep(DETAILS_STEP);
        }}>
          <WizardStepContent>
            {
              isLoading ?
                <CenterpieceSpinner />
                :
                locationCandidateRoots.length <= 1 ?
                  <>
                    <Description>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                      >
                        No locations available
                      </Typography>
                      <br/>
                      <Typography
                        variant="caption"
                      >
                        This item cannot be located at the moment.
                      </Typography>
                    </Description>
                  </>
                  :
                  <>
                    <Description>
                      <Typography
                        variant="h5"
                        fontWeight="bold">
                      Location path
                      </Typography>
                      <br/>
                      <Typography
                        variant="caption"
                        color={!locationNodes || locationNodes.length === 0 || locationPath === 'No Location' ? 'grey' : locationNodes[locationNodes.length - 1].isLocationCandidate ? 'primary' : 'error'}>
                        {locationPath}
                      </Typography>
                    </Description>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <Description>
                      <Typography
                        variant="h5"
                        fontWeight="bold">
                      Add a location for your item
                      </Typography>
                    </Description>
                    <Select
                      data-test-id="add-item-location-select"
                      fullWidth={true}
                      value={activeCandidateRoot ? activeCandidateRoot.id : ''}
                      onChange={handleCandidateRootChange}
                      placeholder="Select from this list..."
                      autoFocus
                      onFocus={() => {
                        if (isLocationValid) {
                          ref.current.focus();
                        }
                      }}
                      customOptions={locationCandidateOptions}
                    />
                    <div>
                      {
                        locationLevels.map((level, index) => {
                          const {
                            id,
                            nodes,
                          } = level;

                          if (nodes.length === 0) {
                            return null;
                          }

                          return (
                            <Row
                              key={id}
                            >
                              {
                                nodes.length > 0 &&
                                nodes.map((node, index) => {
                                  const {
                                    id,
                                    name,
                                  } = node;

                                  return (
                                    <Chip
                                      key={index}
                                      isSelected={locationNodes.filter(node => node.id === id).length > 0}
                                      isLast={locationNodes[locationNodes.length - 1].id === id}
                                      isLocationValid={isLocationValid}
                                      label={name}
                                      onClick={() => {
                                        setIsPristine(false);
                                        // find the node level
                                        const nodeLevelIndex = getNodeLevelIndex(node);

                                        setLocationNodes([
                                          ...locationNodes.slice(0, nodeLevelIndex + 1),
                                          node,
                                        ]);

                                        setLocationLevels([
                                          ...locationLevels.slice(0, nodeLevelIndex + 1),
                                          {
                                            id,
                                            nodes: locationCandidatesTree.filter(node => node.locationId === id),
                                          },
                                        ]);
                                      }}
                                    />
                                  );
                                })
                              }
                            </Row>
                          );
                        })
                      }
                    </div>
                  </>
            }
          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              ref={ref}
              data-test-id="location-step-continue-button"
              variant="primary"
              disabled={!isLocationValid}
            >
              {
                locationNodes.length === 0 ?
                  <>
                    Continue without a location&nbsp;<ForwardIcon/>
                  </>
                  :
                  <>
                    Continue&nbsp;<ForwardIcon/>
                  </>
              }
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ItemLocationStep.propTypes = {
  active: PropTypes.bool,
  itemTypeId: PropTypes.number,
  itemTypeName: PropTypes.string,
  itemNickname: PropTypes.string,
  itemRecordTypeId: PropTypes.number,
  navigateToStep: PropTypes.func,
};

export default ItemLocationStep;
