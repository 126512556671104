import React from 'react';

import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import Message from 'ui-library/components/Message';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import Typography from 'ui-library/components/Typography';
import WarningIcon from '@material-ui/icons/WarningRounded';

import PerspectiveContext from 'contexts/Perspective';
import UserContext from 'contexts/User';
import UserInterfaceContext from 'contexts/UserInterface';

import deleteConversationService from 'services/Conversations/deleteConversation';

export default () => {
  const {
    authToken,
  } = React.useContext(UserContext);
  const {
    currentPerspective: {
      id: perspectiveId,
    },
  } = React.useContext(PerspectiveContext);
  const {
    deleteRecordConversation,
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState(null);

  return (
    <>
      <ModalCard
        open={!!deleteRecordConversation}
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick={true}
      >
        <ModalCardHeader color="error">
          <ModalCardHeaderIcon color="error">
            <WarningIcon
              fontSize="inherit"
            />
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
          Delete Conversation
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent>
          <Typography variant="body">
          Are you sure you want to delete the conversation
          with <Typography variant="body" fontWeight="bold">
              {deleteRecordConversation && deleteRecordConversation.connectionName ? deleteRecordConversation.connectionName : 'connection'}
            </Typography>?
          </Typography>
        </ModalCardContent>
        <ModalCardActions>
          <Button
            data-test-id="delete-conversation-cancel-button"
            variant="text"
            onClick={() => {
              dispatchUserInterfaceAction({
                type: 'SET_DELETE_RECORD_CONVERSATION',
                payload: null,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_RECORD_PANEL',
                payload: true,
              });
            }}
          >
            CANCEL
          </Button>
          <Button
            data-test-id="delete-conversation-delete-button"
            variant="custom"
            textTransform="none"
            customBgColor="error"
            customHoverBgColor="error-dark"
            customTextColor="white"
            customHoverTextColor="white"
            customActiveBgColor="error-darker"
            customActiveTextColor="white"
            startIcon={isDeleting ? <CircularProgress size={24} color="white"/> : <DeleteIcon />}
            disabled={isDeleting}
            onClick={async() => {
              setIsDeleting(true);

              try {
                await deleteConversationService({
                  authToken,
                  perspectiveId,
                  conversationId: deleteRecordConversation.id,
                });
                setDeleteMessage({
                  variant: 'success',
                  messageBody: 'Conversation deleted successfully.',
                });
              } catch (e) {
                setDeleteMessage({
                  variant: 'error',
                  messageBody: 'Something went wrong. Please try again.',
                });
              }

              dispatchUserInterfaceAction({
                type: 'SET_DELETE_RECORD_CONVERSATION',
                payload: null,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_RECORD_PANEL',
                payload: true,
              });
              dispatchUserInterfaceAction({
                type: 'SET_RELOAD_RECORD_CONVERSATION_TAB',
                payload: true,
              });
              setIsDeleting(false);
            }}
          >
            DELETE
          </Button>
        </ModalCardActions>
      </ModalCard>

      <Message
        variant={deleteMessage ? deleteMessage.variant : 'error'}
        open={!!deleteMessage}
        onClose={() => setDeleteMessage(null)}
        messageBody={deleteMessage ? deleteMessage.messageBody : 'error'}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    </>
  );
};
