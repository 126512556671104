import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ListItem.css';

import {
  COLOR_MONIKERS,
  white,
  primaryLightest,
} from 'ui-library/color-palette';

const ListItem = React.forwardRef(({
  children,
  onClick,
  backgroundColor = white,
  isSelected,
  marginTop,
  border,
}, ref) => {

  return (
    <div
      ref={ref}
      onClick={onClick}
      style={{
        backgroundColor: isSelected ? primaryLightest : backgroundColor ? backgroundColor : white,
        marginTop,
        border,
      }}
      className={classNames({
        'ListItem': true,
        selected: isSelected,
      })}
    >
      {children}
    </div>
  );
});

ListItem.displayName = '';

ListItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.oneOf([...COLOR_MONIKERS]),
};

export default ListItem;
