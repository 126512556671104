import {
  RECORD_ATOMIC_PERMISSIONS,
} from './PERMISSION_FLAGS';

function recordAtomicPermissionsToPermissionsInt(atomicPermissions) {
  if (typeof atomicPermissions !== 'object' || atomicPermissions === null) {
    throw new Error(`Invalid Record atomic permissions! Expected an object. Received ${typeof atomicPermissions}.`);
  }

  const receivedAtomicPermissions = Object.keys(atomicPermissions);
  const missingAtomicPermissions = [];
  for (let requiredAtomicPermission of RECORD_ATOMIC_PERMISSIONS) {
    if (!receivedAtomicPermissions.includes(requiredAtomicPermission)) {
      missingAtomicPermissions.push(requiredAtomicPermission);
    }
  }
  if (missingAtomicPermissions.length) {
    throw new Error(`Incomplete Record atomic permissions! Missing permissions: ${missingAtomicPermissions.map(missingAtomicPermission => `"${missingAtomicPermission}"`).join(', ')}.`);
  }

  let permissionBitString = '';

  for (let atomicPermission of RECORD_ATOMIC_PERMISSIONS) {
    permissionBitString += atomicPermissions[atomicPermission] ? 1 : 0;
  }

  return parseInt(permissionBitString, 2);
}

export default recordAtomicPermissionsToPermissionsInt;
