// returns a config that provides an "Authorization" header and an "Perspective" header

export default function configWithAuthorizationAndPerspectiveWithoutDefaultHeaders(config, authToken, perspectiveId) {
  return {
    ...config,
    headers: {
      'Authorization': authToken,
      'Perspective': perspectiveId,
    },
  };
}
