import selectVirtualLocationTreeNodes from './selectVirtualLocationTreeNodes';

function selectFirstVirtualLocationTreeNodeByHintId(
  configurationState,
  virtualLocationTreeNodeHintId,
) {
  const firstVirtualLocationTreeNodeIdByHintId =
    selectVirtualLocationTreeNodes(configurationState)
      .find(virtualLocationTreeNode => virtualLocationTreeNode.hintId === virtualLocationTreeNodeHintId);

  if (firstVirtualLocationTreeNodeIdByHintId === undefined) {
    return null;
  }

  return firstVirtualLocationTreeNodeIdByHintId;
}

export default selectFirstVirtualLocationTreeNodeByHintId;
