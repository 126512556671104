import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

import Typography from 'ui-library/components/Typography';

import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/WarningRounded';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: WarningIcon,
  info: InfoIcon,
};

const ALLOWED_VARIANTS = [
  'success',
  'warning',
  'error',
  'info',
];

const useStyles = makeStyles({
  root: {
    width: props => props.width,
    borderRadius: theme.snackBar.root.borderRadius,
  },
  content: {
    backgroundColor: props => theme.snackBar[props.variant].backgroundColor,
    flexWrap: props => props.withTitle === true ? theme.snackBar.withTitle.flexWrap : theme.snackBar.noTitle.flexWrap,
    alignItems: props => props.withTitle === true ? theme.snackBar.withTitle.alignItems : theme.snackBar.noTitle.alignItems,
  },
  icon: {
    fontSize: theme.snackBar.icon.fontSize,
    opacity: theme.snackBar.icon.opacity,
    color: props => theme.snackBar[props.variant].color,
  },
  closeIcon: {
    fontSize: theme.snackBar.closeIcon.fontSize,
    opacity: theme.snackBar.closeIcon.opacity,
    color: props => theme.snackBar[props.variant].color,
  },
  messageContainer: theme.snackBar.messageContainer,
  message: {
    paddingLeft: theme.snackBar.message.paddingLeft,
    color: props => theme.snackBar[props.variant].color,
  },
});

const Message = ({
  variant,
  messageTitle,
  messageBody,
  open,
  onClose,
  autoHideDuration = 6000,
  width,
  anchorOrigin,
  ...otherProps
}) => {
  const classes = useStyles({
    variant: ALLOWED_VARIANTS.indexOf(variant) > -1 ? variant : 'info',
    width: width ? width : '100%',
    withTitle: messageTitle ? true : false,
  });
  const Icon = variantIcon[variant];

  return (
    <Snackbar
      className={classes.root}
      open={open}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      {...otherProps}
    >
      <SnackbarContent
        classes={{
          root: classes.content,
        }}
        message={
          <div id="client-snackbar" className={classes.messageContainer}>
            <Icon className={classes.icon} />
            <div className={classes.message}>
              {
                messageTitle &&
                <div>
                  <Typography
                    variant="body"
                    fontWeight="bold">
                    {messageTitle}
                  </Typography>
                </div>
              }
              {messageBody}
            </div>
          </div>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

Message.propTypes = {
  variant: PropTypes.oneOf([
    'success',
    'error',
    'warning',
    'info',
  ]),
  messageTitle: PropTypes.string,
  messageBody: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  width: PropTypes.number,
  anchorOrigin: PropTypes.exact({
    vertical: PropTypes.oneOf(['top', 'bottom']),
    horizontal: PropTypes.oneOf(['center', 'left', 'right']),
  }),
};

export default Message;
