import React from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import EHRTypeList from 'components/EHRTypeList';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genEHRHubByRecordId from 'services/EHR/genEHRHubByRecordId.js';

import {
  PERSON_STEP,
} from 'constants/addItem.js';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin: 32px 0px;
  }
`;

const EHRItemStep = ({
  active,
  personName,
  healthRecordId,
  onSetEHRItem,
  navigateToStep,
  nodeSaveState,
  history,
}) => {

  const [types, setTypes] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const loadHealthTypes = React.useCallback(async() => {
    setIsLoading(true);
    const healthTypes = await genEHRHubByRecordId(
      authToken,
      currentPerspectiveId,
      healthRecordId,
    );

    healthTypes.sort((obj1, obj2) => {
      const {
        displayIndex: displayIndexA,
      } = obj1;
      const {
        displayIndex: displayIndexB,
      } = obj2;
      return displayIndexA - displayIndexB;
    });

    setTypes(healthTypes);
    setIsLoading(false);
  }, [authToken, currentPerspectiveId, healthRecordId]);

  React.useEffect(() => {
    if (!healthRecordId) {
      return;
    }
    loadHealthTypes();
  }, [healthRecordId, loadHealthTypes]);

  React.useEffect(() => {
    if (nodeSaveState === 'saved' && healthRecordId) {
      loadHealthTypes();
    }
  }, [healthRecordId, loadHealthTypes, nodeSaveState]);

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add eHR
          </WizardBreadcrumb>
          <WizardBreadcrumb onClick={() => navigateToStep(PERSON_STEP)}>
            {personName}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Files
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          The Electronic Health record is comprised of multiple Jules items.
          Select the health information you would like to add.
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        {
          isLoading ?
            <CenterpieceSpinner /> :
            <>
              <WizardStepContent>
                <Typography variant="lead">
                  Select the health information item you wish to add
                </Typography>
                <EHRTypeList
                  eHRTypes={types}
                  onClickType={(typeId, typeName, typeCount) => {
                    onSetEHRItem({
                      typeId,
                      typeName,
                      typeCount,
                    });
                  }}
                />
              </WizardStepContent>
              {
                nodeSaveState === 'saved' &&
                <Actions>
                  <Button
                    data-test-id="add-ehr-item-wizard-finish-button"
                    variant="primary"
                    onClick={() => {
                      history.push(`/library/records/${healthRecordId}/nodes`);
                    }}>
                      Finish&nbsp;<ForwardIcon/>
                  </Button>
                </Actions>
              }
            </>
        }
      </WizardContentColumn>
    </WizardStep>
  );
};

EHRItemStep.propTypes = {
  onSetEHRItem: PropTypes.func.isRequired,
  active: PropTypes.bool,
  healthRecordId: PropTypes.number,
  personName: PropTypes.string,
  nodeSaveState: PropTypes.string,
  navigateToStep: PropTypes.func,
};

export default withRouter(EHRItemStep);
