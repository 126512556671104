import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    width: theme.toggle.width,
    height: theme.toggle.height,
    padding: theme.toggle.padding,
    borderRadius: theme.toggle.borderRadius,
    display: theme.toggle.display,
    margin: theme.toggle.margin,
    '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
      '& + $track': {
        opacity: theme.toggle['checkedDisabled'].opacity,
      },
    },
  },
  switchBase: {
    padding: theme.toggle['switch-base'].padding,
    color: theme.toggle['switch-base'].color,
    top: theme.toggle['switch-base'].top,
    bottom: theme.toggle['switch-base'].bottom,
    left: theme.toggle['switch-base'].left,
    '&:hover': {
      background: theme.toggle['switch-base'].background,
    },
    '&$checked': {
      transform: theme.toggle['checked'].transform,
      '& + $track': {
        opacity: theme.toggle['checked'].opacity,
        backgroundColor: theme.toggle['checked'].backgroundColor,
        borderColor: theme.toggle['checked'].borderColor,
      },
      '&:hover': {
        background: theme.toggle['checked'].background,
      },
    },
    '&$disabled + $track': {
      border: theme.toggle['disabled'].border,
      opacity: theme.toggle['disabled'].opacity,
      backgroundColor: theme.toggle['disabled'].backgroundColor,
    },
  },
  thumb: {
    width: theme.toggle.thumb.width,
    height: theme.toggle.thumb.height,
    boxShadow: theme.toggle.thumb.boxShadow,
    borderRadius: theme.toggle.thumb.boxShadow,
    backgroundColor: theme.toggle.thumb.backgroundColor,
  },
  track: {
    borderRadius: theme.toggle.track.borderRadius,
    border: theme.toggle.track.border,
    backgroundColor: theme.toggle.track.backgroundColor,
    opacity: theme.toggle.track.opacity,
    transitionProperty: theme.toggle.track.transitionProperty,
    transitionDuration: theme.toggle.track.transitionDuration,
    transitionTimingFunction: theme.toggle.track.transitionTimingFunction,
  },
  checked: {},
  disabled: {},
});

const ToggleSwitch = ({
  ...otherProps
}) => {

  const classes = useStyles();

  return (
    <Switch
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...otherProps}
    />
  );
};

ToggleSwitch.propTypes = {
  label: PropTypes.string,
};

export default ToggleSwitch;
