import React from 'react';
import styled from '@emotion/styled';

import Tabs from '@material-ui/core/Tabs';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import PanelIcon from '@material-ui/icons/VerticalSplitRounded';
import {makeStyles} from '@material-ui/styles';

import theme from 'ui-library/theme';
import Button from 'ui-library/components/Button';

const TabsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  border-bottom: ${theme.personTabs.tabs.borderBottom};
`;
const ActionButtonsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;
const ButtonContainer = styled.div`
  display: flex,
  flex-direction: column;
  justify-content: center;
  padding-top: 5px;
`;

const useStyles = makeStyles({
  root: {
    padding: theme.personTabs.tabs.padding,
    '& .MuiTabs-indicator': {
      backgroundColor: theme.personTabs.tabIndicator.backgroundColor,
      height: theme.personTabs.tabIndicator.height,
      borderRadius: theme.personTabs.tabIndicator.borderRadius,
    },
    '& .MuiTab-root': {
      textTransform: theme.personTabs.tab.textTransform,
    },
  },
});
const PersonTabs = ({
  children,
  selectedTab,
  handleShareRecord,
  handleShowRecord,
}) => {

  const classes = useStyles();

  return (
    <TabsContainer>
      <Tabs
        className={classes.root}
        value={selectedTab}>
        {children}
      </Tabs>
      <ActionButtonsContainer>
        <ButtonContainer>
          <Button
            data-test-id="share-record-button"
            textTransform="none"
            variant="icon-custom"
            customBgColor="transparent"
            customHoverBgColor="primary-lightest"
            customTextColor="grey"
            customHoverTextColor="primary-dark"
            customActiveBgColor="white"
            customActiveTextColor="primary-darker"
            onClick={event => {
              event.stopPropagation();
              handleShareRecord();
            }}>
            <ShareRoundedIcon/>
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            data-test-id="item-open-record-panel-button"
            textTransform="none"
            variant="icon-custom"
            customBgColor="transparent"
            customHoverBgColor="primary-lightest"
            customTextColor="grey"
            customHoverTextColor="primary-dark"
            customActiveBgColor="white"
            customActiveTextColor="primary-darker"
            onClick={event => {
              event.stopPropagation();
              handleShowRecord();
            }}>
            <PanelIcon/>
          </Button>
        </ButtonContainer>
      </ActionButtonsContainer>
    </TabsContainer>
  );
};

export default PersonTabs;
