import React from 'react';
import PageContainer from 'components/chrome/PageContainer';
import PageTitle from 'components/chrome/PageTitle';
import PageDescription from 'components/chrome/PageDescription';
import PageContent from 'components/chrome/PageContent';
import LibraryFilterInput from 'components/LibraryFilterInput';
import ConversationList from './ConversationList';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genFrozenConversations from 'services/Conversations/genFrozenConversations';

import debounce from 'utils/debounce';

const deboucedFilter = debounce((conversations, filter, setFilteredFrozenConversations) => {
  setFilteredFrozenConversations(
    conversations.filter(
      conversation =>
        conversation.connectionName.toLowerCase().includes(
          filter.toLowerCase(),
        )
        ||
        conversation.interlocutorOrganizationName.toLowerCase().includes(
          filter.toLowerCase(),
        ),
    ),
  );
});

const ConversationVault = () => {
  const {
    authToken,
  } = React.useContext(UserContext);

  const {
    currentPerspective: {
      id: perspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  let [filter, setFilter] = React.useState('');
  let [frozenConversations, setFrozenConversations] = React.useState([]);
  let [filteredFrozenConversations, setFilteredFrozenConversations] = React.useState(null);

  React.useEffect(() => {
    const fetchFrozenConversations = async() => {
      const conversations = await genFrozenConversations({
        authToken,
        perspectiveId,
      });

      setFrozenConversations(conversations);
      setFilteredFrozenConversations(conversations);
    };

    fetchFrozenConversations();
  }, [authToken, perspectiveId]);

  return (
    <PageContainer>
      <PageTitle>
        Conversation Vault
      </PageTitle>
      <PageDescription>
        These are conversations where you lost access to the Item, Record, or Connection the conversation was associated with.
      </PageDescription>
      <PageContent>
        <LibraryFilterInput
          type="text"
          value={filter}
          onChange={event => {
            setFilter(event.target.value);
            deboucedFilter(frozenConversations, event.target.value, setFilteredFrozenConversations);
          }}
          placeholder="Type to search..."
        />

        <ConversationList
          conversations={filteredFrozenConversations}
          isEmpty={frozenConversations.length === 0}
          onDelete={async() => {
            const conversations = await genFrozenConversations({
              authToken,
              perspectiveId,
            });

            setFrozenConversations(conversations);
            setFilteredFrozenConversations(conversations);
          }}
        />
      </PageContent>
    </PageContainer>
  );
};

export default ConversationVault;
