import React from 'react';
import AdminContext from 'contexts/Admin';
import Button from '@material-ui/core/Button';
import CreateVirtualLocationTreeNodeHintDialog from './CreateVirtualLocationTreeNodeHintDialog.react';
import VirtualLocationTreeNodeHint from './VirtualLocationTreeNodeHint.react';
import Typography from '@material-ui/core/Typography';
import Prompt from 'components/admin/Prompt';

const VirtualLocationTreeNodeHints = ({
  match,
}) => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
  } = React.useContext(AdminContext);
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  const propertyConfigurationHint = DataModelQueries.selectPropertyConfigurationHintById(DataModel, match.params.id);
  const virtualLocationTreeNodeHints = DataModelQueries.selectVirtualLocationTreeNodeHintsOfPropertyConfigurationHint(
    DataModel,
    propertyConfigurationHint.id,
  );

  const HOMERECORDTYPES = DataModelQueries.selectTypeIdsInRecordTypeById(
    DataModel,
    1,
  );

  // console.log(localState);

  return (
    <div>
      <Typography
        variant="h4">
        Configurator hints
      </Typography>
      <Typography
        variant="body2">
        edit or create location or item hints for this configurator
      </Typography>
      <br/><br/>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatchLocalAction({
            type: 'CREATE',
            payload: {
              parentId: null,
              parentTypeId: propertyConfigurationHint.targetTypeId,
            },
          });
        }}>
        add top-level hint
      </Button>
      <br/><br/><br/>

      {
        virtualLocationTreeNodeHints
          .filter(virtualLocationTreeNodeHint => {
            const {
              parentId,
            } = virtualLocationTreeNodeHint;
            return parentId === null;
          })
          .map(virtualLocationTreeNodeHint => {
            const {
              id,
            } = virtualLocationTreeNodeHint;
            return (
              <VirtualLocationTreeNodeHint
                key={id}
                virtualLocationTreeNodeHints={virtualLocationTreeNodeHints}
                currentVirtualLocationTreeNodeHint={virtualLocationTreeNodeHint}
                DataModel={DataModel}
                DataModelQueries={DataModelQueries}
                onCreate={(parentId, parentTypeId) => {
                  dispatchLocalAction({
                    type: 'CREATE',
                    payload: {
                      parentId,
                      parentTypeId,
                    },
                  });
                }}
                onDelete={virtualLocationTreeNodeHintId => {
                  dispatchLocalAction({
                    type: 'DELETE',
                    payload: virtualLocationTreeNodeHintId,
                  });
                }}
              />
            );
          })
      }

      <CreateVirtualLocationTreeNodeHintDialog
        open={localState.status === 'creating'}
        onClose={() => {
          dispatchLocalAction({
            type: 'RESET',
          });
        }}
        candidateTypes={
          DataModelQueries.selectTypesByIds(
            DataModel,
            DataModelQueries.selectTypeLocationGraphChildIdsOfTypeById(
              DataModel,
              localState.parentTypeId,
            ),
          )
            // YIKES!
            .filter(type => {
              const {
                id: typeId,
              } = type;
              return HOMERECORDTYPES.includes(typeId);
            })
        }
        typeId={localState.typeId}
        onSetTypeId={value => {
          dispatchLocalAction({
            type: 'SET_TYPE_ID',
            payload: value,
          });
        }}
        text={localState.text}
        onSetText={value => {
          dispatchLocalAction({
            type: 'SET_TEXT',
            payload: value,
          });
        }}
        prevalence={localState.prevalence}
        onSetPrevalence={value => {
          dispatchLocalAction({
            type: 'SET_PREVALENCE',
            payload: value,
          });
        }}
        prevalenceWeight={localState.prevalenceWeight}
        onSetPrevalenceWeight={value => {
          dispatchLocalAction({
            type: 'SET_PREVALENCE_WEIGHT',
            payload: value,
          });
        }}
        onSave={async() => {
          const {
            parentId,
            typeId,
            text,
            prevalence,
            prevalenceWeight,
          } = localState;
          dispatchLocalAction({
            type: 'REQUEST_CREATE',
          });
          try {
            const newVirtualLocationTreeNodeHint = await DataModelAPI.createVirtualLocationTreeNodeHint(
              authToken,
              perspectiveId,
              {
                propertyConfigurationHintId: propertyConfigurationHint.id,
                typeId,
                parentId,
                text,
                prevalence,
                prevalenceWeight,
              },
            );
            dispatchLocalAction({
              type: 'RESET',
            });
            dispatchDataModelAction({
              type: 'ADD_VIRTUAL_LOCATION_TREE_NODE_HINT',
              payload: newVirtualLocationTreeNodeHint,
            });
          } catch(error) {
            throw error;
          }
        }}
      />

      {
        localState.status === 'pending create' &&
          <Prompt
            open
            title="Please wait..."
            text="Creating hint..."
          />
      }

      {
        localState.status === 'deleting' &&
          <Prompt
            open
            title="Confirm operation"
            text="Are you sure you want to delete this hint?"
            confirmLabel="delete hint"
            onCancel={() => {
              dispatchLocalAction({
                type: 'RESET',
              });
            }}
            onConfirm={async() => {
              const {
                id: virtualLocationTreeNodeHintId,
              } = localState;
              dispatchLocalAction({
                type: 'REQUEST_DELETE',
              });
              try {
                await DataModelAPI.deleteVirtualLocationTreeNodeHint(
                  authToken,
                  perspectiveId,
                  {
                    virtualLocationTreeNodeHintId,
                  },
                );
                const virtualLocationTreeNodeHintIdsToRemove = [
                  virtualLocationTreeNodeHintId,
                  ...DataModelQueries.selectVirtualLocationTreeNodeHintDescendantIdsById(
                    DataModel,
                    virtualLocationTreeNodeHintId,
                  ),
                ];
                dispatchDataModelAction({
                  type: 'REMOVE_VIRTUAL_LOCATION_TREE_NODE_HINTS',
                  payload: virtualLocationTreeNodeHintIdsToRemove,
                });
                dispatchLocalAction({
                  type: 'RESET',
                });
              } catch(error) {
                throw error;
              }
            }}
          />
      }

      {
        localState.status === 'pending delete' &&
          <Prompt
            open
            title="Please wait..."
            text="Deleting hint..."
          />
      }

    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'CREATE': {
      const {
        parentId,
        parentTypeId,
      } = payload;

      return {
        ...state,
        status: 'creating',
        parentId,
        parentTypeId,
      };
    }

    case 'REQUEST_CREATE': {
      return {
        ...state,
        status: 'pending create',
      };
    }

    case 'DELETE': {
      const id = payload;

      return {
        ...state,
        status: 'deleting',
        id,
      };
    }

    case 'REQUEST_DELETE': {
      return {
        ...state,
        status: 'pending delete',
      };
    }

    case 'SET_TYPE_ID': {
      const typeId = payload;

      return {
        ...state,
        typeId,
      };
    }

    case 'SET_TEXT': {
      const text = payload;

      return {
        ...state,
        text,
      };
    }

    case 'SET_PREVALENCE': {
      const prevalence = payload;

      return {
        ...state,
        prevalence,
      };
    }

    case 'SET_PREVALENCE_WEIGHT': {
      const prevalenceWeight = payload;

      return {
        ...state,
        prevalenceWeight,
      };
    }

    case 'RESET': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      creating
      pending create
      deleting
      pending delete
    */
    status: 'idle',
    id: '',
    parentId: '',
    parentTypeId: '',
    typeId: '',
    text: '',
    prevalence: '',
    prevalenceWeight: '',
  };
}

export default VirtualLocationTreeNodeHints;
