function initAccountState() {
  return {
    firstName: {
      value: '',
      previousValue: '',
      errorMessage: '',
      isSaving: false,
      isFocused: false,
      isEditable: false,
    },
    lastName: {
      value: '',
      previousValue: '',
      errorMessage: '',
      isSaving: false,
      isFocused: false,
      isEditable: false,
    },
    phoneNumber: {
      value: '',
      previousValue: '',
      errorMessage: '',
      isSaving: false,
      isFocused: false,
      isEditable: false,
    },
  };
}

function accountReducer(state, {type: actionType, payload}) {
  switch(actionType) {
    case 'HYDRATE_ACCOUNT': {
      const {
        firstName,
        lastName,
        phoneNumber,
      } = payload;

      return {
        ...state,
        firstName: {
          ...firstName,
        },
        lastName: {
          ...lastName,
        },
        phoneNumber: {
          ...phoneNumber,
        },
      };
    }
    case 'RESET_ACCOUNT': {
      return {
        firstName: {
          value: '',
          previousValue: '',
          errorMessage: '',
          isSaving: false,
          isFocused: false,
          isEditable: false,
        },
        lastName: {
          value: '',
          previousValue: '',
          errorMessage: '',
          isSaving: false,
          isFocused: false,
          isEditable: false,
        },
        phoneNumber: {
          value: '',
          previousValue: '',
          errorMessage: '',
          isSaving: false,
          isFocused: false,
          isEditable: false,
        },
      };
    }
    case 'SET_FIELD_IS_EDITABLE': {
      const {
        fieldName,
        isEditable,
      } = payload;

      const {
        [fieldName]: field,
      } = state;

      return {
        ...state,
        [fieldName]: {
          ...field,
          isEditable,
        },
      };
    }
    case 'SET_FIELD_IS_SAVING': {
      const {
        fieldName,
        isSaving,
      } = payload;

      const {
        [fieldName]: field,
      } = state;

      return {
        ...state,
        [fieldName]: {
          ...field,
          isSaving,
        },
      };
    }
    case 'SET_FIELD_IS_FOCUSED': {
      const {
        fieldName,
        isFocused,
      } = payload;

      const {
        [fieldName]: field,
      } = state;

      return {
        ...state,
        [fieldName]: {
          ...field,
          isFocused,
        },
      };
    }
    case 'SET_FIELD_VALUE': {
      const {
        fieldName,
        value,
      } = payload;

      const {
        [fieldName]: field,
      } = state;

      return {
        ...state,
        [fieldName]: {
          ...field,
          value,
        },
      };
    }
    case 'SET_FIELD_PREVIOUS_VALUE': {
      const {
        fieldName,
        previousValue,
      } = payload;

      const {
        [fieldName]: field,
      } = state;

      return {
        ...state,
        [fieldName]: {
          ...field,
          previousValue,
        },
      };
    }
    case 'SET_FIELD_ERROR_MESSAGE': {
      const {
        fieldName,
        errorMessage,
      } = payload;

      const {
        [fieldName]: field,
      } = state;

      return {
        ...state,
        [fieldName]: {
          ...field,
          errorMessage,
        },
      };
    }
    default:
      throw new Error(`Unknown item action "${actionType}"!`);
  }
};

export {
  initAccountState,
  accountReducer,
};
