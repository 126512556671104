import React from 'react';
import styled from '@emotion/styled';
import LocationList from 'components/LocationList';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import Message from 'ui-library/components/Message';
import BatchActionButton from 'ui-library/components/BatchActionButton';
import BatchActionMenu from 'ui-library/components/BatchActionMenu';
import BatchDeleteWarningDialog from 'components/BatchDeleteWarningDialog';
import BatchDeleteDialog from 'components/BatchDeleteDialog';
import LibraryContext from './context';
import genRecordLocationRoots from 'services/Locations/genRecordLocationRoots';
import genLocationChildren from 'services/Locations/genLocationChildren';
import deleteNode from 'services/Nodes/deleteNode';
import queryString from 'query-string';
import SelectAllItems from 'components/SelectAllItems';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleSwitch from 'ui-library/components/ToggleSwitch';

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const ControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 32px;
`;

const ListContainer = styled.div`
`;

const InternalLocationsListRoute = ({
  match,
}) => {
  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
    currentPerspectiveId,
    nodeIdModifiedDestructivelyForLists,
    dispatchUserInterfaceAction,
    filter,
    pushAndClearFilter,
  } = React.useContext(LibraryContext);
  const [status, setStatus] = React.useState('fetching data');
  const [fatalError, setFatalError] = React.useState(null);
  const [locations, setLocations] = React.useState([]);
  const [selectedLocationIds, setSelectedLocationIds] = React.useState([]);

  const [showBatchDeleteWarningDialog, setShowBatchDeleteWarningDialog] = React.useState(false);
  const [showBatchDeleteDialog, setShowBatchDeleteDialog] = React.useState(false);
  const [showBatchDeleteSuccess, setShowBatchDeleteSuccess] = React.useState(false);

  const [error, setError] = React.useState(false);
  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        setAuthToken(null);
        setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  const searchString = window.location.search;

  const initialize = React.useCallback(async() => {
    const search = queryString.parse(searchString);
    const {
      locations: searchLocations,
    } = search;
    let locations = [];
    try {
      setStatus('fetching data');
      if (!searchLocations) {
        locations = await genRecordLocationRoots(
          authToken,
          currentPerspectiveId,
          {
            recordId: match.params.recordId,
          },
        );
      } else {
        const locationStack = searchLocations.split(',');
        locations = await genLocationChildren(
          authToken,
          currentPerspectiveId,
          {
            locationId: locationStack[locationStack.length - 1],
          },
        );
      }
      setLocations(locations);
      setStatus('ready');
    } catch(error) {
      setError(error);
    }
  },[authToken, currentPerspectiveId, match.params.recordId, searchString]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  React.useEffect(() => {
    if (nodeIdModifiedDestructivelyForLists) {
      initialize();
      dispatchUserInterfaceAction({
        type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: null,
      });
    }
  }, [dispatchUserInterfaceAction, initialize, nodeIdModifiedDestructivelyForLists]);

  const renderedLocations = locations.map(location => {
    return {
      ...location,
      isSelected: false,
    };
  }).filter(location => {
    if (!filter) {
      return true;
    }
    const {
      nodeName,
      typeName,
    } = location;
    return (
      nodeName.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
        typeName.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  }).sort((l1, l2) => {
    const {
      isTerminal: isL1Terminal,
    } = l1;
    const {
      isTerminal: isL2Terminal,
    } = l2;
    if (!isL1Terminal && isL2Terminal) {
      return -1;
    }
    if (isL1Terminal && !isL2Terminal) {
      return 1;
    }
    return 0;
  }).sort((l1, l2) => {
    const {
      locationDescendantsCount: location1DescendantsCount,
    } = l1;
    const {
      locationDescendantsCount: location2DescendantsCount,
    } = l2;
    if (location1DescendantsCount > location2DescendantsCount) {
      return -1;
    }
    if (location1DescendantsCount < location2DescendantsCount) {
      return 1;
    }
    return 0;
  });

  const batchDeleteNodes = () => {
    return new Promise((resolve, reject) => {
      const promises = selectedLocationIds.map(selectedLocationId => {
        return new Promise(async(resolve, reject) => {
          try {
            const result = await deleteNode({authToken, currentPerspectiveId, id: selectedLocationId});
            resolve(result);
          } catch(error) {
            const {
              body: {
                code,
              },
            } = error;
            switch(code) {
              case 3001:
                resolve(null);
                break;
              case 1014:
                resolve({selectedLocationId, code});
                break;
              default:
                reject(error);
            }
          }
        });
      });

      Promise.all(promises)
        .then(deleted => {
          const withErrors = deleted.filter(result => result !== null);
          resolve(withErrors);
          setSelectedLocationIds([]);
          if (withErrors.length === 0) {
            setShowBatchDeleteSuccess(true);
          }
        })
        .catch(error => {
          setError(error);
        });
    });
  };

  if (fatalError) {
    throw fatalError;
  }

  if (status !== 'ready') {
    return (
      <ListSkeleton/>
    );
  }

  if (locations.length === 0) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
          You have not added any items yet. After you add an item to your Jules account, it can be found in your Library.
        </Typography>
      </EmptyList>
    );
  }

  return (
    <>
      <ControlContainer>
        <SelectAllItems
          items={renderedLocations}
          checked={Boolean(selectedLocationIds.length === renderedLocations.length)}
          indeterminate={Boolean(selectedLocationIds.length && (selectedLocationIds.length !== renderedLocations.length))}
          onChange={() => {
            if (selectedLocationIds.length === renderedLocations.length) {
              setSelectedLocationIds([]);
            } else {
              setSelectedLocationIds([
                ...renderedLocations.map(location => location.nodeId),
              ]);
            }
          }}
        />
        <FormControlLabel
          labelPlacement="start"
          control={
            <ToggleSwitch
              checked={true}
              onChange={() => {
                pushAndClearFilter(match.url.replace('/by-location', '/nodes'));
              }}
              value={true}
            />}
          label={
            <Typography
              variant="body"
              fontWeight="normal"
              fontFamily="primary"
            >
              Browse Items by Location&nbsp;&nbsp;
            </Typography>
          }
        />
      </ControlContainer>
      <div>
        <BatchActionMenu>
          <BatchActionButton
            data-test-id="batch-delete-button"
            variant="delete"
            onClick={() => {
              if (selectedLocationIds.length > 0) {
                setShowBatchDeleteDialog(true);
              } else {
                setShowBatchDeleteWarningDialog(true);
              }
            }}
          />
        </BatchActionMenu>
        <ListContainer>
          <LocationList
            locations={renderedLocations}
            selectedLocationIds={selectedLocationIds}
            onLocationAction={(locationId, action) => {
              switch(action) {
                case 'expand': {
                  dispatchUserInterfaceAction({
                    type: 'SET_EXPANDED_NODE_ID',
                    payload: locationId,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_SHOW_NODE_PANEL',
                    payload: true,
                  });
                  break;
                }
                case 'share': {
                  dispatchUserInterfaceAction({
                    type: 'SET_SHOW_SHARE_NODE_WIZARD',
                    payload: true,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_SHARE_NODE_ID',
                    payload: locationId,
                  });
                  break;
                }
                case 'delete': {
                  dispatchUserInterfaceAction({
                    type: 'SET_SHOW_DELETE_NODE_DIALOG',
                    payload: true,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_DELETE_NODE_ID',
                    payload: locationId,
                  });
                  break;
                }
                case 'navigate': {
                  pushLocationToStack(locationId, pushAndClearFilter, match.url);
                  setSelectedLocationIds([]);
                  break;
                }
                case 'select': {
                  setSelectedLocationIds([
                    ...selectedLocationIds,
                    locationId,
                  ]);
                  break;
                }
                case 'deselect': {
                  const index = selectedLocationIds.indexOf(locationId);
                  setSelectedLocationIds([
                    ...selectedLocationIds.slice(0, index),
                    ...selectedLocationIds.slice(index + 1),
                  ]);
                  break;
                }
                default:
                  break;
              }
            }}
          />
        </ListContainer>
      </div>
      <BatchDeleteWarningDialog
        isOpen={showBatchDeleteWarningDialog}
        onClose={() => setShowBatchDeleteWarningDialog(false)}
      />
      <BatchDeleteDialog
        isOpen={showBatchDeleteDialog}
        onClose={() => {
          setShowBatchDeleteDialog(false);
          // refresh list
          dispatchUserInterfaceAction({
            type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
            payload: selectedLocationIds,
          });
        }}
        onCancel={() => setShowBatchDeleteDialog(false)}
        nodes={renderedLocations.filter((location) => {
          return selectedLocationIds.indexOf(location.nodeId) !== -1;
        }).map(location => {
          return {id: location.nodeId, name: location.nodeName, ...location};
        })}
        deleteNodes={batchDeleteNodes}
      />
      <Message
        variant="success"
        open={showBatchDeleteSuccess}
        onClose={() => setShowBatchDeleteSuccess(false)}
        messageTitle="Item(s) Deleted"
        messageBody={
          <Typography variant="body">
            All selected items have been deleted successfully.
          </Typography>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    </>
  );
};

function pushLocationToStack(locationId, pushAndClearFilter, currentUrl) {
  const search = queryString.parse(window.location.search);
  const {
    locations,
  } = search;
  const searchString = locations ? `locations=${locations},${locationId}` : `locations=${locationId}`;
  pushAndClearFilter(`${currentUrl}?${searchString}`);
}

export default InternalLocationsListRoute;
