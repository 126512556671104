import React from 'react';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import TextField from 'ui-library/components/TextField';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import VisibilityIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffRounded';

import InputAdornment from '@material-ui/core/InputAdornment';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const HelperTextContainer = styled.div`
  padding: 8px 0 0 10px;
`;

const PasswordComponent = ({
  accountType,
  password,
  setPassword,
  step,
  setStep,
  hasCorrectLength,
  hasOneLowerCase,
  hasOneUpperCase,
  hasOneDigit,
  hasOneSpecial,
  passwordIsValid,
  setWizardDirection,
  isTransitioning,
}) => {

  const [showPassword, setShowPassword] = React.useState(false);
  const [isPristine, setIsPristine] = React.useState(true);

  return (
    <>
      <RightPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          {accountType === 'personal' ? 'And finally, your' : 'Finally, choose your'} <strong>password...</strong>
        </Typography>
      </RightPanelRow>
      <RightPanelRow>
        <TextField
          fullWidth
          type={showPassword === true ? 'text' : 'password'}
          autoFocus={true}
          placeholder="Type your answer here..."
          clickableAdornment={true}
          value={password}
          onChange={(event) => {
            setIsPristine(false);
            setPassword(event.target.value);
          }}
          onKeyPress={(event) => {
            if (event.which === 13 && passwordIsValid && !isTransitioning) {
              setWizardDirection('forward');
              setStep(step + 1);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {
                  showPassword === true ?
                    <VisibilityIcon />
                    :
                    <VisibilityOffIcon />
                }
              </InputAdornment>
            ),
          }}
          error={!passwordIsValid && !isPristine}
        />
      </RightPanelRow>
      <br/>
      <RightPanelRow>
        <Typography
          variant="x-small"
          color={hasCorrectLength ? 'success' : 'error'}
        >
          Between 8 and 72 characters
        </Typography>
      </RightPanelRow>
      <RightPanelRow>
        <Typography
          variant="x-small"
          color={hasOneUpperCase ? 'success' : 'error'}
        >
          Uppercase characters
        </Typography>
      </RightPanelRow>
      <RightPanelRow>
        <Typography
          variant="x-small"
          color={hasOneLowerCase ? 'success' : 'error'}
        >
          Lowercase characters
        </Typography>
      </RightPanelRow>
      <RightPanelRow>
        <Typography
          variant="x-small"
          color={hasOneDigit ? 'success' : 'error'}
        >
          Numbers
        </Typography>
      </RightPanelRow>
      <RightPanelRow>
        <Typography
          variant="x-small"
          color={hasOneSpecial ? 'success' : 'error'}
        >
          Special characters
        </Typography>
      </RightPanelRow>
      <br/>
      <RightPanelRow>
        <Button
          data-test-id="password-continue-btn"
          variant="secondary"
          endIcon={<ArrowForwardIcon />}
          disabled={!passwordIsValid || isTransitioning}
          onClick={() => {
            setWizardDirection('forward');
            setStep(step + 1);
          }}
        >
          CONTINUE
        </Button>
        <HelperTextContainer>
          <Typography
            variant="tiny"
          >
            press <strong>ENTER</strong>
          </Typography>
        </HelperTextContainer>
      </RightPanelRow>
    </>
  );
};

export default PasswordComponent;
