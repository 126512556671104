class ForbiddenError extends Error {
  constructor(responseStatus, errorBody) {
    super('Could not process the request!');

    this.name = 'ForbiddenError';
    this.body = errorBody;
    this.responseStatus = responseStatus;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ForbiddenError);
    }
  }
}

export default ForbiddenError;
