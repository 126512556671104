import React from 'react';
import PropTypes from 'prop-types';

const ListItemText = ({
  children,
}) => {

  return (
    <div style={{
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }}>
      {children}
    </div>
  );
};

ListItemText.propTypes = {
  children: PropTypes.node,
};

export default ListItemText;
