import POST__nodes_ID_tags from 'network-layer/endpoints/POST__nodes_ID_tags';

function responseAdapter(response) {
  return response;
}

export default async function saveTag(authToken, perspectiveId, params) {
  const response = await POST__nodes_ID_tags(authToken, perspectiveId, params);

  return responseAdapter(response);
}
