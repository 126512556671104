import React from 'react';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import WarningIcon from '@material-ui/icons/WarningRounded';

const ResourceNotFoundModal = ({
  open,
  onClose,
}) => {
  return (
    <ModalCard open={open}>
      <ModalCardHeader>
        <ModalCardHeaderIcon color="error">
          <WarningIcon fontSize="inherit"/>
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle>
        Resource Not Found
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        <Typography variant="body">
          The resource you are trying to access was not found.
          <br/>
          It may have been deleted.
        </Typography>
      </ModalCardContent>
      <ModalCardActions>
        <Button onClick={onClose}>
          OK
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

export default ResourceNotFoundModal;
