import React from 'react';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';
import Message from 'ui-library/components/Message';

import ShareRoundedIcon from '@material-ui/icons/ShareRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import updateNodePermissions from 'services/Nodes/updateNodePermissions';

const StopSharingNodeDialog = () => {
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [isStopSharing, setIsStopSharing] = React.useState(false);

  const {
    showStopSharingNodeDialog,
    stopSharingNodeId,
    stopSharingNodeName,
    stopSharingNodeConnectionId,
    stopSharingNodeConnectionName,
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const [error, setError] = React.useState(false);
  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  // React.useEffect(() => {
  //   async function loadNode() {
  //     try {
  //       setLoadingStatus('loading');
  //       const node = await genNodeByNodeId({
  //         authToken,
  //         perspectiveId: currentPerspectiveId,
  //         nodeId: deleteNodeId,
  //       });
  //       const {
  //         name: nodeName,
  //         permissions,
  //       } = node;
  //       setNodeName(nodeName);
  //       const parsedPermissions = parseNodePermissions(permissions);
  //       const {
  //         frontendPermissions: {
  //           delete: deletePermissions,
  //         },
  //       } = parsedPermissions;
  //       setCanDelete(deletePermissions);
  //       setLoadingStatus('loaded');
  //     } catch (error) {
  //       setError(error);
  //     }
  //   }
  //   if (showDeleteNodeDialog) {
  //     loadNode();
  //   }
  // }, [authToken, currentPerspectiveId, deleteNodeId, showDeleteNodeDialog]);

  const handleStopSharingNode = async() => {
    try {
      setIsStopSharing(true);
      await updateNodePermissions(authToken, currentPerspectiveId, stopSharingNodeId, {connectionId: stopSharingNodeConnectionId, permissions: 0});
      dispatchUserInterfaceAction({
        type: 'SET_STOP_SHARING_NODE_ID',
        payload: null,
      });
      dispatchUserInterfaceAction({
        type: 'SET_STOP_SHARING_NODE_NAME',
        payload: '',
      });
      dispatchUserInterfaceAction({
        type: 'SET_STOP_SHARING_NODE_CONNECTION_ID',
        payload: null,
      });
      dispatchUserInterfaceAction({
        type: 'SET_STOP_SHARING_NODE_CONNECTION_NAME',
        payload: '',
      });
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_STOP_SHARING_NODE_DIALOG',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_RELOAD_ITEM_PERMISSION_GRANTS',
        payload: true,
      });
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_NODE_PANEL',
        payload: true,
      });
      setIsStopSharing(false);
    } catch(error) {
      setError(error);
    }
  };

  const onClose = () => {
    dispatchUserInterfaceAction({
      type: 'SET_STOP_SHARING_NODE_ID',
      payload: null,
    });
    dispatchUserInterfaceAction({
      type: 'SET_STOP_SHARING_NODE_NAME',
      payload: '',
    });
    dispatchUserInterfaceAction({
      type: 'SET_STOP_SHARING_NODE_CONNECTION_ID',
      payload: null,
    });
    dispatchUserInterfaceAction({
      type: 'SET_STOP_SHARING_NODE_CONNECTION_NAME',
      payload: '',
    });
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_STOP_SHARING_NODE_DIALOG',
      payload: false,
    });
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_NODE_PANEL',
      payload: true,
    });
  };
  //
  // if (loadingStatus !== 'loaded') {
  //   return null;
  // }

  return (
    <>
      {/* <NoPermissionsModal
        isOpen={showDeleteNodeDialog && !canDelete}
        onClose={onClose}
        operation="Delete Item"
      /> */}

      <ModalCard
        open={showStopSharingNodeDialog}
        handleClose={() => {
          onClose();
        }}
        maxWidth="sm"
        fullWidth={true}
        disableBackdropClick={true}
      >
        <ModalCardHeader color="error">
          <ModalCardHeaderIcon color="error">
            <ShareRoundedIcon
              fontSize="inherit"
            />
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
          Stop Sharing
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent>
          <Typography variant="body">
            {`Are you sure you want to stop sharing ${stopSharingNodeName} with ${stopSharingNodeConnectionName}?`}
          </Typography>
          <br/><br/>
          <Typography variant="caption">
          Your Connection will no longer have access to any information related to this item.
          </Typography>
        </ModalCardContent>
        <ModalCardActions>
          <Button
            data-test-id="modal-card-cancel-button"
            variant="text"
            onClick={() => {
              onClose();
            }}
          >
            CANCEL
          </Button>
          <Button
            data-test-id="modal-card-delete-button"
            variant="custom"
            textTransform="none"
            customBgColor="error"
            customHoverBgColor="error-dark"
            customTextColor="white"
            customHoverTextColor="white"
            customActiveBgColor="error-darker"
            customActiveTextColor="white"
            startIcon={isStopSharing ? <CircularProgress size={24} color="white"/> : <ShareRoundedIcon />}
            disabled={isStopSharing}
            onClick={handleStopSharingNode}
          >
            STOP SHARING
          </Button>
        </ModalCardActions>
      </ModalCard>

      <Message
        variant="success"
        open={showSuccessMessage}
        onClose={() => {
          setShowSuccessMessage(false);
        }}
        messageTitle="Item deleted successfully"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    </>
  );
};

export default StopSharingNodeDialog;
