import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import {COLOR_MONIKERS} from 'ui-library/color-palette';

const useStyles = makeStyles({
  headerIcon: {
    display: theme.modalCardHeader.headerIcon.display,
    width: theme.modalCardHeader.headerIcon.width,
    height: theme.modalCardHeader.headerIcon.height,
    fontSize: theme.modalCardHeader.headerIcon.fontSize,
    color: props => theme.palette[props.themeColor],
  },
});

const ModalCardHeaderTitle = ({
  children,
  color,
}) => {

  const themeColor = color ? color : 'primary';
  const classes = useStyles({
    themeColor,
  });

  return (
    <div className={classes.headerIcon}>
      {children}
    </div>
  );
};

ModalCardHeaderTitle.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(COLOR_MONIKERS),
};

export default ModalCardHeaderTitle;
