import React from 'react';
import {Route, Switch} from 'react-router-dom';
import OutboundRecordsByConnectionListRoute from './OutboundRecordsByConnectionListRoute';
import OutboundRecordRoute from './OutboundRecordRoute';

const OutboundRecordsByConnectionRoute = ({
  match,
}) => {
  return (
    <>
      <Switch>
        <Route
          exact path={`${match.path}`}
          component={OutboundRecordsByConnectionListRoute}
        />
        <Route
          path={`${match.path}/:recordId`}
          component={OutboundRecordRoute}
        />
      </Switch>
    </>
  );
};

export default OutboundRecordsByConnectionRoute;
