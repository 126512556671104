function selectTypeById(DM, typeId) {
  const {
    typesById: {
      [typeId]: type,
    },
  } = DM;

  return type;
}

export default selectTypeById;
