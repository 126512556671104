import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router';
import styled from '@emotion/styled';
import Button from 'ui-library/components/Button';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';
import Message from 'ui-library/components/Message';
import Link from 'ui-library/components/Link';
import internalCodeErrorMessage from 'api-error-codes/internalCodeErrorMessage';
import UnauthenticatedFooter from 'components/UnauthenticatedFooter';
import UnauthenticatedHeader from 'components/UnauthenticatedHeader';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Visibility from '@material-ui/icons/VisibilityRounded';
import VisibilityOff from '@material-ui/icons/VisibilityOffRounded';
import getSearchParam from 'utils/getSearchParam';

import signIn from 'services/Auth/signIn';
import verifyEmail from 'services/SignUp/verifyEmail';
import resendEmail from 'services/SignUp/resendEmail';

import UserContext from 'contexts/User';

import {notEmpty, email} from 'utils/validator';

import {
  signinHeaderHeight,
  signinFooterHeight,
} from 'constants/layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 500px;
`;

const HeaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 380px;
  height: calc(100vh - ${signinHeaderHeight}px - ${signinFooterHeight}px);
`;

const Content = styled.div`
  width: 100%;
  padding: 50px 0;
`;

const FieldWithValidationGutter = styled.div`
  width: 100%;
  height: 80px;
`;

const FooterContainer = styled.div`
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  margin-bottom: 16px;
`;

const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & :last-child {
    padding-left: 4px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SignIn = ({
  location,
}) => {
  const user = React.useContext(UserContext);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isWaiting, setIsWaiting] = React.useState(false);

  const [fieldIsPristine, setFieldIsPristine] = useState({
    email: true,
    password: true,
  });

  const [errorCode, setErrorCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [activationStatus, setActivationStatus] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);

  const [showPasswordResetSuccessMessage, setShowPasswordResetSuccessMessage] = useState(false);

  const [fatalError, setFatalError] = useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const checkError = error => {
    const {
      body: {
        code,
      },
    } = error;
    try {
      setErrorMessage(internalCodeErrorMessage(code));
      setErrorCode(code);
      setOpenMessage(true);
      setIsWaiting(false);
    } catch (err) {
      setFatalError(err);
    }
  };

  useEffect(() => {
    const emailVerificationToken = getSearchParam(location)('emailToken');
    const email = decodeURIComponent(getSearchParam(location)('email'));

    async function checkEmail() {
      setIsWaiting(true);
      try {
        await verifyEmail(emailVerificationToken);
        setActivationStatus(true);
        setOpenMessage(true);
        setIsWaiting(false);
      } catch(error) {
        checkError(error);
      }
    }

    if (emailVerificationToken && email) {
      setEmailAddress(email);
      checkEmail();
    }

    if (location.state) {
      location.state.passwordResetSuccess && setShowPasswordResetSuccessMessage(true);
    }
  }, [location]);

  const isFormValid = () => {
    return isFieldValid('email') && isFieldValid('password');
  };

  const isFieldValid = field => {
    switch (field) {
      case 'email':
        return email(emailAddress) === '';
      case 'password': {
        return notEmpty(password) === '';
      }
      default:
        return false;
    }
  };

  const getErrorMessage = () => {
    switch (errorCode) {
      case 1002:
        return (
          <MessageContainer>
            <Typography
              variant="body"
              color="white"
              fontWeight="normal">
                It seems you are using an expired activation token.
            </Typography>
            &nbsp;
            <Button
              variant='text'
              textTransform="none"
              onClick={async() => {
                try {
                  await resendEmail(emailAddress);
                  setOpenMessage(false);
                } catch (err) {
                  checkError(err);
                }
              }}>
              <Typography
                variant="body"
                fontWeight="normal"
                color="white">
                Send activation email again
              </Typography>
            </Button>
              &nbsp;
            <Typography
              variant="body"
              color="white"
              fontWeight="normal">
                in order to activate your account
            </Typography>
          </MessageContainer>
        );
      case 1004:
        return (
          <MessageContainer>
            <Typography
              variant="body"
              color="white"
              fontWeight="normal">
              {errorMessage}
            </Typography>
            &nbsp;
            <Button
              variant='text'
              textTransform="none"
              onClick={async() => {
                try {
                  await resendEmail(emailAddress);
                  setOpenMessage(false);
                } catch (err) {
                  checkError(err);
                }
              }}>
              <Typography
                variant="body"
                fontWeight="normal"
                color="white">
                Send activation email again
              </Typography>
            </Button>
            &nbsp;
            <Typography
              variant="body"
              color="white"
              fontWeight="normal">
              in order to activate your account.
            </Typography>
          </MessageContainer>
        );
      default: {
        return (
          <Typography
            variant="body"
            color="white"
            fontWeight="normal">
            {errorMessage}
          </Typography>
        );
      }
    }
  };

  const getErrorTitle = () => {
    switch (errorCode) {
      case 1002:
      case 1004:
        return 'Activation Error!';
      default:
        return null;
    }
  };

  return (
    <Container>
      <Message
        variant='success'
        open={showPasswordResetSuccessMessage}
        onClose={() => setShowPasswordResetSuccessMessage(false)}
        messageTitle="Password changed successfully"
        messageBody={
          <Typography variant="body">
            Use your new password to sign into your account
          </Typography>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        width={480}
      />
      {activationStatus &&
        <Message
          autoHideDuration={6000}
          variant={'success'}
          open={openMessage}
          onClose={() => {
            setOpenMessage(false);
            setActivationStatus(false);
          }}
          messageTitle={'Activation Successfull'}
          messageBody={
            <Typography
              variant="body"
              color="white"
              fontWeight="normal">
              Your account has been activated. Log in to start using Jules.
            </Typography>
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}/>}
      <HeaderContainer>
        <UnauthenticatedHeader />
      </HeaderContainer>
      <ContentContainer>
        <form
          style={{width: '400px'}}
          onSubmit={async event => {
            event.preventDefault();
            setIsWaiting(true);
            try {
              const userData = await signIn(emailAddress, password);
              user.setAuthToken(userData.authToken);
              localStorage.setItem('authToken', userData.authToken);
              user.setAuthenticationStatus('authenticated');
            } catch (error) {
              checkError(error);
            }
          }}>
          {
            errorCode &&
            <Message
              data-test-id="signin-error"
              variant={'error'}
              open={openMessage}
              onClose={() => setOpenMessage(false)}
              messageTitle={getErrorTitle()}
              messageBody={getErrorMessage()}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}/>
          }
          <Content>
            <FieldWithValidationGutter>
              <TextField
                fullWidth
                style={{paddingBottom: '16px'}}
                autoComplete="on"
                label="Email"
                value={emailAddress}
                placeholder="Enter your email"
                disabled={isWaiting}
                onChange={event => {
                  setEmailAddress(event.target.value);
                  setFieldIsPristine({...fieldIsPristine, 'email': false});
                }}
                error={!isFieldValid('email') && !fieldIsPristine['email']}
                helperText={!fieldIsPristine['email'] ? email(emailAddress, 'Please enter a valid email address!') : ''}/>
            </FieldWithValidationGutter>
            <FieldWithValidationGutter>
              <TextField
                fullWidth
                autoComplete="on"
                label="Password"
                placeholder="Enter your password"
                disabled={isWaiting}
                onChange={event => {
                  setPassword(event.target.value);
                  setFieldIsPristine({...fieldIsPristine, 'password': false});
                }}
                error={!isFieldValid('password') && !fieldIsPristine['password']}
                helperText={!fieldIsPristine['password'] ? notEmpty(password, 'Please enter your password!') : ''}
                type={showPassword ? 'text' : 'password'}
                clickableAdornment
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}>
                      {showPassword ? <Visibility /> : <VisibilityOff/>}
                    </InputAdornment>
                  ),
                }}/>
            </FieldWithValidationGutter>

            <ButtonsContainer>
              <Button
                data-test-id="login-button"
                style={{marginBottom: '16px'}}
                variant="primary"
                type="submit"
                disabled={!isFormValid() || isWaiting}
                endIcon={
                  isWaiting &&
                  <CircularProgress
                    variant="indeterminate"
                    size={20}/>
                }>
                Log in
              </Button>
              <Link
                data-test-id="forgot-password-link"
                value="/forgot-password"
                label="Forgot password?"
                color="primary"
                variant="small"
                fontWeight="normal"
              />
            </ButtonsContainer>

            <Divider />
            <SignUpContainer>
              <Typography
                variant="small"
                color="grey"
              >
                Don't have an account?
              </Typography>
              <Link
                data-test-id="sign-up-link"
                value="/sign-up"
                label="Sign up."
                color="primary"
                variant="small"
                fontWeight="bold"
              />
            </SignUpContainer>

          </Content>
        </form>
      </ContentContainer>
      <FooterContainer>
        <UnauthenticatedFooter />
      </FooterContainer>
    </Container>
  );
};

export default withRouter(SignIn);
