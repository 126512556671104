import React from 'react';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import SaveConfirmationScreen from './SaveConfirmationScreen';

const EHRItemSaveStep = ({
  active,
  personName,
  healthTypeName,
  nodeSaveState,
  navigateToStep,
}) => {

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add eHR
          </WizardBreadcrumb>
        </WizardStepTitle>
      </WizardStepHead>

      <WizardContentColumn>
        {
          nodeSaveState === 'saving' &&
            <CenterpieceSpinner/>
        }
        {
          nodeSaveState === 'saved' &&
          <SaveConfirmationScreen
            personName={personName}
            healthTypeName={healthTypeName}
            navigateToStep={navigateToStep}
          />
        }
      </WizardContentColumn>
    </WizardStep>
  );
};

export default EHRItemSaveStep;
