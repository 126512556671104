import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {PATCH} from '../verbs';

export default async function PATCH__notifications_ID(authToken, notificationId, params) {
  return await PATCH(
    `/notifications/${notificationId}`,
    configWithAuthorization(defaultConfig(), authToken),
    {
      ...params,
    },
  );
}
