import React from 'react';
import styled from '@emotion/styled';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';

import WarningIcon from '@material-ui/icons/WarningRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';

import internalCodeErrorMessage from 'api-error-codes/internalCodeErrorMessage';

import {
  greySemi,
} from 'ui-library/color-palette';

const NodesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  margin: 8px 0 0;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid ${greySemi};
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
`;

const BatchDeleteDialog = ({
  isOpen,
  onClose,
  onCancel,
  nodes,
  deleteNodes,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false);

  const [failedToDeleteCount, setFailedToDeleteCount] = React.useState(0);
  const [failedToDeleteNodes, setFailedToDeleteNodes] = React.useState([]);

  const formatFailedToDeleteNodes = (failedToDelete) => {
    const failedNodesByError = {};
    failedToDelete.forEach(item => {
      const {
        code,
        selectedNodeId,
      } = item;

      if (!failedNodesByError[code]) {
        failedNodesByError[code] = {
          code: code,
          failedNodes: [nodes.find(node => node.id === selectedNodeId)],
        };
      } else {
        failedNodesByError[code].failedNodes.push(nodes.find(node => node.id === selectedNodeId));
      }
    });
    return Object.values(failedNodesByError);
  };

  const handleDeleteNodes = async() => {
    setIsDeleting(true);
    const failedToDelete = await deleteNodes();

    if (failedToDelete.length === 0) {
      onClose();
    } else {
      const failedToDeleteNodesByError = formatFailedToDeleteNodes(failedToDelete);
      setFailedToDeleteCount(failedToDelete.length);
      setFailedToDeleteNodes(failedToDeleteNodesByError);
    }

    setIsDeleting(false);
  };

  return (
    <>
      {
        nodes.length !== 0 ?
          <ModalCard
            open={isOpen}
            handleClose={() => {
              onClose();
            }}
            fullWidth={true}
            disableBackdropClick={true}
          >
            <ModalCardHeader color="error">
              <ModalCardHeaderIcon color="error">
                <WarningIcon fontSize="inherit"/>
              </ModalCardHeaderIcon>
              <ModalCardHeaderTitle>
                Delete Item
              </ModalCardHeaderTitle>
            </ModalCardHeader>
            <ModalCardContent maxHeight={30}>
              <Typography variant="body">
                Are you sure you want to delete the following items?
              </Typography>
              <NodesContainer>
                {
                  nodes.map(node => {
                    const {
                      id,
                      name,
                    } = node;
                    return (
                      <Typography
                        key={id}
                        variant="caption"
                        fontWeight="bold">
                        {name}
                      </Typography>
                    );
                  })
                }
              </NodesContainer>
              <Typography variant="caption">
                This item will be permanently deleted including any attachments you have added to this item. All links and location assignments associated with this item will also be deleted.
              </Typography>
              <br/><br/>
              <Typography
                variant="caption"
                fontWeight="bold"
                color="error">
                When deleting a property all items assigned to that property will also be deleted.
              </Typography>
            </ModalCardContent>
            <ModalCardActions>
              <Button
                data-test-id="batch-delete-dialog-cancel-button"
                variant="text"
                onClick={() => onCancel()}
                disabled={isDeleting}
              >
                  CANCEL
              </Button>
              <Button
                data-test-id="batch-delete-dialog-delete-button"
                variant="custom"
                textTransform="none"
                customBgColor="error"
                customHoverBgColor="error-dark"
                customTextColor="white"
                customHoverTextColor="white"
                customActiveBgColor="error-darker"
                customActiveTextColor="white"
                startIcon={isDeleting ? <CircularProgress size={24} color="white"/> : <DeleteIcon />}
                disabled={isDeleting}
                onClick={handleDeleteNodes}
              >
                {isDeleting ? 'DELETING...' : 'DELETE ITEMS'}
              </Button>
            </ModalCardActions>
          </ModalCard>
          :
          <ModalCard
            open={isOpen}
            handleClose={() => {
              onClose();
            }}
            fullWidth={true}
            disableBackdropClick={true}
          >
            <ModalCardHeader color="warning">
              <ModalCardHeaderIcon color="warning">
                <WarningIcon fontSize="inherit"/>
              </ModalCardHeaderIcon>
              <ModalCardHeaderTitle>
                Not all items have been deleted
              </ModalCardHeaderTitle>
            </ModalCardHeader>
            <ModalCardContent maxHeight={30}>
              <Typography variant="body">
                The following {failedToDeleteCount} items could not be deleted.
              </Typography>
              {
                failedToDeleteNodes.map((listObj, index) => {
                  const {
                    code,
                    failedNodes,
                  } = listObj;
                  return (
                    <NodesContainer key={index}>
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        color="primary">
                        {internalCodeErrorMessage(code).toUpperCase()}
                      </Typography>
                      {
                        failedNodes.map((node, index) => {
                          const {
                            name,
                            typeName,
                          } = node;
                          return (
                            <ListItem key={index}>
                              <ListItemContentSegment>
                                <ListItemText>
                                  <Typography variant="small" fontWeight="bold">
                                    {name}
                                  </Typography>
                                </ListItemText>
                                <ListItemText>
                                  <Typography variant="caption" color="grey">
                                    {typeName}
                                  </Typography>
                                </ListItemText>
                              </ListItemContentSegment>
                            </ListItem>
                          );
                        })
                      }
                    </NodesContainer>
                  );
                })
              }
            </ModalCardContent>
            <ModalCardActions>
              <Button
                data-test-id="batch-delete-dialog-delete-button"
                variant="primary"
                onClick={() => {
                  onClose();
                  setFailedToDeleteNodes([]);
                }}>
                  OK
              </Button>
            </ModalCardActions>
          </ModalCard>
      }
    </>
  );
};

export default BatchDeleteDialog;
