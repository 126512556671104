import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import InboundNodesByRecordAndConnectionListRoute from './InboundNodesByRecordAndConnectionListRoute';
import InboundLocationsListRoute from './InboundLocationsListRoute';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ToggleSwitch from 'ui-library/components/ToggleSwitch';
import Typography from 'ui-library/components/Typography';
import ConnectionsContext from './context';

const InboundRecordRoute = ({
  match,
}) => {
  const {
    pushAndClearFilter,
  } = React.useContext(ConnectionsContext);
  const browseByLocation = window.location.pathname === `${match.url}/by-location`;

  return (
    <>
      <div style={{
        paddingLeft: '48px',
      }}>
        <FormControlLabel
          labelPlacement="start"
          control={
            <ToggleSwitch
              checked={browseByLocation}
              onChange={(event, bbl) => {
                pushAndClearFilter(`${match.url}/${bbl ? 'by-location' : 'nodes'}`);
              }}
              value={browseByLocation}
            />}
          label={
            <Typography
              variant="body"
              fontWeight="normal"
              fontFamily="primary"
            >
            Browse Items by Location
            </Typography>
          }
        />
      </div>
      <Switch>
        <Route
          path={`${match.path}/nodes`}
          component={InboundNodesByRecordAndConnectionListRoute}
        />
        <Route
          path={`${match.path}/by-location`}
          component={InboundLocationsListRoute}
        />
        <Redirect
          to={`${match.path}/nodes`}
        />
      </Switch>
    </>
  );
};

export default InboundRecordRoute;
