import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import PersonListRoute from './PersonListRoute';
import PersonRoute from './PersonRoute';
import PageContainer from 'components/chrome/PageContainer';
import PageTitle from 'components/chrome/PageTitle';
import PageDescription from 'components/chrome/PageDescription';
import PageContent from 'components/chrome/PageContent';
import LibraryFilterInput from 'components/LibraryFilterInput';

import PeopleContext from './context';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

const People = ({
  match,
  history,
}) => {
  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } = React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  const {
    personIdModifiedDestructivelyForLists,
    dispatchUserInterfaceAction,
    selectedPersonId,
    selectedPersonRecordId,
    nodeIdModifiedDestructivelyForLists,
    deletePersonId,
    showDeletePersonDialog,
  } = React.useContext(UserInterfaceContext);

  const [filter, setFilter] = React.useState('');

  return (
    <PageContainer>
      <PageTitle>
        People
      </PageTitle>
      <PageDescription>
        Manage the people you have added to your account.
      </PageDescription>
      <PageContent>
        <LibraryFilterInput
          type="text"
          value={filter}
          onChange={event => setFilter(event.target.value)}
          placeholder="Type to search..."
        />
        <PeopleContext.Provider
          value={{
            authToken,
            setAuthToken,
            setAuthenticationStatus,
            currentPerspectiveId,
            dispatchUserInterfaceAction,
            personIdModifiedDestructivelyForLists,
            filter,
            selectedPersonId,
            selectedPersonRecordId,
            nodeIdModifiedDestructivelyForLists,
            deletePersonId,
            showDeletePersonDialog,
            pushAndClearFilter: (...args) => {
              setFilter('');
              history.push(...args);
            },
          }}>
          <Switch>
            <Route
              exact path={`${match.path}`}
              component={PersonListRoute}
            />
            <Route
              path={`${match.path}/:personId/records/:recordId?`}
              component={PersonRoute}
            />
            <Redirect
              from={`${match.path}/:personId`}
              to={`${match.path}/:personId/records`}
            />
          </Switch>
        </PeopleContext.Provider>
      </PageContent>
    </PageContainer>
  );
};

export default People;
