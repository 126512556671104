import React from 'react';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import SaveConfirmationScreen from './SaveConfirmationScreen';

const PropertySaveStep = ({
  active,
  propertyNickname,
  navigateToStep,
  propertySaveState,
  closeWizardWithPrepend,
  propertyConfigurationHint,
}) => {
  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Property
          </WizardBreadcrumb>
        </WizardStepTitle>
      </WizardStepHead>

      <WizardContentColumn>
        {
          propertySaveState === 'saving' &&
            <CenterpieceSpinner/>
        }
        {
          propertySaveState === 'saved' &&
            <SaveConfirmationScreen
              propertyNickname={propertyNickname}
              closeWizardWithPrepend={closeWizardWithPrepend}
              propertyConfigurationHint={propertyConfigurationHint}
              navigateToStep={navigateToStep}
            />
        }
      </WizardContentColumn>
    </WizardStep>
  );
};

export default PropertySaveStep;
