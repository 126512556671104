import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    display: theme.filterOperator.display,
    margin: theme.filterOperator.margin,
  },
});

const FilterOperator = ({
  children,
}) => {

  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      {children}
    </div>
  );
};

FilterOperator.propTypes = {
  children: PropTypes.node,
};

export default FilterOperator;
