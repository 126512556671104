import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../verbs';

export default async function PATCH__notificationTypes_ID(authToken, notificationTypeId, params) {
  return await PATCH(
    `/notification-types/${notificationTypeId}`,
    configWithAuthorization(defaultConfig(), authToken),
    {
      ...params,
    },
  );
}
