import selectTypeCategories from './selectTypeCategories';

function selectTypeCategoriesByPerspectiveType(DM, perspectiveType) {
  return (
    selectTypeCategories(DM)
      .filter(typeCategory => {
        const {
          perspectiveType: typeCategoryPerspectiveType,
        } = typeCategory;
        return typeCategoryPerspectiveType === perspectiveType;
      })
  );
}

export default selectTypeCategoriesByPerspectiveType;
