function initHealthItemState() {
  return {
    personId: null,
    personName: '',
    typeId: null,
    typeName: '',
    healthRecordId: null,
    name: '',
    fieldDefinitionsById: {},
    fieldsByFieldDefinitionId: {},
    fieldDefinitionIds: [],
    successfullySavedNode: null,
  };
}

function healthItemReducer(state, {type: actionType, payload}) {
  switch(actionType) {
    case 'SET_PERSON_ID': {
      return {
        ...state,
        personId: payload,
      };
    }
    case 'SET_PERSON_NAME': {
      return {
        ...state,
        personName: payload,
      };
    }
    case 'SET_TYPE_ID': {
      return {
        ...state,
        typeId: payload,
      };
    }
    case 'SET_TYPE_NAME': {
      return {
        ...state,
        typeName: payload,
      };
    }
    case 'SET_NAME': {
      return {
        ...state,
        name: payload,
      };
    }
    case 'SET_HEALTH_RECORD_ID': {
      return {
        ...state,
        healthRecordId: payload,
      };
    }
    case 'SET_SUCCESSFULLY_SAVED_NODE': {
      return {
        ...state,
        successfullySavedNode: {...payload},
      };
    }
    case 'CLEAR_ITEM_VALUES': {
      return {
        ...state,
        typeId: null,
        typeName: '',
        name: '',
        fieldDefinitionsById: {},
        fieldsByFieldDefinitionId: {},
        fieldDefinitionIds: [],
      };
    }
    case 'HYDRATE_FIELDS': {
      const {
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
      } = payload;

      return {
        ...state,
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
      };
    }
    case 'SET_FIELD_VALUE': {
      const {
        fieldDefinitionId,
        value,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            value,
          },
        },
      };
    }
    case 'SET_ERROR_MESSAGE': {
      const {
        fieldDefinitionId,
        errorMessage,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            errorMessage,
          },
        },
      };
    }
    default:
      throw new Error(`Unknown property action "${actionType}"!`);
  }
}

export {
  initHealthItemState,
  healthItemReducer,
};
