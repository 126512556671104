import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Grid from '@material-ui/core/Grid';

import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';

import Typography from 'ui-library/components/Typography';
import Tag from 'ui-library/components/Tag';
import AddTag from 'components/AddTag';

import genTags from 'services/Tags/genTags';
import genNodeTags from 'services/Tags/genNodeTags';
import saveNodeTag from 'services/Tags/saveNodeTag';
import deleteNodeTag from 'services/Tags/deleteNodeTag';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const NoTagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
`;

const TagsContainer = styled.div`
  display: inline-block;
  margin: 35px 0px 8px 0px;
  width: 100%;
`;

const NodeTags = ({
  nodeState,
  dispatchNodeAction,
  setError,
}) => {
  const {
    node: {
      id: nodeId,
    },
    tags,
    nodeTags = [],
  } = nodeState;

  const {
    authToken,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const [tabState, setTabState] = React.useState('not loaded'); // 'not loaded', 'loading', 'loaded'
  const [tagAcInputValue, setTagAcInputValue] = React.useState('');
  const [tagAcOptions, setTagAcOptions] = React.useState([]);
  const [newTag, setNewTag] = React.useState('');

  React.useEffect(() => {
    const loadTags = async() => {
      try {
        setTabState('loading');
        const genTagsResult = await genTags(authToken, currentPerspectiveId);
        dispatchNodeAction({
          type: 'SET_TAGS',
          payload: genTagsResult,
        });
        const genNodeTagsResult = await genNodeTags(authToken, currentPerspectiveId, nodeId);
        dispatchNodeAction({
          type: 'SET_NODE_TAGS',
          payload: genNodeTagsResult,
        });
        setTabState('loaded');
      } catch(error) {
        setError(error);
      }
    };

    if (tabState === 'not loaded') {
      loadTags();
    }
  }, [authToken, currentPerspectiveId, dispatchNodeAction, nodeId, setError, tabState]);

  React.useEffect(() => {
    const options = tags.filter(tag => {
      const tagIsPresentOnNode = Boolean(nodeTags.filter((nodeTag) => tag.name === nodeTag.name).length);
      return !tagIsPresentOnNode;
    });

    setTagAcOptions([
      ...options,
    ]);
  }, [nodeTags, tags]);

  const onTagAcInputChange = (ev) => {
    setTagAcInputValue(ev.currentTarget.value);

    const tagIsPresentOnNode = Boolean(nodeTags.filter(nodeTag => nodeTag.name === ev.currentTarget.value).length);
    const stringIsTag = Boolean(tagAcOptions.filter(tag => tag.name === ev.currentTarget.value).length);

    if (!ev.currentTarget.value || tagIsPresentOnNode || stringIsTag) {
      setNewTag('');
    } else {
      setNewTag(`${ev.currentTarget.value} (New Tag)`);
    }
  };

  const clearTagAcOptions = () => {
    const options = tags.filter(tag => {
      const tagIsPresentOnNode = Boolean(nodeTags.filter((nodeTag) => tag.name === nodeTag.name).length);
      return !tagIsPresentOnNode;
    });

    setTagAcOptions([
      ...options,
    ]);

    setNewTag('');
  };

  const addNodeTag = async(name) => {
    try {
      const saveNodeTagResponse = await saveNodeTag(authToken, currentPerspectiveId, {nodeId: nodeId, tagName: name});
      const {
        id: tagId,
      } = saveNodeTagResponse;

      dispatchNodeAction({
        type: 'ADD_NODE_TAG',
        payload: {
          name,
          id: tagId,
        },
      });
      dispatchNodeAction({
        type: 'ADD_TAG',
        payload: {
          name,
          id: tagId,
        },
      });
    } catch(error) {
      setError(error);
    }
  };

  const removeNodeTag = async(nodeTag) => {
    const {
      id: tagId,
    } = nodeTag;
    try {
      await deleteNodeTag({
        authToken,
        currentPerspectiveId,
        id: nodeId,
        tagId,
      });

      dispatchNodeAction({
        type: 'REMOVE_NODE_TAG',
        payload: nodeTag.name,
      });
    } catch(error) {
      setError(error);
    }
  };

  if (tabState !== 'loaded') {
    return (
      <PaddedContainer>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              height: '100%',
              flexGrow: 1,
              width: '100%',
            }}
          >
            <NoTagsContainer>
              <CenterpieceSpinner/>
            </NoTagsContainer>
          </Grid>
        </Grid>
      </PaddedContainer>
    );
  }

  return (
    <PaddedContainer>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{
            height: '100%',
            flexGrow: 1,
            width: '100%',
          }}
        >
          <TagsContainer>
            {
              nodeTags.map((nodeTag, index) => {
                return (
                  <Tag
                    key={index}
                    onClick={() => {
                      removeNodeTag(nodeTag);
                    }}
                    label={nodeTag.name}
                  />
                );
              })
            }
          </TagsContainer>
          <AddTag
            tags={tags}
            nodeTags={nodeTags}
            tagAcOptions={tagAcOptions}
            clearTagAcOptions={clearTagAcOptions}
            tagAcInputValue={tagAcInputValue}
            onTagAcInputChange={onTagAcInputChange}
            addNodeTag={addNodeTag}
            newTag={newTag}
          />
          {
            nodeTags.length === 0 ?
              <NoTagsContainer>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color="grey-semi"
                >
                    This item has no tags.
                </Typography>
              </NoTagsContainer>
              :
              null
          }
        </Grid>
      </Grid>
    </PaddedContainer>
  );
};

NodeTags.propTypes = {
  expandedNodeId: PropTypes.number,
};

export default NodeTags;
