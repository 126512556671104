import React from 'react';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';

import WarningIcon from '@material-ui/icons/WarningRounded';

const BatchDeleteWarningDialog = ({
  isOpen,
  onClose,
  type = 'item',
}) => {

  return (
    <>
      <ModalCard
        open={isOpen}
        disableBackdropClick={true}
      >
        <ModalCardHeader color="error">
          <ModalCardHeaderIcon color="error">
            <WarningIcon
              fontSize="inherit"
            />
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
            Oops
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent>
          <Typography variant="body">
            Please select at least one {type} in order to perform this action.
          </Typography>
        </ModalCardContent>
        <ModalCardActions>
          <Button
            data-test-id="batch-delete-warning-dialog-button"
            variant="primary"
            onClick={onClose}
          >
              OK
          </Button>
        </ModalCardActions>
      </ModalCard>
    </>
  );
};

export default BatchDeleteWarningDialog;
