function selectVirtualLocationTreeNodeLocation(
  configurationState,
  virtualLocationTreeNode,
) {
  const {
    virtualLocationTreeNodesById,
    virtualLocationTreeNodeIds,
  } = configurationState;
  const {
    virtualLocationId,
  } = virtualLocationTreeNode;

  if (virtualLocationId === null) {
    return null;
  }

  return (
    virtualLocationTreeNodeIds
      .map(id => virtualLocationTreeNodesById[id])
      .find(virtualLocationTreeNode => {
        return virtualLocationTreeNode.virtualId === virtualLocationId;
      })
  );
}

export default selectVirtualLocationTreeNodeLocation;
