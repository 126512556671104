import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Prompt = ({
  open,
  title,
  text,
  confirmLabel,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (typeof onCancel === 'function') {
          onCancel();
        }
      }}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          typeof onCancel === 'function' &&
          <Button
            variant="text"
            onClick={onCancel}>
            cancel
          </Button>
        }
        {
          typeof onConfirm === 'function' &&
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirm}>
            {confirmLabel || 'ok'}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};

export default Prompt;
