import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {GET} from '../../verbs';

export default async function GET__admin_typeLocationGraph_edges(authToken, perspectiveId) {
  return await GET(
    '/admin/type-location-graph/edges',
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
