import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import Link from 'ui-library/components/Link';

import WarningIcon from '@material-ui/icons/WarningRounded';

const Links = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const PushNotificationsFinishSetupDialog = ({
  isOpen,
  handleClose,
}) => {

  return (
    <ModalCard
      open={isOpen}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <ModalCardHeader>
        <ModalCardHeaderIcon color="error">
          <WarningIcon fontSize="inherit"/>
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle>
          Finish Setup
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        <Typography variant="body">
          This device has been registered for push notifications, but Jules push notifications are currently blocked on this browser.
        </Typography>
        <br/>
        <br/>
        <Typography variant="body">
          Please enable push notifications on this browser in order to receive all the benefits of Jules.
        </Typography>
        <br/>
        <br/>
        <Typography variant="body">
          In order for these changes to take effect, please sign out and sign in again.
        </Typography>
        <Links>
          <Link
            value="https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform=Desktop"
            label="Allowing notifications on Google Chrome"
            color="primary"
            variant="body"
            fontWeight="normal"
          />
          <Link
            value="https://support.mozilla.org/en-US/kb/push-notifications-firefox"
            label="Allowing notifications on Firefox"
            color="primary"
            variant="body"
            fontWeight="normal"
          />
          <Link
            value="https://answers.microsoft.com/en-us/edge/forum/all/turn-on-off-microsoft-edge-pop-up-notifications/1e804ae0-788d-46b0-a414-739231e8eaa3"
            label="Allowing notifications on Edge"
            color="primary"
            variant="body"
            fontWeight="normal"
          />
          <Link
            value="https://support.apple.com/guide/safari/customize-website-notifications-sfri40734/mac"
            label="Allowing notifications on Safari"
            color="primary"
            variant="body"
            fontWeight="normal"
          />
        </Links>
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="finish-setup-close-button"
          onClick={handleClose}
        >
          OK
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

PushNotificationsFinishSetupDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default PushNotificationsFinishSetupDialog;
