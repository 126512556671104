import React from 'react';
import styled from '@emotion/styled';
import Typography from 'ui-library/components/Typography';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Comment from 'components/Comment';

const CommentListContainer = styled.div`

`;

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

export default ({
  comments,
  commentsHaveContextualMenu,
  onReply,
  onEdit,
  onDelete,
  conversation,
  shouldScrollToBottom,
}) => {
  const [contextualMenuState, setContextualMenuState] = React.useState({
    commentData: null,
    anchorEl: null,
  });

  const listEndRef = React.useRef(null);
  React.useEffect(() => {
    const scrollToBottom = () => {
      listEndRef.current.scrollIntoView();
    };
    // be sure we need to scroll
    if (comments && comments.length > 2 && shouldScrollToBottom) {
      scrollToBottom();
    }
  }, [comments, shouldScrollToBottom]);

  const {
    participantOrganizationId,
  } = conversation || {};

  if (comments === null) {
    return (
      <EmptyList>
        <CenterpieceSpinner />
      </EmptyList>
    );
  }

  if (comments.length === 0) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
          You have no comments in this conversation.
        </Typography>
      </EmptyList>
    );
  }

  return (
    <CommentListContainer>
      {comments.map((comment, index) => {
        comment.isReply = false;
        comment.parentComment = null;

        if (comment.parentId !== null) {
          comment.isReply = true;
          comment.parentComment = comments.find(e => e.id === comment.parentId);
        }

        comment.isOwn =
          participantOrganizationId === comment.organizationId;

        return (
          <Comment
            key={index}
            {...comment}
            onReply={onReply}
            onEdit={onEdit}
            onDelete={onDelete}
            hasContextualMenu={commentsHaveContextualMenu}
            contextualMenuState={contextualMenuState}
            setContextualMenuState={setContextualMenuState}
            conversation={conversation}
          />
        );
      })}
      <div ref={listEndRef} />
    </CommentListContainer>
  );
};
