function selectRecordTypeIdsOfTypeById(DM, typeId) {
  const {
    recordTypes_Types,
  } = DM;

  const recordTypeIds = [];

  for (let recordTypeId of Object.keys(recordTypes_Types)) {
    if (recordTypes_Types[recordTypeId].includes(typeId)) {
      recordTypeIds.push(+recordTypeId);
    }
  }

  return recordTypeIds;
}

export default selectRecordTypeIdsOfTypeById;
