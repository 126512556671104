import React from 'react';
import styled from '@emotion/styled';
import {
  conversationPanelActionsHeight,
  conversationPanelNavigationHeight,
  conversationPanelHeaderHeight,
  conversationPanelNoticeHeight,
  conversationPanelNavigationGutter,
} from 'constants/layout';
import {
  greyLightest,
} from 'ui-library/color-palette';

const Root = styled.div`
  padding-top: ${props => props.hasNotice ?
    `calc(${conversationPanelNavigationGutter}px + ${conversationPanelNavigationHeight}px + ${conversationPanelHeaderHeight}px + ${conversationPanelNoticeHeight}px)` :
    `calc(${conversationPanelNavigationGutter}px + ${conversationPanelNavigationHeight}px + ${conversationPanelHeaderHeight}px)`
};
  padding-bottom: ${conversationPanelActionsHeight + 50}px;
  min-height: ${props => props.hasNotice ?
    `calc(100% - ${conversationPanelHeaderHeight}px - ${conversationPanelNavigationHeight}px - ${conversationPanelNoticeHeight}px)` :
    `calc(100% - ${conversationPanelHeaderHeight}px - ${conversationPanelNavigationHeight}px)`
};
  background-color: ${greyLightest};
`;

const ConversationPanelContent = ({
  hasNotice,
  children,
}) => {

  return (
    <Root hasNotice={hasNotice}>
      {children}
    </Root>
  );
};

export default ConversationPanelContent;
