import './NestedBox.css';
import React from 'react';

const NestedBox = ({
  children,
}) => {
  return (
    <div
      className="nested-box">
      {children}
    </div>
  );
};

export default NestedBox;
