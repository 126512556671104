import React from 'react';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import Typography from 'ui-library/components/Typography';
import WizardTypeTile from 'components/chrome/WizardTypeTile';

const ConnectionTypeStep = ({
  active,
  dispatchConnectionAction,
  navigateToStep,
}) => {

  React.useEffect(() => {
    if (active) {
      dispatchConnectionAction({
        type: 'RESET_CONNECTION_STATE',
        payload: null,
      });
    }
  }, [active, dispatchConnectionAction]);

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Connection
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Type
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          A Jules connection allows you to share your items with an external account or access items belonging to an external account.
        </WizardStepDescription>
      </WizardStepHead>

      <WizardContentColumn>
        <WizardStepContent>
          <div style={{'paddingBottom': '60px'}}>
            <Typography variant="lead">
              Do you wish to create a Connection request or accept a Connection request?
            </Typography>
          </div>
          <WizardTypeTile
            data-test-id="connection-outgoing-type-tile"
            name="Create Connection Request"
            description="Generate a Connection Code to share with another Jules user."
            onClick={() => {
              dispatchConnectionAction({
                type: 'SET_CONNECTION_TYPE',
                payload: 'outgoing',
              });
              navigateToStep(2);
            }}
          />
          <WizardTypeTile
            data-test-id="connection-incoming-type-tile"
            name="Accept Connection Request"
            description="Enter a received Connection Code to establish a Connection."
            onClick={() => {
              dispatchConnectionAction({
                type: 'SET_CONNECTION_TYPE',
                payload: 'incoming',
              });
              navigateToStep(2);
            }}
          />

        </WizardStepContent>
      </WizardContentColumn>
    </WizardStep>
  );
};

export default ConnectionTypeStep;
