import React from 'react';
import styled from '@emotion/styled';

import ContextualPanel from 'components/chrome/ContextualPanel';
import ContextualPanelNavigation from 'components/chrome/ContextualPanelNavigation';
import ContextualPanelBackButton from 'components/chrome/ContextualPanelBackButton';
import ContextualPanelCloseButton from 'components/chrome/ContextualPanelCloseButton';
import ConversationPanelHeader from 'components/chrome/ConversationPanelHeader';
import ConversationPanelContent from 'components/chrome/ConversationPanelContent';
import CommentList from 'components/CommentList';
import ConversationStatusNotice from 'components/ConversationStatusNotice';

import Typography from 'ui-library/components/Typography';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import genConversationComments from 'services/Conversations/genConversationComments';

import {
  greySemi,
} from 'ui-library/color-palette';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const PanelHr = styled.div`
  height: 1px;
  background: ${greySemi};
`;

const HeaderConnectionNameContainer = styled.div``;

const HeaderOrganizationNameContainer = styled.div`
  margin-top: 8px;
`;

const HeaderNodeNameContainer = styled.div`
  margin-top: 24px;
`;

const ConversationVaultPanel = () => {
  const {
    authToken,
  } = React.useContext(UserContext);

  const {
    currentPerspective: {
      id: perspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  const {
    expandedFrozenConversation,
    dispatchUserInterfaceAction,
    backNavigationEnabled,
    backNavigationLanding,
  } = React.useContext(UserInterfaceContext);

  let [comments, setComments] = React.useState(null);

  React.useEffect(() => {
    const fetchComments = async() => {
      setComments(await genConversationComments({
        authToken,
        perspectiveId,
        conversationId: expandedFrozenConversation.id,
      }));
    };

    if (!!expandedFrozenConversation) {
      fetchComments();
    }
  }, [authToken, expandedFrozenConversation, perspectiveId]);

  return (
    <ContextualPanel
      expanded={!!expandedFrozenConversation}
      handleEscKey={() => {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_FROZEN_CONVERSATION',
          payload: null,
        });
      }}>
      <ContextualPanelNavigation expanded={!!expandedFrozenConversation}>
        <ContextualPanelBackButton
          data-test-id="node-contextual-panel-back-button"
          isVisible={backNavigationEnabled}
          onClick={() => {
            switch (backNavigationLanding) {
              case 'notifications': {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: false,
                    backNavigationLanding: 'null',
                  },
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                  payload: true,
                });
                break;
              }
              default: {
                dispatchUserInterfaceAction({
                  type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: false,
                    backNavigationLanding: 'null',
                  },
                });
              }
            }
          }}
        />
        <ContextualPanelCloseButton
          data-test-id="conversation-vault-panel-close-button"
          isVisible={true}
          onClick={() => {
            dispatchUserInterfaceAction({
              type: 'SET_EXPANDED_FROZEN_CONVERSATION',
              payload: null,
            });
            dispatchUserInterfaceAction({
              type: 'SET_EXPANDED_FROZEN_CONVERSATION',
              payload: null,
            });
            dispatchUserInterfaceAction({
              type: 'SET_BACK_NAVIGATION_ENABLED',
              payload: {
                backNavigationEnabled: false,
                backNavigationLanding: 'null',
              },
            });
          }}
        />
      </ContextualPanelNavigation>

      {!expandedFrozenConversation ? (<div>Loading...</div>) : (
        <>
          <ConversationPanelHeader>
            <HeaderConnectionNameContainer>
              <Typography variant="h3" fontWeight="bold">
                {expandedFrozenConversation.connectionName}
              </Typography>
            </HeaderConnectionNameContainer>
            <HeaderOrganizationNameContainer>
              <Typography variant="h5" fontWeight="bold">
                {expandedFrozenConversation.interlocutorOrganizationName}
              </Typography>
            </HeaderOrganizationNameContainer>
            <HeaderNodeNameContainer>
              {expandedFrozenConversation.nodeName ? (
                <Typography variant="caption" color="grey">
                  {expandedFrozenConversation.nodeName}
                </Typography>
              ) : expandedFrozenConversation.recordName ? (
                <Typography variant="caption" color="grey">
                  {expandedFrozenConversation.recordName}
                </Typography>
              ) : null}
            </HeaderNodeNameContainer>
          </ConversationPanelHeader>

          <ConversationStatusNotice
            status="frozen"
            onClick={() => {}}
          />

          <PanelHr/>

          <ConversationPanelContent hasNotice>
            <PaddedContainer>
              <CommentList comments={comments} conversation={expandedFrozenConversation} />
            </PaddedContainer>
          </ConversationPanelContent>
        </>
      )}
    </ContextualPanel>
  );
};

export default ConversationVaultPanel;
