export const primaryLightest = '#d2e9ff';
export const primaryLighter = '#8fc8ff';
export const primaryLight = '#4ba6ff';
export const primary = '#1e90ff';
export const primaryDark = '#1565b3';
export const primaryDarker = '#0c3a66';
export const primaryDarkest = '#061d33';

export const secondaryLightest = '#e8f7ed';
export const secondaryLighter = '#a1deb8';
export const secondaryLight = '#5bc682';
export const secondary = '#15ad4d';
export const secondaryDark = '#0f7936';
export const secondaryDarker = '#08451f';
export const secondaryDarkest = '#04230f';

export const tertiaryLightest = '#cfdffe';
export const tertiaryLighter = '#a0bffd';
export const tertiaryLight = '#709ffd';
export const tertiary = '#115ffb';
export const tertiaryDark = '#0a3997';
export const tertiaryDarker = '#072664';
export const tertiaryDarkest = '#031332';

export const successLightest = '#e8f7ed';
export const successLighter = '#a1deb8';
export const successLight = '#5bc682';
export const success = '#15ad4d';
export const successDark = '#0f7936';
export const successDarker = '#08451f';
export const successDarkest = '#04230f';

export const warningLightest = '#e8f7ed';
export const warningLighter = '#ffe4af';
export const warningLight = '#ffcf74';
export const warning = '#ffbb38';
export const warningDark = '#b38327';
export const warningDarker = '#664b16';
export const warningDarkest = '#33250b';

export const errorLightest = '#fbe7e9';
export const errorLighter = '#f09fa7';
export const errorLight = '#e45765';
export const error = '#d90f23';
export const errorDark = '#980b19';
export const errorDarker = '#57060e';
export const errorDarkest = '#2b0307';

export const greyLightest = '#FCFCFC';
export const greyLighter = '#F6F6F6';
export const greyLight = '#F0F0F0';
export const greySemi = '#E6E6E6';
export const grey = '#939393';
export const greyDark = '#666666';
export const greyDarker = '#3D3D3D';

export const white = '#ffffff';
export const black = '#212121';

export const logoGrey = '#666666';
export const logoGreen = '#15AD4D';

export const inherit = 'inherit';
export const transparent = 'rgba(255,255,255,0)';

export const COLOR_MONIKERS = [
  'primary-lightest',
  'primary-lighter',
  'primary-light',
  'primary',
  'primary-dark',
  'primary-darker',
  'primary-darkest',

  'secondary-lightest',
  'secondary-lighter',
  'secondary-light',
  'secondary',
  'secondary-dark',
  'secondary-darker',
  'secondary-darkest',

  'tertiary-lightest',
  'tertiary-lighter',
  'tertiary-light',
  'tertiary',
  'tertiary-dark',
  'tertiary-darker',
  'tertiary-darkest',

  'success-lightest',
  'success-lighter',
  'success-light',
  'success',
  'success-dark',
  'success-darker',
  'success-darkest',

  'warning-lightest',
  'warning-lighter',
  'warning-light',
  'warning',
  'warning-dark',
  'warning-darker',
  'warning-darkest',

  'error-lightest',
  'error-lighter',
  'error-light',
  'error',
  'error-dark',
  'error-darker',
  'error-darkest',

  'grey-lightest',
  'grey-lighter',
  'grey-light',
  'grey-semi',
  'grey',
  'grey-dark',
  'grey-darker',

  'white',
  'black',

  'logo-grey',
  'logo-green',

  'inherit',
  'transparent',
];
