function selectNodeLinkDefinitions(DM) {
  const {
    nodeLinkDefinitionsById,
    nodeLinkDefinitionIds,
  } = DM;

  return (
    nodeLinkDefinitionIds
      .map(nodeLinkDefinitionId => nodeLinkDefinitionsById[nodeLinkDefinitionId])
  );
}

export default selectNodeLinkDefinitions;
