import React from 'react';
import styled from '@emotion/styled';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import RadioButton from 'ui-library/components/RadioButton';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const RightPanelRowWithPadding = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  padding-left: 32px;
  text-align: left;
`;

const SelectAccountComponent = ({
  accountType,
  setAccountType,
  step,
  setStep,
  setWizardDirection,
  isTransitioning,
}) => {

  const accountTypeChange = (event) => {
    setAccountType(event.target.value);
    setStep(0);
  };

  return (
    <>
      <RightPanelRow>
        <FormControlLabel
          control={
            <RadioButton
              data-test-id="select-business-radio-btn"
              checked={accountType === 'business'}
              onChange={accountTypeChange}
              onKeyPress={(event) => {
                if (event.which === 13 && !isTransitioning) {
                  setWizardDirection('forward');
                  setStep(step + 1);
                }
              }}
              value="business"
              autoFocus={true}
            />}
          label={
            <Typography
              variant="body"
              fontWeight="bold"
              fontFamily="primary"
              color={accountType === 'business' ? 'primary' : 'black'}
            >
                BUSINESS
            </Typography>
          }
        />
      </RightPanelRow>
      <RightPanelRowWithPadding>
        <Typography
          variant="body"
          color={accountType === 'business' ? 'black' : 'grey'}
        >
            A Jules Business account can be customized to fit your data management needs and facilitates collaboration between your employees while promoting collaboration with customers and business partners.
        </Typography>
      </RightPanelRowWithPadding>
      <br/>
      <RightPanelRow>
        <FormControlLabel
          control={
            <RadioButton
              data-test-id="select-personal-radio-btn"
              checked={accountType === 'personal'}
              onChange={accountTypeChange}
              onKeyPress={(event) => {
                if (event.which === 13 && !isTransitioning) {
                  setStep(step + 1);
                }
              }}
              value="personal"
            />}
          label={
            <Typography
              variant="body"
              fontWeight="bold"
              fontFamily="primary"
              color={accountType === 'personal' ? 'primary' : 'black'}
            >
               PERSONAL
            </Typography>
          }
        />
      </RightPanelRow>
      <RightPanelRowWithPadding>
        <Typography
          variant="body"
          color={accountType === 'personal' ? 'black' : 'grey'}
        >
          A Jules Personal account allows you to effectively manage your personal and home information as well as collaborate with family members, friends, or Jules Business users.
        </Typography>
      </RightPanelRowWithPadding>
      <br/>
      <br/>
      <RightPanelRowWithPadding>
        <Button
          data-test-id="select-account-continue-btn"
          variant="primary"
          endIcon={<ArrowForwardIcon />}
          disabled={accountType === null || isTransitioning}
          onClick={() => {
            setWizardDirection('forward');
            setStep(step + 1);
          }}
        >
          CONTINUE
        </Button>
      </RightPanelRowWithPadding>
    </>
  );
};

export default SelectAccountComponent;
