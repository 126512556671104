import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/CheckRounded';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import Typography from 'ui-library/components/Typography';


const useStyles = makeStyles({
  root: {
    display: theme.filterCriterion.root.display,
    margin: theme.filterCriterion.root.margin,
    cursor: theme.filterCriterion.root.cursor,
  },
  option: {
    paddingTop: theme.filterCriterion.option.paddingTop,
    paddingLeft: theme.filterCriterion.option.paddingLeft,
    color: theme.filterCriterion.option.color,
  },
  valueContainer: {
    display: theme.filterCriterion.valueContainer.display,
  },
  value: {
    whiteSpace: theme.filterCriterion.value.whiteSpace,
    overflow: theme.filterCriterion.value.overflow,
    textOverflow: theme.filterCriterion.value.textOverflow,
  },
});

const FilterSelect = ({
  index: mainIndex,
  filterPillValue,
  updateFilterPillValue,
  options,
}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const MenuRefrence = React.createRef();
  const classes = useStyles();

  React.useEffect(() => {
    setAnchorEl(MenuRefrence.current);
    if (filterPillValue.length === 0) {
      setIsOpen(true);
    }
  }, [MenuRefrence, filterPillValue]);

  return (
    <>
      <div
        ref={MenuRefrence}
        className={classes.root}
        onClick={(ev) => {
          setAnchorEl(ev.currentTarget);
          setIsOpen(true);
        }}
      >
        <div className={classes.valueContainer}>
          <div className={classes.value}>
            <Typography
              variant="x-small"
              fontWeight="bold"
            >
              {filterPillValue[0]}
            </Typography>
          </div>
        </div>
      </div>

      <Menu
        open={isOpen}
        onClose={() => {
          setAnchorEl(null);
          setIsOpen(false);
        }}
        anchorEl={anchorEl}
      >
        {
          options.map((option, index) => {

            return (
              <MenuItem
                key={index}
                onClick={() => {
                  updateFilterPillValue(mainIndex, [option]);
                  setIsOpen(false);
                }}
              >
                {
                  filterPillValue[0] === option ?
                    <>
                      <strong>{option}</strong>
                      <div
                        className={classes.option}
                      >
                        <CheckIcon/>
                      </div>
                    </>
                    :
                    <>
                      {option}
                    </>
                }
              </MenuItem>
            );
          })
        }
      </Menu>
    </>
  );

};

FilterSelect.propTypes = {
  index: PropTypes.number,
  filterPillValue: PropTypes.array,
  updateFilterPillValue: PropTypes.func,
  options: PropTypes.array,
};

export default FilterSelect;
