import React from 'react';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';

import {
  greyLight,
} from 'ui-library/color-palette';

const ConversationList = ({
  conversations,
  onConversationAction,
  dispatchUserInterfaceAction,
}) => {
  const [conversationMenuState, setConversationMenuState] = React.useState({
    id: null,
    anchorEl: null,
  });

  return (
    <>
      {
        conversations.map(conversation => {
          const {
            id: conversationId,
            connectionName,
            interlocutorOrganizationName,
            status,
          } = conversation;

          return (
            <ListItem
              key={conversationId}
              border={`1px solid ${greyLight}`}
              onClick={() => {
                onConversationAction(conversationId, 'open');
              }}
            >
              <ListItemContentSegment
                weight={3}
                minWidth={200}
              >
                <ListItemText>
                  <Typography variant="small" fontWeight="bold">
                    {connectionName}
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography variant="caption" color="grey" textTransform="capitalize">
                    {interlocutorOrganizationName}
                  </Typography>
                </ListItemText>
              </ListItemContentSegment>
              <ListItemContentSegment
                weight={3}
              >
                {
                  status !== 'active' ?
                    <ListItemText>
                      <Typography variant="small" textTransform="capitalize">
                        {status}
                      </Typography>
                    </ListItemText> :
                    null
                }
              </ListItemContentSegment>

              <ListItemContentSegment
                width={200}
                justifyContent="flex-end"
              >
                <ListItemActions>
                  <Button
                    data-test-id="conversation-context-menu-button"
                    variant="icon-custom"
                    textTransform="none"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={event => {
                      event.stopPropagation();
                      setConversationMenuState({
                        id: conversationId,
                        anchorEl: event.currentTarget,
                      });
                    }}
                  >
                    <MoreHorizIcon/>
                  </Button>
                  <Button
                    data-test-id="drill-down-conversation-button"
                    textTransform="none"
                    variant="icon-custom"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={() => {}}>
                    <NavigateNextIcon/>
                  </Button>
                </ListItemActions>
              </ListItemContentSegment>
              <Menu
                open={conversationMenuState.id === conversationId}
                anchorEl={conversationMenuState.anchorEl}
                onClose={() => {
                  setConversationMenuState({
                    id: null,
                    anchorEl: null,
                  });
                }}>
                {
                  status !== 'frozen' &&
                  <MenuItem
                    data-test-id="context-menu-archive-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setConversationMenuState({
                        id: null,
                        anchorEl: null,
                      });
                      if (status === 'active') {
                        onConversationAction(conversationId, 'archive');
                      } else {
                        onConversationAction(conversationId, 'unarchive');
                      }
                    }}
                  >
                    <Typography variant="small">
                      {status === 'active' ? 'Archive' : 'Unarchive'}
                    </Typography>
                  </MenuItem>
                }
                {
                  status !== 'active' &&
                  <MenuItem
                    data-test-id="context-menu-delete-forever-button"
                    onClick={event => {
                      event.stopPropagation();
                      setConversationMenuState({
                        id: null,
                        anchorEl: null,
                      });
                      onConversationAction(conversationId, 'delete');
                    }}
                  >
                    <Typography variant="small" color="error">
                      Delete Forever
                    </Typography>
                  </MenuItem>
                }
              </Menu>
            </ListItem>
          );
        })
      }
    </>
  );
};

export default ConversationList;
