import React from 'react';
import Typography from 'ui-library/components/Typography';

const PageDescription = ({
  children,
}) => {
  return (
    <div>
      <Typography
        variant="caption">
        {children}
      </Typography>
    </div>
  );
};

export default PageDescription;
