import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Button from 'ui-library/components/Button';

const FilterRowClearButton = ({
  onClick,
}) => {
  return (
    <Button
      data-test-id="clear-all-filters-button"
      variant="icon-custom"
      textTransform="none"
      customBgColor="grey-light"
      customTextColor="grey"
      customHoverBgColor="grey-light"
      customHoverTextColor="tertiary-light"
      customActiveBgColor="grey-light"
      customActiveTextColor="grey"
      onClick={onClick}
    >
      <CloseIcon/>
    </Button>
  );
};

FilterRowClearButton.propTypes = {
  onClick: PropTypes.func,
};

export default FilterRowClearButton;
