import React from 'react';
import AdminContext from 'contexts/Admin';
import ItemsList from './ItemsList.react';
import AddItemDialog from './AddItemDialog.react';
import Typography from '@material-ui/core/Typography';

function deriveIndex(items) {
  let index = items.length;

  for (let item of items) {
    const {
      displayIndex,
    } = item;
    if (index <= displayIndex) {
      index = displayIndex + 1;
    }
  }

  return index;
}

const EhrHub = () => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);


  const ehrRecordTypes = DataModelQueries.selectElectronicHealthRecordTypes(DataModel);
  const ehrHubTypes = DataModelQueries.selectElectronicHealthHubTypes(DataModel);
  const ehrHubTypeIds = DataModelQueries.selectElectronicHealthHubTypeIds(DataModel);
  const ehrHubAvailableTypes = ehrRecordTypes.filter(type => !ehrHubTypeIds.includes(type.id));
  // const ehrHubTypesById = DataModelQueries.selectElectronicHealthHubTypesById(DataModel);

  // console.log('DEBUG - DataModel', DataModel);
  // console.log('DEBUG - DataModelQueries', DataModelQueries);
  // console.log('DEBUG - ehrRecordTypes', ehrRecordTypes);
  // console.log('DEBUG - ehrHubTypes', ehrHubTypes);
  // console.log('DEBUG - ehrHubTypeIds', ehrHubTypeIds);
  // console.log('DEBUG - ehrHubAvailableTypes', ehrHubAvailableTypes);
  // console.log('DEBUG - ehrHubTypesById', ehrHubTypesById);

  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  return (
    <>
      <div>
        <Typography
          variant="h4"
        >
          eHR Hub Configurator
        </Typography>
        <br/>
        <br/>

        <ItemsList
          items={ehrHubTypes}
          onAddItem={(id) => {
            dispatchLocalAction({
              type: 'BEGIN_ADD_ITEM',
            });
          }}
          onRemoveItem={async(id) => {
            try {
              await DataModelAPI.removeTypeFromEhrHub(
                authToken,
                perspectiveId,
                {
                  typeId: id,
                },
              );
              dispatchDataModelAction({
                type: 'ITEM_REMOVED',
                payload: {
                  typeId: id,
                },
              });
            } catch (error) {
              setAPIException(error);
            }
            dispatchLocalAction({
              type: 'END_REMOVE_ITEM',
            });
          }}
          onReorderItem={async itemsIndexPatches => {
            itemsIndexPatches.forEach((item) => {
              const {
                newIndex,
                id: typeId,
              } = item;

              dispatchDataModelAction({
                type: 'UPDATE_ITEM_DISPLAY_INDEX',
                payload: {
                  typeId,
                  displayIndex: newIndex,
                },
              });
            });
            dispatchLocalAction({
              type: 'BEGIN_REORDER_ITEMS',
            });
            try {
              await Promise.all(
                itemsIndexPatches.map(patch => {
                  const {
                    id: typeId,
                    newIndex: displayIndex,
                  } = patch;

                  return DataModelAPI.addTypeToEhrHub(
                    authToken,
                    perspectiveId,
                    {
                      typeId,
                      displayIndex,
                    },
                  );
                }),
              );
            } catch(error) {
              setAPIException(error);
            }
            dispatchLocalAction({
              type: 'END_REORDER_ITEMS',
            });
          }}
        />
      </div>


      <AddItemDialog
        open={
          localState.status === 'adding item' ||
          localState.status === 'requesting add item'
        }
        controlsDisabled={
          localState.status === 'requesting add item'
        }
        typeId={localState.typeId}
        types={ehrHubAvailableTypes}
        onSetItem={value => {
          dispatchLocalAction({
            type: 'SET_ITEM',
            payload: value,
          });
        }}
        onClose={() => {
          dispatchLocalAction({
            type: 'END_ADD_ITEM',
          });
        }}
        onSaveItem={async() => {
          dispatchLocalAction({
            type: 'REQUEST_ADD_ITEM',
          });
          try {
            const {
              typeId,
            } = localState;
            const displayIndex = deriveIndex(ehrHubTypes);
            await DataModelAPI.addTypeToEhrHub(
              authToken,
              perspectiveId,
              {
                typeId,
                displayIndex,
              },
            );
            dispatchDataModelAction({
              type: 'ITEM_ADDED',
              payload: {
                typeId,
                displayIndex,
              },
            });
          } catch(error) {
            setAPIException(error);
          }
          dispatchLocalAction({
            type: 'END_ADD_ITEM',
          });
        }}
      />
      <br/><br/>
    </>
  );
};


function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'SET_ITEM': {
      const typeId = payload;

      return {
        ...state,
        typeId,
      };
    }

    case 'BEGIN_ADD_ITEM': {
      return {
        ...state,
        status: 'adding item',
      };
    }

    case 'REQUEST_ADD_ITEM': {
      return {
        ...state,
        status: 'requesting add item',
      };
    }

    case 'ITEM_ADDED': {
      return {
        ...state,
        status: 'item added',
      };
    }

    case 'END_ADD_ITEM': {
      return {
        ...initState(),
      };
    }

    case 'BEGIN_REMOVE_ITEM': {
      const typeId = payload;

      return {
        ...state,
        status: 'removing item',
        typeId,
      };
    }

    case 'REQUEST_REMOVE_ITEM': {
      return {
        ...state,
        status: 'requesting remove item',
      };
    }

    case 'END_REMOVE_ITEM': {
      return {
        ...initState(),
      };
    }

    case 'BEGIN_REORDER_ITEMS': {
      return {
        ...state,
        status: 'requesting reorder items',
      };
    }

    case 'END_REORDER_ITEMS': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      adding item
      requesting add item
      item added
      removing item
      requesting remove item

      requesting reorder items
    */
    status: 'idle',
    typeId: '',
  };
}

export default EhrHub;
