import DEL__persons_ID from 'network-layer/endpoints/DEL__persons_ID';

function responseAdapter(response) {
  return response;
}

export default async function deletePerson(params) {
  const response = await DEL__persons_ID(params);

  return responseAdapter(response);
}
