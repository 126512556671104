import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  grey,
  black,
  primary,
} from 'ui-library/color-palette';

const Root = styled.span`
  color: ${props => props.isFirst ? black : grey};
  text-transform: ${props => props.textTransform ? props.textTransform : 'none'};
`;

const Content = styled.span`
  cursor: ${props => props.isInteractable ? 'pointer' : 'default'};
  :hover {
    color: ${props => props.isInteractable && primary};
  }
`;

const WizardBreadcrumb = ({
  children,
  isFirst,
  isLast,
  onClick,
  ...otherProps
}) => {
  return (
    <Root
      isFirst={isFirst}
      onClick={() => {
        typeof onClick === 'function' && onClick();
      }}
      {...otherProps}
    >
      {
        !isFirst && <>/&nbsp;</>
      }
      <Content
        isInteractable={typeof onClick === 'function'}>
        {children}
      </Content>
      &nbsp;
    </Root>
  );
};

WizardBreadcrumb.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onClick: PropTypes.func,
};

export default WizardBreadcrumb;
