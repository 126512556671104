import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  main: {
    display: theme.bannerMessage.main.display,
    flexGrow: theme.bannerMessage.main.flexGrow,
    flexDirection: theme.bannerMessage.main.flexDirection,
    padding: theme.bannerMessage.main.padding,
    borderRadius: theme.bannerMessage.main.borderRadius,
    backgroundColor: props => theme.bannerMessage.main.backgroundColor[props.messageType],
    color: props => theme.bannerMessage.main.color[props.messageType],
  },
  wrapper: theme.bannerMessage.wrapper,
  headerIcon: theme.bannerMessage.headerIcon,
  headerText: theme.bannerMessage.headerText,
  bodyText: theme.bannerMessage.bodyText,
});

const BannerMessage = ({
  icon,
  title,
  type,
  children,
}) => {

  const messageType = type ? type : 'info';

  const classes = useStyles({
    messageType,
  });

  return (
    <div className={classes.main}>
      <div className={classes.wrapper}>
        <div className={classes.headerIcon}>
          {icon}
        </div>
        <div className={classes.headerText}>
          <Typography
            variant="body"
            fontWeight="bold"
          >
            {title}
          </Typography>
        </div>
      </div>
      <div className={classes.bodyText}>
        {children}
      </div>
    </div>
  );
};

BannerMessage.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'warning', 'error']),
  children: PropTypes.node,
};

export default BannerMessage;
