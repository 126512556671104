import selectVirtualLocationTreeNodeHints from './selectVirtualLocationTreeNodeHints';

function getVirtualLocationTreeHintParent(
  configurationState,
  virtualLocationTreeHint,
) {
  const virtualLocationTreeNodeHints = selectVirtualLocationTreeNodeHints(configurationState);
  const {
    parentId,
  } = virtualLocationTreeHint;

  return (
    virtualLocationTreeNodeHints.find(virtualLocationTreeHint => {
      const {
        id,
      } = virtualLocationTreeHint;

      return parentId === id;
    })
  );
}

export default getVirtualLocationTreeHintParent;
