import React from 'react';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';
import EHRPersonStep from './steps/EHRPersonStep';
import EHRItemStep from './steps/EHRItemStep';
import EHRItemDetailsStep from './steps/EHRItemDetailsStep';
import EHRItemSaveStep from './steps/EHRItemSaveStep';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genNodeSpawnedRecords from 'services/Nodes/genNodeSpawnedRecords';
import genTypeByTypeId from 'services/Types/genTypeByTypeId';
import saveNodeService from 'services/Nodes/saveNode';
import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';

import {
  PERSON_STEP,
  TYPE_STEP,
  DETAILS_STEP,
  SAVE_STEP,
} from 'constants/addItem.js';

import {
  initHealthItemState,
  healthItemReducer,
} from 'reducers/AddHealthItem';

const steps = [
  PERSON_STEP,
  TYPE_STEP,
  DETAILS_STEP,
  SAVE_STEP,
];

const AddEHRDataWizard = ({
  onClose,
  onSwitchWizard,
}) => {

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const TOTAL_STEPS = 4;
  const [step, setStep] = React.useState(1);
  // 'notSaved' | 'saving' | 'saved'
  const [nodeSaveState, setNodeSaveState] = React.useState('notSaved');

  const [healthItemState, dispatchHealthItemAction] = React.useReducer(
    healthItemReducer,
    null,
    initHealthItemState,
  );

  const {
    personId,
    personName,
    healthRecordId,
    typeId,
    typeName,
    name,
    fieldDefinitionsById,
    fieldsByFieldDefinitionId,
    fieldDefinitionIds,
  } = healthItemState;

  const [fieldDefinitionsAreInitialized, setFieldDefinitionsAreInitialized] = React.useState(false);

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (fatalError) {
    throw fatalError;
  }

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  React.useEffect(() => {
    const loadFieldDefinitions = async() => {
      try {
        setFieldDefinitionsAreInitialized(false);
        const typeDefinition = await genTypeByTypeId({
          authToken,
          perspectiveId: currentPerspectiveId,
          typeId: typeId,
        });
        const {
          fieldDefinitions: fieldDefinitionsById,
        } = typeDefinition || {};

        const fieldDefinitionIds = Object.keys(fieldDefinitionsById || {}).map(key => {
          return {
            id: key,
            index: fieldDefinitionsById[key].index,
          };
        }).sort((a, b) => {
          const {
            index: indexA,
          } = a;
          const {
            index: indexB,
          } = b;

          return indexA - indexB;
        }).map(obj => obj.id);

        const fieldsByFieldDefinitionId = {};
        fieldDefinitionIds.forEach(id => {
          fieldsByFieldDefinitionId[id] = {
            value: fieldDefinitionsById[id].type === 'date' ? null : '',
            errorMessage: '',
            type: fieldDefinitionsById[id].type,
          };
        });

        dispatchHealthItemAction({
          type: 'HYDRATE_FIELDS',
          payload: {
            fieldDefinitionsById,
            fieldsByFieldDefinitionId,
            fieldDefinitionIds,
          },
        });

        setFieldDefinitionsAreInitialized(true);
      } catch (error) {
        setError(error);
      }
    };
    if (typeId) {
      loadFieldDefinitions();
    }
  }, [authToken, currentPerspectiveId, typeId]);

  async function saveEHRItem(fields) {
    try {
      setNodeSaveState('saving');

      let params = {
        name,
        typeId,
        recordId: healthRecordId,
      };

      if (fields) {
        params = {
          ...params,
          fields,
        };
      }

      const successfullySavedNode = await saveNodeService(
        authToken,
        currentPerspectiveId,
        params,
      );

      const {
        id: nodeId,
      } = successfullySavedNode;

      const successfullySavedNodeWithLocationPath = await genNodeByNodeId({authToken, perspectiveId: currentPerspectiveId, nodeId, queryParams: ['locationPath']});

      dispatchHealthItemAction({
        type: 'SET_SUCCESSFULLY_SAVED_NODE',
        payload: successfullySavedNodeWithLocationPath,
      });

      // clear type, nickname and fields values
      dispatchHealthItemAction({
        type: 'CLEAR_ITEM_VALUES',
      });

      setNodeSaveState('saved');
    } catch (error) {
      setError(error);
    }
  }

  function closeWizard() {
    if (!window.confirm('Are you sure you want to quit the wizard? Your changes will not be saved if you quit now.')) {
      return;
    }
    onClose();
  }

  function closeWizardWithPrepend() {
    onClose();
  }

  const navigateToStep = (stepName) => {
    const index = steps.indexOf(stepName) + 1;
    return setStep(index);
  };

  return (
    <Wizard onClose={closeWizard}>
      <WizardProgress
        isVisible={true}
        progress={step}
        total={TOTAL_STEPS}
      />
      <WizardNavigation>
        <WizardBackButton
          isVisible={step > 0 && nodeSaveState === 'notSaved'}
          onClick={() => {
            step > 1 && setStep(step - 1);
            step === 1 && onSwitchWizard();
          }}
        />
        <WizardCloseButton
          isVisible={step < TOTAL_STEPS && nodeSaveState !== 'saving'}
          onClick={closeWizard}
        />
      </WizardNavigation>

      <EHRPersonStep
        active={step === steps.indexOf(PERSON_STEP) + 1}
        onSetEHRPerson={async(selectedPerson) => {
          if (selectedPerson.personId !== personId) {
            dispatchHealthItemAction({
              type: 'SET_PERSON_ID',
              payload: selectedPerson.personId,
            });
            dispatchHealthItemAction({
              type: 'SET_PERSON_NAME',
              payload: selectedPerson.personName,
            });
            const records = await genNodeSpawnedRecords(authToken, currentPerspectiveId, selectedPerson.personId, ['recordType']);
            const recordId = records.filter(record => record.recordType.name === 'Health Record').map(record => record.id)[0];
            dispatchHealthItemAction({
              type: 'SET_HEALTH_RECORD_ID',
              payload: recordId,
            });
          }
          setStep(step + 1);
        }}
        closeWizardWithPrepend={closeWizardWithPrepend}
      />

      <EHRItemStep
        active={step === steps.indexOf(TYPE_STEP) + 1}
        personName={personName}
        healthRecordId={healthRecordId}
        onSetEHRItem={(itemType) => {
          dispatchHealthItemAction({
            type: 'SET_TYPE_ID',
            payload: itemType.typeId,
          });
          dispatchHealthItemAction({
            type: 'SET_TYPE_NAME',
            payload: itemType.typeName,
          });
          dispatchHealthItemAction({
            type: 'SET_NAME',
            payload: itemType.typeCount === 0 ? itemType.typeName : `${itemType.typeName}${itemType.typeCount + 1}`,
          });
          setNodeSaveState('notSaved');
          setStep(step + 1);
        }}
        navigateToStep={navigateToStep}
        nodeSaveState={nodeSaveState}
        setError={setError}
      />

      <EHRItemDetailsStep
        active={step === steps.indexOf(DETAILS_STEP) + 1}
        personName={personName}
        healthRecordId={healthRecordId}
        healthTypeName={typeName}
        fieldDefinitionsById={fieldDefinitionsById}
        fieldsByFieldDefinitionId={fieldsByFieldDefinitionId}
        fieldDefinitionIds={fieldDefinitionIds}
        fieldDefinitionsAreInitialized={fieldDefinitionsAreInitialized}
        onSetFieldValue={(fieldDefinitionId, fieldValue) => {
          dispatchHealthItemAction({
            type: 'SET_FIELD_VALUE',
            payload: {
              fieldDefinitionId,
              value: fieldValue,
            },
          });
        }}
        onSetFieldErrorMessage={(fieldDefinitionId, errorMessage) => {
          dispatchHealthItemAction({
            type: 'SET_ERROR_MESSAGE',
            payload: {
              fieldDefinitionId,
              errorMessage: errorMessage,
            },
          });
        }}
        saveEHRItem={saveEHRItem}
        navigateToStep={navigateToStep}
      />

      <EHRItemSaveStep
        active={step === steps.indexOf(SAVE_STEP) + 1}
        personName={personName}
        healthTypeName={typeName}
        nodeSaveState={nodeSaveState}
        navigateToStep={navigateToStep}
      />
    </Wizard>
  );
};

export default AddEHRDataWizard;
