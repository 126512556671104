import React from 'react';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    display: theme.batchActionMenu.display,
    flexDirection: theme.batchActionMenu.flexDirection,
    position: theme.batchActionMenu.position,
    right: props => theme.batchActionMenu.right + props.scrollbarWidth,
  },
});

const BatchActionMenu = ({
  children,
}) => {
  const [scrollbarWidth, setScrollbarWidth] = React.useState(0);
  React.useEffect(() => {
    setScrollbarWidth(window.innerWidth - document.body.clientWidth);
  }, []);

  const classes = useStyles({scrollbarWidth});

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default BatchActionMenu;
