function initPropertyState() {
  return {
    typeId: null,
    typeName: '',
    recordId: null,
    recordName: '',
    name: '',
    fieldDefinitionsById: {},
    fieldsByFieldDefinitionId: {},
    fieldDefinitionIds: [],
    successfullySavedNode: null,
    propertyConfigurationHint: null,
  };
}

function propertyReducer(state, {type: actionType, payload}) {
  switch(actionType) {
    case 'SET_TYPE': {
      return {
        ...state,
        typeId: payload,
      };
    }
    case 'SET_NAME': {
      return {
        ...state,
        name: payload,
      };
    }
    case 'SET_RECORD': {
      return {
        ...state,
        recordId: payload.id,
        recordName: payload.name,
      };
    }
    case 'SET_SUCCESSFULLY_SAVED_NODE': {
      return {
        ...state,
        successfullySavedNode: {...payload},
      };
    }
    case 'CLEAR': {
      return {
        ...state,
        typeId: null,
        typeName: '',
        recordId: null,
        recordName: '',
        name: '',
        fieldDefinitionsById: {},
        fieldsByFieldDefinitionId: {},
        fieldDefinitionIds: [],
        successfullySavedNode: null,
      };
    }
    case 'HYDRATE_FIELDS': {
      const {
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
      } = payload;

      return {
        ...state,
        fieldDefinitionsById,
        fieldsByFieldDefinitionId,
        fieldDefinitionIds,
      };
    }
    case 'SET_FIELD_VALUE': {
      const {
        fieldDefinitionId,
        value,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            value,
          },
        },
      };
    }
    case 'SET_CONFIGURATION_HINT': {
      return {
        ...state,
        propertyConfigurationHint: payload,
      };
    }
    case 'SET_ERROR_MESSAGE': {
      const {
        fieldDefinitionId,
        errorMessage,
      } = payload;
      const {
        fieldsByFieldDefinitionId,
        fieldsByFieldDefinitionId: {
          [fieldDefinitionId]: selectedNode,
        },
      } = state;

      return {
        ...state,
        fieldsByFieldDefinitionId: {
          ...fieldsByFieldDefinitionId,
          [fieldDefinitionId]: {
            ...selectedNode,
            errorMessage,
          },
        },
      };
    }
    default:
      throw new Error(`Unknown property action "${actionType}"!`);
  }
}

export {
  initPropertyState,
  propertyReducer,
};
