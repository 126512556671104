import React from 'react';
import styled from '@emotion/styled';
import ContextualPanel from 'components/chrome/ContextualPanel';
import ContextualPanelNavigation from 'components/chrome/ContextualPanelNavigation';
import ContextualPanelHeader from 'components/chrome/ContextualPanelHeader';
import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import ContextualPanelTabs from 'components/chrome/ContextualPanelTabs';
import ContextualPanelTab from 'components/chrome/ContextualPanelTab';
import ContextualPanelBackButton from 'components/chrome/ContextualPanelBackButton';
import ContextualPanelCloseButton from 'components/chrome/ContextualPanelCloseButton';
import ContextualPanelActions from 'components/chrome/ContextualPanelActions';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import ResourceNotFoundModal from 'components/ResourceNotFoundModal';
import Notification from 'ui-library/components/Notification';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import NotificationsSettings from './NotificationsSettings';

import UserInterfaceContext from 'contexts/UserInterface';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genNotifications from 'services/Notifications/genNotifications';
import updateNotificationById from 'services/Notifications/updateNotificationById';
import updateNotifications from 'services/Notifications/updateNotifications';
import deleteNotificationById from 'services/Notifications/deleteNotificationById';
import clearNotifications from 'services/Notifications/clearNotifications';
import genNotificationTypes from 'services/Notifications/genNotificationTypes';
import genNotificationsPushStatus from 'services/Notifications/genNotificationsPushStatus';
import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';
import genRecordByRecordId from 'services/Records/genRecordByRecordId';
import genConnectionByConnectionId from 'services/Connections/genConnectionByConnectionId';
import genConversationsByNodeId from 'services/Conversations/genConversationsByNodeId';
import genConversationsByRecordId from 'services/Conversations/genConversationsByRecordId';
import genConversationsByConnectionId from 'services/Conversations/genConversationsByConnectionId';

import {
  initNotificationsState,
  notificationsReducer,
} from 'reducers/Notifications';

import {
  contextualPanelActionsHeight,
  contextualPanelNavigationHeight,
  contextualPanelHeaderHeight,
  gutter,
} from 'constants/layout';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const NotificationsContainer = styled.div`
  margin: 32px 0 0 0;
`;

const NoNotificationsContainer = styled.div`
  padding-top: calc(2 * ${gutter}px);
  padding-bottom: ${contextualPanelActionsHeight + 50}px;
  min-height: calc(100% - ${contextualPanelHeaderHeight}px - ${contextualPanelNavigationHeight}px);
  display: flex;
  justify-content: center;
`;

const ClearButtonContainer = styled.div`
  margin-left: 8px;
`;

const LIMIT = 100;

const NotificationsPanel = () => {

  const {
    showNotificationsPanel,
    notificationsUnreadCount,
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw(fatalError);
  }

  const [notificationsState, dispatchNotificationsAction] = React.useReducer(
    notificationsReducer,
    null,
    initNotificationsState,
  );

  const {
    notificationsById,
    notificationIds,
    hasMore,
    notificationTypesById,
    notificationTypeIds,
    pushStatus,
  } = notificationsState;

  const [isLoadingNotifications, setIsLoadingNotifications] = React.useState(false);
  const [isNotificationsPanelInitialized, setIsNotificationsPanelInitialized] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [hoveredTab, setHoveredTab] = React.useState(null);
  const [showResourceNotFoundModal, setShowResourceNotFoundModal] = React.useState(false);

  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    const getPushNotificationsStatus = async() => {
      try {
        const notificationsPushStatus = await genNotificationsPushStatus(authToken);
        const {
          enabledStatus,
        } = notificationsPushStatus;
        dispatchNotificationsAction({
          type: 'HYDRATE_PUSH_STATUS',
          payload: enabledStatus,
        });
      } catch(error) {
        setError(error);
      }
    };
    if (showNotificationsPanel) {
      getPushNotificationsStatus();
    }
  },[authToken, showNotificationsPanel]);

  React.useEffect(() => {
    const loadNotificationTypes = async() => {
      try {
        const notificationTypes = await genNotificationTypes(authToken);
        dispatchNotificationsAction({
          type: 'HYDRATE_NOTIFICATION_TYPES',
          payload: notificationTypes,
        });
      } catch(error) {
        setError(error);
      }
    };
    if (showNotificationsPanel) {
      loadNotificationTypes();
    }
  },[authToken, showNotificationsPanel]);

  const observer = React.useRef();
  const lastNodeRef = React.useCallback(notification => {
    if (isLoadingNotifications) {
      return;
    }
    if (observer.current) {
      observer.current.disconnect();
    }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setOffset(offset + LIMIT);
      }
    });
    if (notification) {
      observer.current.observe(notification);
    }
  }, [hasMore, isLoadingNotifications, offset]);

  React.useEffect(()=>{
    if (showNotificationsPanel && notificationsUnreadCount) {
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_NOTIFICATIONS_UNREAD_COUNT',
        payload: 0,
      });
      setOffset(0);
    }
  }, [dispatchUserInterfaceAction, notificationsUnreadCount, showNotificationsPanel]);

  React.useEffect(() => {
    const loadNotifications = async() => {
      try {
        offset === 0 && setIsNotificationsPanelInitialized(false);
        setIsLoadingNotifications(true);

        const notifications = await genNotifications(authToken, {limit: LIMIT, offset: offset});
        if (offset === 0) {
          dispatchNotificationsAction({
            type: 'HYDRATE_NOTIFICATIONS',
            payload: notifications,
          });
        } else {
          dispatchNotificationsAction({
            type: 'HYDRATE_ADDITIONAL_NOTIFICATIONS',
            payload: notifications,
          });
        }

        setIsLoadingNotifications(false);
        setIsNotificationsPanelInitialized(true);
      } catch(error) {
        setError(error);
      }
    };

    if (showNotificationsPanel) {
      loadNotifications();
    }
  }, [authToken, offset, showNotificationsPanel]);

  const checkNotificationResourceAccess = async(resource) => {
    const {
      type,
    } = resource;

    switch (type) {
      case 'node': {
        const {
          nodeId,
        } = resource;

        try {
          await genNodeByNodeId({authToken, perspectiveId: currentPerspectiveId, nodeId});
          return true;
        } catch(error) {
          setShowResourceNotFoundModal(true);
          return false;
        }
      }
      case 'record': {
        const {
          recordId,
        } = resource;

        try {
          await genRecordByRecordId(authToken, currentPerspectiveId, recordId);
          return true;
        } catch(error) {
          setShowResourceNotFoundModal(true);
          return false;
        }
      }
      case 'connection': {
        const {
          connectionId,
        } = resource;

        try {
          await genConnectionByConnectionId(authToken, currentPerspectiveId, connectionId);
          return true;
        } catch(error) {
          setShowResourceNotFoundModal(true);
          return false;
        }
      }
      default: {
        break;
      }
    }
  };

  const toggleReadStatus = async(notificationId, currentReadStatus) => {
    dispatchNotificationsAction({
      type: 'TOGGLE_NOTIFICATION_READ_STATUS',
      payload: {
        id: notificationId,
      },
    });
    const updatedReadStatus = currentReadStatus === 'unread' ? 'read' : 'unread';

    await updateNotificationById(
      authToken,
      notificationId,
      {readStatus: updatedReadStatus},
    );
  };

  const deleteNotification = async(notificationId) => {
    dispatchNotificationsAction({
      type: 'DELETE_NOTIFICATION',
      payload: {
        id: notificationId,
      },
    });
    try {
      await deleteNotificationById(authToken, notificationId);
    } catch(error) {
      setError(error);
    }
  };

  const clearAll = async() => {
    dispatchNotificationsAction({
      type: 'DELETE_ALL_NOTIFICATIONS',
    });
    await clearNotifications(authToken);
  };

  const markAllAsRead = async() => {
    dispatchNotificationsAction({
      type: 'MARK_ALL_AS_READ',
    });
    await updateNotifications(authToken, {readStatus: 'read'});
  };

  return (
    <>
      <ContextualPanel
        expanded={showNotificationsPanel}
        handleEscKey={() => {
          dispatchUserInterfaceAction({
            type: 'SET_SHOW_NOTIFICATIONS_PANEL',
            payload: false,
          });
          setSelectedTab(0);
        }}
        onMouseDown={() => {}}
      >
        <ContextualPanelNavigation
          expanded={showNotificationsPanel}>
          <ContextualPanelBackButton
            data-test-id="notifications-contextual-panel-back-button"
            isVisible={false}
            onClick={() => {}}
          />
          <ContextualPanelCloseButton
            data-test-id="notifications-contextual-panel-close-button"
            isVisible={true}
            onMouseDown={(ev) => {
              ev.stopPropagation();
            }}
            onClick={() => {
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                payload: false,
              });
              setSelectedTab(0);
            }}
          />
        </ContextualPanelNavigation>

        <ContextualPanelHeader panelDisplayMode='default'>
          <Typography variant="h3" fontWeight="bold">
          Notifications
          </Typography>
          <Typography variant="caption">
          From here you can manage your account notifications.
          </Typography>
        </ContextualPanelHeader>

        <ContextualPanelContent panelDisplayMode='default'>
          {
            !isNotificationsPanelInitialized &&
          <CenterpieceSpinner/>
          }

          {
            isNotificationsPanelInitialized &&
          <>
            <ContextualPanelTabs selectedTab={selectedTab}>
              <ContextualPanelTab
                data-test-id="notifications-list-tab"
                onMouseEnter={() => setHoveredTab(0)}
                onMouseLeave={() => setHoveredTab(null)}
                tabIndex={0}
                selectedTab={selectedTab}
                hoveredTab={hoveredTab}
                setSelectedTab={setSelectedTab}
                label="List"
                icon={<ListAltRoundedIcon />}
              />
              <ContextualPanelTab
                data-test-id="notifications-settings-tab"
                onMouseEnter={() => setHoveredTab(1)}
                onMouseLeave={() => setHoveredTab(null)}
                tabIndex={1}
                selectedTab={selectedTab}
                hoveredTab={hoveredTab}
                setSelectedTab={setSelectedTab}
                label="Settings"
                icon={<SettingsOutlinedIcon />}
              />
            </ContextualPanelTabs>

            {/* LIST TAB CONTENT */}
            {
              selectedTab === 0 &&
              <PaddedContainer>
                {
                  isLoadingNotifications &&
                  <div style={{margin: '15px'}}>
                    <CenterpieceSpinner />
                  </div>
                }

                {
                  notificationIds.length === 0 &&
                  !isLoadingNotifications &&
                  <NoNotificationsContainer>
                    <Typography
                      variant="h5"
                      color="grey"
                      fontWeight="bold"
                    >
                      No notifications.
                    </Typography>
                  </NoNotificationsContainer>
                }

                {
                  notificationIds.length > 0 &&
                  !isLoadingNotifications &&
                  <NotificationsContainer>
                    {
                      notificationIds.map((notificationId, index) => {
                        const {
                          text,
                          timestamp,
                          event,
                          metadata,
                          readStatus,
                        } = notificationsById[notificationId];

                        return (
                          <Notification
                            ref={notificationIds.length === index + 1 ? lastNodeRef : null}
                            key={notificationId}
                            message={text}
                            age={timestamp}
                            readStatus={readStatus}
                            onToggleReadStatus={() => {
                              toggleReadStatus(notificationId, readStatus);
                            }}
                            onDelete={() => {
                              deleteNotification(notificationId);
                            }}
                            event={event}
                            onClickAction={async(ev) => {
                              ev.stopPropagation();
                              switch(event) {
                                case 'node emitted notification': {
                                  const {
                                    nodeId,
                                  } = metadata;
                                  const hasAccess = await checkNotificationResourceAccess({
                                    type: 'node',
                                    nodeId,
                                  });
                                  if (hasAccess) {
                                    dispatchUserInterfaceAction({
                                      type: 'SET_EXPANDED_NODE_ID',
                                      payload: nodeId,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_BACK_NAVIGATION_ENABLED',
                                      payload: {
                                        backNavigationEnabled: true,
                                        backNavigationLanding: 'notifications',
                                      },
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                      payload: false,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_NODE_PANEL',
                                      payload: true,
                                    });
                                  }
                                  if (readStatus === 'unread') {
                                    toggleReadStatus(notificationId, readStatus);
                                  }
                                  break;
                                }

                                case 'permissions granted on Node by partner or third party': {
                                  const {
                                    nodeId,
                                  } = metadata;
                                  const hasAccess = await checkNotificationResourceAccess({
                                    type: 'node',
                                    nodeId,
                                  });
                                  if (hasAccess) {
                                    dispatchUserInterfaceAction({
                                      type: 'SET_EXPANDED_NODE_ID',
                                      payload: nodeId,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_BACK_NAVIGATION_ENABLED',
                                      payload: {
                                        backNavigationEnabled: true,
                                        backNavigationLanding: 'notifications',
                                      },
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                      payload: false,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_NODE_PANEL',
                                      payload: true,
                                    });
                                  }
                                  if (readStatus === 'unread') {
                                    toggleReadStatus(notificationId, readStatus);
                                  }
                                  break;
                                }

                                case 'permissions granted on Record by partner or third party': {
                                  const {
                                    recordId,
                                  } = metadata;
                                  const hasAccess = await checkNotificationResourceAccess({
                                    type: 'record',
                                    recordId,
                                  });
                                  if (hasAccess) {
                                    dispatchUserInterfaceAction({
                                      type: 'SET_EXPANDED_RECORD_ID',
                                      payload: recordId,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_BACK_NAVIGATION_ENABLED',
                                      payload: {
                                        backNavigationEnabled: true,
                                        backNavigationLanding: 'notifications',
                                      },
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                      payload: false,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_RECORD_PANEL',
                                      payload: true,
                                    });
                                  }
                                  if (readStatus === 'unread') {
                                    toggleReadStatus(notificationId, readStatus);
                                  }
                                  break;
                                }

                                case 'connection established': {
                                  const {
                                    connectionId,
                                  } = metadata;
                                  const hasAccess = await checkNotificationResourceAccess({
                                    type: 'connection',
                                    connectionId,
                                  });
                                  if (hasAccess) {
                                    dispatchUserInterfaceAction({
                                      type: 'SET_EXPANDED_CONNECTION_ID',
                                      payload: connectionId,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_BACK_NAVIGATION_ENABLED',
                                      payload: {
                                        backNavigationEnabled: true,
                                        backNavigationLanding: 'notifications',
                                      },
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                      payload: false,
                                    });
                                    dispatchUserInterfaceAction({
                                      type: 'SET_SHOW_CONNECTION_PANEL',
                                      payload: true,
                                    });
                                  }
                                  if (readStatus === 'unread') {
                                    toggleReadStatus(notificationId, readStatus);
                                  }
                                  break;
                                }

                                case 'conversation comment node': {
                                  try {
                                    const {
                                      nodeId,
                                      conversationId,
                                    } = metadata;

                                    const conversations = await genConversationsByNodeId({
                                      authToken,
                                      perspectiveId: currentPerspectiveId,
                                      nodeId,
                                    });

                                    const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
                                    const {
                                      status: conversationStatus,
                                    } = conversation || {};

                                    if (conversationStatus === 'frozen') {
                                      dispatchUserInterfaceAction({
                                        type: 'SET_BACK_NAVIGATION_ENABLED',
                                        payload: {
                                          backNavigationEnabled: true,
                                          backNavigationLanding: 'notifications',
                                        },
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                        payload: false,
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                                        payload: conversation,
                                      });
                                    } else {
                                      const hasAccess = await checkNotificationResourceAccess({
                                        type: 'node',
                                        nodeId,
                                      });

                                      if (hasAccess) {
                                        dispatchUserInterfaceAction({
                                          type: 'SET_EXPANDED_NODE_ID',
                                          payload: nodeId,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_NODE_PANEL_SELECTED_TAB',
                                          payload: 5,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SELECTED_CONVERSATION',
                                          payload: conversation,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                                          payload: 'conversation',
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_BACK_NAVIGATION_ENABLED',
                                          payload: {
                                            backNavigationEnabled: true,
                                            backNavigationLanding: 'notifications',
                                          },
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                          payload: false,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NODE_PANEL',
                                          payload: true,
                                        });
                                      }
                                    }

                                    if (readStatus === 'unread') {
                                      toggleReadStatus(notificationId, readStatus);
                                    }
                                  } catch (error) {
                                    setError(error);
                                  }
                                  break;
                                }

                                case 'conversation comment node edit': {
                                  try {
                                    const {
                                      nodeId,
                                      conversationId,
                                    } = metadata;

                                    const conversations = await genConversationsByNodeId({
                                      authToken,
                                      perspectiveId: currentPerspectiveId,
                                      nodeId,
                                    });

                                    const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
                                    const {
                                      status: conversationStatus,
                                    } = conversation || {};

                                    if (conversationStatus === 'frozen') {
                                      dispatchUserInterfaceAction({
                                        type: 'SET_BACK_NAVIGATION_ENABLED',
                                        payload: {
                                          backNavigationEnabled: true,
                                          backNavigationLanding: 'notifications',
                                        },
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                        payload: false,
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                                        payload: conversation,
                                      });
                                    } else {
                                      const hasAccess = await checkNotificationResourceAccess({
                                        type: 'node',
                                        nodeId,
                                      });

                                      if (hasAccess) {
                                        dispatchUserInterfaceAction({
                                          type: 'SET_EXPANDED_NODE_ID',
                                          payload: nodeId,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_NODE_PANEL_SELECTED_TAB',
                                          payload: 5,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SELECTED_CONVERSATION',
                                          payload: conversation,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                                          payload: 'conversation',
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_BACK_NAVIGATION_ENABLED',
                                          payload: {
                                            backNavigationEnabled: true,
                                            backNavigationLanding: 'notifications',
                                          },
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                          payload: false,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NODE_PANEL',
                                          payload: true,
                                        });
                                      }
                                    }

                                    if (readStatus === 'unread') {
                                      toggleReadStatus(notificationId, readStatus);
                                    }
                                  } catch (error) {
                                    setError(error);
                                  }
                                  break;
                                }

                                case 'conversation comment record': {
                                  try {
                                    const {
                                      recordId,
                                      conversationId,
                                    } = metadata;

                                    const conversations = await genConversationsByRecordId({
                                      authToken,
                                      perspectiveId: currentPerspectiveId,
                                      recordId,
                                    });

                                    const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
                                    const {
                                      status: conversationStatus,
                                    } = conversation || {};

                                    if (conversationStatus === 'frozen') {
                                      dispatchUserInterfaceAction({
                                        type: 'SET_BACK_NAVIGATION_ENABLED',
                                        payload: {
                                          backNavigationEnabled: true,
                                          backNavigationLanding: 'notifications',
                                        },
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                        payload: false,
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                                        payload: conversation,
                                      });
                                    } else {
                                      const hasAccess = await checkNotificationResourceAccess({
                                        type: 'record',
                                        recordId,
                                      });

                                      if (hasAccess) {
                                        dispatchUserInterfaceAction({
                                          type: 'SET_EXPANDED_RECORD_ID',
                                          payload: recordId,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_RECORD_PANEL_SELECTED_TAB',
                                          payload: 1,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SELECTED_CONVERSATION',
                                          payload: conversation,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                                          payload: 'conversation',
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_BACK_NAVIGATION_ENABLED',
                                          payload: {
                                            backNavigationEnabled: true,
                                            backNavigationLanding: 'notifications',
                                          },
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                          payload: false,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_RECORD_PANEL',
                                          payload: true,
                                        });
                                      }
                                    }

                                    if (readStatus === 'unread') {
                                      toggleReadStatus(notificationId, readStatus);
                                    }
                                  } catch (error) {
                                    setError(error);
                                  }
                                  break;
                                }

                                case 'conversation comment record edit': {
                                  try {
                                    const {
                                      recordId,
                                      conversationId,
                                    } = metadata;

                                    const conversations = await genConversationsByRecordId({
                                      authToken,
                                      perspectiveId: currentPerspectiveId,
                                      recordId,
                                    });

                                    const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
                                    const {
                                      status: conversationStatus,
                                    } = conversation || {};

                                    if (conversationStatus === 'frozen') {
                                      dispatchUserInterfaceAction({
                                        type: 'SET_BACK_NAVIGATION_ENABLED',
                                        payload: {
                                          backNavigationEnabled: true,
                                          backNavigationLanding: 'notifications',
                                        },
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                        payload: false,
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                                        payload: conversation,
                                      });
                                    } else {
                                      const hasAccess = await checkNotificationResourceAccess({
                                        type: 'record',
                                        recordId,
                                      });

                                      if (hasAccess) {
                                        dispatchUserInterfaceAction({
                                          type: 'SET_EXPANDED_RECORD_ID',
                                          payload: recordId,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_RECORD_PANEL_SELECTED_TAB',
                                          payload: 1,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SELECTED_CONVERSATION',
                                          payload: conversation,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_RECORD_PANEL_DISPLAY_MODE',
                                          payload: 'conversation',
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_BACK_NAVIGATION_ENABLED',
                                          payload: {
                                            backNavigationEnabled: true,
                                            backNavigationLanding: 'notifications',
                                          },
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                          payload: false,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_RECORD_PANEL',
                                          payload: true,
                                        });
                                      }
                                    }

                                    if (readStatus === 'unread') {
                                      toggleReadStatus(notificationId, readStatus);
                                    }
                                  } catch (error) {
                                    setError(error);
                                  }
                                  break;
                                }

                                case 'conversation comment connection': {
                                  try {
                                    const {
                                      connectionId,
                                      conversationId,
                                    } = metadata;

                                    const conversations = await genConversationsByConnectionId({
                                      authToken,
                                      perspectiveId: currentPerspectiveId,
                                      connectionId,
                                    });

                                    const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];
                                    const {
                                      status: conversationStatus,
                                    } = conversation || {};

                                    if (conversationStatus === 'frozen') {
                                      dispatchUserInterfaceAction({
                                        type: 'SET_BACK_NAVIGATION_ENABLED',
                                        payload: {
                                          backNavigationEnabled: true,
                                          backNavigationLanding: 'notifications',
                                        },
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                        payload: false,
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                                        payload: conversation,
                                      });
                                    } else {
                                      const hasAccess = await checkNotificationResourceAccess({
                                        type: 'connection',
                                        connectionId,
                                      });

                                      if (hasAccess) {
                                        dispatchUserInterfaceAction({
                                          type: 'SET_EXPANDED_CONNECTION_ID',
                                          payload: connectionId,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_CONNECTION_PANEL_DISPLAY_MODE',
                                          payload: 'conversation',
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SELECTED_CONVERSATION',
                                          payload: conversation,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_BACK_NAVIGATION_ENABLED',
                                          payload: {
                                            backNavigationEnabled: true,
                                            backNavigationLanding: 'notifications',
                                          },
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                          payload: false,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_CONNECTION_PANEL',
                                          payload: true,
                                        });
                                      }
                                    }

                                    if (readStatus === 'unread') {
                                      toggleReadStatus(notificationId, readStatus);
                                    }
                                  } catch (error) {
                                    setError(error);
                                  }
                                  break;
                                }

                                case 'conversation comment connection edit': {
                                  try {
                                    const {
                                      connectionId,
                                      conversationId,
                                    } = metadata;

                                    const conversations = await genConversationsByConnectionId({
                                      authToken,
                                      perspectiveId: currentPerspectiveId,
                                      connectionId,
                                    });

                                    const conversation = conversations.filter(conversation => conversation.id === conversationId)[0];

                                    const {
                                      status: conversationStatus,
                                    } = conversation || {};

                                    if (conversationStatus === 'frozen') {
                                      dispatchUserInterfaceAction({
                                        type: 'SET_BACK_NAVIGATION_ENABLED',
                                        payload: {
                                          backNavigationEnabled: true,
                                          backNavigationLanding: 'notifications',
                                        },
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                        payload: false,
                                      });
                                      dispatchUserInterfaceAction({
                                        type: 'SET_EXPANDED_FROZEN_CONVERSATION',
                                        payload: conversation,
                                      });
                                    } else {
                                      const hasAccess = await checkNotificationResourceAccess({
                                        type: 'connection',
                                        connectionId,
                                      });

                                      if (hasAccess) {
                                        dispatchUserInterfaceAction({
                                          type: 'SET_EXPANDED_CONNECTION_ID',
                                          payload: connectionId,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_CONNECTION_PANEL_DISPLAY_MODE',
                                          payload: 'conversation',
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_BACK_NAVIGATION_ENABLED',
                                          payload: {
                                            backNavigationEnabled: true,
                                            backNavigationLanding: 'conversations',
                                          },
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                                          payload: false,
                                        });
                                        dispatchUserInterfaceAction({
                                          type: 'SET_SHOW_CONNECTION_PANEL',
                                          payload: true,
                                        });
                                      }
                                    }

                                    if (readStatus === 'unread') {
                                      toggleReadStatus(notificationId, readStatus);
                                    }
                                  } catch (error) {
                                    setError(error);
                                  }
                                  break;
                                }

                                default:
                                  break;
                              }

                            }}
                          />
                        );
                      })
                    }
                  </NotificationsContainer>
                }
              </PaddedContainer>
            }

            {/* SETTINGS TAB CONTENT */}
            {
              selectedTab === 1 &&
              <NotificationsSettings
                isPanelOpen={showNotificationsPanel}
                pushStatus={pushStatus}
                notificationTypeIds={notificationTypeIds}
                notificationTypesById={notificationTypesById}
                dispatchNotificationsAction={dispatchNotificationsAction}
              />
            }
          </>
          }
        </ContextualPanelContent>

        <ContextualPanelActions expanded={showNotificationsPanel}>
          {
            selectedTab === 0 &&
          <>
            <Button
              data-test-id="mark-all-notifications-as-read-button"
              disabled={
                notificationIds.length === 0 ||
              !Object.values(notificationsById).map(item => item.readStatus).includes('unread')
              }
              onClick={markAllAsRead}
            >
              Mark all as read
            </Button>
            <ClearButtonContainer>
              <Button
                data-test-id="clear-all-notifications-button"
                variant="text"
                disabled={notificationIds.length === 0}
                onClick={clearAll}
              >
                Clear all
              </Button>
            </ClearButtonContainer>
          </>
          }
        </ContextualPanelActions>
      </ContextualPanel>

      <ResourceNotFoundModal
        open={showResourceNotFoundModal}
        onClose={() => setShowResourceNotFoundModal(false)}
      />
    </>
  );
};

export default NotificationsPanel;
