import React from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import styled from '@emotion/styled';
import {
  primaryDarker,
} from 'ui-library/color-palette';
import Zoom from '@material-ui/core/Zoom';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  margin: 5px 0;
  padding: 7px 0;
  :hover {
    background-color: ${primaryDarker};
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelContainer = styled.div`
  align-items: center;
  padding: 5px 10px;
  text-align: center;
`;

const PipContainer = styled.div`
  display: flex;
  align-items: center;
  height: 8px;
`;

const Pip = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #fff;
`;

const NavigationButton = ({
  to,
  icon: Icon,
  label,
  index,
  history,
  location,
  ...otherProps
}) => {
  const [isSelected, setSelectionState] = React.useState(false);

  React.useEffect(() => {
    if (location.pathname.includes(to)) {
      setSelectionState(true);
    } else {
      setSelectionState(false);
    }
  }, [location.pathname, to]);

  return (
    <Zoom
      in
      timeout={200 + index * 100}>
      <Root
        onClick={() => {
          if (location.pathname !== to) {
            history.push(to);
          }
        }}
        {...otherProps}
      >
        <IconContainer>
          <Icon
            style={{
              color: '#fff',
              fontSize: 32,
            }}
          />
        </IconContainer>
        <LabelContainer>
          <Typography
            fontFamily="primary"
            variant="x-small"
            color="white">
            {label}
          </Typography>
        </LabelContainer>
        <PipContainer>
          {
            isSelected && <Pip/>
          }
        </PipContainer>
      </Root>
    </Zoom>
  );
};

NavigationButton.defaultProps = {
  index: 1,
};

NavigationButton.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  index: PropTypes.number,
};

export default withRouter(NavigationButton);
