import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__connections_ID_records_ID_nodes(authToken, perspectiveId, connectionId, recordId) {
  return await GET(
    `/connections/${connectionId}/records/${recordId}/nodes?include=locationPath`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId, connectionId, recordId),
  );
}
