import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import ChromeContainer from 'components/chrome/ChromeContainer';
import Navigation from 'components/chrome/Navigation';
import NavigationButton from 'components/chrome/NavigationButton';
import AccountPanel from 'components/AccountPanel';
import LogOutModal from 'components/LogOutModal';
import Content from 'components/chrome/Content';
import ApplicationBar from 'components/ApplicationBar';

import LibraryIcon from '@material-ui/icons/LibraryBooksRounded';
import ConnectionsIcon from '@material-ui/icons/Share';
import AddWizardIcon from '@material-ui/icons/LibraryAdd';
import SearchIcon from '@material-ui/icons/SearchRounded';

import Library from 'views/Library';
import Connections from 'views/Connections';
import BusinessSearch from 'views/BusinessSearch';
import BusinessAddWizard from 'views/BusinessAddWizard';
import Notifications from 'views/Notifications';
import ConversationVault from 'views/ConversationVault';

import SuspendedBusinessApplication from 'components/UserInterface/BusinessApplication/Suspended';

import UserInterfaceContext from 'contexts/UserInterface';
import UserContext from 'contexts/User';
import SubscriptionContext from 'contexts/Subscription';

import useInterval from 'utils/useInterval';

import genNotificationsUnreadCount from 'services/Notifications/genNotificationsUnreadCount';

const MILISECONDS_IN_MINUTE = 60000;

const BusinessApplication = () => {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);

  const {
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const [error/*, setError*/] = React.useState(false);
  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  React.useEffect(() => {
    async function getNotificationsCount() {
      try {
        const count = await genNotificationsUnreadCount(authToken);
        dispatchUserInterfaceAction({
          type: 'SET_SHOW_NOTIFICATIONS_UNREAD_COUNT',
          payload: count,
        });
      } catch (error) {
        // setError(error);
      }
    }
    getNotificationsCount();
  }, [authToken, dispatchUserInterfaceAction]);

  useInterval(async() => {
    try {
      const count = await genNotificationsUnreadCount(authToken);
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_NOTIFICATIONS_UNREAD_COUNT',
        payload: count,
      });
    } catch (error) {
      // setError(error);
    }
  }, MILISECONDS_IN_MINUTE);

  const subscription = React.useContext(SubscriptionContext);

  const {
    currentSubscriptionStatus,
  } = subscription;

  if (currentSubscriptionStatus === 'suspended') {
    return (
      <ChromeContainer>
        <SuspendedBusinessApplication />
      </ChromeContainer>
    );
  }

  return (
    <ChromeContainer>

      <ApplicationBar
        setIsPanelOpen={setIsPanelOpen}
        setIsLogoutModalOpen={setIsLogoutModalOpen}
      />

      <Navigation>
        <NavigationButton
          data-test-id="search-navigation-button"
          to="/search"
          icon={SearchIcon}
          label="Search"
          index={1}
        />
        <NavigationButton
          data-test-id="library-navigation-button"
          to="/library"
          icon={LibraryIcon}
          label="Library"
          index={2}
        />
        <NavigationButton
          data-test-id="connections-navigation-button"
          to="/connections"
          icon={ConnectionsIcon}
          label="Connections"
          index={4}
        />
        <NavigationButton
          data-test-id="add-flows-navigation-button"
          to="/add"
          icon={AddWizardIcon}
          label="Add"
          index={5}
        />
      </Navigation>

      <Content>
        <Switch>
          <Route
            exact
            path='/notifications'
            component={Notifications}
          />
          <Route
            exact
            path='/search/:searchContext'
            component={BusinessSearch}
          />
          <Redirect
            from="/search"
            to="/search/all"
          />
          <Route
            path="/library"
            component={Library}
          />
          <Route
            path="/connections"
            component={Connections}
          />
          <Route
            path="/add"
            component={BusinessAddWizard}
          />
          <Route
            path="/conversation-vault"
            component={ConversationVault}
          />
          <Redirect
            to="/search"
          />
        </Switch>
      </Content>

      <AccountPanel
        isPanelOpen={isPanelOpen}
        setIsPanelOpen={setIsPanelOpen}
        onCollapse={(name) => {
          setIsPanelOpen(false);
        }}
      />

      <LogOutModal
        isOpen={isLogoutModalOpen}
        handleClose={() => {
          setIsLogoutModalOpen(false);
        }}
      />

    </ChromeContainer>
  );
};

export default BusinessApplication;
