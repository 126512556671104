import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__records_ID_nodes(authToken, recordId, perspectiveId) {
  return await GET(
    `/records/${recordId}/nodes?include=locationPath`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
