import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PUT} from '../../verbs';

export default async function PUT__admin_fieldWatchers_FIELDWATCHERID_fieldDefinitions(authToken, perspectiveId, params) {
  const {
    fieldWatcherId,
    ...otherParams
  } = params;

  return await PUT(
    `/admin/field-watchers/${fieldWatcherId}/field-definitions`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...otherParams,
    },
  );
}
