import GET__records_ID_nodes from 'network-layer/endpoints/GET__records_ID_nodes';

function responseAdapter(response) {
  return response;
}

export default async function genNodesByRecordId(authToken, recordId, perspectiveId) {
  const response = await GET__records_ID_nodes(authToken, recordId, perspectiveId);

  return responseAdapter(response);
}
