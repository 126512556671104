import React from 'react';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';

import VirtualLocationTreeCrossSection from 'IntelligentDataFramework/VirtualLocationTree/components/VirtualLocationTreeCrossSection';
import EditVirtualLocationTreeNodeNameDialog from 'IntelligentDataFramework/VirtualLocationTree/components/EditVirtualLocationTreeNodeNameDialog';
import selectVirtualLocationTreeNodesByLocationDepth from 'IntelligentDataFramework/VirtualLocationTree/API/selectVirtualLocationTreeNodesByLocationDepth';
import Button from 'ui-library/components/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import propertyConfigurationHintReducer from 'IntelligentDataFramework/VirtualLocationTree/API/propertyConfigurationHintReducer';
import propertyConfigurationHintStateInitializer from 'IntelligentDataFramework/VirtualLocationTree/API/propertyConfigurationHintStateInitializer';
import propertyConfigurationHintUIReducer from 'IntelligentDataFramework/VirtualLocationTree/API/propertyConfigurationHintUIReducer';
import propertyConfigurationHintUIStateInitializer from 'IntelligentDataFramework/VirtualLocationTree/API/propertyConfigurationHintUIStateInitializer';

import genTypes from 'services/Types/genTypes';
import deriveMaximumVirtualLocationTreeDepth from 'IntelligentDataFramework/VirtualLocationTree/API/deriveMaximumVirtualLocationTreeDepth';
import selectVirtualLocationTreeNodes from 'IntelligentDataFramework/VirtualLocationTree/API/selectVirtualLocationTreeNodes';

import submitPropertyConfiguration from 'services/IntelligentDataFramework/submitPropertyConfiguration';

const PropertyConfigurationStep = ({
  active,
  propertyNickname,
  navigateToStep,
  propertySaveState,
  closeWizardWithPrepend,
  propertyConfigurationHint,
  authToken,
  perspectiveId,
  closeWizard,
  successfullySavedNode,
}) => {
  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isSavingConfiguration, setIsSavingConfiguration] = React.useState(false);

  const [configurationState, dispatchPropertyConfigurationAction] = React.useReducer(
    propertyConfigurationHintReducer,
    null,
    propertyConfigurationHintStateInitializer,
  );

  const [UIState, dispatchUIAction] = React.useReducer(
    propertyConfigurationHintUIReducer,
    null,
    propertyConfigurationHintUIStateInitializer,
  );

  const [locationDepth, setLocationDepth] = React.useState(0);

  React.useEffect(() => {
    async function initialize() {
      const types = await genTypes(authToken, perspectiveId);

      const {
        virtualLocationTreeTitle,
        virtualLocationTreeDescription,
        virtualLocationTreeNodeHints: inboundVirtualLocationTreeHints,
      } = propertyConfigurationHint;

      dispatchPropertyConfigurationAction({
        type: 'HYDRATE_PROPERTY_CONFIGURATION_HINT',
        payload: {
          virtualLocationTreeTitle,
          virtualLocationTreeDescription,
          virtualLocationTreeHints: inboundVirtualLocationTreeHints
            .map(inboundVirtualLocationTreeNodeHint => {
              const {
                id,
                parentId,
                typeId,
                prevalence,
                prevalenceWeight,
                text,
              } = inboundVirtualLocationTreeNodeHint;

              return {
                id,
                parentId,
                typeId,
                typeName: types.find(type => type.id === typeId).name,
                prevalence,
                prevalenceWeight,
                text,
              };
            }),
        },
      });
    }

    if (!propertyConfigurationHint) {
      return;
    }

    initialize();

    setIsInitialized(true);
  }, [authToken, perspectiveId, propertyConfigurationHint]);

  if (!active) {
    return null;
  }

  if (!isInitialized) {
    return (
      <CenterpieceSpinner/>
    );
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Configure property
          </WizardBreadcrumb>
        </WizardStepTitle>
      </WizardStepHead>

      <WizardContentColumn>
        {
          isSavingConfiguration ?
            <CenterpieceSpinner/>
            :
            <>
              <VirtualLocationTreeCrossSection
                locationDepth={locationDepth}
                configurationState={configurationState}
                dispatchPropertyConfigurationAction={dispatchPropertyConfigurationAction}
                UIState={UIState}
                dispatchUIAction={dispatchUIAction}
              />
              <br/>
              {
                locationDepth > 0 &&
                  <>
                    <Button
                      variant="text"
                      data-test-id="configure-property-back-button"
                      startIcon={<ArrowBackIcon/>}
                      onClick={() => {
                        if (locationDepth > 0) {
                          setLocationDepth(locationDepth - 1);
                        }
                      }}>
                      back
                    </Button>
                    &nbsp;&nbsp;
                  </>
              }
              {
                locationDepth < deriveMaximumVirtualLocationTreeDepth(configurationState) + 1 &&
                  <Button
                    data-test-id="configure-property-continue-button"
                    variant="primary"
                    onClick={() => {
                      setLocationDepth(locationDepth + 1);
                    }}
                    disabled={!selectVirtualLocationTreeNodesByLocationDepth(configurationState, locationDepth).length}
                  >
                    continue
                  </Button>
              }
              {
                locationDepth === deriveMaximumVirtualLocationTreeDepth(configurationState) + 1 &&
                  <Button
                    data-test-id="configure-property-save-configuration-button"
                    disabled={isSavingConfiguration}
                    variant="secondary"
                    onClick={async() => {
                      const nodesToSave = selectVirtualLocationTreeNodes(configurationState)
                        .map(node => {
                          const {
                            virtualId,
                            name,
                            typeId,
                            virtualLocationId,
                          } = node;

                          return {
                            virtualId,
                            name,
                            typeId,
                            virtualLocationId,
                          };
                        });

                      setIsSavingConfiguration(true);

                      try {
                        await submitPropertyConfiguration(
                          authToken,
                          perspectiveId,
                          {
                            nodeId: successfullySavedNode.id,
                            virtualLocationTreeNodes: nodesToSave,
                          },
                        );
                        closeWizardWithPrepend();
                      } catch(error) {
                        throw error;
                      }
                    }}>
                    save configuration
                  </Button>
              }

              <EditVirtualLocationTreeNodeNameDialog
                open={UIState.editingVirtualLocationTreeNodeId !== null}
                onClose={() => {
                  dispatchUIAction({
                    type: 'EDIT_VIRTUAL_LOCATION_TREE_NODE',
                    payload: {
                      virtualLocationTreeNodeId: null,
                    },
                  });
                }}
                name={UIState.editingVirtualLocationTreeNodeName}
                typeName={UIState.editingVirtualLocationTreeNodeTypeName}
                onChangeName={value => {
                  dispatchUIAction({
                    type: 'SET_EDITING_VIRTUAL_LOCATION_TREE_NODE_NAME',
                    payload: value,
                  });
                }}
                onSave={() => {
                  dispatchPropertyConfigurationAction({
                    type: 'SET_VIRTUAL_LOCATION_TREE_NODE_NAME',
                    payload: {
                      virtualLocationTreeNodeId: UIState.editingVirtualLocationTreeNodeId,
                      newName: UIState.editingVirtualLocationTreeNodeName,
                    },
                  });
                  dispatchUIAction({
                    type: 'EDIT_VIRTUAL_LOCATION_TREE_NODE',
                    payload: {
                      virtualLocationTreeNodeId: null,
                    },
                  });
                }}
              />
            </>
        }
      </WizardContentColumn>
    </WizardStep>
  );
};

export default PropertyConfigurationStep;
