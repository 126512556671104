import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FieldWatcherIcon from '@material-ui/icons/TrackChanges';
import FieldDefinitionIcon from '@material-ui/icons/PowerInput';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FieldDefinitionsList from './FieldDefinitionsList.react';

const FieldWatchersList = ({
  DataModelQueries,
  DataModel,
  fieldWatchers,
  onRemoveFieldWatcher,
  onEditFieldWatcher,
  onAddFieldDefinitionToFieldWatcher,
  onRemoveFieldDefinitionFromFieldWatcher,
}) => {
  return (
    <>
      {
        fieldWatchers
          .sort((fw1, fw2) => {
            const {
              timeDelta: timeDelta1,
            } = fw1;
            const {
              timeDelta: timeDelta2,
            } = fw2;
            if (timeDelta1 < timeDelta2) {
              return 1;
            }
            if (timeDelta1 > timeDelta2) {
              return -1;
            }
            return 0;
          })
          .map(fieldWatcher => {
            const {
              id: fieldWatcherId,
              type: fieldWatcherType,
              fieldDefinitionIds: fieldWatcherFieldDefinitionIds,
              timeDelta: fieldWatcherTimeDelta,
            } = fieldWatcher;

            return (
              <NestedBox
                key={fieldWatcherId}>
                <ContentLine>
                  <FieldWatcherIcon/>&nbsp;&nbsp;
                  <ContentLineText>
                    <Typography
                      variant="body1">
                      watching {fieldWatcherFieldDefinitionIds.length} {fieldWatcherType} fields with a time delta of <strong>{fieldWatcherTimeDelta}</strong> day{[-1, 1].includes(fieldWatcherTimeDelta) ? '' : 's'}:
                    </Typography>
                  </ContentLineText>
                  <Tooltip
                    placement="top"
                    title="edit this field watcher">
                    <span>
                      <IconButton
                        onClick={() => {
                          onEditFieldWatcher(fieldWatcherId);
                        }}>
                        <EditIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="select a field to watch">
                    <span>
                      <IconButton
                        onClick={() => {
                          onAddFieldDefinitionToFieldWatcher(fieldWatcherId);
                        }}>
                        <FieldDefinitionIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title="delete this field watcher">
                    <span>
                      <IconButton
                        onClick={() => {
                          onRemoveFieldWatcher(fieldWatcherId);
                        }}>
                        <DeleteIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                </ContentLine>

                <FieldDefinitionsList
                  DataModel={DataModel}
                  DataModelQueries={DataModelQueries}
                  fieldDefinitionIds={fieldWatcherFieldDefinitionIds}
                  onRemoveFieldDefinition={fieldDefinitionId => {
                    onRemoveFieldDefinitionFromFieldWatcher(fieldDefinitionId, fieldWatcherId);
                  }}
                />
              </NestedBox>
            );
          })
      }
    </>
  );
};

export default FieldWatchersList;
