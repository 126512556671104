import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const CreatePropertyConfigurationHintDialog = ({
  open,
  onClose,
  propertyTypes,
  targetTypeId,
  onSetTargetTypeId,
  virtualLocationTreeTitle,
  onSetVirtualLocationTreeTitle,
  virtualLocationTreeDescription,
  onSetVirtualLocationTreeDescription,
  onSave,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Create a configurator
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a property type and create a configurator for it.
        </DialogContentText>
        <Select
          fullWidth
          value={targetTypeId}
          onChange={event => {
            onSetTargetTypeId(event.target.value);
          }}>
          {
            propertyTypes.map(type => {
              const {
                id,
                name,
              } = type;
              return (
                <MenuItem
                  key={id}
                  value={id}>
                  {name}
                </MenuItem>
              );
            })
          }
        </Select>
        <TextField
          fullWidth
          margin="normal"
          value={virtualLocationTreeTitle}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 50) {
              onSetVirtualLocationTreeTitle(value);
            }
          }}
          label="Top-level title"
        />
        <TextField
          fullWidth
          margin="normal"
          value={virtualLocationTreeDescription}
          onChange={event => {
            const value = event.target.value;
            if (value.length <= 150) {
              onSetVirtualLocationTreeDescription(value);
            }
          }}
          label="Top-level description"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            targetTypeId === '' ||
            !virtualLocationTreeTitle ||
            !virtualLocationTreeDescription
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePropertyConfigurationHintDialog;
