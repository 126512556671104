import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {POST} from '../../verbs';

export default async function POST__admin_users_USERID_properties(authToken, perspectiveId, userId, propertyId) {
  return await POST(
    `/admin/users/${userId}/properties`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      propertyId,
    },
  );
}
