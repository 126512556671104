import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__conversations_frozen(params) {
  const {
    authToken,
    perspectiveId,
  } = params;

  return await GET(
    '/conversations/frozen',
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
