function selectTypeIdsInRecordTypeById(DM, recordTypeId) {
  const {
    recordTypes_Types: {
      [recordTypeId]: types,
    },
  } = DM;

  if (!types) {
    return [];
  }

  return types;
}

export default selectTypeIdsInRecordTypeById;
