import POST__conversations_connections_CONNECTIONID_records_RECORDID from 'network-layer/endpoints/POST__conversations_connections_CONNECTIONID_records_RECORDID';

function responseAdapter(response) {
  return response;
}

export default async function createRecordConversation(authToken, perspectiveId, params) {
  const response = await POST__conversations_connections_CONNECTIONID_records_RECORDID(authToken, perspectiveId, params);

  return responseAdapter(response);
}
