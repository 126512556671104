import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    borderRadius: '8px',
    marginRight: '8px',
    minHeight: '40px',
    backgroundColor: theme.searchBar.root.backgroundColor,
  },
  input: {
    padding: theme.searchBar.input.padding,
    color: theme.searchBar.input.color,
    fontSize: theme.searchBar.input.fontSize,
  },
});

const Textarea = ({
  rowsMax,
  placeholder,
  value,
  onChange,
  maxLength,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Input
        classes={{
          root: classes.input,
          focused: classes.focused,
        }}
        type="text"
        multiline
        rowsMax={rowsMax}
        fullWidth
        disableUnderline
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        inputProps={{
          maxLength: maxLength,
        }}
        {...otherProps}
      />
    </div>
  );
};

Textarea.propTypes = {
  rowsMax: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Textarea;
