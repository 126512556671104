import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__nodes_ID_records(authToken, perspectiveId, nodeId, include) {
  let url = `/nodes/${nodeId}/records`;

  if (include) {
    url += `?include=${include.join(',')}`;
  }

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
