import GET__nodeLinks_ID from 'network-layer/endpoints/GET__nodeLinks_ID';

function responseAdapter(response) {
  return response;
}

export default async function genNodeLinkById(authToken, perspectiveId, nodeId, params) {

  const response = await GET__nodeLinks_ID(authToken, perspectiveId, nodeId, params);

  return responseAdapter(response);
}
