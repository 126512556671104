import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const LocationParentsDialog = ({
  open,
  onClose,
  controlsDisabled,
  type,
  onAddTypeLocationGraphEdge,
  onDeleteTypeLocationGraphEdge,
  DataModel,
  DataModelQueries,
}) => {
  if (!type) {
    return null;
  }

  const types = DataModelQueries.selectTypes(DataModel);
  const currentType = type;
  const locationParentIds = DataModelQueries.selectTypeLocationGraphParentIdsOfTypeById(
    DataModel,
    currentType.id,
  );

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Set location parents for {type.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            types.map(type => {
              return (
                <React.Fragment
                  key={type.name}>
                  <input
                    id={type.id}
                    disabled={controlsDisabled}
                    type="checkbox"
                    checked={locationParentIds.includes(type.id)}
                    onChange={() => {
                      if (!locationParentIds.includes(type.id)) {
                        onAddTypeLocationGraphEdge(currentType.id, type.id);
                      } else {
                        onDeleteTypeLocationGraphEdge(currentType.id, type.id);
                      }
                    }}>
                  </input>
                  &nbsp;
                  <label htmlFor={type.id}>{type.name}</label>
                  <br/>
                </React.Fragment>
              );
            })
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={controlsDisabled}
          variant="text"
          onClick={onClose}>
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LocationParentsDialog;
