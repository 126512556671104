import invariant from 'invariant';
import queryString from 'query-string';

export default location => paramName => {
  invariant(
    typeof location === 'object' && !(location instanceof Array),
    '"location" is not an object!',
  );
  invariant(
    typeof location.search === 'string',
    '"location.search" is not a string!',
  );

  const {
    search,
  } = location;

  return queryString.parse(search, {arrayFormat: 'index'})[paramName];
};
