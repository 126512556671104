import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import TextField from 'ui-library/components/TextField';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/SearchRounded';
import DoneIcon from '@material-ui/icons/DoneRounded';

const useStyles = makeStyles({
  popupIndicatorOpen: {
    transform: theme.autocomplete.popupIndicatorOpen.transform,
  },
  focused: {
    '& .MuiAutocomplete-popupIndicator': {
      color: theme.autocomplete.focusedColor,
    },
  },
  option: {
    textTransform: theme.autocomplete.option.textTransform,
    '&:hover': {
      backgroundColor: theme.autocomplete.option.hoverBackground,
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.autocomplete.option.greySemi,
      fontWeight: theme.autocomplete.option.selectedFontWeight,
    },
  },
  iconSelected: {
    width: theme.autocomplete.iconSelected.width,
    height: theme.autocomplete.iconSelected.height,
    margin: theme.autocomplete.iconSelected.margin,
    color: theme.autocomplete.iconSelected.color,
  },
  text: {
    flexGrow: theme.autocomplete.text.flexGrow,
  },
  inputFocused: {
    textTransform: theme.autocomplete.inputFocused.textTransform,
  },
});

const Autocomplete = ({
  label,
  placeholder,
  helperText,
  value,
  onChange,
  options,
  getOptionLabel,
  getOptionSelected,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <MUIAutocomplete
      classes={{
        popupIndicatorOpen: classes.popupIndicatorOpen,
        focused: classes.focused,
        option: classes.option,
        inputFocused: classes.inputFocused,
      }}
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      {...otherProps}
      disableClearable={true}
      popupIcon={<SearchIcon />}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{shrink: true}}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          margin="normal"
          fullWidth
        />
      )}
      renderOption={(option, {selected}) => {
        const displayValue = getOptionLabel(option);
        return (
          <>
            <div className={classes.text}>
              {displayValue}
            </div>
            <DoneIcon
              className={classes.iconSelected}
              style={{visibility: selected ? 'visible' : 'hidden'}}
            />
          </>
        );
      }}
    />
  );
};

Autocomplete.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
};

export default Autocomplete;
