function selectFieldDefinitionById(DM, fieldDefinitionId) {
  const {
    fieldDefinitionsById: {
      [fieldDefinitionId]: fieldDefinition,
    },
  } = DM;

  return fieldDefinition;
}

export default selectFieldDefinitionById;
