import React from 'react';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import TextField from 'ui-library/components/TextField';
import {userFirstName} from 'utils/validator';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const HelperTextContainer = styled.div`
  padding: 8px 0 0 10px;
`;

const RightPanelRowWithValidationGutter = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
  height: 80px;
`;

const FirstNameComponent = ({
  accountType,
  firstName,
  setFirstName,
  step,
  setStep,
  setWizardDirection,
  isTransitioning,
}) => {

  const [isPristine, setIsPristine] = React.useState(true);

  return (
    <>
      <RightPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          {accountType === 'personal' ? 'Start with your' : 'Your'} <strong>first name...</strong>
        </Typography>
      </RightPanelRow>
      <RightPanelRowWithValidationGutter>
        <TextField
          value={firstName}
          onChange={(ev) => {
            setIsPristine(false);
            setFirstName(ev.target.value);
          }}
          fullWidth
          placeholder="Type your answer here..."
          autoFocus={true}
          onKeyPress={(event) => {
            if (event.which === 13 && !userFirstName(firstName) && !isTransitioning) {
              setWizardDirection('forward');
              setStep(step + 1);
            }
          }}
          error={!isPristine && !!userFirstName(firstName)}
          helperText={!isPristine && userFirstName(firstName)}
        />
      </RightPanelRowWithValidationGutter>
      <br/>
      <RightPanelRow>
        <Button
          data-test-id="first-name-continue-btn"
          variant="primary"
          endIcon={<ArrowForwardIcon />}
          disabled={!!userFirstName(firstName) || isTransitioning}
          onClick={() => {
            setWizardDirection('forward');
            setStep(step + 1);
          }}
        >
            CONTINUE
        </Button>
        <HelperTextContainer>
          <Typography
            variant="tiny"
          >
              press <strong>ENTER</strong>
          </Typography>
        </HelperTextContainer>
      </RightPanelRow>
    </>
  );

};

export default FirstNameComponent;
