import './ContentLineText.css';
import React from 'react';

const ContentLineText = ({
  children,
}) => {
  return (
    <div
      className="content-line-text">
      {children}
    </div>
  );
};

export default ContentLineText;
