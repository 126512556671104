import {
  NODE_FRONTEND_PERMISSIONS,
} from './PERMISSION_FLAGS';

function nodeFrontendPermissionsToAtomicPermissions(frontendPermissions) {
  if (typeof frontendPermissions !== 'object' || frontendPermissions === null) {
    throw new Error(`Invalid Node frontend permissions! Expected an object. Received ${typeof frontendPermissions}.`);
  }

  const receivedFronendPermissions = Object.keys(frontendPermissions);
  const missingFrontendPermissions = [];
  for (let requiredFrontendPermission of Object.keys(NODE_FRONTEND_PERMISSIONS)) {
    if (!receivedFronendPermissions.includes(requiredFrontendPermission)) {
      missingFrontendPermissions.push(requiredFrontendPermission);
    }
  }
  if (missingFrontendPermissions.length) {
    throw new Error(`Incomplete Node frontend permissions! Missing permissions: ${missingFrontendPermissions.map(missingAtomicPermission => `"${missingAtomicPermission}"`).join(', ')}.`);
  }

  const atomicPermissions = {};
  for (let frontendPermission of Object.keys(frontendPermissions)) {
    for (let frontendPermissionAtomicDependency of NODE_FRONTEND_PERMISSIONS[frontendPermission]) {
      if (
        frontendPermissions[frontendPermission] ||
        atomicPermissions[frontendPermissionAtomicDependency]
      ) {
        atomicPermissions[frontendPermissionAtomicDependency] = true;
      } else {
        atomicPermissions[frontendPermissionAtomicDependency] = false;
      }
    }
  }

  return atomicPermissions;
}

export default nodeFrontendPermissionsToAtomicPermissions;
