function createVirtualLocationTreeNode(
  typeId,
  virtualLocationTreeNodeHint,
  virtualId,
  virtualLocationId,
) {
  const {
    id: hintId,
    typeName,
  } = virtualLocationTreeNodeHint;

  return {
    virtualId,
    typeId,
    typeName,
    name: typeName,
    hintId,
    virtualLocationId,
  };
}

export default createVirtualLocationTreeNode;
