import GET__backupDownload_FILENAME from 'network-layer/endpoints/GET__backupDownload_FILENAME';

function responseAdapter(response) {
  return response;
}

export default async function downloadBackup(authToken, downloadRequest, params) {
  const response = await GET__backupDownload_FILENAME(authToken, downloadRequest, params);

  return responseAdapter(response);
}
