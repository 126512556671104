import React from 'react';
import AdminContext from 'contexts/Admin';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FieldDefinitionsList from './FieldDefinitionsList.react';
import AddFieldDefinitionDialog from './AddFieldDefinitionDialog.react';
import EditFieldDefinitionDialog from './EditFieldDefinitionDialog.react';
import Prompt from 'components/admin/Prompt';

function deriveFieldDefinitionIndex(fieldDefinitions) {
  let index = fieldDefinitions.length;

  for (let fieldDefinition of fieldDefinitions) {
    const {
      index: currentIndex,
    } = fieldDefinition;
    if (index <= currentIndex) {
      index = currentIndex + 1;
    }
  }

  return index;
}

const FieldDefinitions = ({
  history,
  match,
}) => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);

  const currentTypeRef = React.useRef(DataModelQueries.selectTypeById(DataModel, match.params.typeId));

  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  if (!currentTypeRef.current) {
    return (
      <div>
        <Typography
          variant="h4">
          Error: Type with id {match.params.typeId} does not exist!
        </Typography>
      </div>
    );
  }

  const currentType = currentTypeRef.current;
  const fieldDefinitions = DataModelQueries.selectFieldDefinitionsByTypeId(DataModel, +match.params.typeId);

  return (
    <div>
      <Typography
        variant="h4">
        Customize Field Definitions for <strong>"{currentType.name}"</strong> Type
      </Typography>
      <br/><br/>

      <FieldDefinitionsList
        fieldDefinitions={fieldDefinitions}
        onAddFieldDefinition={() => {
          dispatchLocalAction({
            type: 'BEGIN_ADD_FIELD_DEFINITION',
          });
        }}
        onRemoveFieldDefinition={fieldDefinitionId => {
          dispatchLocalAction({
            type: 'BEGIN_REMOVE_FIELD_DEFINITION',
            payload: fieldDefinitionId,
          });
        }}
        onEditFieldDefinition={fieldDefinitionId => {
          const fieldDefinition = DataModelQueries.selectFieldDefinitionById(
            DataModel,
            fieldDefinitionId,
          );
          const {
            id,
            label,
            description,
            type,
            enumValues,
          } = fieldDefinition;
          dispatchLocalAction({
            type: 'BEGIN_EDIT_FIELD_DEFINITION',
            payload: {
              id,
              label,
              description,
              type,
              enumValues,
            },
          });
        }}
        onReorderFieldDefinitions={async fieldDefinitionsIndexPatches => {
          // dispatchLocalAction({
          //   type: 'BEGIN_REORDER_FIELD_DEFINITIONS',
          // });
          dispatchDataModelAction({
            type: 'REORDER_FIELD_DEFINITIONS',
            payload: fieldDefinitionsIndexPatches,
          });
          try {
            await Promise.all(
              fieldDefinitionsIndexPatches.map(patch => {
                const {
                  id,
                  newIndex,
                } = patch;
                return DataModelAPI.updateFieldDefinition(
                  authToken,
                  perspectiveId,
                  {
                    fieldDefinitionId: id,
                    index: newIndex,
                  },
                );
              }),
            );
          } catch(error) {
            setAPIException(error);
          }
          // dispatchLocalAction({
          //   type: 'END_REORDER_FIELD_DEFINITIONS',
          // });
        }}
        controlsDisabled={
          localState.status === 'requesting reorder field definitions'
        }
      />

      <AddFieldDefinitionDialog
        open={
          localState.status === 'adding field definition' ||
          localState.status === 'requesting add field definition'
        }
        controlsDisabled={
          localState.status === 'requesting add field definition'
        }
        label={localState.label}
        description={localState.description}
        type={localState.type}
        enumValues={localState.enumValues}
        onSetLabel={value => {
          dispatchLocalAction({
            type: 'SET_LABEL',
            payload: value,
          });
        }}
        onSetDescription={value => {
          dispatchLocalAction({
            type: 'SET_DESCRIPTION',
            payload: value,
          });
        }}
        onSetType={value => {
          dispatchLocalAction({
            type: 'SET_TYPE',
            payload: value,
          });
        }}
        onPushEnumValue={() => {
          dispatchLocalAction({
            type: 'PUSH_BLANK_ENUM_VALUE',
          });
        }}
        onSetEnumValue={(index, value) => {
          dispatchLocalAction({
            type: 'SET_ENUM_VALUE',
            payload: {
              value,
              index,
            },
          });
        }}
        onRemoveEnumValue={index => {
          dispatchLocalAction({
            type: 'SPLICE_ENUM_VALUE',
            payload: index,
          });
        }}
        onClose={() => {
          dispatchLocalAction({
            type: 'END_ADD_FIELD_DEFINITION',
          });
        }}
        onSaveFieldDefinition={async() => {
          dispatchLocalAction({
            type: 'REQUEST_ADD_FIELD_DEFINITION',
          });
          try {
            const {
              label,
              description,
              type,
              enumValues,
            } = localState;
            const newFieldDefinition = await DataModelAPI.addFieldDefinitionToType(
              authToken,
              perspectiveId,
              {
                typeId: match.params.typeId,
                label,
                description,
                type,
                index: deriveFieldDefinitionIndex(fieldDefinitions),
                enumValues: type === 'enum' ? enumValues.filter(value => !!value) : null,
              },
            );
            dispatchDataModelAction({
              type: 'ADD_FIELD_DEFINITION',
              payload: newFieldDefinition,
            });
          } catch(error) {
            setAPIException(error);
          }
          dispatchLocalAction({
            type: 'END_ADD_FIELD_DEFINITION',
          });
        }}
      />
      <br/><br/>

      <EditFieldDefinitionDialog
        open={
          localState.status === 'editing field definition' ||
          localState.status === 'requesting edit field definition'
        }
        controlsDisabled={
          localState.status === 'requesting edit field definition'
        }
        label={localState.label}
        description={localState.description}
        type={localState.type}
        enumValues={localState.enumValues}
        onSetLabel={value => {
          dispatchLocalAction({
            type: 'SET_LABEL',
            payload: value,
          });
        }}
        onSetDescription={value => {
          dispatchLocalAction({
            type: 'SET_DESCRIPTION',
            payload: value,
          });
        }}
        onPushEnumValue={() => {
          dispatchLocalAction({
            type: 'PUSH_BLANK_ENUM_VALUE',
          });
        }}
        onSetEnumValue={(index, value) => {
          dispatchLocalAction({
            type: 'SET_ENUM_VALUE',
            payload: {
              value,
              index,
            },
          });
        }}
        onRemoveEnumValue={index => {
          dispatchLocalAction({
            type: 'SPLICE_ENUM_VALUE',
            payload: index,
          });
        }}
        onClose={() => {
          dispatchLocalAction({
            type: 'END_ADD_FIELD_DEFINITION',
          });
        }}
        onSaveFieldDefinition={async() => {
          dispatchLocalAction({
            type: 'REQUEST_EDIT_FIELD_DEFINITION',
          });

          try {
            const {
              id,
              label: patchLabel,
              description: patchDescription,
              enumValues: patchEnumValues,
            } = localState;

            const {
              label,
              description,
            } = DataModelQueries.selectFieldDefinitionById(
              DataModel,
              id,
            );

            const patch = {
              enumValues: patchEnumValues || null,
            };

            if (patchLabel !== label) {
              patch.label = patchLabel;
            }
            if (patchDescription !== description) {
              patch.description = patchDescription;
            }

            await DataModelAPI.updateFieldDefinition(
              authToken,
              perspectiveId,
              {
                fieldDefinitionId: id,
                ...patch,
              },
            );
            dispatchDataModelAction({
              type: 'UPDATE_FIELD_DEFINITION',
              payload: {
                fieldDefinitionId: id,
                patch,
              },
            });
          } catch(error) {
            setAPIException(error);
          }
          dispatchLocalAction({
            type: 'END_EDIT_FIELD_DEFINITION',
          });
        }}
      />
      <br/><br/>

      {
        localState.status === 'removing field definition' &&
        <Prompt
          open
          title="Confirm action"
          text={
            <span>
            Are you sure you want to remove the <strong>{DataModelQueries.selectFieldDefinitionById(DataModel, localState.id).label}</strong> field definition? This is a destructive action and cannot be undone!
            </span>
          }
          onCancel={() => {
            dispatchLocalAction({
              type: 'END_REMOVE_FIELD_DEFINITION',
            });
          }}
          confirmLabel="yes, remove this field definition"
          onConfirm={async() => {
            dispatchLocalAction({
              type: 'REQUEST_REMOVE_FIELD_DEFINITION',
            });
            try {
              const {
                id: fieldDefinitionId,
              } = localState;
              await DataModelAPI.removeFieldDefinition(
                authToken,
                perspectiveId,
                {
                  fieldDefinitionId,
                },
              );
              dispatchDataModelAction({
                type: 'REMOVE_FIELD_DEFINITION',
                payload: fieldDefinitionId,
              });
            } catch(error) {
              setAPIException(error);
            }
            dispatchLocalAction({
              type: 'END_REMOVE_FIELD_DEFINITION',
            });
          }}
        />
      }

      <Button
        variant="text"
        onClick={() => {
          history.push(`/types/${match.params.typeId}`);
        }}>
        go to type details
      </Button>
      &nbsp;
      <Button
        variant="text"
        onClick={() => {
          history.push('/types/list');
        }}>
        go to the list of types
      </Button>
    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'SET_LABEL': {
      return {
        ...state,
        label: payload,
      };
    }

    case 'SET_DESCRIPTION': {
      return {
        ...state,
        description: payload,
      };
    }

    case 'SET_TYPE': {
      return {
        ...state,
        type: payload,
        enumValues: [],
      };
    }

    case 'BEGIN_ADD_FIELD_DEFINITION': {
      return {
        ...state,
        status: 'adding field definition',
      };
    }

    case 'REQUEST_ADD_FIELD_DEFINITION': {
      return {
        ...state,
        status: 'requesting add field definition',
      };
    }

    case 'FIELD_DEFINITION_ADDED': {
      return {
        ...state,
        status: 'field definition added',
      };
    }

    case 'END_ADD_FIELD_DEFINITION': {
      return {
        ...initState(),
      };
    }

    case 'BEGIN_REMOVE_FIELD_DEFINITION': {
      const id = payload;

      return {
        ...state,
        status: 'removing field definition',
        id,
      };
    }

    case 'REQUEST_REMOVE_FIELD_DEFINITION': {
      return {
        ...state,
        status: 'requesting remove field definition',
      };
    }

    case 'END_REMOVE_FIELD_DEFINITION': {
      return {
        ...initState(),
      };
    }

    case 'PUSH_BLANK_ENUM_VALUE': {
      return {
        ...state,
        enumValues: [
          ...state.enumValues,
          '',
        ],
      };
    }

    case 'SET_ENUM_VALUE': {
      const {
        value,
        index,
      } = payload;

      return {
        ...state,
        enumValues: [
          ...state.enumValues.slice(0, index),
          value,
          ...state.enumValues.slice(index + 1),
        ],
      };
    }

    case 'SPLICE_ENUM_VALUE': {
      const spliceIndex = payload;

      return {
        ...state,
        enumValues: [
          ...state.enumValues.slice(0, spliceIndex),
          ...state.enumValues.slice(spliceIndex + 1),
        ],
      };
    }

    case 'BEGIN_EDIT_FIELD_DEFINITION': {
      const {
        id,
        label,
        description,
        type,
        enumValues,
      } = payload;

      return {
        ...state,
        status: 'editing field definition',
        id,
        label,
        description,
        type,
        enumValues,
      };
    }

    case 'REQUEST_EDIT_FIELD_DEFINITION': {
      return {
        ...state,
        status: 'requesting edit field definition',
      };
    }

    case 'END_EDIT_FIELD_DEFINITION': {
      return {
        ...initState(),
      };
    }

    case 'BEGIN_REORDER_FIELD_DEFINITIONS': {
      return {
        ...state,
        status: 'requesting reorder field definitions',
      };
    }

    case 'END_REORDER_FIELD_DEFINITIONS': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      adding field definition
      requesting add field definition
      field definition added
      removing field definition
      requesting remove field definition
      editing field definition
      requesting edit field definition
      requesting reorder field definitions
    */
    status: 'idle',
    id: null,
    label: '',
    description: '',
    type: '',
    enumValues: [],
  };
}

export default FieldDefinitions;
