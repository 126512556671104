import isEmail from 'validator/lib/isEmail';

export default function email(
  value,
  errorMessage = 'Please enter a valid email address.',
) {
  if (isEmail(value)) {
    return '';
  }
  return errorMessage;
}
