import React from 'react';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';
import Message from 'ui-library/components/Message';

const RightPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;

const ActivationComponent = ({
  sendActivationEmail,
  isTransitioning,
  isLoading,
  resendConfirmationEmail,
  setResendConfirmationEmail,
}) => {

  return (
    <>
      <RightPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
            A verification link has been sent to the email address you provided. Please verify your email to activate your account.
        </Typography>
      </RightPanelRow>
      <br/>
      <RightPanelRow>
        <Button
          data-test-id="resend-activation-email-btn"
          variant="primary"
          onClick={sendActivationEmail}
          disabled={isTransitioning || isLoading}
          endIcon={
            !isLoading ?
              null
              :
              <CircularProgress
                size={20}
                style={{
                  color: '#fff',
                }}
              />
          }
        >
          SEND EMAIL AGAIN
        </Button>
      </RightPanelRow>

      <Message
        variant="success"
        open={resendConfirmationEmail}
        onClose={() => {
          setResendConfirmationEmail(false);
        }}

        messageBody="Email has been sent!"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        width={532}
      />
    </>
  );
};

export default ActivationComponent;
