import {
  NODE_ATOMIC_PERMISSIONS,
} from './PERMISSION_FLAGS';

function nodeAtomicPermissionsToPermissionsInt(atomicPermissions) {
  if (typeof atomicPermissions !== 'object' || atomicPermissions === null) {
    throw new Error(`Invalid Node atomic permissions! Expected an object. Received ${typeof atomicPermissions}.`);
  }

  const receivedAtomicPermissions = Object.keys(atomicPermissions);
  const missingAtomicPermissions = [];
  for (let requiredAtomicPermission of NODE_ATOMIC_PERMISSIONS) {
    if (!receivedAtomicPermissions.includes(requiredAtomicPermission)) {
      missingAtomicPermissions.push(requiredAtomicPermission);
    }
  }
  if (missingAtomicPermissions.length) {
    throw new Error(`Incomplete Node atomic permissions! Missing permissions: ${missingAtomicPermissions.map(missingAtomicPermission => `"${missingAtomicPermission}"`).join(', ')}.`);
  }

  let permissionBitString = '';

  for (let atomicPermission of NODE_ATOMIC_PERMISSIONS) {
    permissionBitString += atomicPermissions[atomicPermission] ? 1 : 0;
  }

  return parseInt(permissionBitString, 2);
}

export default nodeAtomicPermissionsToPermissionsInt;
