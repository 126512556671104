import {
  RECORD_FRONTEND_PERMISSION_LABELS,
} from './PERMISSION_FLAGS';

function recordFrontendPermissionLabel(permission) {
  if (!permission) {
    throw new Error('Cannot provide Record frontend permission label! Permission is undefined!');
  }
  return RECORD_FRONTEND_PERMISSION_LABELS[permission];
}

export default recordFrontendPermissionLabel;
