import React from 'react';
import PropTypes from 'prop-types';

import StripeForm from 'components/StripeForm';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';

import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

const AddCardDialog = ({
  isVisible,
  isProcessing,
  errorMessage,
  setErrorMessage,
  handleClose,
  handleSubmit,
}) => {

  return (
    <ModalCard
      open={isVisible}
      handleClose={handleClose}
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <ModalCardHeader
        color="primary"
      >
        <ModalCardHeaderIcon
          color="primary"
        >
          <CreditCardRoundedIcon
            fontSize="inherit"
          />
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle
        >
          Add Card Details
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        <Typography
          variant="body"
        >
          Add a credit or debit card to be billed for your membership.
        </Typography>
        <div style={{
          height: 80,
          paddingTop: 8,
        }}>
          <StripeForm
            setErrorMessage={setErrorMessage}
          />
          {
            errorMessage ?
              <Typography
                variant="x-small"
                color="error"
              >
                {errorMessage}
              </Typography>
              :
              null
          }
        </div>
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="modal-add-card-cancel-button"
          variant="text"
          onClick={handleClose}
          disabled={isProcessing}
        >
          Cancel
        </Button>
        <Button
          data-test-id="modal-add-card-submit-button"
          onClick={handleSubmit}
          disabled={isProcessing}
          startIcon={isProcessing ? <CircularProgress size={24} color="white" /> : <AddRoundedIcon />}
        >
          Add Card
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

AddCardDialog.propTypes = {
  isVisible: PropTypes.bool,
  isProcessing: PropTypes.bool,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default AddCardDialog;
