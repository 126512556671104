import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import ContextualPanel from 'components/chrome/ContextualPanel';
import ContextualPanelNavigation from 'components/chrome/ContextualPanelNavigation';
import ContextualPanelBackButton from 'components/chrome/ContextualPanelBackButton';
import ContextualPanelCloseButton from 'components/chrome/ContextualPanelCloseButton';
import ContextualPanelHeader from 'components/chrome/ContextualPanelHeader';
import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import ContextualPanelActions from 'components/chrome/ContextualPanelActions';
import ContextualPanelTabs from 'components/chrome/ContextualPanelTabs';
import ContextualPanelTab from 'components/chrome/ContextualPanelTab';

import NodeDetails from 'components/NodeDetails';
import NodeAttachments from 'components/NodeAttachments';
import NodeLinks from 'components/NodeLinks';
import NodeTags from 'components/NodeTags';
import NodeLocationEdit from 'components/NodeLocationEdit';
import NodePermissionsTab from 'components/NodePermissionsTab';
import PermissionSwitches from 'components/Permissions/PermissionSwitches';
import NodeConversationsTab from 'components/NodeConversationsTab';
import ConversationContainer from 'components/ConversationContainer';
import StartConversation from 'components/StartConversation';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';

import Grid from '@material-ui/core/Grid';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import EditableTitle from 'ui-library/components/EditableTitle';
import NodeLinkCandidatesList from 'ui-library/components/NodeLinkCandidatesList';
import NodeLinkInfoCard from 'ui-library/components/NodeLinkInfoCard';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import Field from 'ui-library/components/Field';
import Message from 'ui-library/components/Message';

import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';
import genTypeByTypeId from 'services/Types/genTypeByTypeId';
import updateNodeName from 'services/Nodes/updateNodeName';
import replaceNodeFieldValue from 'services/Nodes/replaceNodeFieldValue';
import deleteNodeFieldValue from 'services/Nodes/deleteNodeFieldValue';
import replaceNodeFields from 'services/Nodes/replaceNodeFields';
import updateNodePermissions from 'services/Nodes/updateNodePermissions';
import genAvailableConnectionsByNodeId from 'services/Conversations/genAvailableConnectionsByNodeId';
import createNodeConversation from 'services/Conversations/createNodeConversation';
import updateConversationStatus from 'services/Conversations/updateConversationStatus';
import genConversationByConversationId from 'services/Conversations/genConversationByConversationId';

import UserInterfaceContext from 'contexts/UserInterface';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import parseNodePermissions from 'utils/permissions/parseNodePermissions';
import getDateWithTimezoneOffset from 'utils/getDateWithTimezoneOffset';
import parseFieldValue from 'utils/parseFieldValue';
import nodeAtomicPermissionsToPermissionsInt from 'utils/permissions/nodeAtomicPermissionsToPermissionsInt';
import nodeFrontendPermissionsToAtomicPermissions from 'utils/permissions/nodeFrontendPermissionsToAtomicPermissions';
import nodeFrontendPermissionLabel from 'utils/permissions/nodeFrontendPermissionLabel';
import nodeAtomicPermissionsToFrontendPermissions from 'utils/permissions/nodeAtomicPermissionsToFrontendPermissions';
import nodeFrontendPermissionsToImpliedFrontendPermissions from 'utils/permissions/nodeFrontendPermissionsToImpliedFrontendPermissions';
import getDateToAPIFormat from 'utils/getDateToAPIFormat';
import {
  NODE_FRONTEND_DISABLED_PERMISSIONS,
} from 'utils/permissions/PERMISSION_FLAGS';
import {
  fieldIsText,
  fieldIsLongformText,
  fieldIsNumber,
  fieldIsCurrency,
  fieldIsDate,
  fieldIsUrl,
  fieldIsPhoneNumber,
  fieldIsEmail,
  fieldIsEnum,
  fieldIsBoolean,
  fieldIsYear,
  fieldIsName,
  fieldIsZipCode,
  name as nameValidator,
} from 'utils/validator';

import {
  initNodeState,
  nodeReducer,
} from 'reducers/NewNodePanel';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const SecondButtonContainer = styled.div`
  margin-left: 8px;
`;

const PermissionsContainer = styled.div`
  margin-top: 24px;
  padding-left: 20px;
`;

const FIELD_VALIDATION = {
  'text': fieldIsText,
  'longform text': fieldIsLongformText,
  'number': fieldIsNumber,
  'currency': fieldIsCurrency,
  'date': fieldIsDate,
  'url': fieldIsUrl,
  'phone number': fieldIsPhoneNumber,
  'e-mail address': fieldIsEmail,
  'enum': fieldIsEnum,
  'boolean': fieldIsBoolean,
  'year': fieldIsYear,
  'name': fieldIsName,
  'zip code': fieldIsZipCode,
};

const NewNodePanel = () => {
  const {
    expandedNodeId,
    expandedNodeLoadingState,
    showNodePanel,
    nodePanelDisplayMode,
    selectedNodePanelTab,
    backNavigationEnabled,
    backNavigationLanding,
    dispatchUserInterfaceAction,
    selectedConversation,
    newConversation,
  } = React.useContext(UserInterfaceContext);

  const {
    connectionId,
    connectionName: conversationConnectionName,
    interlocutorOrganizationName: conversationInterlocutorOrganizationName,
    nodeName: conversationTetherName,
  } = selectedConversation || newConversation || {};

  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const [nodeState, dispatchNodeAction] = React.useReducer(
    nodeReducer,
    null,
    initNodeState,
  );

  const {
    node: {
      id: nodeId,
      name: nodeName,
      typeName,
    },
    previousNodeName,
    isNodeSaving,
    isNodeNameSaving,
    isNodeNameEditable,
    fieldsByFieldDefinitionId,
    fieldDefinitionIds,
    fieldDefinitionsById,
    attachmentsById,
    attachmentIds,
    uploadsById,
    uploadIds,

    isLinkInfoOpen,
    isLinkInfoLoading,
    linkInfo,

    nodePermissions: {
      frontendPermissions,
      frontendPermissions:{
        edit: nodeEditPermissions,
        delete: nodeDeletePermissions,
        allow3rdPartyAccess: sharePermissions,
      },
    },

    nodeGrantFrontendPermissions,
    nodeGrantAtomicPermissions,
    nodeGrantConnection,
  } = nodeState;

  const [hoveredTab, setHoveredTab] = React.useState(null);

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  const [isEditMenuOpen, setIsEditMenuOpen] = React.useState(false);
  const [editMenuAnchorEl, setEditMenuAnchorEl] = React.useState(null);
  const [isPanelMenuOpen, setIsPanelMenuOpen] = React.useState(false);
  const [panelMenuAnchorEl, setPanelMenuAnchorEl] = React.useState(null);

  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        setAuthToken(null);
        setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw(fatalError);
  }

  const loadNode = React.useCallback(async(postEditReload) => {
    try {
      if (!postEditReload) {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_NODE_LOADING_STATE',
          payload: 'loading',
        });
      }
      const response = await genNodeByNodeId({
        authToken,
        perspectiveId: currentPerspectiveId,
        nodeId: expandedNodeId,
        queryParams: ['locationPath', 'record'],
      });
      const {
        name,
        permissions,
      } = response;

      dispatchNodeAction({
        type: 'SET_NODE',
        payload: {
          ...response,
        },
      });
      dispatchNodeAction({
        type: 'SET_PREVIOUS_NODE_NAME',
        payload: name,
      });
      dispatchNodeAction({
        type: 'SET_NODE_PERMISSIONS',
        payload: parseNodePermissions(permissions),
      });
      if (!postEditReload) {
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_NODE_LOADING_STATE',
          payload: 'loaded',
        });
      }
    } catch (error) {
      setError(error);
    }
  }, [authToken, currentPerspectiveId, dispatchUserInterfaceAction, expandedNodeId]);

  React.useEffect(() => {
    if (expandedNodeId !== null && expandedNodeLoadingState === 'not loaded') {
      loadNode();
    }
  }, [expandedNodeId, expandedNodeLoadingState, loadNode]);

  const nodeNameValidationErrorMessage = nameValidator(nodeName);
  const fieldsHaveErrors = fieldDefinitionIds.map(fieldDefinitionId => {
    const {
      errorMessage,
    } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

    return errorMessage === '';
  }).includes(false);

  const setSelectedTab = (value) => {
    dispatchUserInterfaceAction({
      type: 'SET_NODE_PANEL_SELECTED_TAB',
      payload: value,
    });
  };

  const saveNodeName = async() => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: true,
    });
    try {
      await updateNodeName(authToken, currentPerspectiveId, expandedNodeId, nodeName);
      dispatchNodeAction({
        type: 'SET_PREVIOUS_NODE_NAME',
        payload: nodeName,
      });
      dispatchNodeAction({
        type: 'SET_IS_NODE_NAME_EDITABLE',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: expandedNodeId,
      });
    } catch(error) {
      setError(error);
    };
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: false,
    });
  };

  const revertNodeName = () => {
    dispatchNodeAction({
      type: 'SET_NODE_NAME',
      payload: previousNodeName,
    });
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_EDITABLE',
      payload: false,
    });
  };

  const saveNodeField = async({
    value,
    previousValue,
    id,
    fieldDefinitionId,
    type,
    errorMessage,
  }) => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: true,
    });
    dispatchNodeAction({
      type: 'SET_FIELD_IS_EDITABLE',
      payload: {
        fieldDefinitionId,
        isEditable: false,
      },
    });

    try {
      if (![null, previousValue, '', 'N/A'].includes(value) && !Boolean(errorMessage)) {
        dispatchNodeAction({
          type: 'SET_FIELD_IS_SAVING',
          payload: {
            fieldDefinitionId,
            isSaving: true,
          },
        });
        await replaceNodeFieldValue({authToken, currentPerspectiveId, id: expandedNodeId, fieldDefinitionId, value: parseFieldValue({type, value, setError})});
        dispatchNodeAction({
          type: 'SET_FIELD_PREVIOUS_VALUE',
          payload: {
            fieldDefinitionId,
            value: value,
          },
        });
        dispatchNodeAction({
          type: 'SET_FIELD_IS_SAVING',
          payload: {
            fieldDefinitionId,
            isSaving: false,
          },
        });
        dispatchNodeAction({
          type: 'SET_FIELD_IS_FOCUSED',
          payload: {
            fieldDefinitionId,
            isFocused: false,
          },
        });
      } else {
        dispatchNodeAction({
          type: 'SET_FIELD_IS_FOCUSED',
          payload: {
            fieldDefinitionId,
            isFocused: false,
          },
        });
      }
    } catch(error) {
      setError(error);
    }
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: false,
    });
  };

  const deleteEmptyNodeFields = async() => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: true,
    });
    const emptyFieldsById = fieldDefinitionIds.filter(fielDefinitionId => {
      const {
        value,
        previousValue,
      } = fieldsByFieldDefinitionId[fielDefinitionId];

      return [null, '', 'N/A'].includes(value) && ![null, '', 'N/A'].includes(previousValue);
    });

    try {
      await Promise.all(emptyFieldsById.map(async(fieldDefinitionId) => {
        await deleteNodeFieldValue({authToken, currentPerspectiveId, id: expandedNodeId, fieldDefinitionId});

        dispatchNodeAction({
          type: 'SET_FIELD_VALUE',
          payload: {
            fieldDefinitionId,
            value: '',
          },
        });

        dispatchNodeAction({
          type: 'SET_FIELD_PREVIOUS_VALUE',
          payload: {
            fieldDefinitionId,
            value: '',
          },
        });

        dispatchNodeAction({
          type: 'SET_FIELD_INLINE_EDIT_MODE',
          payload: {
            fieldDefinitionId,
            inlineEditMode: false,
          },
        });
      }));
      dispatchNodeAction({
        type: 'SET_IS_NODE_SAVING',
        payload: false,
      });
    } catch(error) {
      setError(error);
    }

    loadNode(true);

    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: false,
    });

    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: false,
    });

    dispatchUserInterfaceAction({
      type: 'SET_NODE_PANEL_DISPLAY_MODE',
      payload: 'default',
    });
    setSelectedTab(0);
  };

  const revertAllFields = () => {
    dispatchNodeAction({
      type: 'REVERT_FIELDS',
      payload: {
        revertIds: fieldDefinitionIds,
      },
    });
    dispatchNodeAction({
      type: 'SET_NODE_NAME',
      payload: previousNodeName,
    });
  };

  const revertErroneousNodeFields = () => {
    const erroneusFieldIds = fieldDefinitionIds.filter(fielDefinitionId => {
      const {
        errorMessage,
      } = fieldsByFieldDefinitionId[fielDefinitionId];

      return Boolean(errorMessage);
    });

    dispatchNodeAction({
      type: 'REVERT_FIELDS',
      payload: {
        revertIds: erroneusFieldIds,
      },
    });

    if (Boolean(nodeNameValidationErrorMessage)) {
      dispatchNodeAction({
        type: 'SET_NODE_NAME',
        payload: previousNodeName,
      });
    }
  };

  const saveAllFields = async() => {
    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: true,
    });
    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: true,
    });
    const fields = {};
    Object.keys(fieldsByFieldDefinitionId).forEach(fieldDefinitionId => {
      const {
        type,
        value,
      } = fieldsByFieldDefinitionId[fieldDefinitionId];

      if (![null, '', 'N/A'].includes(value)) {
        fields[fieldDefinitionId] = parseFieldValue({type, value, setError});
      }
    });

    try {
      await replaceNodeFields({
        authToken,
        currentPerspectiveId,
        id: nodeId,
        fields,
      });
      await updateNodeName(authToken, currentPerspectiveId, nodeId, nodeName);

      setShowSuccessMessage(true);
    } catch(error) {
      setError(error);
    }

    loadNode(false);

    dispatchNodeAction({
      type: 'SET_IS_NODE_SAVING',
      payload: false,
    });

    dispatchNodeAction({
      type: 'SET_IS_NODE_NAME_SAVING',
      payload: false,
    });

    dispatchUserInterfaceAction({
      type: 'SET_NODE_PANEL_DISPLAY_MODE',
      payload: 'default',
    });
    setSelectedTab(0);
  };

  const uploadsInProgress = () => {
    const activeUploads = uploadIds.filter((id) => uploadsById[id].request);
    return Boolean(activeUploads.length);
  };

  const downloadsInProgress = () => {
    const activeDownloads = attachmentIds.filter((id) => attachmentsById[id].downloadRequest);
    return Boolean(activeDownloads.length);
  };

  const cancelAllUploads = () => {
    uploadIds.forEach((id) => {
      const {
        request,
      } = uploadsById[id];
      if (request) {
        request.abort();
        dispatchNodeAction({
          type: 'REMOVE_UPLOAD',
          payload: {
            id,
          },
        });
      }
    });
  };

  const cancelAllDownloads = () => {
    attachmentIds.forEach((id) => {
      const {
        downloadRequest,
      } = attachmentsById[id];
      if (downloadRequest) {
        downloadRequest.abort();
        dispatchNodeAction({
          type: 'SET_ATTACHMENT_DOWNLOAD_REQUEST',
          payload: {
            id,
            downloadRequest: null,
          },
        });
      }
    });
  };

  const handleCloseLinkInfo = () => {
    dispatchNodeAction({
      type: 'SET_IS_LINK_INFO_OPEN',
      payload: false,
    });
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_NODE_PANEL',
      payload: true,
    });
  };

  const handleLinkInfo = async(linkedNodeId) => {

    try {
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_NODE_PANEL',
        payload: false,
      });
      dispatchNodeAction({
        type: 'SET_IS_LINK_INFO_LOADING',
        payload: true,
      });
      dispatchNodeAction({
        type: 'SET_IS_LINK_INFO_OPEN',
        payload: true,
      });
      const linkInfoNode = await genNodeByNodeId({authToken, perspectiveId: currentPerspectiveId, nodeId: linkedNodeId});
      const {
        name,
        typeName,
        fields,
        typeId: linkInfoNodeTypeId,
      } = linkInfoNode;

      const linkInfoNodeType = await genTypeByTypeId({authToken, perspectiveId: currentPerspectiveId, typeId: linkInfoNodeTypeId});
      const {
        fieldDefinitions,
      } = linkInfoNodeType;

      const linkInfo = {
        name,
        fields: [{
          label: 'Type',
          value: typeName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
          type: 'text',
        }],
      };

      if (fields) {
        Object.keys(fields).forEach(key => {
          linkInfo.fields.push({
            label: fieldDefinitions[key].label,
            value: fields[key],
            type: fieldDefinitions[key].type,
          });
        });
      }

      dispatchNodeAction({
        type: 'SET_LINK_INFO',
        payload: linkInfo,
      });
      dispatchNodeAction({
        type: 'SET_IS_LINK_INFO_LOADING',
        value: false,
      });

    } catch(error) {
      setError(error);
    }
  };

  const handleClosePanelMenu = () => {
    setIsPanelMenuOpen(false);
    setPanelMenuAnchorEl(null);
  };

  const handleCloseEditMenu = () => {
    setIsEditMenuOpen(false);
    setEditMenuAnchorEl(null);
  };

  const saveNodePermissions = async() => {
    try {
      dispatchNodeAction({
        type: 'SET_IS_NODE_SAVING',
        payload: true,
      });
      const atomicPermissions = nodeFrontendPermissionsToAtomicPermissions(nodeGrantFrontendPermissions);
      const permissionsInt = nodeAtomicPermissionsToPermissionsInt(atomicPermissions);
      await updateNodePermissions(authToken, currentPerspectiveId, nodeId, {
        connectionId: nodeGrantConnection,
        permissions: permissionsInt,
      });
      dispatchNodeAction({
        type: 'SET_IS_NODE_SAVING',
        payload: false,
      });
      dispatchUserInterfaceAction({
        type: 'SET_NODE_PANEL_DISPLAY_MODE',
        payload: 'default',
      });
    } catch(error) {
      setError(error);
    }
  };

  const closePanel = () => {
    if (expandedNodeId !== null) {
      // CONFIRMATION DIALOG IF UPLOADS IN PROGRESS
      let cancelUploads = false;
      if (uploadsInProgress()) {
        if (window.confirm('There are uploads in progress. Are you sure you want to continue?')) {
          cancelUploads = true;
        }
      } else {
        cancelUploads = true;
      }

      // CONFIRMATION DIALOG IF DOWNLOADS IN PROGRESS
      let cancelDownloads = false;
      if (downloadsInProgress()) {
        if (window.confirm('There are downloads in progress. Are you sure you want to continue?')) {
          cancelDownloads = true;
        }
      } else {
        cancelDownloads = true;
      }

      if (cancelUploads && cancelDownloads) {
        dispatchUserInterfaceAction({
          type: 'SET_SHOW_NODE_PANEL',
          payload: false,
        });

        // CANCEL ALL IN PROGRESS UPLOADS
        cancelAllUploads();

        // CANCEL ALL IN PROGRESS DOWNLOADS
        cancelAllDownloads();

        // RESET SELECTED & HOVERED TAB
        setHoveredTab(null);
        setSelectedTab(0);

        // RESET PANEL DISPLAY MODE
        dispatchUserInterfaceAction({
          type: 'SET_NODE_PANEL_DISPLAY_MODE',
          payload: 'default',
        });

        // DELETE INLINE EDITED FIELDS LEFT EMPTY (N/A)
        deleteEmptyNodeFields();

        // RESET LOCAL MENU STATE
        setIsEditMenuOpen(false);
        setEditMenuAnchorEl(null);
        setIsPanelMenuOpen(false);
        setPanelMenuAnchorEl(null);

        // RESET EDITABLE TITLE
        dispatchNodeAction({
          type: 'SET_IS_NODE_NAME_EDITABLE',
          payload: false,
        });

        // CLOSE SUCCESS MESSAGE
        setShowSuccessMessage(false);

        // RESET USER INTERFACE STATE
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_NODE_ID',
          payload: null,
        });
        dispatchUserInterfaceAction({
          type: 'SET_RELOAD_ITEM_PERMISSION_GRANTS',
          payload: false,
        });
        dispatchUserInterfaceAction({
          type: 'SET_EXTERNAL_RECORD_CONNECTION_ID',
          payload: null,
        });
        dispatchUserInterfaceAction({
          type: 'SET_EXPANDED_NODE_LOADING_STATE',
          payload: 'not loaded',
        });
        dispatchUserInterfaceAction({
          type: 'SET_NODE_PANEL_SELECTED_TAB',
          payload: 0,
        });
        dispatchUserInterfaceAction({
          type: 'SET_SELECTED_CONVERSATION',
          payload: null,
        });
        dispatchUserInterfaceAction({
          type: 'SET_NEW_CONVERSATION',
          payload: null,
        });

        if (backNavigationEnabled) {
          dispatchUserInterfaceAction({
            type: 'SET_BACK_NAVIGATION_ENABLED',
            payload: {
              backNavigationEnabled: false,
              backNavigationLanding: null,
            },
          });
        }
      }
    }
  };

  return (
    <ContextualPanel
      expanded={!!expandedNodeId && showNodePanel}
      handleEscKey={() => {
        if (nodePanelDisplayMode !== 'editItem') {
          closePanel();
        }
      }}
      onMouseDown={(ev) => {
        if (nodeEditPermissions) {
          if (isNodeNameEditable && !Boolean(nodeNameValidationErrorMessage)) {
            if (nodeName !== previousNodeName) {
              saveNodeName();
            } else {
              dispatchNodeAction({
                type: 'SET_IS_NODE_NAME_EDITABLE',
                payload: false,
              });
            }
          }

          Object.keys(fieldsByFieldDefinitionId).forEach(fieldDefinitionId => {
            const {
              value,
              previousValue,
              isFocused,
              type,
              errorMessage,
            } = fieldsByFieldDefinitionId[fieldDefinitionId];

            if (isFocused === true && !Boolean(errorMessage)) {
              saveNodeField({value, previousValue, id: expandedNodeId, type, fieldDefinitionId});
            }
          });
        }
      }}
    >
      <ContextualPanelNavigation
        expanded={!!expandedNodeId && showNodePanel}
      >
        <ContextualPanelBackButton
          data-test-id="node-contextual-panel-back-button"
          isVisible={backNavigationEnabled}
          onClick={() => {
            switch (backNavigationLanding) {
              case 'notifications': {
                closePanel();
                dispatchUserInterfaceAction({
                  type: 'SET_SHOW_NOTIFICATIONS_PANEL',
                  payload: true,
                });
                break;
              }
              case 'conversations': {
                dispatchUserInterfaceAction({
                  type: 'SET_NODE_PANEL_DISPLAY_MODE',
                  payload: 'default',
                });
                dispatchUserInterfaceAction({
                  type: 'SET_SELECTED_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_NEW_CONVERSATION',
                  payload: null,
                });
                dispatchUserInterfaceAction({
                  type: 'SET_BACK_NAVIGATION_ENABLED',
                  payload: {
                    backNavigationEnabled: false,
                    backNavigationLanding: null,
                  },
                });
                setSelectedTab(5);
                break;
              }
              default:
                closePanel();
            }
          }}
        />
        <ContextualPanelCloseButton
          data-test-id="contextual-panel-close-button"
          isVisible={true}
          onClick={() => {
            closePanel();
          }}
        />
      </ContextualPanelNavigation>

      {
        expandedNodeLoadingState !== 'loaded' ?
          <div>loading ...</div>
          :
          <>
            {
              nodePanelDisplayMode !== 'conversation' && nodePanelDisplayMode !== 'start_conversation' &&
              <ContextualPanelHeader
                panelDisplayMode={nodePanelDisplayMode}
              >
                <EditableTitle
                  data-test-id="node-panel-nickname-input"
                  disabled={false}
                  isSaving={isNodeNameSaving}
                  autoFocus={true}
                  value={nodeName}
                  editable={nodeEditPermissions && isNodeNameEditable}
                  panelDisplayMode={nodePanelDisplayMode}
                  setEditable={() => {
                    dispatchNodeAction({
                      type: 'SET_IS_NODE_NAME_EDITABLE',
                      payload: true,
                    });
                  }}
                  error={Boolean(nodeNameValidationErrorMessage)}
                  helperText={nodeNameValidationErrorMessage}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      if (nodeNameValidationErrorMessage) {
                        return;
                      }
                      if (nodeName !== previousNodeName) {
                        dispatchNodeAction({
                          type: 'SET_NODE_NAME',
                          payload: ev.target.value,
                        });
                        saveNodeName();
                      } else {
                        dispatchNodeAction({
                          type: 'SET_IS_NODE_NAME_EDITABLE',
                          payload: false,
                        });
                      }
                    }
                  }}
                  onChange={(ev) => {
                    dispatchNodeAction({
                      type: 'SET_NODE_NAME',
                      payload: ev.target.value,
                    });
                  }}
                  onMouseDown={(ev) => {
                    ev.stopPropagation();
                  }}
                  saveName={saveNodeName}
                  revertName={revertNodeName}
                />
                <div
                  style={{
                    paddingLeft: 12,
                  }}
                >
                  <span style={{textTransform: 'capitalize'}}>
                    <Typography
                      variant="h5"
                    >
                      {typeName}
                    </Typography>
                  </span>
                </div>
              </ContextualPanelHeader>
            }

            {/* PANEL DISPLAY MODE: 'editPermissions' */}
            {
              nodePanelDisplayMode === 'editPermissions' &&
              <>
                <ContextualPanelContent
                  panelDisplayMode={nodePanelDisplayMode}
                >
                  <PaddedContainer>
                    <Grid
                      container
                      spacing={3}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <PermissionsContainer>
                          <PermissionSwitches
                            disabled={isNodeSaving}
                            permissions={nodeAtomicPermissionsToFrontendPermissions(nodeGrantAtomicPermissions)}
                            getPermissionReadableFormat={nodeFrontendPermissionLabel}
                            editable={frontendPermissions}
                            disabledPermissions={NODE_FRONTEND_DISABLED_PERMISSIONS}
                            onTogglePermission={(permission) => {
                              const newSharedFrontendPermissions = {
                                ...nodeGrantFrontendPermissions,
                                [permission]: !nodeGrantFrontendPermissions[permission],
                              };
                              dispatchNodeAction({
                                type: 'SET_NODE_GRANT_FRONTEND_PERMISSIONS',
                                payload: nodeFrontendPermissionsToImpliedFrontendPermissions(newSharedFrontendPermissions),
                              });

                              const newSharedAtomicPermissions = nodeFrontendPermissionsToAtomicPermissions(newSharedFrontendPermissions);
                              dispatchNodeAction({
                                type: 'SET_NODE_GRANT_ATOMIC_PERMISSIONS',
                                payload: newSharedAtomicPermissions,
                              });
                            }}
                          />
                        </PermissionsContainer>
                      </Grid>
                    </Grid>
                  </PaddedContainer>
                </ContextualPanelContent>
                <ContextualPanelActions expanded={showNodePanel}>
                  <Button
                    data-test-id="save-all-fields-button"
                    disabled={expandedNodeLoadingState !== 'loaded' || isNodeSaving}
                    onClick={() => {
                      saveNodePermissions();
                    }}
                  >
                    {isNodeSaving ? 'SAVING...' : 'SAVE'}
                  </Button>
                  <SecondButtonContainer>
                    <Button
                      data-test-id="cancel-button"
                      variant="text"
                      disabled={expandedNodeLoadingState !== 'loaded' || isNodeSaving}
                      onClick={() => {
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                          payload: 'default',
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  </SecondButtonContainer>
                </ContextualPanelActions>
              </>
            }

            {/* PANEL DISPLAY MODE: 'editItem' */}
            {
              nodePanelDisplayMode === 'editItem' &&
              <>
                <ContextualPanelContent
                  panelDisplayMode={nodePanelDisplayMode}
                >
                  <PaddedContainer>
                    <Grid
                      container
                      spacing={3}>
                      {
                        fieldDefinitionIds.map(fieldDefinitionId => {

                          const {
                            value,
                            isSaving,
                            errorMessage,
                          } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

                          const {
                            type,
                            label,
                            description,
                            enumValues,
                          } = fieldDefinitionsById[fieldDefinitionId];

                          const options = type === 'enum' ? ['N/A', ...enumValues] : enumValues;

                          return (
                            <Grid
                              key={fieldDefinitionId}
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              style={{
                                cursor: 'pointer',
                                height: '110px',
                                flexGrow: 1,
                              }}
                            >
                              {
                                type === 'date' ?
                                  <Field
                                    type="date"
                                    label={label}
                                    helperText={errorMessage ? errorMessage : description}
                                    error={Boolean(errorMessage)}
                                    isEditable={true}
                                    required={false}
                                    value={value === null ? value : getDateWithTimezoneOffset(value)}
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                    }}
                                    onChange={(value) => {
                                      dispatchNodeAction({
                                        type: 'SET_FIELD_VALUE',
                                        payload: {
                                          fieldDefinitionId,
                                          value: value === null ? null : getDateToAPIFormat(value),
                                        },
                                      });
                                      dispatchNodeAction({
                                        type: 'SET_FIELD_ERROR_MESSAGE',
                                        payload: {
                                          fieldDefinitionId,
                                          errorMessage: FIELD_VALIDATION['date']({value: value}),
                                        },
                                      });
                                    }}
                                    onKeyPress={(ev) => {
                                      if (ev.key === 'Enter') {
                                        dispatchNodeAction({
                                          type: 'SET_FIELD_ERROR_MESSAGE',
                                          payload: {
                                            fieldDefinitionId,
                                            errorMessage: FIELD_VALIDATION['date']({value: value}),
                                          },
                                        });
                                        dispatchNodeAction({
                                          type: 'SET_FIELD_VALUE',
                                          payload: {
                                            fieldDefinitionId,
                                            value: value === null ? null : value,
                                          },
                                        });
                                      }
                                    }}
                                    onMouseDown={(ev) =>{
                                      ev.stopPropagation();
                                    }}
                                    disabled={false}
                                    isSaving={isSaving}
                                  />
                                  :
                                  <Field
                                    inlineEdit={false}
                                    autoFocus={false}
                                    type={type}
                                    label={label}
                                    helperText={Boolean(errorMessage) ? errorMessage : description}
                                    error={Boolean(errorMessage)}
                                    isEditable={true}
                                    value={value}
                                    options={options}
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                    }}
                                    onKeyPress={(ev) => {
                                      if (ev.key === 'Enter') {
                                        dispatchNodeAction({
                                          type: 'SET_FIELD_VALUE',
                                          payload: {
                                            fieldDefinitionId,
                                            value: ev.target.value,
                                          },
                                        });
                                      }
                                    }}
                                    onChange={(ev) => {
                                      dispatchNodeAction({
                                        type: 'SET_FIELD_VALUE',
                                        payload: {
                                          fieldDefinitionId,
                                          value: ev.target.value,
                                        },
                                      });
                                      dispatchNodeAction({
                                        type: 'SET_FIELD_ERROR_MESSAGE',
                                        payload: {
                                          fieldDefinitionId,
                                          errorMessage: FIELD_VALIDATION[type]({value: ev.target.value, options: options}),
                                        },
                                      });
                                    }}
                                    onMouseDown={(ev) =>{
                                      ev.stopPropagation();
                                    }}
                                    disabled={false}
                                    isSaving={isSaving}
                                    required={false}
                                  />
                              }
                            </Grid>
                          );
                        })
                      }
                    </Grid>
                  </PaddedContainer>
                </ContextualPanelContent>
                <ContextualPanelActions expanded={showNodePanel}>
                  <Button
                    data-test-id="save-all-fields-button"
                    disabled={expandedNodeLoadingState !== 'loaded' || fieldsHaveErrors || Boolean(nodeNameValidationErrorMessage) || isNodeSaving}
                    onClick={() => {
                      saveAllFields();
                    }}
                  >
                    {isNodeSaving ? 'SAVING...' : 'SAVE'}
                  </Button>
                  <SecondButtonContainer>
                    <Button
                      data-test-id="cancel-button"
                      variant="text"
                      disabled={expandedNodeLoadingState !== 'loaded' || isNodeSaving}
                      onClick={() => {
                        revertAllFields();
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                          payload: 'default',
                        });
                      }}
                    >
                      CANCEL
                    </Button>
                  </SecondButtonContainer>
                </ContextualPanelActions>
              </>
            }

            {/* PANEL DISPLAY MODE: 'editLocation' */}
            {
              nodePanelDisplayMode === 'editLocation' &&
              <NodeLocationEdit
                panelDisplayMode={nodePanelDisplayMode}
                nodeState={nodeState}
                dispatchNodeAction={dispatchNodeAction}
                setError={setError}
              />
            }

            {/* PANEL DISPLAY MODE: 'addNewLink' */}
            {
              nodePanelDisplayMode === 'addNewLink' &&
              <>
                <ContextualPanelContent
                  panelDisplayMode={nodePanelDisplayMode}
                >
                  <PaddedContainer>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          cursor: 'pointer',
                          flexGrow: 1,
                          height: '100%',
                        }}
                      >
                        <NodeLinkCandidatesList
                          nodeState={nodeState}
                          dispatchNodeAction={dispatchNodeAction}
                          setError={setError}
                          handleLinkInfo={handleLinkInfo}
                        />
                      </Grid>
                    </Grid>
                  </PaddedContainer>
                </ContextualPanelContent>
                <ContextualPanelActions expanded={showNodePanel}>
                  <Button
                    data-test-id="close-candidates-list-button"
                    disabled={false}
                    onClick={() => {
                      dispatchUserInterfaceAction({
                        type: 'SET_NODE_PANEL_DISPLAY_MODE',
                        payload: 'default',
                      });
                    }}
                  >
                    Done
                  </Button>
                </ContextualPanelActions>
              </>
            }

            {/* PANEL DISPLAY MODE: 'conversation' */}
            {
              nodePanelDisplayMode === 'conversation' &&
              <ConversationContainer
                newConversationConnectionId={connectionId}
                conversationConnectionName={conversationConnectionName}
                conversationInterlocutorOrganizationName={conversationInterlocutorOrganizationName}
                conversationTetherName={conversationTetherName}
                conversation={selectedConversation}
                onCreateNewConversation={async(connectionId, message) => {
                  const newConversation = await createNodeConversation(
                    authToken,
                    currentPerspectiveId,
                    {
                      nodeId: expandedNodeId,
                      message,
                      connectionId,
                    },
                  );
                  dispatchUserInterfaceAction({
                    type: 'SET_SELECTED_CONVERSATION',
                    payload: newConversation,
                  });
                }}
                onUpdateConversationStatus={async(conversationId, status) => {
                  await updateConversationStatus({
                    authToken,
                    perspectiveId: currentPerspectiveId,
                    conversationId,
                    status,
                  });
                  const updatedConversation = await genConversationByConversationId({
                    authToken,
                    perspectiveId: currentPerspectiveId,
                    conversationId,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_SELECTED_CONVERSATION',
                    payload: updatedConversation,
                  });
                }}
              />
            }

            {/* PANEL DISPLAY MODE: 'start_conversation' */}
            {
              nodePanelDisplayMode === 'start_conversation' &&
              <StartConversation
                panelDisplayMode={nodePanelDisplayMode}
                loadConnectionsList={async() => {
                  const result = await genAvailableConnectionsByNodeId({
                    authToken,
                    perspectiveId: currentPerspectiveId,
                    nodeId,
                  });

                  return result;
                }}
                onSelectConnection={(connection) => {
                  const {
                    name,
                    peerName,
                    id: connectionId,
                  } = connection;

                  dispatchUserInterfaceAction({
                    type: 'SET_NEW_CONVERSATION',
                    payload: {
                      connectionId,
                      connectionName: name,
                      interlocutorOrganizationName: peerName,
                      nodeName: nodeName,
                    },
                  });

                  dispatchUserInterfaceAction({
                    type: 'SET_NODE_PANEL_DISPLAY_MODE',
                    payload: 'conversation',
                  });
                }}
                dispatchUserInterfaceAction={dispatchUserInterfaceAction}
              />
            }

            {/* PANEL DISPLAY MODE: 'default' */}
            {
              nodePanelDisplayMode === 'default' &&
              <>
                <ContextualPanelContent
                  panelDisplayMode={nodePanelDisplayMode}
                >
                  <ContextualPanelTabs selectedTab={selectedNodePanelTab}>
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(0);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={0}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={setSelectedTab}
                      label="Item Details"
                      icon={<InfoRoundedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(1);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={1}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={setSelectedTab}
                      label="Attachments"
                      icon={<AttachFileRoundedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(2);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={2}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={setSelectedTab}
                      label="Links"
                      icon={<LinkRoundedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(3);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={3}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={setSelectedTab}
                      label="Tags"
                      icon={<LabelOutlinedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(4);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={4}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={setSelectedTab}
                      label="Permissions"
                      icon={<ShareRoundedIcon />}
                    />
                    <ContextualPanelTab
                      onMouseEnter={() => {
                        setHoveredTab(4);
                      }}
                      onMouseLeave={() => {
                        setHoveredTab(null);
                      }}
                      tabIndex={5}
                      selectedTab={selectedNodePanelTab}
                      hoveredTab={hoveredTab}
                      setSelectedTab={setSelectedTab}
                      label="Conversations"
                      icon={<ChatBubbleOutlineIcon />}
                    />
                  </ContextualPanelTabs>
                  {
                    selectedNodePanelTab === 0 &&
                    <NodeDetails
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                      saveNodeField={saveNodeField}
                      openEditMode={() => {
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                          payload: 'editItem',
                        });
                      }}
                    />
                  }
                  {
                    selectedNodePanelTab === 1 &&
                    <NodeAttachments
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                    />
                  }
                  {
                    selectedNodePanelTab === 2 &&
                    <NodeLinks
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                      handleLinkInfo={handleLinkInfo}
                    />
                  }
                  {
                    selectedNodePanelTab === 3 &&
                    <NodeTags
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      setError={setError}
                    />
                  }
                  {
                    selectedNodePanelTab === 4 &&
                    <NodePermissionsTab
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      dispatchUserInterfaceAction={dispatchUserInterfaceAction}
                      setError={setError}
                    />
                  }
                  {
                    selectedNodePanelTab === 5 &&
                    <NodeConversationsTab
                      nodeState={nodeState}
                      dispatchNodeAction={dispatchNodeAction}
                      dispatchUserInterfaceAction={dispatchUserInterfaceAction}
                      setError={setError}
                    />
                  }
                </ContextualPanelContent>

                <ContextualPanelActions expanded={showNodePanel}>
                  <Button
                    data-test-id="full-edit-button"
                    disabled={expandedNodeLoadingState !== 'loaded' || isNodeSaving || isNodeNameSaving}
                    onClick={(ev) => {
                      setEditMenuAnchorEl(ev.currentTarget);
                      setIsEditMenuOpen(true);
                    }}
                  >
                    EDIT
                  </Button>

                  <SecondButtonContainer>
                    <Button
                      variant="icon-text"
                      onClick={(ev) => {
                        setPanelMenuAnchorEl(ev.currentTarget);
                        setIsPanelMenuOpen(true);
                      }}
                    >
                      <MoreHorizIcon />
                    </Button>
                  </SecondButtonContainer>

                  <Menu
                    open={isPanelMenuOpen}
                    onClose={handleClosePanelMenu}
                    anchorEl={panelMenuAnchorEl}
                    disablePortal={true}
                  >
                    <MenuItem
                      data-test-id="node-panel-context-menu-share-button"
                      disabled={!sharePermissions || uploadsInProgress() || downloadsInProgress()}
                      onClick={() => {
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_SHARE_NODE_WIZARD',
                          payload: true,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_SHARE_NODE_ID',
                          payload: expandedNodeId,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_NODE_PANEL',
                          payload: false,
                        });
                        setIsPanelMenuOpen(false);
                      }}
                    >
                      <Typography variant="small">
                        Share Item
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      data-test-id="node-panel-context-menu-delete-button"
                      disabled={!nodeDeletePermissions || uploadsInProgress() || downloadsInProgress()}
                      onClick={() => {
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_NODE_PANEL',
                          payload: false,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_DELETE_NODE_ID',
                          payload: nodeId,
                        });
                        dispatchUserInterfaceAction({
                          type: 'SET_SHOW_DELETE_NODE_DIALOG',
                          payload: true,
                        });
                        setIsPanelMenuOpen(false);
                      }}
                      color="error"
                    >
                      <Typography
                        variant="small"
                      >
                        Delete Item
                      </Typography>
                    </MenuItem>
                  </Menu>

                  <Menu
                    open={isEditMenuOpen}
                    onClose={handleCloseEditMenu}
                    anchorEl={editMenuAnchorEl}
                    disablePortal={true}
                  >
                    <MenuItem
                      disabled={!nodeEditPermissions}
                      data-test-id="node-panel-item-details-button"
                      showDivider={true}
                      onClick={async() => {
                        try {
                          await deleteEmptyNodeFields();
                          revertErroneousNodeFields();
                          dispatchUserInterfaceAction({
                            type: 'SET_NODE_PANEL_DISPLAY_MODE',
                            payload: 'editItem',
                          });
                          setIsEditMenuOpen(false);
                        } catch(error) {
                          setError(error);
                        }
                      }}
                    >
                      <Typography
                        variant="small"
                      >
                        Item Details
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      disabled={!nodeEditPermissions}
                      data-test-id="node-panel-item-location-button"
                      onClick={() => {
                        if (selectedNodePanelTab !== 0) {
                          setSelectedTab(0);
                        }
                        dispatchUserInterfaceAction({
                          type: 'SET_NODE_PANEL_DISPLAY_MODE',
                          payload: 'editLocation',
                        });
                        setIsEditMenuOpen(false);
                      }}
                    >
                      <Typography
                        variant="small"
                      >
                        Assignment / Location
                      </Typography>
                    </MenuItem>
                  </Menu>
                </ContextualPanelActions>
              </>
            }

            <NodeLinkInfoCard
              open={isLinkInfoOpen}
              loading={isLinkInfoLoading}
              handleClose={handleCloseLinkInfo}
              linkInfo={linkInfo}
            />

            <Message
              variant="success"
              open={showSuccessMessage}
              onClose={() => {
                setShowSuccessMessage(false);
              }}
              messageTitle="Item saved successfully"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              width={532}
            />
          </>
      }
    </ContextualPanel>
  );
};

NewNodePanel.propTypes = {
  expandedNodeId: PropTypes.number,
  showNodePanel: PropTypes.bool,
  dispatchUserInterfaceAction: PropTypes.func,
};

export default NewNodePanel;
