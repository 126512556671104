import React from 'react';
import styled from '@emotion/styled';

import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import Grid from '@material-ui/core/Grid';

import ContextualPanelHeader from 'components/chrome/ContextualPanelHeader';
import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';


import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
`;

const StartConversation = ({
  panelDisplayMode,
  loadConnectionsList,
  onSelectConnection,
}) => {

  const {
    authToken,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);


  const [isInitialized, setIsInitialized] = React.useState(false);
  const [connections, setConnections] = React.useState([]);

  React.useEffect(() => {
    const init = async() => {
      const connections = await loadConnectionsList();
      setConnections(connections);
      setIsInitialized(true);
    };

    if (!isInitialized) {
      init();
    }

  }, [authToken, currentPerspectiveId, isInitialized, loadConnectionsList]);

  if (!isInitialized) {
    return (
      <PaddedContainer>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            style={{
              height: '100%',
              flexGrow: 1,
              width: '100%',
            }}
          >
            <LoadingContainer>
              <CenterpieceSpinner/>
            </LoadingContainer>
          </Grid>
        </Grid>
      </PaddedContainer>
    );
  }

  return (
    <>
      <ContextualPanelHeader
        panelDisplayMode={panelDisplayMode}
      >
        <Typography
          variant="h3"
          fontWeight="bold"
        >
        Start a Conversation
        </Typography>
        <Typography
          variant="caption"
        >
          You can start a conversation with one of your Connections
        </Typography>
      </ContextualPanelHeader>

      <ContextualPanelContent
        panelDisplayMode={panelDisplayMode}
      >
        <PaddedContainer>
          {connections.map((connection, index) => (
            <ListItem
              key={index}
              onClick={() => {}}>
              <ListItemContentSegment
                weight={1}
              >
                <ListItemText>
                  <div>
                    <Typography
                      variant="small"
                      fontWeight="bold"
                    >
                      {connection.name}
                    </Typography>
                  </div>
                  <Typography variant="caption" color="grey">
                    {connection.peerName}
                  </Typography>
                </ListItemText>
              </ListItemContentSegment>
              <ListItemContentSegment
                width={200}
                justifyContent="flex-end"
              >
                <ListItemActions>
                  <Button
                    data-test-id="select-connection-for-conversation-button"
                    variant="text"
                    startIcon={<ChatRoundedIcon />}
                    onClick={event => {
                      event.stopPropagation();
                      onSelectConnection(connection);
                    }}
                  >
                    Start
                  </Button>
                </ListItemActions>
              </ListItemContentSegment>
            </ListItem>
          ))}
        </PaddedContainer>
      </ContextualPanelContent>
    </>
  );
};

export default StartConversation;
