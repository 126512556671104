import React from 'react';

import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import Typography from 'ui-library/components/Typography';

import DeleteIcon from '@material-ui/icons/DeleteRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';

export default ({
  deleteComment,
  isDeleting,
  onDelete,
  onCancel,
}) => (
  <ModalCard
    open={deleteComment !== null}
    handleClose={onCancel}
    disableBackdropClick={true}
  >
    <ModalCardHeader color="error">
      <ModalCardHeaderIcon color="error">
        <WarningIcon
          fontSize="inherit"
        />
      </ModalCardHeaderIcon>
      <ModalCardHeaderTitle>
        Delete Comment
      </ModalCardHeaderTitle>
    </ModalCardHeader>
    <ModalCardContent>
      <Typography variant="body">
          Are you sure you want to delete the following comment?
        <Typography variant="body" fontStyle="italic">
          "{deleteComment && deleteComment.message}"
        </Typography>
      </Typography>
    </ModalCardContent>
    <ModalCardActions>
      <Button
        data-test-id="delete-comment-cancel-button"
        variant="text"
        onClick={() => onCancel()}
      >
        CANCEL
      </Button>
      <Button
        data-test-id="delete-comment-button"
        variant="custom"
        textTransform="none"
        customBgColor="error"
        customHoverBgColor="error-dark"
        customTextColor="white"
        customHoverTextColor="white"
        customActiveBgColor="error-darker"
        customActiveTextColor="white"
        startIcon={isDeleting ? <CircularProgress size={24} color="white"/> : <DeleteIcon />}
        disabled={isDeleting}
        onClick={onDelete}
      >
        DELETE
      </Button>
    </ModalCardActions>
  </ModalCard>
);
