import React from 'react';
import styled from '@emotion/styled';
import {
  wizardProgressHeight,
  wizardNavigationHeight,
} from 'constants/layout';
import {
  greyLighter,
} from 'ui-library/color-palette';

const Root = styled.div`
  position: fixed;
  top: ${wizardProgressHeight}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${wizardNavigationHeight}px;
  padding: 0 10px;
  background-color: ${greyLighter};
`;

const WizardNavigation = ({
  children,
}) => {
  return (
    <Root>
      {children}
    </Root>
  );
};

export default WizardNavigation;
