import React from 'react';
import styled from '@emotion/styled';
import TimeAgo from 'timeago-react';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';

import {
  tertiary,
  tertiaryLightest,
  grey,
  greySemi,
  greyDark,
  greyDarker,
  white,
  black,
} from 'ui-library/color-palette';

const CommentContainer = styled.div`
  background: ${props => props.isDeleted ? 'transparent' : props.isOwn ? tertiary : white};
  border: ${props => props.isDeleted ? `1px solid ${grey}` : props.isOwn ? 'none' : `1px solid ${greySemi}`};
  box-sizing: border-box;
  border-radius: 20px;
  color: ${props => props.isOwn && !props.isDeleted ? white : black};
  margin-left: ${props => props.isReply && !props.isDeleted ? '35px' : '0px'};
  padding: 16px 24px 24px 24px;
  &:not(:first-of-type) {
    margin-top: ${props => props.isReply && !props.isDeleted ? '-20px' : '14px'};
  }
`;

const ParentCommentContainer = styled(CommentContainer)`
  background: ${props => props.isDeleted ? 'transparent' : props.isOwn ? tertiaryLightest : greySemi};
  color: ${props => props.isDeleted ? black : props.isOwn ? greyDark : greyDarker};
  margin-top: 14px;
  margin-right: 35px;
  padding: 16px 24px 24px 24px;
`;

const CommentHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  & > span {
    margin-top: 8px;
  }
`;

const ParentCommentHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  place-content: space-between;
  & > :first-of-type {
    margin-top: 8px;
  }
`;

const CommentBodyContainer = styled.div`
  overflow-wrap: break-word;
  white-space: pre-line;
`;

const CommentFooterContainer = styled.div`
  margin-top: 12px;
`;

const Comment = ({
  id,
  isOwn,
  message,
  isReply,
  parentComment,
  flag,
  timestamp,
  organizationId,
  hasContextualMenu,
  contextualMenuState,
  setContextualMenuState,
  onReply,
  onEdit,
  onDelete,
  conversation,
}) => {
  const {
    interlocutorOrganizationId,
    interlocutorOrganizationName,
    participantOrganizationName,
  } = conversation || {};
  return (
    <>
      {parentComment && flag !== 'deleted' && (
        <ParentCommentContainer
          isDeleted={parentComment.flag === 'deleted'}
          isOwn={parentComment.isOwn}>
          {parentComment.flag === 'deleted' || (
            <ParentCommentHeaderContainer>
              <Typography variant="caption" fontWeight="bold">
                {
                  parentComment.organizationId === interlocutorOrganizationId
                    ? interlocutorOrganizationName
                    : participantOrganizationName
                }
              </Typography>
              {parentComment.flag === 'edited' && (
                <Typography variant="tiny" fontWeight="bold">
                  Edited
                </Typography>
              )}
            </ParentCommentHeaderContainer>
          )}
          <CommentBodyContainer>
            <Typography fontStyle={parentComment.flag === 'deleted' ? 'italic' : 'normal'} variant="body">
              {parentComment.flag === 'deleted' ? 'This comment was deleted.' : parentComment.message}
            </Typography>
          </CommentBodyContainer>
        </ParentCommentContainer>
      )}
      <CommentContainer
        isDeleted={flag === 'deleted'}
        isOwn={isOwn}
        isReply={isReply}>
        {flag === 'deleted' || (
          <CommentHeaderContainer>
            <Typography variant="caption" fontWeight="bold">
              {
                organizationId === interlocutorOrganizationId
                  ? interlocutorOrganizationName
                  : participantOrganizationName
              }
            </Typography>
            {hasContextualMenu && (
              <>
                <Button
                  data-test-id="comment-context-menu-button"
                  variant="icon-custom"
                  textTransform="none"
                  customBgColor="transparent"
                  customHoverBgColor="primary-lightest"
                  customTextColor={isOwn ? 'white' : 'black'}
                  customHoverTextColor="primary-dark"
                  customActiveBgColor="white"
                  customActiveTextColor="primary-darker"
                  onClick={event => {
                    event.stopPropagation();
                    setContextualMenuState({
                      anchorEl: event.currentTarget,
                      commentData: {
                        id,
                        isOwn,
                        message,
                      },
                    });
                  }}
                >
                  <MoreHorizIcon/>
                </Button>
                <Menu
                  open={!!contextualMenuState.commentData && contextualMenuState.commentData.id === id}
                  anchorEl={contextualMenuState.anchorEl}
                  onClose={() => {
                    setContextualMenuState({
                      commentData: null,
                      anchorEl: null,
                    });
                  }}>
                  <MenuItem
                    data-test-id="comment-context-menu-reply-button"
                    onClick={event => {
                      event.stopPropagation();
                      onReply(contextualMenuState.commentData);
                      setContextualMenuState({
                        commentData: null,
                        anchorEl: null,
                      });
                    }}
                  >
                    <Typography variant="small">
                      Reply
                    </Typography>
                  </MenuItem>
                  {isOwn && [
                    <MenuItem
                      key={1}
                      data-test-id="comment-context-menu-edit-button"
                      onClick={event => {
                        event.stopPropagation();
                        onEdit(contextualMenuState.commentData);
                        setContextualMenuState({
                          commentData: null,
                          anchorEl: null,
                        });
                      }}
                    >
                      <Typography variant="small">
                        Edit
                      </Typography>
                    </MenuItem>,
                    <MenuItem
                      key={2}
                      data-test-id="comment-context-menu-delete-button"
                      onClick={event => {
                        event.stopPropagation();
                        onDelete(contextualMenuState.commentData);
                        setContextualMenuState({
                          commentData: null,
                          anchorEl: null,
                        });
                      }}
                    >
                      <Typography variant="small" color="error">
                        Delete
                      </Typography>
                    </MenuItem>,
                  ]}
                </Menu>
              </>
            )}
          </CommentHeaderContainer>
        )}
        <CommentBodyContainer>
          <Typography fontStyle={flag === 'deleted' ? 'italic' : 'normal'} variant="body">
            {flag === 'deleted' ? 'This comment was deleted.' : message}
          </Typography>
        </CommentBodyContainer>
        {flag === 'deleted' || (
          <CommentFooterContainer>
            <Typography variant="tiny">
              <TimeAgo
                datetime={timestamp * 1000}
                locale='en-US'
                live={false}
              />
            </Typography>
            {flag === 'edited' && (
              <Typography variant="tiny" fontWeight="bold"> · Edited</Typography>
            )}
          </CommentFooterContainer>
        )}
      </CommentContainer>
    </>
  );
};

export default Comment;
