import POST__verifyEmail_resendEmail from 'network-layer/endpoints/POST__verifyEmail_resendEmail';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return response;
}

export default async function resendEmail(email) {

  const response = await POST__verifyEmail_resendEmail(email);

  return responseAdapter(response);
}
