import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../verbs';

export default async function DELETE__nodeLinks_ID(authToken, perspectiveId, nodeLinkId) {

  return await DELETE(
    `/node-links/${nodeLinkId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
