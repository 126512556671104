import React from 'react';
import PropTypes from 'prop-types';
import MUIDialogTitle from '@material-ui/core/DialogTitle';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import {COLOR_MONIKERS} from 'ui-library/color-palette';

const useStyles = makeStyles({
  root: {
    padding: theme.modalCardHeader.root.padding,
  },
  closeIcon: {
    color: props => theme.palette[props.themeColor],
    cursor: theme.modalCardHeader.closeIcon.cursor,
  },
  closeIconContainer: {
    display: theme.modalCardHeader.closeIconContainer.display,
    justifyContent: theme.modalCardHeader.closeIconContainer.justifyContent,
  },
  headerContainer: {
    width: theme.modalCardHeader.headerContainer.width,
    border: theme.modalCardHeader.headerContainer.border,
    height: theme.modalCardHeader.headerContainer.height,
    display: theme.modalCardHeader.headerContainer.display,
    padding: theme.modalCardHeader.headerContainer.padding,
  },
});

const ModalCardHeader = ({
  color,
  children,
}) => {

  const themeColor = color ? color : 'primary';
  const classes = useStyles({
    themeColor,
  });

  return (
    <MUIDialogTitle className={classes.root}>
      <div className={classes.headerContainer}>
        {children}
      </div>
    </MUIDialogTitle>
  );
};

ModalCardHeader.propTypes = {
  color: PropTypes.oneOf(COLOR_MONIKERS),
  children: PropTypes.node,
};

export default ModalCardHeader;
