import React from 'react';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import {NavLink} from 'react-router-dom';
import styled from '@emotion/styled';

function breadcrumbComponentsFromBreadcrumbs(breadcrumbs, status, history) {
  let key = 0;

  const components = [];

  for (let i = 0; i < breadcrumbs.length; i++) {
    const {
      path,
      label,
    } = breadcrumbs[i];

    if (i === breadcrumbs.length - 1) {
      components.push(
        <TerminalBreadcrumb
          key={key++}
        >
          <Typography
            variant="body"
            fontWeight="bold"
          >
            {label}
          </Typography>
        </TerminalBreadcrumb>,
      );
    } else {
      components.push(
        <Breadcrumb
          key={key++}
        >
          {
            status === 'ready' ?
              <>
                <NavLink
                  to={path}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Typography
                    variant="body"
                  >
                    {label}
                  </Typography>
                </NavLink>
                {i < breadcrumbs.length - 1 && (
                  <BreadcrumbSeparator
                    key={key++}
                  />
                )}
              </>
              :
              <Typography
                variant="body"
              >
                {label}
              </Typography>
          }
        </Breadcrumb>,
      );
    }
  }

  return (
    <Breadcrumbs>
      <Button
        variant="icon-custom"
        textTransform="none"
        customBgColor="transparent"
        customHoverBgColor="tertiary-lightest"
        customTextColor="primary"
        customHoverTextColor="primary"
        customActiveBgColor="tertiary-lightest"
        customActiveTextColor="primary"
        style={{
          marginLeft: '16px',
          marginRight: '8px',
        }}
        disabled={breadcrumbs.length < 2}
        onClick={() => {
          const breadcrumbsStack = breadcrumbs.filter(breadcrumb => !breadcrumb.skipWhenUnstacking);
          history.push(
            breadcrumbsStack[breadcrumbsStack.length - 2].path,
          );
        }}
      >
        <ArrowBackRoundedIcon/>
      </Button>
      <div style={{paddingTop: '4px'}}>
        {components}
      </div>
    </Breadcrumbs>
  );
}

const Breadcrumbs = ({
  children,
}) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'flex-start',
    }}>
      {children}
    </div>
  );
};

const Breadcrumb = styled.span`
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`;

const TerminalBreadcrumb = ({
  children,
}) => {
  return (
    <div style={{
      display: 'inline-block',
    }}>
      {children}
    </div>
  );
};

const BreadcrumbSeparator = () => {
  return (
    <div
      style={{
        margin: '0 4px',
        display: 'inline-block',
      }}
    >
      /
    </div>
  );
};

export default breadcrumbComponentsFromBreadcrumbs;
