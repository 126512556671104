import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {POST} from '../verbs';

export default async function POST__conversations_connections_CONNECTIONID(authToken, perspectiveId, params) {
  const {
    connectionId,
    message,
  } = params;

  return await POST(
    `/conversations/connections/${connectionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      message,
    },
  );
}
