import React from 'react';
import PropTypes from 'prop-types';

import TextEditMode from './Text/editMode.js';
import TextViewMode from './Text/viewMode.js';
import LongformTextEditMode from './LongformText/editMode.js';
import LongformTextViewMode from './LongformText/viewMode.js';
import NumberEditMode from './Number/editMode.js';
import NumberViewMode from './Number/viewMode.js';
import CurrencyEditMode from './Currency/editMode.js';
import CurrencyViewMode from './Currency/viewMode.js';
import DateEditMode from './Date/editMode.js';
import DateViewMode from './Date/viewMode.js';
import UrlEditMode from './Url/editMode.js';
import UrlViewMode from './Url/viewMode.js';
import PhoneNumberEditMode from './PhoneNumber/editMode.js';
import PhoneNumberViewMode from './PhoneNumber/viewMode.js';
import EmailEditMode from './Email/editMode.js';
import EmailViewMode from './Email/viewMode.js';
import EnumEditMode from './Enum/editMode.js';
import EnumViewMode from './Enum/viewMode.js';
import BooleanEditMode from './Boolean/editMode.js';
import BooleanViewMode from './Boolean/viewMode.js';
import YearEditMode from './Year/editMode.js';
import YearViewMode from './Year/viewMode.js';
import NameEditMode from './Name/editMode.js';
import NameViewMode from './Name/viewMode.js';
import ZipcodeEditMode from './Zipcode/editMode.js';
import ZipcodeViewMode from './Zipcode/viewMode.js';

const EDIT_MODE_COMPONENTS = {
  'text': TextEditMode,
  'longform text': LongformTextEditMode,
  'number': NumberEditMode,
  'currency': CurrencyEditMode,
  'date': DateEditMode,
  'url': UrlEditMode,
  'phone number': PhoneNumberEditMode,
  'e-mail address': EmailEditMode,
  'enum': EnumEditMode,
  'boolean': BooleanEditMode,
  'year': YearEditMode,
  'name': NameEditMode,
  'zip code': ZipcodeEditMode,
};

const VIEW_MODE_COMPONENTS = {
  'text': TextViewMode,
  'longform text': LongformTextViewMode,
  'number': NumberViewMode,
  'currency': CurrencyViewMode,
  'date': DateViewMode,
  'url': UrlViewMode,
  'phone number': PhoneNumberViewMode,
  'e-mail address': EmailViewMode,
  'enum': EnumViewMode,
  'boolean': BooleanViewMode,
  'year': YearViewMode,
  'name': NameViewMode,
  'zip code': ZipcodeViewMode,
};

const fieldComponentEditMode = type => {
  const component = EDIT_MODE_COMPONENTS[type];
  if (!component) {
    throw new Error(`No edit mode component defined for "${type}"!`);
  }
  return component;
};

const fieldComponentViewMode = type => {
  const component = VIEW_MODE_COMPONENTS[type];
  if (!component) {
    throw new Error(`No view mode component defined for "${type}"!`);
  }
  return component;
};

const Field = ({
  autoFocus,
  inlineEdit,
  label,
  helperText,
  error,
  isEditable,
  isSaving,
  required,
  value,
  options,
  customOptions,
  disabled,
  onClose,
  onChange,
  onKeyPress,
  onClick,
  onBlur,
  onFocus,
  onMouseDown,
  saveField,
  revertField,

  type,
}) => {

  const FieldControl = isEditable ? fieldComponentEditMode(type) : fieldComponentViewMode(type);

  return (
    <FieldControl
      autoFocus={autoFocus}
      inlineEdit={inlineEdit}
      label={label}
      helperText={helperText}
      error={error}
      isEditable={isEditable}
      isSaving={isSaving}
      required={required}
      value={value}
      options={options}
      customOptions={customOptions}
      disabled={disabled}
      onClose={onClose}
      onChange={onChange}
      onKeyPress={onKeyPress}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      saveField={saveField}
      revertField={revertField}
    />
  );
};

Field.propTypes = {
  autoFocus: PropTypes.bool,
  inlineEdit: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  isEditable: PropTypes.bool,
  isSaving: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  customOptions: PropTypes.array,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  saveField: PropTypes.func,
  revertField: PropTypes.func,

  type: PropTypes.oneOf([
    'text',
    'longform text',
    'number',
    'currency',
    'date',
    'url',
    'phone number',
    'e-mail address',
    'enum',
    'boolean',
    'year',
    'name',
    'zip code',
  ]).isRequired,
};

export default Field;
