import React from 'react';
import {NavLink, Route, Switch, Redirect} from 'react-router-dom';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';

import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardTypeTile from 'components/chrome/WizardTypeTile';

import Typography from 'ui-library/components/Typography';

import AddPropertyWizard from 'components/AddPropertyWizard';
import AddItemWizard from 'components/AddItemWizard';
import AddConnectionWizard from 'components/AddConnectionWizard';

import ArrowForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';

const BusinessAddWizard = ({
  history,
  match,
}) => {
  return (
    <>
      <Wizard onClose={() => {}}>
        <WizardProgress
          isVisible={true}
          progress={1}
          total={10}
        />
        <WizardNavigation>
          <WizardBackButton
            isVisible={false}
            onClick={() => {}}
          />
          <WizardCloseButton
            isVisible={true}
            onClick={() => history.goBack()}
          />
        </WizardNavigation>

        <WizardStep>
          <WizardStepHead>
            <WizardStepTitle>
              Add to your Jules account
            </WizardStepTitle>
            <WizardStepDescription>
              You can add a new item, property, employee access, or create a new external account connection.
            </WizardStepDescription>
          </WizardStepHead>

          <WizardContentColumn>
            <WizardStepContent>
              <div style={{'paddingBottom': '30px'}}>
                <Typography variant="lead">
                  What would you like to add to your Jules account?
                </Typography>
              </div>
              <NavLink to={`${match.path}/property`} style={{'textDecoration': 'none'}}>
                <WizardTypeTile
                  data-test-id="property-wizard-tile"
                  icon={<HomeRoundedIcon/>}
                  name="Property"
                  description="Add a property to your Jules account."
                />
              </NavLink>
              <NavLink to={`${match.path}/item`} style={{'textDecoration': 'none'}}>
                <WizardTypeTile
                  data-test-id="item-wizard-tile"
                  icon={<ListAltRoundedIcon/>}
                  name="Item"
                  description="Add a new item to one of your Jules records."
                />
              </NavLink>
              <NavLink to={`${match.path}/connection`} style={{'textDecoration': 'none'}}>
                <WizardTypeTile
                  data-test-id="connection-wizard-tile"
                  icon={<ArrowForwardIcon/>}
                  name="Connection"
                  description="Provide/gain access to an external Jules account."
                />
              </NavLink>
            </WizardStepContent>
          </WizardContentColumn>
        </WizardStep>
        <Switch>
          <Route
            exact
            path={`${match.path}/property`}>
            <AddPropertyWizard
              onClose={() => {
                history.go(-2);
              }}
              onSwitchWizard={() => {
                history.goBack();
              }}
            />
          </Route>
          <Route
            exact
            path={`${match.path}/item`}
            component={AddItemWizard}>
            <AddItemWizard
              onClose={() => {
                history.go(-2);
              }}
              onSwitchWizard={() => {
                history.goBack();
              }}
            />
          </Route>
          <Route
            exact
            path={`${match.path}/connection`}
            component={AddConnectionWizard}>
            <AddConnectionWizard
              onClose={() => {
                history.go(-2);
              }}
              onSwitchWizard={() => {
                history.goBack();
              }}
            />
          </Route>
          <Redirect
            to={`${match.path}`}
          />
        </Switch>
      </Wizard>
    </>
  );
};

export default BusinessAddWizard;
