import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import InlineMessage from 'ui-library/components/InlineMessage';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';

import UnarchiveIcon from '@material-ui/icons/Unarchive';

import {
  conversationPanelNavigationHeight,
  conversationPanelHeaderHeight,
  conversationPanelNoticeHeight,
} from 'constants/layout';

const Root = styled.div`
  margin-top: calc(${conversationPanelNavigationHeight}px + ${conversationPanelHeaderHeight}px);
  position: fixed;
  width: 85vw;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media (min-width: 1200px) {
    width: 75vw;
  }
  @media (min-width: 1600px) {
    width: 60vw;
  }
  @media (min-width: 1920px) {
    width: 50vw;
  }
`;

const NoticeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConversationStatusNotice = ({
  onClick,
  status,
}) => {
  switch (status) {
    case 'archived':
      return (
        <Root>
          <InlineMessage
            variant="warning"
            open={true}
            persistent={true}
            borderType="flat"
            padding={'8px 50px'}
            height={conversationPanelNoticeHeight}
            messageBody={
              <NoticeContainer>
                <Typography variant="caption">
                This conversation is archived. You need to unarchive it in order to post a comment.
                </Typography>

                <Button
                  variant="custom"
                  textTransform="uppercase"
                  customBgColor="warning-light"
                  customTextColor="black"
                  customHoverBgColor="warning"
                  customHoverTextColor="black"
                  customActiveBgColor="warning-darker"
                  customActiveTextColor="black"
                  endIcon={<UnarchiveIcon />}
                  onClick={onClick}
                >
                  Unarchive
                </Button>
              </NoticeContainer>
            }
          />
        </Root>
      );
    case 'frozen':
      return (
        <Root>
          <InlineMessage
            variant="error"
            open={true}
            persistent={true}
            borderType="flat"
            padding={'8px 50px'}
            height={conversationPanelNoticeHeight}
            messageBody={
              <Typography variant="caption">
                This conversation is frozen. No comments can be added.
              </Typography>
            }
          />
        </Root>
      );
    default:
      return null;
  }
};

ConversationStatusNotice.propTypes = {
  onClick: PropTypes.func,
};

export default ConversationStatusNotice;
