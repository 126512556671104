import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import Typography from 'ui-library/components/Typography';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MUITextField from '@material-ui/core/TextField';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {
  tagValidator,
} from 'utils/validator';

const useStyles = makeStyles({
  root: {
    display: theme.addTag.root.display,
  },
  viewMode: {
    display: theme.addTag.viewMode.display,
    padding: theme.addTag.viewMode.padding,
    border: theme.addTag.viewMode.border,
    borderRadius: theme.addTag.viewMode.borderRadius,
    transition: theme.addTag.viewMode.transition,
    '&:hover': {
      border: theme.addTag.viewMode.hoverBorder,
    },
    color: theme.addTag.viewMode.color,
    alignItems: theme.addTag.viewMode.alignItems,
    justifyContent: theme.addTag.viewMode.justifyContent,
    cursor: theme.addTag.viewMode.cursor,
  },
  icon: {
    width: theme.addTag.icon.width,
    height: theme.addTag.icon.height,
    marginRight: theme.addTag.icon.marginRight,
  },
  ac: {
    '& .MuiFormControl-marginNormal': {
      margin: theme.addTagAutocomplete.margin,
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.addTagAutocomplete.padding,
    },

    '& .MuiAutocomplete-inputRoot': {
      fontSize: theme.addTagAutocomplete.inputRoot.fontSize,
      padding: theme.addTagAutocomplete.inputRoot.padding,
      height: theme.addTagAutocomplete.inputRoot.height,
      backgroundColor: theme.addTagAutocomplete.inputRoot.backgroundColor,
      borderRadius: theme.addTagAutocomplete.inputRoot.borderRadius,
      width: theme.addTagAutocomplete.inputRoot.width,
    },

    '& .MuiAutocomplete-endAdornment': {
      display: theme.addTagAutocomplete.endAdornment.display,
    },
    '& .MuiInputBase-root:hover': {
      boxShadow: theme.addTagAutocomplete.boxShadow,
    },
    '& .MuiInput-underline': {
      '&:before': {
        border: theme.addTagAutocomplete.border,
      },
      '&:after': {
        border: theme.addTagAutocomplete.border,
      },
      '&:hover:not(.Mui-disabled):before': {
        border: theme.addTagAutocomplete.border,
      },
    },
  },
  acTextField: {
    '& input': {
      fontSize: theme.addTagAutocomplete.textField.fontSize,
      padding: theme.addTagAutocomplete.textField.padding,
      color: theme.addTagAutocomplete.textField.color,
      fontWeight: theme.addTagAutocomplete.textField.fontWeight,
    },

    // boxShadow on input hover
    '& .MuiInputBase-root:hover': {
      boxShadow: theme.addTagAutocomplete.boxShadow,
    },

    // border
    '& .MuiInput-underline': {
      '&:before': {
        border: theme.addTagAutocomplete.border,
      },
      '&:after': {
        border: theme.addTagAutocomplete.border,
      },
      '&:hover:not(.Mui-disabled):before': {
        border: theme.addTagAutocomplete.border,
      },
    },
  },
  iconSelected: {
    width: theme.autocomplete.iconSelected.width,
    height: theme.autocomplete.iconSelected.height,
    margin: theme.autocomplete.iconSelected.margin,
    color: theme.autocomplete.iconSelected.color,
  },
  text: {
    flexGrow: theme.autocomplete.text.flexGrow,
    textTransform: theme.autocomplete.option.textTransform,
  },
  iconContainer: {
    display: theme.addTagAutocomplete.iconContainer.display,
    height: theme.addTagAutocomplete.iconContainer.height,
    width: theme.addTagAutocomplete.iconContainer.width,
    alignItems: theme.addTagAutocomplete.iconContainer.alignItems,
    justifyContent: theme.addTagAutocomplete.iconContainer.justifyContent,
    color: theme.addTagAutocomplete.iconContainer.color,
    margin: theme.addTagAutocomplete.iconContainer.margin,
  },
  closeIconContainer: {
    display: theme.addTagAutocomplete.iconContainer.display,
    height: theme.addTagAutocomplete.iconContainer.height,
    width: theme.addTagAutocomplete.iconContainer.width,
    alignItems: theme.addTagAutocomplete.iconContainer.alignItems,
    justifyContent: theme.addTagAutocomplete.iconContainer.justifyContent,
    color: theme.addTagAutocomplete.iconContainer.color,
    margin: theme.addTagAutocomplete.iconContainer.margin,
    cursor: theme.addTagAutocomplete.iconContainer.cursor,
  },
  acContainer: {
    display: theme.addTagAutocomplete.acContainer.display,
    alignItems: theme.addTagAutocomplete.acContainer.alignItems,
    justifyContent: theme.addTagAutocomplete.acContainer.justifyContent,
    width: theme.addTagAutocomplete.acContainer.width,
    height: theme.addTagAutocomplete.acContainer.height,
    backgroundColor: theme.addTagAutocomplete.acContainer.backgroundColor,
    borderRadius: theme.addTagAutocomplete.acContainer.borderRadius,
  },
});

const AddTag = ({
  tags,
  nodeTags,
  tagAcOptions,
  clearTagAcOptions,
  tagAcInputValue,
  onTagAcInputChange,
  addNodeTag,
  newTag,
}) => {

  const [addTagMode, setAddTagMode] = React.useState(false);

  const classes = useStyles();

  const acOptions = newTag ? [
    ...tagAcOptions,
    {
      name: newTag,
    },
  ] : tagAcOptions;

  return (
    <div className={classes.root}>
      {
        addTagMode ?
          <div className={classes.acContainer}>
            <div className={classes.iconContainer}>
              <AddRoundedIcon style={{
                height: 16,
                width: 16,
              }}/>
            </div>
            <MUIAutocomplete
              data-test-id="add-tag-autocomplete"
              multiple={false}
              open={addTagMode}
              className={classes.ac}
              autoFocus={true}
              options={acOptions}
              getOptionLabel={(option) => option.name}
              renderTags={() => null}
              onChange={(ev, value) => {
                const {
                  name,
                } = value;

                const aux = name.trim();

                if (aux.includes(' (New Tag)')) {
                  const cleanName = aux.split(' (New Tag)')[0];
                  addNodeTag(cleanName);
                } else {
                  addNodeTag(name);
                }

                setAddTagMode(false);
                clearTagAcOptions();
              }}
              onBlur={() => {
                setAddTagMode(false);
                clearTagAcOptions();
              }}
              noOptionsText={'No Results'}
              renderInput={params => (
                <MUITextField
                  {...params}
                  value={tagAcInputValue}
                  data-test-id="filter-autocomplete-input"
                  className={classes.acTextField}
                  variant="standard"
                  size="medium"
                  fullWidth={true}
                  autoFocus
                  onKeyPress={(ev) => {
                    const tagIsValid = !tagValidator(`${tagAcInputValue}${ev.key}`);
                    if (!tagIsValid) {
                      ev.preventDefault();
                    }
                  }}
                  onChange={onTagAcInputChange}
                />
              )}
            />
            <div
              className={classes.closeIconContainer}
              data-test-id="add-tag-close-button"
            >
              <CloseRoundedIcon style={{
                height: 16,
                width: 16,
              }}/>
            </div>
          </div>
          :
          <div
            data-test-id="add-tag-button"
            className={classes.viewMode}
            onClick={() => {
              setAddTagMode(true);
            }}
          >
            <AddRoundedIcon
              className={classes.icon}
            />
            <Typography
              variant="caption"
              color="primary"
            >
              Add Tag
            </Typography>
          </div>
      }
    </div>
  );
};

AddTag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object),
  nodeTags: PropTypes.arrayOf(PropTypes.object),
  tagAcOptions: PropTypes.arrayOf(PropTypes.object),
  clearTagAcOptions: PropTypes.func,
  inputValue: PropTypes.string,
  onTagAcInputChange: PropTypes.func,
  addNodeTag: PropTypes.func,
  newTag: PropTypes.string,
};

export default AddTag;
