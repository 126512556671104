// returns a config that also provides a "Content-Type" header

export default function configWithContentType(config, contentType) {
  return {
    ...config,
    headers: {
      ...config.headers,
      'Content-Type': contentType,
    },
  };
}
