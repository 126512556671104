import React from 'react';
import styled from '@emotion/styled';

import Typography from 'ui-library/components/Typography';

import LoginRedirect from 'components/SignUp/LeftPanel/LoginRedirect';

const LeftPanelRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  text-align: right;
`;

const BusinessAccount = () => {

  return (
    <>
      <LeftPanelRow>
        <Typography
          variant="h1"
          fontWeight="bold"
          fontFamily="primary"
          color="grey"
        >
            SIGN-UP
        </Typography>
      </LeftPanelRow>
      <LeftPanelRow>
        <Typography
          variant="x-small"
          fontWeight="bold"
          fontFamily="primary"
          color="grey"
        >
            FOR A JULES BUSINESS ACCOUNT
        </Typography>
      </LeftPanelRow>
      <br/>
      <br/>
      <LeftPanelRow>
        <Typography
          variant="h4"
          fontWeight="normal"
          fontFamily="primary"
        >
          Jules establishes a powerful collaboration platform to provide solutions to your everyday business concerns.
        </Typography>
      </LeftPanelRow>
      <br/>
      <br/>
      <LeftPanelRow>
        <LoginRedirect />
      </LeftPanelRow>
    </>
  );

};

export default BusinessAccount;
