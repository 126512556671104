import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/DoneRounded';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDownRounded';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MUISelect from '@material-ui/core/Select';

const useStyles = makeStyles({
  selectControl: {
    '& .MuiSelect-root': {
      // background color
      backgroundColor: theme.select.background.activeColor,

      // boxShadow on input hover
      '&:hover': {
        boxShadow: props => props.disabled ? theme.select.inputBase.disabledBoxShadow : theme.select.inputBase.activeBoxShadow,
      },
    },
  },

  formControl: {
    // label
    '& .MuiFormLabel-root': {
      color: props => props.error ? theme.select.label.error : theme.select.label.default,
      // label focused
      '&.Mui-focused': {
        color: props => props.error ? theme.select.label.error : theme.select.label.focused,
      },
    },

    // bg color
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
      '&.Mui-focused': {
        backgroundColor: theme.select.background.focusedColor,
        boxShadow: props => props.disabled ? theme.select.inputBase.disabledBoxShadow : theme.select.inputBase.activeBoxShadow,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.select.background.disabledColor,
      },
    },

    // border
    '& .MuiFilledInput-underline': {
      '&:before': {
        borderBottom: props => props.disabled ? theme.select.border.disabled : props.error ? theme.select.border.error : theme.select.border.defaultDisabled,
      },
      '&:after': {
        borderBottom: props => props.disabled ? theme.select.border.disabled : props.error ? theme.select.border.error : theme.select.border.defaultActive,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: props => props.error ? theme.select.border.error : theme.select.border.hover,
      },
    },

    // helper text
    '& .MuiFormHelperText-root': {
      color: props => props.error ? theme.select.label.error : theme.select.label.default,
    },
  },
  values: theme.select.values,
  chip: theme.select.chip,
  doneIcon: theme.select.doneIcon,
  iconWrap: theme.select.iconWrap,
  menuItem: theme.select.menuItem,
  selected: theme.select.selected,
});

const Select = ({
  autoFocus,
  label,
  helperText,
  error,
  value,
  options,
  disabled,
  required,
  onChange,
  onBlur,
  onFocus,
  onMouseDown,
  onExited,
  multiple,
  placeholder,
  customOptions,
  'data-test-id': dataTestId,
}) => {

  const classes = useStyles({
    disabled,
    error,
  });

  const availableOptions = customOptions ? customOptions : options.map(option => {
    return {
      label: option,
      value: option,
    };
  });

  const getLabel = (value) => {
    return availableOptions.filter(option => option.value === value)[0].label;
  };

  const menuItems = availableOptions.map((option, index) => {
    const isSelected = multiple ? value.find(selected => selected === option.value) : value === option.value;
    return (
      <MenuItem
        key={index}
        value={option.value}
        selected
        classes={{
          root: classes.menuItem,
          selected: classes.selected,
        }}
      >
        {option.label}
        {
          isSelected &&
            <div className={classes.iconWrap}>
              <DoneIcon className={classes.doneIcon}/>
            </div>
        }
      </MenuItem>
    );
  });

  return (
    <FormControl
      variant="filled"
      className={classes.formControl}
      error={error}
      required={required}
      disabled={disabled}
      fullWidth={true}
    >
      <InputLabel shrink={true}>{label}</InputLabel>
      <MUISelect
        data-test-id={dataTestId}
        autoFocus={autoFocus}
        fullWidth={true}
        className={classes.selectControl}
        multiple={multiple}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        displayEmpty
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        renderValue={selected => {
          if (multiple) {
            if (selected.length === 0) {
              return 'Placeholder';
            }
            return (
              <div className={classes.values}>
                {selected.map((value, index) => (
                  <Chip key={index} label={getLabel(value)} className={classes.chip}/>
                ))}
              </div>
            );
          }
          if (selected === '') {
            return placeholder;
          }
          return  <div className={classes.values}>{getLabel(selected)}</div>;
        }}
        IconComponent={KeyboardArrowDown}
        MenuProps={{
          onExited: () => {
            typeof onExited === 'function' && onExited();
          },
          PaperProps: {
            style: {
              maxHeight: theme.select.paperProps.maxHeight,
              width: theme.select.paperProps.width,
            },
          },
        }}
      >
        {menuItems}
      </MUISelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Select.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  customOptions: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  onExited:PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  'data-test-id': PropTypes.string,
};

export default Select;
