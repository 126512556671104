import GET__records_ID_permissionGrants from 'network-layer/endpoints/GET__records_ID_permissionGrants';

function responseAdapter(response) {
  return response;
}

export default async function genRecordsPermissionGrants(authToken, perspectiveId, recordId) {
  const response = await GET__records_ID_permissionGrants(authToken, perspectiveId, recordId);

  return responseAdapter(response);
}
