import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspectiveWithoutDefaultHeaders from '../config/configWithAuthorizationAndPerspectiveWithoutDefaultHeaders';
import {GET} from '../verbs';

export default async function GET__imageThumbnails_SIZE_FILENAME(authToken, perspectiveId, size, fileName) {

  const response = await GET(
    `/image-thumbnails/${size}/${fileName}`,
    configWithAuthorizationAndPerspectiveWithoutDefaultHeaders(defaultConfig(), authToken, perspectiveId),
  );

  return response;
}
