import endpointURL from '../util/endpointURL';
import successBody from '../util/successBody';

export default async function GET(
  endpoint,
  config,
) {
  const response = await fetch(
    endpointURL(endpoint),
    {
      ...config,
      method: 'GET',
    },
  );

  return await successBody(response);
}
