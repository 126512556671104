import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Grid from '@material-ui/core/Grid';

import Typography from 'ui-library/components/Typography';

import PermissionGrant from 'components/Permissions/PermissionGrant';
import PermissionGrantInformation from 'components/PermissionGrantInformation';

import genNodesPermissionGrants from 'services/Nodes/genNodesPermissionGrants';

import parseNodePermissions from 'utils/permissions/parseNodePermissions';
import nodeFrontendPermissionLabel from 'utils/permissions/nodeFrontendPermissionLabel';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const PermissionGrantsHeadingRoot = styled.div`
  padding: 0px 20px 12px 20px;
`;

const NodePermissionsTab = ({
  nodeState,
  dispatchNodeAction,
  dispatchUserInterfaceAction,
  setError,
}) => {
  const {
    nodePermissions,
    node: {
      id: nodeId,
      name: nodeName,
    },
    inboundGrants,
    outboundGrants,
    downstreamGrants,
  } = nodeState;

  const [tabState, setTabState] = React.useState('not loaded'); // 'not loaded', 'loading', 'loaded'

  const {
    authToken,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  const {
    reloadItemPermissionGrants,
  } = React.useContext(UserInterfaceContext);

  const loadPermissions = React.useCallback(async() => {
    try {
      setTabState('loading');

      const nodePermissionGrants = await genNodesPermissionGrants(authToken, currentPerspectiveId, nodeId);

      const nodeInboundGrants = nodePermissionGrants.filter(grant => grant.grantLocality === 'inbound').map(grant => {
        const {
          permissions,
        } = grant;

        return {
          ...grant,
          parsedPermissions: parseNodePermissions(permissions),
        };
      });
      dispatchNodeAction({
        type: 'SET_INBOUND_GRANTS',
        payload: nodeInboundGrants,
      });


      const nodeOutboundGrants = nodePermissionGrants.filter(grant => grant.grantLocality === 'outbound').map(grant => {
        const {
          permissions,
        } = grant;

        return {
          ...grant,
          parsedPermissions: parseNodePermissions(permissions),
        };
      });
      dispatchNodeAction({
        type: 'SET_OUTBOUND_GRANTS',
        payload: nodeOutboundGrants,
      });

      const nodeDownstreamGrants = nodePermissionGrants.filter(grant => grant.grantLocality === 'downstream').map(grant => {
        const {
          permissions,
        } = grant;

        return {
          ...grant,
          parsedPermissions: parseNodePermissions(permissions),
        };
      });
      dispatchNodeAction({
        type: 'SET_DOWNSTREAM_GRANTS',
        payload: nodeDownstreamGrants,
      });

      dispatchUserInterfaceAction({
        type: 'SET_RELOAD_ITEM_PERMISSION_GRANTS',
        payload: false,
      });

      setTabState('loaded');
    } catch (e) {
      setError(e);
    }
  }, [authToken, currentPerspectiveId, dispatchNodeAction, dispatchUserInterfaceAction, nodeId, setError]);

  React.useEffect(() => {
    if (tabState !== 'loaded' || reloadItemPermissionGrants) {
      loadPermissions();
    }
  }, [loadPermissions, reloadItemPermissionGrants, tabState]);

  if (!nodePermissions) {
    return null;
  }

  const getPermissionGrantExplanatoryText = (grant) => {
    const {
      grantSpecificity,
      grantLocality,
      granterName,
      granteeName,
      grantedViaRecordName,
    } = grant;
    switch (grantSpecificity) {
      case 'explicit':
        switch (grantLocality) {
          case 'inbound':
            return `${granterName} shared this item with you with these permissions.`;
          case 'outbound':
            return `You shared this item with ${granteeName} with these permissions.`;
          case 'downstream':
            return `${granteeName} received access to this item from ${granterName} with these permissions.`;
          default:
            return null;
        }
      case 'implicit':
        switch (grantLocality) {
          case 'inbound':
            return `You have these permissions for this item because of the permissions you were granted for ${grantedViaRecordName}.`;
          case 'outbound':
            return `${granteeName} has these permissions for this item because of the permissions you provided for ${grantedViaRecordName}.`;
          case 'downstream':
            return `${granteeName} has these permissions for this item because ${granterName} provided them access to ${grantedViaRecordName}.`;
          default:
            return null;
        }
      case 'direct creator':
        switch (grantLocality) {
          case 'inbound':
            return 'You have been granted these permissions because you have created this item.';
          case 'outbound':
          case 'downstream':
            return `${granteeName} has these permissions for this item because they’ve created it.`;
          default:
            return null;
        }
      case 'indirect creator':
        switch (grantLocality) {
          case 'inbound':
            return 'You have been granted these permissions because one of your partners has created the item.';
          case 'outbound':
          case 'downstream':
            return `${granteeName} has these permissions for this item because one of their partners created it.`;
          default:
            return null;
        }
      default:
        return null;
    }
  };

  return (
    <PaddedContainer>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          style={{
            cursor: 'pointer',
            flexGrow: 1,
            paddingTop: 40,
          }}
        >
          {/* INBOUND GRANTS */}
          {
            inboundGrants.length > 0 &&
            <>
              <PermissionGrantsHeadingRoot>
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  fontFamily="primary"
                  color="tertiary">
                  PERMISSIONS RECEIVED FROM
                </Typography>
              </PermissionGrantsHeadingRoot>
              {
                inboundGrants.map((grant, index) => {
                  const {
                    parsedPermissions: {
                      frontendPermissions,
                    },
                  } = grant;
                  const activePermissions = Object.keys(frontendPermissions).filter(key => frontendPermissions[key]).map(key => nodeFrontendPermissionLabel(key));
                  return (
                    <PermissionGrant
                      key={index}
                      title={grant.grantedViaConnectionName}
                      subTitle={grant.granterName}
                    >
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        fontFamily="primary"
                        color="grey"
                      >
                        PERMISSIONS
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="normal"
                        fontFamily="primary"
                        color="grey">
                        {
                          activePermissions.join(', ')
                        }
                      </Typography>
                      <PermissionGrantInformation
                        label='Why does my Organization have permissions on this item?'
                        dismissLabel='Dismiss'
                        explanatoryText={getPermissionGrantExplanatoryText(grant)}
                      />
                    </PermissionGrant>
                  );
                })
              }
            </>
          }

          {/* OUTBOUND GRANTS */}
          {
            outboundGrants.length > 0 &&
            <>
              <PermissionGrantsHeadingRoot>
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  fontFamily="primary"
                  color="tertiary">
                  PARTNER PERMISSIONS
                </Typography>
              </PermissionGrantsHeadingRoot>
              {
                outboundGrants.map((grant, index) => {
                  const {
                    parsedPermissions: {
                      frontendPermissions,
                      atomicPermissions,
                    },
                    granteeName,
                    grantedViaConnectionName,
                    grantedViaRecordName,
                    grantedViaConnectionId,
                  } = grant;
                  const activePermissions = Object.keys(frontendPermissions).filter(key => frontendPermissions[key]).map(key => nodeFrontendPermissionLabel(key));
                  return (
                    <PermissionGrant
                      key={index}
                      title={grantedViaConnectionName}
                      subTitle={granteeName}
                      menuOptions={
                        grantedViaRecordName ?
                          []
                          :
                          [
                            {
                              label: 'Edit Permissions',
                              onClick: () => {
                                dispatchNodeAction({
                                  type: 'SET_NODE_GRANT_FRONTEND_PERMISSIONS',
                                  payload: frontendPermissions,
                                });
                                dispatchNodeAction({
                                  type: 'SET_NODE_GRANT_ATOMIC_PERMISSIONS',
                                  payload: atomicPermissions,
                                });
                                dispatchNodeAction({
                                  type: 'SET_NODE_GRANT_CONNECTION',
                                  payload: grantedViaConnectionId,
                                });
                                dispatchUserInterfaceAction({
                                  type: 'SET_NODE_PANEL_DISPLAY_MODE',
                                  payload: 'editPermissions',
                                });
                              },
                            },
                            {
                              label: 'Stop Sharing',
                              onClick: () => {
                                dispatchUserInterfaceAction({
                                  type: 'SET_STOP_SHARING_NODE_ID',
                                  payload: nodeId,
                                });
                                dispatchUserInterfaceAction({
                                  type: 'SET_STOP_SHARING_NODE_NAME',
                                  payload: nodeName,
                                });
                                dispatchUserInterfaceAction({
                                  type: 'SET_STOP_SHARING_NODE_CONNECTION_ID',
                                  payload: grantedViaConnectionId,
                                });
                                dispatchUserInterfaceAction({
                                  type: 'SET_STOP_SHARING_NODE_CONNECTION_NAME',
                                  payload: grantedViaConnectionName,
                                });
                                dispatchUserInterfaceAction({
                                  type: 'SET_SHOW_NODE_PANEL',
                                  payload: false,
                                });
                                dispatchUserInterfaceAction({
                                  type: 'SET_SHOW_STOP_SHARING_NODE_DIALOG',
                                  payload: true,
                                });
                              },
                            },
                          ]
                      }
                    >
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        fontFamily="primary"
                        color="grey">
                        PERMISSIONS
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="normal"
                        fontFamily="primary"
                        color="grey">
                        {
                          activePermissions.join(', ')
                        }
                      </Typography>
                      <PermissionGrantInformation
                        label='Why does this Organization have these permissions for this item?'
                        dismissLabel='Dismiss'
                        explanatoryText={getPermissionGrantExplanatoryText(grant)}
                      />
                    </PermissionGrant>
                  );
                })
              }
            </>
          }

          {/* DOWNSTREAM GRANTS */}
          {
            downstreamGrants.length > 0 &&
            <>
              <PermissionGrantsHeadingRoot>
                <Typography
                  variant="caption"
                  fontWeight="bold"
                  fontFamily="primary"
                  color="tertiary">
                  3RD PARTY PERMISSIONS
                </Typography>
              </PermissionGrantsHeadingRoot>
              {
                downstreamGrants.map((grant, index) => {
                  const {
                    parsedPermissions: {
                      frontendPermissions,
                    },
                    granteeName,
                  } = grant;
                  const activePermissions = Object.keys(frontendPermissions).filter(key => frontendPermissions[key]).map(key => nodeFrontendPermissionLabel(key));
                  return (
                    <PermissionGrant
                      key={index}
                      title={granteeName}
                    >
                      <Typography
                        variant="caption"
                        fontWeight="bold"
                        fontFamily="primary"
                        color="grey">
                        PERMISSIONS
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight="normal"
                        fontFamily="primary"
                        color="grey">
                        {
                          activePermissions.join(', ')
                        }
                      </Typography>
                      <PermissionGrantInformation
                        label='Why does this Organization have these permissions for this item?'
                        dismissLabel='Dismiss'
                        explanatoryText={getPermissionGrantExplanatoryText(grant)}
                      />
                    </PermissionGrant>
                  );
                })
              }
            </>
          }
        </Grid>
      </Grid>
    </PaddedContainer>
  );
};

NodePermissionsTab.propTypes = {
  expandedNodeId: PropTypes.number,
};

export default NodePermissionsTab;
