import endpointURL from '../util/endpointURL';
import successBody from '../util/successBody';

export default async function DELETE(
  endpoint,
  config,
  body,
) {
  const response = await fetch(
    endpointURL(endpoint),
    {
      ...config,
      method: 'DELETE',
      body: body ? JSON.stringify(body) : undefined,
    },
  );

  return await successBody(response);
}
