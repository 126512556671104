function initSubscriptionState() {
  return {
    currentSubscriptionName: null,
    currentSubscriptionDescription: null,
    currentSubscriptionStatus: null,
    currentSubscriptionPrice: null,
    currentSubscriptionCycleDuration: null,
    currentSubscriptionRemainingDays: null,
    currentSubscriptionIsFreeTrial: null,
    currentSubscriptionIsRenewable: null,
    currentSubscriptionAutoRenew: null,
    currentSubscriptionCycleDurationInYears: null,

    creditCardId: null,
    creditCardBrand: null,
    creditCardLast4: null,
    creditCardExpirationDate: null,

    paymentInvoicesById: {},
    paymentInvoiceIds: [],
  };
};

function subscriptionReducer(state, {type: actionType, payload}) {
  switch (actionType) {

    case 'HYDRATE_CURRENT_SUBSCRIPTION': {
      const {
        name: currentSubscriptionName,
        description: currentSubscriptionDescription,
        status: currentSubscriptionStatus,
        price: currentSubscriptionPrice,
        cycleDuration: currentSubscriptionCycleDuration,
        remainingDays: currentSubscriptionRemainingDays,
        isFreeTrial: currentSubscriptionIsFreeTrial,
        isRenewable: currentSubscriptionIsRenewable,
        autoRenew: currentSubscriptionAutoRenew,
        autoRenewDate: currentSubscriptionAutoRenewDate,
      } = payload;

      return {
        ...state,
        currentSubscriptionName,
        currentSubscriptionDescription,
        currentSubscriptionStatus,
        currentSubscriptionPrice,
        currentSubscriptionCycleDuration,
        currentSubscriptionRemainingDays,
        currentSubscriptionIsFreeTrial,
        currentSubscriptionIsRenewable,
        currentSubscriptionAutoRenew,
        currentSubscriptionAutoRenewDate,
        currentSubscriptionCycleDurationInYears: parseInt(currentSubscriptionCycleDuration / 365),
      };
    }

    case 'HYDRATE_CREDIT_CARD': {
      if (payload === null) {
        return {
          ...state,
          creditCardId: null,
          creditCardBrand: null,
          creditCardLast4: null,
          creditCardExpirationDate: null,
        };
      }

      const {
        id: creditCardId,
        brand: creditCardBrand,
        last4: creditCardLast4,
        expirationDate: creditCardExpirationDate,
      } = payload;

      return {
        ...state,
        creditCardId,
        creditCardBrand,
        creditCardLast4,
        creditCardExpirationDate,
      };
    }

    case 'HYDRATE_PAYMENT_HISTORY': {
      const paymentInvoicesById = {};
      const paymentInvoiceIds = [];

      for (let paymentInvoice of payload) {
        const {
          id,
        } = paymentInvoice;
        paymentInvoicesById[id] = paymentInvoice;
        paymentInvoiceIds.push(id);
      }

      return {
        ...state,
        paymentInvoicesById,
        paymentInvoiceIds,
      };
    }

    case 'SET_CURRENT_SUBSCRIPTION_AUTORENEW': {
      return {
        ...state,
        currentSubscriptionAutoRenew: payload,
      };
    }

    default:
      throw new Error(`Unknown subscription action "${actionType}"!`);

  }
};

export {
  initSubscriptionState,
  subscriptionReducer,
};
