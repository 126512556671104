import GET__connections_ID from 'network-layer/endpoints/GET__connections_ID';

function responseAdapter(response) {
  return response;
}

export default async function genConnectionByConnectionId(authToken, perspectiveId, connectionId) {
  const response = await GET__connections_ID(authToken, perspectiveId, connectionId);

  return responseAdapter(response);
}
