import recordPermissionsIntToAtomicPermissions from './recordPermissionsIntToAtomicPermissions';
import recordAtomicPermissionsToFrontendPermissions from './recordAtomicPermissionsToFrontendPermissions';
import {
  RECORD_FRONTEND_PERMISSIONS,
} from './PERMISSION_FLAGS';

function parseRecordPermissions(permissionsInt) {
  const atomicPermissions = recordPermissionsIntToAtomicPermissions(permissionsInt);
  const frontendPermissions = recordAtomicPermissionsToFrontendPermissions(atomicPermissions);

  return {
    frontendPermissions,
    atomicPermissions,
    recordFrontendPermissionFlags: RECORD_FRONTEND_PERMISSIONS,
  };
}

export default parseRecordPermissions;
