import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {GET} from '../verbs';

export default async function GET__notifications(authToken, params) {
  let url = '/notifications';
  if (params) {
    url += '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&');
  }

  return await GET(
    url,
    configWithAuthorization(defaultConfig(), authToken),
  );
}
