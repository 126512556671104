import addType from 'services/ControlPanel/addType';
import updateType from 'services/ControlPanel/updateType';

import addFieldDefinitionToType from 'services/ControlPanel/addFieldDefinitionToType';
import genFieldDefinitions from 'services/ControlPanel/genFieldDefinitions';
import removeFieldDefinition from 'services/ControlPanel/removeFieldDefinition';
import updateFieldDefinition from 'services/ControlPanel/updateFieldDefinition';

import addTypeToRecordType from 'services/ControlPanel/addTypeToRecordType';
import genTypeUsageInRecordType from 'services/ControlPanel/genTypeUsageInRecordType';
import removeTypeFromRecordType from 'services/ControlPanel/removeTypeFromRecordType';

import createPropertyConfigurationHint from 'services/ControlPanel/createPropertyConfigurationHint';
import updatePropertyConfigurationHint from 'services/ControlPanel/updatePropertyConfigurationHint';
import deletePropertyConfigurationHint from 'services/ControlPanel/deletePropertyConfigurationHint';

import genTypeLocationGraph from 'services/ControlPanel/genTypeLocationGraph';
import genTypeLocationDescendants from 'services/ControlPanel/genTypeLocationDescendants';
import createTypeLocationGraphEdge from 'services/ControlPanel/createTypeLocationGraphEdge';
import deleteTypeLocationGraphEdge from 'services/ControlPanel/deleteTypeLocationGraphEdge';

import createNodeLinkDefinition from 'services/ControlPanel/createNodeLinkDefinition';
import updateNodeLinkDefinition from 'services/ControlPanel/updateNodeLinkDefinition';
import deleteNodeLinkDefinition from 'services/ControlPanel/deleteNodeLinkDefinition';
import createTypeRelationship from 'services/ControlPanel/createTypeRelationship';
import deleteTypeRelationship from 'services/ControlPanel/deleteTypeRelationship';

import createVirtualLocationTreeNodeHint from 'services/ControlPanel/createVirtualLocationTreeNodeHint';
import deleteVirtualLocationTreeNodeHint from 'services/ControlPanel/deleteVirtualLocationTreeNodeHint';

import createNotificationPipeline from 'services/ControlPanel/createNotificationPipeline';
import genNotificationPipelines from 'services/ControlPanel/genNotificationPipelines';
import updateNotificationPipeline from 'services/ControlPanel/updateNotificationPipeline';
import deleteNotificationPipeline from 'services/ControlPanel/deleteNotificationPipeline';
import createFieldWatcher from 'services/ControlPanel/createFieldWatcher';
import genFieldWatchers from 'services/ControlPanel/genFieldWatchers';
import updateFieldWatcher from 'services/ControlPanel/updateFieldWatcher';
import deleteFieldWatcher from 'services/ControlPanel/deleteFieldWatcher';
import addFieldDefinitionIdToFieldWatcher from 'services/ControlPanel/addFieldDefinitionIdToFieldWatcher';
import removeFieldDefinitionIdFromFieldWatcher from 'services/ControlPanel/removeFieldDefinitionIdFromFieldWatcher';

import genUserOrganizations from 'services/ControlPanel/genOrganizations';
import genOrganizationSubscriptions from 'services/ControlPanel/genSubscriptions';
import genCurrentSubscription from 'services/ControlPanel/genCurrentSubscription';
import updateCurrentSubscription from 'services/ControlPanel/updateCurrentSubscription';
import changeEmail from 'services/ControlPanel/changeEmail';
import genUserProperties from 'services/ControlPanel/genUserProperties';
import transferProperty from 'services/ControlPanel/transferProperty';

import addTypeToEhrHub from 'services/ControlPanel/addTypeToEhrHub';
import removeTypeFromEhrHub from 'services/ControlPanel/removeTypeFromEhrHub';

export default {
  addType,
  updateType,

  addFieldDefinitionToType,
  genFieldDefinitions,
  removeFieldDefinition,
  updateFieldDefinition,

  addTypeToRecordType,
  genTypeUsageInRecordType,
  removeTypeFromRecordType,

  createPropertyConfigurationHint,
  updatePropertyConfigurationHint,
  deletePropertyConfigurationHint,

  genTypeLocationGraph,
  genTypeLocationDescendants,
  createTypeLocationGraphEdge,
  deleteTypeLocationGraphEdge,

  createNodeLinkDefinition,
  updateNodeLinkDefinition,
  deleteNodeLinkDefinition,
  createTypeRelationship,
  deleteTypeRelationship,

  createVirtualLocationTreeNodeHint,
  deleteVirtualLocationTreeNodeHint,

  createNotificationPipeline,
  genNotificationPipelines,
  updateNotificationPipeline,
  deleteNotificationPipeline,
  createFieldWatcher,
  genFieldWatchers,
  updateFieldWatcher,
  deleteFieldWatcher,
  addFieldDefinitionIdToFieldWatcher,
  removeFieldDefinitionIdFromFieldWatcher,

  genUserOrganizations,
  genOrganizationSubscriptions,
  genCurrentSubscription,
  updateCurrentSubscription,
  changeEmail,
  genUserProperties,
  transferProperty,

  addTypeToEhrHub,
  removeTypeFromEhrHub,
};
