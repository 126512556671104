import React from 'react';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import Chip from 'ui-library/components/Chip';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const EHRTypeList = ({
  eHRTypes,
  onClickType,
}) => {
  return (
    <div style={{marginTop: '16px'}}>
      {
        eHRTypes.map(type => {
          const {
            typeId,
            typeName,
            count,
          } = type;
          return (
            <ListItem
              key={typeId}
              onClick={(event) => {
                event.stopPropagation();
                onClickType(typeId, typeName, count);
              }}>
              <AddCircleOutlineOutlinedIcon style={{
                color: '#939393',
                margin: '2px 10px 2px 2px',
              }}/>
              <ListItemContentSegment>
                <ListItemText>
                  <Typography
                    variant="small"
                    fontWeight="bold"
                  >
                    {typeName}
                  </Typography>
                </ListItemText>
              </ListItemContentSegment>
              <ListItemContentSegment
                justifyContent="flex-end"
              >
                <ListItemActions>
                  <Chip label={count ? `${count}` : 'not added yet'}/>
                  <Button
                    data-test-id="item-open-record-panel-button"
                    textTransform="none"
                    variant="icon-custom"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={event => {
                      event.stopPropagation();
                      onClickType(typeId, typeName, count);
                    }}>
                    <NavigateNextIcon/>
                  </Button>
                </ListItemActions>
              </ListItemContentSegment>
            </ListItem>
          );
        })
      }
    </div>
  );
};

export default EHRTypeList;
