import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import TimeAgo from 'timeago-react';

const NotificationAge = ({
  children,
}) => {
  return (
    <Typography variant="caption" color="grey">
      <TimeAgo
        datetime={children}
        locale='en-US'
      />
    </Typography>
  );
};

NotificationAge.propTypes = {
  children: PropTypes.string,
};

export default NotificationAge;
