import './Pill.css';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Pill = ({
  variant,
  onClick,
  children,
  ...otherProps
}) => {
  return (
    <div
      {...otherProps}
      onClick={() => {
        if (typeof onClick !== 'function') {
          return;
        }
        onClick();
      }}
      className={classnames(
        'Pill',
        {
          'variant-highlighted': variant === 'highlighted',
          'variant-plain': variant === 'plain',
          'variant-ghost': variant === 'ghost',
        },
      )}>
      {children}
    </div>
  );
};

Pill.propTypes = {
  variant: PropTypes.oneOf(['highlighted', 'plain', 'ghost']).isRequired,
};

export default Pill;
