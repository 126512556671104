import React from 'react';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import PanelIcon from '@material-ui/icons/VerticalSplitRounded';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
// import UserInterfaceContext from 'contexts/UserInterface';

const ConnectionList = ({
  connections,
  onConnectionAction,
}) => {
  const [contextualMenuState, setContextualMenuState] = React.useState({
    id: null,
    anchorEl: null,
  });

  // const {
  //   dispatchUserInterfaceAction,
  // } = React.useContext(UserInterfaceContext);

  return (
    <>
      {
        connections.map(connection => {
          const {
            id,
            name,
            status,
            peerName,
          } = connection;
          return (
            <ListItem
              key={id}
              onClick={() => {
                onConnectionAction(id, 'expand');
              }}>
              <ListItemContentSegment
                weight={1}
              >
                <ListItemText>
                  <Typography
                    variant="small"
                    fontWeight="bold"
                  >
                    {name}
                  </Typography>
                </ListItemText>
              </ListItemContentSegment>
              <ListItemContentSegment
                weight={3}
              >
                <ListItemText>
                  <Typography
                    variant="small"
                    fontStyle={status === 'active' ? 'normal' : 'italic'}
                    color={status === 'active' ? 'grey-darker' : 'grey'}
                  >
                    {status === 'active' ? peerName : 'pending'}
                  </Typography>
                </ListItemText>
              </ListItemContentSegment>
              <ListItemContentSegment
                width={200}
                justifyContent="flex-end"
              >
                <ListItemActions>
                  <Button
                    data-test-id="item-context-menu-button"
                    variant="icon-custom"
                    textTransform="none"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={event => {
                      event.stopPropagation();
                      setContextualMenuState({
                        id,
                        anchorEl: event.currentTarget,
                      });
                    }}
                  >
                    <MoreHorizIcon/>
                  </Button>
                  <Button
                    data-test-id="item-open-connection-panel-button"
                    textTransform="none"
                    variant="icon-custom"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={event => {
                      // dispatchUserInterfaceAction({
                      //   type: 'SET_EXPANDED_CONNECTION_ID',
                      //   payload: id,
                      // });
                      // dispatchUserInterfaceAction({
                      //   type: 'SET_SHOW_CONNECTION_PANEL',
                      //   payload: true,
                      // });
                      event.stopPropagation();
                      onConnectionAction(id, 'expand');
                    }}>
                    <PanelIcon/>
                  </Button>
                  <Button
                    data-test-id="drill-down-connection-button"
                    textTransform="none"
                    variant="icon-custom"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={event => {
                      event.stopPropagation();
                      onConnectionAction(id, 'navigate');
                    }}>
                    <NavigateNextIcon/>
                  </Button>
                </ListItemActions>
              </ListItemContentSegment>
              <Menu
                open={contextualMenuState.id === id}
                anchorEl={contextualMenuState.anchorEl}
                onClose={() => {
                  setContextualMenuState({
                    id: null,
                    anchorEl: null,
                  });
                }}>
                <MenuItem
                  data-test-id="context-menu-view-button"
                  onClick={event => {
                    event.stopPropagation();
                    onConnectionAction(id, 'expand');
                    setContextualMenuState({
                      id: null,
                      anchorEl: null,
                    });
                  }}
                >
                  <Typography variant="small">
                    View Details
                  </Typography>
                </MenuItem>
                <MenuItem
                  data-test-id="context-menu-delete-button"
                  onClick={event => {
                    event.stopPropagation();
                    onConnectionAction(id, 'delete');
                    setContextualMenuState({
                      id: null,
                      anchorEl: null,
                    });
                  }}
                >
                  <Typography variant="small" color="error">
                    Delete Connection
                  </Typography>
                </MenuItem>
              </Menu>
            </ListItem>
          );
        })
      }
    </>
  );
};

export default ConnectionList;
