import isFloat from 'validator/lib/isNumeric';

export default function fieldIsCurrency(
  params,
  errorMessage = 'invalid currency',
) {
  const {
    value,
  } = params;

  if (value === '' || (isFloat(value) && parseFloat(value) <= 9999999999999.99)) {
    return '';
  }
  return errorMessage;
}
