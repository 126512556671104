import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__conversations_CONVERSATIONID_comments(params) {
  const {
    authToken,
    perspectiveId,
    conversationId,
  } = params;

  return await GET(
    `/conversations/${conversationId}/comments`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
