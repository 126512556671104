import GET__records_ID_locationRoots from 'network-layer/endpoints/GET__records_ID_locationRoots';

function responseAdapter(response) {
  return response;
}

export default async function genRecordLocationRoots(authToken, perspectiveId, params) {
  const response = await GET__records_ID_locationRoots(authToken, perspectiveId, params);

  return responseAdapter(response);
}
