import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__types_ID_recordCandidates(authToken, perspectiveId, typeId, connectionId) {
  let url = `/types/${typeId}/record-candidates`;
  if (connectionId) {
    url += `?connectionId=${connectionId}`;
  }
  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
