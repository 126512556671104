import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import Autocomplete from 'components/Autocomplete';
import NoAddPermissionScreen from 'components/AddItemWizard/NoAddPermissionScreen';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genGenericTypesService from 'services/Types/genGenericTypes';

const ItemTypeStep = ({
  active,
  connectionId,
  onSetItemType,
  closeWizardWithPrepend,
}) => {
  const [types, setTypes] = React.useState([]);
  const [itemType, setItemType] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  React.useEffect(() => {
    async function init() {
      setIsLoading(true);
      const types = await genGenericTypesService(
        authToken,
        currentPerspectiveId,
        connectionId,
      );
      setTypes(types);
      setIsLoading(false);
    }
    init();
  }, [authToken, connectionId, currentPerspectiveId]);

  if (!active) {
    return null;
  }

  if (connectionId && types.length === 0 && !isLoading) {
    return (
      <NoAddPermissionScreen
        closeWizardWithPrepend={closeWizardWithPrepend}
      />
    );
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Item
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Type
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Find the item you wish to add to your Jules Item Library
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        {
          isLoading ?
            <CenterpieceSpinner /> :
            <form onSubmit={(event) => {
              event.preventDefault();
              if (!itemType) {
                return;
              }
              const {
                id: typeId,
                name: typeName,
              } = itemType;
              onSetItemType({
                typeId,
                typeName,
              });
            }}>
              <WizardStepContent>
                <Typography variant="lead">
                  Search the &nbsp;
                  <Typography variant="lead" fontWeight="bold">
                    type of item
                  </Typography>
                  &nbsp; you would like to add...
                </Typography>
                <Autocomplete
                  options={types}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option, value) => option.name === value.name}
                  value={itemType}
                  placeholder="Start typing..."
                  onChange={(event, newType) => {
                    setItemType(newType);
                  }}
                />
              </WizardStepContent>
              <WizardStepActions>
                <WizardSubmitWithEnterButton
                  data-test-id="add-item-type-step-continue-button"
                  variant="primary"
                  disabled={!Boolean(itemType)}>
                  Continue &nbsp;<ForwardIcon/>
                </WizardSubmitWithEnterButton>
              </WizardStepActions>
            </form>
        }
      </WizardContentColumn>
    </WizardStep>
  );
};

ItemTypeStep.propTypes = {
  onSetItemType: PropTypes.func.isRequired,
};

export default ItemTypeStep;
