function selectTypeRelationships(DM) {
  const {
    typeRelationshipsById,
    typeRelationshipIds,
  } = DM;

  return (
    typeRelationshipIds
      .map(typeRelationshipId => typeRelationshipsById[typeRelationshipId])
  );
}

export default selectTypeRelationships;
