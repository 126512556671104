import GET__files_FILENAME from 'network-layer/endpoints/GET__files_FILENAME';

function responseAdapter(response) {
  return response;
}

export default async function downloadAttachments(authToken, perspectiveId, uploadRequest, params) {
  const response = await GET__files_FILENAME(authToken, perspectiveId, uploadRequest, params);

  return responseAdapter(response);
}
