import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import Autocomplete from 'components/Autocomplete';
import Typography from 'ui-library/components/Typography';
import Link from 'ui-library/components/Link';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import genConnectionsService from 'services/Connections/genConnections';

const ShareRecordConnectionStep = ({
  active,
  connection,
  onSetConnection,
  navigateToStep,
  onClose,
}) => {
  const [connections, setConnections] = React.useState([]);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;


  React.useEffect(() => {
    async function loadConnections() {
      const results = await genConnectionsService(authToken, currentPerspectiveId);
      setConnections(results.filter(connection => connection.status === 'active'));
    }
    loadConnections();
  }, [authToken, currentPerspectiveId]);

  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Share Record
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Connection
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          In order to share items, a Connection must already exist. Your existing Connections can be found&nbsp;
          <Link
            data-test-id="connection-list-link"
            value="/connections"
            label="here"
            variant="caption"
            fontWeight="normal"
            color="primary"
            onClick={onClose}
          />
          .
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        <form onSubmit={(event) => {
          event.preventDefault();
          if (connection === null) {
            return;
          }
          navigateToStep(2);
        }}>
          <WizardStepContent>
            <Typography variant="lead">
              Select the &nbsp;
              <Typography variant="lead" fontWeight="bold">
              Connection
              </Typography>
              &nbsp; you would like to share items with:
            </Typography>
            <Autocomplete
              options={connections}
              getOptionLabel={option => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              value={connection}
              placeholder="Start typing..."
              onChange={(event, newConnection) => {
                onSetConnection(newConnection);
              }}
            />
          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              data-test-id="add-item-type-step-continue-button"
              variant="primary"
              disabled={connection === null}>
              Continue &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ShareRecordConnectionStep.propTypes = {
  active: PropTypes.bool,
  onSetConnection: PropTypes.func,
};

export default ShareRecordConnectionStep;
