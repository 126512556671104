import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddBox';
import TypeCategoryIcon from '@material-ui/icons/FolderSpecial';
import TypeIcon from '@material-ui/icons/DonutLarge';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const TypeCategory = ({
  typeCategory,
  typeCategories,
  DataModel,
  DataModelQueries,
}) => {
  const {
    id: typeCategoryId,
    name: typeCategoryName,
  } = typeCategory;

  return (
    <NestedBox>
      <ContentLine>
        <TypeCategoryIcon/>&nbsp;&nbsp;
        <ContentLineText>
          <Typography
            vartiant="body1">
            {typeCategoryName}
          </Typography>
        </ContentLineText>
        <Tooltip
          placement="top"
          title={`Edit types in ${typeCategoryName}`}>
          <span>
            <IconButton disabled>
              <TypeIcon/>
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          placement="top"
          title={`Add a child category to ${typeCategoryName}`}>
          <span>
            <IconButton disabled>
              <AddIcon/>
            </IconButton>
          </span>
        </Tooltip>
      </ContentLine>

      {
        DataModelQueries.selectChildTypeCategoriesOfTypeCategoryById(
          DataModel,
          typeCategoryId,
        )
          .map(typeCategory => {
            const {
              id: typeCategoryId,
            } = typeCategory;
            return (
              <TypeCategory
                key={typeCategoryId}
                typeCategory={typeCategory}
                DataModel={DataModel}
                DataModelQueries={DataModelQueries}
              />
            );
          })
      }
    </NestedBox>
  );
};

export default TypeCategory;
