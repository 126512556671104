import GET__types_ID from 'network-layer/endpoints/GET__types_ID';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return response;
}

export default async function genTypeByTypeId(params) {
  const {
    authToken,
    perspectiveId,
    typeId,
  } = params;

  const response = await GET__types_ID(authToken, perspectiveId, typeId);

  return responseAdapter(response);
}
