import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Typography from 'ui-library/components/Typography';

const useStyles = makeStyles({
  root: {
    display: theme.tag.root.display,
    maxWidth: theme.tag.root.maxWidth,
    margin: theme.tag.root.margin,
  },
  tag: {
    display: theme.tag.display,
    height: theme.tag.height,
    padding: theme.tag.padding,
    borderRadius: theme.tag.borderRadius,
    backgroundColor: theme.tag.backgroundColor,
  },
  label: {
    minWidth: theme.tag.label.minWidth,
    display: theme.tag.label.display,
    alignItems: theme.tag.label.alignItems,
    justifyContent: theme.tag.label.justifyContent,
  },
  iconContainer: {
    display: theme.tag.iconContainer.display,
    width: theme.tag.iconContainer.width,
    alignItems: theme.tag.iconContainer.alignItems,
    justifyContent: theme.tag.iconContainer.justifyContent,
    marginLeft: theme.tag.iconContainer.marginLeft,
    color: theme.tag.iconContainer.color,
    cursor: theme.tag.iconContainer.cursor,
  },
  icon: {
    width: theme.tag.icon.width,
  },
});

const Tag = ({
  onClick,
  label,
}) => {

  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      <div
        className={classes.tag}
      >
        <div
          className={classes.label}
        >
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography
              variant="caption"
              fontWeight="normal"
              color="black"
              className={classes.tag.typography}
            >
              {label}
            </Typography>
          </span>
        </div>

        <div
          data-test-id="tag-remove-button"
          className={classes.iconContainer}
          onClick={onClick}
        >
          <CloseRoundedIcon
            className={classes.icon}
          />
        </div>
      </div>
    </div>
  );
};

Tag.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
};

export default Tag;
