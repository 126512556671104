import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {COLOR_MONIKERS} from 'ui-library/color-palette';

const ALLOWED_VARIANTS = ['uber', 'hero', 'h1', 'h2', 'h3', 'h4', 'h5', 'lead', 'body', 'small', 'caption', 'x-small', 'tiny'];
const ALLOWED_FONT_WEIGHTS = ['bold', 'normal'];
const ALLOWED_FONT_FAMILIES = ['primary', 'secondary'];
const ALLOWED_FONT_STYLES = ['normal', 'italic'];
const ALLOWED_TEXT_TRANSFORMS = ['none', 'capitalize', 'uppercase', 'lowercase', 'initial', 'inherit'];

const Typography = ({
  variant,
  fontWeight,
  fontFamily,
  fontStyle,
  children,
  color,
  textTransform,
  ...otherProps
}) => {


  const variantProp = ALLOWED_VARIANTS.indexOf(variant) > -1 ? variant : 'inherit';
  const fontWeightProp = ALLOWED_FONT_WEIGHTS.indexOf(fontWeight) > -1 ? fontWeight : 'inherit';
  const fontFamilyProp = ALLOWED_FONT_FAMILIES.indexOf(fontFamily) > -1 ? fontFamily : 'inherit';
  const fontStyleProp = ALLOWED_FONT_STYLES.indexOf(fontStyle) > -1 ? fontStyle : 'normal';
  const colorProp = COLOR_MONIKERS.indexOf(color) > -1 ? color : 'inherit';
  const textTransformProp = ALLOWED_TEXT_TRANSFORMS.indexOf(textTransform) > -1 ? textTransform : 'inherit';

  return (
    <span
      style={{
        fontFamily: theme.typography.fontFamily[fontFamilyProp],
        fontWeight: theme.typography.fontWeight[fontWeightProp],
        fontSize: theme.typography[variantProp].fontSize,
        fontStyle: theme.typography.fontStyle[fontStyleProp],
        lineHeight: theme.typography[variantProp].lineHeight,
        color: theme.palette[colorProp],
        textTransform: textTransformProp,
      }}

      {...otherProps}
    >
      {children}
    </span>
  );
};

Typography.propTypes = {
  variant: PropTypes.oneOf(ALLOWED_VARIANTS).isRequired,
  fontWeight: PropTypes.oneOf(ALLOWED_FONT_WEIGHTS),
  fontFamily: PropTypes.oneOf(ALLOWED_FONT_FAMILIES),
  fontStyle: PropTypes.oneOf(ALLOWED_FONT_STYLES),
  children: PropTypes.node,
  color: PropTypes.oneOf(COLOR_MONIKERS),
};

export default Typography;
