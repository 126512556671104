import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_typeRelationships_TYPERELATIONSHIPID(authToken, perspectiveId, params) {
  const {
    typeRelationshipId,
  } = params;

  return await DELETE(
    `/admin/type-relationships/${typeRelationshipId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {},
  );
}
