import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PUT} from '../verbs';

export default async function PUT__nodes_ID_fields_ID(params) {
  const {
    authToken,
    currentPerspectiveId,
    id,
    fieldDefinitionId,
    value,
  } = params;

  return await PUT(
    `/nodes/${id}/fields/${fieldDefinitionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, currentPerspectiveId),
    {
      value,
    },
  );
}
