import React from 'react';
import styled from '@emotion/styled';
import NodeList from 'components/NodeList';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import Message from 'ui-library/components/Message';
import BatchActionButton from 'ui-library/components/BatchActionButton';
import BatchActionMenu from 'ui-library/components/BatchActionMenu';
import BatchDeleteWarningDialog from 'components/BatchDeleteWarningDialog';
import BatchDeleteDialog from 'components/BatchDeleteDialog';
import SelectAllItems from 'components/SelectAllItems';

import ConnectionsContext from './context';

import genNodesByConnectionIdAndRecordId from 'services/Nodes/genNodesByConnectionIdAndRecordId';
import deleteNode from 'services/Nodes/deleteNode';

const ListContainer = styled.div`
`;

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const InboundNodesByRecordAndConnectionListRoute = ({
  match,
}) => {
  const {
    authToken,
    setAuthToken,
    setAuthenticationStatus,
    currentPerspectiveId,
    nodeIdModifiedDestructivelyForLists,
    dispatchUserInterfaceAction,
    filter,
  } = React.useContext(ConnectionsContext);
  const [status, setStatus] = React.useState('fetching data');
  const [fatalError, setFatalError] = React.useState(null);
  const [nodes, setNodes] = React.useState([]);
  const [selectedNodeIds, setSelectedNodeIds] = React.useState([]);

  const [showBatchDeleteWarningDialog, setShowBatchDeleteWarningDialog] = React.useState(false);
  const [showBatchDeleteDialog, setShowBatchDeleteDialog] = React.useState(false);
  const [batchDeleteSuccess, setBatchDeleteSuccess] = React.useState(false);

  const [error, setError] = React.useState(false);
  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        setAuthToken(null);
        setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  const initialize = React.useCallback(async() => {
    try {
      setStatus('fetching data');
      const nodes = await genNodesByConnectionIdAndRecordId(authToken, currentPerspectiveId, match.params.connectionId, match.params.recordId);
      setNodes(nodes);
      setStatus('ready');
    } catch(error) {
      setError(error);
    }
  },[authToken, currentPerspectiveId, match.params.connectionId, match.params.recordId]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  React.useEffect(() => {
    if (nodeIdModifiedDestructivelyForLists) {
      initialize();
      dispatchUserInterfaceAction({
        type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: null,
      });
    }
  }, [dispatchUserInterfaceAction, initialize, nodeIdModifiedDestructivelyForLists]);


  const renderedNodes = nodes.map(node => {
    return {
      ...node,
      isSelected: false,
    };
  }).filter(node => {
    if (!filter) {
      return true;
    }
    const {
      name,
      typeName,
    } = node;
    return (
      name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
      typeName.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
    );
  });

  const batchDeleteNodes = () => {
    return new Promise((resolve, reject) => {
      const promises = selectedNodeIds.map(selectedNodeId => {
        return new Promise(async(resolve, reject) => {
          try {
            const result = await deleteNode({authToken, currentPerspectiveId, id: selectedNodeId});
            resolve(result);
          } catch(error) {
            const {
              body: {
                code,
              },
            } = error;
            switch(code) {
              case 3001:
                resolve(null);
                break;
              case 1014:
                resolve({selectedNodeId, code});
                break;
              default:
                reject(error);
            }
          }
        });
      });

      Promise.all(promises)
        .then(deleted => {
          const withErrors = deleted.filter(result => result !== null);
          resolve(withErrors);
          setSelectedNodeIds([]);
          if (withErrors.length === 0) {
            setBatchDeleteSuccess(true);
          }
        })
        .catch(error => {
          setError(error);
        });
    });
  };

  if (fatalError) {
    throw fatalError;
  }

  if (status !== 'ready') {
    return (
      <ListSkeleton/>
    );
  }

  if (nodes.length === 0) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
          No items assigned to this record have been shared with you yet. When you receive access to items assigned to this record they will appear here.
        </Typography>
      </EmptyList>
    );
  }

  return (
    <>
      <SelectAllItems
        items={renderedNodes}
        checked={Boolean(selectedNodeIds.length === renderedNodes.length)}
        indeterminate={Boolean(selectedNodeIds.length && (selectedNodeIds.length !== renderedNodes.length))}
        onChange={() => {
          if (selectedNodeIds.length === renderedNodes.length) {
            setSelectedNodeIds([]);
          } else {
            setSelectedNodeIds([
              ...renderedNodes.map(node => node.id),
            ]);
          }
        }}
      />
      <div>
        <BatchActionMenu>
          <BatchActionButton
            data-test-id="batch-delete-button"
            variant="delete"
            onClick={() => {
              if (selectedNodeIds.length > 0) {
                setShowBatchDeleteDialog(true);
              } else {
                setShowBatchDeleteWarningDialog(true);
              }
            }}
          />
        </BatchActionMenu>
        <ListContainer>
          <NodeList
            nodes={renderedNodes}
            selectedNodeIds={selectedNodeIds}
            onNodeAction={(nodeId, action) => {
              switch(action) {
                case 'expand': {
                  dispatchUserInterfaceAction({
                    type: 'SET_EXPANDED_NODE_ID',
                    payload: nodeId,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_EXTERNAL_RECORD_CONNECTION_ID',
                    payload: match.params.connectionId,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_SHOW_NODE_PANEL',
                    payload: true,
                  });
                  break;
                }
                case 'share': {
                  dispatchUserInterfaceAction({
                    type: 'SET_SHOW_SHARE_NODE_WIZARD',
                    payload: true,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_SHARE_NODE_ID',
                    payload: nodeId,
                  });
                  break;
                }
                case 'delete': {
                  dispatchUserInterfaceAction({
                    type: 'SET_SHOW_DELETE_NODE_DIALOG',
                    payload: true,
                  });
                  dispatchUserInterfaceAction({
                    type: 'SET_DELETE_NODE_ID',
                    payload: nodeId,
                  });
                  break;
                }
                case 'select': {
                  setSelectedNodeIds([
                    ...selectedNodeIds,
                    nodeId,
                  ]);
                  break;
                }
                case 'deselect': {
                  const index = selectedNodeIds.indexOf(nodeId);
                  setSelectedNodeIds([
                    ...selectedNodeIds.slice(0, index),
                    ...selectedNodeIds.slice(index + 1),
                  ]);
                  break;
                }
                default:
                  break;
              }
            }}
          />
        </ListContainer>
      </div>

      <BatchDeleteWarningDialog
        isOpen={showBatchDeleteWarningDialog}
        onClose={() => setShowBatchDeleteWarningDialog(false)}
      />
      <BatchDeleteDialog
        isOpen={showBatchDeleteDialog}
        onCancel={() => setShowBatchDeleteDialog(false)}
        onClose={() => {
          setShowBatchDeleteDialog(false);
          // refresh list
          dispatchUserInterfaceAction({
            type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
            payload: selectedNodeIds,
          });
        }}
        nodes={renderedNodes.filter((node) => {
          return selectedNodeIds.indexOf(node.id) !== -1;
        })}
        deleteNodes={batchDeleteNodes}
      />
      <Message
        variant="success"
        open={batchDeleteSuccess}
        onClose={() => setBatchDeleteSuccess(false)}
        messageTitle="Item(s) Deleted"
        messageBody={
          <Typography variant="body">
            All selected items have been deleted successfully.
          </Typography>
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        width={532}
      />
    </>
  );
};

export default InboundNodesByRecordAndConnectionListRoute;
