import React from 'react';
import {makeStyles} from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkRoundedIcon from '@material-ui/icons/LinkRounded';
import LinkOffRoundedIcon from '@material-ui/icons/LinkOffRounded';
import Typography from 'ui-library/components/Typography';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import SearchBar from 'ui-library/components/SearchBar';
import Button from 'ui-library/components/Button';
import theme from 'ui-library/theme';
import PropTypes from 'prop-types';

import saveNodeLink from 'services/Links/saveNodeLink';
import deleteNodeLink from 'services/Links/deleteNodeLink';
import genNodeLinkById from 'services/Links/genNodeLinkById';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import debounce from 'utils/debounce';

const useStyles = makeStyles({
  listItemCategoryHeader: {
    height: theme.listItemCategoryHeader.height,
    paddingLeft: theme.listItemCategoryHeader.paddingLeft,
    marginTop: theme.listItemCategoryHeader.marginTop,
    display: theme.listItemCategoryHeader.display,
    alignItems: theme.listItemCategoryHeader.alignItems,
  },
  noResultsContainer: {
    display: theme.noResultsContainer.display,
    justifyContent: theme.noResultsContainer.justifyContent,
    textAlign: theme.noResultsContainer.textAlign,
    marginTop: theme.noResultsContainer.marginTop,
  },
});

const filterCandidates = (value, nodeLinkCandidates, dispatchNodeAction) => {
  const filteredNodeLinkCandidates = nodeLinkCandidates.map((candidate) => {
    if (candidate.candidateNodeName.toLowerCase().indexOf(value.toLowerCase()) > -1 || candidate.candidateTypeName.toLowerCase().indexOf(value.toLowerCase()) > -1 || candidate.nodeLinkDefinitionLabel.toLowerCase().indexOf(value.toLowerCase()) > -1) {
      candidate.isVisible = true;
    } else {
      candidate.isVisible = false;
    }

    return candidate;
  });

  dispatchNodeAction({
    type: 'SET_NODE_LINK_CANDIDATES',
    payload: filteredNodeLinkCandidates,
  });
};

const debouncedFilterCandidates = debounce(filterCandidates, 500);

const NodeLinkCandidatesList = ({
  nodeState,
  dispatchNodeAction,
  setError,
  handleLinkInfo,
}) => {

  const classes = useStyles();

  const {
    authToken,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const {
    node: {
      id: nodeId,
    },
    nodeLinkCandidates,
    isLinkCandidatesListLoading,
    nodeLinks,
  } = nodeState;

  const [searchQuery, setSearchQuery] = React.useState('');
  let hasVisibleCandidates = false;

  const candidatesByLabelObj = {};
  nodeLinkCandidates.forEach(candidate => {
    const {
      nodeLinkDefinitionLabel,
      isVisible,
    } = candidate;

    if (isVisible) {
      hasVisibleCandidates = true;
    }

    if (!candidatesByLabelObj[nodeLinkDefinitionLabel]) {
      candidatesByLabelObj[nodeLinkDefinitionLabel] = {
        label: nodeLinkDefinitionLabel,
        candidates: [candidate],
      };
    } else {
      candidatesByLabelObj[nodeLinkDefinitionLabel].candidates.push(candidate);
    }
  });

  const candidatesByLabelList = Object.values(candidatesByLabelObj).sort((a,b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));

  const handleCandidateLink = async(candidate) => {
    const {
      linked: candidateIsLinked,
      linkId,
      nodeLinkDefinitionId,
      originNodeId,
      destinationNodeId,
    } = candidate;

    const isSelectedCandidate = (element) => {
      let found = true;
      Object.keys(element).forEach(key => {
        if (candidate[key] !== element[key]) {
          found = false;
        }
      });
      return found;
    };

    const candidateIndex = nodeLinkCandidates.findIndex(isSelectedCandidate);

    try {
      if (candidateIsLinked) {
        dispatchNodeAction({
          type: 'SET_CANDIDATE_OPERATION_IN_PROGRESS',
          payload: {
            value: true,
            candidateIndex,
          },
        });
        await deleteNodeLink(authToken, currentPerspectiveId, linkId);
        let excludedLinkIndex;
        nodeLinks.forEach((nodeLink, index) => {
          if (linkId === nodeLink.id) {
            excludedLinkIndex = index;
          }
        });
        dispatchNodeAction({
          type: 'REMOVE_LINK',
          payload: excludedLinkIndex,
        });
        dispatchNodeAction({
          type: 'SET_LINK_CANDIDATE',
          payload: {
            value: false,
            linkId: null,
            candidateIndex,
          },
        });
        dispatchNodeAction({
          type: 'SET_CANDIDATE_OPERATION_IN_PROGRESS',
          payload: {
            value: false,
            candidateIndex,
          },
        });
      } else {
        dispatchNodeAction({
          type: 'SET_CANDIDATE_OPERATION_IN_PROGRESS',
          payload: {
            value: true,
            candidateIndex,
          },
        });
        let newLink = await saveNodeLink(authToken, currentPerspectiveId, {
          definitionId: nodeLinkDefinitionId,
          originNodeId,
          destinationNodeId,
        });

        const {
          id: nodeLinkId,
        } = newLink;

        let linkObj = await genNodeLinkById(authToken, currentPerspectiveId, nodeLinkId, ['definition', 'nodes']);

        linkObj = {
          ...linkObj,
          isLinkDeleting: false,
        };

        dispatchNodeAction({
          type: 'ADD_LINK',
          payload: linkObj,
        });

        dispatchNodeAction({
          type: 'SET_LINK_CANDIDATE',
          payload: {
            value: true,
            linkId: newLink.id,
            candidateIndex,
          },
        });
        dispatchNodeAction({
          type: 'SET_CANDIDATE_OPERATION_IN_PROGRESS',
          payload: {
            value: false,
            candidateIndex,
          },
        });
      }
    } catch(error) {
      setError(error);
    }
  };


  if (isLinkCandidatesListLoading) {
    return (
      <ListSkeleton
        itemCount={5}
      />
    );
  }

  if (nodeLinkCandidates.length === 0 && searchQuery === '' && !isLinkCandidatesListLoading) {
    return (
      <div className={classes.noResultsContainer}>
        <Typography
          variant="h5"
          color="grey"
          fontWeight="bold"
        >
          This item cannot be linked with other items.
        </Typography>
      </div>
    );
  }

  return (
    <>
      <SearchBar
        data-test-id="filter-candidates-search-bar"
        searchQuery={searchQuery}
        onChange={(value) => {
          setSearchQuery(value);
          debouncedFilterCandidates(value, nodeLinkCandidates, dispatchNodeAction);
        }}
        onSubmitSearchQuery={() => {
          filterCandidates(searchQuery, nodeLinkCandidates, dispatchNodeAction);
        }}
        hideSearchButton={true}
      />
      {
        hasVisibleCandidates ?
          candidatesByLabelList.map((category, index) => {
            const {
              label,
              candidates,
            } = category;

            const labelIsVisible = Boolean(candidates.filter(candidate => candidate.isVisible).length);

            return (
              <div
                key={index}
              >
                {
                  labelIsVisible ?
                    <div className={classes.listItemCategoryHeader}>
                      <Typography
                        variant="caption"
                        color="primary"
                        fontWeight="bold"
                      >
                        {label.toUpperCase()}
                      </Typography>
                    </div>
                    :
                    null
                }
                {
                  candidates.map((candidate, index) => {
                    const {
                      originNodeId,
                      destinationNodeId,
                    } = candidate;

                    const candidateNodeId = nodeId === originNodeId ? destinationNodeId : originNodeId;

                    const {
                      candidateNodeName,
                      candidateTypeName,
                      linked,
                      isVisible,
                      operationInProgress,
                    } = candidate;

                    if (!isVisible) {
                      return null;
                    }

                    return (
                      <ListItem
                        key={index}
                        onClick={() => {
                          if (!operationInProgress) {
                            handleLinkInfo(candidateNodeId);
                          }
                        }}
                      >
                        <ListItemContentSegment
                          weight={4}
                        >
                          <ListItemText>
                            <Typography
                              variant="small"
                              fontWeight="bold"
                              color={linked ? 'tertiary' : 'black'}
                            >
                              {candidateNodeName}
                            </Typography>
                          </ListItemText>
                          <ListItemText>
                            <Typography
                              variant="caption"
                              color="grey"
                            >
                              {candidateTypeName}
                            </Typography>
                          </ListItemText>
                        </ListItemContentSegment>
                        <ListItemContentSegment
                          weight={1}
                          justifyContent="flex-end"
                        >
                          <ListItemActions>
                            <Button
                              data-test-id="info-button"
                              style={{
                                height: 48,
                                width: 48,
                              }}
                              variant="icon-custom"
                              textTransform="none"
                              customBgColor="transparent"
                              customHoverBgColor="grey-semi"
                              customTextColor="grey-dark"
                              customHoverTextColor="grey-dark"
                              customActiveBgColor="grey-semi"
                              customActiveTextColor="grey-dark"
                              onClick={(ev) => {
                                ev.stopPropagation();
                                if (!operationInProgress) {
                                  handleLinkInfo(candidateNodeId);
                                }
                              }}
                            >
                              <InfoOutlinedIcon />
                            </Button>
                            <Button
                              data-test-id="link-unlink-candidate-button"
                              style={{
                                height: 48,
                                width: 48,
                              }}
                              variant="icon-custom"
                              textTransform="none"
                              customBgColor="transparent"
                              customHoverBgColor={`${linked ? 'tertiary-lightest' : 'grey-semi'}`}
                              customTextColor={`${linked ? 'tertiary' : 'grey-dark'}`}
                              customHoverTextColor={`${linked ? 'tertiary' : 'grey-dark'}`}
                              customActiveBgColor={`${linked ? 'tertiary-lightest' : 'grey-semi'}`}
                              customActiveTextColor={`${linked ? 'tertiary' : 'grey-dark'}`}
                              onClick={(ev) => {
                                ev.stopPropagation();
                                if (!operationInProgress) {
                                  handleCandidateLink(candidate);
                                }
                              }}
                            >
                              {
                                linked ?
                                  <LinkOffRoundedIcon />
                                  :
                                  <LinkRoundedIcon />
                              }
                            </Button>
                          </ListItemActions>
                        </ListItemContentSegment>
                      </ListItem>
                    );
                  })
                }
              </div>
            );
          })
          :
          <div className={classes.noResultsContainer}>
            <Typography
              variant="h5"
              color="grey"
              fontWeight="bold"
            >
            No matching items.
              <br/>
            Try changing your search criteria.
            </Typography>
          </div>
      }
    </>
  );
};

NodeLinkCandidatesList.propTypes = {
  selectedNodeId: PropTypes.number,
  isLinkCandidatesListLoading: PropTypes.bool,
  nodeLinkCandidates: PropTypes.array,
  handleCandidateLink: PropTypes.func,
  handleCandidateInfo: PropTypes.func,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  onSubmitSearchQuery: PropTypes.func,
  hasVisibleCandidates: PropTypes.bool,
};

export default NodeLinkCandidatesList;
