import selectVirtualLocationTreeNodeHints from './selectVirtualLocationTreeNodeHints';
import deriveVirtualLocationTreeNodeHintLocationDepth from './deriveVirtualLocationTreeNodeHintLocationDepth';

function deriveMaximumVirtualLocationTreeDepth(configurationState) {
  let maximulLocationDepth = 0;

  selectVirtualLocationTreeNodeHints(configurationState)
    .map(virtualLocationTreeNodeHint => deriveVirtualLocationTreeNodeHintLocationDepth(configurationState, virtualLocationTreeNodeHint))
    .forEach(depth => {
      if (depth > maximulLocationDepth) {
        maximulLocationDepth = depth;
      }
    });

  return maximulLocationDepth;
}

export default deriveMaximumVirtualLocationTreeDepth;
