import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../verbs';

export default async function PATCH__subscriptions_currentSubscription(authToken, perspectiveId, params) {
  const {
    autoRenew,
  } = params;

  const url = '/subscriptions/current-subscription';

  return await PATCH(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      autoRenew,
    },
  );
}
