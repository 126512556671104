// returns a config that also provides an "Authorization" header

export default function configWithAuthorization(config, authToken) {
  return {
    ...config,
    headers: {
      ...config.headers,
      'Authorization': authToken,
    },
  };
}
