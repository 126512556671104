import './ContentLine.css';
import React from 'react';

const ContentLine = ({
  children,
}) => {
  return (
    <div
      className="content-line">
      {children}
    </div>
  );
};

export default ContentLine;
