import React from 'react';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';

import ShareItemConnectionStep from './steps/ShareItemConnectionStep';
import ShareItemPermissionsStep from './steps/ShareItemPermissionsStep';
import ShareItemSummaryStep from './steps/ShareItemSummaryStep';

import Message from 'ui-library/components/Message';
import NoPermissionsModal from 'components/NoPermissionsModal';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import parseNodePermissions from 'utils/permissions/parseNodePermissions';
import nodeFrontendPermissionstoAtomicPermissions from 'utils/permissions/nodeFrontendPermissionsToAtomicPermissions';
import nodeAtomicPermissionsToPermissionsInt from 'utils/permissions/nodeAtomicPermissionsToPermissionsInt';

import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';
import updateNodePermissions from 'services/Nodes/updateNodePermissions';

const ShareItemWizard = () => {
  const {
    showShareNodeWizard: open,
    shareNodeId,
    expandedNodeId,
    dispatchUserInterfaceAction,
    selectedNodePanelTab,
  } = React.useContext(UserInterfaceContext);

  const TOTAL_STEPS = 3;
  const [step, setStep] = React.useState(1);
  // 'notShared' | 'sharing' | 'shared' | 'shareError'
  const [shareStatus, setShareStatus] = React.useState('notShared');
  const [showMessage, setShowMessage] = React.useState(false);
  // 'notLoaded' | 'loading' | 'loaded'
  const [loadingStatus, setLoadingStatus] = React.useState('notLoaded');
  const [sharedNodeName, setSharedNodeName] = React.useState('');
  const [hasSharePermissions, setHasSharePermissions] = React.useState(false);

  const [connection, setConnection] = React.useState(null);
  const [sharedPermissions, setSharedPermissions] = React.useState({});
  const [givenPermissions, setGivenPermissions] = React.useState({});

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw fatalError;
  }

  React.useEffect(() => {
    async function loadNode() {
      try {
        setLoadingStatus('loading');
        const node = await genNodeByNodeId({
          authToken,
          perspectiveId: currentPerspectiveId,
          nodeId: shareNodeId,
        });
        const {
          name: nodeName,
          permissions,
        } = node;
        setSharedNodeName(nodeName);
        const parsedPermissions = parseNodePermissions(permissions);
        const {
          frontendPermissions,
          frontendPermissions: {
            allow3rdPartyAccess,
          },
        } = parsedPermissions;
        setHasSharePermissions(allow3rdPartyAccess);
        setConnection(null);
        setSharedPermissions(frontendPermissions);
        setGivenPermissions(frontendPermissions);
        setShareStatus('notShared');
        setStep(1);
        setLoadingStatus('loaded');
      } catch (error) {
        setError(error);
      }
    }
    if (open) {
      loadNode();
    }
  }, [authToken, currentPerspectiveId, open, shareNodeId]);

  const hasState = () => {
    return step > 1;
  };

  const closeConfirmed = () => {
    if (!hasState()) {
      return true;
    }
    if (hasState() && window.confirm('Are you sure you want to quit the wizard? Your changes will not be saved if you quit now.')) {
      return true;
    }
    return false;
  };

  const closeWizard = () => {
    if (!closeConfirmed()) {
      return;
    }
    onClose();
  };

  const onClose = () => {
    dispatchUserInterfaceAction({
      type: 'SET_SHOW_SHARE_NODE_WIZARD',
      payload: false,
    });
    if (expandedNodeId) {
      dispatchUserInterfaceAction({
        type: 'SET_SHOW_NODE_PANEL',
        payload: true,
      });
      if (selectedNodePanelTab === 5) {
        dispatchUserInterfaceAction({
          type: 'SET_RELOAD_NODE_CONVERSATION_TAB',
          payload: true,
        });
      }
    }
  };

  const shareItem = async() => {
    try {
      setShareStatus('sharing');
      const atomicPermissions = nodeFrontendPermissionstoAtomicPermissions(sharedPermissions);
      const permissionsInt = nodeAtomicPermissionsToPermissionsInt(atomicPermissions);
      const {
        id: connectionId,
      } = connection;
      await updateNodePermissions(
        authToken,
        currentPerspectiveId,
        shareNodeId,
        {
          connectionId: connectionId,
          permissions: permissionsInt,
        },
      );
      if (expandedNodeId) {
        dispatchUserInterfaceAction({
          type: 'SET_RELOAD_ITEM_PERMISSION_GRANTS',
          payload: true,
        });
      }
      setShareStatus('shared');
      setShowMessage(true);
    } catch (error) {
      setError(error);
      setShareStatus('shareError');
      setShowMessage(true);
    }
    onClose();
  };

  if (!open) {
    if (shareStatus === 'shared') {
      const {
        peerName: partnerName,
      } = connection;
      return (
        <Message
          variant="success"
          open={showMessage}
          onClose={() => {
            setShowMessage(false);
          }}
          messageTitle="Share Success"
          messageBody={`${sharedNodeName} was shared successfully with ${partnerName}`}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          width={532}
        />
      );
    }
    if (shareStatus === 'shareError') {
      return (
        <Message
          variant="error"
          open={showMessage}
          onClose={() => {
            setShowMessage(false);
          }}
          messageTitle="Share Failed"
          messageBody="There was a problem sharing this item"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          width={532}
        />
      );
    }
    return null;
  }

  if (loadingStatus !== 'loaded') {
    return null;
  }

  if (open && !hasSharePermissions) {
    return (
      <NoPermissionsModal
        isOpen={true}
        onClose={onClose}
        operation="Share Item"
      />
    );
  }

  return (
    <Wizard onClose={closeWizard}>
      <WizardProgress
        isVisible={true}
        progress={step}
        total={TOTAL_STEPS}
      />
      <WizardNavigation>
        <WizardBackButton
          data-test-id="share-item-wizard-back-button"
          isVisible={step > 1 && shareStatus === 'notShared'}
          onClick={() => {
            step > 1 && setStep(step - 1);
          }}
        />
        <WizardCloseButton
          data-test-id="share-item-wizard-close-button"
          isVisible={step <= TOTAL_STEPS && shareStatus === 'notShared'}
          onClick={closeWizard}
        />
      </WizardNavigation>
      <ShareItemConnectionStep
        active={step === 1}
        connection={connection}
        onSetConnection={selectedConnection => {
          setConnection(selectedConnection);
        }}
        onClose={() => {
          dispatchUserInterfaceAction({
            type: 'SET_SHOW_SHARE_NODE_WIZARD',
            payload: false,
          });
        }}
        navigateToStep={setStep}
      />
      <ShareItemPermissionsStep
        active={step === 2}
        connection={connection}
        permissions={sharedPermissions}
        givenPermissions={givenPermissions}
        onSetPermissions={(permissionObject) => {
          setSharedPermissions(permissionObject);
        }}
        navigateToStep={setStep}
      />
      <ShareItemSummaryStep
        active={step === 3}
        connection={connection}
        permissions={sharedPermissions}
        nodeName={sharedNodeName}
        onShareItem={() => {
          shareItem();
        }}
        shareStatus={shareStatus}
        navigateToStep={setStep}
      />
    </Wizard>
  );
};

export default ShareItemWizard;
