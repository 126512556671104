import defaultConfig from '../config/defaultConfig';
import configWithContentType from '../config/configWithContentType';
import {POST} from '../verbs';

export default async function POST__resetPassword(password, passwordResetToken) {
  return await POST(
    '/reset-password/new-password',
    configWithContentType(defaultConfig(), 'application/json'),
    {
      password,
      passwordResetToken,
    },
  );
}
