import invariant from 'invariant';

export default match => paramName => {
  invariant(
    typeof match === 'object' && !(match instanceof Array),
    '"match" is not an object!',
  );
  invariant(
    typeof match.params === 'object' && !(match instanceof Array),
    '"match.params" is not an object!',
  );

  const {
    params: {
      [paramName]: paramValue,
    },
  } = match;

  return paramValue;
};
