import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import Grid from '@material-ui/core/Grid';
import Field from 'ui-library/components/Field';

import {
  fieldIsEnum,
  fieldIsPersonName,
} from 'utils/validator';

const FIELD_VALIDATION = {
  'enum': fieldIsEnum,
  'name': fieldIsPersonName,
};

const Description = styled.div`
  margin-bottom: 32px;
  margin-top: 20px;
`;

const PersonDetailsStep = ({
  active,
  personFields,
  setPersonFields,
  isSaving,
  savePerson,
}) => {
  if (!active) {
    return null;
  }

  const fieldsHaveErrors =
    personFields === null ||
    Object.keys(personFields).filter((id, index) => {
      return personFields[id].errorMessage !== '';
    }).length > 0;

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Person
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Name
          </WizardBreadcrumb>
        </WizardStepTitle>

        <WizardStepDescription>
          You can edit the details later from the People Section.
        </WizardStepDescription>
      </WizardStepHead>

      <WizardContentColumn width="100%" padding="0px 200px">
        <form onSubmit={(event) => {
          event.preventDefault();

          savePerson();
        }}>
          <WizardStepContent>
            <Description>
              <Typography variant="lead">
                  What is the name of this person?
              </Typography>
            </Description>

            {personFields === null ? (
              <CenterpieceSpinner/>
            ) : (
              <Grid container spacing={8}>
                {Object.keys(personFields).map((id, index) => {
                  const {
                    type,
                    label,
                    enumValues,
                    value,
                    errorMessage,
                  } = personFields[id];

                  const options = type === 'enum' ? ['N/A', ...enumValues] : enumValues;

                  return (
                    <Grid
                      key={index}
                      container
                      item
                      spacing={2}
                      xs={6}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Field
                        inlineEdit={false}
                        autoFocus={index === 0}
                        type={type}
                        label={label}
                        helperText={Boolean(errorMessage) ? errorMessage : ''}
                        error={Boolean(errorMessage)}
                        isEditable={true}
                        value={value}
                        options={options}
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                        onChange={(event) => {
                          personFields[id].value = event.target.value;
                          personFields[id].errorMessage = FIELD_VALIDATION[type]({value: event.target.value, options: options});
                          setPersonFields({...personFields});
                        }}
                        onMouseDown={(ev) =>{
                          ev.stopPropagation();
                        }}
                        disabled={false}
                        required={false}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}

          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              data-test-id="item-details-step-save-item-button"
              disabled={
                fieldsHaveErrors ||
                personFields === null ||
                Object.keys(personFields).filter(id => ['', 'N/A'].indexOf(personFields[id].value) === -1).length === 0 ||
                isSaving
              }>
              Save
              <ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

PersonDetailsStep.propTypes = {
  active: PropTypes.bool,
};

export default PersonDetailsStep;
