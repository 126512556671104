import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {GET} from '../../verbs';

export default async function GET__admin_typeLocationGraph_descendants(authToken, perspectiveId, params) {
  const {
    typeId,
  } = params;

  return await GET(
    `/admin/type-location-graph/descendants?typeId=${typeId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
