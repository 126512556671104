export default function fieldIsBoolean(
  params,
  errorMessage = 'invalid field value',
) {
  const {
    value,
  } = params;

  if (['N/A', true, false].includes(value)) {
    return '';
  }
  return errorMessage;
}
