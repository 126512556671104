import React from 'react';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemCheckbox from 'ui-library/components/ListItemCheckbox';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import PanelIcon from '@material-ui/icons/VerticalSplitRounded';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const LocationList = ({
  locations,
  selectedLocationIds,
  onLocationAction,
}) => {
  const [contextualMenuState, setContextualMenuState] = React.useState({
    nodeId: null,
    anchorEl: null,
  });

  return (
    <>
      {
        locations.map(node => {
          const {
            nodeId,
            nodeName,
            typeName,
            isTerminal,
            locationDescendantsCount,
          } = node;

          const isSelected = selectedLocationIds.indexOf(nodeId) > -1;

          return (
            <ListItem
              key={nodeId}
              isSelected={isSelected}
              onClick={() => {
                onLocationAction(nodeId, 'expand');
              }}>

              <ListItemContentSegment
                width={44}
              >
                <ListItemCheckbox
                  checked={isSelected}
                  onChange={() => {
                    switch (isSelected) {
                      case true: {
                        onLocationAction(nodeId, 'deselect');
                        break;
                      }
                      case false: {
                        onLocationAction(nodeId, 'select');
                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  }}
                />
              </ListItemContentSegment>

              <ListItemContentSegment
                weight={1}
              >
                <ListItemText>
                  <Typography
                    variant="small"
                    fontWeight="bold"
                  >
                    {nodeName}
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography
                    variant="caption"
                    color="grey"
                    textTransform="capitalize"
                  >
                    {typeName}
                  </Typography>
                </ListItemText>
              </ListItemContentSegment>
              <ListItemContentSegment
                weight={4}
              >
                <ListItemText>
                  {
                    !isTerminal &&
                    <Typography
                      variant="small"
                      color={locationDescendantsCount ? 'black' : 'grey'}
                    >
                      {locationDescendantsCount ? `${locationDescendantsCount}` : 'No'} Items
                    </Typography>
                  }
                </ListItemText>
              </ListItemContentSegment>
              <ListItemContentSegment
                width={200}
                justifyContent="flex-end"
              >
                <ListItemActions>
                  <Button
                    data-test-id="item-context-menu-button"
                    variant="icon-custom"
                    textTransform="none"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={event => {
                      event.stopPropagation();
                      setContextualMenuState({
                        id: nodeId,
                        anchorEl: event.currentTarget,
                      });
                    }}
                  >
                    <MoreHorizIcon/>
                  </Button>
                  <Button
                    data-test-id="item-open-node-panel-button"
                    textTransform="none"
                    variant="icon-custom"
                    customBgColor="transparent"
                    customHoverBgColor="primary-lightest"
                    customTextColor="grey"
                    customHoverTextColor="primary-dark"
                    customActiveBgColor="white"
                    customActiveTextColor="primary-darker"
                    onClick={event => {
                      event.stopPropagation();
                      onLocationAction(nodeId, 'expand');
                    }}>
                    <PanelIcon/>
                  </Button>
                  {
                    !isTerminal &&
                    <Button
                      data-test-id="drill-down-node-button"
                      disabled={!locationDescendantsCount}
                      textTransform="none"
                      variant="icon-custom"
                      customBgColor="transparent"
                      customHoverBgColor="primary-lightest"
                      customTextColor="grey"
                      customHoverTextColor="primary-dark"
                      customActiveBgColor="white"
                      customActiveTextColor="primary-darker"
                      onClick={event => {
                        event.stopPropagation();
                        onLocationAction(nodeId, 'navigate');
                      }}>
                      <NavigateNextIcon/>
                    </Button>
                  }
                </ListItemActions>
              </ListItemContentSegment>
              <Menu
                open={contextualMenuState.id === nodeId}
                anchorEl={contextualMenuState.anchorEl}
                onClose={() => {
                  setContextualMenuState({
                    id: null,
                    anchorEl: null,
                  });
                }}>
                <MenuItem
                  data-test-id="context-menu-view-button"
                  onClick={event => {
                    event.stopPropagation();
                    onLocationAction(nodeId, 'expand');
                  }}
                >
                  <Typography variant="small">
                    View Details
                  </Typography>
                </MenuItem>
                <MenuItem
                  data-test-id="context-menu-share-button"
                  onClick={event => {
                    event.stopPropagation();
                    onLocationAction(nodeId, 'share');
                  }}
                >
                  <Typography variant="small">
                    Share Item
                  </Typography>
                </MenuItem>
                <MenuItem
                  data-test-id="context-menu-delete-button"
                  onClick={event => {
                    event.stopPropagation();
                    onLocationAction(nodeId, 'delete');
                    setContextualMenuState({
                      id: null,
                      anchorEl: null,
                    });
                  }}
                >
                  <Typography variant="small" color="error">
                    Delete Item
                  </Typography>
                </MenuItem>
              </Menu>
            </ListItem>
          );
        })
      }
    </>
  );
};

export default LocationList;
