import GET__tags from 'network-layer/endpoints/GET__tags';

function responseAdapter(response) {
  return response;
}

export default async function genTags(authToken, perspectiveId) {
  const response = await GET__tags(authToken, perspectiveId);

  return responseAdapter(response);
}
