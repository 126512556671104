import React from 'react';
import ListItem from 'ui-library/components/ListItem';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PanelIcon from '@material-ui/icons/VerticalSplitRounded';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';

// import UserInterfaceContext from 'contexts/UserInterface';

const RecordList = ({
  records,
  onRecordAction,
}) => {
  const [contextualMenuState, setContextualMenuState] = React.useState({
    id: null,
    anchorEl: null,
  });

  // const {
  //   dispatchUserInterfaceAction,
  // } = React.useContext(UserInterfaceContext);

  return (
    <>
      {
        records.map(record => {
          const {
            id,
            name,
          } = record;
          return (
            <ListItem
              key={id}
              onClick={() => {
                onRecordAction(id, 'expand');
              }}>
              <ListItemText>
                <Typography
                  variant="small"
                  fontWeight="bold"
                >
                  {name}
                </Typography>
              </ListItemText>
              <ListItemActions>
                <Button
                  data-test-id="item-context-menu-button"
                  variant="icon-custom"
                  textTransform="none"
                  customBgColor="transparent"
                  customHoverBgColor="primary-lightest"
                  customTextColor="grey"
                  customHoverTextColor="primary-dark"
                  customActiveBgColor="white"
                  customActiveTextColor="primary-darker"
                  onClick={event => {
                    event.stopPropagation();
                    setContextualMenuState({
                      id,
                      anchorEl: event.currentTarget,
                    });
                  }}
                >
                  <MoreHorizIcon/>
                </Button>
                <Button
                  data-test-id="item-open-record-panel-button"
                  textTransform="none"
                  variant="icon-custom"
                  customBgColor="transparent"
                  customHoverBgColor="primary-lightest"
                  customTextColor="grey"
                  customHoverTextColor="primary-dark"
                  customActiveBgColor="white"
                  customActiveTextColor="primary-darker"
                  onClick={event => {
                    // dispatchUserInterfaceAction({
                    //   type: 'SET_EXPANDED_RECORD_ID',
                    //   payload: id,
                    // });
                    // dispatchUserInterfaceAction({
                    //   type: 'SET_SHOW_RECORD_PANEL',
                    //   payload: true,
                    // });
                    event.stopPropagation();
                    onRecordAction(id, 'expand');
                  }}>
                  <PanelIcon/>
                </Button>
                <Button
                  data-test-id="drill-down-record-button"
                  textTransform="none"
                  variant="icon-custom"
                  customBgColor="transparent"
                  customHoverBgColor="primary-lightest"
                  customTextColor="grey"
                  customHoverTextColor="primary-dark"
                  customActiveBgColor="white"
                  customActiveTextColor="primary-darker"
                  onClick={event => {
                    event.stopPropagation();
                    onRecordAction(id, 'navigate');
                  }}>
                  <NavigateNextIcon/>
                </Button>
              </ListItemActions>
              <Menu
                open={contextualMenuState.id === id}
                anchorEl={contextualMenuState.anchorEl}
                onClose={() => {
                  setContextualMenuState({
                    id: null,
                    anchorEl: null,
                  });
                }}>
                <MenuItem
                  data-test-id="context-menu-view-button"
                  onClick={event => {
                    event.stopPropagation();
                    onRecordAction(id, 'expand');
                    setContextualMenuState({
                      id: null,
                      anchorEl: null,
                    });
                  }}
                >
                  <Typography variant="small">
                    View Details
                  </Typography>
                </MenuItem>
                <MenuItem
                  data-test-id="context-menu-share-button"
                  onClick={event => {
                    event.stopPropagation();
                    onRecordAction(id, 'share');
                    setContextualMenuState({
                      id: null,
                      anchorEl: null,
                    });
                  }}
                >
                  <Typography variant="small">
                    Share Record
                  </Typography>
                </MenuItem>
              </Menu>
            </ListItem>
          );
        })
      }
    </>
  );
};

export default RecordList;
