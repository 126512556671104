export default function fieldIsBoolean(
  params,
  errorMessage = 'invalid field value',
) {
  const {
    value,
    options,
  } = params;

  if (options.includes(value) && options.includes('N/A')) {
    return '';
  }
  return errorMessage;
}
