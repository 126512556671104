import DELETE__notifications from 'network-layer/endpoints/DELETE__notifications';

function responseAdapter(response) {
  return response;
}

export default async function clearNotifications(authToken) {
  const response = await DELETE__notifications(authToken);

  return responseAdapter(response);
}
