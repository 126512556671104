import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../verbs';

export default async function DEL__conversations_CONVERSATIONID(params) {
  const {
    authToken,
    perspectiveId,
    conversationId,
  } = params;

  return await DELETE(
    `/conversations/${conversationId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
