import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Button from 'ui-library/components/Button';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LinkOffRoundedIcon from '@material-ui/icons/LinkOffRounded';
import Typography from 'ui-library/components/Typography';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import AddNewListItemButton from 'ui-library/components/AddNewListItemButton';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';
import theme from 'ui-library/theme';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  listContainer: {
    marginTop: theme.listContainer.marginTop,
  },
  listItemCategoryHeader: {
    height: theme.listItemCategoryHeader.height,
    paddingLeft: theme.listItemCategoryHeader.paddingLeft,
    marginTop: theme.listItemCategoryHeader.marginTop,
    display: theme.listItemCategoryHeader.display,
    alignItems: theme.listItemCategoryHeader.alignItems,
  },
  noResultsContainer: {
    display: theme.noResultsContainer.display,
    justifyContent: theme.noResultsContainer.justifyContent,
    textAlign: theme.noResultsContainer.textAlign,
    marginTop: theme.noResultsContainer.marginTop,
  },
});

const NodeLinksList = ({
  selectedNodeId,
  isLinksListLoading,
  handleAddNewLink,
  nodeLinks,
  handleRemoveLink,
  handleLinkInfo,
}) => {
  const classes = useStyles();

  if (isLinksListLoading) {
    return (
      <div className={classes.listContainer}>
        <ListSkeleton
          itemCount={5}
        />
      </div>
    );
  }

  const linksByLabelObj = {};
  nodeLinks.forEach(link => {
    const {
      definition: {
        label,
      },
    } = link;

    if (!linksByLabelObj[label]) {
      linksByLabelObj[label] = {
        label: label,
        links: [link],
      };
    } else {
      linksByLabelObj[label].links.push(link);
    }
  });
  const linksByLabelList = Object.values(linksByLabelObj).sort((a,b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : ((b.label.toLowerCase() > a.label.toLowerCase()) ? -1 : 0));

  return (
    <div className={classes.listContainer}>
      <AddNewListItemButton
        data-test-id="add-new-link"
        label="Add new link"
        onClick={handleAddNewLink}
      />
      {
        linksByLabelList.length === 0 ?
          <div className={classes.noResultsContainer}>
            <Typography
              variant="h4"
              color="grey-semi"
              fontWeight="bold"
            >
              This item is not linked to anything.
            </Typography>
          </div>
          :
          null
      }
      {
        linksByLabelList.map((listObj, index) => {
          const {
            label,
            links,
          } = listObj;
          return (
            <div
              key={index}
            >
              <div className={classes.listItemCategoryHeader}>
                <Typography
                  variant="caption"
                  color="primary"
                  fontWeight="bold"
                >
                  {label.toUpperCase()}
                </Typography>
              </div>
              {
                links.map((link, index) => {
                  const {
                    nodes,
                    definition,
                    originNodeId,
                    isLinkDeleting,
                  } = link;

                  const linkedNodeId = Object.keys(nodes).filter(key => key !== selectedNodeId.toString())[0];

                  const {
                    [linkedNodeId]: {
                      name: nodeName,
                      typeName,
                    },
                  } = nodes;

                  const qualifier = selectedNodeId === originNodeId ? definition.directQualifier : definition.reverseQualifier;

                  return (
                    <ListItem
                      key={index}
                      onClick={() => {
                        if (!isLinkDeleting) {
                          handleLinkInfo(linkedNodeId);
                        }
                      }}
                    >
                      <ListItemContentSegment
                        width={150}
                      >
                        <ListItemText>
                          <Typography
                            variant="x-small"
                          >
                            {qualifier}
                          </Typography>
                        </ListItemText>
                      </ListItemContentSegment>
                      <ListItemContentSegment>
                        <ListItemText>
                          <Typography
                            variant="body"
                            fontWeight="bold"
                          >
                            {nodeName}
                          </Typography>
                        </ListItemText>
                        <ListItemText>
                          <Typography
                            variant="caption"
                            color="grey"
                          >
                            {typeName}
                          </Typography>
                        </ListItemText>
                      </ListItemContentSegment>
                      <ListItemContentSegment
                        width={200}
                        justifyContent="flex-end"
                      >
                        <ListItemActions>
                          <Button
                            data-test-id="info-button"
                            style={{
                              height: 48,
                              width: 48,
                            }}
                            variant="icon-custom"
                            textTransform="none"
                            customBgColor="transparent"
                            customHoverBgColor="grey-semi"
                            customTextColor="grey-dark"
                            customHoverTextColor="grey-dark"
                            customActiveBgColor="grey-semi"
                            customActiveTextColor="grey-dark"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              if (!isLinkDeleting) {
                                handleLinkInfo(linkedNodeId);
                              }
                            }}
                          >
                            <InfoOutlinedIcon />
                          </Button>
                          <Button
                            data-test-id="delete-link-button"
                            style={{
                              height: 48,
                              width: 48,
                            }}
                            variant="icon-custom"
                            textTransform="none"
                            customBgColor="transparent"
                            customHoverBgColor="grey-semi"
                            customTextColor="grey-dark"
                            customHoverTextColor="grey-dark"
                            customActiveBgColor="grey-semi"
                            customActiveTextColor="grey-dark"
                            onClick={(ev) => {
                              ev.stopPropagation();
                              if (!isLinkDeleting) {
                                handleRemoveLink(link);
                              }
                            }}
                          >
                            <LinkOffRoundedIcon />
                          </Button>
                        </ListItemActions>
                      </ListItemContentSegment>
                    </ListItem>
                  );
                })
              }
            </div>
          );
        })
      }
    </div>
  );
};

NodeLinksList.propTypes = {
  selectedNodeId: PropTypes.number,
  isLinksListLoading: PropTypes.bool,
  handleAddNewLink: PropTypes.func,
  nodeLinks: PropTypes.array,
  handleRemoveLink: PropTypes.func,
  handleLinkInfo: PropTypes.func,
};

export default NodeLinksList;
