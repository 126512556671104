import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__nodesLinks_ID(authToken, perspectiveId, nodeLinkId, params) {

  let url = `/node-links/${nodeLinkId}`;

  if (params) {
    url += `?include=${params.join(',')}`;
  }

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
