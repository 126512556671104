import selectVirtualLocationTreeNodes from './selectVirtualLocationTreeNodes';

function selectLastVirtualLocationTreeNodeByHintId(
  configurationState,
  virtualLocationTreeNodeHintId,
) {
  const lastVirtualLocationTreeNodeIdByHintId =
    selectVirtualLocationTreeNodes(configurationState)
      .reverse()
      .find(virtualLocationTreeNode => virtualLocationTreeNode.hintId === virtualLocationTreeNodeHintId);

  if (lastVirtualLocationTreeNodeIdByHintId === undefined) {
    return null;
  }

  return lastVirtualLocationTreeNodeIdByHintId;
}

export default selectLastVirtualLocationTreeNodeByHintId;
