export default function fieldIsLongformText(
  params,
  errorMessage = 'invalid length',
) {
  const {
    value,
  } = params;

  if (value.length <= 65535) {
    return '';
  }
  return errorMessage;
}
