import GET__records_ID from 'network-layer/endpoints/GET__records_ID';

function responseAdapter(response) {
  return response;
}

export default async function genRecordByRecordId(authToken, perspectiveId, recordId) {
  const response = await GET__records_ID(authToken, perspectiveId, recordId);

  return responseAdapter(response);
}
