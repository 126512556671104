import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__records_ID_locationRoots(authToken, perspectiveId, params) {
  const {
    recordId,
    connectionId,
  } = params;

  return await GET(
    `/records/${recordId}/location-roots${connectionId !== undefined ? `?connectionId=${connectionId}` : ''}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
