export default function fieldIsUrl(
  params,
  errorMessage = 'invalid url',
) {
  const {
    value,
  } = params;

  const urlFormat = new RegExp(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);

  if (value === '' || urlFormat.test(value)) {
    return '';
  }
  return errorMessage;
}
