import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__genericTypes(authToken, perspectiveId, connectionId) {
  let url = '/generic-types';
  if (connectionId) {
    url += `?connectionId=${connectionId}`;
  }

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
