import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {GET} from '../../verbs';

export default async function GET__admin_users_USERID_properties(authToken, perspectiveId, userId) {
  return await GET(
    `/admin/users/${userId}/properties`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
