import React from 'react';
import styled from '@emotion/styled';
import {
  grey,
  greyLight,
} from 'ui-library/color-palette';
import SearchIcon from '@material-ui/icons/Search';

const Root = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  border: none;
  border-radius: .5em;
  background-color: ${greyLight};
  outline: none;
  padding: 1em 1em 1em 2.5em;
  font-size: 1rem;
  width: 100%;
`;

const LibraryFilterInput = props => {
  return (
    <Root>
      <StyledInput
        {...props}
      />
      <SearchIcon
        style={{
          color: grey,
          position: 'absolute',
          left: '.4em',
          top: '.55em',
        }}
      />
    </Root>
  );
};

export default LibraryFilterInput;
