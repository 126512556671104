import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {POST} from '../verbs';

export default async function POST_subscriptions_purchase_ID(authToken, perspectiveId, params) {
  const {
    subscriptionPlanId,
  } = params;

  const url = `/subscriptions/purchase/${subscriptionPlanId}`;

  return await POST(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {},
  );
}
