import React from 'react';
import styled from '@emotion/styled';
import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import CircularProgress from 'ui-library/components/CircularProgress';

import WarningIcon from '@material-ui/icons/WarningRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';

import internalCodeErrorMessage from 'api-error-codes/internalCodeErrorMessage';

import {
  greySemi,
} from 'ui-library/color-palette';

const NodesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  margin: 8px 0 0;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid ${greySemi};
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
`;

const BatchDeletePersonDialog = ({
  isOpen,
  onClose,
  onCancel,
  persons,
  deletePersons,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false);

  const [failedToDeleteCount, setFailedToDeleteCount] = React.useState(0);
  const [failedToDeletePersons, setFailedToDeletePersons] = React.useState([]);

  const formatFailedToDeleteNodes = (failedToDelete) => {
    const failedNodesByError = {};
    failedToDelete.forEach(person => {
      const {
        code,
        selectedPersonId,
      } = person;

      if (!failedNodesByError[code]) {
        failedNodesByError[code] = {
          code: code,
          failedNodes: [persons.find(person => person.id === selectedPersonId)],
        };
      } else {
        failedNodesByError[code].failedNodes.push(persons.find(person => person.id === selectedPersonId));
      }
    });
    return Object.values(failedNodesByError);
  };

  const handleDeleteNodes = async() => {
    setIsDeleting(true);
    const failedToDelete = await deletePersons();

    if (failedToDelete.length === 0) {
      onClose();
    } else {
      const failedToDeleteNodesByError = formatFailedToDeleteNodes(failedToDelete);
      setFailedToDeleteCount(failedToDelete.length);
      setFailedToDeletePersons(failedToDeleteNodesByError);
    }

    setIsDeleting(false);
  };

  if (failedToDeleteCount) {
    return (
      <ModalCard
        open={isOpen}
        handleClose={() => {
          onClose();
        }}
        fullWidth={true}
        disableBackdropClick={true}
      >
        <ModalCardHeader color="warning">
          <ModalCardHeaderIcon color="warning">
            <WarningIcon fontSize="inherit"/>
          </ModalCardHeaderIcon>
          <ModalCardHeaderTitle>
                Not all items have been deleted
          </ModalCardHeaderTitle>
        </ModalCardHeader>
        <ModalCardContent maxHeight={30}>
          <Typography variant="body">
                The following {failedToDeleteCount} items could not be deleted.
          </Typography>
          {
            failedToDeletePersons.map((listObj, index) => {
              const {
                code,
                failedNodes,
              } = listObj;
              return (
                <NodesContainer key={index}>
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    color="primary">
                    {internalCodeErrorMessage(code).toUpperCase()}
                  </Typography>
                  {
                    failedNodes.map((node, index) => {
                      const {
                        name,
                        typeName,
                      } = node;
                      return (
                        <ListItem key={index}>
                          <ListItemContentSegment>
                            <ListItemText>
                              <Typography variant="small" fontWeight="bold">
                                {name}
                              </Typography>
                            </ListItemText>
                            <ListItemText>
                              <Typography variant="caption" color="grey">
                                {typeName}
                              </Typography>
                            </ListItemText>
                          </ListItemContentSegment>
                        </ListItem>
                      );
                    })
                  }
                </NodesContainer>
              );
            })
          }
        </ModalCardContent>
        <ModalCardActions>
          <Button
            data-test-id="batch-delete-dialog-delete-button"
            variant="primary"
            onClick={() => {
              onClose();
              setFailedToDeletePersons([]);
            }}>
                  OK
          </Button>
        </ModalCardActions>
      </ModalCard>
    );
  }

  return (
    <ModalCard
      open={isOpen}
      handleClose={() => {
        onClose();
      }}
      fullWidth={true}
      disableBackdropClick={true}
    >
      <ModalCardHeader color="error">
        <ModalCardHeaderIcon color="error">
          <WarningIcon fontSize="inherit"/>
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle>
            Delete Person
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent maxHeight={30}>
        <Typography variant="body">
            Are you sure you want to delete the following person(s)?
        </Typography>
        <NodesContainer>
          {
            persons.map(person => {
              const {
                id,
                name,
              } = person;
              return (
                <Typography
                  key={id}
                  variant="caption"
                  fontWeight="bold">
                  {name}
                </Typography>
              );
            })
          }
        </NodesContainer>
        <Typography variant="caption">
            All attachments and links associated with this person(s) will be permanently deleted.
        </Typography>
        <br/><br/>
        <Typography
          variant="caption"
          fontWeight="bold"
          color="error">
            Additionally, all items assigned to the Personal Property and Personal Information Records for this person(s) will be permanently deleted.
        </Typography>
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="batch-delete-dialog-cancel-button"
          variant="text"
          onClick={() => onCancel()}
          disabled={isDeleting}
        >
              CANCEL
        </Button>
        <Button
          data-test-id="batch-delete-dialog-delete-button"
          variant="custom"
          textTransform="none"
          customBgColor="error"
          customHoverBgColor="error-dark"
          customTextColor="white"
          customHoverTextColor="white"
          customActiveBgColor="error-darker"
          customActiveTextColor="white"
          startIcon={isDeleting ? <CircularProgress size={24} color="white"/> : <DeleteIcon />}
          disabled={isDeleting}
          onClick={handleDeleteNodes}
        >
          {isDeleting ? 'DELETING...' : 'DELETE'}
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

export default BatchDeletePersonDialog;
