import defaultConfig from '../config/defaultConfig';
import configWithContentType from '../config/configWithContentType';
import {POST} from '../verbs';

export default async function POST__verifyEmail_resendEmail(email) {
  return await POST(
    '/verify-email/resend-email',
    configWithContentType(defaultConfig(), 'application/json'),
    {
      email,
    },
  );
}
