import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../verbs';

export default async function PATCH__attachments_ID(authToken, perspectiveId, nodeId, params) {
  return await PATCH(
    `/attachments/${nodeId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
