export default function name(
  value,
  errorMessage = 'Name is required and can have maximum 80 characters!',
) {

  if (value.trim().length === 0) {
    return errorMessage;
  }

  if (value.length > 80) {
    return errorMessage;
  }

  return '';
}
