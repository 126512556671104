import ParseError from '../errors/ParseError';
import CustomError from '../errors/CustomError';
import UnknownSuccessCodeError from '../errors/UnknownSuccessCodeError';
import UnknownContentTypeHeaderError from '../errors/UnknownContentTypeHeaderError';

export default async function successBody(response) {
  const SUPPORTED_CONTENT_TYPE_HEADERS = [
    'image/jpeg',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'application/vnd.ms-office',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
    'application/vnd.ms-excel.template.macroEnabled.12',
    'application/vnd.ms-excel.addin.macroEnabled.12',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    'application/octet-stream',
  ];

  if (!response.ok) {
    let errorBody;

    try {
      errorBody = await response.json();
    } catch(parseError) {
      throw new ParseError(parseError);
    }

    throw CustomError(response.status, errorBody);
  }

  const contentTypeHeader = response.headers.get('Content-Type');

  switch(response.status) {
    case 200: {
      if (contentTypeHeader === 'application/json') {
        try {
          const successBody = await response.json();
          return successBody;
        } catch(parseError) {
          throw new ParseError(parseError);
        }
      }

      if (SUPPORTED_CONTENT_TYPE_HEADERS.includes(contentTypeHeader)) {
        try {
          const successBody = await response.blob();
          return successBody;
        } catch(blobError) {
          throw new ParseError(blobError);
        }
      }

      throw new UnknownContentTypeHeaderError(response.status);
    }
    case 201: {
      if (contentTypeHeader === 'application/json') {
        try {
          const successBody = await response.json();
          return successBody;
        } catch(parseError) {
          throw new ParseError(parseError);
        }
      }

      return null;
    }
    case 202: {
      return null;
    }
    case 204: {
      return null;
    }
    default:
      throw new UnknownSuccessCodeError(response.status);
  }
}
