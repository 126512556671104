import React from 'react';
import styled from '@emotion/styled';

const Root = styled.div`
  display: flex;
  flex: ${props => props.width ? null : props.weight};
  width: ${props => props.width};
`;

const DrilldownTrackerSegment = ({
  weight,
  width,
  children,
}) => {
  return (
    <Root
      weight={weight}
      width={width}
    >
      {children}
    </Root>
  );
};

export default DrilldownTrackerSegment;
