import selectVirtualLocationTreeNodeHints from './selectVirtualLocationTreeNodeHints';

function selectVirtualLocationTreeNodeHintChildIdsById(DM, virtualLocationTreeNodeHintId) {
  return (
    selectVirtualLocationTreeNodeHints(DM)
      .filter(virtualLocationTreeNodeHint => {
        const {
          parentId,
        } = virtualLocationTreeNodeHint;
        return virtualLocationTreeNodeHintId === parentId;
      })
      .map(virtualLocationTreeNodeHint => virtualLocationTreeNodeHint.id)
  );
}

export default selectVirtualLocationTreeNodeHintChildIdsById;
