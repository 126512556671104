import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import FilterRowClearButton from 'ui-library/components/FilterRowClearButton';
import AddNewFilterButton from 'ui-library/components/AddNewFilterButton';
import SearchIcon from '@material-ui/icons/SearchRounded';

const useStyles = makeStyles({
  root: {
    display: theme.filterRow.root.display,
    transition: theme.filterRow.root.transition,
    marginBottom: theme.filterRow.root.marginBottom,
    marginTop: theme.filterRow.root.marginTop,
    height: theme.filterRow.root.height,
    borderRadius: theme.filterRow.root.borderRadius,
    backgroundColor: theme.filterRow.root.backgroundColor,
    boxShadow: theme.filterRow.boxShadow,
    minWidth: theme.filterRow.root.minWidth,
    justifyContent: theme.filterRow.root.justifyContent,
    '&:hover': {
      cursor: theme.filterRow.root.cursor,
      boxShadow: theme.filterRow.hoverBoxShadow,
    },
  },
  children: {
    display: theme.filterRow.children.display,
    flexDirection: theme.filterRow.children.flexDirection,
    flexWrap: theme.filterRow.children.flexWrap,
    alignItems: theme.filterRow.children.alignItems,
    padding: theme.filterRow.children.padding,
    flexGrow: theme.filterRow.children.flexGrow,
  },
  iconButton: {
    margin: theme.filterRow.iconButton.margin,
    alignItems: theme.filterRow.iconButton.alignItems,
  },
  searchIcon: {
    color: theme.filterRow.searchIcon.color,
    margin: theme.filterRow.searchIcon.margin,
    alignSelf: theme.filterRow.searchIcon.alignSelf,
    display: theme.filterRow.searchIcon.display,
    alignItems: theme.filterRow.searchIcon.alignItems,
  },
});

const FilterRow = ({
  children,
  hasActiveCriteria,
  clearFilters,
  isAddNewFilterDisabled,
  isAddNewFilterActive,
  handleAddNewFilter,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <AddNewFilterButton
          data-test-id="add-new-filter-button"
          disabled={isAddNewFilterDisabled}
          active={isAddNewFilterActive}
          onClick={handleAddNewFilter}
        />
      </div>
      <div className={classes.children}>
        {children}
      </div>
      {
        hasActiveCriteria ?
          <div className={classes.iconButton}>
            <FilterRowClearButton
              onClick={clearFilters}
            />
          </div>
          :
          <SearchIcon
            className={classes.searchIcon}
          />
      }
    </div>
  );
};

FilterRow.propTypes = {
  children: PropTypes.node,
  hasActiveCriteria: PropTypes.bool,
  clearFilters: PropTypes.func,
};

export default FilterRow;
