import queryString from 'query-string';
import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';

export default async(location, authToken, perspectiveId) => {
  const {
    locations: searchLocations,
  } = queryString.parse(location.search);

  let locations = [];

  if (!searchLocations) {
    return locations;
  }

  locations = await Promise.all(searchLocations.split(',').map(async(locationId, locationIndex) => {
    const {
      name,
    } = await genNodeByNodeId({
      authToken,
      perspectiveId,
      nodeId: locationId,
    });

    return {
      path: location.pathname + '?locations=' + searchLocations.split(',').slice(0, locationIndex + 1).join(','),
      label: name,
    };
  }));

  return locations;
};
