import React from 'react';

import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemActions from 'ui-library/components/ListItemActions';
import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';
import Menu from 'ui-library/components/Menu';
import MenuItem from 'ui-library/components/MenuItem';

import MoreHorizIcon from '@material-ui/icons/MoreHorizRounded';
import PanelIcon from '@material-ui/icons/VerticalSplitRounded';

const ListItemActionsWrapper = React.memo(({
  id,
  onNodeAction,
}) => {

  const [contextualMenuState, setContextualMenuState] = React.useState({
    id: null,
    anchorEl: null,
  });

  return (
    <>
      <ListItemContentSegment
        width={200}
        justifyContent="flex-end"
      >
        <ListItemActions>
          <Button
            data-test-id="item-context-menu-button"
            variant="icon-custom"
            textTransform="none"
            customBgColor="transparent"
            customHoverBgColor="primary-lightest"
            customTextColor="grey"
            customHoverTextColor="primary-dark"
            customActiveBgColor="white"
            customActiveTextColor="primary-darker"
            onClick={event => {
              event.stopPropagation();
              setContextualMenuState({
                id,
                anchorEl: event.currentTarget,
              });
            }}
          >
            <MoreHorizIcon />
          </Button>
          <Button
            data-test-id="item-open-node-panel-button"
            textTransform="none"
            variant="icon-custom"
            customBgColor="transparent"
            customHoverBgColor="primary-lightest"
            customTextColor="grey"
            customHoverTextColor="primary-dark"
            customActiveBgColor="white"
            customActiveTextColor="primary-darker"
            onClick={event => {
              event.stopPropagation();
              onNodeAction(id, 'expand');
            }}>
            <PanelIcon/>
          </Button>
        </ListItemActions>
      </ListItemContentSegment>

      <Menu
        open={contextualMenuState.id === id}
        anchorEl={contextualMenuState.anchorEl}
        onClose={() => {
          setContextualMenuState({
            id: null,
            anchorEl: null,
          });
        }}>
        <MenuItem
          data-test-id="context-menu-view-button"
          onClick={event => {
            event.stopPropagation();
            onNodeAction(id, 'expand');
            setContextualMenuState({
              id: null,
              anchorEl: null,
            });
          }}
        >
          <Typography variant="small">
            View Details
          </Typography>
        </MenuItem>
        <MenuItem
          data-test-id="context-menu-share-button"
          onClick={event => {
            event.stopPropagation();
            onNodeAction(id, 'share');
            setContextualMenuState({
              id: null,
              anchorEl: null,
            });
          }}
        >
          <Typography variant="small">
            Share Item
          </Typography>
        </MenuItem>
        <MenuItem
          data-test-id="context-menu-delete-button"
          onClick={event => {
            event.stopPropagation();
            onNodeAction(id, 'delete');
            setContextualMenuState({
              id: null,
              anchorEl: null,
            });
          }}
        >
          <Typography variant="small" color="error">
            Delete Item
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}, (prevProps, nextProps) => {
  return true;
});

export default ListItemActionsWrapper;
