import React from 'react';

export default function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function currentCallback() {
      savedCallback.current();
    }
    let id = setInterval(currentCallback, delay);
    return () => clearInterval(id);
  }, [delay]);
}
