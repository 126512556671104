import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import theme from 'ui-library/theme';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles({
  root: {
    color: theme.radioButton.color,
    borderColor: theme.radioButton.borderColor,
    '&:hover': {
      backgroundColor: theme.radioButton.backgroundColor,
    },
    '&$checked': {
      color: theme.radioButton['checked'].color,
      borderColor: theme.radioButton['checked'].borderColor,
      '&:hover': {
        backgroundColor: theme.radioButton.backgroundColor,
      },
    },
    '&$disabled': {
      color: theme.radioButton['disabled'].color,
      backgroundColor: theme.radioButton['disabled'].backgroundColor,
    },
  },
  checked: {},
  disabled: {},
});

export default function RadioButton({
  ...otherProps
}) {
  const classes = useStyles();
  return (
    <Radio
      disableRipple
      classes={{
        root: classes.root,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      {...otherProps}
    />
  );
}
