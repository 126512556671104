import POST__users from 'network-layer/endpoints/POST__users';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return response;
}

export default async function createAccount(params) {
  const {
    accountType,
    email,
    password,
    firstName,
    lastName,
    businessName,
  } = params;

  const requestObject = {
    email,
    password,
    firstName,
    lastName,
  };

  if (accountType === 'personal') {
    requestObject.groupType = 'household';
  } else {
    requestObject.groupType = 'company';
    requestObject.groupName = businessName;
  }

  const response = await POST__users(requestObject);

  return responseAdapter(response);
}
