import POST__attachments from 'network-layer/endpoints/POST__attachments';

function responseAdapter(response) {
  return response;
}

export default async function uploadAttachment(authToken, perspectiveId, uploadRequest, params) {
  const response = await POST__attachments(authToken, perspectiveId, uploadRequest, params);

  return responseAdapter(response);
}
