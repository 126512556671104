import React from 'react';
import styled from '@emotion/styled';

import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import ConversationPanelHeader from 'components/chrome/ConversationPanelHeader';
import ConversationPanelContent from 'components/chrome/ConversationPanelContent';
import ConversationHeader from 'components/ConversationHeader';
import ConversationFooter from 'components/ConversationFooter';
import ConversationStatusNotice from 'components/ConversationStatusNotice';
import CommentList from 'components/CommentList';
import DeleteCommentDialog from 'components/DeleteCommentDialog';

import Message from 'ui-library/components/Message';
import InlineMessage from 'ui-library/components/InlineMessage';
import Typography from 'ui-library/components/Typography';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import addConversationComment from 'services/Conversations/addConversationComment';
import addConversationCommentReply from 'services/Conversations/addConversationCommentReply';
import updateConversationComment from 'services/Conversations/updateConversationComment';
import deleteConversationComment from 'services/Conversations/deleteConversationComment';
import genConversationComments from 'services/Conversations/genConversationComments';

import {
  conversationPanelNavigationHeight,
  conversationPanelNavigationGutter,
} from 'constants/layout';

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const DeletedConversationContainer = styled.div`
  padding: calc(${conversationPanelNavigationHeight}px + ${conversationPanelNavigationGutter}px) 35px;
`;

const ConversationContainer = ({
  newConversationConnectionId,
  conversationConnectionName,
  conversationInterlocutorOrganizationName,
  conversationTetherName,
  conversation,
  onCreateNewConversation,
  onUpdateConversationStatus,
}) => {

  const [comments, setComments] = React.useState([]);
  const [originalComment, setOriginalComment] = React.useState(null);
  // 'add', 'edit', 'reply'
  const [status, setStatus] = React.useState('add');
  const [message, setMessage] = React.useState('');

  const [deleteComment, setDeleteComment] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const [shouldScrollToBottom, setShouldScrollToBottom] = React.useState(false);

  const {
    authToken,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: perspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const {
    id: conversationId,
    status: conversationStatus,
  } = conversation || {};

  const loadComments = React.useCallback(async() => {
    const response = await genConversationComments({authToken, perspectiveId, conversationId});
    setComments(response);
  }, [authToken, conversationId, perspectiveId]);

  React.useEffect(() => {
    if (conversationId) {
      loadComments();
    }
  }, [conversationId, loadComments]);

  if (isLoading) {
    return <CenterpieceSpinner />;
  }

  if (!conversation && !newConversationConnectionId) {
    return (
      <DeletedConversationContainer>
        <InlineMessage
          variant="error"
          open={true}
          persistent={true}
          messageTitle="Conversation not found"
          messageBody={
            <Typography variant="body">
              This conversation was not found. It may have been deleted.
            </Typography>
          }
        />

      </DeletedConversationContainer>
    );
  }

  return (
    <>
      <ConversationPanelHeader>

        <ConversationHeader
          conversationConnectionName={conversationConnectionName}
          conversationInterlocutorOrganizationName={conversationInterlocutorOrganizationName}
          conversationTetherName={conversationTetherName}
        />

      </ConversationPanelHeader>

      {
        (conversationStatus === 'archived' || conversationStatus === 'frozen') &&
        <ConversationStatusNotice
          status={conversationStatus}
          onClick={async() => {
            if (conversationStatus !== 'archived') {
              return;
            }
            setIsLoading(true);
            await onUpdateConversationStatus(conversationId, 'active');
            setIsLoading(false);
          }}
        />
      }

      <ConversationPanelContent
        hasNotice={conversationStatus === 'archived' || conversationStatus === 'frozen'}
      >
        <PaddedContainer>
          <CommentList
            comments={comments}
            conversation={conversation}
            commentsHaveContextualMenu={conversationStatus !== 'frozen' && conversationStatus !== 'archived'}
            onReply={commentData => {
              setOriginalComment(commentData);
              setStatus('reply');
            }}
            onEdit={commentData => {
              setOriginalComment(commentData);
              setStatus('edit');
              setMessage(commentData.message);
            }}
            onDelete={commentData => {
              setDeleteComment(commentData);
            }}
            shouldScrollToBottom={shouldScrollToBottom}
          />
        </PaddedContainer>

      </ConversationPanelContent>

      {
        conversationStatus !== 'frozen' && conversationStatus !== 'archived' &&
        <>
          <ConversationFooter
            originalComment={originalComment}
            commentStatus={status}
            message={message}
            disabled={comments === null || message === '' || isDeleting}
            onChange={(event) => setMessage(event.target.value)}
            onAddComment={async() => {
              setShouldScrollToBottom(true);

              if (conversationId) {
                await addConversationComment({
                  authToken,
                  perspectiveId,
                  conversationId,
                  message,
                });
                await loadComments();
              }

              if (!conversationId && newConversationConnectionId) {
                onCreateNewConversation(newConversationConnectionId, message);
              }

              setMessage('');
            }}
            onEditComment={async() => {
              setShouldScrollToBottom(false);

              await updateConversationComment({
                authToken,
                perspectiveId,
                conversationId,
                commentId: originalComment.id,
                message,
              });
              await loadComments();
              setMessage('');
              setOriginalComment(null);
              setStatus('add');
            }}
            onReplyComment={async() => {
              setShouldScrollToBottom(true);

              await addConversationCommentReply({
                authToken,
                perspectiveId,
                conversationId,
                commentId: originalComment.id,
                message,
              });
              await loadComments();
              setMessage('');
              setOriginalComment(null);
              setStatus('add');
            }}
            onCancelEdit={() => {
              setMessage('');
              setOriginalComment(null);
              setStatus('add');
            }}
            onCancelReply={() => {
              setOriginalComment(null);
              setStatus('add');
            }}
          />

          <DeleteCommentDialog
            deleteComment={deleteComment}
            isDeleting={isDeleting}
            onCancel={() => setDeleteComment(null)}
            onDelete={async() => {
              setIsDeleting(true);
              try {
                setComments(null);
                setOriginalComment(null);
                if (status === 'edit') setMessage('');
                await deleteConversationComment({
                  authToken,
                  perspectiveId,
                  conversationId: conversationId,
                  commentId: deleteComment.id,
                });
                setDeleteMessage({
                  variant: 'success',
                  messageBody: 'Successfully deleted comment.',
                });
                await loadComments();
              } catch (e) {
                setDeleteMessage({
                  variant: 'error',
                  messageBody: 'Something went wrong. Please try again.',
                });
              }
              setDeleteComment(null);
              setIsDeleting(false);
            }}
          />
          {deleteMessage && (
            <Message
              variant={deleteMessage.variant}
              open={true}
              onClose={() => setDeleteMessage(null)}
              autoHideDuration={3000}
              messageBody={deleteMessage.messageBody}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              width={532}
            />
          )}
        </>
      }
    </>
  );
};

export default ConversationContainer;
