import POST__notifications_devices from 'network-layer/endpoints/POST__notifications_devices';

function responseAdapter(response) {
  return response;
}

export default async function addNotificationsDevice(authToken, playerId) {
  const response = await POST__notifications_devices(authToken, playerId);

  return responseAdapter(response);
}
