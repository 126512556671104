import React from 'react';
import {Link} from 'react-router-dom';
import styled from '@emotion/styled';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';

const LoginTextContainer = styled.div`
  padding: 7px 3px 0 0;
`;

const LoginRedirect = () => {
  return (
    <>
      <LoginTextContainer>
        <Typography
          variant="small"
          fontWeight="normal"
          fontFamily="primary"
          color="grey"
        >
          Have an account?
        </Typography>
      </LoginTextContainer>
      <Link
        to={{pathname: '/sign-in'}}
        style={{textDecoration: 'none'}}
      >
        <Button
          data-test-id="go-to-login-btn"
          variant="text"
          textTransform="none"
        >
          Log In.
        </Button>
      </Link>
    </>
  );
};

export default LoginRedirect;
