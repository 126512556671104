import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100px',
    alignItems: 'center',
  },
});

const ListItemActions = ({
  children,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

ListItemActions.propTypes = {
  children: PropTypes.node,
};

export default ListItemActions;
