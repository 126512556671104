import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_notificationPipelines_NOTIFICATIONPIPELINEID(authToken, perspectiveId, params) {
  const {
    notificationPipelineId,
  } = params;

  return await DELETE(
    `/admin/notification-pipelines/${notificationPipelineId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
