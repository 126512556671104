function selectNodeLinkDefinitionById(DM, nodeLinkDefinitionId) {
  const {
    nodeLinkDefinitionsById: {
      [nodeLinkDefinitionId]: nodeLinkDefinition,
    },
  } = DM;

  return nodeLinkDefinition;
}

export default selectNodeLinkDefinitionById;
