import defaultConfig from '../config/defaultConfig';
import configWithContentType from '../config/configWithContentType';
import {POST} from '../verbs';

export default async function POST__login(params) {
  const {
    email,
    password,
  } = params;

  const login = await POST(
    '/login',
    configWithContentType(defaultConfig(), 'application/json'),
    {
      email,
      password,
    },
  );
  return login;
}
