import defaultConfig from '../config/defaultConfig';
import configWithContentType from '../config/configWithContentType';
import {GET} from '../verbs';

export default async function GET__isEmailAvailable(email) {

  return await GET(
    `/is-email-available?email=${email}`,
    configWithContentType(defaultConfig(), 'application/json'),
  );
}
