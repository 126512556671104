import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {PATCH} from '../verbs';

export default async function PATCH__users_firstName(authToken, params) {
  return await PATCH(
    '/users/first-name',
    configWithAuthorization(defaultConfig(), authToken),
    {
      ...params,
    },
  );
}
