import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import Typography from 'ui-library/components/Typography';
import MUITabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
  root: {
    color: theme.tabs.root.color,
    '&$selected': {
      color: theme.tabs.selected.color,
    },
    minWidth: '72px',
    padding: '6px 16px',
  },
  selected: {},
  indicator: {
    backgroundColor: theme.tabs.indicator.color,
  },
});

const Tabs = ({
  value,
  onChange,
  options,
}) => {

  const classes = useStyles();

  return (
    <MUITabs
      classes={{
        indicator: classes.indicator,
      }}
      value={value}
      onChange={onChange}
    >
      {
        options.map(option => {
          const {
            label,
            value,
          } = option;
          return (
            <Tab
              data-test-id={'tab-' + value}
              classes={{
                root: classes.root,
                selected: classes.selected,
              }}
              key={value}
              value={value}
              label={
                <Typography variant="caption" fontWeight="bold">
                  {label}
                </Typography>
              }
            />
          );
        })
      }
    </MUITabs>
  );
};

Tabs.propTypes = {
  onChange: PropTypes.func,
};

export default Tabs;
