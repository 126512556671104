import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import Button from 'ui-library/components/Button';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    display: theme.filterCloseButton.display,
  },
});

const FilterCloseButton = ({
  onClick,
}) => {

  const classes = useStyles();

  return (
    <div
      className={classes.root}
    >
      <Button
        variant="icon-custom"
        textTransform="none"
        customBgColor="tertiary-lightest"
        customTextColor="tertiary-light"
        customHoverBgColor="tertiary-lightest"
        customHoverTextColor="tertiary-light"
        customActiveBgColor="tertiary-lightest"
        customActiveTextColor="tertiary-light"
        onClick={onClick}
      >
        <CloseIcon/>
      </Button>
    </div>
  );
};

FilterCloseButton.propTypes = {
  onClick: PropTypes.func,
};

export default FilterCloseButton;
