import POST__forgotPassword from 'network-layer/endpoints/POST__forgotPassword';

function responseAdapter(response) {
  return response;
}

export default async function forgotPassword(email) {
  const response = await POST__forgotPassword(email);

  return responseAdapter(response);
}
