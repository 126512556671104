function selectTypeLocationGraphChildIdsOfTypeById(DM, typeId) {
  const {
    typeLocationGraph: {
      [typeId]: childIds,
    },
  } = DM;

  if (!childIds) {
    return [];
  }

  return childIds;
}

export default selectTypeLocationGraphChildIdsOfTypeById;
