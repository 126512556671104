function selectPropertyConfigurationHints(DM) {
  const {
    propertyConfigurationHintsById,
    propertyConfigurationHintIds,
  } = DM;

  return (
    propertyConfigurationHintIds
      .map(propertyConfigurationHintId => propertyConfigurationHintsById[propertyConfigurationHintId])
  );
}

export default selectPropertyConfigurationHints;
