import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const AddTypeToRecordTypeDialog = ({
  open,
  onClose,
  recordTypeName,
  candidateTypes,
  typeId,
  onSetTypeId,
  onSave,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Associate a type with the "{recordTypeName}" record type
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a type and associate it with {recordTypeName} records. Doing this will allow users to assign items of that type to {recordTypeName} records.
        </DialogContentText>
        <Select
          fullWidth
          value={typeId}
          onChange={event => {
            onSetTypeId(event.target.value);
          }}>
          {
            candidateTypes.map(type => {
              const {
                id: typeId,
                name: typeName,
              } = type;
              return (
                <MenuItem
                  key={typeId}
                  value={typeId}>
                  {typeName}
                </MenuItem>
              );
            })
          }
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            typeId === ''
          }
          variant="contained"
          color="primary"
          onClick={onSave}>
          associate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTypeToRecordTypeDialog;
