import React from 'react';
import styled from '@emotion/styled';

import CloseIcon from '@material-ui/icons/CloseRounded';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';

import {
  tertiaryLightest,
  greySemi,
} from 'ui-library/color-palette';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${props => props.isOwn ? tertiaryLightest : greySemi};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 60px 17px 60px;
`;

const ButtonContainer = styled.div`
  align-self: flex-start;
  padding: 8px;
`;

const ConversationOriginalComment = ({
  isOwn,
  actionName,
  originalMessage,
  onCancel,
}) => {
  return (
    <Root isOwn={isOwn}>
      <MessageContainer>
        <Typography variant="caption" fontWeight="bold">
          {actionName}
        </Typography>
        <Typography
          variant="body"
          fontStyle="italic"
        >
          {originalMessage}
        </Typography>
      </MessageContainer>
      <ButtonContainer>
        <Button
          data-test-id="cancel-edit-reply-comment"
          variant="icon-custom"
          textTransform="none"
          customBgColor="transparent"
          customTextColor="black"
          customHoverBgColor="transparent"
          customHoverTextColor="tertiary-light"
          customActiveBgColor="transparent"
          customActiveTextColor="black"
          onClick={onCancel}
        >
          <CloseIcon/>
        </Button>
      </ButtonContainer>
    </Root>
  );
};

export default ConversationOriginalComment;
