import {
  primaryLightest,
  primaryLighter,
  primaryLight,
  primary,
  primaryDark,
  primaryDarker,
  primaryDarkest,
  secondaryLightest,
  secondaryLighter,
  secondaryLight,
  secondary,
  secondaryDark,
  secondaryDarker,
  secondaryDarkest,
  tertiaryLightest,
  tertiaryLighter,
  tertiaryLight,
  tertiary,
  tertiaryDark,
  tertiaryDarker,
  tertiaryDarkest,
  successLightest,
  successLighter,
  successLight,
  success,
  successDark,
  successDarker,
  successDarkest,
  warningLightest,
  warningLighter,
  warningLight,
  warning,
  warningDark,
  warningDarker,
  warningDarkest,
  errorLightest,
  errorLighter,
  errorLight,
  error,
  errorDark,
  errorDarker,
  errorDarkest,
  greyLightest,
  greyLighter,
  greyLight,
  greySemi,
  grey,
  greyDark,
  greyDarker,
  white,
  black,
  logoGrey,
  logoGreen,
  inherit,
  transparent,
} from 'ui-library/color-palette';

export default {
  typography: {
    fontFamily: {
      primary: '"Roboto", "Helvetica", "Arial", sans-serif',
      secondary: '"Lucida Console", Monaco, monospace',
      inherit: 'inherit',
    },
    fontWeight: {
      bold: 'bold',
      normal: 'normal',
      inherit: 'inherit',
    },
    fontStyle: {
      normal: 'normal',
      italic: 'italic',
    },
    uber: {
      fontSize: '112px',
      lineHeight: '1.07',
    },
    hero: {
      fontSize: '80px',
      lineHeight: '1.2em',
    },
    h1: {
      fontSize: '48px',
      lineHeight: '1.25em',
    },
    h2: {
      fontSize: '37px',
      lineHeight: '1.41em',
    },
    h3: {
      fontSize: '31px',
      lineHeight: '1.55em',
    },
    h4: {
      fontSize: '26px',
      lineHeight: '1.54em',
    },
    h5: {
      fontSize: '21px',
      lineHeight: '1.52em',
    },
    lead: {
      fontSize: '24px',
      lineHeight: '1.5em',
    },
    body: {
      fontSize: '18px',
      lineHeight: '1.78em',
    },
    small: {
      fontSize: '16px',
      lineHeight: '1.75em',
    },
    caption: {
      fontSize: '14px',
      lineHeight: '1.43em',
    },
    'x-small': {
      fontSize: '12px',
      lineHeight: '1.67em',
    },
    tiny: {
      fontSize: '10px',
      lineHeight: '1.6em',
    },
    inherit: {
      fontSize: 'inherit',
      lineHeight: '1.78em',
    },
  },

  palette: {
    // PRIMARY
    'primary-lightest': primaryLightest,
    'primary-lighter': primaryLighter,
    'primary-light': primaryLight,
    'primary': primary,
    'primary-dark': primaryDark,
    'primary-darker': primaryDarker,
    'primary-darkest': primaryDarkest,
    // SECONDARY
    'secondary-lightest': secondaryLightest,
    'secondary-lighter': secondaryLighter,
    'secondary-light': secondaryLight,
    'secondary': secondary,
    'secondary-dark': secondaryDark,
    'secondary-darker': secondaryDarker,
    'secondary-darkest': secondaryDarkest,
    // TERTIARY
    'tertiary-lightest': tertiaryLightest,
    'tertiary-lighter': tertiaryLighter,
    'tertiary-light': tertiaryLight,
    'tertiary': tertiary,
    'tertiary-dark': tertiaryDark,
    'tertiary-darker': tertiaryDarker,
    'tertiary-darkest': tertiaryDarkest,
    // SUCCESS
    'success-lightest': successLightest,
    'success-lighter': successLighter,
    'success-light': successLight,
    'success': success,
    'success-dark': successDark,
    'success-darker': successDarker,
    'success-darkest': successDarkest,
    // WARNINGZ
    'warning-lightest': warningLightest,
    'warning-lighter': warningLighter,
    'warning-light': warningLight,
    'warning': warning,
    'warning-dark': warningDark,
    'warning-darker': warningDarker,
    'warning-darkest': warningDarkest,
    // ERROR
    'error-lightest': errorLightest,
    'error-lighter': errorLighter,
    'error-light': errorLight,
    'error': error,
    'error-dark': errorDark,
    'error-darker': errorDarker,
    'error-darkest': errorDarkest,
    // GREY
    'grey-lightest': greyLightest,
    'grey-lighter': greyLighter,
    'grey-light': greyLight,
    'grey-semi': greySemi,
    'grey': grey,
    'grey-dark': greyDark,
    'grey-darker': greyDarker,
    // BLACK & WHITE
    'white': white,
    'black': black,
    // LOGO
    'logo-grey': logoGrey,
    'logo-green': logoGreen,
    // INHERIT
    'inherit': inherit,
    // TRANSPARENT
    'transparent': transparent,
  },

  button: {
    boxShadow: 'none',
    fontWeight: 'bold',
    textAlign: 'center',
    custom: {
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '55px',
    },
    'custom-toolbar': {
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '55px',
    },
    outlined: {
      border: {
        default: `2px solid ${primary}`,
        hover: `2px solid ${primaryDark}`,
        active: `2px solid ${primaryDarker}`,
        disabled: `2px solid ${grey}`,
      },
      background: white,
      color: primary,
      hoverBackground: white,
      hoverColor: primaryDark,
      activeBackground: white,
      activeColor: primaryDarker,
      disabledBackground: white,
      disabledColor: grey,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '55px',
    },
    'icon-outlined': {
      border: {
        default: `2px solid ${primary}`,
        hover: `2px solid ${primaryDark}`,
        active: `2px solid ${primaryDarker}`,
        disabled: `2px solid ${grey}`,
      },
      background: white,
      color: primary,
      hoverBackground: white,
      hoverColor: primaryDark,
      activeBackground: white,
      activeColor: primaryDarker,
      disabledBackground: white,
      disabledColor: grey,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '40px',
    },
    primary: {
      background: primary,
      color: white,
      hoverBackground: primaryDark,
      hoverColor: white,
      activeBackground: primaryDarker,
      activeColor: white,
      disabledBackground: grey,
      disabledColor: white,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '55px',
    },
    'icon-primary': {
      background: primary,
      color: white,
      hoverBackground: primaryDark,
      hoverColor: white,
      activeBackground: primaryDarker,
      activeColor: white,
      disabledBackground: grey,
      disabledColor: white,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '40px',
    },
    secondary: {
      background: secondary,
      color: white,
      hoverBackground: secondaryDark,
      hoverColor: white,
      activeBackground: secondaryDarker,
      activeColor: white,
      disabledBackground: grey,
      disabledColor: white,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '55px',
    },
    'icon-secondary': {
      background: secondary,
      color: white,
      hoverBackground: secondaryDark,
      hoverColor: white,
      activeBackground: secondaryDarker,
      activeColor: white,
      disabledBackground: grey,
      disabledColor: white,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '40px',
    },
    'icon-custom': {
      background: 'transparent',
      color: white,
      hoverBackground: secondaryDark,
      hoverColor: white,
      activeBackground: secondaryDarker,
      activeColor: white,
      disabledBackground: 'transparent',
      disabledColor: greyLight,
      borderRadius: '4px',
      padding: '8px 8px',
      minWidth: '40px',
    },
    'icon-tertiary-light': {
      background: tertiaryLight,
      color: white,
      hoverBackground: tertiary,
      hoverColor: white,
      activeBackground: tertiaryDark,
      activeColor: white,
      disabledBackground: grey,
      disabledColor: white,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '40px',
    },
    text: {
      background: 'none',
      color: primary,
      hoverBackground: primaryLightest,
      hoverColor: primaryDark,
      activeBackground: 'none',
      activeColor: primaryDarker,
      disabledBackground: 'none',
      disabledColor: grey,
      borderRadius: '4px',
      padding: '8px 16px',
      minWidth: '55px',
    },
    'icon-text': {
      background: 'none',
      color: primary,
      hoverBackground: primaryLightest,
      hoverColor: primaryDark,
      activeBackground: 'none',
      activeColor: primaryDarker,
      disabledBackground: 'none',
      disabledColor: grey,
      borderRadius: '4px',
      padding: '8px 0px',
      minWidth: '40px',
    },
    'fab-standard': {
      background: primary,
      color: white,
      hoverBackground: primaryDark,
      hoverColor: white,
      activeBackground: primaryDarker,
      activeColor: white,
      disabledBackground: grey,
      disabledColor: white,
      borderRadius: '28px',
      padding: '8px 16px',
      minWidth: '55px',
    },
    'fab-extended': {
      background: primary,
      color: white,
      hoverBackground: primaryDark,
      hoverColor: white,
      activeBackground: primaryDarker,
      activeColor: white,
      disabledBackground: grey,
      disabledColor: white,
      borderRadius: '24px',
      padding: '8px 16px',
      minWidth: '55px',
    },
  },

  checkbox: {
    active: {
      background: primaryLightest,
      icon: {
        border: `2px solid ${primary}`,
        borderRadius: '4px',
        width: '18px',
        height: '18px',
      },
      checkedIcon: {
        background: primary,
        border: `2px solid ${primary}`,
        color: white,
      },
    },
    disabled: {
      background: 'transparent',
      icon: {
        border: `2px solid ${greySemi}`,
        borderRadius: '4px',
        width: '18px',
        height: '18px',
      },
      checkedIcon: {
        background: greySemi,
        border: `2px solid ${greySemi}`,
        color: white,
      },
    },
  },

  radioButton: {
    color: primary,
    borderColor: primary,
    backgroundColor: primaryLightest,
    'checked': {
      color: primary,
      borderColor: primary,
    },
    'disabled': {
      color: greySemi,
      backgroundColor: 'transparent',
    },
  },

  toggle: {
    width: 52,
    height: 32,
    padding: 0,
    margin: 8,
    borderRadius: 18,
    display: 'flex',
    'switch-base': {
      padding: 1,
      top: 2,
      bottom: 2,
      left: 2,
      color: greySemi,
      background: 'none',
    },
    'checked': {
      transform: 'translateX(22px)',
      backgroundColor: primary,
      borderColor: primary,
      opacity: 1,
      background: 'none',
    },
    'checkedDisabled': {
      transform: 'translateX(22px)',
      backgroundColor: primaryLighter,
      borderColor: primaryLighter,
      opacity: 0.5,
    },
    'disabled': {
      border: `1px solid ${greySemi}`,
      backgroundColor: greySemi,
      opacity: 1,
    },
    'thumb': {
      width: 24,
      height: 24,
      boxShadow: 'none',
      borderRadius: 12,
      backgroundColor: white,
    },
    'track': {
      borderRadius: 36 / 2,
      border: `1px solid ${greySemi}`,
      backgroundColor: greySemi,
      opacity: 1,
      transitionProperty: 'border-color background-color',
      transitionDuration: '.2s',
      transitionTimingFunction: 'ease',
    },
  },

  textField: {
    input: {
      paddingRight: '12px',
      paddingRightEndAdornment: '0px',
    },
    inputBase:{
      activeBoxShadow: '0 2px 4px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05)',
      disabledBoxShadow: 'none',
    },
    label: {
      default: grey,
      focused: primary,
      error: error,
      success: success,
    },
    background: {
      disabledColor: greySemi,
      activeColor: 'transparent',
      focusedColor: white,
    },
    border: {
      disabled: `2px solid ${grey}`,
      hover: `2px solid ${grey}`,
      defaultDisabled: `2px solid ${greySemi}`,
      defaultActive: `2px solid ${primary}`,
      error: `2px solid ${error}`,
      success: `2px solid ${success}`,
    },
    adornment: {
      color: grey,
      marginTop: '1px',
      pointer: 'pointer',
      default: 'default',
      hoverColor: primary,
    },
  },

  filteredTextField: {
    padding: '2px',
    fontSize: '14px',
    paddingRightEndAdornment: '0px',
    borderBottom: `2px solid ${tertiaryLight}`,
    height: '16px',
    boxShadow: 'none',
    container: {
      display: 'flex',
      margin: '10px 3px',
      cursor: 'pointer',
    },
  },

  filteredAutocomplete: {
    margin: '0px',
    padding: '0px',
    boxShadow: 'none',
    borderBottom: `2px solid ${tertiaryLight}`,
    inputRoot: {
      fontSize: '14px',
      padding: '2px',
      height: '20px',
      width: '300px',
    },
    endAdornment: {
      display: 'none',
    },
    container: {
      display: 'flex',
      margin: '10px 3px',
      cursor: 'pointer',
    },
    value: {
      textTransform: 'capitalize',
    },
  },

  filterDrillDown: {
    color: primary,
    display: 'flex',
    maxHeight: '600px',
    minHeight: '144px',
    minWidth: '300px',
    overflow: 'auto',
    radioButton: {
      color: primary,
      backgroundColor: 'transparent',
      'checked': {
        color: primary,
      },
    },
    searchInput: {
      activeColor: primary,
      borderBottom: `1px solid ${primary}`,
      input: {
        color: black,
        fontSize: 16,
        borderBottom: `1px solid ${greySemi}`,
        marginTop: '8px',
        height: 'auto',
        minWidth: '300px',
      },
    },
    searchIcon: {
      color: grey,
      width: 34,
      height: 24,
      marginLeft: 8,
    },
    optionContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    noResultsContainer: {
      minWidth: '300px',
      height: '96px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingContainer: {
      display: 'flex',
      height: '96px',
      alignItems: 'center',
      justifyContent: 'center',
      spinner: {
        width: '20px',
        height: '20px',
      },
    },
  },

  title: {
    input: {
      fontSize: '31px',
      fontWeight: 'bold',
    },
    editContainer: {
      height: 102,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',
    },
    ellipsisContainer: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    viewContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 12,
      paddingTop: 22,
      height: 102,
      width: '100%',
    },
    actionButtonsContainer: {
      position: 'absolute',
      top: 74,
      right: 0,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%',
      justifyContent: 'flex-end',
      height: 28,
      marginRight: -3,
      paddingTop: 4,
    },
    actionButton: {
      display: 'flex',
      width:24,
      height: 24,
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: `${white}`,
      marginBottom: 4,
      marginRight: 4,
      color: `${primary}`,
      '&:hover': {
        backgroundColor: `${primary}`,
        color: `${white}`,
      },
    },
  },

  snackBar: {
    root: {
      borderRadius: 8,
    },
    success: {
      backgroundColor: success,
      color: white,
    },
    error: {
      backgroundColor: error,
      color: white,
    },
    warning: {
      backgroundColor: warning,
      color: black,
    },
    info: {
      backgroundColor: primary,
      color: white,
    },
    icon: {
      fontSize: 27,
      opacity: 1,
    },
    closeIcon: {
      fontSize: 22,
      opacity: 1,
    },
    messageContainer: {
      display: 'flex',
      padding: 5,
    },
    message: {
      paddingLeft: 18,
    },
    withTitle: {
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
    },
    noTitle: {
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  },

  inlineMessage: {
    borderRadius: 8,
    display: 'flex',
    minWidth: 280,
    success: {
      backgroundColor: success,
      color: white,
    },
    error: {
      backgroundColor: error,
      color: white,
    },
    warning: {
      backgroundColor: warningLight,
      color: black,
    },
    info: {
      backgroundColor: tertiary,
      color: white,
    },
    icon: {
      fontSize: 27,
      opacity: 1,
      justifyContent: 'flex-end',
    },
    closeIcon: {
      fontSize: 22,
      opacity: 1,
    },
    closeContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-end',
      padding: 4,
    },
    messageContainer: {
      display: 'flex',
      padding: 20,
      width: '100%',
    },
    message: {
      paddingLeft: 20,
      width: '100%',
    },
    withTitle: {
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
    },
    noTitle: {
      flexWrap: 'wrap',
      alignItems: 'center',
    },
  },

  select: {
    inputBase:{
      activeBoxShadow: '0 2px 4px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05)',
      disabledBoxShadow: 'none',
    },
    label: {
      default: grey,
      focused: primary,
      error: error,
      success: success,
    },
    background: {
      disabledColor: greySemi,
      activeColor: 'transparent',
      focusedColor: white,
    },
    border: {
      disabled: `2px solid ${grey}`,
      hover: `2px solid ${grey}`,
      defaultDisabled: `2px solid ${greySemi}`,
      defaultActive: `2px solid ${primary}`,
      error: `2px solid ${error}`,
      success: `2px solid ${success}`,
    },
    values: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    doneIcon: {
      color: primary,
    },
    iconWrap: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
    menuItem: {
      display: 'flex',
    },
    selected: {
      fontWeight: 600,
    },
    paperProps: {
      maxHeight: 48 * 4.5 + 8,
    },
  },

  autocomplete: {
    focusedColor: primary,
    popupIndicatorOpen: {
      transform: 'none',
    },
    option: {
      hoverBackground: greyLight,
      selectedBackground: greySemi,
      selectedFontWeight: 600,
      textTransform: 'capitalize',
    },
    iconSelected: {
      width: 24,
      height: 24,
      margin: '12 5',
      color: primary,
    },
    text: {
      flexGrow: 1,
    },
    inputFocused: {
      textTransform: 'capitalize',
    },
  },

  datepicker: {
    background: {
      disabledColor: greySemi,
      activeColor: 'transparent',
      focusedColor: white,
    },
    inputBase:{
      activeBoxShadow: '0 2px 4px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05)',
      disabledBoxShadow: 'none',
    },
    label: {
      default: grey,
      focused: primary,
      error: error,
      success: success,
    },
    border: {
      disabled: `2px solid ${grey}`,
      hover: `2px solid ${grey}`,
      defaultDisabled: `2px solid ${greySemi}`,
      defaultActive: `2px solid ${primary}`,
      error: `2px solid ${error}`,
      success: `2px solid ${success}`,
    },
    arrow: {
      color: primary,
    },
    toolbar: {
      backgroundColor: greyLight,
      color: grey,
      selectedColor: black,
    },
    day: {
      color: black,
    },
    selectedDay: {
      backgroundColor: greySemi,
      color: black,
    },
    year: {
      hoverColor: greyLight,
    },
    selectedYear: {
      color: black,
      backgroundColor: greySemi,
    },
    calendarHeader: {
      color: grey,
    },
  },

  boolean: {
    disabledColor: grey,
    activeColor: black,
    disabledCursor: 'default',
    activeCursor: 'pointer',
    mainContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '18px',
      height: '36px',
      backgroundColor: greyLight,
      padding: '0 6px 0 6px',
      width: '210px',
    },
    selected: {
      position: 'absolute',
      backgroundColor: white,
      borderRadius: '14px',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
      height: '27px',
      width: '66px',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '3px',
      userSelect: 'none',
      transition: 'left 0.3s linear',
      transitionTimingFunction: 'ease',
    },
    na: {
      position: 'absolute',
      width: '66px',
      height: '27px',
      left: '6px',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '3px',
      userSelect: 'none',
    },
    yes: {
      position: 'absolute',
      width: '66px',
      height: '27px',
      left: '72px',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '3px',
      userSelect: 'none',
    },
    no: {
      position: 'absolute',
      width: '66px',
      height: '27px',
      left: '138px',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '3px',
      userSelect: 'none',
    },
  },

  contextMenu: {
    root: {
      padding: '4px',
      boxShadow: '0 10px 20px hsla(0, 0%, 0%, .2), 0 0px 8px hsla(0, 0%, 0%, .1)',
    },
    popper: {
      zIndex: 120,
    },
  },

  contextMenuItem: {
    borderRadius: '4px',
    marginBottom: '2px',
    marginTop: '2px',
    divider: `1px solid ${greySemi}`,
    dividerLast: 'none',
    cursor: 'pointer',
    disabledCursor: 'not-allowed',
  },

  link: {
    color: primary,
    userSelect: 'none',
    disabledCursor: 'not-allowed',
    activeCursor: 'pointer',
    disabledDecoration: 'none',
    activeDecoration: 'underline',
    disabledColor: grey,
  },

  listItem: {
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '56px',
      margin: '8px 0 0',
      padding: '0 16px',
      borderRadius: '4px',
      backgroundColor: `${white}`,
      boxShadow: '0 0  1px hsla(0, 0%, 0%, .4)',
      minWidth: '500px',
      highlightColor: `${primary}`,
      transition: '0.3s cubic-bezier(.25,.8,.25,1)',
    },
    hover: {
      boxShadow: '0 10px 20px hsla(0, 0%, 0%, .2), 0 0px 8px hsla(0, 0%, 0%, .1)',
      cursor: 'pointer',
      zIndex: 2,
    },
  },

  listItemContentSegment: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  listItemActions: {
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100px',
      alignItems: 'center',
    },
  },

  listItemText: {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  listItemName: {
    root: {
      display: 'flex',
      padding: '0 16px',
      flex: 1,
      transition: 'color .08s ease-in',
      minWidth: '200px',
      userSelect: 'none',
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    normalColor: black,
  },

  listItemDate: {
    root: {
      display: 'flex',
      padding: '0 16px',
      transition: 'color .08s ease-in',
      minWidth: '120px',
      userSelect: 'none',
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    normalColor: black,
  },

  listItemPayment: {
    root: {
      display: 'flex',
      padding: '0 16px',
      transition: 'color .08s ease-in',
      minWidth: '100px',
      userSelect: 'none',
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    normalColor: black,
  },

  listItemLocationName: {
    root: {
      display: 'flex',
      padding: '0 16px',
      flex: 1,
      transition: 'color .08s ease-in',
      minWidth: '200px',
      userSelect: 'none',
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    normalColor: black,
  },

  listItemQualifier: {
    root: {
      display: 'flex',
      padding: '0 16px',
      flex: 1,
      transition: 'color .08s ease-in',
      minWidth: '200px',
      userSelect: 'none',
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    normalColor: black,
  },

  listItemAddress: {
    root: {
      padding: '0 16px',
      flex: 1,
      minWidth: '200px',
      userSelect: 'none',
      flexDirection: 'column',
      display: 'flex',
    },
    addressRow: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  listItemCategoryHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
    paddingLeft: 37,
    marginTop: 8,
  },

  listContainer: {
    marginTop: 28,
  },

  listItemInfo: {
    root: {
      padding: '0 16px',
      flex: 1,
      minWidth: '200px',
      userSelect: 'none',
      flexDirection: 'column',
      display: 'flex',
    },
    row: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textTransform: 'capitalize',
    },
  },

  listItemDescription: {
    root: {
      padding: '0 16px',
      flex: 1,
      minWidth: '200px',
      userSelect: 'none',
      flexDirection: 'column',
      display: 'flex',
    },
    title: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  listItemDetails: {
    root: {
      display: 'flex',
      minWidth: '150px',
    },
  },

  listItemSkeleton: {
    root: {
      marginTop: '8px',
      borderRadius: '4px',
    },
  },

  listItemCheckbox: {
    root: {
      display: 'flex',
    },
  },

  addNewListItemButton: {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minWidth: '500px',
      height: '56px',
      margin: '8px 0 0',
      padding: '0 0 0 16px',
      border: `2px dashed ${greySemi}`,
      borderRadius: '8px',
      cursor: 'pointer',
      transition: 'border .2s ease',
      hoverBorder: `2px dashed ${primary}`,
    },
  },

  field: {
    editContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    actionButtonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-end',
      height: 28,
    },
    actionButton: {
      display: 'flex',
      width:24,
      height: 24,
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: `${white}`,
      marginBottom: 4,
      marginRight: 4,
      color: `${primary}`,
      '&:hover': {
        backgroundColor: `${primary}`,
        color: `${white}`,
      },
    },
    viewContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '12px',
      paddingTop: '6px',
      paddingBottom: 5,
      backgroundColor: greyLighter,
      height:56,
      marginTopInlineEdit: 28,
      marginTop: 0,
    },
    iconValueContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '-3px',
      flex: 1,
      minWidth: '150px',
    },
    ellipsisContainer: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    adornment: {
      color: `${grey}`,
      marginRight: '9px',
    },
  },

  progressBar: {
    transition: 'width .3s ease',
  },

  searchPill: {
    content: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      margin: '10px 3px',
    },
  },

  filterPill: {
    root: {
      display: 'inline-block',
    },
    container: {
      backgroundColor: `${tertiaryLightest}`,
      display: 'flex',
      paddingLeft: '4px',
      margin: '4px',
      height: '40px',
      borderRadius: '4px',
      justifyContent: 'flex-end',
    },
    loading: {
      display: 'flex',
      margin: '10px 3px',
    },
  },

  filterCriterion: {
    root: {
      display: 'flex',
      margin: '10px 3px',
      cursor: 'pointer',
    },
    option: {
      paddingTop: '5px',
      paddingLeft: '25px',
      color: primary,
    },
    valueContainer: {
      display: 'grid',
    },
    value: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '5px',
      width: '300px',
    },
  },

  filterOperator: {
    display: 'flex',
    margin: '10px 3px',
  },

  filterCloseButton: {
    display: 'flex',
  },
  addNewFilterButton: {
    root: {
      display: 'flex',
      alignItems: 'start',
      height: '56px',
      padding: '8px',
      cursor: 'pointer',
      transition: 'border .2s ease',
    },
  },

  filterRow: {
    boxShadow: '0 0 1px rgba(0, 0, 0, 0.4)',
    hoverBoxShadow: '0 0 1px rgba(17, 95, 251, 0.6), inset 0 0 1px rgba(0, 0, 0, 0.2)',
    root: {
      display: 'flex',
      transition: 'all 0.3s ease',
      marginBottom: '32px',
      marginTop: '8px',
      height: 'auto',
      borderRadius: '4px',
      backgroundColor: greyLight,
      minWidth: '500px',
      cursor: 'pointer',
      justifyContent: 'space-between',
    },
    children: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '4px',
      flexGrow: 1,
    },
    iconButton: {
      margin: 8,
      alignItems: 'start',
    },
    searchIcon: {
      color: 'grey',
      margin: '8px 16px 8px 8px',
      alignSelf: 'center',
      display: 'flex',
      alignItems: 'start',
    },
  },

  newSearchBar: {
    root: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '8px',
    },
  },

  newListItemBadge: {
    root: {
      display: 'inline',
    },
    badge: {
      height: '16px',
      minWidth: '16px',
      borderRadius: '8px',
      backgroundColor: tertiary,
    },
    anchorOrigin: {
      left: '0%',
    },
  },

  modalCardHeader: {
    root: {
      padding: '16px 36px',
    },
    closeIcon: {
      cursor: 'pointer',
    },
    closeIconContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    headerContainer: {
      width: '100%',
      height: 48,
      display: 'flex',
      padding: '0 36',
    },
    headerIcon: {
      fontSize: 48,
      color: primary,
      width: 48,
      height: 48,
      display: 'flex',
    },
    headerTitle: {
      paddingTop: 3,
      paddingLeft: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  modalCardContent: {
    padding: '16px 36px',
  },

  circularProgress: {
    color: {
      white: white,
      primary: primary,
      error: error,
    },
  },

  contextualPanelTabs: {
    tabs: {
      borderBottom: `1px solid ${greySemi}`,
      padding: '0 35px',
    },
    tabIndicator: {
      backgroundColor: primary,
      height: '2px',
      borderRadius: '2px',
    },
    tab: {
      textTransform: 'uppercase',
    },
  },

  personTabs: {
    tabs: {
      borderBottom: `1px solid ${greySemi}`,
      padding: '0px',
    },
    tabIndicator: {
      backgroundColor: primary,
      height: '2px',
      borderRadius: '2px',
    },
    tab: {
      textTransform: 'uppercase',
    },
  },

  fileDNDTarget: {
    cursor: 'pointer',
    userSelect: 'none',
    padding: '2em',
    backgroundColor: greyLighter,
    border: '2px dashed',
    borderRadius: '10px',
    transition: 'border-color .2s ease',
    draggedOverBorderColor: primary,
    idleBorderColor: greySemi,
    icon: {
      color: greySemi,
      height: 48,
      width: 48,
    },
    iconContainer: {
      transition: 'color .2s ease',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 24,
      iconHoverColor: primary,
      defaultColor: greySemi,
    },
    textContainer: {
      transition: 'color .2s ease',
      textHoverColor: black,
      defaultColor: grey,
    },
    text: {
      marginTop: '-7px',
      defaultColor: grey,
    },
    flexWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    fileInput: {
      display: 'none',
    },
  },

  searchBar: {
    boxShadow: '0 0 1px rgba(0, 0, 0, 0.4)',
    activeBoxShadow: '0 0 1px rgba(17, 95, 251, 0.4), inset 0 0 2px rgba(17, 95, 251, 0.2)',
    hoverBoxShadow: '0 0 1px rgba(17, 95, 251, 0.6), inset 0 0 1px rgba(0, 0, 0, 0.2)',
    inputActiveColor: white,
    adornmentActiveColor: tertiary,
    borderRadius: '4px',
    cursor: 'pointer',
    root: {
      transition: 'all 0.3s ease',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '32px',
      marginTop: '8px',
      height: 'auto',
      borderRadius: '4px',
      backgroundColor: greyLight,
      minWidth: '500px',
      justifyContent: 'space-between',
    },
    input: {
      padding: 8,
      color: black,
      fontSize: 18,
    },
    icon: {
      color: tertiaryLight,
      width: 54,
      height: 28,
      transition: 'color .2s ease',
    },
  },

  nodeAttachmentUpload: {
    root: {
      display: 'flex',
      alignItems: 'center',
      margin: '1em 0',
      userSelect: 'none',
      color: {
        pending: grey,
        default: black,
      },
    },
    nodeAttachmentInfo: {
      flex: 1,
    },
    fileInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    fileName: {
      display: 'inline-block',
      fontSize: '.8em',
      fontWeight: 'bold',
      transition: 'color .2s ease',
    },
    fileSize: {
      display: 'inline-block',
      color: grey,
    },
    nodeAttachmentCloseAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '40px',
      cursor: 'pointer',
      color: primary,
    },
    errorMessageContainer: {
      color: error,
    },
    flexContainer: {
      display: 'flex',
    },
    errorIconContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      color: error,
    },
    nameContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  },

  nodeAttachmentProgressBar: {
    rail: {
      width: '100%',
      height: '8px',
      margin: '4px 0',
      borderRadius: '4px',
      errorBackgroundColor: error,
    },
    progress: {
      height: '8px',
      borderRadius: '4px',
      transition: 'width .5s ease',
      errorBackgroundColor: error,
    },
  },

  nodeAttachmentDownload: {
    attachmentWrapper: {
      diplay: 'flex',
      flexDirection: 'column',
      padding: '.2em 0',
    },
    attachment: {
      display: 'flex',
      borderRadius: 10,
      padding: 10,
      transition: 'background-color .2s ease',
      downloadBackgroundColor: tertiaryLighter,
      deleteBackgroundColor: errorLighter,
      hoverBackgroundColor: greyLighter,
    },
    attachmentThumbnail: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      padding: '.3em',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      color: 'white',
      width: '60px',
      height: '60px',
      borderRadius: '8px',
      backgroundColor: greySemi,
      downloadBackgroundColor: tertiary,
      deleteBackgroundColor: errorLight,
      minWidth: 60,
    },
    attachmentInfo: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0 0 0 1em',
      minWidth: 0,
    },
    attachmentInfoMain: {
      display: 'flex',
    },
    attachmentTitle: {
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.1em',
      fontWeight: 'bold',
      paddingLeft: 4,
      inputPadding: '19px 4px 18px',
      inputFontSize: 18,
    },
    titleActionButtonsContainer: {
      position: 'absolute',
      top: -32,
      right: 0,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%',
      justifyContent: 'flex-end',
      height: 28,
      marginRight: -3,
      paddingTop: 4,
    },
    titleErrorContainer: {
      position: 'absolute',
      top: 64,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-start',
    },
    titleActionButton: {
      display: 'flex',
      width:24,
      height: 24,
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: `${white}`,
      marginBottom: 4,
      marginRight: 4,
      color: `${primary}`,
      '&:hover': {
        backgroundColor: `${primary}`,
        color: `${white}`,
      },
    },
    attachmentFileSize: {
      width: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    attachmentInfoDownloadProgress: {
    },
    attachmentActions: {
      width: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    attachmentAction: {
      display: 'inline-block',
      defaultColor: grey,
      highlightColor: primary,
      deleteColor: error,
      cursor: 'pointer',
      disabledCursor: 'not-allowed',
    },
    attachmentCancelDownloadAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '40px',
      cursor: 'pointer',
      paddingTop: 8,
      color: tertiary,
    },
    attachmentDeleteWarning: {
      display: 'flex',
      alignItems: 'center',
    },
    attachmentDeleteText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    attachmentDeleteButtons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexGrow: 1,
    },
  },

  nodeAttachmentTitle: {
    attachmentTitle: {
      flex: '1',
      alignItems: 'center',
      fontSize: '1.1em',
      fontWeight: 'bold',
      paddingLeft: 4,
      inputPadding: '19px 4px 18px',
      inputFontSize: 18,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    titleActionButtonsContainer: {
      position: 'absolute',
      top: -32,
      right: 0,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      width: '100%',
      justifyContent: 'flex-end',
      height: 28,
      marginRight: -3,
      paddingTop: 4,
    },
    titleErrorContainer: {
      position: 'absolute',
      top: 64,
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'flex-start',
    },
    titleActionButton: {
      display: 'flex',
      width:24,
      height: 24,
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14)',
      backgroundColor: `${white}`,
      marginBottom: 4,
      marginRight: 4,
      color: `${primary}`,
      '&:hover': {
        backgroundColor: `${primary}`,
        color: `${white}`,
      },
    },
  },

  nodeAttachmentPreviewImage: {
    modalBody: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalHeaderWrap: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: 50,
    },
    modalHeader: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
      color: white,
      height: 40,
      cursor: 'pointer',
    },
    modalImage: {
      maxWidth: '80vw',
      maxHeight: '80vh',
      border: `2px solid ${white}`,
    },
  },

  nodeAttachmentList: {
    list: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingBottom: 120,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${greySemi}`,
    },
    name: {
      display: 'flex',
      padding: '5px 5px 5px 83px',
      justifyContent: 'center',
      userSelect: 'none',
      selectedColor: primary,
      defaultColor: black,
    },
    size: {
      display: 'flex',
      padding: '5px 50px 5px 5px',
      justifyContent: 'center',
      userSelect: 'none',
      selectedColor: primary,
      defaultColor: black,
    },
    icon: {
      display: 'flex',
      width: '24px',
      height: '24px',
    },
  },

  nodeLinkInfoCard: {
    row: {
      marginBottom: 8,
    },
  },

  nodeLocationAssignmentViewMode: {
    row: {
      display: 'flex',
      padding: '8px',
      flex: 1,
      transition: 'color .08s ease-in',
      minWidth: '200px',
      userSelect: 'none',
    },
    label: {
      display: 'flex',
      minWidth: 150,
    },
    value: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  nodeLocationAssignmentEditMode: {
    row: {
      display: 'flex',
      padding: '8px',
      flex: 1,
      transition: 'color .08s ease-in',
      minWidth: '200px',
      userSelect: 'none',
      borderBottom: `1px solid ${greySemi}`,
    },
    label: {
      display: 'flex',
      minWidth: 150,
    },
    value: {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },

  locationWarning: {
    main: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      padding: 16,
      backgroundColor: warning,
      borderRadius: 8,
    },
    wrapper: {
      display:'flex',
      flexGrow: 1,
    },
    headerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
    },
    headerText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 40,
      paddingLeft: 12,
    },
    bodyText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 30,
      paddingLeft: 52,
    },
  },

  subscriptionExpiredWarning: {
    main: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      padding: 16,
      backgroundColor: error,
      borderRadius: 8,
      color: white,
    },
    wrapper: {
      display:'flex',
      flexGrow: 1,
    },
    headerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
    },
    headerText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 40,
      paddingLeft: 12,
    },
    bodyText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 30,
      paddingLeft: 52,
    },
  },

  trialPeriodMessage: {
    main: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      padding: 16,
      backgroundColor: primary,
      borderRadius: 8,
      color: white,
    },
    wrapper: {
      display:'flex',
      flexGrow: 1,
    },
    headerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
    },
    headerText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 40,
      paddingLeft: 12,
    },
    bodyText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 30,
      paddingLeft: 52,
    },
  },

  bannerMessage: {
    main: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      padding: 16,
      borderRadius: 8,
      backgroundColor: {
        warning: warning,
        error: error,
        info: primary,
      },
      color: {
        warning: black,
        error: white,
        info: white,
      },
    },
    wrapper: {
      display:'flex',
      flexGrow: 1,
    },
    headerIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 40,
    },
    headerText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 40,
      paddingLeft: 12,
    },
    bodyText: {
      flexDirection: 'column',
      paddingLeft: 52,
    },
  },

  noResultsContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '130px',
  },

  notification: {
    root: {
      display: 'flex',
      height: 'auto',
      padding: '0 16px',
      backgroundColor: `${white}`,
      boxShadow: '0 0  1px hsla(0, 0%, 0%, .4)',
      minWidth: '500px',
      transition: '0.3s cubic-bezier(.25,.8,.25,1)',
      firstItem: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      },
      lastItem: {
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
    },
    hover: {
      boxShadow: '0 10px 20px hsla(0, 0%, 0%, .2), 0 0px 8px hsla(0, 0%, 0%, .1)',
      cursor: 'pointer',
      zIndex: 2,
    },
  },

  notificationInfo: {
    padding: '6px 16px',
    flex: 1,
    minWidth: '200px',
    userSelect: 'none',
    flexDirection: 'column',
    display: 'flex',
  },

  notificationStatus: {
    display: 'flex',
    backgroundColor: 'transparent',
    alignItems: 'flex-start',
    padding: '16px 8px',
    width: '24px',
    height: '24px',
    'checked': {
      color: tertiary,
    },
  },

  notificationActions: {
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100px',
    },
    iconButton: {
      alignItems: 'flex-start',
      padding: '16px 8px',
      backgroundColor: 'transparent',
    },
  },

  tag: {
    display: 'flex',
    height: 32,
    padding: '4px 8px 4px 16px',
    borderRadius: 8,
    backgroundColor: greySemi,
    root: {
      display: 'inline-block',
      maxWidth: '100%',
      margin: '4px 2px',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 0,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 12,
      color: grey,
      cursor: 'pointer',
    },
    icon: {
      width: 16,
    },
  },

  notificationsCount: {
    display: 'inline-flex',
    borderRadius: '4px',
    width: '53px',
    height: '28px',
    backgroundColor: 'transparent',
    activeBackgroundColor: tertiaryLightest,
    alignItems: 'center',
    justifyContent: 'center',
    icon: {
      activeColor: tertiary,
      color: primaryDarker,
      width: '24px',
      height: '24px',
    },
  },

  addTag: {
    root: {
      display: 'inline-block',
    },
    viewMode: {
      display: 'flex',
      padding: '4px 20px 4px 8px',
      borderRadius: '8px',
      border: `2px dashed ${greySemi}`,
      hoverBorder: `2px dashed ${primary}`,
      color: primary,
      transition: 'border-color 0.2s linear',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    icon: {
      width: 16,
      height: 16,
      marginRight: 8,
    },
  },

  addTagAutocomplete: {
    margin: '0px',
    padding: '0px',
    boxShadow: 'none',
    border: 'none',
    inputRoot: {
      fontSize: '14px',
      padding: '2px',
      height: 32,
      backgroundColor: tertiary,
      borderRadius: 4,
      width: 325,
    },
    endAdornment: {
      display: 'none',
    },
    textField: {
      fontSize: '14px',
      padding: '2px',
      color: white,
      fontWeight: 'bold',
    },
    iconContainer: {
      display: 'flex',
      height: 16,
      width: 16,
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      margin: '0px 8px',
      cursor: 'pointer',
    },
    acContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: 389,
      height: 34,
      backgroundColor: tertiary,
      borderRadius: 4,
    },
  },

  tabs: {
    root: {
      color: grey,
    },
    selected: {
      color: black,
    },
    indicator: {
      color: primary,
    },
  },

  batchActionButton: {
    root: {
      display: 'flex',
      height: '56px',
      width: '56px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '12px',
      fontWeight: 'normal',
    },
    disabled: {
      opacity: .9,
      backgroundColor: white,
    },
    delete: {
      iconColor: error,
      hoverIconColor: white,
      labelColor: black,
      hoverLabelColor: white,
      disabled: {
        iconColor: errorLighter,
        labelColor: grey,
      },
    },
    share: {
      iconColor: primary,
      hoverIconColor: white,
      labelColor: black,
      hoverLabelColor: white,
      disabled: {
        iconColor: primaryLighter,
        labelColor: grey,
      },
    },
  },

  batchActionMenu: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    right: 4,
  },
};
