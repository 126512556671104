import GET__search from 'network-layer/endpoints/GET__search';

function responseAdapter(response) {
  return response;
}

export default async function genSearch(authToken, perspectiveId, params) {
  const response = await GET__search(authToken, perspectiveId, params);

  return responseAdapter(response);
}
