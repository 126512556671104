import React from 'react';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';

const FullScreenSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
      }}>
      <CenterpieceSpinner/>
    </div>
  );
};

export default FullScreenSpinner;
