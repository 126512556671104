import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import FieldDefinitionIcon from '@material-ui/icons/PowerInput';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Clear';

const FieldDefinitionsList = ({
  DataModelQueries,
  DataModel,
  fieldDefinitionIds,
  onRemoveFieldDefinition,
}) => {
  const fieldDefinitions = fieldDefinitionIds
    .map(fieldDefinitionId => DataModelQueries.selectFieldDefinitionById(DataModel, fieldDefinitionId));

  return (
    <>
      {
        fieldDefinitions
          .map(fieldDefinition => {
            const {
              id: fieldDefinitionId,
              label: fieldDefinitionLabel,
              typeId: fieldDefinitionTypeId,
            } = fieldDefinition;

            return (
              <NestedBox
                key={fieldDefinitionId}>
                <ContentLine>
                  <FieldDefinitionIcon/>&nbsp;&nbsp;
                  <ContentLineText>
                    <Typography
                      variant="body1">
                      <em
                        style={{color: '#aaa'}}>
                        {DataModelQueries.selectTypeById(DataModel, fieldDefinitionTypeId).name}
                      </em>
                      &nbsp;&nbsp;&rsaquo;&nbsp;&nbsp;
                      {fieldDefinitionLabel}
                    </Typography>
                  </ContentLineText>
                  <Tooltip
                    placement="top"
                    title="unwatch this Field">
                    <span>
                      <IconButton
                        onClick={() => {
                          onRemoveFieldDefinition(fieldDefinitionId);
                        }}>
                        <RemoveIcon/>
                      </IconButton>
                    </span>
                  </Tooltip>
                </ContentLine>
              </NestedBox>
            );
          })
      }
    </>
  );
};

export default FieldDefinitionsList;
