import GET__isEmailAvailable from 'network-layer/endpoints/GET__isEmailAvailable';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return response;
}

export default async function resendEmail(email) {

  const response = await GET__isEmailAvailable(email);

  return responseAdapter(response);
}
