import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import List from './list';
import Create from './create';
import Edit from './edit';
import FieldDefinitions from './field-definitions';

const TypesAdmin = ({
  match,
}) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/list`}
        component={List}
      />
      <Route
        exact
        path={`${match.path}/create`}
        component={Create}
      />
      <Route
        exact
        path={`${match.path}/:typeId`}
        component={Edit}
      />
      <Route
        path={`${match.path}/:typeId/field-definitions`}
        component={FieldDefinitions}
      />
      <Redirect
        to={`${match.path}/list`}
      />
    </Switch>
  );
};

export default TypesAdmin;
