import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {GET} from '../verbs';

export default async function GET__notifications_unreadCount(authToken) {
  return await GET(
    '/notifications/unread-count',
    configWithAuthorization(defaultConfig(), authToken),
  );
}
