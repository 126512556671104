import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import Message from 'ui-library/components/Message';

import {
  connectionName as isConnectionName,
  connectionToken as isConnectionToken,
} from 'utils/validator';

const Description = styled.div`
  margin: 18px 0;
`;

const ConnectionNameStep = ({
  active,
  connectionState,
  dispatchConnectionAction,
  navigateToStep,
  saveConnection,
}) => {

  const {
    connectionType,
    connectionName,
    isConnectionNamePristine,
    connectionToken,
    isConnectionTokenPristine,
    connectionErrorMessage,
    connectionSaveState,
  } = connectionState;

  React.useEffect(() => {
    if (connectionToken) {
      dispatchConnectionAction({
        type: 'SET_IS_CONNECTION_TOKEN_PRISTINE',
        payload: false,
      });
    }
    if (connectionName) {
      dispatchConnectionAction({
        type: 'SET_IS_CONNECTION_NAME_PRISTINE',
        payload: false,
      });
    }
  }, [connectionToken, connectionName, dispatchConnectionAction]);

  if (!active) {
    return null;
  }

  const connectionTokenErrorMessage = !isConnectionTokenPristine && isConnectionToken(connectionToken);
  const connectionNameErrorMessage = !isConnectionNamePristine && isConnectionName(connectionName);

  return (
    <WizardStep>
      <Message
        variant="error"
        open={Boolean(connectionErrorMessage)}
        onClose={() => {
          dispatchConnectionAction({
            type: 'SET_CONNECTION_ERROR_MESSAGE',
            payload: '',
          });
        }}
        messageBody={connectionErrorMessage}
        autoHideDuration={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Connection
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="connection-name-step-breadcrumb"
            textTransform="capitalize"
            onClick={() => navigateToStep(1)}
          >
            {connectionType}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Name
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          {
            connectionType === 'outgoing' ?
              'Share the generated Connection Code with the external account you wish to provide access to. Afterwards, you will be able to select what items you wish to share through this Connection.'
              :
              'Use the Connection Code you have received to gain access to the items that user shares with you.'
          }
        </WizardStepDescription>
      </WizardStepHead>

      <WizardContentColumn>
        <form onSubmit={async(event) => {
          event.preventDefault();
          if (connectionTokenErrorMessage || connectionNameErrorMessage) {
            return;
          }

          const connectionHasBeenSaved = await saveConnection();
          if (connectionHasBeenSaved) {
            navigateToStep(3);
          }
        }}>
          <WizardStepContent>
            {
              connectionType === 'outgoing' ?
                null
                :
                connectionType === 'incoming' ?
                  <>
                    <Description>
                      <Typography
                        variant="lead">
                          Copy and Paste the &nbsp;
                        <Typography
                          variant="lead"
                          fontWeight="bold">
                            Connection Code &nbsp;
                        </Typography>
                        below:
                      </Typography>
                    </Description>

                    <TextField
                      data-test-id="connection-code-input"
                      autoFocus
                      label="Connection Code"
                      value={connectionToken}
                      error={Boolean(connectionTokenErrorMessage)}
                      helperText={connectionTokenErrorMessage}
                      onChange={(event) => {
                        dispatchConnectionAction({
                          type: 'SET_CONNECTION_TOKEN',
                          payload: event.currentTarget.value,
                        });
                      }}
                    />
                  </>
                  :
                  null
            }
            <Description>
              <Typography
                variant="lead">
                  Name this Connection:
              </Typography>
              <br/>
              <Typography
                variant="caption">
                {
                  connectionType === 'outgoing' ?
                    'Best practice is to name the connection after the party you are providing access to.'
                    :
                    'Best practice is to name the connection after the party providing access to their account.'
                }
              </Typography>
            </Description>
            <TextField
              data-test-id="connection-name-input"
              autoFocus={connectionType === 'outgoing'}
              label="Connection Name"
              value={connectionName}
              error={Boolean(connectionNameErrorMessage)}
              helperText={connectionNameErrorMessage}
              onChange={(event) => {
                dispatchConnectionAction({
                  type: 'SET_CONNECTION_NAME',
                  payload: event.currentTarget.value,
                });
              }}
            />
          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              data-test-id="save-connection-button"
              variant="secondary"
              disabled={connectionSaveState === 'saving' || (connectionType === 'incoming' && (!Boolean(connectionToken) || Boolean(connectionTokenErrorMessage))) || !Boolean(connectionName) || Boolean(connectionNameErrorMessage)}>
              Save &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ConnectionNameStep.propTypes = {
  active: PropTypes.bool.isRequired,
  connectionState: PropTypes.object,
  dispatchConnectionAction: PropTypes.func,
  navigateToStep: PropTypes.func,
};

export default ConnectionNameStep;
