function propertyConfigurationHintUIReducer(state, {type: actionType, payload}) {

  switch(actionType) {

    case 'EXPAND_VIRTUAL_LOCATION_TREE_NODE_HINT': {
      return {
        ...state,
        expandedVirtualLocationTreeNodeHints: state.expandedVirtualLocationTreeNodeHints.includes(payload) ?
          [...state.expandedVirtualLocationTreeNodeHints]
          :
          [...state.expandedVirtualLocationTreeNodeHints, payload],
      };
    }

    case 'COLLAPSE_VIRTUAL_LOCATION_TREE_NODE_HINT': {
      return {
        ...state,
        expandedVirtualLocationTreeNodeHints: [
          ...state.expandedVirtualLocationTreeNodeHints.slice(0, state.expandedVirtualLocationTreeNodeHints.indexOf(payload)),
          ...state.expandedVirtualLocationTreeNodeHints.slice(state.expandedVirtualLocationTreeNodeHints.indexOf(payload) + 1),
        ],
      };
    }

    case 'EDIT_VIRTUAL_LOCATION_TREE_NODE': {
      const {
        virtualLocationTreeNodeId,
        virtualLocationTreeNodeName,
        virtualLocationTreeNodeTypeName,
      } = payload;

      return {
        ...state,
        editingVirtualLocationTreeNodeId: virtualLocationTreeNodeId,
        editingVirtualLocationTreeNodeName: virtualLocationTreeNodeId === null ? '' : virtualLocationTreeNodeName,
        editingVirtualLocationTreeNodeTypeName: virtualLocationTreeNodeId === null ? '' : virtualLocationTreeNodeTypeName,
      };
    }

    case 'SET_EDITING_VIRTUAL_LOCATION_TREE_NODE_NAME': {
      return {
        ...state,
        editingVirtualLocationTreeNodeName: payload,
      };
    }

    default:
      throw new Error(`Unknown action "${actionType}"!`);

  }

}

export default propertyConfigurationHintUIReducer;
