import React from 'react';
import PropTypes from 'prop-types';
import MUIDialogContent from '@material-ui/core/DialogContent';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    padding: theme.modalCardContent.padding,
    height: props => props.height ? `${props.height}vh` : '',
    maxHeight: props => props.maxHeight ? `${props.maxHeight}vh` : '',
  },
});

const ModalCardContent = ({
  height,
  maxHeight,
  children,
}) => {

  const classes = useStyles({
    height,
    maxHeight,
  });

  return (
    <MUIDialogContent className={classes.root}>
      {children}
    </MUIDialogContent>
  );
};

ModalCardContent.propTypes = {
  height: PropTypes.number,
  maxHeight: PropTypes.number,
  children: PropTypes.node,
};

export default ModalCardContent;
