import selectTypes from './selectTypes';

function selectTypesByIds(DM, typeIds) {
  return (
    selectTypes(DM)
      .filter(type => {
        const {
          id: typeId,
        } = type;
        return typeIds.includes(typeId);
      })
  );
}

export default selectTypesByIds;
