import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    marginTop: theme.listItemSkeleton.root.marginTop,
    '& .MuiSkeleton-root': {
      borderRadius: theme.listItemSkeleton.root.borderRadius,
    },
  },
});

const ListSkeleton = ({
  itemCount,
}) => {

  const classes = useStyles();

  const itemSkeletons = [];

  for (let i = 0; i < itemCount; i++) {
    itemSkeletons.push(
      <div
        key={i}
        className={classes.root}>
        <Skeleton variant="rect" height={56}/>
      </div>,
    );
  }

  return (
    itemSkeletons
  );
};

ListSkeleton.propTypes = {
  itemCount: PropTypes.number,
};

ListSkeleton.defaultProps = {
  itemCount: 3,
};

export default ListSkeleton;
