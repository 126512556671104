import React from 'react';
import styled from '@emotion/styled';

const Root = styled.div`
  width: ${props => props.width ? props.width : '40%'};
  margin-top: 0;
  margin-bottom: 0;
  margin-left: ${props => props.margin ? props.margin : 'auto'};
  margin-right: ${props => props.margin ? props.margin : 'auto'};
  padding: ${props => props.padding ? props.padding : '0px'};
 `;

const RootWrap = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const WizardContentColumn = ({
  children,
  ...otherProps
}) => {
  return (
    <RootWrap>
      <Root {...otherProps}>
        {children}
      </Root>
    </RootWrap>
  );
};

export default WizardContentColumn;
