export default function userLastName(
  value,
  errorMessage = 'invalid title value',
) {
  const allowedCharacters = new RegExp(/^[\w#()'&\-%$_.`’‘ ñÑáéíóúäëïöüÄÖÜÉ]*$/);
  if (!allowedCharacters.test(value)) {
    return errorMessage;
  }

  if (value.length === 0) {
    return errorMessage;
  }

  if (value.length > 60) {
    return errorMessage;
  }

  return '';
}
