import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import OutboundNodesByRecordAndConnectionListRoute from './OutboundNodesByRecordAndConnectionListRoute';

const OutboundRecordRoute = ({
  match,
}) => {
  return (
    <>
      <Switch>
        <Route
          path={`${match.path}/nodes`}
          component={OutboundNodesByRecordAndConnectionListRoute}
        />
        <Redirect
          to={`${match.path}/nodes`}
        />
      </Switch>
    </>
  );
};

export default OutboundRecordRoute;
