import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import TypeCategory from './TypeCategory.react';

const TypeCategoriesAdmin = () => {
  const {
    // authToken,
    // perspectiveId,
    DataModel,
    DataModelQueries,
    // DataModelAPI,
    // dispatchDataModelAction,
  } = React.useContext(AdminContext);
  // const [localState, dispatchLocalAction] = React.useReducer(
  //   reducer,
  //   null,
  //   initState,
  // );

  const householdTypeCategories = DataModelQueries.selectTypeCategoriesByPerspectiveType(
    DataModel,
    'household',
  );
  const companyTypeCategories = DataModelQueries.selectTypeCategoriesByPerspectiveType(
    DataModel,
    'company',
  );

  return (
    <div>
      <Typography
        variant="h4">
        Type Categories
      </Typography>
      <Typography
        variant="body2">
        edit or create Type Categories
      </Typography>
      <br/><br/>

      {/*
        ------------------------ HOUSEHOLD ------------------------
      */}

      <Typography
        variant="h5">
        Household
      </Typography>
      <br/><br/>

      {
        householdTypeCategories
          .filter(typeCategory => {
            const {
              parentId,
            } = typeCategory;
            return parentId === null;
          })
          .map(typeCategory => {
            const {
              id: typeCategoryId,
            } = typeCategory;
            return (
              <TypeCategory
                key={typeCategoryId}
                typeCategory={typeCategory}
                DataModel={DataModel}
                DataModelQueries={DataModelQueries}
              />
            );
          })
      }



      {/*
        ------------------------ COMPANY ------------------------
      */}

      <br/><br/>
      <br/><br/>
      <Typography
        variant="h5">
        Company
      </Typography>
      <br/><br/>

      {
        companyTypeCategories
          .filter(typeCategory => {
            const {
              parentId,
            } = typeCategory;
            return parentId === null;
          })
          .map(typeCategory => {
            const {
              id: typeCategoryId,
            } = typeCategory;
            return (
              <TypeCategory
                key={typeCategoryId}
                typeCategory={typeCategory}
                DataModel={DataModel}
                DataModelQueries={DataModelQueries}
              />
            );
          })
      }
    </div>
  );
};

// function reducer(state, {type: actionType, payload}) {
//   switch(actionType) {

//     case 'RESET': {
//       return {
//         ...initState(),
//       };
//     }

//     default:
//       throw new Error(`Unknown action type "${actionType}"!`);

//   }
// }

// function initState() {
//   return {
//   };
// }

export default TypeCategoriesAdmin;
