import React from 'react';
import styled from '@emotion/styled';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Grid from '@material-ui/core/Grid';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import Typography from 'ui-library/components/Typography';
import BannerMessage from 'ui-library/components/BannerMessage';
import Button from 'ui-library/components/Button';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemText from 'ui-library/components/ListItemText';
import ListItemActions from 'ui-library/components/ListItemActions';

import FullScreenSpinner from 'components/chrome/FullScreenSpinner';
import AddCardDialog from 'components/AddCardDialog';
import RemoveCardDialog from 'components/RemoveCardDialog';
import PaymentDialog from 'components/PaymentDialog';
import UpdateCardDialog from 'components/UpdateCardDialog';
import UpgradeMembershipDialog from 'components/UpgradeMembershipDialog';
import ApplicationBar from 'components/ApplicationBar';
import LogOutModal from 'components/LogOutModal';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import SubscriptionContext from 'contexts/Subscription';

import genPaymentHistory from 'services/Subscription/genPaymentHistory';
import genPaymentMethod from 'services/Subscription/genPaymentMethod';
import deletePaymentMethod from 'services/Subscription/deletePaymentMethod';
import setPaymentMethod from 'services/Subscription/setPaymentMethod';
import renewCurrentSubscription from 'services/Subscription/renewCurrentSubscription';
import genPaymentInvoice from 'services/Subscription/genPaymentInvoice';
import genSubscriptionUpgrades from 'services/Subscription/genSubscriptionUpgrades';
import purchaseNewSubscription from 'services/Subscription/purchaseNewSubscription';

const MessageRow = styled.div`
  display: flex;
  flex-grow: 1;
`;

const SuspendedSubscriptionHousehold = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLogoutModalOpen, setIsLogoutModalOpen] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const [isDeletePaymentDialogOpen, setIsDeletePaymentDialogOpen] = React.useState(false);
  const [isDeletingPayment, setIsDeletingPayment] = React.useState(false);

  const [isAddPaymentDialogOpen, setIsAddPaymentDialogOpen] = React.useState(false);
  const [isAddingPaymentMethod, setIsAddingPaymentMethod] = React.useState(false);

  const [isMakePaymentDialogOpen, setIsMakePaymentDialogOpen] = React.useState(false);
  const [isMakingPayment, setIsMakingPayment] = React.useState(false);

  const [isUpdatePaymentMethodDialogOpen, setIsUpdatePaymentMethodDialogOpen] = React.useState(false);
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = React.useState(false);

  const [isUpgradeMembershipDialogOpen, setIsUpgradeMembershipDialogOpen] = React.useState(false);
  const [isUpgradingMembership, setIsUpgradingMembership] = React.useState(false);
  const [subscriptionUpgradePlan, setSubscriptionUpgradePlan] = React.useState(null);

  const [errorMessage, setErrorMessage] = React.useState('');

  const [isCreditCardExpired, setIsCreditCardExpired] = React.useState(false);

  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw(fatalError);
  }

  const subscription = React.useContext(SubscriptionContext);
  const {
    currentSubscriptionName,
    currentSubscriptionDescription,
    currentSubscriptionIsFreeTrial,
    currentSubscriptionPrice,

    paymentInvoicesById,
    paymentInvoiceIds,

    creditCardId,
    creditCardBrand,
    creditCardLast4,
    creditCardExpirationDate,

    dispatchSubscriptionAction,
  } = subscription;

  React.useEffect(() => {
    const loadSubscriptionData = async() => {
      try {
        if (!currentSubscriptionIsFreeTrial) {
          const paymentHistory = await genPaymentHistory(authToken, currentPerspectiveId);
          dispatchSubscriptionAction({
            type: 'HYDRATE_PAYMENT_HISTORY',
            payload: paymentHistory,
          });

          const paymentMethod = await genPaymentMethod(authToken, currentPerspectiveId);
          dispatchSubscriptionAction({
            type: 'HYDRATE_CREDIT_CARD',
            payload: paymentMethod,
          });
        }

        if (currentSubscriptionIsFreeTrial) {
          const subscriptionUpgrades = await genSubscriptionUpgrades(authToken, currentPerspectiveId);
          setSubscriptionUpgradePlan(subscriptionUpgrades[0]);
        }

        setIsLoading(true);
      } catch (error) {
        setError(error);
      }
    };

    loadSubscriptionData();
  }, [authToken, currentPerspectiveId, currentSubscriptionIsFreeTrial, dispatchSubscriptionAction]);

  React.useEffect(() => {
    if (creditCardExpirationDate) {
      const now = new Date();
      const [month, year] = creditCardExpirationDate.split('/');

      setIsCreditCardExpired(now.getTime() > new Date(parseInt(`20${year}`), parseInt(month), 0).getTime());
    }
  }, [creditCardExpirationDate]);

  if (!isLoading) {
    return (
      <FullScreenSpinner />
    );
  };

  const handleDeletePaymentMethod = async() => {
    try {
      setIsDeletingPayment(true);
      await deletePaymentMethod(authToken, currentPerspectiveId);
      dispatchSubscriptionAction({
        type: 'HYDRATE_CREDIT_CARD',
        payload: null,
      });
      setIsDeletingPayment(false);
      setIsDeletePaymentDialogOpen(false);
    } catch(error) {
      setError(error);
    }
  };

  const handleAddPaymentMethod = async(event) => {
    try {
      setIsAddingPaymentMethod(true);
      event.preventDefault();
      const cardElement = elements.getElement(CardElement);
      const {token, error} = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
        setIsAddingPaymentMethod(false);
      } else {
        const setPaymentMethodResponse = await setPaymentMethod(authToken, currentPerspectiveId, {stripePayload: token});
        dispatchSubscriptionAction({
          type: 'HYDRATE_CREDIT_CARD',
          payload: setPaymentMethodResponse,
        });
        setIsAddingPaymentMethod(false);
        setIsAddPaymentDialogOpen(false);
      }
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleMakePayment = async() => {
    try {
      setIsMakingPayment(true);
      const renewCurrentSubscriptionResponse = await renewCurrentSubscription(authToken, currentPerspectiveId);
      dispatchSubscriptionAction({
        type: 'HYDRATE_CURRENT_SUBSCRIPTION',
        payload: {
          ...renewCurrentSubscriptionResponse,
        },
      });

      const paymentHistoryResponse = await genPaymentHistory(authToken, currentPerspectiveId);
      dispatchSubscriptionAction({
        type: 'HYDRATE_PAYMENT_HISTORY',
        payload: paymentHistoryResponse,
      });

      setIsMakingPayment(false);
      setIsMakePaymentDialogOpen(false);
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsMakingPayment(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsMakingPayment(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsMakingPayment(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleUpdatePaymentMethod = async(event) => {
    try {
      setIsUpdatingPaymentMethod(true);
      event.preventDefault();
      const cardElement = elements.getElement(CardElement);
      const {token, error} = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
        setIsUpdatingPaymentMethod(false);
      } else {
        const setPaymentMethodResponse = await setPaymentMethod(authToken, currentPerspectiveId, {stripePayload: token});
        dispatchSubscriptionAction({
          type: 'HYDRATE_CREDIT_CARD',
          payload: setPaymentMethodResponse,
        });
        setIsUpdatingPaymentMethod(false);
        setIsUpdatePaymentMethodDialogOpen(false);
      }
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsUpdatingPaymentMethod(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsUpdatingPaymentMethod(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsUpdatingPaymentMethod(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  const handleUpgradeMembership = async(event) => {
    try {
      setIsAddingPaymentMethod(true);
      setIsUpgradingMembership(true);
      event.preventDefault();
      const cardElement = elements.getElement(CardElement);
      const {token, error} = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
        setIsAddingPaymentMethod(false);
        setIsUpgradingMembership(false);
      } else {

        const setPaymentMethodResponse = await setPaymentMethod(authToken, currentPerspectiveId, {stripePayload: token});

        dispatchSubscriptionAction({
          type: 'HYDRATE_CREDIT_CARD',
          payload: setPaymentMethodResponse,
        });

        const purchaseNewSubscriptionResponse = await purchaseNewSubscription(authToken, currentPerspectiveId, {subscriptionPlanId: subscriptionUpgradePlan.id});

        setIsAddingPaymentMethod(false);
        setIsUpgradingMembership(false);
        setIsUpgradeMembershipDialogOpen(false);

        dispatchSubscriptionAction({
          type: 'HYDRATE_CURRENT_SUBSCRIPTION',
          payload: purchaseNewSubscriptionResponse,
        });

        const paymentHistoryResponse = await genPaymentHistory(authToken, currentPerspectiveId);
        dispatchSubscriptionAction({
          type: 'HYDRATE_PAYMENT_HISTORY',
          payload: paymentHistoryResponse,
        });
      }
    } catch(error) {
      const {
        body: {
          code,
          message,
        },
      } = error;

      switch (code) {
        case 1006: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          setIsUpgradingMembership(false);
          break;
        }
        case 1011: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          setIsUpgradingMembership(false);
          break;
        }
        case 1048: {
          setErrorMessage(message);
          setIsAddingPaymentMethod(false);
          setIsUpgradingMembership(false);
          break;
        }
        default: {
          setError(error);
        }
      }
    }
  };

  return (
    <>
      <ApplicationBar
        setIsLogoutModalOpen={setIsLogoutModalOpen}
        enablePerspectiveSwitcher={false}
        enableAccountPanel={false}
        enableNotifications={false}
      />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '65px 50px 50px 50px',
      }}>
        <Grid container>
          <Grid item xs={12} style={{paddingBottom: 34}}>
            <Typography
              variant="h3"
              fontWeight="bold"
            >
              Welcome to Jules!
            </Typography>
          </Grid>
          <Grid item xs={12} style={{paddingBottom: 68}}>
            <BannerMessage
              icon={
                <InfoRoundedIcon
                  fontSize="large"
                />
              }
              title="This membership has expired."
              type="error"
            >
              <MessageRow>
                <Typography
                  variant="body"
                >
                      Manage your details below to restore access to your Jules account.
                </Typography>
              </MessageRow>
            </BannerMessage>
          </Grid>
          <Grid item xs={12} style={{padding: '0px 0px 0px 16px'}}>
            <Typography
              variant="h5"
              fontWeight="bold"
            >
                    Membership type
            </Typography>
          </Grid>
          <Grid item xs={12} style={{padding: '10px 0px 0px 16px'}}>
            <Typography
              variant="small"
              fontWeight="bold"
            >
              {currentSubscriptionName.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{padding: '10px 0px 0px 16px'}}>
            <Typography
              variant="caption"
            >
              {currentSubscriptionDescription}
            </Typography>
          </Grid>
          {
            currentSubscriptionIsFreeTrial &&
                      <Grid item xs={3} style={{padding: '30px 0px 0px 16px'}}>
                        <Button
                          variant="text"
                          startIcon={<ArrowUpwardRoundedIcon />}
                          onClick={() => {
                            setIsUpgradeMembershipDialogOpen(true);
                          }}
                        >
                            Upgrade
                        </Button>
                      </Grid>
          }

          {
            !currentSubscriptionIsFreeTrial &&
                        <>
                          <Grid item xs={4} style={{padding: '30px 0px 0px 16px'}}>
                            <Button
                              variant="text"
                              disabled={creditCardId === null || isCreditCardExpired}
                              onClick={() => {
                                setIsMakePaymentDialogOpen(true);
                              }}
                            >
                              Make a one-off payment
                            </Button>
                          </Grid>
                          <Grid item xs={12} style={{padding: '68px 0px 0px 16px'}}>
                            <Typography
                              variant="h5"
                              fontWeight="bold"
                            >
                                Credit Card
                            </Typography>
                          </Grid>
                          {
                            creditCardId !== null ?
                              <>
                                <Grid item xs={12} style={{padding: '10px 0px 0px 16px'}}>
                                  <Typography
                                    variant="small"
                                    fontWeight="bold"
                                  >
                                    {creditCardBrand} **{creditCardLast4}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} style={{padding: '10px 0px 0px 16px'}}>
                                  <Typography
                                    variant="caption"
                                    color={isCreditCardExpired ? 'error' : 'black'}
                                  >
                                    {`Expiration date: ${creditCardExpirationDate}`}
                                  </Typography>
                                  {
                                    isCreditCardExpired &&
                                      <Typography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="error"
                                      >
                                          &nbsp;(card expired)
                                      </Typography>
                                  }
                                </Grid>
                                <Grid item xs={12} style={{padding: '30px 0px 0px 16px'}}>
                                  <Button
                                    variant="text"
                                    endIcon={<CreditCardRoundedIcon />}
                                    onClick={() => {
                                      setIsUpdatePaymentMethodDialogOpen(true);
                                    }}
                                  >
                                          Change
                                  </Button>
                                  <Button
                                    variant="text"
                                    textTransform="uppercase"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                    onClick={() => {
                                      setIsDeletePaymentDialogOpen(true);
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      fontWeight="bold"
                                      fontFamily="primary"
                                      color="error"
                                    >
                                                Delete
                                    </Typography>
                                  </Button>
                                </Grid>
                              </>
                              :
                              <>
                                <Grid item xs={12} style={{padding: '30px 0px 0px 16px'}}>
                                  <Button
                                    variant="text"
                                    endIcon={<CreditCardRoundedIcon />}
                                    onClick={() => {
                                      setIsAddPaymentDialogOpen(true);
                                    }}
                                  >
                                              Add
                                  </Button>
                                </Grid>
                              </>
                          }
                          <Grid item xs={12} style={{padding: '68px 0px 0px 16px'}}>
                            <Typography
                              variant="h5"
                              fontWeight="bold"
                            >
                                            Payment History
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{padding: '10px 0px 0px 16px'}}>
                            {
                              paymentInvoiceIds.length === 0 ?
                                <Grid item xs={12} style={{padding: '10px 0px 0px 0px'}}>
                                  <Typography
                                    variant="small"
                                    fontWeight="bold"
                                  >
                                                  There are no invoices available.
                                  </Typography>
                                </Grid>
                                :
                                paymentInvoiceIds.map((id, index) => {
                                  const {
                                    date,
                                    amount,
                                    details,
                                  } = paymentInvoicesById[id];

                                  return (
                                    <ListItem
                                      key={index}
                                    >
                                      <ListItemContentSegment
                                        width={120}
                                      >
                                        <ListItemText>
                                          <Typography
                                            variant="caption"
                                          >
                                            {date}
                                          </Typography>
                                        </ListItemText>
                                      </ListItemContentSegment>
                                      <ListItemContentSegment
                                        width={100}
                                      >
                                        <ListItemText>
                                          <Typography
                                            variant="caption"
                                            fontWeight="bold"
                                          >
                                                                    $ {amount}
                                          </Typography>
                                        </ListItemText>
                                      </ListItemContentSegment>
                                      <ListItemContentSegment>
                                        <ListItemText>
                                          <Typography
                                            variant="caption"
                                          >
                                            {details}
                                          </Typography>
                                        </ListItemText>
                                      </ListItemContentSegment>
                                      <ListItemContentSegment
                                        width={100}
                                        justifyContent="flex-end"
                                      >
                                        <ListItemActions>
                                          <Button
                                            variant="icon-text"
                                            onClick={(ev) => {
                                              genPaymentInvoice(authToken, currentPerspectiveId, paymentInvoicesById[id]);
                                            }}
                                          >
                                            <GetAppRoundedIcon />
                                          </Button>
                                        </ListItemActions>
                                      </ListItemContentSegment>
                                    </ListItem>
                                  );
                                })
                            }
                          </Grid>
                        </>
          }
        </Grid>
        <AddCardDialog
          isVisible={isAddPaymentDialogOpen}
          isProcessing={isAddingPaymentMethod}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleClose={() => {
            setErrorMessage('');
            setIsAddPaymentDialogOpen(false);
          }}
          handleSubmit={handleAddPaymentMethod}
        />
        <RemoveCardDialog
          isVisible={isDeletePaymentDialogOpen}
          isProcessing={isDeletingPayment}
          handleClose={() => {
            setIsDeletePaymentDialogOpen(false);
          }}
          handleSubmit={handleDeletePaymentMethod}
        />
        <PaymentDialog
          price={currentSubscriptionPrice}
          isVisible={isMakePaymentDialogOpen}
          isProcessing={isMakingPayment}
          handleClose={() => {
            setIsMakePaymentDialogOpen(false);
          }}
          handleSubmit={handleMakePayment}
        />
        <UpdateCardDialog
          isVisible={isUpdatePaymentMethodDialogOpen}
          isProcessing={isUpdatingPaymentMethod}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleClose={() => {
            setErrorMessage('');
            setIsUpdatePaymentMethodDialogOpen(false);
          }}
          handleSubmit={handleUpdatePaymentMethod}
        />
        <UpgradeMembershipDialog
          isVisible={isUpgradeMembershipDialogOpen}
          isProcessing={isUpgradingMembership}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleClose={() => {
            setErrorMessage('');
            setIsUpgradeMembershipDialogOpen(false);
          }}
          handleSubmit={handleUpgradeMembership}
          subscriptionUpgradePlan={subscriptionUpgradePlan}
        />
      </div>
      <LogOutModal
        isOpen={isLogoutModalOpen}
        handleClose={() => {
          setIsLogoutModalOpen(false);
        }}
      />
    </>
  );
};

export default SuspendedSubscriptionHousehold;
