import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import {
  COLOR_MONIKERS,
} from 'ui-library/color-palette';

const useStyles = makeStyles({
  rail: {
    height: props => props.height,
    backgroundColor: props => theme.palette[props.railColor],
  },
  progress: {
    width: props => `${props.progressPercentage}%`,
    height: props => props.height,
    backgroundColor: props => theme.palette[props.progressColor],
    transition: theme.progressBar.transition,
  },
});

const ProgressBar = ({
  height,
  railColor,
  progressColor,
  progress,
  total,
}) => {
  const progressPercentage = 100 / total * progress;

  const classes = useStyles({
    height,
    railColor,
    progressColor,
    progressPercentage,
  });

  return (
    <div
      className={classes.rail}>
      <div
        className={classes.progress}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  height: PropTypes.number,
  railColor: PropTypes.oneOf([...COLOR_MONIKERS, 'transparent']),
  progressColor: PropTypes.oneOf(COLOR_MONIKERS),
  progress: PropTypes.number,
  total: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  height: 8,
  railColor: 'transparent',
  progressColor: 'primary',
  progress: 0,
};

export default ProgressBar;
