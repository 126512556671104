import React from 'react';
import styled from '@emotion/styled';

const Root = styled.div`
  display: flex;
  margin: 16px 0;
`;

const DrilldownTracker = ({
  children,
}) => {
  return (
    <Root>
      {children}
    </Root>
  );
};

export default DrilldownTracker;
