import React from 'react';

import ModalCard from 'ui-library/components/ModalCard';
import ModalCardHeader from 'ui-library/components/ModalCardHeader';
import ModalCardHeaderTitle from 'ui-library/components/ModalCardHeaderTitle';
import ModalCardHeaderIcon from 'ui-library/components/ModalCardHeaderIcon';
import ModalCardContent from 'ui-library/components/ModalCardContent';
import ModalCardActions from 'ui-library/components/ModalCardActions';

import Typography from 'ui-library/components/Typography';
import Button from 'ui-library/components/Button';

import WarningIcon from '@material-ui/icons/WarningRounded';

const NoPermissionsModal = ({
  isOpen,
  onClose,
  operation,
}) => {
  return (
    <ModalCard
      open={isOpen}
      handleClose={() => {
        onClose();
      }}
      maxWidth="sm"
      fullWidth={true}
      disableBackdropClick={true}
    >
      <ModalCardHeader color="error">
        <ModalCardHeaderIcon color="error">
          <WarningIcon fontSize="inherit"/>
        </ModalCardHeaderIcon>
        <ModalCardHeaderTitle>
          {operation}
        </ModalCardHeaderTitle>
      </ModalCardHeader>
      <ModalCardContent>
        <Typography variant="caption">
          You don't have enough permissions to perform this operation.
        </Typography>
      </ModalCardContent>
      <ModalCardActions>
        <Button
          data-test-id="modal-card-cancel-button"
          fullWidth={true}
          variant="text"
          onClick={() => {
            onClose();
          }}
        >
          CLOSE
        </Button>
      </ModalCardActions>
    </ModalCard>
  );
};

export default NoPermissionsModal;
