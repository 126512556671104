import PUT__nodes_ID_fields from 'network-layer/endpoints/PUT__nodes_ID_fields';

function responseAdapter(response) {
  return response;
}

export default async function replaceNodeFields(params) {
  const response = await PUT__nodes_ID_fields(params);

  return responseAdapter(response);
}
