import React from 'react';
import styled from '@emotion/styled';
import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';

const Root = styled.div`
  display: flex;
  align-items: center;
`;

const WizardSubmitWithEnterButton = React.forwardRef(({
  children,
  variant = 'secondary',
  disabled,
  ...otherProps
}, ref) => {
  return (
    <Root>
      <Button
        ref={ref}
        type="submit"
        variant={variant}
        disabled={disabled}
        style={{fontSize: '16px'}}
        disableRipple
        disableFocusRipple
        {...otherProps}>
        {children}
      </Button>
      {
        !disabled &&
        <>
          &nbsp;&nbsp;&nbsp;
          <Typography
            variant="tiny">
            press&nbsp;
            <Typography
              variant="tiny"
              fontWeight="bold">
              ENTER
            </Typography>
          </Typography>
        </>
      }
    </Root>
  );
});

export default WizardSubmitWithEnterButton;
