import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';
import TypeIcon from '@material-ui/icons/DonutLarge';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';

const VirtualLocationTreeNodeHint = ({
  virtualLocationTreeNodeHints,
  currentVirtualLocationTreeNodeHint,
  DataModel,
  DataModelQueries,
  onCreate,
  onDelete,
}) => {
  const {
    id: currentVirtualLocationTreeNodeHintId,
    prevalence,
    prevalenceWeight,
    typeId,
  } = currentVirtualLocationTreeNodeHint;

  const type = DataModelQueries.selectTypeById(DataModel, typeId);
  const {
    name: typeName,
  } = type;

  const children = virtualLocationTreeNodeHints
    .filter(child => {
      const {
        parentId,
      } = child;
      return parentId === currentVirtualLocationTreeNodeHintId;
    });

  return (
    <NestedBox>
      <ContentLine>
        <TypeIcon/>&nbsp;&nbsp;
        <ContentLineText>
          <Typography
            vartiant="body1">
            {typeName}&nbsp;&nbsp;
            {
              <span
                style={{
                  color: '#aaa',
                }}>
                {prevalence} | {prevalenceWeight}
              </span>
            }
          </Typography>
        </ContentLineText>
        <Tooltip
          placement="top"
          title="Add nested location hint">
          <IconButton
            onClick={() => {
              onCreate(currentVirtualLocationTreeNodeHintId, typeId);
            }}>
            <AddIcon/>
          </IconButton>
        </Tooltip>
        {/* <IconButton
          onClick={() => {
          }}>
          <EditIcon/>
        </IconButton> */}
        <IconButton
          onClick={() => {
            onDelete(currentVirtualLocationTreeNodeHintId);
          }}>
          <DeleteIcon/>
        </IconButton>
      </ContentLine>

      {
        children.map(child => {
          const {
            id,
          } = child;
          return (
            <VirtualLocationTreeNodeHint
              key={id}
              virtualLocationTreeNodeHints={virtualLocationTreeNodeHints}
              currentVirtualLocationTreeNodeHint={child}
              DataModel={DataModel}
              DataModelQueries={DataModelQueries}
              onCreate={onCreate}
              onDelete={onDelete}
            />
          );
        })
      }
    </NestedBox>
  );
};

export default VirtualLocationTreeNodeHint;
