import PUT__records_ID_permissions from 'network-layer/endpoints/PUT__records_ID_permissions';

function responseAdapter(response) {
  return response;
}

export default async function updateRecordPermissions(authToken, perspectiveId, recordId, params) {
  const response = await PUT__records_ID_permissions(authToken, perspectiveId, recordId, params);

  return responseAdapter(response);
}
