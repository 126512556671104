import genNodeByNodeId from 'services/Nodes/genNodeByNodeId';

function genPeopleBreadcrumbsFromPathname(pathname, authToken, perspectiveId) {
  const path = pathname.split('/').filter(segment => segment);
  return new Promise((resolve, reject) => {
    const breadcrumbs = [{
      path: '/people',
      label: 'People',
    }];

    const dataSegments = [
      extractPersonId(path),
    ];

    const promises = [];

    for (let i = 0; i < dataSegments.length; i++) {
      if (dataSegments[i] === null) {
        break;
      }
      switch(i) {
        case 0: {
          promises.push(new Promise(async(resolve, reject) => {
            const person = await genNodeByNodeId({authToken, perspectiveId, nodeId: dataSegments[i]});
            resolve(person);
          }));
          break;
        }
        default:
          break;
      }
    }

    Promise.all(promises).then(([person]) => {
      if (!person) {
        return resolve(breadcrumbs);
      }

      breadcrumbs.push({
        id: person.id,
        label: person.name,
        path: `/people/${person.id}`,
      });

      resolve(breadcrumbs);
    });
  });
}

function extractPersonId(path) {
  if (path[1]) {
    return +path[1];
  }
  return null;
}

export default genPeopleBreadcrumbsFromPathname;
