function selectTypeRelationshipById(DM, typeRelationshipId) {
  const {
    typeRelationshipsById: {
      [typeRelationshipId]: typeRelationship,
    },
  } = DM;

  return typeRelationship;
}

export default selectTypeRelationshipById;
