import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'ui-library/components/TextField';

import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';

import NumberFormat from 'react-number-format';

import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  editContainer: theme.field.editContainer,
  actionButtonsContainer: theme.field.actionButtonsContainer,
  actionButton: theme.field.actionButton,
});

const NumberEditMode = ({
  autoFocus,
  inlineEdit,
  label,
  helperText,
  error,
  value,
  disabled,
  required,
  onChange,
  onKeyPress,
  onBlur,
  onFocus,
  onMouseDown,
  saveField,
  revertField,
}) => {

  const classes = useStyles();

  if (!inlineEdit) {
    return (
      <NumberFormat
        customInput={TextField}
        thousandSeparator=","
        decimalSeparator="."
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        value={value}
        disabled={disabled}
        required={required}
        onKeyPress={values => {
          if (typeof onKeyPress === 'function') {
            onKeyPress({
              key: values.key,
              target: {
                value: value,
              },
            });
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  }

  return (
    <div
      className={classes.editContainer}
    >
      <div
        className={classes.actionButtonsContainer}
      >
        {
          !disabled &&
          <div
            className={classes.actionButton}
            onMouseDown={onMouseDown}
          >
            <CloseIcon
              data-test-id="inline-revert-field-button"
              onClick={revertField}
            />
          </div>
        }
        {
          !disabled &&
          <div
            className={classes.actionButton}
          >
            <CheckIcon
              data-test-id="inline-save-field-button"
              onClick={() => {
                if (!error) {
                  saveField();
                }
              }}
              onMouseDown={onMouseDown}
            />
          </div>
        }
      </div>
      <NumberFormat
        customInput={TextField}
        thousandSeparator=","
        decimalSeparator="."
        autoFocus={autoFocus}
        label={label}
        helperText={helperText}
        error={error}
        value={value}
        disabled={disabled}
        required={required}
        onKeyPress={values => {
          if (typeof onKeyPress === 'function') {
            onKeyPress({
              key: values.key,
              target: {
                value: value,
              },
            });
          }
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        onMouseDown={onMouseDown}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    </div>
  );
};

NumberEditMode.propTypes = {
  autoFocus: PropTypes.bool,
  inlineEdit: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  saveField: PropTypes.func,
  revertField: PropTypes.func,
};

export default NumberEditMode;
