import React from 'react';
import styled from '@emotion/styled';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import UserInterfaceContext from 'contexts/UserInterface';

import genLocationCandidateRoots from 'services/Locations/genLocationCandidateRoots';
import genLocationCandidatesTree from 'services/Locations/genLocationCandidatesTree';
import updateNodeLocation from 'services/Nodes/updateNodeLocation';
import genNodeRecordCandidates from 'services/Nodes/genNodeRecordCandidates';

import ContextualPanelContent from 'components/chrome/ContextualPanelContent';
import ContextualPanelActions from 'components/chrome/ContextualPanelActions';

import LocationWarning from 'ui-library/components/LocationWarning';
import Select from 'ui-library/components/Select';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';
import Chip from 'ui-library/components/Chip';
import Button from 'ui-library/components/Button';

import Grid from '@material-ui/core/Grid';


import {
  greySemi,
} from 'ui-library/color-palette';

const Description = styled.div`
  margin-bottom: 18px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 2px solid ${greySemi};
  flex-wrap: wrap;
`;

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const SecondButtonContainer = styled.div`
  margin-left: 8px;
`;

const NodeLocationEdit = ({
  nodeState,
  dispatchNodeAction,
  setError,
  panelDisplayMode,
}) => {

  const {
    authToken,
  } =  React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } =  React.useContext(PerspectiveContext);

  const {
    showNodePanel,
    externalRecordConnectionId,
    dispatchUserInterfaceAction,
  } = React.useContext(UserInterfaceContext);

  const {
    node: {
      id: nodeId,
      typeId,
      record: {
        id: recordId,
        name: recordName,
      },
      locationPath,
    },
  } = nodeState;

  const [tabState, setTabState] = React.useState('not loaded'); // 'not loaded', 'loading', 'loaded'

  const [isLocationLoading, setIsLocationLoading] = React.useState(false);
  const [recordCandidates, setRecordCandidates] = React.useState([]);
  const [recordCandidatesOptions, setRecordCandidatesOptions] = React.useState([]);
  const [selectedRecordCandidate, setSelectedRecordCandidate] = React.useState(null);
  const [recordCandidateIsPristine, setRecordCandidateIsPristine] = React.useState(true);
  const [currentLocationPath, setCurrentLocationPath] = React.useState('No Location');
  const [locationCandidateRoots, setLocationCandidateRoots] = React.useState([]);
  const [locationCandidateRootOptions, setLocationCandidateRootOptions] = React.useState([]);
  const [activeCandidateRoot, setActiveCandidateRoot] = React.useState(null);
  const [locationNodes, setLocationNodes] = React.useState([]);
  const [locationCandidatesTree, setLocationCandidatesTree] = React.useState([]);
  const [locationLevels, setLocationLevels] = React.useState([]);
  const [isLocationValid, setIsLocationValid] = React.useState(true);

  React.useEffect(() => {
    const initLocation = async() => {
      if (recordId) {
        setTabState('loading');
        setIsLocationLoading(true);
        const responseRecordCandidates = await genNodeRecordCandidates(authToken, currentPerspectiveId, nodeId);
        setRecordCandidates(responseRecordCandidates);

        const optionsRecordCandidates = responseRecordCandidates.map((record) => {
          return {
            label: record.name,
            value: record.id,
          };
        });

        setRecordCandidatesOptions(optionsRecordCandidates);
        setCurrentLocationPath(locationPath ? locationPath.length ? locationPath.join(' / ') : 'No Location' : 'No Location');

        const isRecordInResponseRecordCandidates = responseRecordCandidates.filter(recordCandidate => recordCandidate.id === recordId).length > 0;

        if (isRecordInResponseRecordCandidates) {

          setSelectedRecordCandidate({
            id: recordId,
            name: recordName,
          });

          const responseLocationCandidateRoots = await genLocationCandidateRoots(authToken, currentPerspectiveId, typeId, recordId);
          const candidateRoots = [
            {
              id: 0,
              isLocationCandidate: true,
              locationId: null,
              name: 'No Location',
            },
            ...responseLocationCandidateRoots,
          ];
          setLocationCandidateRoots(candidateRoots);
          const optionsLocationCandidateRoots = candidateRoots.map((locationCandidateRoot) => {
            return {
              label: locationCandidateRoot.name,
              value: locationCandidateRoot.id,
            };
          });
          setLocationCandidateRootOptions(optionsLocationCandidateRoots);
        }

        setIsLocationLoading(false);
        setTabState('loaded');
      }
    };

    if (tabState === 'not loaded') {
      initLocation();
    } else {
      // clearLocation();
    }
  }, [authToken, currentPerspectiveId, externalRecordConnectionId, locationPath, nodeId, recordId, recordName, tabState, typeId]);
  // }, [authToken, currentPerspectiveId, recordId, typeId]);

  React.useEffect(() => {
    const loadCandidateRoots = async() => {
      setIsLocationLoading(true);
      const {
        id,
      } = selectedRecordCandidate;
      const responseLocationCandidateRoots = await genLocationCandidateRoots(authToken, currentPerspectiveId, typeId, id);
      const candidateRoots = [
        {
          id: 0,
          isLocationCandidate: true,
          locationId: null,
          name: 'No Location',
        },
        ...responseLocationCandidateRoots,
      ];
      setLocationCandidateRoots(candidateRoots);
      const optionsLocationCandidateRoots = candidateRoots.map((locationCandidateRoot) => {
        return {
          label: locationCandidateRoot.name,
          value: locationCandidateRoot.id,
        };
      });
      setLocationCandidateRootOptions(optionsLocationCandidateRoots);
      setIsLocationLoading(false);
    };


    if (selectedRecordCandidate) {
      if (!recordCandidateIsPristine) {
        setCurrentLocationPath('No Location');
        setActiveCandidateRoot(null);
        setLocationNodes([]);
        setLocationCandidatesTree([]);
        setLocationLevels([]);
        loadCandidateRoots();
      }
    }
  }, [recordCandidateIsPristine, selectedRecordCandidate, authToken, currentPerspectiveId, typeId]);

  React.useEffect(() => {
    if (!locationNodes.length) {
      setIsLocationValid(true);
    } else {
      setIsLocationValid(locationNodes[locationNodes.length - 1].isLocationCandidate);
    }
    const path = locationNodes.length > 0 ? locationNodes.map((node) => node.name).join(' / ') : 'No Location';
    setCurrentLocationPath(path);
  }, [locationNodes]);

  const handleCandidateRootChange = async(event) => {
    const candidateRoot = locationCandidateRoots.filter(node => node.id === event.target.value)[0];
    setActiveCandidateRoot(candidateRoot);

    setLocationCandidatesTree([]);
    setLocationLevels([]);
    setLocationNodes([candidateRoot]);

    if (candidateRoot.id !== 0) {
      setIsLocationLoading(true);
      const response = await genLocationCandidatesTree(authToken, currentPerspectiveId, typeId, recordId, event.target.value);
      setLocationCandidatesTree(response);
      setLocationLevels([{
        id: candidateRoot.id,
        nodes: response.filter(node => node.locationId === candidateRoot.id),
      }]);
      setIsLocationLoading(false);
    }
  };

  const getNodeLevelIndex = (node) => {
    const {
      locationId,
    } = node;

    const isSelectedNode = (element) => {
      const {
        id,
      } = element;
      let found = true;

      if (id !== locationId) {
        found = false;
      }
      return found;
    };
    const nodeLevelIndex = locationLevels.findIndex(isSelectedNode);

    return nodeLevelIndex;
  };

  const saveNodeLocation = async() => {
    try {
      let locationId;

      if (activeCandidateRoot === null) {
        locationId = null;
      } else {
        locationId = locationNodes[locationNodes.length - 1].id !== 0 ? locationNodes[locationNodes.length - 1].id : null;
      }

      await updateNodeLocation({authToken, currentPerspectiveId, id: nodeId, recordId: selectedRecordCandidate.id, locationId});

      dispatchNodeAction({
        type: 'SET_LOCATION_PATH',
        payload: locationNodes.map(el => el.name),
      });
      dispatchNodeAction({
        type: 'SET_RECORD',
        payload: {
          recordId: selectedRecordCandidate.id,
          recordName: selectedRecordCandidate.name,
        },
      });
      dispatchUserInterfaceAction({
        type: 'SET_NODE_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: nodeId,
      });
      dispatchUserInterfaceAction({
        type: 'SET_NODE_PANEL_DISPLAY_MODE',
        payload: 'default',
      });
    } catch(error) {
      setError(error);
    }
  };


  if (isLocationLoading || tabState !== 'loaded') {
    return (
      <div>
        Loading ...
      </div>
    );
  }

  return (
    <>
      <ContextualPanelContent
        panelDisplayMode={panelDisplayMode}
      >
        <PaddedContainer>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                cursor: 'pointer',
                flexGrow: 1,
                height: '100%',
              }}
            >
              <LocationWarning
                warning="Changing the assignment will reset the location"
              />
              <div style={{
                marginTop: 24,
                display: 'flex',
                flexGrow: 1,
                height: 80,
                alignItems: 'center',
              }}>
                <Select
                  label="Assigned to:"
                  value={selectedRecordCandidate ? selectedRecordCandidate.id : ''}
                  onChange={(ev) => {
                    if (!recordCandidateIsPristine || ev.target.value !== recordId) {
                      setRecordCandidateIsPristine(false);
                      setSelectedRecordCandidate(recordCandidates.filter(record => ev.target.value === record.id)[0]);
                    }
                  }}
                  placeholder="Select Record"
                  customOptions={recordCandidatesOptions}
                />
              </div>

              <div style={{
                marginTop: 8,
                display: 'flex',
                flexGrow: 1,
                height: 80,
                marginBottom: 20,
              }}>
                <TextField
                  disabled={true}
                  label="Location Path"
                  value={currentLocationPath}
                  helperText={isLocationValid ? '' : 'Location is incomplete'}
                  success={isLocationValid}
                  error={!isLocationValid}
                />
              </div>

              <div style={{
                display: 'flex',
                flexGrow: 1,
                height: 80,
                alignItems: 'center',
              }}>
                {
                  locationCandidateRoots.length > 1 ?
                    <Select
                      value={activeCandidateRoot ? activeCandidateRoot.id : ''}
                      onChange={handleCandidateRootChange}
                      placeholder="Choose where to locate your item"
                      customOptions={locationCandidateRootOptions}
                    />
                    :
                    <Description>
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                      >
                    No locations available
                      </Typography>
                      <br/>
                      <Typography
                        variant="caption"
                      >
                    This item cannot be located at the moment.
                      </Typography>
                    </Description>
                }
              </div>
              <div>
                {
                  locationLevels.map((level, index) => {
                    const {
                      id,
                      nodes,
                    } = level;

                    if (nodes.length === 0) {
                      return null;
                    }

                    return (
                      <Row
                        key={id}
                      >
                        {
                          nodes.length > 0 &&
                      nodes.map((node, index) => {
                        const {
                          id,
                          name,
                        } = node;

                        return (
                          <Chip
                            key={index}
                            isSelected={locationNodes.filter(node => node.id === id).length > 0}
                            isLast={locationNodes[locationNodes.length - 1].id === id}
                            isLocationValid={isLocationValid}
                            label={name}
                            onClick={() => {
                              // find the node level
                              const nodeLevelIndex = getNodeLevelIndex(node);

                              setLocationNodes([
                                ...locationNodes.slice(0, nodeLevelIndex + 1),
                                node,
                              ]);

                              setLocationLevels([
                                ...locationLevels.slice(0, nodeLevelIndex + 1),
                                {
                                  id,
                                  nodes: locationCandidatesTree.filter(node => node.locationId === id),
                                },
                              ]);
                            }}
                          />
                        );
                      })
                        }
                      </Row>
                    );
                  })
                }
              </div>
            </Grid>
          </Grid>
        </PaddedContainer>
      </ContextualPanelContent>
      <ContextualPanelActions expanded={showNodePanel}>
        <Button
          data-test-id="save-location-button"
          disabled={!isLocationValid || selectedRecordCandidate === null}
          onClick={(ev) => {
            saveNodeLocation();
          }}
        >
          SAVE
        </Button>
        <SecondButtonContainer>
          <Button
            variant="text"
            data-test-id="save-location-button"
            disabled={false}
            onClick={(ev) => {
              dispatchUserInterfaceAction({
                type: 'SET_NODE_PANEL_DISPLAY_MODE',
                payload: 'default',
              });
            }}
          >
            CANCEL
          </Button>
        </SecondButtonContainer>
      </ContextualPanelActions>
    </>
  );
};

export default NodeLocationEdit;
