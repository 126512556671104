import React from 'react';
import PropTypes from 'prop-types';
import {
  white,
  grey,
  greySemi,
  primary,
} from 'ui-library/color-palette';
import Checkmark from './checkmark.png';

const ListItemCheckbox = ({
  checked,
  disabled = false,
  onChange,
  children,
}) => {

  return (
    <div
      style={{
        paddingTop: 4,
      }}
    >
      <input
        type="checkbox"
        onClick={(ev) => {
          ev.stopPropagation();
        }}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        style={{
          outline: 0,
          backgroundColor: checked ? primary : white,
          border: disabled ? `2px solid ${greySemi}` : checked ? 'none' : `2px solid ${grey}`,
          backgroundImage: checked ? `url(${Checkmark})` : '',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          borderRadius: '4px',
          cursor: 'pointer',
          height: 18,
          width: 18,
          apperance: 'none',
          MozAppearance: 'none',
          WebkitAppearance: 'none',
          OAppearance: 'none',
        }}
      />
    </div>
  );
};

ListItemCheckbox.propTypes = {
  children: PropTypes.node,
};

export default ListItemCheckbox;
