import React from 'react';
import PropTypes from 'prop-types';
import MUIDialogActions from '@material-ui/core/DialogActions';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    padding: theme.modalCardContent.padding,
  },
});

const ModalCardContent = ({
  children,
}) => {

  const classes = useStyles();

  return (
    <MUIDialogActions className={classes.root}>
      {children}
    </MUIDialogActions>
  );
};

ModalCardContent.propTypes = {
  children: PropTypes.node,
};

export default ModalCardContent;
