import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircle';

const EditFieldDefinitionDialog = ({
  open,
  onClose,
  controlsDisabled,
  onSaveFieldDefinition,
  label,
  description,
  type,
  enumValues,
  onSetLabel,
  onSetDescription,
  onPushEnumValue,
  onSetEnumValue,
  onRemoveEnumValue,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <DialogTitle>
        Add Field Definition
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="h6">
          Label
        </Typography>
        <TextField
          autoFocus
          fullWidth
          disabled={controlsDisabled}
          margin="normal"
          value={label}
          onChange={event => {
            if (event.target.value.length > 120) {
              return;
            }
            onSetLabel(event.target.value);
          }}
        />
        <br/><br/>

        <Typography
          variant="h6">
          Description
        </Typography>
        <TextField
          fullWidth
          disabled={controlsDisabled}
          margin="normal"
          value={description}
          onChange={event => {
            if (event.target.value.length > 120) {
              return;
            }
            onSetDescription(event.target.value);
          }}
        />
        <br/><br/>

        {
          type === 'enum' &&
          <>
            <Typography
              variant="h6">
              Enum values
            </Typography>
            {
              enumValues
                .map((enumValue, index) => {
                  return (
                    <React.Fragment
                      key={index}>
                      <TextField
                        fullWidth
                        disabled={controlsDisabled}
                        InputProps={{
                          startAdornment:
                            <InputAdornment
                              position="start">
                              <IconButton
                                onClick={() => {
                                  onRemoveEnumValue(index);
                                }}>
                                <RemoveIcon/>
                              </IconButton>
                            </InputAdornment>,
                        }}
                        value={enumValue}
                        onChange={event => {
                          if (event.target.value.length > 100) {
                            return;
                          }
                          onSetEnumValue(index, event.target.value);
                        }}
                      />
                      <br/><br/>
                    </React.Fragment>
                  );
                })
            }
            <Tooltip
              placement="top"
              title="add an enum value">
              <span>
                <IconButton
                  disabled={
                    !!enumValues.length &&
                    !enumValues[enumValues.length - 1].length
                  }
                  onClick={() => {
                    onPushEnumValue();
                  }}>
                  <AddIcon/>
                </IconButton>
              </span>
            </Tooltip>
          </>
        }

      </DialogContent>
      <DialogActions>
        <Button
          disabled={controlsDisabled}
          variant="text"
          onClick={onClose}>
          cancel
        </Button>
        <Button
          disabled={
            controlsDisabled ||
            label === '' ||
            description === '' ||
            (
              type === 'enum' &&
              !enumValues.filter(value => !!value).length
            )
          }
          variant="contained"
          color="primary"
          onClick={onSaveFieldDefinition}>
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFieldDefinitionDialog;
