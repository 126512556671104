import React from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import CheckIcon from '@material-ui/icons/CheckRounded';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import Typography from 'ui-library/components/Typography';

const useStyles = makeStyles({
  iconSelected: {
    width: theme.autocomplete.iconSelected.width,
    height: theme.autocomplete.iconSelected.height,
    margin: theme.autocomplete.iconSelected.margin,
    color: theme.autocomplete.iconSelected.color,
  },
  text: {
    flexGrow: theme.autocomplete.text.flexGrow,
    textTransform: theme.autocomplete.option.textTransform,
  },
  acTextField: {
    '& input': {
      height: theme.filteredTextField.height,
      fontSize: theme.filteredTextField.fontSize,
      padding: theme.filteredTextField.padding,
    },

    // boxShadow on input hover
    '& .MuiInputBase-root:hover': {
      boxShadow: theme.filteredTextField.boxShadow,
    },

    // border
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
      '&:after': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
    },
  },
  container: {
    display: theme.filteredTextField.container.display,
    margin: theme.filteredTextField.container.margin,
    cursor: theme.filteredTextField.container.cursor,
  },
  ac: {
    '& .MuiFormControl-marginNormal': {
      margin: theme.filteredAutocomplete.margin,
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      padding: theme.filteredAutocomplete.padding,
    },

    '& .MuiAutocomplete-inputRoot': {
      fontSize: theme.filteredAutocomplete.inputRoot.fontSize,
      padding: theme.filteredAutocomplete.inputRoot.padding,
      height: theme.filteredAutocomplete.inputRoot.height,
      width: theme.filteredAutocomplete.inputRoot.width,
    },

    '& .MuiAutocomplete-endAdornment': {
      display: theme.filteredAutocomplete.endAdornment.display,
    },
    '& .MuiInputBase-root:hover': {
      boxShadow: theme.filteredTextField.boxShadow,
    },
    '& .MuiInput-underline': {
      '&:before': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
      '&:after': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: theme.filteredTextField.borderBottom,
      },
    },
  },
  valueContainer: {
    display: theme.filterCriterion.valueContainer.display,
  },
  value: {
    whiteSpace: theme.filterCriterion.value.whiteSpace,
    overflow: theme.filterCriterion.value.overflow,
    textOverflow: theme.filterCriterion.value.textOverflow,
    paddingRight: theme.filterCriterion.value.paddingRight,
    textTransform: theme.filteredAutocomplete.value.textTransform,
  },
});

const Autocomplete = ({
  index,
  filterPillValue,
  updateFilterPillValue,
  editMode,
  setEditMode,
  options,
  removeFilterPillCriterion,
}) => {

  const classes = useStyles();
  const InputRefrence = React.createRef();

  const autocompleteValue = filterPillValue.length > 0 ? filterPillValue : [];

  React.useEffect(() => {
    if (editMode === true && filterPillValue.length !== 0) {
      InputRefrence.current.focus();
    }
  }, [editMode, InputRefrence, filterPillValue.length, setEditMode]);

  if (editMode) {
    return (
      <div className={classes.container}>
        <div className={classes.valueContainer}>
          <div className={classes.value}>
            <Typography variant="x-small" fontWeight="bold">
              {filterPillValue.map(filterValue => filterValue.name).join(', ')}
            </Typography>
          </div>
        </div>
        <MUIAutocomplete
          data-test-id="filter-autocomplete"
          multiple
          open={editMode}
          className={classes.ac}
          options={options}
          value={autocompleteValue}
          getOptionLabel={(option) => option.name}
          onBlur={() => {
            setEditMode(false);
            if (filterPillValue.length === 0) {
              removeFilterPillCriterion(index);
            }
          }}
          onChange={(ev, newValue) => {
            if (newValue !== null) {
              updateFilterPillValue(index, newValue);
            } else {
              updateFilterPillValue(index, []);
            }
            InputRefrence.current.focus();
          }}
          renderInput={params => (
            <MUITextField
              {...params}
              data-test-id="filter-autocomplete-input"
              className={classes.acTextField}
              variant="standard"
              size="small"
              fullWidth={true}
              autoFocus
              inputRef={InputRefrence}
            />
          )}
          renderTags={() => null}
          renderOption={(option, {selected}) => {
            return (
              <>
                <div className={classes.text}>
                  {option.name}
                </div>
                <CheckIcon
                  className={classes.iconSelected}
                  style={{visibility: selected ? 'visible' : 'hidden'}}
                />
              </>
            );
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={classes.container}
      onClick={() => {
        setEditMode(true);
      }}
    >
      <div className={classes.valueContainer}>
        <div className={classes.value}>
          <Typography variant="x-small" fontWeight="bold">
            {filterPillValue.map(filterValue => filterValue.name).join(', ')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

Autocomplete.propTypes = {
  index: PropTypes.number,
  filterPillValue: PropTypes.array,
  setValue: PropTypes.func,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  options: PropTypes.array,
  updateFilterPillValue: PropTypes.func,
  removeFilterPillCriterion: PropTypes.func,
};

export default Autocomplete;
