import React from 'react';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import Typography from 'ui-library/components/Typography';
import Fade from '@material-ui/core/Fade';
import Button from 'ui-library/components/Button';
import CenterpieceWarning from 'components/chrome/CenterpieceWarning';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    margin-bottom: 32px;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
`;

const NoAddPermissionScreen = ({
  closeWizardWithPrepend,
}) => {
  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Item
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Connection
          </WizardBreadcrumb>
        </WizardStepTitle>
      </WizardStepHead>
      <WizardContentColumn>
        <Fade in timeout={400}>
          <Root>
            <CenterpieceWarning/>
            <Typography variant="h2" fontWeight="bold" color="black">
              You cannot add items for this Connection.
            </Typography>
            <MessageContainer>
              <Typography variant="lead" fontWeight="normal" color="black">
                You don’t have permission to add items for this Connection.
              </Typography>
              <Typography variant="lead" fontWeight="normal" color="black">
                Please contact this Partner to update your permissions as necessary.
              </Typography>
            </MessageContainer>
            <Actions>
              <Button
                data-test-id="add-item-wizard-no-rights-finish-button"
                variant="primary"
                onClick={() => {
                  closeWizardWithPrepend();
                }}>
                Finish
              </Button>
            </Actions>
          </Root>
        </Fade>
      </WizardContentColumn>
    </WizardStep>
  );
};

export default NoAddPermissionScreen;
