import POST__verifyEmail from 'network-layer/endpoints/POST__verifyEmail';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return response;
}

export default async function verifyEmail(email) {

  const response = await POST__verifyEmail(email);

  return responseAdapter(response);
}
