import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import List from './list';
import EditUser from './edit';

const UsersAdmin = ({
  match,
}) => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/list`}
        component={List}
      />
      <Route
        exact
        path={`${match.path}/:userId`}
        component={EditUser}
      />
      <Redirect
        to={`${match.path}/list`}
      />
    </Switch>
  );
};

export default UsersAdmin;
