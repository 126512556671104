import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__search_filters(authToken, perspectiveId, params) {
  const {
    context,
    criterion,
  } = params;
  let url = `/search/filters?context=${context}&criterion=${criterion}`;

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
