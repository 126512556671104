export default function fieldIsDate(
  params,
  errorMessage = 'invalid date',
) {
  const {
    value,
  } = params;

  if (value === '' || value === null || Date.parse(value)) {
    return '';
  }

  return errorMessage;
}
