import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__payments_invoices_ID_download(authToken, perspectiveId, invoiceId) {
  const url = `/payments/invoices/${invoiceId}/download`;

  return await GET(
    url,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
