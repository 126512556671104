import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles({
  viewContainer: {
    display: theme.field.viewContainer.display,
    flexDirection: theme.field.viewContainer.flexDirection,
    paddingLeft: theme.field.viewContainer.paddingLeft,
    paddingTop: theme.field.viewContainer.paddingTop,
    paddingBottom: theme.field.viewContainer.paddingBottom,
    backgroundColor: theme.field.viewContainer.backgroundColor,
    height: theme.field.viewContainer.height,
    marginTop: props => props.inlineEdit ? theme.field.viewContainer.marginTopInlineEdit : theme.field.viewContainer.marginTop,
  },
  iconValueContainer: theme.field.iconValueContainer,
});

const BooleanViewMode = ({
  inlineEdit,
  label,
  value,
  isSaving,
  onClick,
}) => {

  const classes = useStyles({
    inlineEdit,
  });

  const customOptions = [
    {
      label: 'N/A',
      value: 'N/A',
    },
    {
      label: 'YES',
      value: true,
    },
    {
      label: 'NO',
      value: false,
    },
  ];

  const getLabel = () => {
    return customOptions.filter(option => option.value === value)[0].label;
  };

  return (
    <div
      className={classes.viewContainer}
      onClick={onClick}
    >
      <div className={classes.iconValueContainer}>
        <Typography
          variant="x-small"
          color="grey"
        >
          {label}
        </Typography>
      </div>
      <div className={classes.iconValueContainer}>
        <Typography
          variant="small"
        >
          {isSaving ? 'Saving...' : value !== '' ? getLabel() : ''}
        </Typography>
      </div>
    </div>
  );
};

BooleanViewMode.propTypes = {
  inlineEdit: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  isSaving: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BooleanViewMode;
