import React from 'react';

import Checkbox from 'ui-library/components/Checkbox';
import Typography from 'ui-library/components/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel';

const SelectAllItems = ({
  checked,
  indeterminate,
  disabled,
  onChange,
}) => {

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '56px',
        margin: '8px 0 0',
        padding: '0 30px',
        borderRadius: '4px',
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            disabled={disabled}
            onChange={onChange}
          />
        }
        label={
          <div
            style={{
              marginLeft: 10,
            }}
          >
            <Typography
              variant="body"
              fontWeight="normal"
              fontFamily="primary"
            >
              Select All
            </Typography>
          </div>
        }
      />
    </div>
  );
};

export default SelectAllItems;
