import React from 'react';
import PropTypes from 'prop-types';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardTypeTile from 'components/chrome/WizardTypeTile';

import Typography from 'ui-library/components/Typography';

import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';

const ItemOwnershipTypeStep = ({
  active,
  onSetOwnershipType,
  currentPerspectiveType,
}) => {
  if (!active) {
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          Add to your Jules account
        </WizardStepTitle>
        <WizardStepDescription>
          {
            currentPerspectiveType === 'company' ?
              'You can add a new item, property, employee account, or create a new external account connection.'
              :
              'You can add a new item, property, household member, or create a new external account connection.'
          }
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        <WizardStepContent>
          <div style={{'paddingBottom': '30px'}}>
            <Typography variant="lead">
              Where would you like to add this item?
            </Typography>
          </div>
          <WizardTypeTile
            data-test-id="internal-item-tile"
            icon={<LibraryBooksRoundedIcon/>}
            name="to one of my records"
            description="Add an item to one of the records owned by you."
            onClick={() => onSetOwnershipType('internal')}
          />
          <WizardTypeTile
            data-test-id="external-item-tile"
            icon={<ShareRoundedIcon/>}
            name="To an external record"
            description="Add an item to one of the records shared with you."
            onClick={() => onSetOwnershipType('external')}
          />
        </WizardStepContent>
      </WizardContentColumn>
    </WizardStep>
  );
};

ItemOwnershipTypeStep.propTypes = {
  active: PropTypes.bool,
  onSetOwnershipType: PropTypes.func.isRequired,
};

export default ItemOwnershipTypeStep;
