import nodePermissionsIntToAtomicPermissions from './nodePermissionsIntToAtomicPermissions';
import nodeAtomicPermissionsToFrontendPermissions from './nodeAtomicPermissionsToFrontendPermissions';
import {
  NODE_FRONTEND_PERMISSIONS,
} from './PERMISSION_FLAGS';

function parseNodePermissions(permissionsInt) {
  const atomicPermissions = nodePermissionsIntToAtomicPermissions(permissionsInt);
  const frontendPermissions = nodeAtomicPermissionsToFrontendPermissions(atomicPermissions);

  return {
    frontendPermissions,
    atomicPermissions,
    nodeFrontendPermissionFlags: NODE_FRONTEND_PERMISSIONS,
  };
}

export default parseNodePermissions;
