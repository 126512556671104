import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {GET} from '../verbs';

export default async function GET__hub_record_ID(authToken, perspectiveId, recordId) {
  return await GET(
    `/hub/${recordId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
  );
}
