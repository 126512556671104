import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import CenterpieceSpinner from 'components/chrome/CenterpieceSpinner';
import Typography from 'ui-library/components/Typography';
import ForwardIcon from '@material-ui/icons/ArrowForwardRounded';
import Grid from '@material-ui/core/Grid';
import Field from 'ui-library/components/Field';
import parseFieldValue from 'utils/parseFieldValue';
import getDateToAPIFormat from 'utils/getDateToAPIFormat';

import {
  fieldIsText,
  fieldIsLongformText,
  fieldIsNumber,
  fieldIsCurrency,
  fieldIsDate,
  fieldIsUrl,
  fieldIsPhoneNumber,
  fieldIsEmail,
  fieldIsEnum,
  fieldIsBoolean,
  fieldIsYear,
  fieldIsName,
  fieldIsZipCode,
} from 'utils/validator';

const Description = styled.div`
  margin-bottom: 32px;
  margin-top: 20px;
`;

const FIELD_VALIDATION = {
  'text': fieldIsText,
  'longform text': fieldIsLongformText,
  'number': fieldIsNumber,
  'currency': fieldIsCurrency,
  'date': fieldIsDate,
  'url': fieldIsUrl,
  'phone number': fieldIsPhoneNumber,
  'e-mail address': fieldIsEmail,
  'enum': fieldIsEnum,
  'boolean': fieldIsBoolean,
  'year': fieldIsYear,
  'name': fieldIsName,
  'zip code': fieldIsZipCode,
};

const PropertyDetailsStep = ({
  active,
  propertyNickname,
  fieldDefinitionsById,
  fieldsByFieldDefinitionId,
  fieldDefinitionIds,
  fieldDefinitionsAreInitialized,
  onSetFieldValue,
  onSetFieldErrorMessage,
  navigateToStep,
  saveProperty,
}) => {

  const fieldsHaveErrors = fieldDefinitionIds.map(fieldDefinitionId => {
    const {
      errorMessage,
    } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

    return errorMessage === '';
  }).includes(false);

  if (!active){
    return null;
  }

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Add Property
          </WizardBreadcrumb>
          <WizardBreadcrumb
            data-test-id="property-details-step-nickname-breadcrumb"
            onClick={() => navigateToStep(1)}>
            {propertyNickname}
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Details
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Some text that is an explanation of the current page.
        </WizardStepDescription>
      </WizardStepHead>

      <WizardContentColumn width="auto" margin="200px">
        <form onSubmit={(event) => {
          event.preventDefault();

          const fields = {};
          Object.keys(fieldsByFieldDefinitionId).forEach(fieldDefinitionId => {
            const {
              type,
              value,
            } = fieldsByFieldDefinitionId[fieldDefinitionId];

            if (![null, '', 'N/A'].includes(value)) {
              fields[fieldDefinitionId] = parseFieldValue({type, value, setError: (error) => {throw error;}});
            }
          });

          saveProperty(fields);
          navigateToStep(3);
        }}>
          <WizardStepContent>
            <Description>
              <Typography
                variant="lead">
              Now, let's add some&nbsp;
                <Typography
                  variant="lead"
                  fontWeight="bold">
                details&nbsp;
                </Typography>
                for&nbsp;
                <Typography
                  variant="lead"
                  fontWeight="bold">
                  {propertyNickname}&hellip;
                </Typography>
              </Typography>
              <div>
                <Typography
                  variant="x-small"
                  color="grey">
                You can use the TAB key to jump between fields.
                </Typography>
              </div>
            </Description>
            {!fieldDefinitionsAreInitialized && <CenterpieceSpinner/>}
            {
              fieldDefinitionsAreInitialized &&
              <Grid container spacing={8}>
                {
                  fieldDefinitionIds.map((fieldDefinitionId, index) => {
                    const {
                      value,
                      errorMessage,
                    } = fieldsByFieldDefinitionId[fieldDefinitionId] || {};

                    const {
                      type,
                      label,
                      description,
                      enumValues,
                    } = fieldDefinitionsById[fieldDefinitionId];

                    const options = type === 'enum' ? ['N/A', ...enumValues] : enumValues;

                    return (
                      <Grid
                        key={fieldDefinitionId}
                        container
                        item
                        spacing={2}
                        xs={12} sm={12} md={6} lg={4} xl={3}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {
                          type === 'date' ?
                            <Field
                              isEditable={true}
                              label={label}
                              type="date"
                              helperText={errorMessage ? errorMessage : description}
                              error={Boolean(errorMessage)}
                              required={false}
                              value={value === null ? value : new Date(value)}
                              onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                              }}
                              onChange={(value) => {
                                onSetFieldValue(
                                  fieldDefinitionId,
                                  value === null ? null : getDateToAPIFormat(value),
                                );
                                onSetFieldErrorMessage(
                                  fieldDefinitionId,
                                  FIELD_VALIDATION['date']({value: value}),
                                );
                              }}
                              onMouseDown={(ev) =>{
                                ev.stopPropagation();
                              }}
                              disabled={false}
                            />
                            :
                            <Field
                              inlineEdit={false}
                              isEditable={true}
                              autoFocus={index === 0}
                              type={type}
                              label={label}
                              helperText={Boolean(errorMessage) ? errorMessage : description}
                              error={Boolean(errorMessage)}
                              value={value}
                              options={options}
                              onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                              }}
                              onChange={(event) => {
                                onSetFieldValue(
                                  fieldDefinitionId,
                                  event.target.value,
                                );
                                onSetFieldErrorMessage(
                                  fieldDefinitionId,
                                  FIELD_VALIDATION[type]({value: event.target.value, options: options}),
                                );
                              }}
                              onMouseDown={(ev) =>{
                                ev.stopPropagation();
                              }}
                              disabled={false}
                              required={false}
                            />
                        }
                      </Grid>
                    );
                  })
                }
              </Grid>
            }
          </WizardStepContent>

          <WizardStepActions>
            <WizardSubmitWithEnterButton
              data-test-id="property-details-step-save-property-button"
              disabled={!fieldDefinitionsAreInitialized || fieldsHaveErrors}>
              {
                Object.values(fieldsByFieldDefinitionId).find(
                  field => ![null, '', 'N/A'].includes(field.value)) ? 'Save Property' : 'Skip and Save Property'
              } &nbsp;<ForwardIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

PropertyDetailsStep.propTypes = {
  active: PropTypes.bool,
  propertyNickname: PropTypes.string,
  fieldDefinitionsAreInitialized: PropTypes.bool,
  onSetFieldValue:PropTypes.func.isRequired,
  onSetFieldErrorMessage: PropTypes.func,
  navigateToStep: PropTypes.func,
  saveProperty: PropTypes.func.isRequired,
};

export default PropertyDetailsStep;
