import PATCH__notifications_ID from 'network-layer/endpoints/PATCH__notifications_ID';

function responseAdapter(response) {
  return response;
}

export default async function updateNotificationById(authToken, notificationId, params) {
  const response = await PATCH__notifications_ID(authToken, notificationId, params);

  return responseAdapter(response);
}
