import defaultConfig from '../config/defaultConfig';
import configWithAuthorization from '../config/configWithAuthorization';
import {DELETE} from '../verbs';

export default async function DELETE__notifications_ID(authToken, notificationId) {

  return await DELETE(
    `/notifications/${notificationId}`,
    configWithAuthorization(defaultConfig(), authToken),
  );
}
