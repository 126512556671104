import DELETE__notifications_devices from 'network-layer/endpoints/DELETE__notifications_devices';

function responseAdapter(response) {
  return response;
}

export default async function deleteNotificationDevice(authToken, devicePlayerId) {
  const response = await DELETE__notifications_devices(authToken, devicePlayerId);

  return responseAdapter(response);
}
