import GET__nodes_ID from 'network-layer/endpoints/GET__nodes_ID';

function responseAdapter(response) {
  // TODO: CHECK API RESPONSE
  return response;
}

export default async function genNodeByNodeId(params) {
  const {
    authToken,
    perspectiveId,
    nodeId,
    queryParams,
  } = params;

  const response = await GET__nodes_ID(authToken, perspectiveId, nodeId, queryParams);

  return responseAdapter(response);
}
