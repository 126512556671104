import defaultConfig from '../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../verbs';

export default async function PATCH__connections_ID(authToken, perspectiveId, connectionId, params) {
  return await PATCH(
    `/connections/${connectionId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
