import PATCH__connections_ID from 'network-layer/endpoints/PATCH__connections_ID';

function responseAdapter(response) {
  return response;
}

export default async function updateConnection(authToken, perspectiveId, connectionId, params) {
  const response = await PATCH__connections_ID(authToken, perspectiveId, connectionId, params);

  return responseAdapter(response);
}
