import React from 'react';
import styled from '@emotion/styled';

const Root = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AppBarActions = ({
  children,
}) => {
  return (
    <Root>
      {children}
    </Root>
  );
};

export default AppBarActions;
