export const appBarHeight = 70;

export const navigationClearance = 8;
export const navigationWidth = 120;
export const navigationFixedBehaviorVerticalBreakpoint = 950;

export const floatingListActionsClearance = 8;
export const floatingListActionsWidth = 57;

export const wizardProgressHeight = 12;
export const wizardNavigationHeight = 46;
export const wizardStepHeadHeight = 150;

export const contextualPanelNavigationHeight = 55;
export const contextualPanelNavigationGutter = 16;
export const contextualPanelHeaderHeight = 110;
export const contextualPanelActionsHeight = 55;

export const conversationPanelNavigationHeight = 55;
export const conversationPanelNavigationGutter = 24;
export const conversationPanelHeaderHeight = 168;
export const conversationPanelActionsHeight = 55;
export const conversationPanelNoticeHeight = 56;
export const conversationPanelGutter = 48;

export const gutter = 32;

export const singupWizardProgressHeight = 4;
export const signupWizardnavigationHeight = 50;
export const singupWizardHeaderHeight = 150;
export const singupWizardFooterHeight = 300;

export const signinHeaderHeight = 200;
export const signinFooterHeight = 180;
