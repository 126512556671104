import React from 'react';
import NestedBox from 'components/admin/NestedBox';
import ContentLine from 'components/admin/ContentLine';
import ContentLineText from 'components/admin/ContentLineText';
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@material-ui/icons/AddBox';
// import RemoveIcon from '@material-ui/icons/Remove';
// import ForbiddenIcon from '@material-ui/icons/Block';
// import RecordTypeIcon from '@material-ui/icons/Book';
import TypeIcon from '@material-ui/icons/DonutLarge';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const TypeLocationGraphParent = ({
  type,
  locationChildTypes,
}) => {
  const {
    name: typeName,
    aliases: typeAliases,
  } = type;

  return (
    <NestedBox>
      <ContentLine>
        <TypeIcon/>&nbsp;&nbsp;
        <ContentLineText>
          <Typography
            vartiant="body1">
            {typeName}
            {
              !!typeAliases.length &&
              <>
                &nbsp;&nbsp;
                <span
                  style={{
                    color: '#aaa',
                  }}>
                  ({typeAliases.join(', ')})
                </span>
              </>
            }
          </Typography>
        </ContentLineText>
      </ContentLine>

      {
        locationChildTypes.map(type => {
          const {
            id: typeId,
            name: typeName,
            aliases: typeAliases,
          } = type;
          return (
            <NestedBox
              key={typeId}>
              <ContentLine>
                <TypeIcon/>&nbsp;&nbsp;
                <ContentLineText>
                  <Typography
                    vartiant="body1">
                    {typeName}
                    {
                      !!typeAliases.length &&
                      <>
                        &nbsp;&nbsp;
                        <span
                          style={{
                            color: '#aaa',
                          }}>
                          ({typeAliases.join(', ')})
                        </span>
                      </>
                    }

                  </Typography>
                </ContentLineText>
              </ContentLine>
            </NestedBox>
          );
        })
      }
    </NestedBox>
  );
};

export default TypeLocationGraphParent;
