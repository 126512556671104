import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Grid from '@material-ui/core/Grid';

import {greySemi} from 'ui-library/color-palette';

import Button from 'ui-library/components/Button';
import Typography from 'ui-library/components/Typography';
import NodeLocationAssignment from 'ui-library/components/NodeLocationAssignment';
import Field from 'ui-library/components/Field';

import genTypeByTypeId from 'services/Types/genTypeByTypeId';

import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';

import getDateToAPIFormat from 'utils/getDateToAPIFormat';
import getDateWithTimezoneOffset from 'utils/getDateWithTimezoneOffset';

import {
  fieldIsText,
  fieldIsLongformText,
  fieldIsNumber,
  fieldIsCurrency,
  fieldIsDate,
  fieldIsUrl,
  fieldIsPhoneNumber,
  fieldIsEmail,
  fieldIsEnum,
  fieldIsBoolean,
  fieldIsYear,
  fieldIsName,
  fieldIsZipCode,
} from 'utils/validator';

const FIELD_VALIDATION = {
  'text': fieldIsText,
  'longform text': fieldIsLongformText,
  'number': fieldIsNumber,
  'currency': fieldIsCurrency,
  'date': fieldIsDate,
  'url': fieldIsUrl,
  'phone number': fieldIsPhoneNumber,
  'e-mail address': fieldIsEmail,
  'enum': fieldIsEnum,
  'boolean': fieldIsBoolean,
  'year': fieldIsYear,
  'name': fieldIsName,
  'zip code': fieldIsZipCode,
};

const PaddedContainer = styled.div`
  padding: 1px 35px;
`;

const NoAccessContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const NodeLocationContainer = styled.div`
  width: 100%;
  padding: 28px 35px;
  border-bottom: 1px solid ${greySemi};
`;

const NoFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
`;

const NoFieldsMessageRow = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const NodeDetails = ({
  nodeState,
  dispatchNodeAction,
  setError,
  saveNodeField,
  openEditMode,
}) => {
  const {
    authToken,
  } = React.useContext(UserContext);

  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = React.useContext(PerspectiveContext);

  const {
    node: {
      record: {
        name: recordName,
      },
      locationPath,
      fields: nodeFields,
      typeId,
      id: nodeId,
    },
    nodePermissions: {
      frontendPermissions: {
        view: nodeViewPermissions,
        edit: nodeEditPermissions,
      },
    },
  } = nodeState;

  // console.log('locationPath', locationPath);
  // console.log('recordName', recordName);

  const [tabState, setTabState] = React.useState('not loaded'); // 'not loaded', 'loading', 'loaded'

  const fields = nodeFields || {};

  const {
    fieldDefinitionsById,
    fieldsByFieldDefinitionId,
    fieldDefinitionIds,
  } = nodeState;

  React.useEffect(() => {
    const loadFieldDefinitions = async() => {

      const type = await genTypeByTypeId({authToken, perspectiveId: currentPerspectiveId, typeId: typeId});

      const {
        fieldDefinitions: fieldDefinitionsById,
      } = type || {};

      const fieldDefinitionIds = Object.keys(fieldDefinitionsById || {}).map(key => {
        return {
          id: key,
          index: fieldDefinitionsById[key].index,
        };
      }).sort((a, b) => {
        const {
          index: indexA,
        } = a;
        const {
          index: indexB,
        } = b;

        return indexA - indexB;
      }).map(obj => obj.id);

      const fieldsByFieldDefinitionId = {};
      fieldDefinitionIds.forEach(id => {
        if (fields[id] !== undefined) {
          fieldsByFieldDefinitionId[id] = {
            value: fields[id] !== undefined ? fields[id] : null,
            previousValue: fields[id] !== undefined ? fields[id] : null,
            isEditable: false,
            isSaving: false,
            isFocused: false,
            isPristine: true,
            inlineEditMode: true,
            errorMessage: '',
            type: fieldDefinitionsById[id].type,
          };
        } else {
          fieldsByFieldDefinitionId[id] = {
            value: fieldDefinitionsById[id].type === 'date' ? null : '',
            previousValue: fieldDefinitionsById[id].type === 'date' ? null : '',
            isEditable: false,
            isSaving: false,
            isFocused: false,
            isPristine: true,
            inlineEditMode: false,
            errorMessage: '',
            type: fieldDefinitionsById[id].type,
          };
        }
      });

      dispatchNodeAction({
        type: 'HYDRATE_FIELDS',
        payload: {
          fieldDefinitionsById,
          fieldsByFieldDefinitionId,
          fieldDefinitionIds,
        },
      });

      setTabState('loaded');
    };

    if (tabState === 'not loaded') {
      loadFieldDefinitions();
    }
  }, [authToken, currentPerspectiveId, dispatchNodeAction, fieldDefinitionIds, fieldDefinitionsById, fields, tabState, typeId]);

  const revertField = ({
    previousValue,
    fieldDefinitionId,
  }) => {
    dispatchNodeAction({
      type: 'SET_FIELD_VALUE',
      payload: {
        fieldDefinitionId,
        value: previousValue,
      },
    });
    dispatchNodeAction({
      type: 'SET_FIELD_ERROR_MESSAGE',
      payload: {
        fieldDefinitionId,
        errorMessage: '',
      },
    });
    dispatchNodeAction({
      type: 'SET_FIELD_IS_EDITABLE',
      payload: {
        fieldDefinitionId,
        isEditable: false,
      },
    });
  };

  if (tabState !== 'loaded') {
    return (
      <NoAccessContainer>
        <Typography
          variant="h5"
          color="grey"
          fontWeight="bold"
        >
          Loading ...
        </Typography>
      </NoAccessContainer>
    );
  }

  if (!nodeViewPermissions) {
    return (
      <NoAccessContainer>
        <Typography
          variant="h5"
          color="grey"
          fontWeight="bold"
        >
          You don’t have permission to view this item’s information. Please contact the relevant Connection to update your permissions if necessary.
        </Typography>
      </NoAccessContainer>
    );
  }

  return (
    <>
      <NodeLocationContainer>
        <NodeLocationAssignment
          assignedTo={recordName ? recordName : ''}
          locationPath={locationPath ? locationPath.length ? locationPath.join(' / ') : 'No Location' : 'No Location'}
        />
      </NodeLocationContainer>
      <PaddedContainer>
        <Grid
          container
          spacing={3}
        >
          {
            Object.keys(fields).length === 0 && fieldDefinitionIds.length !== 0 &&
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{
                height: '100%',
                flexGrow: 1,
                width: '100%',
              }}
            >
              <NoFieldsContainer>
                <NoFieldsMessageRow>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    color="grey-semi"
                  >
                  This item has no information.
                  </Typography>
                </NoFieldsMessageRow>
                <NoFieldsMessageRow>
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    color="grey-semi"
                  >
                  Click
                    <Button
                      variant="text"
                      textTransform="none"
                      onClick={openEditMode}
                    >
                      <Typography
                        variant="h4"
                        fontWeight="bold"
                        color="primary-light"
                      >
                        Edit
                      </Typography>
                    </Button>
                   to add more.
                  </Typography>
                </NoFieldsMessageRow>
              </NoFieldsContainer>

            </Grid>


          }
          {
            fieldDefinitionIds.map(fieldDefinitionId => {
              if (!fieldsByFieldDefinitionId[fieldDefinitionId] || !fieldsByFieldDefinitionId[fieldDefinitionId].inlineEditMode) {
                return null;
              }

              const {
                value,
                previousValue,
                isEditable,
                isSaving,
                errorMessage,
              } = fieldsByFieldDefinitionId[fieldDefinitionId];

              const {
                type,
                label,
                description,
                enumValues,
              } = fieldDefinitionsById[fieldDefinitionId];

              const options = type === 'enum' ? ['N/A', ...enumValues] : enumValues;

              return (
                <Grid
                  key={fieldDefinitionId}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{
                    cursor: 'pointer',
                    height: '92px',
                    flexGrow: 1,
                  }}
                >
                  {
                    type === 'date' ?
                      <Field
                        inlineEdit={true}
                        autoFocus={true}
                        label={label}
                        type="date"
                        helperText={Boolean(errorMessage) ? errorMessage : description}
                        error={Boolean(errorMessage)}
                        isEditable={nodeEditPermissions && isEditable}
                        value={value === null ? value : getDateWithTimezoneOffset(value)}
                        onClose={() => {
                          saveNodeField({
                            value,
                            previousValue,
                            id: nodeId,
                            fieldDefinitionId,
                            type,
                            errorMessage,
                          });
                        }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          if (!isEditable && !isSaving) {
                            dispatchNodeAction({
                              type: 'SET_FIELD_IS_EDITABLE',
                              payload: {
                                fieldDefinitionId,
                                isEditable: true,
                              },
                            });
                          }
                        }}
                        onFocus={() => {
                          dispatchNodeAction({
                            type: 'SET_FIELD_IS_FOCUSED',
                            payload: {
                              fieldDefinitionId,
                              isFocused: true,
                            },
                          });
                        }}
                        onKeyPress={(ev) => {
                          if (ev.key === 'Enter') {
                            dispatchNodeAction({
                              type: 'SET_FIELD_ERROR_MESSAGE',
                              payload: {
                                fieldDefinitionId,
                                errorMessage: FIELD_VALIDATION[type]({value: ev.target.value, options: options}),
                              },
                            });
                            dispatchNodeAction({
                              type: 'SET_FIELD_VALUE',
                              payload: {
                                fieldDefinitionId,
                                value: value === null ? null : value,
                              },
                            });
                            dispatchNodeAction({
                              type: 'SET_FIELD_IS_EDITABLE',
                              payload: {
                                fieldDefinitionId,
                                isEditable: false,
                              },
                            });
                            saveNodeField({
                              value,
                              previousValue,
                              id: nodeId,
                              fieldDefinitionId,
                              type,
                              errorMessage,
                            });
                          }
                        }}
                        onChange={(value) => {
                          dispatchNodeAction({
                            type: 'SET_FIELD_ERROR_MESSAGE',
                            payload: {
                              fieldDefinitionId,
                              errorMessage: FIELD_VALIDATION[type]({value: value, options: options}),
                            },
                          });
                          dispatchNodeAction({
                            type: 'SET_FIELD_VALUE',
                            payload: {
                              fieldDefinitionId,
                              value: value !== null ? getDateToAPIFormat(value) : null,
                            },
                          });
                        }}
                        onMouseDown={(ev) =>{
                          ev.stopPropagation();
                        }}
                        disabled={false}
                        isSaving={isSaving}
                        required={false}
                        revertField={() => {
                          revertField({
                            previousValue,
                            fieldDefinitionId,
                          });
                        }}
                        saveField={() => {
                          saveNodeField({
                            value,
                            previousValue,
                            id: nodeId,
                            fieldDefinitionId,
                            type,
                            errorMessage,
                          });
                        }}
                      />
                      :
                      <Field
                        inlineEdit={true}
                        autoFocus={true}
                        type={type}
                        label={label}
                        helperText={Boolean(errorMessage) ? errorMessage : description}
                        error={Boolean(errorMessage)}
                        isEditable={nodeEditPermissions && isEditable}
                        value={value}
                        options={options}
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          if (!isEditable && !isSaving) {
                            dispatchNodeAction({
                              type: 'SET_FIELD_IS_EDITABLE',
                              payload: {
                                fieldDefinitionId,
                                isEditable: true,
                              },
                            });
                          }
                        }}
                        onFocus={() => {
                          dispatchNodeAction({
                            type: 'SET_FIELD_IS_FOCUSED',
                            payload: {
                              fieldDefinitionId,
                              isFocused: true,
                            },
                          });
                        }}
                        onKeyPress={(ev) => {
                          if (Boolean(errorMessage)) {
                            return;
                          }
                          if (ev.key === 'Enter') {
                            dispatchNodeAction({
                              type: 'SET_FIELD_VALUE',
                              payload: {
                                fieldDefinitionId,
                                value: ev.target.value,
                              },
                            });
                            dispatchNodeAction({
                              type: 'SET_FIELD_IS_EDITABLE',
                              payload: {
                                fieldDefinitionId,
                                isEditable: false,
                              },
                            });
                            saveNodeField({
                              value,
                              previousValue,
                              id: nodeId,
                              fieldDefinitionId,
                              type,
                              errorMessage,
                            });
                          }
                        }}
                        onChange={(ev) => {
                          dispatchNodeAction({
                            type: 'SET_FIELD_VALUE',
                            payload: {
                              fieldDefinitionId,
                              value: ev.target.value,
                            },
                          });
                          dispatchNodeAction({
                            type: 'SET_FIELD_ERROR_MESSAGE',
                            payload: {
                              fieldDefinitionId,
                              errorMessage: FIELD_VALIDATION[type]({value: ev.target.value, options: options}),
                            },
                          });
                        }}
                        onMouseDown={(ev) =>{
                          ev.stopPropagation();
                        }}
                        disabled={false}
                        isSaving={isSaving}
                        required={false}
                        revertField={() => {
                          revertField({
                            previousValue,
                            fieldDefinitionId,
                          });
                        }}
                        saveField={() => {
                          saveNodeField({
                            value,
                            previousValue,
                            id: nodeId,
                            fieldDefinitionId,
                            type,
                            errorMessage,
                          });
                        }}
                      />
                  }
                </Grid>
              );
            })
          }
        </Grid>
      </PaddedContainer>
    </>
  );
};

NodeDetails.propTypes = {
  expandedNodeId: PropTypes.number,
};

export default NodeDetails;
