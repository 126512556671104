import React from 'react';
import PropTypes from 'prop-types';
import Typography from 'ui-library/components/Typography';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';
import {Link as RRLink} from 'react-router-dom';

const useStyles = makeStyles({
  root:{
    textDecoration: theme.link.disabledDecoration,
    color: props => props.disabled ? theme.link.disabledColor : theme.palette[props.color],
    userSelect: theme.link.userSelect,
    fontSize: props => theme.typography[props.variant].fontSize,
    lineHeight: props => theme.typography[props.variant].lineHeight,
    fontWeight: props => theme.typography.fontWeight[props.fontWeight],
    cursor: props => props.disabled ? theme.link.disabledCursor : theme.link.activeCursor,
    '&:hover': {
      textDecoration: props => props.disabled ? theme.link.disabledDecoration : theme.link.activeDecoration,
      color: props => props.hoverColor,
    },
  },
});

const Link = ({
  value,
  label,
  color,
  hoverColor,
  variant,
  fontWeight,
  disabled,
  onClick,
  children,
  ...otherProps
}) => {

  const typographyVariant = variant ? variant : 'body';

  const classes = useStyles({
    color: color ? color : 'primary',
    hoverColor: hoverColor ? hoverColor : 'primary',
    variant: typographyVariant,
    fontWeight: fontWeight ? fontWeight : 'normal',
    disabled,
  });

  if (value === '#') {
    return (
      <a
        href={value}
        rel="noopener noreferrer"
        className={classes.root}
        onClick={(ev) => {
          if (disabled) {
            ev.preventDefault();
          }
          typeof onClick === 'function' && onClick();
        }}
        {...otherProps}
      >
        <Typography
          variant={typographyVariant}
          fontWeight={fontWeight}
          fontFamily="primary"
        >
          {label ? label : children}
        </Typography>
      </a>
    );
  }

  if (
    !value.startsWith('www.') &&
    value.indexOf('://') === -1 && value.indexOf('tel:') === -1 && value.indexOf('mailto:') === -1
  ) {
    return (
      <RRLink
        onClick={(ev) => {
          if (disabled) {
            ev.preventDefault();
            return;
          }
          typeof onClick === 'function' && onClick();
        }}
        className={classes.root}
        to={value}
        {...otherProps}
      >
        {label}
      </RRLink>
    );
  }

  return (
    <a
      href={value.startsWith('www.') ? `https://${value}` : value}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.root}
      onClick={(ev) => {
        if (disabled) {
          ev.preventDefault();
        }
      }}
      {...otherProps}
    >
      <Typography
        variant={typographyVariant}
        fontWeight={fontWeight}
        fontFamily="primary"
      >
        {label ? label : children}
      </Typography>
    </a>
  );
};

Link.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default Link;
