import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {PATCH} from '../../verbs';

export default async function PATCH__admin_fieldWatchers_FIELDWATCHERID(authToken, perspectiveId, params) {
  const {
    fieldWatcherId,
    ...otherParams
  } = params;

  return await PATCH(
    `/admin/field-watchers/${fieldWatcherId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...otherParams,
    },
  );
}
