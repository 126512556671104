import DELETE__nodeLinks_ID from 'network-layer/endpoints/DELETE__nodeLinks_ID';

function responseAdapter(response) {
  return response;
}

export default async function deleteNode(authToken, perspectiveId, nodeLinkId) {
  const response = await DELETE__nodeLinks_ID(authToken, perspectiveId, nodeLinkId);

  return responseAdapter(response);
}
