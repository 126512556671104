import isInt from 'validator/lib/isInt';

export default function fieldIsNumber(
  params,
  errorMessage = 'invalid number',
) {
  const {
    value,
  } = params;

  if (value === '' || (isInt(value) && parseInt(value) <= 9007199254740991)) {
    return '';
  }
  return errorMessage;
}
