function selectRecordTypes(DM) {
  const {
    recordTypesById,
    recordTypeIds,
  } = DM;

  return (
    recordTypeIds
      .map(recordTypeId => recordTypesById[recordTypeId])
  );
}

export default selectRecordTypes;
