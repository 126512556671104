import defaultConfig from '../../config/defaultConfig';
import configWithAuthorizationAndPerspective from '../../config/configWithAuthorizationAndPerspective';
import {DELETE} from '../../verbs';

export default async function DELETE__admin_intelligentDataFramework_propertyConfigurationHints_PROPERTYCONFIGURATIONHINTID(authToken, perspectiveId, params) {
  const {
    propertyConfigurationHintId,
  } = params;

  return await DELETE(
    `/admin/intelligent-data-framework/property-configuration-hints/${propertyConfigurationHintId}`,
    configWithAuthorizationAndPerspective(defaultConfig(), authToken, perspectiveId),
    {
      ...params,
    },
  );
}
