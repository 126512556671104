import React from 'react';
import UserContext from 'contexts/User';
import PerspectiveContext from 'contexts/Perspective';
import Wizard from 'components/chrome/Wizard';
import WizardProgress from 'components/chrome/WizardProgress';
import WizardNavigation from 'components/chrome/WizardNavigation';
import WizardBackButton from 'components/chrome/WizardBackButton';
import WizardCloseButton from 'components/chrome/WizardCloseButton';
import PropertyNicknameStep from './steps/PropertyNicknameStep';
import PropertyDetailsStep from './steps/PropertyDetailsStep';
import PropertySaveStep from './steps/PropertySaveStep';
import PropertyConfigurationStep from './steps/PropertyConfigurationStep';

import {
  initPropertyState,
  propertyReducer,
} from 'reducers/AddProperty';

import genPropertyTypesService from 'services/Properties/genPropertyTypes';
import savePropertyService from 'services/Properties/saveProperty';
import genRecordCandidates from 'services/Types/genRecordCandidates';
import genTypeByTypeId from 'services/Types/genTypeByTypeId';
import genPropertyConfigurationHint from 'services/IntelligentDataFramework/genPropertyConfigurationHint';

const AddPropertyWizard = ({
  onClose,
  onSwitchWizard,
}) => {

  const TOTAL_STEPS = 4;
  const user = React.useContext(UserContext);
  const {
    authToken,
  } = user;

  const perspective = React.useContext(PerspectiveContext);
  const {
    currentPerspective: {
      id: currentPerspectiveId,
    },
  } = perspective;


  const [step, setStep] = React.useState(1);
  const [propertyState, dispatchPropertyAction] = React.useReducer(
    propertyReducer,
    null,
    initPropertyState,
  );

  // 'notSaved' | 'saving' | 'saved'
  const [propertySaveState, setPropertySavedState] = React.useState('notSaved');
  const [fieldDefinitionsAreInitialized, setFieldDefinitionsAreInitialized] = React.useState(false);

  const [fatalError, setFatalError] = React.useState(null);
  const [error, setError] = React.useState(null);

  if (error) {
    const {
      name,
    } = error;
    switch (name) {
      case 'AuthorizationError':
        user.setAuthToken(null);
        user.setAuthenticationStatus('not-authenticated');
        localStorage.removeItem('authToken');
        break;
      default:
        setFatalError(error);
    }
  }

  if (fatalError) {
    throw fatalError;
  }

  const {
    typeId,
    name,
    recordId,
    fieldDefinitionsById,
    fieldsByFieldDefinitionId,
    fieldDefinitionIds,
    successfullySavedNode,
    propertyConfigurationHint,
  } = propertyState;

  React.useEffect(() => {
    async function initPropertyType() {
      const propertyTypes = await genPropertyTypesService(authToken, currentPerspectiveId);
      // we currently have a single type of property in the system
      const propertyType = propertyTypes[0];
      const {
        id: typeId,
      } = propertyType;

      // Properties should always be assigned to a Real Estate Record
      // that is returned by the API call for retrieving record candidates.
      // This does not need user input (i.e. there’s no “assign this property to a record“ step).
      const recordCandidates = await genRecordCandidates(authToken, currentPerspectiveId, typeId);
      const record = recordCandidates[0];
      dispatchPropertyAction({
        type: 'SET_RECORD',
        payload: record,
      });

      dispatchPropertyAction({
        type: 'SET_TYPE',
        payload: typeId,
      });
      dispatchPropertyAction({
        type: 'SET_NAME',
        payload: '',
      });
    }

    initPropertyType();
  }, [authToken, currentPerspectiveId]);

  React.useEffect(() => {
    const loadFieldDefinitions = async() => {
      try {
        setFieldDefinitionsAreInitialized(false);

        const typeDefinition = await genTypeByTypeId({
          authToken,
          perspectiveId: currentPerspectiveId,
          typeId: typeId,
        });
        const {
          fieldDefinitions: fieldDefinitionsById,
        } = typeDefinition || {};

        const fieldDefinitionIds = Object.keys(fieldDefinitionsById || {}).map(key => {
          return {
            id: key,
            index: fieldDefinitionsById[key].index,
          };
        }).sort((a, b) => {
          const {
            index: indexA,
          } = a;
          const {
            index: indexB,
          } = b;

          return indexA - indexB;
        }).map(obj => obj.id);

        const fieldsByFieldDefinitionId = {};
        fieldDefinitionIds.forEach(id=>{
          fieldsByFieldDefinitionId[id] = {
            value: fieldDefinitionsById[id].type === 'date' ? null : '',
            errorMessage: '',
            type: fieldDefinitionsById[id].type,
          };
        });

        dispatchPropertyAction({
          type: 'HYDRATE_FIELDS',
          payload: {
            fieldDefinitionsById,
            fieldsByFieldDefinitionId,
            fieldDefinitionIds,
          },
        });

        setFieldDefinitionsAreInitialized(true);
      } catch(error){
        setError(error);
      }
    };
    if (typeId) {
      loadFieldDefinitions();
    }
  }, [authToken, currentPerspectiveId, typeId]);

  async function saveProperty(fields) {
    try {
      setPropertySavedState('saving');

      const params = fields ? {
        name,
        typeId,
        recordId,
        fields,
      } : {
        name,
        typeId,
        recordId,
      };

      const successfullySavedNode = await savePropertyService(
        authToken,
        currentPerspectiveId,
        params,
      );

      dispatchPropertyAction({
        type: 'SET_SUCCESSFULLY_SAVED_NODE',
        payload: successfullySavedNode,
      });

      const propertyConfigurationHint = await genPropertyConfigurationHint(
        authToken,
        currentPerspectiveId,
        {
          targetTypeId: typeId,
        },
      );

      dispatchPropertyAction({
        type: 'SET_CONFIGURATION_HINT',
        payload: propertyConfigurationHint,
      });

      setPropertySavedState('saved');
    } catch (error) {
      setError(error);
    }
  }

  function hasState() {
    if (step === TOTAL_STEPS - 1 && propertySaveState === 'saved') {
      return false;
    }
    return step > 1;
  }

  function closeConfirmed() {
    if (!hasState()) {
      return true;
    }
    const message = step !== TOTAL_STEPS ?
      `Are you sure you want to quit the wizard? Your ${propertyState.typeName || 'property'} will not be saved if you quit now.` :
      'Are you sure you want to quit the wizard? Your configuration will not be saved if you quit now.';
    if (hasState() && window.confirm(message)) {
      return true;
    }
    return false;
  }

  function closeWizard() {
    if (!closeConfirmed()) {
      return;
    }
    onClose();
  }

  function closeWizardWithPrepend() {
    onClose();
  }

  return (
    <Wizard onClose={closeWizard}>
      <WizardProgress
        isVisible={true}
        progress={step}
        total={TOTAL_STEPS}
      />
      <WizardNavigation>
        <WizardBackButton
          data-test-id="add-property-wizard-back-button"
          isVisible={step > 0 && propertySaveState === 'notSaved'}
          onClick={() => {
            step > 1 && setStep(step - 1);
            step === 1 && onSwitchWizard();
          }}
        />
        <WizardCloseButton
          data-test-id="add-property-wizard-close-button"
          isVisible={step !== TOTAL_STEPS - 1 && propertySaveState !== 'saving'}
          onClick={closeWizard}
        />
      </WizardNavigation>

      <PropertyNicknameStep
        active={step === 1}
        propertyNickname={name}
        onSetPropertyNickname={propertyName => {
          dispatchPropertyAction({
            type: 'SET_NAME',
            payload: propertyName,
          });
        }}
        navigateToStep={setStep}
      />

      <PropertyDetailsStep
        active={step === 2}
        propertyNickname={name}
        fieldDefinitionsById={fieldDefinitionsById}
        fieldsByFieldDefinitionId={fieldsByFieldDefinitionId}
        fieldDefinitionIds={fieldDefinitionIds}
        fieldDefinitionsAreInitialized={fieldDefinitionsAreInitialized}
        onSetFieldValue={(fieldDefinitionId, fieldValue) => {
          dispatchPropertyAction({
            type: 'SET_FIELD_VALUE',
            payload: {
              fieldDefinitionId,
              value: fieldValue,
            },
          });
        }}
        onSetFieldErrorMessage={(fieldDefinitionId, errorMessage) => {
          dispatchPropertyAction({
            type: 'SET_ERROR_MESSAGE',
            payload: {
              fieldDefinitionId,
              errorMessage: errorMessage,
            },
          });
        }}
        navigateToStep={setStep}
        saveProperty={saveProperty}
      />

      <PropertySaveStep
        active={step === 3}
        propertyNickname={name}
        navigateToStep={setStep}
        propertySaveState={propertySaveState}
        closeWizardWithPrepend={closeWizardWithPrepend}
        propertyConfigurationHint={propertyConfigurationHint}
      />

      <PropertyConfigurationStep
        active={step === 4}
        propertyNickname={name}
        navigateToStep={setStep}
        closeWizardWithPrepend={closeWizardWithPrepend}
        propertyConfigurationHint={propertyConfigurationHint}
        authToken={authToken}
        perspectiveId={currentPerspectiveId}
        closeWizard={onClose}
        successfullySavedNode={successfullySavedNode}
      />

    </Wizard>
  );
};

export default AddPropertyWizard;
