import React from 'react';
import Button from 'ui-library/components/Button';
import TextField from 'ui-library/components/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const EditVirtualLocationTreeNodeNameDialog = ({
  open,
  onClose,
  name,
  typeName,
  onChangeName,
  onSave,
}) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}>
      <form
        onSubmit={event => {
          event.preventDefault();
          onSave();
        }}>
        <DialogTitle>
          Give this {typeName.toLocaleLowerCase()} a name
        </DialogTitle>
        <DialogContent>
          <TextField
            data-test-id="configure-property-rename-node-name-field"
            fullWidth
            autoFocus
            value={name}
            onChange={event => {
              onChangeName(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-test-id="configure-property-rename-node-dialog-cancel-button"
            variant="text"
            onClick={onClose}>
            cancel
          </Button>
          <Button
            data-test-id="configure-property-rename-node-dialog-save-button"
            disabled={!name}
            type="submit"
            variant="primary">
            save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditVirtualLocationTreeNodeNameDialog;
