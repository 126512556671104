export default function fieldIsPhoneNumber(
  params,
  errorMessage = 'invalid phone number',
) {
  const {
    value,
  } = params;

  const phoneNumberFormat = new RegExp(/^[0-9()*\- #]{3,}$/);

  if (value === '' || phoneNumberFormat.test(value)) {
    return '';
  }
  return errorMessage;
}
