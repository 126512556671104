import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const useStyles = makeStyles({
  root: {
    display: theme.notificationInfo.display,
    flexDirection: theme.notificationInfo.flexDirection,
    padding: theme.notificationInfo.padding,
    flex: theme.notificationInfo.flex,
    minWidth: theme.notificationInfo.minWidth,
    userSelect: theme.notificationInfo.userSelect,
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
});

const NotificationInfo = ({
  children,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

NotificationInfo.propTypes = {
  children: PropTypes.node,
};

export default NotificationInfo;
