import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import WizardStep from 'components/chrome/WizardStep';
import WizardStepHead from 'components/chrome/WizardStepHead';
import WizardStepTitle from 'components/chrome/WizardStepTitle';
import WizardBreadcrumb from 'components/chrome/WizardBreadcrumb';
import WizardStepDescription from 'components/chrome/WizardStepDescription';
import WizardContentColumn from 'components/chrome/WizardContentColumn';
import WizardStepContent from 'components/chrome/WizardStepContent';
import WizardStepActions from 'components/chrome/WizardStepActions';
import WizardSubmitWithEnterButton from 'components/chrome/WizardSubmitWithEnterButton';
import Typography from 'ui-library/components/Typography';
import Chip from 'ui-library/components/Chip';
import ShareIcon from '@material-ui/icons/ShareRounded';

import recordFrontendPermissionLabel from 'utils/permissions/recordFrontendPermissionLabel';

const RecordsContainer = styled.div`
  display: flex;
  margin: 24px 0;
`;

const PermissionsContainer = styled.div`
`;

const ShareRecordSummaryStep = ({
  active,
  connection,
  permissions,
  recordName,
  onShareData,
  shareStatus,
  navigateToStep,
}) => {
  if (!active) {
    return null;
  }

  if (connection === null) {
    return null;
  }

  const {
    peerName: partnerName,
  } = connection;

  return (
    <WizardStep>
      <WizardStepHead>
        <WizardStepTitle>
          <WizardBreadcrumb isFirst>
            Share Item
          </WizardBreadcrumb>
          <WizardBreadcrumb onClick={() => navigateToStep(1)}>
            {partnerName}
          </WizardBreadcrumb>
          <WizardBreadcrumb onClick={() => navigateToStep(2)}>
            Permissions
          </WizardBreadcrumb>
          <WizardBreadcrumb isLast>
            Summary
          </WizardBreadcrumb>
        </WizardStepTitle>
        <WizardStepDescription>
          Carefully review the summary of what you are about to share and make sure everything is correct before continuing.
        </WizardStepDescription>
      </WizardStepHead>
      <WizardContentColumn>
        <form onSubmit={(event) => {
          event.preventDefault();
          onShareData();
        }}>
          <WizardStepContent>
            <Typography variant="lead">
              You are about to share data about the following record with&nbsp;
              <Typography variant="lead" fontWeight="bold">
                {partnerName}
              </Typography>
              :
            </Typography>
            <RecordsContainer>
              <Typography variant="h5" fontWeight="bold">
                {recordName}
              </Typography>
            </RecordsContainer>
            <Typography variant="lead">
              This Connection will be able to:
            </Typography>
            <PermissionsContainer>
              {
                Object.keys(permissions).map((permission, index) => {
                  if (permissions[permission]) {
                    const label = recordFrontendPermissionLabel(permission);
                    return <Chip key={index} label={label}/>;
                  } else {
                    return null;
                  }
                })
              }
            </PermissionsContainer>
          </WizardStepContent>
          <WizardStepActions>
            <WizardSubmitWithEnterButton
              autoFocus={true}
              data-test-id="wizard-share-record-button"
              variant="secondary"
              disabled={shareStatus === 'sharing'}>
              Share record &nbsp;<ShareIcon/>
            </WizardSubmitWithEnterButton>
          </WizardStepActions>
        </form>
      </WizardContentColumn>
    </WizardStep>
  );
};

ShareRecordSummaryStep.propTypes = {
  active: PropTypes.bool,
  onSetConnection: PropTypes.func,
};

export default ShareRecordSummaryStep;
