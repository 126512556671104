import GET__nodeLinks_candidates from 'network-layer/endpoints/GET__nodeLinks_candidates';

function responseAdapter(response) {
  return response;
}

export default async function genNodeLinkCandidates(authToken, perspectiveId, nodeId) {
  const response = await GET__nodeLinks_candidates(authToken, perspectiveId, nodeId);

  return responseAdapter(response);
}
