import React from 'react';
import styled from '@emotion/styled';
import {
  contextualPanelActionsHeight,
  gutter,
} from 'constants/layout';
import {
  greyLightest,
} from 'ui-library/color-palette';

const Root = styled.div`
  position: fixed;
  bottom: 0;
  right: ${props => props.expanded ? 0 : '-100vw'};
  display: flex;
  align-items: center;
  width: 85vw;
  height: ${props => props.multiline ? 'auto' : `${contextualPanelActionsHeight}px`};
  padding: 0 ${gutter}px;
  background-color: ${greyLightest};
  box-shadow: 0 0 4px rgba(0, 0, 0, .2), 0 0 2px rgba(0, 0, 0, .14), 0 0 1px rgba(0, 0, 0, .1);
  z-index: 2;
  transition: right .3s ease;
  @media (max-width: 1100px) {
    width: 100vw;
  }
  @media (min-width: 1200px) {
    width: 75vw;
  }
  @media (min-width: 1600px) {
    width: 60vw;
  }
  @media (min-width: 1920px) {
    width: 50vw;
  }
`;

const ContextualPanelActions = ({
  expanded,
  multiline,
  children,
}) => {
  return (
    <Root
      expanded={expanded}
      multiline={multiline}
    >
      {children}
    </Root>
  );
};

export default ContextualPanelActions;
