const getFileExtension = (fileName) => {
  const segments = fileName.split('.');
  const length = segments.length;

  if (length === 1 || segments[length - 1].replace(/\s/g, '') === '') {
    return '';
  }

  return segments[length - 1].toLowerCase();
};

export default getFileExtension;
