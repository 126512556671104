import React from 'react';
import styled from '@emotion/styled';
import {
  navigationWidth,
  navigationClearance,
} from 'constants/layout';
import {
  primaryDarkest,
} from 'ui-library/color-palette';
import JulesNavigationLogo from 'images/jules-navigation-logo.png';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  width: ${navigationWidth}px;
  min-height: 100%;
  padding: ${navigationClearance}px;
  @media (max-height) {
    position: absolute;
  }
  z-index: 3;
`;

const NavigationRail = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: ${navigationWidth}px;
  border-radius: 20px;
  background-color: ${primaryDarkest};
`;

const NavigationLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
`;

const NavigationButtonsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Navigation = ({
  children,
}) => {
  return (
    <Root>
      <NavigationRail>
        <NavigationLogoContainer>
          <img src={JulesNavigationLogo} alt="Jules" />
        </NavigationLogoContainer>
        <NavigationButtonsContainer>
          {children}
        </NavigationButtonsContainer>
      </NavigationRail>
    </Root>
  );
};

export default Navigation;
