function deriveNextVirtualLocationTreeNodeId(configurationState) {
  const {
    virtualLocationTreeNodeIds,
  } = configurationState;

  let nextVirtualLocationTreeNodeId = 0;

  while(virtualLocationTreeNodeIds.includes(nextVirtualLocationTreeNodeId)) {
    nextVirtualLocationTreeNodeId++;
  }

  return nextVirtualLocationTreeNodeId;
}

export default deriveNextVirtualLocationTreeNodeId;
