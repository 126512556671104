import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui-library/components/Button';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import ShareIcon from '@material-ui/icons/ShareRounded';
import {makeStyles} from '@material-ui/styles';
import theme from 'ui-library/theme';

const ALLOWED_VARIANTS = [
  'delete',
  'share',
];

const variantIcon = {
  delete: DeleteIcon,
  share: ShareIcon,
};

const variantLabel = {
  delete: 'Delete',
  share: 'Share',
};

const useStyles = makeStyles({
  root: {
    display: theme.batchActionButton.root.display,
    height: theme.batchActionButton.root.height,
    width: theme.batchActionButton.root.width,
    '& .MuiButton-root': {
      transition: 'none',
    },
    '& :hover:not(.Mui-disabled)': {
      '& $content': {
        color: props => theme.batchActionButton[props.variant].hoverLabelColor,
      },
      '& $content $icon': {
        color: props => theme.batchActionButton[props.variant].hoverIconColor,
      },
    },
    '& :disabled': {
      backgroundColor: theme.batchActionButton.disabled.backgroundColor,
      opacity: theme.batchActionButton.disabled.opacity,
      '& $content': {
        color: props => theme.batchActionButton[props.variant].disabled.labelColor,
      },
      '& $content $icon': {
        color: props => theme.batchActionButton[props.variant].disabled.iconColor,
      },
    },
  },
  content: {
    display: theme.batchActionButton.content.display,
    flexDirection: theme.batchActionButton.content.flexDirection,
    alignItems: theme.batchActionButton.content.alignItems,
    fontSize: theme.batchActionButton.content.fontSize,
    fontWeight: theme.batchActionButton.content.fontWeight,
    color: props => theme.batchActionButton[props.variant].labelColor,
  },
  icon: {
    color: props => theme.batchActionButton[props.variant].iconColor,
  },
});

const BatchActionButton = ({
  variant,
  disabled,
  onClick,
  'data-test-id': dataTestId,
}) => {

  const classes = useStyles({
    variant: ALLOWED_VARIANTS.indexOf(variant) > -1 ? variant : 'delete',
  });

  const Icon = variantIcon[variant];
  const label = variantLabel[variant];

  return (
    <div
      className={classes.root}
      onClick={() => {
        if (disabled) {
          return;
        }
        if (typeof onClick !== 'function') {
          return;
        }
        onClick();
      }}
      data-test-id={dataTestId}
    >
      <Button
        variant="custom"
        textTransform='none'
        customBgColor="white"
        customHoverBgColor={variant === 'delete' ? 'error' : 'primary'}
        customTextColor={variant === 'delete' ? 'error' : 'primary'}
        customHoverTextColor="white"
        customActiveBgColor={variant === 'delete' ? 'error' : 'primary'}
        customActiveTextColor="black"
        disabled={disabled}
      >
        <div className={classes.content}>
          <Icon className={classes.icon}/>
          {label}
        </div>
      </Button>
    </div>
  );
};

BatchActionButton.propTypes = {
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BatchActionButton;
