import React from 'react';
import ToggleSwitch from 'ui-library/components/ToggleSwitch';
import Typography from 'ui-library/components/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

const PermissionSwitches = ({
  disabled,
  permissions,
  getPermissionReadableFormat,
  editable,
  onTogglePermission,
  disabledPermissions,
}) => {
  return (
    <>
      {
        Object.keys(permissions).map((permission, index) => {
          const label = getPermissionReadableFormat(permission);
          const isPermissionDisabled = () => {
            let result = false;

            if (disabledPermissions[permission]) {
              disabledPermissions[permission].forEach(inferringPermission => {
                if (permissions[inferringPermission] === true) {
                  result = true;
                }
              });
            }

            return result;
          };

          return (
            <div
              key={index}>
              <FormControlLabel
                control={
                  <ToggleSwitch
                    checked={permissions[permission]}
                    value={permissions[permission]}
                    // checked={permissions[permission] && !isPermissionDisabled()}
                    // value={permissions[permission] && !isPermissionDisabled()}
                    disabled={!editable[permission] || disabled || isPermissionDisabled()}
                    onChange={() => {
                      onTogglePermission(permission);
                    }}
                  />}
                label={
                  <Typography
                    variant="body"
                    fontWeight="normal"
                    fontFamily="primary">
                    {label}
                  </Typography>
                }
              />
            </div>
          );
        })
      }
    </>
  );
};

PermissionSwitches.propTypes = {
  permissions: PropTypes.objectOf(PropTypes.bool).isRequired,
  getPermissionReadableFormat: PropTypes.func.isRequired,
  editable: PropTypes.objectOf(PropTypes.bool).isRequired,
  onTogglePermission: PropTypes.func.isRequired,
};

export default PermissionSwitches;
