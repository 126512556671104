const NODE_ATOMIC_PERMISSIONS = [
  'grantOwnPermissions',
  'deleteNodeLinks',
  'readNodeLinks',
  'createNodeLinks',
  'deleteNodeAttachments',
  'updateNodeAttachments',
  'readNodeAttachments',
  'createNodeAttachments',
  'deleteNodeFields',
  'updateNodeFields',
  'readNodeFields',
  'createNodeFields',
  'updateLocation',
  'updateRecordAssignment',
  'delete',
  'update',
  'read',
];

const NODE_FRONTEND_PERMISSIONS = {
  view: [
    'read',
    'readNodeFields',
  ],
  edit: [
    'read',
    'update',
    'createNodeFields',
    'readNodeFields',
    'updateNodeFields',
    'deleteNodeFields',
    'updateRecordAssignment',
    'updateLocation',
  ],
  delete: [
    'read',
    'delete',
  ],
  manageAttachments: [
    'read',
    'createNodeAttachments',
    'readNodeAttachments',
    'updateNodeAttachments',
    'deleteNodeAttachments',
  ],
  manageLinks: [
    'read',
    'createNodeLinks',
    'readNodeLinks',
    'deleteNodeLinks',
  ],
  allow3rdPartyAccess: [
    'read',
    'grantOwnPermissions',
  ],
};

const NODE_ATOMIC_PERMISSION_LABELS = {
  grantOwnPermissions: 'Grant own permissions',
  deleteNodeLinks: 'Delete Item Links',
  readNodeLinks: 'Read Item Links',
  createNodeLinks: 'Create Item Links',
  deleteNodeAttachments: 'Delete Item Attachments',
  updateNodeAttachments: 'Update Item Attachments',
  readNodeAttachments: 'Read Item Attachments',
  createNodeAttachments: 'Create Item Attachments',
  deleteNodeFields: 'Delete Item Fields',
  updateNodeFields: 'Update Item Fields',
  readNodeFields: 'Read Item Fields',
  createNodeFields: 'Create Item Fields',
  updateLocation: 'Update Location',
  updateRecordAssignment: 'Update Record Assignments',
  delete: 'Delete',
  update: 'Update',
  read: 'View basic details',
};

const NODE_FRONTEND_PERMISSION_LABELS = {
  view: 'View',
  edit: 'Edit',
  delete: 'Delete',
  manageAttachments: 'Manage Attachments',
  manageLinks: 'Manage Links',
  allow3rdPartyAccess: 'Allow 3rd Party Access',
};

const RECORD_ATOMIC_PERMISSIONS = [
  'grantOwnPermissions',
  'deleteNodeLinks',
  'readNodeLinks',
  'createNodeLinks',
  'deleteNodeAttachments',
  'updateNodeAttachments',
  'readNodeAttachments',
  'createNodeAttachments',
  'deleteNodeFields',
  'updateNodeFields',
  'readNodeFields',
  'createNodeFields',
  'updateNodeLocations',
  'updateNodeRecordAssignments',
  'deleteNodes',
  'updateNodes',
  'readNodes',
  'createNodes',
  'update',
  'read',
];

const RECORD_FRONTEND_PERMISSIONS = {
  view: [
    'read',
    'readNodes',
    'readNodeFields',
  ],
  edit: [
    'read',
    'update',
    'createNodes',
    'readNodes',
    'updateNodes',
    'deleteNodes',
    'updateNodeRecordAssignments',
    'updateNodeLocations',
    'createNodeFields',
    'readNodeFields',
    'updateNodeFields',
    'deleteNodeFields',
  ],
  manageAttachments: [
    'read',
    'readNodes',
    'createNodeAttachments',
    'readNodeAttachments',
    'updateNodeAttachments',
    'deleteNodeAttachments',
  ],
  manageLinks: [
    'read',
    'readNodes',
    'createNodeLinks',
    'readNodeLinks',
    'deleteNodeLinks',
  ],
  allow3rdPartyAccess: [
    'read',
    'readNodes',
    'grantOwnPermissions',
  ],
};

const RECORD_ATOMIC_PERMISSION_LABELS = {
  grantOwnPermissions: 'Grant own permissions',
  deleteNodeLinks: 'Delete Item Links',
  readNodeLinks: 'Read Item Links',
  createNodeLinks: 'Create Item Links',
  deleteNodeAttachments: 'Delete Item Attachments',
  updateNodeAttachments: 'Update Item Attachments',
  readNodeAttachments: 'Read Item Attachments',
  createNodeAttachments: 'Create Item Attachments',
  deleteNodeFields: 'Delete Item Fields',
  updateNodeFields: 'Update Item Fields',
  readNodeFields: 'Read Item Fields',
  createNodeFields: 'Create Item Fields',
  updateNodeLocations: 'Update Item Locations',
  updateNodeRecordAssignments: 'Update Item Record Assignments',
  deleteNodes: 'Delte Items',
  updateNodes: 'Update Items',
  readNodes: 'Read Items',
  createNodes: 'Create Items',
  update: 'Update',
  read: 'View basic details',
};

const RECORD_FRONTEND_PERMISSION_LABELS = {
  view: 'View',
  edit: 'Edit',
  manageAttachments: 'Manage Attachments',
  manageLinks: 'Manage Links',
  allow3rdPartyAccess: 'Allow 3rd Party Access',
};

const NODE_FRONTEND_IMPLIED_PERMISSIONS = {
  edit: ['view'], // edit implies view
};

const NODE_FRONTEND_DISABLED_PERMISSIONS = {
  view: ['edit'], // view disabled by edit
};

const RECORD_FRONTEND_IMPLIED_PERMISSIONS = {
  edit: ['view'], // edit implies view
};

const RECORD_FRONTEND_DISABLED_PERMISSIONS = {
  view: ['edit'], // view disabled by edit
};

export {
  NODE_ATOMIC_PERMISSIONS,
  NODE_FRONTEND_PERMISSIONS,
  NODE_ATOMIC_PERMISSION_LABELS,
  NODE_FRONTEND_PERMISSION_LABELS,
  NODE_FRONTEND_IMPLIED_PERMISSIONS,
  NODE_FRONTEND_DISABLED_PERMISSIONS,
  RECORD_ATOMIC_PERMISSIONS,
  RECORD_FRONTEND_PERMISSIONS,
  RECORD_ATOMIC_PERMISSION_LABELS,
  RECORD_FRONTEND_PERMISSION_LABELS,
  RECORD_FRONTEND_IMPLIED_PERMISSIONS,
  RECORD_FRONTEND_DISABLED_PERMISSIONS,
};
