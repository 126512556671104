import React from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';
import FullScreenSpinner from 'components/chrome/FullScreenSpinner';

import Notifications from 'views/Notifications';
import SignIn from 'views/SignIn';
import SignUp from 'views/SignUp';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ResetPassword';

const UnauthenticatedApplication = () => {
  return (
    <React.Suspense
      fallback={<FullScreenSpinner/>}>
      <Switch>
        <Route
          path="/notifications"
          component={Notifications}
        />
        <Route
          path="/verify-email"
          component={SignIn}
        />
        <Route
          path="/forgot-password"
          component={ForgotPassword}
        />
        <Route
          path="/reset-password"
          component={ResetPassword}
        />
        <Route
          exact path="/sign-in"
          component={SignIn}
        />
        <Route
          path="/sign-up"
          component={SignUp}
        />
        <Redirect
          to="/sign-in"
        />
      </Switch>
    </React.Suspense>
  );
};

export default UnauthenticatedApplication;
