import GET__nodes_ID_records from 'network-layer/endpoints/GET__nodes_ID_records';

function responseAdapter(response) {
  return response;
}

export default async function genNodeSpawnedRecords(authToken, perspectiveId, nodeId, include) {
  const response = await GET__nodes_ID_records(authToken, perspectiveId, nodeId, include);

  return responseAdapter(response);
}
