import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui-library/theme';
import {makeStyles} from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';

const useStyles = makeStyles({
  viewContainer: {
    display: theme.title.viewContainer.display,
    flexDirection: theme.title.viewContainer.flexDirection,
    paddingLeft: theme.title.viewContainer.paddingLeft,
    paddingTop: theme.title.viewContainer.paddingTop,
    backgroundColor: theme.title.viewContainer.backgroundColor,
    height: theme.title.viewContainer.height,
    width: theme.title.viewContainer.width,
  },
  editContainer: {
    height: theme.title.editContainer.height,
    display: theme.title.editContainer.display,
    flexDirection: theme.title.editContainer.flexDirection,
    position: theme.title.editContainer.position,
    width: theme.title.editContainer.width,
  },
  actionButtonsContainer: theme.title.actionButtonsContainer,
  actionButton: theme.title.actionButton,
  input: {
    fontSize: theme.title.input.fontSize,
    fontWeight: theme.title.input.fontWeight,
  },
  ellipsisContainer: theme.title.ellipsisContainer,
});

const EditableTitle = ({
  autoFocus,
  helperText,
  error,
  value,
  disabled,
  editable,
  setEditable,
  // fullEditMode,
  // addNewLinkMode,
  panelDisplayMode,
  onChange,
  onKeyPress,
  onBlur,
  onMouseDown,
  revertName,
  saveName,
  isSaving,
  'data-test-id': dataTestId,
}) => {

  const classes = useStyles();

  // if (fullEditMode && !addNewLinkMode) {
  if (panelDisplayMode === 'editItem') {
    return (
      <div
        className={classes.editContainer}
        data-test-id={dataTestId}
      >
        <TextField
          title={true}
          autoFocus={autoFocus}
          value={value}
          error={error}
          helperText={helperText}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onChange={onChange}
          onBlur={onBlur}
          InputProps={{
            className: classes.input,
          }}
        />
      </div>
    );
  }

  if (isSaving) {
    return (
      <div
        className={classes.viewContainer}
      >
        <div className={classes.iconValueContainer}>
          <div className={classes.ellipsisContainer}>
            <Typography
              variant="h3"
              fontWeight="bold"
              onClick={() => {
                setEditable();
              }}
            >
              Saving...
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  if (editable) {
    return (
      <div
        className={classes.editContainer}
        data-test-id={dataTestId}
      >
        <TextField
          title={true}
          autoFocus={autoFocus}
          value={value}
          error={error}
          helperText={helperText}
          disabled={disabled}
          onKeyPress={onKeyPress}
          onChange={onChange}
          onBlur={onBlur}
          onMouseDown={onMouseDown}
          InputProps={{
            className: classes.input,
          }}
        />
        <div
          className={classes.actionButtonsContainer}
        >
          {
            !disabled &&
            <div
              className={classes.actionButton}
              onMouseDown={onMouseDown}
            >
              <CloseIcon
                onClick={revertName}
              />
            </div>
          }
          {
            !disabled &&
            <div
              className={classes.actionButton}
            >
              <CheckIcon
                onClick={() => {
                  if (!error) {
                    saveName();
                  }
                }}
                onMouseDown={onMouseDown}
              />
            </div>
          }
        </div>
      </div>
    );
  }

  return (
    <div
      className={classes.viewContainer}
      data-test-id={dataTestId}
    >
      <div className={classes.iconValueContainer}>
        <div className={classes.ellipsisContainer}>
          <Typography
            variant="h3"
            fontWeight="bold"
            onClick={() => {
              setEditable();
            }}
          >
            {value}
          </Typography>
        </div>
      </div>
    </div>
  );
};

EditableTitle.propTypes = {
  autoFocus: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  setEditable: PropTypes.func,
  fullEditMode: PropTypes.bool,
  addNewLinkMode: PropTypes.bool,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onMouseDown: PropTypes.func,
  revertName: PropTypes.func,
  saveName: PropTypes.func,
  isSaving: PropTypes.bool,
};

export default EditableTitle;
