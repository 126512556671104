import React from 'react';
import styled from '@emotion/styled';
import ConnectionList from 'components/ConnectionList';
import ConnectionsContext from './context';
import ListSkeleton from 'ui-library/components/ListSkeleton';
import Typography from 'ui-library/components/Typography';
import genConnections from 'services/Connections/genConnections';

const EmptyList = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 130px;
`;

const ConnectionListRoute = ({
  match,
}) => {
  const {
    authToken,
    currentPerspectiveId,
    connectionIdModifiedDestructivelyForLists,
    dispatchUserInterfaceAction,
    filter,
    pushAndClearFilter,
  } = React.useContext(ConnectionsContext);
  const [status, setStatus] = React.useState('fetching data');
  const [fatalError, setFatalError] = React.useState(null);
  const [connections, setConnections] = React.useState(null);

  const initialize = React.useCallback(async() => {
    try {
      setStatus('fetching data');
      const connections = await genConnections(authToken, currentPerspectiveId);
      setConnections(connections);
      dispatchUserInterfaceAction({
        type: 'SET_CONNECTION_ID_MODIFIED_DESTRUCTIVELY_FOR_LISTS',
        payload: null,
      });
      setStatus('ready');
    } catch(error) {
      setFatalError(error);
    }
  }, [authToken, currentPerspectiveId, dispatchUserInterfaceAction]);

  React.useEffect(() => {
    initialize();
  },[initialize]);

  React.useEffect(() => {
    if (connectionIdModifiedDestructivelyForLists) {
      initialize();
    }
  }, [connectionIdModifiedDestructivelyForLists, initialize]);

  if (fatalError) {
    throw fatalError;
  }

  if (status !== 'ready') {
    return (
      <ListSkeleton/>
    );
  }

  if (connections.length === 0) {
    return (
      <EmptyList>
        <Typography variant="h4" color="grey" fontWeight="bold">
          You have not established any Connections with other Jules accounts. Connections allow you to share access to your Jules data as well as gain access to external Jules accounts.
        </Typography>
      </EmptyList>
    );
  }

  const renderedConnections =
    connections
      .filter(connection => {
        if (!filter) {
          return true;
        }
        const {
          name,
        } = connection;
        return (
          name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
        );
      })
      .sort((c1, c2) => {
        const {
          status: c1Status,
        } = c1;
        const {
          status: c2Status,
        } = c2;
        if (c1Status === 'active' && c2Status === 'pending') {
          return -1;
        } else if (c1Status === 'pending' && c2Status === 'active') {
          return 1;
        }
        return 0;
      });

  return (
    <div>
      <ConnectionList
        connections={renderedConnections}
        onConnectionAction={(connectionId, action) => {
          switch(action) {
            case 'expand': {
              dispatchUserInterfaceAction({
                type: 'SET_EXPANDED_CONNECTION_ID',
                payload: connectionId,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_CONNECTION_PANEL',
                payload: true,
              });
              break;
            }
            case 'delete': {
              dispatchUserInterfaceAction({
                type: 'SET_DELETE_CONNECTION_ID',
                payload: connectionId,
              });
              dispatchUserInterfaceAction({
                type: 'SET_SHOW_DELETE_CONNECTION_DIALOG',
                payload: true,
              });
              break;
            }
            case 'navigate': {
              pushAndClearFilter(`${match.url}/${connectionId}`);
              break;
            }
            default:
              break;
          }
        }}
      />
    </div>
  );
};

export default ConnectionListRoute;
