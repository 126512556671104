import React from 'react';
import Typography from 'ui-library/components/Typography';
import Link from 'ui-library/components/Link';
import styled from '@emotion/styled';

import AppStore from 'images/appstore.svg';
import GooglePlay from 'images/googleplay.png';

const Root = styled.div`
  width: 400px;
  height: 180px;
`;

const Title = styled.div`
  text-align: center;
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 15px 20px 15px;
`;

const AppLinks = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 15px 0 15px;
`;

const UnauthenticatedFooter = () => {
  return (
    <Root>
      <Title>
        <Typography
          variant="body"
          fontWeight="bold"
          color="grey"
        >
          Download the free Jules mobile app.
        </Typography>
      </Title>
      <AppLinks>
        <div
          style={{
            display: 'flex',
            height: 43,
            justifyContent: 'center',
            margin: 2,
          }}
        >
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://itunes.apple.com/us/app/jules-mobile/id1443574567?mt=8"
          >
            <img
              alt="Download on the App Store"
              src={AppStore}
            />
          </a>
        </div>

        <div
          style={{
            display: 'flex',
            height: 43,
            justifyContent: 'center',
            margin: 2,
          }}
        >
          <a
            rel="noopener noreferrer"
            target="_blank"
            href='https://play.google.com/store/apps/details?id=app.jules.mobile&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          >
            <img
              height={40}
              alt='Get it on Google Play'
              src={GooglePlay}
            />
          </a>
        </div>
      </AppLinks>
      <Links>
        <Link
          data-test-id="faq-link"
          variant="x-small"
          color="primary"
          value="https://static.jules.app/faq.html"
          label="FAQ"
        />
        <Link
          data-test-id="terms-and-cond-link"
          variant="x-small"
          color="primary"
          value="https://static.jules.app/terms_of_use.html"
          label="TERMS & CONDITIONS"
        />
        <Link
          data-test-id="privacy-policy-link"
          variant="x-small"
          color="primary"
          value="https://static.jules.app/privacy_policy.html"
          label="PRIVACY POLICY"
        />
      </Links>
    </Root>
  );
};

export default UnauthenticatedFooter;
