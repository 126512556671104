import React from 'react';
import ListItem from 'ui-library/components/ListItem';
import ListItemContentSegment from 'ui-library/components/ListItemContentSegment';
import ListItemCheckbox from 'ui-library/components/ListItemCheckbox';

import ListItemActionsWrapper from 'components/ListItemActionsWrapper';
import ListItemLocationWrapper from 'components/ListItemLocationWrapper';
import ListItemNameWrapper from 'components/ListItemNameWrapper';

const NodeList = ({
  nodes,
  selectedNodeIds,
  onNodeAction,
}) => {
  return (
    <>
      {
        nodes.map(node => {
          const {
            id,
            name,
            typeName,
            locationPath,
          } = node;

          const isSelected = selectedNodeIds.indexOf(id) > -1;

          return (
            <ListItem
              key={id}
              isSelected={isSelected}
              onClick={() => {
                onNodeAction(id, 'expand');
              }}
            >
              <ListItemContentSegment
                width={44}
                padding={'11px 8px'}
                onClick={e => {
                  e.stopPropagation();
                  if (isSelected) {
                    onNodeAction(id, 'deselect');
                  } else {
                    onNodeAction(id, 'select');
                  }
                }}
              >
                <ListItemCheckbox
                  checked={isSelected}
                  onChange={() => {
                    switch (isSelected) {
                      case true: {
                        onNodeAction(id, 'deselect');
                        break;
                      }
                      case false: {
                        onNodeAction(id, 'select');
                        break;
                      }
                      default: {
                        break;
                      }
                    }
                  }}
                />
              </ListItemContentSegment>
              <ListItemNameWrapper
                name={name}
                typeName={typeName}
              />

              <ListItemLocationWrapper
                locationPath={locationPath}
              />

              <ListItemActionsWrapper
                id={id}
                onNodeAction={onNodeAction}
              />
            </ListItem>
          );
        })
      }
    </>
  );
};

export default NodeList;
