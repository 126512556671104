function selectFieldDefinitionsByTypeId(DM, typeId) {
  const {
    fieldDefinitionsById,
    fieldDefinitionIds,
  } = DM;

  return (
    fieldDefinitionIds
      .map(fieldDefinitionId => fieldDefinitionsById[fieldDefinitionId])
      .filter(fieldDefinition => fieldDefinition.typeId === typeId)
  );
}

export default selectFieldDefinitionsByTypeId;
