import GET__imagePreviews_FILENAME from 'network-layer/endpoints/GET__imagePreviews_FILENAME';

function responseAdapter(response) {
  return response;
}

export default async function genImagePreview(params) {
  const {
    authToken,
    currentPerspectiveId,
    fileName,
  } = params;

  const response = await GET__imagePreviews_FILENAME(authToken, currentPerspectiveId, fileName);
  return responseAdapter(response);
}
