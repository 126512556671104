import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import styled from '@emotion/styled';
import Button from 'ui-library/components/Button';
import TextField from 'ui-library/components/TextField';
import Typography from 'ui-library/components/Typography';
import Link from 'ui-library/components/Link';
import Message from 'ui-library/components/Message';
import UnauthenticatedFooter from 'components/UnauthenticatedFooter';
import UnauthenticatedHeader from 'components/UnauthenticatedHeader';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffRounded';

import internalCodeErrorMessage from 'api-error-codes/internalCodeErrorMessage';

import getSearchParam from 'utils/getSearchParam';

import resetPassword from 'services/Auth/resetPassword';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 500px;
`;


const HeaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 328px;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
  padding: 110px 0;
`;

const Content = styled.div`
  width: 100%;
  padding: 32px 0;
`;

const FieldWithValidationGutter = styled.div`
  height: 80px;
`;

const FooterContainer = styled.div`
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
`;

const ValidationRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-start;
  text-align: left;
`;


const ResetPassword = () => {
  const [password, setPassword] = React.useState('');
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [passwordIsPristine, setPasswordIsPristine] = React.useState(true);
  const [passwordConfirmIsPristine, setPasswordConfirmIsPristine] = React.useState(true);
  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState(null);

  const [fatalError, setFatalError] = React.useState(null);
  if (fatalError) {
    throw fatalError;
  }

  const location = useLocation();
  const [passwordResetToken, setPasswordResetToken] = React.useState(null);

  React.useEffect(() => {
    setPasswordResetToken(getSearchParam(location)('passwordResetToken'));
  }, [location]);


  const [hasCorrectLength, setHasCorrectLength] = React.useState(false);
  const [hasOneLowerCase, setHasOneLowerCase] = React.useState(false);
  const [hasOneUpperCase, setHasOneUpperCase] = React.useState(false);
  const [hasOneDigit, setHasOneDigit] = React.useState(false);
  const [hasOneSpecial, setHasOneSpecial] = React.useState(false);
  const [passwordIsValid, setPasswordIsValid] = React.useState(false);

  React.useEffect(() => {
    const correctLength = new RegExp(/^(?=.{8,72}$).*/);
    const oneLowerCase = new RegExp(/(?=.*[a-z]).*$/);
    const oneUpperCase = new RegExp(/(?=.*[A-Z]).*$/);
    const oneDigit = new RegExp(/(?=.*[0-9]).*$/);
    const oneSpecial = new RegExp(/(?=.*[^a-zA-Z0-9]).*$/);

    setHasCorrectLength(correctLength.test(password));
    setHasOneLowerCase(oneLowerCase.test(password));
    setHasOneUpperCase(oneUpperCase.test(password));
    setHasOneDigit(oneDigit.test(password));
    setHasOneSpecial(oneSpecial.test(password));

    setPasswordIsValid(hasCorrectLength && hasOneLowerCase && hasOneUpperCase && hasOneDigit && hasOneSpecial);
  }, [
    password,
    hasCorrectLength,
    setHasCorrectLength,
    hasOneLowerCase,
    setHasOneLowerCase,
    hasOneUpperCase,
    setHasOneUpperCase,
    hasOneDigit,
    setHasOneDigit,
    hasOneSpecial,
    setHasOneSpecial,
    passwordIsValid,
    setPasswordIsValid,
  ]);

  const passwordConfirmIsValid = () => {
    if (passwordConfirm === '') {
      return false;
    }
    return password === passwordConfirm;
  };

  const getErrorMessage = (errorCode) => {
    switch (errorCode) {
      case 1002:
        return (
          <Typography variant="body">
            The link you're using has expired. Please request a new one&nbsp;
            <Link
              value="/forgot-password"
              label="here."
              color="white"
              variant="body"
            />
          </Typography>
        );
      default:
        return internalCodeErrorMessage(errorCode);
    }
  };

  if (resetPasswordSuccess) {
    return <Redirect to={{
      pathname: '/sign-in',
      state: {passwordResetSuccess: true},
    }}/>;
  }

  return (
    <Container>
      <HeaderContainer>
        <UnauthenticatedHeader />
      </HeaderContainer>
      <Message
        variant="error"
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
        messageBody={errorCode && getErrorMessage(errorCode)}
        autoHideDuration={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
      <ContentContainer>
        <Typography variant="body" color="grey" fontWeight="bold">
          Type your new password
        </Typography>
        <br />
        <ValidationRow>
          <Typography variant="x-small" color={hasCorrectLength ? 'success' : 'error'}>
            Between 8 and 72 characters
          </Typography>
        </ValidationRow>
        <ValidationRow>
          <Typography variant="x-small" color={hasOneUpperCase ? 'success' : 'error'}>
            Uppercase characters
          </Typography>
        </ValidationRow>
        <ValidationRow>
          <Typography variant="x-small" color={hasOneLowerCase ? 'success' : 'error'}>
            Lowercase characters
          </Typography>
        </ValidationRow>
        <ValidationRow>
          <Typography variant="x-small" color={hasOneDigit ? 'success' : 'error'}>
            Numbers
          </Typography>
        </ValidationRow>
        <ValidationRow>
          <Typography variant="x-small" color={hasOneSpecial ? 'success' : 'error'}>
            Special characters
          </Typography>
        </ValidationRow>
        <Content>
          <FieldWithValidationGutter>
            <TextField
              fullWidth
              style={{paddingBottom: '16px'}}
              autoComplete="on"
              label="New password"
              value={password}
              disabled={false}
              onChange={(event) => {
                setPassword(event.target.value);
                setPasswordIsPristine(false);
              }}
              error={!passwordIsValid && !passwordIsPristine}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => {setShowPassword(!showPassword);}}>
                    {showPassword === true ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </FieldWithValidationGutter>

          <FieldWithValidationGutter>
            <TextField
              fullWidth
              style={{paddingBottom: '16px'}}
              autoComplete="on"
              label="New password again"
              value={passwordConfirm}
              disabled={false}
              onChange={(event) => {
                setPasswordConfirm(event.target.value);
                setPasswordConfirmIsPristine(false);
              }}
              error={!passwordConfirmIsValid() && !passwordConfirmIsPristine}
              helperText={(!passwordConfirmIsValid() && !passwordConfirmIsPristine) ? 'Passwords do not match!' : ''}
              type={showPasswordConfirm ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={() => {setShowPasswordConfirm(!showPasswordConfirm);}}>
                    {showPasswordConfirm === true ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </FieldWithValidationGutter>

          <ButtonsContainer>
            <Button
              data-test-id="reset-password-button"
              variant="primary"
              disabled={!passwordConfirmIsValid() || !passwordIsValid}
              onClick={async() => {
                try {
                  await resetPassword(passwordConfirm, passwordResetToken);
                  setResetPasswordSuccess(true);
                } catch (err) {
                  const {
                    body: {
                      code,
                    },
                  } = err;
                  switch (code) {
                    case 1002:
                      setShowErrorMessage(true);
                      setErrorCode(code);
                      break;
                    default:
                      setFatalError(err);
                  }
                }
              }}
            >
              Reset password
            </Button>
          </ButtonsContainer>

          <Divider />

          <LinkContainer>
            <Link
              data-test-id="login-link"
              value="/sign-in"
              label="Back to Login"
              color="primary"
              variant="small"
              fontWeight="bold"
            />
          </LinkContainer>
        </Content>
      </ContentContainer>
      <FooterContainer>
        <UnauthenticatedFooter />
      </FooterContainer>
    </Container>
  );
};

export default ResetPassword;
