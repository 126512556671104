import React from 'react';
import styled from '@emotion/styled';
import {
  conversationPanelNavigationHeight,
  conversationPanelHeaderHeight,
  conversationPanelGutter,
} from 'constants/layout';
import {
  greyLightest,
  greySemi,
} from 'ui-library/color-palette';

const Root = styled.div`
  margin-top: ${conversationPanelNavigationHeight}px;
  display: flex;
  position: fixed;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: ${conversationPanelHeaderHeight}px;
  padding: 24px ${conversationPanelGutter}px 0px ${conversationPanelGutter}px;
  background-color: ${greyLightest};
  transition: right .3s ease;
  border-bottom: 1px solid ${greySemi};
  z-index: 1;
`;

const ConversationPanelHeader = ({
  children,
}) => {

  return (
    <Root>
      {children}
    </Root>
  );
};

export default ConversationPanelHeader;
