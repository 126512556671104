function selectFieldWatchersByNotificationPipelineId(DM, notificationPipelineId) {
  const {
    fieldWatchersById,
    fieldWatcherIds,
  } = DM;

  return (
    fieldWatcherIds
      .map(fieldWatcherId => fieldWatchersById[fieldWatcherId])
      .filter(fieldWatcher => fieldWatcher.notificationPipelineId === notificationPipelineId)
  );
}

export default selectFieldWatchersByNotificationPipelineId;
