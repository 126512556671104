import React from 'react';
import AdminContext from 'contexts/Admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import RecordTypeAssociationsDialog from './RecordTypeAssociationsDialog.react';
import NameDialog from './NameDialog.react';
import LocationParentsDialog from './LocationParentsDialog.react';
import TypesList from './TypesList.react';
import Prompt from 'components/admin/Prompt';

const List = ({
  history,
}) => {
  const {
    authToken,
    perspectiveId,
    DataModel,
    DataModelQueries,
    DataModelAPI,
    dispatchDataModelAction,
    setAPIException,
  } = React.useContext(AdminContext);
  const [localState, dispatchLocalAction] = React.useReducer(
    reducer,
    null,
    initState,
  );

  const localType = DataModelQueries.selectTypeById(DataModel, localState.typeId);
  const localRecordType = DataModelQueries.selectRecordTypeById(DataModel, localState.recordTypeId);
  const isTypeIdActiveInEhrHub = DataModelQueries.selectElectronicHealthHubTypeIds(DataModel).includes(localState.typeId);
  const isEhrRecordTypeSelected = localState.recordTypeId === 7;

  const filteredTypes = DataModelQueries.selectTypes(DataModel)
    .filter(type => {
      if (localState.showAll) {
        return true;
      }

      if (localState.filterQuery.length < 3) {
        return false;
      }

      const {
        name: typeName,
        aliases: typeAliases,
      } = type;

      if (typeName.toLocaleLowerCase().includes(localState.filterQuery.toLocaleLowerCase())) {
        return true;
      }
      for (let typeAlias of typeAliases) {
        if (typeAlias.toLocaleLowerCase().includes(localState.filterQuery.toLocaleLowerCase())) {
          return true;
        }
      }

      return false;
    });

  return (
    <div>
      <Typography
        variant="h4">
        Types
      </Typography>
      <Typography
        variant="body2">
        create or edit Types
      </Typography>

      <br/><br/>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatchLocalAction({
            type: 'SET_SHOW_ALL',
            payload: !localState.showAll,
          });
        }}>
        {localState.showAll ? 'hide' : 'show'} all
      </Button>
      &nbsp;
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          history.push('/types/create');
        }}>
        create a new type
      </Button>
      <br/><br/>

      <TextField
        fullWidth
        placeholder="filter by type name or type alias (3 characters minimum)"
        value={localState.filterQuery}
        onChange={event => {
          dispatchLocalAction({
            type: 'SET_FILTER_QUERY',
            payload: event.target.value,
          });
        }}
      />
      <br/><br/><br/>

      <TypesList
        types={filteredTypes}
        onEditType={typeId => {
          history.push(`/types/${typeId}`);
        }}
        onEditRecordAssignments={typeId => {
          dispatchLocalAction({
            type: 'EDIT_RECORD_ASSIGNMENTS',
            payload: typeId,
          });
        }}
        onSetLocationParents={typeId => {
          dispatchLocalAction({
            type: 'SET_LOCATION_PARENTS',
            payload: typeId,
          });
        }}
      />

      <NameDialog
        open={
          localState.status === 'renaming' ||
          localState.status === 'pending rename'
        }
        controlsDisabled={localState.status === 'pending rename'}
        type={localType}
        name={localState.name}
        onSetName={newName => {
          dispatchLocalAction({
            type: 'SET_NAME',
            payload: newName,
          });
        }}
        pluralName={localState.pluralName}
        onSetPluralName={newPluralName => {
          dispatchLocalAction({
            type: 'SET_PLURAL_NAME',
            payload: newPluralName,
          });
        }}
        onClose={() => {
          dispatchLocalAction({
            type: 'END_RENAME',
          });
        }}
        onSave={async() => {
          dispatchLocalAction({
            type: 'REQUEST_RENAME',
          });

          try {
            const {
              typeId,
              name,
              pluralName,
            } = localState;

            await DataModelAPI.updateType(
              authToken,
              perspectiveId,
              {
                typeId,
                name,
                pluralName,
              },
            );

            dispatchDataModelAction({
              type: 'UPDATE_TYPE',
              payload: {
                typeId,
                name,
                pluralName,
              },
            });
            dispatchLocalAction({
              type: 'END_RENAME',
            });
          } catch(error) {
            dispatchLocalAction({
              type: 'END_RENAME',
            });
            setAPIException(error);
          }
        }}
      />

      <RecordTypeAssociationsDialog
        open={
          localState.status === 'editing record assignments' ||
          localState.status === 'associating type with record type' ||
          localState.status === 'disassociating type from record type' ||
          localState.status === 'pending association mutation'
        }
        controlsDisabled={localState.status === 'pending association mutation'}
        onClose={() => {
          dispatchLocalAction({
            type: 'END_EDIT_RECORD_ASSIGNMENTS',
          });
        }}
        typeId={localState.typeId}
        onAssociateTypeWithRecordType={(typeId, recordTypeId) => {
          dispatchLocalAction({
            type: 'ASSOCIATE_TYPE_WITH_RECORD_TYPE',
            payload: {
              typeId,
              recordTypeId,
            },
          });
        }}
        onDisassociateTypeFromRecordType={(typeId, recordTypeId) => {
          console.log(typeId, recordTypeId);
          dispatchLocalAction({
            type: 'DISASSOCIATE_TYPE_FROM_RECORD_TYPE',
            payload: {
              typeId,
              recordTypeId,
            },
          });
        }}
        DataModelQueries={DataModelQueries}
        DataModel={DataModel}
      />

      {
        localState.status === 'associating type with record type' &&
          <Prompt
            open
            title="Confirm association"
            text={
              <>
                Are you sure you want to associate <strong>{localType.name}</strong> with <strong>{localRecordType.name}</strong>? Doing so means that users will be able to assign {localType.pluralName} to {localRecordType.name} records.
              </>
            }
            onCancel={() => {
              dispatchLocalAction({
                type: 'END_ASSOCIATE_TYPE_WITH_RECORD_TYPE',
              });
            }}
            confirmLabel="perform the association"
            onConfirm={async() => {
              dispatchLocalAction({
                type: 'REQUEST_ASSOCIATE_TYPE_WITH_RECORD_TYPE',
              });

              try {
                const {
                  typeId,
                  recordTypeId,
                } = localState;

                await DataModelAPI.addTypeToRecordType(
                  authToken,
                  perspectiveId,
                  {
                    typeId,
                    recordTypeId,
                  },
                );

                dispatchDataModelAction({
                  type: 'ADD_TYPE_TO_RECORD_TYPE',
                  payload: {
                    typeId,
                    recordTypeId,
                  },
                });
                dispatchLocalAction({
                  type: 'END_ASSOCIATE_TYPE_WITH_RECORD_TYPE',
                });
              } catch(error) {
                dispatchLocalAction({
                  type: 'END_ASSOCIATE_TYPE_WITH_RECORD_TYPE',
                });
                setAPIException(error);
              }
            }}
          />
      }

      {
        localState.status === 'disassociating type from record type' &&
        (!isEhrRecordTypeSelected || (isEhrRecordTypeSelected && !isTypeIdActiveInEhrHub)) &&
          <Prompt
            open
            title="Confirm disassociation"
            text={
              <>
                Are you sure you want to disassociate <strong>{localType.name}</strong> from <strong>{localRecordType.name}</strong>? Doing so means the following things:
                <br/><br/>
                &bull; users will no longer be able to assign {localType.pluralName} to {localRecordType.name} records<br/>
                &bull; <strong>{localType.name}</strong> will be removed from any Categories that no longer apply to it based on its record assignments<br/>
                &bull; if <strong>{localType.name}</strong> was added to a property configurator then it will be removed from that property configurator along with all its descendants in that property configurator<br/>
                <br/>
                <em>
                  Note that if there are any {localType.pluralName} assigned to {localRecordType.name} records anywhere in the Data Graph then this operation will not be performed.
                  <br/><br/>
                  Also, any Type must be assigned to at least one type of record so you may not remove <strong>{localType.name}</strong> from <strong>{localRecordType.name}</strong> if that is its only assignment.
                </em>
                <br/><br/>
                <strong style={{color: 'red'}}>
                  As stated above, this is potentially a highly destructive operation and it cannot be rolled back! Please be certain before you confirm.
                </strong>
              </>
            }
            onCancel={() => {
              dispatchLocalAction({
                type: 'END_DISASSOCIATE_TYPE_FROM_RECORD_TYPE',
              });
            }}
            confirmLabel="I'm certain, perform the disassociation"
            onConfirm={async() => {
              dispatchLocalAction({
                type: 'REQUEST_DISASSOCIATE_TYPE_FROM_RECORD_TYPE',
              });

              try {
                const {
                  typeId,
                  recordTypeId,
                } = localState;

                await DataModelAPI.removeTypeFromRecordType(
                  authToken,
                  perspectiveId,
                  {
                    typeId,
                    recordTypeId,
                  },
                );

                dispatchDataModelAction({
                  type: 'REMOVE_TYPE_FROM_RECORD_TYPE',
                  payload: {
                    typeId,
                    recordTypeId,
                  },
                });

                dispatchLocalAction({
                  type: 'END_DISASSOCIATE_TYPE_FROM_RECORD_TYPE',
                });

              } catch(error) {
                dispatchLocalAction({
                  type: 'END_DISASSOCIATE_TYPE_FROM_RECORD_TYPE',
                });
                setAPIException(error);
              }
            }}
          />
      }

      {
        localState.status === 'disassociating type from record type' &&
        isEhrRecordTypeSelected &&
        isTypeIdActiveInEhrHub &&
        <Prompt
          open
          title="Operation forbidden!"
          text={`The "${localType.name}" type cannot be disassociated with the "${localRecordType.name}" record type because it is used in the eHR Hub!`}
          onConfirm={() => {
            dispatchLocalAction({
              type: 'RESET',
            });
          }}
        />
      }

      <LocationParentsDialog
        open={
          localState.status === 'setting location parents' ||
          localState.status === 'pending set location parent'
        }
        controlsDisabled={localState.status === 'pending set location parent'}
        type={localType}
        onClose={() => {
          dispatchLocalAction({
            type: 'END_SET_LOCATION_PARENTS',
          });
        }}
        DataModel={DataModel}
        DataModelQueries={DataModelQueries}
        onAddTypeLocationGraphEdge={async(childTypeId, parentTypeId) => {
          dispatchLocalAction({
            type: 'REQUEST_SET_LOCATION_PARENT',
          });
          try {
            await DataModelAPI.createTypeLocationGraphEdge(
              authToken,
              perspectiveId,
              {
                ancestorId: parentTypeId,
                descendantId: childTypeId,
              },
            );
            dispatchDataModelAction({
              type: 'CREATE_TYPE_LOCATION_GRAPH_EDGE',
              payload: {
                ancestorId: parentTypeId,
                descendantId: childTypeId,
              },
            });
            dispatchLocalAction({
              type: 'END_SET_LOCATION_PARENT',
            });
          } catch(error) {
            dispatchLocalAction({
              type: 'END_SET_LOCATION_PARENT',
            });
            setAPIException(error);
          }
        }}
        onDeleteTypeLocationGraphEdge={async(childTypeId, parentTypeId) => {
          dispatchLocalAction({
            type: 'REQUEST_SET_LOCATION_PARENT',
          });
          try {
            await DataModelAPI.deleteTypeLocationGraphEdge(
              authToken,
              perspectiveId,
              {
                ancestorId: parentTypeId,
                descendantId: childTypeId,
              },
            );
            dispatchDataModelAction({
              type: 'DELETE_TYPE_LOCATION_GRAPH_EDGE',
              payload: {
                ancestorId: parentTypeId,
                descendantId: childTypeId,
              },
            });
            dispatchLocalAction({
              type: 'END_SET_LOCATION_PARENT',
            });
          } catch(error) {
            dispatchLocalAction({
              type: 'END_SET_LOCATION_PARENT',
            });
            setAPIException(error);
          }
        }}
      />
    </div>
  );
};

function reducer(state, {type: actionType, payload}) {
  switch(actionType) {

    case 'EDIT_RECORD_ASSIGNMENTS': {
      const typeId = payload;

      return {
        ...state,
        status: 'editing record assignments',
        typeId,
      };
    }

    case 'ASSOCIATE_TYPE_WITH_RECORD_TYPE': {
      const {
        typeId,
        recordTypeId,
      } = payload;

      return {
        ...state,
        status: 'associating type with record type',
        typeId,
        recordTypeId,
      };
    }

    case 'REQUEST_ASSOCIATE_TYPE_WITH_RECORD_TYPE': {
      return {
        ...state,
        status: 'pending association mutation',
      };
    }

    case 'END_ASSOCIATE_TYPE_WITH_RECORD_TYPE': {
      return {
        ...state,
        status: 'editing record assignments',
        recordTypeId: '',
      };
    }

    case 'DISASSOCIATE_TYPE_FROM_RECORD_TYPE': {
      const {
        typeId,
        recordTypeId,
      } = payload;

      return {
        ...state,
        status: 'disassociating type from record type',
        typeId,
        recordTypeId,
      };
    }

    case 'REQUEST_DISASSOCIATE_TYPE_FROM_RECORD_TYPE': {
      return {
        ...state,
        status: 'pending association mutation',
      };
    }

    case 'END_DISASSOCIATE_TYPE_FROM_RECORD_TYPE': {
      return {
        ...state,
        status: 'editing record assignments',
        recordTypeId: '',
      };
    }

    case 'END_EDIT_RECORD_ASSIGNMENTS': {
      return {
        ...state,
        status: 'idle',
        typeId: '',
      };
    }

    case 'RENAME': {
      const typeId = payload;

      return {
        ...state,
        status: 'renaming',
        typeId,
      };
    }

    case 'SET_NAME': {
      const name = payload;

      return {
        ...state,
        name,
      };
    }

    case 'SET_PLURAL_NAME': {
      const pluralName = payload;

      return {
        ...state,
        pluralName,
      };
    }

    case 'REQUEST_RENAME': {
      return {
        ...state,
        status: 'pending rename',
      };
    }

    case 'END_RENAME': {
      return {
        ...state,
        status: 'idle',
        typeId: '',
        name: '',
        pluralName: '',
      };
    }

    case 'SET_LOCATION_PARENTS': {
      const typeId = payload;

      return {
        ...state,
        status: 'setting location parents',
        typeId,
      };
    }

    case 'REQUEST_SET_LOCATION_PARENT': {
      return {
        ...state,
        status: 'pending set location parent',
      };
    }

    case 'END_SET_LOCATION_PARENT': {
      return {
        ...state,
        status: 'setting location parents',
      };
    }

    case 'END_SET_LOCATION_PARENTS': {
      return {
        ...state,
        status: 'idle',
        typeId: '',
      };
    }

    case 'SET_SHOW_ALL': {
      const showAll = payload;
      return {
        ...state,
        showAll,
        filterQuery: '',
      };
    }

    case 'SET_FILTER_QUERY': {
      const filterQuery = payload;

      return {
        ...state,
        showAll: false,
        filterQuery,
      };
    }

    case 'RESET': {
      return {
        ...initState(),
      };
    }

    default:
      throw new Error(`Unknown action type "${actionType}"!`);

  }
}

function initState() {
  return {
    /*
      idle
      renaming
      pending rename
      editing record assignments
      associating type with record type
      disassociating type from record type
      pending association mutation
      setting location parents
      pending set location parent
    */
    status: 'idle',
    typeId: '',
    name: '',
    pluralName: '',
    recordTypeId: '',
    // filters:
    showAll: false,
    filterQuery: '',
  };
}

export default List;
